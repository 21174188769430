<template>
  <div ref="draggableContainer" id="draggable-container" :style="{'visibility': isVisible ? 'visible' : 'hidden','background-color':getBackgroundLayoutColor(),'fill':getFontLayoutColor(),'color':getFontLayoutColor()}">
    <div id="draggable-header" @mousedown="dragMouseDown" >
      <div id="panel-container" >
        <div>
          <i class="fa fa-bars" style="margin-top:0.35em;padding-right:0.35em"></i>
          <!--slot name="header"></slot-->
        </div>
        <div id="panel-button" class="no-drag" @mousedown="dragMouseDownNo">
          <button class="button button1"  :style="{'filter':getBrightness()}" @click="setShowPanel('defaults')"><i class="fa fa-bookmark"></i></button>
        </div>
        <div v-if="lineWidth()>0" class="vl"></div>
        <div v-if="lineWidth()>0" id="panel-button" class="no-drag" @mousedown="dragMouseDownNo">
          <button class="button button1"  :style="{'filter':getBrightness()}" @click="setShowLineWidthPanel('lineWidth')">
            <div class="lineitem" :style="{'padding-top': lineWidth()+'px', 'background-color': 'rgba(232,232,232,0.53)'}"></div>
          </button>
        </div>
        <div v-if="extendPoint('P2')>=0" class="vl"></div>
        <div v-if="extendPoint('P2')>=0" id="panel-button" class="no-drag" @mousedown="dragMouseDownNo">
          <button class="button button1"  @click="setExtendPoints('P2')"  :style="{'filter':getBrightness(),color: 'rgb(232,232,232)'}"><i class="fa fa-long-arrow-left"></i></button>
        </div>
        <div v-if="extendPoint('P1')>=0" class="vl"></div>
        <div v-if="extendPoint('P1')>=0" id="panel-button" class="no-drag" @mousedown="dragMouseDownNo">
          <button class="button button1" @click="setExtendPoints('P1')"  :style="{'filter':getBrightness(),color: 'rgb(232,232,232)'}"><i class="fa fa-long-arrow-right"></i></button>
        </div>
        <div v-if="getCheckValues().length>0" class="vl"></div>
        <div v-if="getCheckValues().length>0" id="panel-button" class="no-drag" @mousedown="dragMouseDownNo">
          <button class="button button1" :style="{'filter':getBrightness()}" @click="setShowPanel('checked')"><i class="fa fa-check-square-o"></i></button>
        </div>
        <div v-for="col in colors()" :key="col.key" id="color-button-group">
          <div class="vl"></div>
          <div id="color-button" class="no-drag" @mousedown="dragMouseDownNo">
            <button class="button button1 colorbutton" @click="setShowColorPanel(col.key, col.caption, col.hex)"  :style="{'background-color':col.hex}" ></button>
          </div>
        </div>
        <div v-for="param in paramters()" :key="param.key" id="color-button-group">
          <div class="vl"></div>
          <div class="no-drag" @mousedown="dragMouseDownNo">
            <input class="panel-parameter" type="number" @input="parameterValueChanged(param.key,param.value,$event)" :id="param.key" :name="param.key" :value="param.value">
          </div>
        </div>
        <div v-if="hasText()" class="vl"></div>
        <div v-if="hasText()" id="panel-button"  class="no-drag" @mousedown="dragMouseDownNo">
          <button class="button button1" :style="{'filter':getBrightness()}" @click="setShowPanel('text')"><i class="fa fa-font"></i></button>
        </div>
        <div class="vl"></div>
        <div id="panel-button"  class="no-drag" @mousedown="dragMouseDownNo">
          <button class="button button1" :style="{'filter':getBrightness()}" @click="cloneTool()"><i class="fa fa-clone"></i></button>
        </div>
        <div v-if="chartHandler.isAdmin" class="vl"></div>
        <div v-if="chartHandler.isAdmin" id="panel-button" class="no-drag" @mousedown="dragMouseDownNo">
          <button class="button button1" :style="{'filter':getBrightness()}" @click="setShowPanel('animation')"><i class="fa fa-play"></i></button>
        </div>
        <div class="vl"></div>
        <div id="panel-button" class="no-drag" @mousedown="dragMouseDownNo">
          <button class="button button1" :style="{'filter':getBrightness()}" @click="deleteTool()"><i class="fa fa-trash"></i></button>
        </div>
        <div class="vl"></div>
        <div id="panel-button" class="no-drag" @mousedown="dragMouseDownNo">

          <button class="button button1" :style="{'filter':getBrightness(),'padding':'1px'}" @click="pinModifyPanel()">
            <svg v-if="chartHandler.chartSettings.isPinModifyPanel" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-fill" viewBox="0 0 16 16">
              <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354z"/>
            </svg>
            <svg v-if="!chartHandler.chartSettings.isPinModifyPanel" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pin-angle-fill" viewBox="0 0 16 16">
              <path d="M9.828.722a.5.5 0 0 1 .354.146l4.95 4.95a.5.5 0 0 1 0 .707c-.48.48-1.072.588-1.503.588-.177 0-.335-.018-.46-.039l-3.134 3.134a5.927 5.927 0 0 1 .16 1.013c.046.702-.032 1.687-.72 2.375a.5.5 0 0 1-.707 0l-2.829-2.828-3.182 3.182c-.195.195-1.219.902-1.414.707-.195-.195.512-1.22.707-1.414l3.182-3.182-2.828-2.829a.5.5 0 0 1 0-.707c.688-.688 1.673-.767 2.375-.72a5.922 5.922 0 0 1 1.013.16l3.134-3.133a2.772 2.772 0 0 1-.04-.461c0-.43.108-1.022.589-1.503a.5.5 0 0 1 .353-.146z"/>
            </svg>
          </button>
        </div>
        <div style="margin-right: 0.5em;"></div>
    </div>
      </div>



    <div v-show="isShowColorPanel && indicator" class="modifypanel" id="colormap">
      <div>{{colorCaption}}</div>
      <color-panel v-model="modalColor"></color-panel>
    </div>
    <div v-show="isShowTextPanel && indicator" class="modifypanel row">
      <div class="column">{{textCaption}}</div>
      <div class="column">
        <select v-model="fontSize" id="fontSize" name="fontSize">
          <option value="10">10px</option>
          <option value="11">11px</option>
          <option value="12">12px</option>
          <option value="14">14px</option>
          <option value="16">16px</option>
          <option value="18">18px</option>
          <option value="20">20px</option>
          <option value="24">24px</option>
        </select>
      </div>
      <textarea
          :onchange="onchangeText"
          class="nonDeleteShortcut"
          name="text1" v-model="textModel" cols="40" rows="5"></textarea>
    </div>
    <div v-show="isShowLineWidthPanel && indicator" class="modifypanel" id="linewidthpanel">
      <div class="lineitem" @click="setLineWidth(lineWidthKey, 1)">dünn</div>
      <div class="lineitem" @click="setLineWidth(lineWidthKey, 2)">mittel</div>
      <div class="lineitem" @click="setLineWidth(lineWidthKey, 3)">breiter</div>
      <div class="lineitem" @click="setLineWidth(lineWidthKey, 4)">sehr breit</div>
    </div>
    <div v-show="isShowChecksPanel && indicator" class="modifypanel" id="checkedpanel">
      <div class="lineitem lineitemlong" v-for="(setting, index) in getCheckValues()"  @click="setChecked(setting.key, !setting.value)" :key="index" :style="{'color':setting.value?'#dedede':'rgba(222,222,222,0.56)'}">
        <button class="itembutton" :style="{'color':setting.value?'#dedede':'rgba(222,222,222,0.1)'}" @click="setChecked(setting.key, !setting.value)">
          <i class="fa fa-check-square-o" @click="setChecked(setting.key, !setting.value)"></i>
        </button> {{setting.caption}}
      </div>
    </div>
    <div v-show="isShowAnimationpanel && isInstance()" class="modifypanel" id="animationpanel">
      <label>Länge des Videos in Sekunden:</label>
      <input
          class="nonDeleteShortcut"
          name="secondPosToShow" v-model="videoSize" style="margin-left: 1em; width:7em;"/>

      <p style="margin-top: 0.5em;margin-bottom: 0.2em;">für die Animation verfügbar:</p>
      <DraggableTimeLine v-if="!getIsAnimationLabel()" @onAnimationInfo="onAnimationInfo" :caption="'markiertes Zeichentool'" :selectedLabelId="false" :videoLength="getVideoLength()" :labelId="getLabelId()" :currentSecond="0" :videoSize="1" @onchangeStartSec="onchangeStartSec">
      </DraggableTimeLine>
      <DraggableTimeLine @onAnimationInfo="addZoom" :caption="'aktueller Zoombereich'" :selectedLabelId="false" :videoLength="getVideoLength()" :labelId="null" :currentSecond="0" :videoSize="1"  @onchangeStartSec="onchangeStartSec">
      </DraggableTimeLine>

      <p style="margin-top: 1.2em;margin-bottom: 0.2em;">aktuelle Animation:</p>
      <DraggableTimeLine v-for="(setting, index) in animationLabels" @switchTool="switchTool" :caption="setting.type || setting.action.type" @onAnimationInfo="onAnimationInfo" :selectedLabelId="(setting.action.type=='zoom') || (setting.action.labelId==getLabelId())" :key="index" :videoSize="setting.videoSize" :labelId="setting.action.labelId" :currentSecond="setting.startSec" @onchangeStartSec="onchangeStartSec">
      </DraggableTimeLine>
    </div>
    <div v-show="isShowSaveAsPanel && indicator" class="modifypanel" id="saveaspanel">
      <div class="lineitem" @click="saveAsDialog()">Zeichenelement speichern ...</div>
      <!--div class="lineitem" @click="saveAsDefault()">als Standardvorlage speichern</div-->
      <div class="lineitem" style="padding: 0.5px; background-color: rgba(232,232,232,0.53);"></div>
      <div v-for="setting in settings()" :key="setting.idToolSettings" id="color-button-group">
        <div v-if="setting.idToolSettings>0" class="lineitem" @click="loadSetting(setting.idToolSettings,$event)" style="margin-right: 3.5em;" >{{setting.settingName}}
          <i v-on:click="toogleFavorites(setting.settingName, $event)" :style="{float: 'right', width: '15px', 'margin-right': '-0.1em','margin-top': '0.1em','font-size': 'large','color': '#b4b4b4'}" class="fa fa-star-o"></i>
          <i v-on:click="saveAsIcon(setting.idToolSettings)" :style="{float: 'right', width: '15px', 'margin-right': '-2.1em','margin-top': '0.1em','font-size': 'large','color': '#b4b4b4'}" class="fa fa-save"></i>
          <i v-on:click="delToolSetting(setting.idToolSettings)" :style="{float: 'right', width: '15px', 'margin-right': '-3.4em','margin-top': '0.1em','font-size': 'large','color': setting.userId>0 ? '#b4b4b4' : 'rgba(180,180,180,0.2)'}" class="fa fa-trash"></i>
        </div>
        <div v-if="setting.idToolSettings<=0" style="margin-right: 1.8em;" class="lineitemunlink">{{setting.settingName}}
          <i v-on:click="saveAsDefault()" :style="{float: 'right', width: '15px', 'margin-right': '-1.3em','margin-top': '0.1em','font-size': 'large','color': '#b4b4b4'}" class="fa fa-save"></i>
        </div>
      </div>
    </div>
    <SaveDialog v-if="saveDialogVisible" :text-model="saveDialogInput" @onSaveClick="onSaveClick" @onCancelClick="onCancelClick">
      <template slot="header"  >
        Vorlage eines Zeichentools speichern ...
      </template>
      <template slot="caption"  >
        Bitte geben Sie den Namen der Vorlage an:
      </template>
    </SaveDialog>
  </div>
</template>

<script>
import Vue from "vue";
import SaveDialog from '../components/SaveDialog.vue';
import DraggableTimeLine from '../components/DraggableTimeLine.vue';
import Mouse from '../../lib/stuff/mouse.js'
import {LayoutColors} from "../../utils/LayoutHandler";

export default {
  name: 'TopModifyPanel',
  components: { SaveDialog, DraggableTimeLine},
  props: ["indicator","chartHandler","animationLabels","videoLength"],
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      currentTool: null,
      saveDialogInput: "Name der Vorlage",
      saveDialogVisible: false,
      isShowColorPanel: false,
      isShowAnimationpanel: false,
      isShowTextPanel: false,
      isShowLineWidthPanel: false,
      isShowSaveAsPanel: false,
      isShowChecksPanel: false,
      isVisible : false,
      modalColor: "#00FF00FF",
      colorCaption: "",
      colorKey: "",
      textCaption: "Hinweistext",
      textModel: "",
      fontSize: 10,
      startSec: 0,
      videoSize: 10,
      lineWidthKey: "",
    }
  },
  methods: {
    onSaveClick(inputData) {
      this.saveAs(inputData);
      this.resetPanels();
    },
    onCancelClick() {
      this.resetPanels();
    },
    getColorItem(key) {
      let res = "#ff0000FF";
      this.colors().forEach((col) => {
        if (col.key === key) {
          res = col.hex;
        }
      });
      return res;
    },
    switchTool(labelId) {
      this.$emit('onLabelEvent', {action: {type: "switchTool", labelId: labelId}});
    },
    addZoom() {
      this.$emit('onLabelEvent', {action: {type: "addZoom"}});
    },
    onAnimationInfo(labelId) {
      this.$emit('onAnimationInfo', labelId, this.startSec);
    },
    setColorItem(key, hexValue) {
      if (this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.settings) {
        //this.$props.indicator.indicatorInstance.settings[key] = hexValue;

        //if (this.chartHandler.isPriceLineIndicator(this.$props.indicator.indicatorInstance.id))
          this.chartHandler.changeIndicatorValue(this.$props.indicator.indicatorInstance.id + '#' + key, hexValue);
      }
    },
    setLineWidth(key, px) {
      this.resetPanels();
      if (this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.settings) {
        //this.$props.indicator.indicatorInstance.settings[key] = px;

        //if (this.chartHandler.isPriceLineIndicator(this.$props.indicator.indicatorInstance.id))
          this.chartHandler.changeIndicatorValue(this.$props.indicator.indicatorInstance.id + '#' + key, px);
      }
    },
    deleteTool() {
      this.resetPanels();
      if (this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.settings)
        this.chartHandler.deleteTool(this.$props.indicator.indicatorInstance.settings.$uuid)
    },
    getLabelId() {
      if (this.isInstance())
        return this.indicator.indicatorInstance.labelId;
      return "";
    },
    getIsAnimationLabel() {
      var found = false;
      if (this.isInstance()) {
        if (this.indicator.indicatorInstance.labelId) {
          this.animationLabels.forEach((al) => {
            if (al && al.action && (al.action.labelId ==this.indicator.indicatorInstance.labelId))
              found = true;
          });
        }
      }
      return found;
    },
    getVideoLength() {
      //console.log(this.videoLength);
      return this.videoLength;
    },
    isDark() {
      return this.chartHandler.layoutHandler.isDarkMode();
    },
    getBrightness() {
      return this.isDark()? 'brightness(1.4)':'brightness(.25)';
    },
    setChecked(key, value) {
      //console.log(key, value);

      if (this.$props.indicator && this.$props.indicator.settings && this.$props.indicator.settings.fieldsTemplate && this.$props.indicator.indicatorInstance && this.$props.indicator.settings) {
        //this.$props.indicator.indicatorInstance.settings[key] = !this.$props.indicator.indicatorInstance.settings[key];
        this.chartHandler.changeIndicatorValue(this.$props.indicator.indicatorInstance.id + '#' + key, value);
        /*
        this.$props.indicator.settings.fieldsTemplate.forEach((s) => {
          if (s[0] === "bool") {
            res.push({caption : s[1], key : s[2], value: });
          }
        });*/
      }
    },
    getCheckValues() {
      var res = [];
      if (this.$props.indicator && this.$props.indicator.settings && this.$props.indicator.settings.fieldsTemplate && this.$props.indicator.indicatorInstance && this.$props.indicator.settings) {
        this.$props.indicator.settings.fieldsTemplate.forEach((s) => {
          if (s[0] === "bool") {
            res.push({caption : s[1], key : s[2], value: this.$props.indicator.indicatorInstance.settings[s[2]]});
          }
        });
      }
      return res;
    },
    isInstance() {
      return this.indicator && this.indicator.indicatorInstance;
    },
    pinModifyPanel() {
      this.chartHandler.pinModifyPanel(
          !this.chartHandler.chartSettings.isPinModifyPanel,
          this.$refs.draggableContainer.offsetLeft,
          this.$refs.draggableContainer.offsetTop);
    },
    cloneTool() {
      this.resetPanels();
      if (this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.settings) {
        //console.log(this.$props.indicator.indicatorInstance.id);
        this.chartHandler.duplicateTool(this.$props.indicator.indicatorInstance.id)
      }
    },
    saveAs(name) {
      this.resetPanels();
      if (this.$props.indicator && this.$props.indicator.settings) {
        let cfg = {};
        this.$props.indicator.settings.fieldsTemplate.forEach((s) => {
          cfg[s[2]] = this.$props.indicator.indicatorInstance.settings[s[2]];
        });
        this.chartHandler.saveToolSetting(this.$props.indicator.indicatorInstance.name, name, cfg);
      }
    },
    saveAsDefault() {
      this.resetPanels();
      this.saveAs('default');
    },
    saveAsDialog() {
      this.resetPanels();
      this.saveDialogInput = "";
      this.saveDialogVisible = true;
    },
    toogleFavorites(name, event) {
      console.log(this.currentTool.name,name)
      this.chartHandler.favoritesHandler.toogleToFavorites(this.currentTool.name,name);
      event.stopPropagation();
    },
    saveAsIcon(idToolSettings) {
      this.resetPanels();
      this.chartHandler.settings.tools.forEach((setting, idx) => {
        if (setting.idToolSettings === idToolSettings) {
          this.saveAs(setting.settingName);
        }
      });
    },
    delToolSetting(idToolSettings) {
      //console.log('delToolSetting',idToolSettings);
      this.chartHandler.deleteToolSetting(idToolSettings);
    },
    loadSetting(idToolSettings, event) {
      console.log(event, idToolSettings);
      if (!event || !event.srcElement || ((event.srcElement.className !== "fa fa-save") && (event.srcElement.className !== "fa fa-trash"))) {
        if (this.$props.indicator && this.$props.indicator.indicatorInstance) {
          this.chartHandler.settings.tools.forEach((setting) => {
            //console.log(setting.idToolSettings, idToolSettings,setting);
            if ((setting.idToolSettings === idToolSettings) &&
                (!setting.deleted)) {
              Object.keys(setting.configData).forEach((s) => {
                //console.log(setting.idToolSettings, idToolSettings, s);
                this.$props.indicator.indicatorInstance.settings[s] = setting.configData[s];
                this.chartHandler.changeIndicatorValue(this.$props.indicator.indicatorInstance.id + '#' + s, setting.configData[s], false);

              });
            }
          });
        }
      }
    },
    parameterValueChanged(key, value, event) {
      console.log(key, value, event);
      if (event && event.target && this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.settings) {
        this.$props.indicator.indicatorInstance.settings[event.target.id] = event.target.value;

        //if (this.chartHandler.isPriceLineIndicator(this.$props.indicator.indicatorInstance.id))
        this.chartHandler.changeIndicatorValue(this.$props.indicator.indicatorInstance.id + '#' + event.target.id, event.target.value, true);
      }
    },
    setShowColorPanel(key, caption, hexValue) {
      const orgPanelVisible = this.isShowColorPanel;
      const orgColorKey = this.colorKey;

      this.resetPanels();

      /*Vue.use(Verte, {
        onRecentColorsChange (colors) {
          console.log(colors);
          this.setColorItem(this.colorKey, JSON.stringify(colors));
        }
      });
*/
      if (orgColorKey !== "")
        this.setColorItem(key, hexValue);

      this.modalColor = this.getColorItem(key);
      this.colorKey = key;
      this.colorCaption = caption;
      this.isShowColorPanel = (!orgPanelVisible) || (orgColorKey !== key);
    },
    setShowTextPanel() {
      const orgPanelVisible = this.isShowTextPanel;
      const orgColorKey = this.colorKey;

      this.resetPanels();

      /*Vue.use(Verte, {
        onRecentColorsChange (colors) {
          console.log(colors);
          this.setColorItem(this.colorKey, JSON.stringify(colors));
        }
      });
*/

      this.modalColor = this.getColorItem(key);
      this.colorKey = key;
      this.colorCaption = caption;
      this.isShowColorPanel = (!orgPanelVisible) || (orgColorKey !== key);
    },
    onchangeStartSec(labelId, newStartSec) {
      var found = false;
      if (labelId) {
        this.animationLabels.forEach((la) => {
          console.log(labelId, la.action, newStartSec);
          if (la && la.action && (la.action.labelId == labelId)) {
            la.startSec = newStartSec;
            found = true;
          }
        })
      }

      if (!found)
        this.startSec = newStartSec;

      console.log(labelId, found, newStartSec);
    },
    onchangeVideoSize() {
      this.animationLabels.forEach((la) => {
        la.videoSize = this.videoSize;
      });
    },
    getBackgroundLayoutColor() {
      return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.backgroundMain);
    },
    getFontLayoutColor() {
      return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorMain);
    },
    onchangeText(event) {
      console.log(event);
    },
    setShowPanel(panelName) {
      const orgPanelState = this.isShowSaveAsPanel;
      const orgPanelTextState = this.isShowTextPanel;
      const orgPanelAnimationState = this.isShowAnimationpanel;
      const orgPanelChecks = this.isShowChecksPanel;

      this.resetPanels();
      if (panelName=="animation") {
        this.isShowAnimationpanel = !orgPanelAnimationState;
        const prev = this.videoSize;
        this.$emit('onLabelEvent', {action: {type: "loaded", videoSize: this.videoSize}});
        setTimeout(
        () => {
          this.$emit('onLabelEvent', {action: {type: "loaded", videoSize: prev}})
        }, 50);
        //this.$emit('onLabelEvent', {action: {type: "loaded", videoSize: prev}});
      } else if (panelName=='text') {
        this.textModel = this.$props.indicator.indicatorInstance.settings.text;
        this.fontSize = this.$props.indicator.indicatorInstance.settings.fontSize;
        this.isShowTextPanel = !orgPanelTextState;
      } else if (panelName=='checked')  {
        this.isShowChecksPanel = !orgPanelChecks;
      } else {
        this.isShowSaveAsPanel = !orgPanelState;
      }
    },
    setShowLineWidthPanel(key) {
      console.log('isShowLineWidthPanel');
      this.resetPanels();
      this.lineWidthKey = key;
      this.isShowLineWidthPanel = !this.isShowLineWidthPanel;
    },
    resetPanels() {
      this.isShowTextPanel = false;
      this.isShowSaveAsPanel = false;
      this.isShowColorPanel = false;
      this.isShowLineWidthPanel = false;
      this.saveDialogVisible = false;
      this.isShowAnimationpanel = false;
      this.isShowChecksPanel = false;
    },
    clickColor(hex) {
      console.log(hex);
    },
    dragMouseDownNo: function (event) {
      event.stopPropagation();
      console.log('dragMouseDownNo',event);
    },
    dragMouseDown: function (event) {
      console.log('dragMouseDown',event);
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement() {
      this.chartHandler.pinModifyPanel(
          this.chartHandler.chartSettings.isPinModifyPanel,
          this.$refs.draggableContainer.offsetLeft,
          this.$refs.draggableContainer.offsetTop);
      document.onmouseup = null
      document.onmousemove = null
    },

    settings() {
      let res = [];
      //console.log("get settings");
      let defIdx = -2;
      let bFound = false;
      if (this.$props.indicator && this.$props.indicator.settings && this.$props.indicator.indicatorInstance) {
        this.chartHandler.settings.tools.forEach((setting, idx) => {
          if (setting.toolName === this.$props.indicator.indicatorInstance.name) {
            if (setting.settingName !== 'default')
              res.push(setting);

            if (setting.settingName === 'default') {
              if (setting.userId > 0) {
                let cfg = JSON.parse(JSON.stringify(setting));
                cfg.settingName = "Standardvorlage laden";
                if ((res.length > 0) && (res[0].userId == 0))
                  res.shift();
                res.unshift(cfg);
                defIdx = -1;
                bFound = true;
              } else if (!bFound) {
                defIdx = setting.idToolSettings;
              }
            }
          }
        });
        if (defIdx > 0) {
          res.unshift({settingName: "Standardvorlage laden", idToolSettings: defIdx, userId: 0});
        } else if (defIdx == -2) {
          res.unshift({settingName: "Standardvorlage laden", idToolSettings: 0, userId: 0});
        }
      }
      return res; //[{"key":"id1", "hex" : "#00FF00FF"},
      //{"key":"id2", "hex" : "#003366FF"}];
    },

    lineWidth() {
      let width = 0;
      if (this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.settings) {
        if ("lineWidth" in this.$props.indicator.indicatorInstance.settings) {
          width = this.$props.indicator.indicatorInstance.settings.lineWidth;
        } else {
          this.$props.indicator.settings.fieldsTemplate.forEach((setting) => {
            if (setting[2] == "lineWidth") {
              if (setting[3])
                width = setting[3]
              else
                width = 1
            }
          })
        }
      }
      return width;
    },

    extendPoint(point) {
      let p1 = -1;
      if (this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.settings) {
        //console.log(point, this.$props.indicator.indicatorInstance.settings);
        if ("extend"+point in this.$props.indicator.indicatorInstance.settings) {
          p1 = this.$props.indicator.indicatorInstance.settings["extend" + point] ? 1 : 0;
        }
      }
      return p1;
    },

    setExtendPoints(point) {
      this.resetPanels();
      if (this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.settings) {
        if ("extend"+point in this.$props.indicator.indicatorInstance.settings) {
          this.$props.indicator.indicatorInstance.settings["extend" + point] = !this.$props.indicator.indicatorInstance.settings["extend" + point];
          this.chartHandler.changeIndicatorValue(this.$props.indicator.indicatorInstance.id + '#extend' + point, this.$props.indicator.indicatorInstance.settings["extend" + point], false);
        }
      }
    },
    hasText() {
      if (this.$props.indicator && this.$props.indicator.settings && this.$props.indicator.indicatorInstance) {
        this.textModel = this.$props.indicator.indicatorInstance.settings.text || "";
        return 'text' in this.$props.indicator.indicatorInstance.settings;
      }
      this.textModel = "";
      return false;
    },
    paramters(){
      let res = [];
      if (this.$props.indicator && this.$props.indicator.settings && this.$props.indicator.indicatorInstance) {
        this.$props.indicator.settings.fieldsTemplate.forEach((setting) => {
          if (setting[0] == 'integer') {
            let value = 5;
            if (setting[2] in this.$props.indicator.indicatorInstance.settings) {
              value = this.$props.indicator.indicatorInstance.settings[setting[2]];
            } else if ((this.$props.indicator.settings.settings) && (setting[2] in this.$props.indicator.settings.settings)) {
              value = this.$props.indicator.settings.settings[setting[2]];
            } else if (setting.length > 3) {
              value = setting[3];
            }

            res.push({"key": setting[2], "caption": setting[1], "value": value})
          }
        });
      }
      return res;
    },
    colors() {
      let res = []
      if (this.$props.indicator && this.$props.indicator.settings && this.$props.indicator.indicatorInstance) {
        //this.isVisible = true;
        //this.startSec = this.$props.indicator.indicatorInstance.startSec || 0;
        this.$props.indicator.settings.fieldsTemplate.forEach((setting) => {
          if (setting[0] == 'color') {
            let value = "#fff";

            if (setting[2] in this.$props.indicator.indicatorInstance.settings) {
              value = this.$props.indicator.indicatorInstance.settings[setting[2]];
            } else if ((this.$props.indicator.settings.settings) && (setting[2] in this.$props.indicator.settings.settings)) {
              value = this.$props.indicator.settings.settings[setting[2]];
            } else if (setting.length > 3) {
              value = setting[3];
            }

            res.push({"key": setting[2], "caption": setting[1], "hex": value})
          }
        });
      } /* else {
        this.isVisible = false;
        this.resetPanels();
      }*/
      return res; //[{"key":"id1", "hex" : "#00FF00FF"},
      //{"key":"id2", "hex" : "#003366FF"}];
    },
    panelVisible() {
      return this.$props.indicator && this.$props.indicator.indicatorInstance;
    },
    setVisible(x,y) {;
      console.log("isVisible xy",)
      if (y > this.$parent.height) {
        this.$refs.draggableContainer.style.top = (this.$parent.height-20) + 'px';
      } else {
        this.$refs.draggableContainer.style.top = y + 'px';
      }
      if (x+this.$refs.draggableContainer.offsetWidth > this.$parent.width) {
        this.$refs.draggableContainer.style.left = (this.$parent.width-this.$refs.draggableContainer.offsetWidth-30) + 'px';
      } else {
        this.$refs.draggableContainer.style.left = x + 'px';
      }
    }
  },

  watch: {
    "videoSize"(newValue) {
      this.onchangeVideoSize();
    },
    "modalColor"(newValue) {
      this.setColorItem(this.colorKey,newValue);
    },
    "textModel"(newValue) {
      if (this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.indicatorInstance.settings)
        this.$props.indicator.indicatorInstance.settings.text = newValue;
    },
    "startSec"(newValue) {
      if (this.$props.indicator && this.$props.indicator.indicatorInstance) {
        this.$props.indicator.indicatorInstance.startSec = parseInt(newValue);
        this.$props.indicator.indicatorInstance.type = this.$props.indicator.indicatorInstance.name;
        this.$emit('onAnimationInfo', this.$props.indicator.indicatorInstance.labelId, this.startSec);
      }
    },
    "fontSize"(newValue) {
      if (this.$props.indicator && this.$props.indicator.indicatorInstance && this.$props.indicator.indicatorInstance.settings && this.$props.indicator.indicatorInstance.settings.fontSize)
        this.$props.indicator.indicatorInstance.settings.fontSize = newValue;

    },

    "indicator.indicatorInstance"(newValue) {
      //console.log("indicator.indicatorInstance", newValue)
      if (this.currentTool !== newValue) {
        if (newValue) {
          this.isVisible = true;

          if (newValue && !window.chartHandler.chartSettings.isPinModifyPanel && this.$props.indicator.indicatorInstance && this.$props.indicator.indicatorInstance.settings && this.$props.indicator.indicatorInstance.settings.p1) {
            const toolPos = window.chartHandler.getToolPos(this.$props.indicator.indicatorInstance.id);
            if ((toolPos.length > 1) && (toolPos[0]) && (toolPos[1])) {
              this.setVisible(toolPos[0] + 40, toolPos[1] + 60);
            }
          } else {
            this.setVisible(this.chartHandler.chartSettings.pinModifyPanelPos[0], this.chartHandler.chartSettings.pinModifyPanelPos[1]);
          }
        } else {
          this.isVisible = false;
          this.resetPanels();
        }
      }
      this.currentTool = newValue;
    },
    "isVisible"(newValue) {
/*
      console.log("isVisible",this.$props.indicator, newValue);

      if (newValue && !window.chartHandler.chartSettings.isPinModifyPanel && this.$props.indicator.indicatorInstance && this.$props.indicator.indicatorInstance.settings && this.$props.indicator.indicatorInstance.settings.p1) {
        const toolPos = window.chartHandler.getToolPos(this.$props.indicator.indicatorInstance.id);
        if ((toolPos.length>1) && (toolPos[0]) && (toolPos[1])) {
          this.setVisible(toolPos[0]+40,toolPos[1]+60);
        }
      } else {
        this.setVisible(this.chartHandler.chartSettings.pinModifyPanelPos[0],this.chartHandler.chartSettings.pinModifyPanelPos[1]);
      }*/
      //console.log("isVisible",this.$props.indicator.indicatorInstance,window.chartHandler.chartSettings.isPinModifyPanel, newValue);
    },
  },
  computed: {
  }
}
</script>

<style scoped>

.fa {
  font-size: large;
  padding-top: 0.1em;
}
.fcolorpicker-curbox {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

#draggable-container {
  display: block;
  position: absolute;
  z-index: 90;
  top: 20em;
  left: 30em;
}
#panel-container {
  display: flex;
  padding-left: 0.5em;
  padding-top: 0.2em;
  _color: #d9d9d9;
  _background-color: rgb(37, 41, 42);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  top:25%;
  left:45%;
  box-shadow: 0px 0px 4px #4b4b4b;
}
#draggable-header {
  cursor:grab;
  z-index: 91;
  padding-left: 0.2em;
  padding-right: 0.2em;
  padding-bottom: 0.2em;
  padding-top: 0.1em;
}

.no-drag {
  cursor:default;
  z-index: 92;
  font-size: small;
}

panel-button {
  z-index: 91;
  padding-right: 1em;
  padding-bottom: 0.1em;
}

#color-button-group {
  display: flex;
}

color-button {
  z-index: 91;
}

.button:hover {
  background-color: #3b3b3b;
  border: 1px solid #b9b9b9;
}

.button:focus {
  border: 0px solid #b9b9b9;
  outline:0;
}

button:focus {
  border: 1px solid #b9b9b900;
  outline:1px;
}

.vl {
  border-left: 1px solid rgba(185, 185, 185, 0.49);
  margin: 0.25em;
  margin-top: 0.2em;
  height: 2.0em;
}

.button {
  background-color: #00000000;
  border: 0.1px solid rgba(222, 222, 222, 0.29);
  color: #dedede;
  border-radius: 2px;
  box-shadow_: rgba(117, 119, 119, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember",sans-serif;
  font-size: 0.8em;
  line-height: 2em;
  padding: 2px 2px 2px 2px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  border-left_: 1px solid #b9b9b9;
  margin-top: 0.35em;
  width: 2.4em;
  height: 2.4em;
}

.itembutton {
  background-color: #00000000;
  border: 0.1px solid rgba(222, 222, 222, 0.0);
  color: #dedede;
  border-radius: 2px;
  box-shadow_: rgba(117, 119, 119, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember",sans-serif;
  font-size: 0.8em;
  line-height: 2em;
  padding: 1px 1px 1px 1px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  border-left_: 1px solid #b9b9b9;
  margin-top_: 0.35em;
  width: 2.4em;
  height: 2.4em;
}

.panel-parameter {
  margin-top: 0.3em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  width: 3em;
  text-align: center;
}

.button1 {
  border-radius: 4px;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.selbutton {
  margin: 0.05em;
}

.colorbutton {
  background-color: #0dca62;

}
.colorbutton:hover {
  background-color: #0dca62;
  color: #dedede;
}

#linewidthpanel {
  width:10em;
  margin-left: 1em;
}

#checkedpanel {
  width:21em;
  margin-left: 1em;
}

.lineitem {
  padding-left: 0.5em;
  cursor: pointer;
  width:100%;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.lineitemlong {
  width:20em;
}

.lineitem:hover {
  background-color: #1F4661;
}

.lineitemunlink {
  padding-left: 0.5em;
  cursor: default;
  width:100%;
  margin: 0.2em;
  color: rgba(222,222,222,0.41);
}

saveaspanel {
  margin-right: 0.2em;

}

.modifypanel {
  margin-top: 0.5em;
  _background-color: rgb(37, 41, 42);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  _color: #dedede;
  padding: 0.5em;
}

.row {
  display: flex;
  width:18em;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 50%;/* Should be removed. Only for demonstration */
}
#fontSize {
  width:100%;
}
#colormap {
  display: flex;
  flex-wrap: wrap;
  width: 19em;
}

</style>

