<template>
    <div>
        <Dropdown :chart-handler="chartHandler" @item-selected="$emit('item-selected', $event)" @item-delete="on_item_delete" :items="templates" :title="'Templates'" :hasDelete="true" :nameKey="'NAME'">
            <div class="btn-wrapper">
                <div @click="save" class="btn btn-primary hide_on_detach">Save</div>
                <div @click="open_modal" class="btn btn-ghost btn-primary hide_on_detach">Save As</div>
            </div>
        </Dropdown>
        <div class="modal" v-if="modelOpen">
            <div class="modal_header">
                <h3>Save Template</h3>
                <span @click="modelOpen = false" class="close-icon" style="width: 18px; height: 18px"></span>
            </div>
            <div class="modal_content">
                <form ref="form" @submit="submit">
                    <div class="akj-input">
                        <input type="text" v-model="templateName" placeholder="Enter Template Name" required />
                    </div>
                  <div class="akj-input-check"><input v-model="makeDefault" type="checkbox" />Set as Default</div>
                </form>
            </div>
            <div class="modal_footer btn-wrapper">
                <div @click="modelOpen = false" class="btn btn-primary btn-ghost">
                    Cancel
                </div>
                <div @click="submit" class="btn btn-primary">Save</div>
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";
//import akjStuff from "@/helpers/akjStuff.js";
//import API from "@/helpers/fb.js";

export default {
    name: "IndicatorTemplates",
    props: ["templates","chartHandler"],
    components: { Dropdown },

    created() {
        //API.init();
    },

    methods: {
        submit(e) {
            e.preventDefault();

            if (this.templateName.trim() == "") return;

            const template = {
                NAME: this.templateName.trim(),
                //TEMPLATE_DATA: JSON.stringify(akjStuff.getTemplate()), //SYMBOL
                IS_DEFAULT: this.makeDefault,
            };

            const existingId = this.templates.filter((a) => a.NAME.toLowerCase() == this.templateName.trim().toLowerCase());

            if (existingId.length == 0) {
                // create new template
                /*API.dbIndicatorTemplate.create(template, (result) => {
                    if (result.success)
                        this.templates.unshift({
                            ID_TEMPLATE: result.createdId,
                            ...template,
                        });
                });*/
            } else {
                // update existing template
                /*API.dbIndicatorTemplate.update(
                    existingId[0].ID_TEMPLATE,
                    {
                        TEMPLATE_DATA: template.TEMPLATE_DATA,
                        IS_DEFAULT: template.IS_DEFAULT,
                    },
                    (result) => {
                        // console.log(result);
                    }
                );*/
            }

            // Empty input field
            this.templateName = "";
            this.makeDefault = false;

            // close the modal
            this.modelOpen = false;
        },

        save() {
          console.log('showmodal');
          this.open_modal();
          return;
        },

        on_item_delete(item) {
          console.log('del template',item);
          this.$parent.chartHandler.deleteTemplate('template',item.ID_TEMPLATE);
            //API.dbIndicatorTemplate.delete(item.ID_TEMPLATE);
        },

        open_modal() {
            // hide if there's any modal
          console.log('showmodal');
          this.$emit("saveChart_selected", 'template');
        },
    },

    computed: {
        getTemplates() {
            return this.items;
        },
    },

    data() {
        return {
            templateName: "",
            makeDefault: false,
            modelOpen: true,
        };
    },
};
</script>

<style scoped>
dropdown {
  position: relative;
  color: #fff;
  font-size: 13px;
  display: flex;
  align-items: center;
  user-select: none;
}
dropdown-list {
  position: absolute;
  min-width: 200px;
  top: 42px;
  background-color: #12151b;
  box-shadow: 0px 1px 6px 1px #00000080;
  z-index: 1000;
}
.menu-item {
  padding: 10px 10px;
  height: 34px;
  display: flex;
  align-items: center;
}

.menu-item .item-name {
  padding: 0px 0px;
  top: -5px;
  left: 5px;
  float: left;
  position: relative;
}

.menu-item .svg_item {
  padding: 0px 0px;
  top: -3px;
  left: -5px;
  float: left;
  position: relative;
}

.menu-item .icon-delete {
  cursor: pointer;
  display: none;
}
.tb-dropdown + .menu-item {
  padding: 0px 0px;
}
.menu-item:hover,
.menu-item.active {
  background-color: #1b222f;
}
.menu-item:hover .icon-delete {
  display: block;
}
.icon-delete:hover {
  filter: brightness(1.45) sepia(1) hue-rotate(310deg) saturate(4.5) !important;
}
.menu-item.active #icon {
  filter: brightness(1.45) sepia(1) hue-rotate(90deg) saturate(4.5) !important;
}
.menu-item svg {
  margin-left: 10px;
}

/*==== Modal style ====*/
 .modal {
   position: fixed;
   min-width: 400px;
   border-radius: 8px;
   background-color: #0c0f15;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 1000;
 }
.modal_header {
  padding: 12px;
  border-bottom: 1px solid #414953;
}
.modal_header h3 {
  font-size: 16px;
  margin: 0;
}
.modal_content {
  padding: 12px;
  max-height: 60vh;
  overflow-y: scroll;
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 12px;
  border-top: 1px solid #414953;
}
.modal_footer .btn {
  margin-left: 12px;
}
</style>