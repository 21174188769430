<template>
  <div class="chart-box">
    <trading-vue ref="tvjs" :id="id" :data="dc" :extensions="ext" :titleTxt = "title()" :index="index" :tf="timeframe()"
                 v-on:legend-button-click="on_button_click"
                 v-on:custom-event="custom_event"
                 v-on:range-changed="rangeChanged"
                 v-on:grid-doubletap="on_grid_doubletap" v-on:grid-tap="on_grid_tap" :timezone="timezone()" v-on:click="on_click"
                 :width="vue_chart_width()" :height="height" :overlays="ov" :indexBased="true" :toolbar="false"
                 :color-title="colors.colorTitle" :legend-buttons="['settings','remove']"
                 :color-back="backgroundColor()"
                 :color-grid="colorGrid()"
                 :color-text="colors.colorText"
                 :color-candle-up="colors.colorCandleUp"
                 :color-candle-dw="colors.colorCandleDw"
                 :color-wick-up="colors.colorWickUp"
                 :color-wick-down="colors.colorWickDw"
                 :color-vol-dw="colors.colorVolDw"
                 :color-vol-up="colors.colorVolUp"
                 :color-text-h-l="colors.colorCandleUpText"
                 :chartConfig="chartConfig()">
    </trading-vue>
    <fib-keys-table v-if="!isApp && chartHandler.chartSettings.isAutoFibs && chartHandler.isAdmin" :fibData="fibData" :id="id" :chartHandler="chartHandler" :style="{bottom:fibheight1()+'px','z-index':1000,}"/>
    <tf-selector v-if="!isApp" :defaultTf="timeframe()" :tfReset="tfReset" v-on:selected="timeframeChanged" :style="{right:getRightOffset+'px','z-index':1000,}"> </tf-selector>
  </div>
</template>

<script>
import CRV from "../overlays/CRV.vue";
import LineTool from "../overlays/LineTool.vue";
import HorizontalLine from "../overlays/HorizontalLine.vue";
import VerticalLine from "../overlays/VerticalLine.vue";
import TradingVue from '../lib/TradingVue.vue'
import TfSelector from "./components/TFSelector.vue";
import Overlays from 'tvjs-overlays';
import Rainbow from '../overlays/Rainbow.vue';
import AutoLines from '../overlays/AutoLines.vue';
import AutoTrend from '../overlays/AutoTrend.vue';
import GhostFeed from '../overlays/GhostFeed.vue';
import pattern_XABCD from '../overlays/patterns/XABCD.vue';
import pattern_ABCD from '../overlays/patterns/ABCD.vue';
import AUTOFIB from '../overlays/AUTOFIB.vue';
import ftWiliamsR from '../overlays/indicators/WilliamsR.vue';
import ftTEMA from '../overlays/indicators/TEMA.vue';
import ftMACD from '../overlays/indicators/MACD.vue';
import ftStochastic from '../overlays/indicators/Stochastic.vue';
import TXT from '../overlays/TEXT.vue';
import FIB from '../overlays/FIB.vue';
import ZoomTool from '../overlays/ZoomTool.vue';
import AutoOverlay from '../overlays/AutoOverlay.vue';
import XP from 'tvjs-xp'
import FibKeysTable from "./components/FibKeysTable.vue";
import TrendChannel from "../overlays/TrendChannel.vue";
import MarkerInfo from "../overlays/MarkerInfo.vue";
import Rectangle from "../overlays/Rectangle.vue";
import RangeTool from "../overlays/RangeTool.vue";
import DrawingLines from "../overlays/Drawing.vue";
import PriceGap from "../overlays/PriceGap.vue";
import AUTOHORI from "../overlays/AUTOHORI.vue";
import elliot_12345 from "../overlays/patterns/Elliot_12345.vue";
import pattern_SKS from "../overlays/patterns/SKS.vue";
import {LayoutColors} from "../utils/LayoutHandler.js";


require("../utils/TradingVueModifies")

export default {
  name: 'Chartbox',
  props: ['id', 'data', 'width', 'height', 'theme', 'index', 'symbol', 'onchart', 'chartHandler', 'fibData', 'tool'],
  components: {
    TradingVue, TfSelector, FibKeysTable
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.chartHandler.setChart(this, this.id);
    this.updateFibPos();
    this.updateFibHeight();
  },
  methods: {
    vue_chart_width () {
      this.updateFibHeight();
        return this.$props.width-5;
    },
    onResize() {
      this.updateFibHeight();
      this.updateFibPos();
    },
    onrange(r) {
      //this.updateFibHeight();
      //console.log('range',r);
      if (r && this.data.data.chart.data.length && r[0] < this.data.data.chart.data[0][0]) {
        this.chartHandler.loadMoreHistoryData(this.timeframe(), r[0]);
        //console.log(this.data.data.chart.data[0]);
      }
    },
    timeframeChanged(tf) {
      //console.log(this.id);
      this.chartHandler.saveConfig('chart', false);
      this.chartHandler.setActiveChart(this.id);
      this.$emit("timeframe-changed", { ...tf, chartId: this.id });
      //console.log(this.$props.index);
    },
    on_grid_tap(event) {
      //console.log(this.id);
      this.$props.chartHandler.setActiveChart(this.id);
      this.updateFibHeight();
    },
    custom_event(event) {
      console.log('chartbox - custom_event',event)
    },
    rangeChanged(event) {
      //console.log('chartbox - rangeChanged',event)
      if (event && (event.length == 2) && this.$props.chartHandler.isSendLiveChart()) {
        this.$props.chartHandler.setLiveChartZoom(event[0],event[1])
      }
    },
    on_grid_doubletap(event) {
      this.$emit("on_grid_doubletap", { ...event, chartId: this.id });
    },

    onSelectTool(ind) {
      this.$emit("onSelectTool", { ...ind });
    },
    title() {
      if (this.isApp)
        return "";
      if (this.$refs && this.$refs.tvjs && this.$refs.tvjs.$refs.chart._layout.grids[0].height > 200)
        return (this.$props.chartHandler.layoutHandler.isFirst(this.$props.id) ? this.$props.symbol + ' - ' : '') + this.timeframe()
      else
        return (this.$props.chartHandler.layoutHandler.isFirst(this.$props.id) ? this.$props.symbol + ' - ' : '') + this.timeframe()
    },
    backgroundColor(){
      return this.$props.chartHandler.layoutHandler.getLayoutColor(LayoutColors.backgroundMain);
    },
    colorGrid(){
      return this.$props.chartHandler.chartSettings.isGrid ? this.$props.chartHandler.currentTheme.colorGrid : '#0000' ;
    },

    timezone(){
      return 'timezone' in this.$props.chartHandler.chartSettings ? parseFloat(this.$props.chartHandler.chartSettings.timezone) : 2 ;
    },
    select_tool(toolOrg, settings=null) {
      const tool = toolOrg.id || toolOrg.name || (toolOrg.hint ? toolOrg.hint.replace(' ','') : toolOrg.hint);
      const types = {TXT:'Segment', TrendChannel:'Segment', MarkerInfo:'Segment', GhostFeed:'Segment', DrawingLines:'Segment',  Rectangle:'Price', ZoomTool:'Price', FIB:'Price', RangeTool:'Price', LineTool:'Segment', CRV: 'Segment', HorizontalLine:'Segment', VerticalLine:'Segment',
        pattern_XABCD: 'Segment', pattern_ABCD: 'Segment', elliot_12345: 'Segment',pattern_SKS:'Segment'};
      console.log('sel_tool', tool, types[tool]);
      const type = ((tool == 'LineTool') && (toolOrg.settings.extended)) ? 'Extended' : types[tool];
      this.$refs.tvjs.custom_event( {
        event:'tool-selected', args: [tool+':'+type, window.defaultToolSettings]
      });
      this.onSelectTool(toolOrg);
      this.updateFibHeight();
    },

    on_button_click(event) {
      //console.log('sel_tool',event);
      if (event.button === 'remove') {
        this.$emit("remove-indicator", { ...event, chartId: this.id });
        event.button = '';
      }
      if (event.button === 'settings') {
        this.$emit("modify-indicator", { ...event, chartId: this.id });
        event.button = '';
      }
      this.updateFibHeight();
    },

    on_click() {
      this.updateFibHeight();
    },

    updateFibPos() {
      this.updateFibHeight();
      //const ele = document.getElementById('0-grid-0-canvas');
      //console.log(ele, ele.getAttribute('height'), this.height, ele.style.height.replace('px',''));
      //if (ele)
      //  this.fibheight = this.height - parseInt(ele.style.height.replace('px','')) +10;
      //this.$refs.tvjs.resetChart(false);
    },

    updateFibHeight() {
      //let h = this.$refs.tvjs ? this.$refs.tvjs.$refs.chart._layout.grids[0].height : 10;

      //const ele = document.getElementById('0-grid-0-canvas');
      //if (ele)
      //  h = this.height - parseInt(ele.style.height.replace('px','')) +10;
      //else
      //  h = this.height + 10;
      if (this.$refs.tvjs) {
        //console.log('updateFibHeight');
        this.$props.chartHandler.charts[this.$props.id].fibHeight = this.$refs.tvjs.$refs.chart._layout.grids[0].height+1;
        this.$set(this, 'fibheight', this.$refs.tvjs.$refs.chart._layout.grids[0].height)
        //this.fibheight = this.$refs.tvjs.$refs.chart._layout.grids[0].height;
        //this.$props.chartHandler.charts[this.$props.id].fibHeight = this.$refs.tvjs.$refs.chart._layout.grids[0].height;
      }
    },

    fibheight1() {
      return this.height - this.$props.chartHandler.charts[this.$props.id].fibHeight + 15;
    },
    chartConfig() {
      //console.log('chartConfig', this.$props.chartHandler.chartSettings.defaultLen);
      return {DEFAULT_LEN:this.$props.chartHandler.getLength() || 100, logScale: this.$props.chartHandler.chartSettings.logScale};
    },
    timeframe() {
      //console.log(this.$props.chartHandler.charts[this.$props.id]);
      return this.$props.chartHandler.charts[this.$props.id].tf;
    },
    indexBased() {
      return false; //this.$props.chartHandler.chartSettings.isIndexBased;
    }
    /*currentTool() {
      console.log(this.$refs.tvjs);
    }*/
  },
  computed: {
    /*
    chart() {
      return {
        //ohlcv: this.$props.data,
        //onchart: this.onchart
      };
    },*/

    showToolbar(){
      return this.$props.chartHandler.layoutHandler.isFirst(this.$props.id);
    },
    ov() {
      return Object.values(Overlays).concat(ZoomTool).concat(LineTool).concat(VerticalLine).concat(HorizontalLine).concat(HorizontalLine).concat(pattern_ABCD).concat(pattern_XABCD).concat(pattern_SKS).concat(elliot_12345).concat(DrawingLines).concat(CRV).concat(TrendChannel).concat(Rectangle).concat(TXT).concat(RangeTool).concat(FIB).concat(MarkerInfo).concat(PriceGap).concat(AUTOHORI).concat(AutoOverlay).concat(AutoLines).concat(AutoTrend).concat(Rainbow).concat(AUTOFIB).concat(GhostFeed)
          .concat(ftWiliamsR).concat(ftTEMA).concat(ftMACD).concat(ftStochastic);
    },

    /*colors() {
      return this.$props.night ? {} : {
        colorBack: '#1b1f2c',
        colorText: '#eee',
        colorTitle: '#eee'
      }
    },*/
  },
  data() {
    //console.log(this.$props.data);
    return {
      tfReset: 0,
      chartWidth: 100,
      fibheight: 20,
      getRightOffset: 100,
      ext: Object.values(XP),
      dc: this.$props.data,
      currentTool: "RangeTool:PriceTime",
      height1 : 0,
      colors: this.chartHandler.currentTheme,
      isApp: this.chartHandler.isApp,
    }
  }
};
</script>

<style scoped>
.chart-box {
  z-index: 1;
  width: 100%;
  border-left: 0.5px solid black;
 }

.btn_detach {
  position: relative;
  position: absolute;
  bottom: 40px;
  right: 80px;
  border: 0.5px solid transparent;
}

</style>