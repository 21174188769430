<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";
import ChartUtils from "../utils/ChartUtils.js";

export default {
    name: "FIB",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "C451", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                group: "FIB",
                icon: Icons["fib.png"],
                type: "Price",
                name: "FIB",
                hint: "Price Range",
                data: [], // Default data
                settings: {
                    levels: [0, 23.6, 38.2, 50, 61.8, 78.6, 100, 138.2, 161.8, 200, 261.8, -138.2, -161.8, -200, -261.8, -61.8],
                    showPrice: true,
                    showPercentage: true,
                    showBackground: false,
                    showShortLines: false,
                    backColor: "#787b86",
                    backOpacity: 10,
                    color: "#b2b5be",
                    lineWidth: 1,
                    showLevel0: true,
                    showLevel1: false,
                    showLevel2: true,
                    showLevel3: true,
                    showLevel4: true,
                    showLevel5: true,
                    showLevel6: true,
                    showLevel7: false,
                    showLevel8: true,
                    showLevel9: true,
                    showLevel10: false,
                    showLevel11: false,
                    showLevel12: true,
                    showLevel13: false,
                    showLevel14: false,
                    showLevel15: false,
                }, // Default settings
                fieldsTemplate: [
                    ["bool", "Show Price", "showPrice"],
                    ["bool", "Show Percentage", "showPercentage"],
                    ["sep", "sep", "sep"],

                    ["bool", "Show Background", "showBackground", false],
                    ["bool", "Show Line Short", "showShortLines"],
                    ["color", "Back Color", "backColor"],
                    ["integer", "Background Opacity", "backOpacity"],
                    ["sep", "sep", "sep"],
                    ["color", "Line Color", "color"],
                    ["lineWidth", "Line Thickness", "lineWidth"],
                    ["sep", "sep", "sep"],
                    ["bool", "Show Level 0%", "showLevel0"],
                    ["bool", "Show Level 23.6%", "showLevel1"],
                    ["bool", "Show Level 38.2%", "showLevel2"],
                    ["bool", "Show Level 50%", "showLevel3"],
                    ["bool", "Show Level 61.8%", "showLevel4"],
                    ["bool", "Show Level 78.6%", "showLevel5"],
                    ["bool", "Show Level 100%", "showLevel6"],
                    ["bool", "Show Level 138.2%", "showLevel7"],
                    ["bool", "Show Level 161.8%", "showLevel8"],
                    ["bool", "Show Level 200%", "showLevel9"],
                    ["bool", "Show Level 261.8%", "showLevel10"],
                    ["bool", "Show Level -61.8%", "showLevel15"],
                    ["bool", "Show Level -138.2%", "showLevel11"],
                    ["bool", "Show Level -161.8%", "showLevel12"],
                    ["bool", "Show Level -200%", "showLevel13"],
                  ["bool", "Show Level -261.8%", "showLevel14"],
                ],
            };
        },
        // Called after overlay mounted
        init() {
          this.updatePins();
        },
        updatePins() {
          // loading from saved data
          const self = this;
          this.pins.splice(0,this.pins.length);
            if (this.$props.settings.p1) {
              this.$props.settings.$state = "finished";
                this.pins.push(
                    new Pin(this, "p1", {
                        state: "finished",
                        t: this.$props.settings.p1[0],
                        y$: this.$props.settings.p1[1],
                    })
                );
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "finished",
                        t: this.$props.settings.p2[0],
                        y$: this.$props.settings.p2[1],
                    })
                );
            } else {
                // First pin is settled at the mouse position
              self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("FIB", this.id, this.$props.settings);
                this.pins.push(new Pin(this, "p1"));
                // Second one is following mouse until it clicks
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "tracking",
                    })
                );
                this.pins[1].on("settled", () => {
                    // Call when current tool drawing is finished
                    // (Optionally) reset the mode back to 'Cursor'
                    if (self.state !== "finished") {
                      this.set_state("finished");
                      this.$emit("drawing-mode-off");

                      //global drawing mode
                      //window.$store.drawingMode = false;
                      self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("FIB");
                    }
                });
            }
        },
        draw(ctx) {
            if (!this.p1 || !this.p2) return;

            let dir = Math.sign(this.p1[1] - this.p2[1]);

            const layout = this.$props.layout;
            this.x1 = layout.t2screen(this.p2[0]);
            this.y1 = layout.$2screen(this.p2[1]);
            this.x2 = layout.t2screen(this.p1[0]);
            this.y2 = layout.$2screen(this.p1[1]);

            this.nY1 = layout.$2screen(this.p2[1] + ((this.p1[1] - this.p2[1]) * this.levels[10]) / 100);
            this.nY2 = layout.$2screen(this.p2[1] + ((this.p1[1] - this.p2[1]) * this.levels[this.levels.length - 1]) / 100);


            let layoutWidth = layout.width;

            if (this.show_ShortLines) {
              layoutWidth = Math.min(Math.min(this.x1, this.x2)+100, layoutWidth);
            }

            this.nX2 = layout.screen2t(layoutWidth);

            // Background
            if (this.show_background) {
                ctx.fillStyle = this.back_color;
                ctx.fillStyle += ChartUtils.alphaToHex(this.back_opacity);
                ctx.fillRect(Math.min(this.x1, this.x2), this.nY1, this.nX2 - this.x1, this.nY2 - this.nY1);
            }

            this.showPrice = this.$props.settings.showPrice;
            this.showPercentage = this.$props.settings.showPercentage;

            // draw levels
            ctx.lineWidth = this.line_width;
            ctx.strokeStyle = this.color;
            for (let i = 0; i < this.levels.length; i++) {
                // show / hide this level
                if (!this.$props.settings[`showLevel${i}`]) continue;
                ctx.beginPath();
                let nY = this.p2[1] + ((this.p1[1] - this.p2[1]) * this.levels[i]) / 100;

                new Seg(this, ctx).draw([Math.min(this.p2[0], this.p1[0]), nY], [this.nX2, nY]);

                this.draw_level(ctx, this.levels[i], this.x1, layoutWidth+5, layout.$2screen(nY), this.showPrice, this.showPercentage);
                ctx.stroke();
            }

            /* let xm = layout.t2screen((this.p1[0] + this.p2[0]) * 0.5)
            ctx.moveTo(xm - 4, this.y2 + 5 * dir)
            ctx.lineTo(xm, this.y2)
            ctx.lineTo(xm + 4, this.y2 + 5 * dir)
            ctx.stroke() */
            if (this.show_pins)
              ctx.lineWidth = this.line_width+3;
            else
              ctx.lineWidth = this.line_width;

            // Diagonal line
            ctx.beginPath();
            ctx.setLineDash([5, 5]);
            new Seg(this, ctx).draw([this.p2[0], this.p2[1]], [this.p1[0], this.p1[1]]);
            ctx.stroke();
            ctx.setLineDash([]);

            // this.collisions.push( this.make(Math.min(this.x1, this.x2), Math.min(this.y1, this.y2), Math.max(this.x1, this.x2), Math.max(this.y1, this.y2)) )

            this.render_pins(ctx);
        },
        // Collision function. x, y - mouse coord.
        make(x1, y1, x2, y2) {
            return (x, y) => {
                return x > x1 && x < x2 && y > y1 && y < y2;
            };
        },
        draw_level(ctx, p, x1, x2, y, showPrice, showPercentage) {
            let price = "",
                percentage = "";
            ctx.font = this.new_font;
            //ctx.fillStyle = this.value_color;
            ctx.fillStyle = '#e2e2e2'; //this.value_color;
            ctx.textAlign = "center";

            if (showPrice) price = `(${this.layout.screen2$(y).toFixed(2)})`;
            if (showPercentage) percentage = `${p}%`;

            let text = `${percentage} ${price} `;
            let w = ctx.measureText(text).width + 20;
            ctx.fillText(text, x2 - w * 0.5, y - 4);
        },

        dblclick(event, force = false) {
            if (event.defaultPrevented && !force) return;
            let x = this.mouse.x;
            let y = this.mouse.y;
          //if (x > this.x1 && x < this.x2 && y > this.y1 && y < this.y2) console.log("col");
        },

        use_for() {
            return ["FIB"];
        },
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        levels() {
            return this.$props.settings.levels || [0, 23.6, 38.2, 50, 61.8, 78.6, 100, 138.2, 161.8, 200, 261.8, -138.2, -161.8, -200, -261.8, -61.8];
        },
        p1() {
            return this.$props.settings.p1;
        },
        p2() {
            return this.$props.settings.p2;
        },
        line_width() {
            return this.sett.lineWidth || 1;
        },
        color() {
            return this.sett.color || this.$props.colors.colorCross;
        },
        show_background() {
            return this.sett.showBackground == undefined ? true : this.sett.showBackground;
        },
        show_ShortLines() {
          return this.sett.showShortLines == undefined ? false : this.sett.showShortLines;
        },
        back_color() {
            return this.sett.backColor ? this.sett.backColor : "#9b9ba3";
        },
        back_opacity() {
            return this.sett.backOpacity || 10;
        },
        value_color() {
            return this.sett.valueColor || this.$props.colors.colorText;
        },
        new_font() {
            return "1spx " + this.$props.font.split("px").pop();
        },
    },
    data() {
        return {};
    },
};
</script>
