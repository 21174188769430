const IndicatorConfigDefinition = Object.freeze([
    {
        name: ['RSI','EMA','SMA','ATR','RSI','TSI','Williams %R',"TEMA",'MACD',"Stochastic"],
        fieldsTemplate: [["integer", "Period", "length"],
            ["color", "Line Color", "color", "#ff0"],
            ["lineWidth", "Line Thickness", "lineWidth"]
        ],
    },
    {
        name: ['MACD'],
        fieldsTemplate: [["integer", "Fast", "fast", 12],
            ["integer", "Slow", "slow", 26],
            ["integer", "Smooth", "smooth", 9],
            ["lineWidth", "Hist Width", "histWidth", 4],
            ["lineWidth", "MACD Width", "macdWidth", 1],
            ["lineWidth", "Signal Width", "signalWidth", 1],
            ["color", "Line Color", "defColor", "#42b28a"],
            ["color", "MACD Color", "macdColor", "#42b28a"],
            ["color", "Signal Color", "signalColor", "#42b28a"],
            ["color", "Line Color", "histColors", [
                "#35a776", "#79e0b3", "#e54150", "#ea969e"
            ]]
        ],
    },{
        name: ['Momentum'],
        fieldsTemplate: [["integer", "Length", "length", 12],
            ["lineWidth", "Line Width", "lineWidth"],
            ["color", "Line Color", "color", "#bcc427ee"],
        ],
    },
    {
        name: ['TXT'],
        fieldsTemplate: [["color", "Text Color", "color"],
            ["integer", "Font Size", "fontSize"],
            ["text", "Text", "text"]
        ]
    }, {
        name: ['MarkerInfo'],
        fieldsTemplate: [
            ["color", "Text Color", "color"],
            ["integer", "Font Size", "fontSize"],
            ["text", "Text", "text"],
            ["text", "Caption", "caption"],
            ["color", "Back Color", "backColor"],
            ["color", "Header Color", "headColor"],
        ]
    },{
            name: ['AUTO HORI'],
            fieldsTemplate: [
                ["bool", "Show Price", "showPrice"]
            ]
        },{
            name: ['HorizontalLine'],
            fieldsTemplate: [
                ["color", "Color", "color"],
                ["lineWidth", "Line Thickness", "lineWidth"],
                ["options", "Line Type", "lineType"]
            ],
        icon: {
            name: "horizontal.png"
        }
        },{
            name: ['VerticalLine'],
            fieldsTemplate: [
                ["color", "Color", "color"],
                ["lineWidth", "Line Thickness", "lineWidth"]
            ],
        icon: {
                name: "vertical.png"
        }
        },{
        name: ['pattern_ABCD', 'pattern_XABCD', 'elliot_12345', 'pattern_SKS'],
        fieldsTemplate: [
            ["color", "Color", "lineColor"],
            ["lineWidth", "Line Thickness", "lineWidth"],
            ["color", "Back Color", "backColor"],
        ],
        icon : {
            name: "rectangle.png"
        },
        settings: {
            lineColor: "#405d75",
            color: "rgba(61,62,65,0.18)",
        }, // Default settings
    },{
        name: ['elliot_12345'],
        fieldsTemplate: [
            ["color", "Color", "lineColor"],
            ["lineWidth", "Line Thickness", "lineWidth"],
        ],
        icon : {
            name: "rectangle.png"
        },
        settings: {
            lineColor: "#405d75",
            color: "rgba(61,62,65,0.18)",
        }, // Default settings
    },{
        name: ['Rectangle', ],
        fieldsTemplate: [
            ["color", "Color", "color"],
            ["lineWidth", "Line Thickness", "lineWidth"],
            ["color", "Back Color", "backColor"],
        ],
        icon : {
            name: "rectangle.png"
        },
        settings: {
            color: "#788998",
        }, // Default settings
    },{
        name: ['TrendChannel'],
        fieldsTemplate: [
            ["color", "Color", "color"],
            ["lineWidth", "Line Thickness", "lineWidth"],
            ["color", "Back Color", "backColor"],
            ["extend", "Extend", "extendP1"],
            ["extend", "Extend", "extendP2"],
        ],
        settings: {
            color: "#788998",
            extendP1: true,
            extendP2: true,
        }, // Default settings
        },{
            name: ['CRV'],
            settings: {
                lineWidth: 1,
                colorLong: "#28b200",
                backColorLong: "rgba(71,122,81,0.14)",
                colorStop: "rgba(166,0,0,0.83)",
                backColorStop: "rgba(134,7,7,0.09)",
            }, // Default settings
            fieldsTemplate: [
                ["color", "Color", "colorLong"],
                ["color", "Back Color", "backColorLong"],
                ["color", "Color", "colorStop"],
                ["color", "Back Color", "backColorStop"],
                ["lineWidth", "Line Thickness", "lineWidth"],
            ],
        },{
        name: ['GhostFeed'],
        fieldsTemplate: [
            ["color", "Color", "color"],
            ["color", "Backcolor Up", "backColorUp"],
            ["color", "Backcolor Down", "backColorDown"],
            ["color", "Linecolor Up", "lineColorUp"],
            ["color", "Linecolor Down", "lineColorDown"],
            ["color", "Dochtcolor Up", "dochtUp"],
            ["color", "Dochtcolor Down", "dochtDown"]
        ],
        settings: {
            color: "#eecc99",
            backColorUp: "#5ec423",
            backColorDown: "#a80000",
            lineColorUp: "#eaeaea",
            lineColorDown: "#dc570e",
            dochtUp: "#e8e8e8",
            dochtDown: "#3e487a"

        }, // Default settings
    },{
            name: ['RangeTool'],
            fieldsTemplate: [
                ["color", "Color", "color"],
                ["lineWidth", "Line Thickness", "lineWidth"],
                ["color", "Back Color", "backColor"],,
                ["integer", "Background Opacity", "backOpacity"],
                ["color", "Value Color", "valueColor"],
                ["color", "Back Value Color", "valueBack"]
            ]
        },
        {
            name: ['FIB'],
            fieldsTemplate: [
                ["bool", "Show Price", "showPrice"],
                ["bool", "Show Percentage", "showPercentage"],
                ["sep", "sep", "sep"],

                ["bool", "Show Background", "showBackground", false],
                ["bool", "Show Line Short", "showShortLines"],
                ["color", "Back Color", "backColor"],
                ["integer", "Background Opacity", "backOpacity"],
                ["sep", "sep", "sep"],
                ["color", "Line Color", "color"],
                ["lineWidth", "Line Thickness", "lineWidth"],
                ["sep", "sep", "sep"],
                ["bool", "Show Level 0%", "showLevel0"],
                ["bool", "Show Level 23.6%", "showLevel1"],
                ["bool", "Show Level 38.2%", "showLevel2"],
                ["bool", "Show Level 50%", "showLevel3"],
                ["bool", "Show Level 61.8%", "showLevel4"],
                ["bool", "Show Level 78.6%", "showLevel5"],
                ["bool", "Show Level 100%", "showLevel6"],
                ["bool", "Show Level 138.2%", "showLevel7"],
                ["bool", "Show Level 161.8%", "showLevel8"],
                ["bool", "Show Level 200%", "showLevel9"],
                ["bool", "Show Level 261.8%", "showLevel10"],
                ["bool", "Show Level -61.8%", "showLevel15"],
                ["bool", "Show Level -138.2%", "showLevel11"],
                ["bool", "Show Level -161.8%", "showLevel12"],
                ["bool", "Show Level -200%", "showLevel13"],
                ["bool", "Show Level -261.8%", "showLevel14"]
            ]
        },{
            name: ['LineTool'],
            fieldsTemplate: [
                ["color", "Color", "color"],
                ["lineWidth", "Line Thickness", "lineWidth"],
                ["extend", "Extend", "extendP1"],
                ["extend", "Extend", "extendP2"],
            ],
            settings: {
                lineWidth: 1,
                color: "#ff0000",
                extendP1: true,
                extendP2: true,
            }, // Default settings
            icon : {
                data: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAAkhJREFUaAXtmbtOAkEUhtlLAYVW0tgTILa2Fta+g5XhDUiIRgswRsIbaGfiG9hrYktrgFBZa2lB4rL4H5JJZneHxR3mVuwmJzt7Zpj5v7mRnFOplE85A+UM5M2Al1dps242m52sVqsu7Jh0eJ43ho2azea7SJeTIJPJ5BKibwGR0AdfDN91u92+S8P4aYftb1oJCB5A8C/evVqtdkhGZfgiqqM2aZ1h2mH7G2JpO/kQfNNqtYacnuF0Oq2g7h6+LiyxxZxbEQhdn4lqtfrEQayLzMfa8PXOgfDiipSdA8GWGhPAYrE4T4MwH2vD1zt3RoIgeFwul2fYPn06E2w7EQT5ABHDRjwElRPXW7rS9Pd8Pq9HUfSKcQ8gtg7hiR1DEPAJr9/AtNhN43EQR2jziZW5wHsPtg/7AcQbrIOb7BnfmceJFUlBfIRheNpoNL4yanMc1kFUQBCfVRBVEFZBVEJYA1ENYQVEB4RxEF0QRkF0QhgD0Q1hBMQEhHYQUxBaQUxCaAMxDaEFxAaEchBbEEpBbEIoA7ENoQTEBYidQVyB2AnEJQhpENcgpEBchMgFEeUnfN9/QPCMgsgUspGKdtCgOh5h8GFLfoLCNN8yIRsdAKzPRCSPnP/IT9QRPOsVjTuxAXW9M7FfhCS35ifiOO5A0IsuUTL9ZlYEIFL5CZnBVf4mA6Kyc5N9ZUAQKJbKT5gULRorc0YAMkLDwvkJUecmfcLrFwmWK4joF8lPmBQtGksIQg1Ff4i0WpsS9qLOS185A+UMuDMDfyDWs0Dqr+3nAAAAAElFTkSuQmCC"
            }
        },
        {
            name: ['PriceGap'],
            fieldsTemplate: [
                ["integer", "Minimum Gap", "minPriceGap", 0],
                ["integer", "Number of Candles", "numberOfCandles", 1],
                ["color", "Gap Up Color", "gapUpColor"],
                ["integer", "Gap Up Opacity", "gapUpOpacity", 0, 100],
                ["color", "Gap Down Color", "gapDownColor"],
                ["integer", "Gap Down Opacity", "gapDownOpacity", 0, 100],
            ],
            settings: {
                minPriceGap: 0,
                numberOfCandles: 300,
                gapUpColor: "#00ff00",
                gapUpOpacity: 10,
                gapDownColor: "#ff0000",
                gapDownOpacity: 10,
                rerender: 0,
            },
        },
    {
        name: ['Bollinger Band','BB'],
        fieldsTemplate: [["integer", "Period", "length"],
            ["integer", "StdDev", "stddev"],
            ["color", "Line Color", "color"],
            ["color", "Back Color", "backColor"],
            ["lineWidth", "Line Thickness", "lineWidth"],
            ["bool", "Show Middle", "showMid"]
        ]
    },
        {
            name: ['AUTOFIB-X', 'AUTOFIB-2', 'AUTOFIB-1'],
            fieldsTemplate: [
                ["bool", "Show Price", "showPrice"],
                ["bool", "Show Percentage", "showPercentage"],
                ["sep", "sep", "sep"],
                /*["bool", "Show Background", "showBackground", false],
                ["color", "Back Color", "backColor"],
                ["integer", "Background Opacity", "backOpacity"],
                ["sep", "sep", "sep"],
                ["color", "Line Color", "color"],
                ["lineWidth", "Line Thickness", "lineWidth"],
                ["sep", "sep", "sep"],*/
                ["bool", "Show Level 0%", "showLevel0"],
                ["bool", "Show Level 23.6%", "showLevel1"],
                ["bool", "Show Level 38.2%", "showLevel2"],
                ["bool", "Show Level 50%", "showLevel3"],
                ["bool", "Show Level 61.8%", "showLevel4"],
                ["bool", "Show Level 78.6%", "showLevel5"],
                ["bool", "Show Level 100%", "showLevel6"],
                ["bool", "Show Level 138.2%", "showLevel7"],
                ["bool", "Show Level 161.8%", "showLevel8"],
                ["bool", "Show Level 200%", "showLevel9"],
                ["bool", "Show Level 261.8%", "showLevel10"],
                ["bool", "Show Level -138.2%", "showLevel11"],
                ["bool", "Show Level -161.8%", "showLevel12"],
                ["bool", "Show Level -200%", "showLevel13"],
                ["bool", "Show Level -261.8%", "showLevel14"],
            ]
        }
    ]
);
/*
const IndicatorSettings = Object.freeze([
    {
        name: ['RSI'],
        settings: {
            upper: 70,
            lower: 30,
            backColor: "#9b9ba316",
            bandColor: "#666"
        }
    }, {
        name: ['EMA'],
        settings: {
            length: 14
        }
    }, {
        name: ['SMA'],
        settings: {
            length: 14
        }
    }, {
        name: ['TXT'],
        settings: {
            color: "#8091a0",
            fontSize: 18,
            text: "Text1",
        }
    },{
        name: ['MarkerInfo'],
        settings: {
            color: "#7c0742",
            backColor: '#f5ead9',
            headColor: '#614521',
            backOpacity: 10,
            fontSize: 11,
            text: "Text",
            caption: "Hinweis",
        },
    },{
        name: ['AUTO HORI'],
        settings: {
        }
    },{
        name: ['HorizontalLine'],
        settings: {
            lineWidth: 1,
            color: "#00ff00",
            lineType: "Full Line",
        },
    },{
        name: ['VerticalLine'],
        settings: {
            lineWidth: 1,
            color: "#00ff00",
            lineType: "Full Line",
        },
    },{
        name: ['Rectangle'],
        settings: {
            lineWidth: 1,
            color: "#ffb74d",
            backColor: "#ffb74d",
        }, // Default settings

    },{
        name: ['TrendChannel'],
        settings: {
            lineWidth: 1,
            color: "#ffb74d",
            backColor: "#ffb74d",
            extendP1: true,
            extendP2: true,
        }, // Default settings
    },
    {
        name: ['RangeTool'],
        settings: {
            color: "#788998",
        }
    },{
        name: ['GhostFeed'],
        fieldsTemplate: [
                ["color", "Color", "color"],
                ["color", "Backcolor Up", "backColorUp"],
                ["color", "Backcolor Down", "backColorDown"],
                ["color", "Linecolor Up", "lineColorUp"],
                ["color", "Linecolor Down", "lineColorDown"],
                ["color", "Dochtcolor Up", "dochtUp"],
                ["color", "Dochtcolor Down", "dochtDown"]
        ],
        settings: {
            color: "#eecc99",
            backColorUp: "#5ec423",
            backColorDown: "#a80000",
            lineColorUp: "#eaeaea",
            lineColorDown: "#dc570e",
            dochtUp: "#e8e8e8",
            dochtDown: "#3e487a"

        },
    },{
        name: ['FIB'],
            fieldsTemplate: [
                ["bool", "Show Price", "showPrice"],
                ["bool", "Show Percentage", "showPercentage"],
                ["sep", "sep", "sep"],

                ["bool", "Show Background", "showBackground", false],
                ["color", "Back Color", "backColor"],
                ["integer", "Background Opacity", "backOpacity"],
                ["sep", "sep", "sep"],
                ["color", "Line Color", "color"],
                ["lineWidth", "Line Thickness", "lineWidth"],
                ["sep", "sep", "sep"],
                ["bool", "Show Level 0%", "showLevel0"],
                ["bool", "Show Level 23.6%", "showLevel1"],
                ["bool", "Show Level 38.2%", "showLevel2"],
                ["bool", "Show Level 50%", "showLevel3"],
                ["bool", "Show Level 61.8%", "showLevel4"],
                ["bool", "Show Level 78.6%", "showLevel5"],
                ["bool", "Show Level 100%", "showLevel6"],
                ["bool", "Show Level 138.2%", "showLevel7"],
                ["bool", "Show Level 161.8%", "showLevel8"],
                ["bool", "Show Level 200%", "showLevel9"],
                ["bool", "Show Level 261.8%", "showLevel10"],
                ["bool", "Show Level -138.2%", "showLevel11"],
                ["bool", "Show Level -161.8%", "showLevel12"],
                ["bool", "Show Level -200%", "showLevel13"],
                ["bool", "Show Level -261.8%", "showLevel14"]
            ]
        },{
            name: ['LineTool'],
            settings: {
                lineWidth: 1,
                color: "#ff0000",
                extendP1: true,
                extendP2: false,
            }, // Default settings
        },
        {
            name: ['PriceGap'],
            fieldsTemplate: [
                ["integer", "Minimum Gap", "minPriceGap", 0],
                ["integer", "Number of Candles", "numberOfCandles", 1],
                ["color", "Gap Up Color", "gapUpColor"],
                ["integer", "Gap Up Opacity", "gapUpOpacity", 0, 100],
                ["color", "Gap Down Color", "gapDownColor"],
                ["integer", "Gap Down Opacity", "gapDownOpacity", 0, 100],
            ],
            settings: {
                minPriceGap: 0,
                numberOfCandles: 300,
                gapUpColor: "#00ff00",
                gapUpOpacity: 10,
                gapDownColor: "#ff0000",
                gapDownOpacity: 10,
                rerender: 0,
            },
        },
        {
            name: ['Bollinger Band'],
            fieldsTemplate: [["integer", "Period", "length"],
                ["integer", "StdDev", "stddev"],
                ["color", "Line Color", "color"],
                ["color", "Back Color", "backColor"],
                ["lineWidth", "Line Thickness", "lineWidth"],
                ["bool", "Show Middle", "showMid"]
            ]
        },
        {
            name: ['AUTOFIB-X', 'AUTOFIB-2', 'AUTOFIB-1'],
            fieldsTemplate: [
                ["bool", "Show Price", "showPrice"],
                ["bool", "Show Percentage", "showPercentage"],
                ["sep", "sep", "sep"],
                ["bool", "Show Level 0%", "showLevel0"],
                ["bool", "Show Level 23.6%", "showLevel1"],
                ["bool", "Show Level 38.2%", "showLevel2"],
                ["bool", "Show Level 50%", "showLevel3"],
                ["bool", "Show Level 61.8%", "showLevel4"],
                ["bool", "Show Level 78.6%", "showLevel5"],
                ["bool", "Show Level 100%", "showLevel6"],
                ["bool", "Show Level 138.2%", "showLevel7"],
                ["bool", "Show Level 161.8%", "showLevel8"],
                ["bool", "Show Level 200%", "showLevel9"],
                ["bool", "Show Level 261.8%", "showLevel10"],
                ["bool", "Show Level -138.2%", "showLevel11"],
                ["bool", "Show Level -161.8%", "showLevel12"],
                ["bool", "Show Level -200%", "showLevel13"],
                ["bool", "Show Level -261.8%", "showLevel14"],
            ]
        }
    ]
);
*/
export class ConfigHandler {
    constructor(owner) {
        const self = this;
        this.indicatorConfigDefinitions = new Map();

        IndicatorConfigDefinition.forEach((icd) => {
            if (typeof icd.name == 'string')
                self.indicatorConfigDefinitions.set(icd.name, icd);
            else {
                for (let idx in icd.name) {
                    self.indicatorConfigDefinitions.set(icd.name[idx], icd);
                }
            }
        });
    }

    getConfigFieldTemplates(indName) {
        console.log('getConfigFieldTemplates', indName)
        return this.indicatorConfigDefinitions.get(indName);
    }
}
