<template>
    <div class="tb-dropdown">
        <div class="menu-item" :class="{ active: isActive }" @click="show" v-clickaway="hide">
            <span v-if="icon != undefined" id="icon" :style="icon_style"></span>
            <span style="font-weight: 300; ">{{ title }}</span>
            <svg v-if="title != undefined" viewBox="0 0 1030 638" width="10">
                <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" ></path>
            </svg>
        </div>
        <div class="tb-dropdown-list" v-if="isActive"  :style="{'background-color':getBackgroundLayoutColor(),'fill':getFontLayoutColor(),'color':getFontLayoutColor(),'top': (topPosition?topPosition:'42')+'px'}">
            <slot></slot>
            <div @click="on_item_select(item, $event, i)" class="menu-item" v-for="(item, i) in items" :key="i" style="display: block">
                <div>
                    <svg class="svg_item" v-if="(item[svgKey] != '') && item[svgKey] != undefined " height="24" width="32" :style="{'filter': 'brightness('+getBrightness+') hue-rotate(90deg) saturate(4.5) !important;'}">
                      <path :d="item[svgKey]"></path>
                    </svg>
                    <div class="item-name">{{ item[nameKey] }}</div>
                  <i v-if="hasDelete && (!filterInfoKey || filterInfoKey==item[infoKey])" class="fa fa-times-circle icon-delete" aria-hidden="true" style="float: right; width: 22px"></i>
                  <i v-if="hasSave && (!filterInfoKey || filterInfoKey==item[infoKey])" class="fa fa-save icon-save" aria-hidden="true" style="float: right; width: 22px"></i>
                  <i v-if="hasFav && item.isFav" class="fa fa fa-star icon-fav" aria-hidden="true" style="float: right; width: 22px"></i>
                  <i v-if="hasFav && !item.isFav" class="fa fa fa-star-o icon-fav" aria-hidden="true" style="float: right; width: 22px"></i>
                  <div v-if="infoKey" class="item-name" style="float: right; width: 1.7em; text-align:left">|</div>
                  <div v-if="infoKey" class="item-name" style="float: right; width: 2.8em; text-align:center">{{ item[infoKey] }}</div>
                  <div v-if="infoKey" class="item-name" style="float: right; width: 0.1em">|</div>
                </div>
                <span style="color: #444">{{ item["TAGS"] != undefined && item["TAGS"].length > 0 ? "in: " + item["TAGS"].toString() : "" }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {LayoutColors} from "../../utils/LayoutHandler";

export default {
    name: "dropdown",
    props: ["items", "title", "nameKey", "infoKey", "icon", "hasDelete", "svgKey", "hasSave", "hasFav","filterInfoKey","chartHandler","topPosition"],

    data() {
        return {
            isActive: false,
        };
    },

    methods: {
        show: function() {
            this.isActive = true;
        },
        hide: function() {
            this.isActive = false;
        },
      getBackgroundLayoutColor() {
        return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.backgroundMain);
      },
      getFontLayoutColor() {
        return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorMain);
      },
        on_item_select(item, event, i) {
          if (event.target.classList.contains("icon-delete")) {
            this.$emit("item-delete", item);
            this.items.splice(i, 1);
          } else if (event.target.classList.contains("icon-save")) {
            this.$emit("item-save", item);
          }  else if (event.target.classList.contains("icon-fav")) {
              this.$emit("item-fav", item);
              event.stopPropagation();
          } else {
              this.$emit("item-selected", item);
              //event.stopPropagation();
          }
        },
    },

    computed: {
      getBrightness() {
        return chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorBrightnessMain);
      },
        icon_style() {
            return {
                "background-image": `url(${this.icon})`,
                width: "25px",
                height: "25px",
                "background-repeat": "no-repeat",
                "background-size": "contain",
                margin: `0 ${this.title != undefined ? "8px" : "0"} 2px 0`,
                filter: `brightness(${chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorBrightnessMain)}) hue-rotate(90deg) saturate(4.5) !important`,
            };
        },
    },
};
</script>

<style scoped>
.tb-dropdown {
    position: relative;
    _color: #fff;
    __color: #2d2d2d;
    font-size: 13px;
    display: flex;
    align-items: center;
    user-select: none;
}
.tb-dropdown-list {
    position: absolute;
    min-width: 200px;
    top: 42px;
    _background-color: #12151b;
    box-shadow: 0px 1px 6px 1px #00000080;
    z-index: 1000;
}
.menu-item {
    padding: 8px 8px;
    height: 30px;
    display: flex;
    align-items: center;
  _background-color: #f2f2f2;
}

.menu-item .item-name {
  padding: 0px 0px;
  top: 0px;
  left: 5px;
  float: left;
  position: relative;
  font-size: smaller;
}

.menu-item .svg_item {
  padding: 0px 0px;
  top: -3px;
  left: -5px;
  float: left;
  position: relative;
}


svg_item {
  filter: brightness(0.0) hue-rotate(90deg) saturate(4.5) !important;
}


.menu-item .icon-delete {
  cursor: pointer;
}
.menu-item .icon-save {
  cursor: pointer;
}
.menu-item .icon-fav {
  cursor: pointer;
}
.tb-dropdown + .menu-item {
    padding: 0px 0px;
}
.menu-item:hover,
.menu-item.active {
  _background-color: #bebebe;
  _color:#222222;
}

.span {
  _color:#222222;
}

/*
.menu-item:hover .icon-delete {
    display: block;
}
.icon-delete:hover {
    filter: brightness(1.45) sepia(1) hue-rotate(310deg) saturate(4.5) !important;
}
.menu-item.active #icon {
    filter: brightness(0.0) sepia(1) hue-rotate(90deg) saturate(4.5) !important;
}*/
.menu-item svg {
    margin-left: 10px;
}
</style>
