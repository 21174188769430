import Seg from "../../lib/components/primitives/seg";
import Pin from "../../lib/components/primitives/pin";

function calculateIntersection(p1, p2, p3, p4) {

    var c2x = p3.x - p4.x; // (x3 - x4)
    var c3x = p1.x - p2.x; // (x1 - x2)
    var c2y = p3.y - p4.y; // (y3 - y4)
    var c3y = p1.y - p2.y; // (y1 - y2)

    // down part of intersection point formula
    var d  = c3x * c2y - c3y * c2x;

    if (d == 0) {
        return { x: null, y: null };
        throw new Error('Number of intersection points is zero or infinity.');
    }

    // upper part of intersection point formula
    var u1 = p1.x * p2.y - p1.y * p2.x; // (x1 * y2 - y1 * x2)
    var u4 = p3.x * p4.y - p3.y * p4.x; // (x3 * y4 - y3 * x4)

    // intersection point formula

    var px = (u1 * c2x - c3x * u4) / d;
    var py = (u1 * c2y - c3y * u4) / d;

    var p = { x: px, y: py };

    return p;
}
function roundRect(
    ctx,
    x,
    y,
    width,
    height,
    radius = 5,
    fill = false,
    stroke = true,
    text = "",
    textHeight = 15,
    textcolor = "#dedede"
) {
    if (typeof radius === 'number') {
        radius = {tl: radius, tr: radius, br: radius, bl: radius};
    } else {
        radius = {...{tl: 0, tr: 0, br: 0, bl: 0}, ...radius};
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
        ctx.fill();
    }
    if (text) {
        const textWidth = ctx.measureText(text+"").width;
        const oldStyle = ctx.fillStyle;
        ctx.fillStyle = textcolor;
        ctx.fillText(text, x+(width/2)-(textWidth/2), y+textHeight, width);
        ctx.fillStyle = oldStyle;
    }
    if (stroke) {
        ctx.stroke();
    }
}


export function addPointWithTracking(self) {
    self.pins.push(
        new Pin(self, 'p'+(self.pins.length+1), {
            state: "tracking",
        })
    );
    self.pins[self.pins.length-1].on("settled", (name, source) => {
        if ((self.state !== "finished") && (source == "mousedown")) {
            if ((self.pins.length >= self.pointCount)) {
                self.set_state("finished");
                self.$emit("drawing-mode-off");
                //console.log('#tool',self.tool())
                self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off(self.tool().name);
            } else {
                addPointWithTracking(self);
            }
        }
    });
}

export function updatePins(self, settings) {
    self.pins.splice(0,self.pins.length);
    if (settings.p1) {
        settings.$state = "finished";
        let idx = 0;
        while (idx++ < self.pointCount) {
            self.pins.push(new Pin(self, "p" + idx));
        }
    } else {
        self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on(self.tool().name, self.id, settings);
        self.pins.push(new Pin(self, "p1"));
        addPointWithTracking(self);
    }
}

function getPoint(self, layout, pnt) {
    let p = null;

    if (typeof pnt === 'number')
        p = self.pins[pnt];
    else {
        let res = calculateIntersection(
            {x:layout.t2screen(self.pins[pnt[0]].t), y:layout.$2screen(self.pins[pnt[0]].y$)},
            {x:layout.t2screen(self.pins[pnt[1]].t), y:layout.$2screen(self.pins[pnt[1]].y$)},
            {x:layout.t2screen(self.pins[pnt[2]].t), y:layout.$2screen(self.pins[pnt[2]].y$)},
            {x:layout.t2screen(self.pins[pnt[3]].t), y:layout.$2screen(self.pins[pnt[3]].y$)});
        if ((res.x) || (res.y))
            p = {t: layout.screen2t(res.x),y$: layout.screen2$(res.y)}
    }
    //console.log(typeof pnt, pnt.length,p);
    return p;
}

export function drawPattern(self, layout, ctx) {
    if (!self.p1 || !self.p2) return;

    if (self.show_pins)
        ctx.lineWidth = self.line_width+3;
    else
        ctx.lineWidth = self.line_width;
    //ctx.lineWidth = self.lineWidth;
    ctx.strokeStyle = self.lineColor;
    ctx.fillStyle = self.backColor;

    ctx.font = `10px Arial`;

    let x1, y1;

    self.pins.forEach((p, index) => {
        const x = p.t;
        const y = p.y$;
        if (index > 0) {
            ctx.beginPath();
            new Seg(self, ctx).draw([x1, y1], [x, y]);
            ctx.stroke();
        }
        x1 = x;
        y1 = y;
        if (self.pointCaptions[index]) {
            const offset = ((self.pins.length - 1 > index) && (p.y$ < self.pins[index + 1].y$)) || ((self.pins.length - 1 == index) && (p.y$ < self.pins[index - 1].y$)) ? 7 : -22;
            const w = ctx.measureText(self.pointCaptions[index]).width + 10;

            ctx.fillStyle = '#242526';
            roundRect(ctx, layout.t2screen(x1) - w / 2, layout.$2screen(y1) + offset, w, 15, 3, true, true, self.pointCaptions[index], 11, ctx.strokeStyle);
            ctx.fillStyle = self.backColor;
        }
    });

    if (self.drawBackgroundInfo) {
        self.drawBackgroundInfo.forEach((bgi)=> {
            if ((bgi.area) && (self.pins.length>bgi.area.minCalcIdx)) {
                let region = new Path2D();
                bgi.area.points.forEach((pnt, index)=> {
                    const p = getPoint(self, layout, pnt);
                    if (p) {
                        if (index > 0)
                            region.lineTo(layout.t2screen(p.t), layout.$2screen(p.y$));
                        else
                            region.moveTo(layout.t2screen(p.t), layout.$2screen(p.y$));
                    }
                })
                region.closePath();
                ctx.fill(region);
            }
            if (bgi.relations) {
                bgi.relations.forEach((rel) => {
                    if (self.pins.length > rel.minCalcIdx) {
                        const p1 = getPoint(self, layout, rel.base[0]);
                        const p2 = getPoint(self, layout, rel.base[1]);
                        const pCalc = getPoint(self, layout, rel.calcPoint);
                        const p3 = getPoint(self, layout, rel.pos[0]);
                        const p4 = getPoint(self, layout, rel.pos[1]);
                        const p100 = Math.abs((p2.y$ - pCalc.y$) / (p2.y$ - p1.y$));
                        const pText = p100.toFixed(2)+""
                        const w = ctx.measureText(pText).width + 10;
                        const x1 = layout.t2screen(p3.t);
                        const x2 = layout.t2screen(p4.t);
                        const y1 = layout.$2screen(p3.y$);
                        const y2 = layout.$2screen(p4.y$);

                        if (rel.lineType)
                            ctx.setLineDash(rel.lineType);

                        ctx.beginPath();
                        ctx.moveTo(x1,y1);
                        ctx.lineTo(x2,y2);
                        ctx.stroke();
                        ctx.setLineDash([]);

                        ctx.fillStyle = '#242526';
                        roundRect(ctx, (x1+x2)/2 - w/2, (y1+y2)/2-7, w, 15, 3, true, true, pText, 11, ctx.strokeStyle);
                        ctx.fillStyle = self.backColor;
                    }
                });
            }
        })
    }

    self.render_pins(ctx);
}