<template>
  <div id="content">
      <div v-bind:id="item.key" @mouseover="sonmouseover" v-if="item.selected" class="height-mask" >
        <!--p >{{ item.name }}</p>
        <p-- >{{ item.caption }}</p-->
        <b>#{{item.name}}</b>
        <span>{{ item.caption }}</span>
    </div>
    <div v-else v-bind:id="item.key" @mouseover="sonmouseover" class="modal-mask" >
      <div   >
        <!--label class="caption_label">{{ item.caption }}</label>
        <label class="name_label">{{ item.name }}</label-->
        <label class="caption_label">{{item.caption}}</label>
        <label class="name_label">{{ item.name }}</label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
      item: { required: true }
    },
  methods: {
    sonmouseover(s) {
      //console.log(s.srcElement, s.srcElement.id)
      this.$parent.$parent.$props.chartHandler.updateMarketListSelected(s.srcElement.id);
      //if (this.$props.chartHandler)
      //  this.$props.chartHandler.updateMarketListSelected(s.srcElement.id);
    }
  }
}
</script>

<style>

.caption_label {
  width: 33em;
  padding-top:0.0em;
  padding-left:1.0em;
  margin:0em;
  color: #06324d;
}

.name_label {
  width: 30em;
  text-align: right;
  padding:0em;
  padding-right:1.0em;
  margin:0em;
  color: #06324d;
}

/*

.height-mask {
  position: relative;
  width: 228px;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background: #18438e;
  color: #ccc;
  display: flex;
  justify-content: flex;
  align-items: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  z-index: 100000;
}

.modal-mask {
  position: relative;
  width: 50em;
  top: 0;
  left:0;
  right: 0;
  bottom: 0;
  background: #202e49;
  color: #ccc;
  flex: 3 0 auto;
  display_: flex;
  justify-content_: flex;
  align-items_: center;
  padding-top: 0.3em;
  padding-bottom: 0.01em;
  padding-left: 1.0em;
  padding-right: 1.0em;
  margin: 0px;
  z-index: 100000;

}

.vue-suggestion-list {
  overflow-y: scroll;
  max-height: 80vh;
  background-color: #0dca62;
  color: #0dca62;

}
.modal-mask :hover{
  background: #667ba4;

}*/

</style>