<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";
import ChartUtils from "../utils/ChartUtils.js";

export default {
    name: "MarkerInfo",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "formationstrader", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                name: "MarkerInfo",
                group: "DrawingM",
                icon: Icons["analytics.png"],
                type: "Segment",
                hint: "Price Range",
                data: [], // Default data
                settings: {
                    lineWidth: 1,
                    color: "#ffb74d",
                    backColor: "#f5ead9",
                    headColor: "#614521",
                    backOpacity: 10,
                    fontSize: 13,
                    text: "Text",
                },
                fieldsTemplate: [
                  ["color", "Text Color", "color"],
                  ["integer", "Font Size", "fontSize"],
                  ["text", "Text", "text"],
                  ["text", "Caption", "caption"],
                  ["color", "Back Color", "backColor"],
                  ["color", "Header Color", "headColor"],
                ],
            };
        },
        // Called after overlay mounted
        init() {
          this.updatePins();
        },

        getTextLines(context, text, maxWidth, lineHeight) {
          var words = text.split(' ');
          var line = '';
          var lines = [];
          var y = lineHeight;

          for(var n = 0; n < words.length; n++) {
            var testLine = line + words[n] + ' ';
            var metrics = context.measureText(testLine);
            var testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
              lines.push(line);
              line = words[n] + ' ';
              y += lineHeight;
            }
            else {
              line = testLine;
            }
          }
          lines.push(line);
          return {lines : lines, height : y};
        },
        drawLines(context, linesInfo, x, y, lineHeight, maxLinesHeight) {
            const lastIdx = linesInfo.lines.length-1;
            linesInfo.lines.forEach((line,i) => {
                if ((maxLinesHeight > y) && (maxLinesHeight <= y+lineHeight) && (lastIdx > i)){
                    line = line.trim() + '...';
                }
                if (maxLinesHeight > y) {
                  context.fillText(line, x, y);
                }
                y += lineHeight;
          });
        },

        roundRect(
                ctx,
                x,
                y,
                width,
                height,
                radius = 5,
                fill = false,
                stroke = true
            ) {
            if (typeof radius === 'number') {
              radius = {tl: radius, tr: radius, br: radius, bl: radius};
            } else {
              radius = {...{tl: 0, tr: 0, br: 0, bl: 0}, ...radius};
            }
            ctx.beginPath();
            ctx.moveTo(x + radius.tl, y);
            ctx.lineTo(x + width - radius.tr, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
            ctx.lineTo(x + width, y + height - radius.br);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
            ctx.lineTo(x + radius.bl, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
            ctx.lineTo(x, y + radius.tl);
            ctx.quadraticCurveTo(x, y, x + radius.tl, y);
            ctx.closePath();
            if (fill) {
              ctx.fill();
            }
            if (stroke) {
              ctx.stroke();
            }
        },
        draw(ctx) {

            if (!this.p1 || !this.p2) return;

            const C_MIN_SIZE = 140;
            const C_MIN_HEIGHT = 45;
            const C_HEADER_SIZE = 20;
            const C_HEADER_OFFSET = 15;
            const C_LINE_HEIGHT = this.$props.settings["fontSize"] || 10;
            const C_LINE_COLOR  = this.color;
            const C_BACK_COLOR  =  this.back_color;
            const C_HEAD_COLOR   = this.head_color;

            const layout = this.$props.layout;

            let x3 = this.p3 ? layout.t2screen(this.p3[0]) : 0;
            let x1 = layout.t2screen(this.p1[0]);
            //let x1 = this.p1[0];
            let y1 = layout.$2screen(this.p1[1]);
            let x2 = layout.t2screen(this.p2[0]);
            let y2 = layout.$2screen(this.p2[1]);
            let isIn = false;
            let borderSize = 3;

            if (x2 - x1 < C_MIN_SIZE) {
                x1 = x2 - C_MIN_SIZE;
            }

            ctx.strokeStyle = C_HEAD_COLOR;
            ctx.fillStyle = C_LINE_COLOR;

            if ((x3 > x1) && (this.pins[1].state === "settled")) {
              const m = this.mouse;

              isIn = (m &&
                      (Math.abs(m.x-x2)< 10) &&
                      (Math.abs(m.y-(y2+10))< 10));
              //if (m)
              //  console.log(isIn, m, m.x, m.y, x2, y2);
            }
            if ((x3 > x1) && (this.pins[1].state === "settled") && !isIn){
                this.roundRect(ctx,x2-10, y2, 16, 16, 8, true, true);
                ctx.fillStyle = '#462b02';
                //this.roundRect(ctx,   x2-5, y2+5, 10, 10, 5, true, false);
                ctx.font = `14px Arial`;
                ctx.fillText("!", x2-4, y2+13);
            } else {
              ctx.font = `${C_LINE_HEIGHT}px Arial`;
              var linesInfo = this.getTextLines(ctx, this.text, x2 - x1 - borderSize, C_LINE_HEIGHT);

              if (y2 - y1 < C_MIN_HEIGHT) {
                y2 = y1 + C_MIN_HEIGHT;
              }
              if (y2 - y1 > linesInfo.height + C_HEADER_SIZE + C_HEADER_OFFSET) {
                y2 = y1 + linesInfo.height + C_HEADER_SIZE + C_HEADER_OFFSET
              }

              var xmid = x1+Math.floor((x2-x1)/2);
              var ymid = y1+Math.floor((y2-y1)/2);

              ctx.beginPath();

              // ctx.strokeRect(x1, y1, x2 - x1, y2 - y1)
              var markerlines = 3;
              while (this.$props.settings['p'+(++markerlines)]) {
                var p = this.$props.settings['p'+(markerlines)];
                var x = layout.t2screen(p[0]);
                var y = layout.$2screen(p[1]);

                //console.log('markerlines',x,y);
                ctx.fillStyle = C_LINE_COLOR;
                let pos1 = ChartUtils.calcAnglePos(xmid,ymid,x,y,-10);
                let pos2 = ChartUtils.calcAnglePos(xmid,ymid,x,y,10);

                /*if (x>xmid) {
                  pos1 = ChartUtils.calcAnglePos(xmid,ymid,xmid-(xmid-x)*2,ymid-(ymid-y)*2,20);
                  pos2 = {x:xmid,y:ymid}; //ChartUtils.calcAnglePos(xmid,ymid,xmid-(xmid-x)*2,ymid-(ymid-y)*2,-20);
                } else {
                  pos1 = ChartUtils.calcAnglePos(xmid,ymid,x,y,20);
                  pos2 = ChartUtils.calcAnglePos(xmid,ymid,x,y,-20);
                //}
*/
                ctx.beginPath()
                ctx.moveTo(x,y);
                ctx.lineTo(pos1.x,pos1.y);
                ctx.lineTo(pos2.x,pos2.y);
                ctx.lineTo(x,y);
                ctx.fill();
              };

              ctx.fillStyle = C_BACK_COLOR;


              ctx.font = `14px Arial`;
              //ctx.fillRect(x1, y1, x2 - x1, 20);

              //ctx.fillStyle = this.back_color;
              //ctx.fillStyle += ChartUtils.alphaToHex(this.back_opacity);
              ctx.lineWidth = this.line_width;
              ctx.strokeStyle = this.color;
              ctx.lineWidth = 2;
              this.roundRect(ctx, x1, y1, x2 - x1 + 1, y2 - y1 + 1, 12, true, true);
              ctx.lineWidth = 1;
              ctx.fillStyle = C_HEAD_COLOR;
              this.roundRect(ctx, x1 + 0.5, y1 + 0.5, x2 - x1, C_HEADER_SIZE, 12, true, false);
              ctx.fillRect(x1 + 0.5, y1 + 12 + 0.5, x2 - x1, 10);

              ctx.font = `${C_LINE_HEIGHT}px Arial`;
              this.drawLines(ctx, linesInfo, x1 + borderSize, y1 + 35 + 0.5, C_LINE_HEIGHT, y2);
              ctx.fillStyle = C_BACK_COLOR;

              ctx.font = `14px Arial`;
              ctx.fillText(this.caption, x1 + (x2 - x1) / 2 - 20, y1 + 16);
              //ctx.fillText("Analyse Wertpapiere", x1+16, y1+16);
              // Background

              //let x1 = this.p1[0];
              //ctx.beginPath();
              // Top
              if (this.isAdmin && !isIn) {
                ctx.fillStyle = '#0000';
                const xt1 = layout.screen2t(x1);
                const xt2 = layout.screen2t(x2);
                const yt1 = layout.screen2$(y1);
                const yt2 = layout.screen2$(y2);

                new Seg(this, ctx).draw([xt1, yt1], [xt2, yt1]);
                // // Top
                new Seg(this, ctx).draw([xt2, yt1], [xt2, yt2]);
                // // Top
                new Seg(this, ctx).draw([xt2, yt2], [xt1, yt2]);
                // // Top
                new Seg(this, ctx).draw([xt1, yt2], [xt1, yt1]);

                ctx.fill();

                //console.log('add_ind_2',this.p1[0],this.p1[1],this.p2[0],this.p2[1])

                this.render_pins(ctx);
                if (this.selected || this.show_pins) {
                  this.pins.forEach((p,i) => {

                    var r = 20, lw = 1.5;

                    ctx.beginPath()
                    ctx.fillStyle = '#eee';
                    ctx.font = `9px Arial`;
                    ctx.fillText(i+1, p.x-2.5, p.y+3);
                  });

                }
              }
            }
        },
        use_for() {
            return ["MarkerInfo"];
        },
        data_colors() {
            return [this.color];
        },
      updatePins() {
        const self = this;
        //console.log('add_ind_updatePins');if (this.$props.settings.p1) {
        this.pins.splice(0,this.pins.length);
        if (this.$props.settings.p1){
          this.pins.push(
              new Pin(this, "p1", {
                state: "finished",
                t: this.$props.settings.p1[0],
                y$: this.$props.settings.p1[1],
              })
          );
          this.pins.push(
              new Pin(this, "p2", {
                state: "finished",
                t: this.$props.settings.p2[0],
                y$: this.$props.settings.p2[1],
              })
          );
          this.pins.push(
              new Pin(this, "p3", {
                state: "finished",
                t: this.$props.settings.p3 ? this.$props.settings.p3[0] : this.$props.settings.p1[0],
                y$: this.$props.settings.p3 ? this.$props.settings.p3[1] : this.$props.settings.p1[1],
              })
          );

          // Second one is following mouse until it clicks
          this.pins.push(
              new Pin(this, "p4", {
                state: "finished",
                t: this.$props.settings.p4 ? this.$props.settings.p4[0] : this.$props.settings.p2[0],
                y$: this.$props.settings.p4 ? this.$props.settings.p4[1] : this.$props.settings.p2[1],
              })
          );
          // Second one is following mouse until it clicks
          this.pins.push(
              new Pin(this, "p5", {
                state: "finished",
                t: this.$props.settings.p5 ? this.$props.settings.p5[0] : this.$props.settings.p2[0],
                y$: this.$props.settings.p5 ? this.$props.settings.p5[1] : this.$props.settings.p2[1],
              })
          );
          // Second one is following mouse until it clicks
          this.pins.push(
              new Pin(this, "p6", {
                state: "finished",
                t: this.$props.settings.p6 ? this.$props.settings.p6[0] : this.$props.settings.p2[0],
                y$: this.$props.settings.p6 ? this.$props.settings.p6[1] : this.$props.settings.p2[1],
              })
          );
        } else {
          self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("MarkerInfo1", this.id, this.$props.settings);
          // First pin is settled at the mouse position
          this.pins.push(new Pin(this, "p1"));

          // Second one is following mouse until it clicks
          this.pins.push(
              new Pin(this, "p2", {
                state: "tracking",
              })
          );

          this.pins[1].on("settled", () => {

            const pin3 = new Pin(this, "p3", {
                            state: "tracking",
                          });
            this.pins.push( pin3 );
            this.pins[2].on("settled", () => {
              // Call when current tool drawing is finished
              // (Optionally) reset the mode back to 'Cursor'
              this.set_state("finished");
              this.$emit("drawing-mode-off");

              if (this.pins.length<4) {
                console.log('add p4', this.$props.settings.p1[0], this.$props.settings.p2[0]);
                // Second one is following mouse until it clicks
                this.pins.push(
                    new Pin(this, "p4", {
                      state: "tracking",
                    })
                );
                this.pins.push(
                    new Pin(this, "p5", {
                      state: "tracking",
                    })
                );
                this.pins.push(
                    new Pin(this, "p6", {
                      state: "tracking",
                    })
                );
              }
            });

            //global drawing mode
            //window.$store.drawingMode = false;
            self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("MarkerInfo1");
          });
        }
      },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        isAdmin() {
          return window.chartHandler.isAdmin;
        },
        p1() {
          return this.$props.settings.p1;
        },
        p2() {
          return this.$props.settings.p2;
        },
      p3() {
        return this.$props.settings.p3;
      },
      p4() {
        return this.$props.settings.p4;
      },
      p5() {
        return this.$props.settings.p5;
      },
      p6() {
        return this.$props.settings.p6;
      },
        caption() {
          return this.$props.settings.caption || "Hinweis";
        },
        text() {
          return this.$props.settings.text || "...";
        },
        line_width() {
            return this.sett.lineWidth || 1;
        },
        color() {
            return this.sett.color || "#7c0742";
        },
        back_color() {
          return this.sett.backColor || "#f5ead9";
        },
        head_color() {
          return this.sett.headColor || "#f5ead9";
        },
        back_opacity() {
            return this.sett.backOpacity || 10;
        },
    },
    data() {
        return {};
    },
};
</script>
