<template>
  <div ref="draggableContainer" id="draggable-container" >
    <div id="panel-container">
      <div id="draggable-header" @mousedown="dragMouseDown">
        <slot name="header"></slot>
      </div>
      <div class="bodypanel">
        <label><slot name="caption"></slot></label><br>
        <input v-model="inputtextData"/>
        <div class="box">
          <button class="link" style="margin-top: 10px" v-on:click="saveClick()">Speichern</button>
          <button class="link" style="margin-top: 10px" v-on:click="cancelClick()">Abbrechen</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SaveDialog',
  props: ["textModel"],
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      chartHandler: window.chartHandler,
      inputtextData: "",
    }
  },
  methods: {
    saveClick() {
      this.$emit('onSaveClick', this.inputtextData);
    },
    cancelClick() {
      this.$emit('onCancelClick');
    },
    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },
  },

  watch: {},
  computed: {}
}
</script>

<style scoped>

.fcolorpicker-curbox {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.bodypanel {
  margin: 2.0em;
}

input {
  width : 100%;
}

#draggable-container {
  display: block;
  position: absolute;
  z-index: 1090;
  width: 30em;
  top: 50vw;
  left: 50vh;
  transform: translate(-50%, -50%);
}
#panel-container {
  color: #d9d9d9;
  background-color: rgb(37, 41, 42);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  top:25%;
  left:45%;
  box-shadow: 0px 0px 4px #4b4b4b;
}
#draggable-header {
  cursor:grab;
  z-index: 1091;
  padding: .2em;
  margin: 0.2em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  background-color: rgb(18, 52, 80);
  font-size: large;
}

panel-button {
  z-index: 91;
  padding-right: 1em;
  padding-bottom: 0.1em;
}

#color-button-group {
  display: flex;
}

color-button {
  z-index: 91;
  padding-right: 1em;
  padding-bottom: 0.1em;
}

.button:hover {
  background-color: #3b3b3b;
  border: 1px solid #b9b9b9;
}

.button:focus {
  border: 0px solid #b9b9b9;
  outline:0;
}

button:focus {
  border: 1px solid #b9b9b900;
  outline:1px;
}

.vl {
  border-left: 1px solid #b9b9b9;
  margin: 0.5em;
  margin-top: 0.4em;
  height: 1.5em;
}

.button {
  background-color: #00000000;
  border: 1px solid #00000000;
  color: #dedede;
  border-radius: 2px;
  box-shadow_: rgba(117, 119, 119, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember",sans-serif;
  font-size: 0.8em;
  line-height: 2em;
  padding: 2px 2px 2px 2px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  border-left_: 1px solid #b9b9b9;
  width: 3em;
  margin-top: 0.25em;
}

.button1 {border-radius: 4px;}

.selbutton {
  margin: 0.05em;
}

.colorbutton {
  margin-top: 0.5em;
  background-color: #0dca62;
  width: 2em;
  height: 2em;
}
.colorbutton:hover {
  margin-top: 0.5em;
  background-color: #0dca62;
  color: #dedede;
  width: 2em;
  height: 2em;
}

#linewidthpanel {
  width:10em;
  margin-left: 1em;
}

.lineitem {
  padding: 0.2em;
}

.lineitem:hover {
  background-color: #1F4661;
}

.modifypanel {
  margin-top: 0.5em;
  background-color: rgb(37, 41, 42);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  color: #dedede;
  padding: 0.5em;
}

#colormap {
  display: flex;
  flex-wrap: wrap;
  width: 19em;
}

button{
  background-color: #ffffff;
  border: 1.5px solid #FF6E00;
  font-weight: bold;
  color: #FF6E00;
  padding: 4px 10px;
  #font-size: 16px;
  font-size: medium;
  cursor: pointer;
  width: 8em;
  border-radius: 3px;
  text-decoration: none;
}

button:hover  {
  border: 1.5px solid #FF6E00;
  background-color: #FF6E00;
  color: #ffffff;  border-radius: 3px;
  transition: all 250ms ease-in-out;
}
button:focus {
  outline: none;
}
.link {
  margin-top: 1px;
  margin-bottom: 3px;
  transition: all 250ms ease-in-out;
  color: #FF6E00;
  #font-size: 15px;
  font-size: small;
}
.box {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 4em;
  margin: 1em;
}

.box > * {
  flex: 1;
}

</style>

