<template>
  <div class="viewMain" id="viewMain" style="margin: .2em;" >
    <div class="hover">
      <p style="text-align:center;background-color: rgb(37,85,121);color: rgb(255,255,255);margin: .3em;margin-top: 0.5em;padding-top: 0.3em;font-size: large;height:2.0em" >
        Marktübersicht
        <text class="sub-text st1 st2 tooltip"></text>
        <i title="Ansicht speichern" class="fa fa-save" v-on:click="setMarketListLayout('default')" style="float: right; font-size:x-large;margin-right:0.5em;margin-left:0.5em;margin-top:0.05em;cursor:default"></i>
      <i v-if="(addMarketListId != '') && (addMarketListId>=0)" title="Marktübersicht hinzufügen" class="fa fa-plus" v-on:click="addMarketListLayout(addMarketListId)" style="float: right; font-size:x-large;color:#ffffff;margin-right:0.5em;margin-top:0.05em;cursor:default"></i>
      <i v-if="(addMarketListId == '') || (addMarketListId<0)" title="Marktübersicht hinzufügen" class="fa fa-plus" style="float: right; font-size:x-large;color:#8d8d8d;margin-right:0.5em;margin-top:0.05em;cursor:default"></i>
      <v-select class="style-chooser" id="selAddMarketListId" v-model="addMarketListId" :options="a_MarketListToAdd" label="marketName" :reduce="(option) => option.marketListId" style="float: right; height:0.7em;margin-top:-0.1em" />
      </p>
    </div>
    <div v-show="!connectionActive()">
      <p style="text-align:center;background-color: rgb(220,87,14);color: rgb(255,255,255);margin: 25%;margin-top: 15%;height: 80%;font-size: large;width:50%; padding:3em" >
        Bitte melden Sie sich in der Hauptmaske an! <br>
        Anschließend stehen Ihnen die Daten wieder zur Verfügung!
      </p>
    </div>
    <div v-show="connectionActive()" id="messagesA" style="margin: .3em">
      <grid-layout class="layout_mu"
                   :layout.sync="layout"
                   :col-num="120"
                   :margin="[4, 4]"
                   :row-height="1"
                   :max-rows="height()/5-12"
                   :responsive="false"
                   :is-draggable="draggable"
                   :is-resizable="draggable"
                   :is-bounded="false"
                   :prevent-collision="true"
                   :vertical-compact="false"
                   :use-css-transforms="false"

                   drag-allow-from=".vue-draggable-handle"
      >
        <grid-item v-for="(item, idxMarketList) in a_currentSymbols" v-bind:key="layout[idxMarketList].i"
                   :static="false"
                   :x="layout[idxMarketList].x"
                   :y="layout[idxMarketList].y"
                   :w="layout[idxMarketList].w"
                   :h="layout[idxMarketList].h"
                   :i="layout[idxMarketList].i">
          <market-overview-item :item="item" :windowheight="layout[idxMarketList].h" :draggable="draggable" :trendvisible="true" v-on:changeSymbol="changeSymbol" v-on:changeDirection="changeDirection"></market-overview-item>
        </grid-item>
      </grid-layout>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {MarketOverviewHandler} from "../utils/MarketOverviewHandler";
import {GridItem, GridLayout} from "vue-grid-layout";
import MarketOverviewItem from "./components/MarketOverviewItem.vue";

export default {
  name: "MarketOverview",
  components: {MarketOverviewItem,
    GridLayout,
    GridItem,draggable
  },
  data() {
    //console.log(this.$route.query)
    return {
      a_currentSymbols : this.marketOverviewHandler.displayData.marketListsLayout,
      a_MarketListToAdd : this.marketOverviewHandler.displayData.marketListToAdd,
      svgToolTip: this.marketOverviewHandler.displayData.svgToolTip,
      SideColumns : 3,
      sideRows : 4,
      width: window.innerWidth,
      layout: this.marketOverviewHandler.displayData.layout,
      draggable: true,
      isDragging: false,
      index: 0,
      addMarketListId : "",
      connectionActives:  (this.marketOverviewHandler.displayData.token != "") && (this.marketOverviewHandler.displayData.token != "x"),

    };
  },
  mounted() {

  },
  beforeCreate() {
    const loggedIn = JSON.parse(localStorage.getItem("user"));
    this.marketOverviewHandler = new MarketOverviewHandler(loggedIn ? loggedIn.token : null, loggedIn ? loggedIn.isAdmin : false, this.$route.query)
    console.log('marketOverviewHandler',this.marketOverviewHandler,this.$route.query)
  },
  created() {
  },
  computed: {
    currentSymbol() {
      //console.log('currentSymbol',  this.marketOverviewHandler)
      return this.marketOverviewHandler.displayData.symbol;
    },

  },
  methods: {
    getHeight(idx) {
      return 20;
    },

    connectionActive() {
      return this.marketOverviewHandler.displayData.token.length>0;
    } ,
    height() {
      return window.innerHeight;
    },
    changeSymbol(symbol) {
      console.log(symbol)
      if (this.marketOverviewHandler)
        this.marketOverviewHandler.setChangeSymbol(symbol);
    },
    getDirColor(change) {
      if (change <= 0.000001)
        return '#FF3A3AFF';
      return '#40b60d';
    },
    changeDirection(col, marketListId) {
      this.marketOverviewHandler.changeDirection(col, marketListId);
    },
    handleDraggingEnd(marketListId){
      this.isDragging = false;
      console.log(marketListId);
      this.marketOverviewHandler.updateColIndex(marketListId);
    },
    updMarketListDetails(marketListId){
      this.draggable = true;
      this.marketOverviewHandler.updMarketListDetails(marketListId);
    },
    resetMarketListDetailsSort(marketListId){
      this.draggable = true;
      this.marketOverviewHandler.resetMarketListDetailsSort(marketListId);
    },
    setMarketListLayout(name) {
      this.marketOverviewHandler.setMarketListLayout(name, JSON.stringify(this.layout));
    },
    addMarketListLayout(marketListId) {
      this.addMarketListId = "";
      this.marketOverviewHandler.addMarketListLayout(marketListId,4,4,10,20);
    },
    delMarketListLayout(marketListId) {
      this.marketOverviewHandler.delMarketListLayout(marketListId);
    }
  },
};
</script>

<style>
</style>
