import Vue from 'vue';

export class DaxenHandler {
    constructor(owner) {
        this.owner = owner;
        this.groups = [];
        this.activeRuns = {};
        this.info = {activeRuns : 0, finishedRuns : 4};
    }

    handleInfoUpdate(msg) {
        const self = this;
        msg.general.groups.forEach((grp) => {
            self.groups.push(grp);
        });
        msg.general.groups.forEach((grp) => {
            self.groups.push(grp);
        });
    }

    handleRunUpdate(msg) {
        Vue.set(this.activeRuns,'Run_'+msg.id, msg);
        Vue.set(this.info, 'activeRuns', Object.keys(this.activeRuns).length);
        Vue.set(this.info, 'finishedRuns', Object.keys(this.activeRuns).length*2);
    }
}
