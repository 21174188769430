import Vue from 'vue';
import {SVGIcons} from "../img/svg_icons";
import hexMD5 from "./md5.js";
import Utils from "../lib/stuff/utils";
import ChartUtils from "./ChartUtils";

const C_LAYOUT_WIDTH = 12;
const C_LAYOUT_HEIGHT = 6;

export const LayoutColors = {
    backgroundMain: 1,
    fontColorMain: 2,
    fontColorMainLight: 3,
    fontColorBrightnessMain: 4,
}

const LayoutDefinition = Object.freeze([
        {
            id : 1,
            name: "1 Chart",
            definition: [{x: 0, y: 0, w: C_LAYOUT_WIDTH, h: C_LAYOUT_HEIGHT, i: "0" }],
            svgPath: SVGIcons.layout_one_chart
        },
        {
            id : 2,
            name: "2 Charts",
            definition: [{x: 0, y: 0, w: C_LAYOUT_WIDTH/2, h: C_LAYOUT_HEIGHT, i: "0" },
                         {x: C_LAYOUT_WIDTH/2, y: 0, w: C_LAYOUT_WIDTH/2, h: C_LAYOUT_HEIGHT, i: "1" }],
            svgPath: SVGIcons.layout_two_chart
        },
        {
            id : 3,
            name: "3 Charts",
            definition: [{x: 0, y: 0, w: C_LAYOUT_WIDTH/2, h: C_LAYOUT_HEIGHT/2, i: "0" },
                {x: C_LAYOUT_WIDTH/2, y: 0, w: C_LAYOUT_WIDTH/2, h: C_LAYOUT_HEIGHT/2, i: "1" },
                {x: 0, y: C_LAYOUT_HEIGHT/2, w: C_LAYOUT_WIDTH, h: C_LAYOUT_HEIGHT/2, i: "2" }],
            svgPath: SVGIcons.layout_three_chart_1
        },
        {
            id : 4,
            name: "3 Charts",
            definition: [{x: 0, y: 0, w: 8*C_LAYOUT_WIDTH/12, h: C_LAYOUT_HEIGHT, i: "0" },
                {x: 8*C_LAYOUT_WIDTH/12, y: 0, w: 4*C_LAYOUT_WIDTH/12, h: C_LAYOUT_HEIGHT/2, i: "1" },
                {x: 8*C_LAYOUT_WIDTH/12, y: C_LAYOUT_HEIGHT/2, w: 4*C_LAYOUT_WIDTH/12, h: C_LAYOUT_HEIGHT/2, i: "2" }],
            svgPath: SVGIcons.layout_three_chart_2
        },
        {
            id : 5,
            name: "4 Charts",
            definition: [{x: 0, y: 0, w: 8*C_LAYOUT_WIDTH/12, h: C_LAYOUT_HEIGHT, i: "0" },
                {x: 8*C_LAYOUT_WIDTH/12, y: 0, w: 4*C_LAYOUT_WIDTH/12, h: C_LAYOUT_HEIGHT/3, i: "1" },
                {x: 8*C_LAYOUT_WIDTH/12, y: C_LAYOUT_HEIGHT/3, w: 4*C_LAYOUT_WIDTH/12, h: C_LAYOUT_HEIGHT/3, i: "2" },
                {x: 8*C_LAYOUT_WIDTH/12, y: 2*C_LAYOUT_HEIGHT/3, w: 4*C_LAYOUT_WIDTH/12, h: C_LAYOUT_HEIGHT/3, i: "3" }],
            svgPath: SVGIcons.layout_four_chart_1
        },
        {
            id : 6,
            name: "4 Charts",
            definition: [
                {x: 0, y: 0, w: C_LAYOUT_WIDTH/2, h: C_LAYOUT_HEIGHT/2, i: "0" },
                {x: 0, y: C_LAYOUT_HEIGHT/2, w: C_LAYOUT_WIDTH/2, h: C_LAYOUT_HEIGHT/2, i: "1" },
                {x: C_LAYOUT_WIDTH/2, y: 0, w: C_LAYOUT_WIDTH/2, h: C_LAYOUT_HEIGHT/2, i: "2" },
                {x: C_LAYOUT_WIDTH/2, y: C_LAYOUT_HEIGHT/2, w: C_LAYOUT_WIDTH/2, h: C_LAYOUT_HEIGHT/2, i: "3" }
            ],
            svgPath: SVGIcons.layout_four_chart_2
        },
    ]);

export class LayoutHandler {
    constructor(owner, query) {
        const self = this;
        this.owner = owner;
        this.id = (query && query.id) ? query.id : Utils.uuid();
        this.layoutDefinition = [].concat(LayoutDefinition);
        this.activeLayoutId = 1;
        this.activeLayout = this.layoutDefinition[0];
        this.switchLayoutConfig = [0,1,2,3,4,5,6,7,8,9];

        this.userViews = {views: [], currentView: "", currentWindows: []};

        this.screens = { isAutoDetectedScreens: false, isExtended: false, grantedStateManagement: null, grantedStatePlacement: null, screens: [], currentScreen : null, svgScreens: "", osSystem : ""}

        const doSetupScreens = async () => {
            try {
                //console.log('window-management 1', granted);
                const {state} = await navigator.permissions.query({name: 'window-placement'});
                self.screens.grantedStatePlacement = state;
                //console.log('window-management 2', granted);
            } catch {
                // Nothing.
            }
            try {
                //console.log('window-management 1', granted);
                const {state} = await navigator.permissions.query({name: 'window-management'});
                self.screens.grantedStateManagement = state;
                //console.log('window-management 2', granted);
            } catch {
                // Nothing.
            }
        }

        doSetupScreens();


        if ('getScreenDetails' in window) {
            const doSetup = async () => {
                //console.log('doSetup')
                const screenDetails = await window.getScreenDetails()
                /*if (screen.isExtended && screenDetails.screens.length > 1) {
                    const newChildWindow = window.open(
                        url,
                        'New Child Window',
                        `popup,width=${800},height=${600},left=0,top=0`
                    )
                    newChildWindow.moveTo(screenDetails.screens[1].left, 0)
                }*/
                if (screenDetails) {
                    const s = screenDetails.screens;
                    const res = [];
                    s.forEach((scr) => {
                        res.push({
                            availHeight: scr.availHeight,
                            availLeft: scr.availLeft,
                            availTop: scr.availTop,
                            availWidth: scr.availWidth,
                            colorDepth: scr.colorDepth,
                            devicePixelRatio: scr.devicePixelRatio,
                            height: scr.height,
                            isExtended: scr.isExtended,
                            isInternal: scr.isInternal,
                            isPrimary: scr.isPrimary,
                            label: scr.label,
                            left: scr.left,
                            orientation: scr.orientation,
                            pixelDepth: scr.pixelDepth,
                            top: scr.top,
                            width: scr.width
                        });
                    });
                    return {
                        isAutoDetectedScreens: true,
                        isExtended: screen.isExtended,
                        systemViewKey: res,
                        osSystem : ChartUtils.getOS(),
                        screens: res,
                        currentScreen: {
                            availHeight: screenDetails.currentScreen.availHeight,
                            availLeft: screenDetails.currentScreen.availLeft,
                            availTop: screenDetails.currentScreen.availTop,
                            availWidth: screenDetails.currentScreen.availWidth,
                            colorDepth: screenDetails.currentScreen.colorDepth,
                            devicePixelRatio: screenDetails.currentScreen.devicePixelRatio,
                            height: screenDetails.currentScreen.height,
                            isExtended: screenDetails.currentScreen.isExtended,
                            isInternal: screenDetails.currentScreen.isInternal,
                            isPrimary: screenDetails.currentScreen.isPrimary,
                            label: screenDetails.currentScreen.label,
                            left: screenDetails.currentScreen.left,
                            orientation: screenDetails.currentScreen.orientation,
                            pixelDepth: screenDetails.currentScreen.pixelDepth,
                            top: screenDetails.currentScreen.top,
                            width: screenDetails.currentScreen.width
                        }
                    };
                }
                return {isExtended: screen.isExtended, osSystem: ChartUtils.getOS(), isAutoDetectedScreens: false, systemViewKey: null, screens: [window.screen], currentScreen: window.screen};
            }
            const res = doSetup();
            res.then((value) => {
                self.onScreenInfo(value);
                self.checkCurrentView();
            });

            setInterval(this.sendHeartbeat.bind(this), 5000);
            //console.log(window.screen, doSetup, res);
        }
    }

    isDarkMode() {
        return this.owner.chartSettings.theme ? this.owner.chartSettings.theme.indexOf('dark')>=0 : false;
    }

    getLayoutColor(type) {
        switch (type) {
            case LayoutColors.backgroundMain : return this.isDarkMode() ? '#2c2c2c' : '#f2f2f2';
            case LayoutColors.fontColorMain : return this.isDarkMode() ? '#f2f2f2' : '#2c2c2c';
            case LayoutColors.fontColorMainLight : return this.isDarkMode() ? '#3A3A3A' : '#fafafa';
            case LayoutColors.fontColorBrightnessMain : return this.isDarkMode() ? .9: .1;
        }
        return '#ef00ff'
    }

    sendHeartbeat() {
        //console.log('sendHeartbeat', this.id);
        if (this.owner && this.owner.channel) {
            let send = JSON.parse(JSON.stringify({action : "heartbeat", id: this.id}));
            this.owner.channel.postMessage(send);
        }
    }

    handleUserViews(data) {
        //console.log('handleUserViews', data);
        if (data && data.data) {
            while (this.userViews.views.pop());

            data.data.forEach((v) => {
                //v.instances = [];
                this.userViews.views.push(v);
            });

            this.checkCurrentView();

        }
    }

    closeMultiscreen() {
        if (this.owner && this.owner.channel) {
            let send = JSON.parse(JSON.stringify({action : "close"}));
            this.owner.channel.postMessage(send);
        }
    }

    loadMultiScreenView(view) {
        console.log('loadMultiScreenView', view);
        this.closeMultiscreen();
        this.userViews.views.forEach((v) => {
            //console.log('handleUserViews 1 -> ', v.keyInfo, this.screens.systemViewKey, this.screens.systemViewKey == v.keyInfo);
            if (view === v.keyInfo) {
                console.log("try load MultiScreenView", v)
                v.instances.forEach((i) => {
                    let url = `./#/marketoverview?`;
                    const winInfo = JSON.parse(i.settingsInstance);

                    if (i.typeInstance === "ch") {
                        const symbol = window.chartHandler.displayData.symbol;
                        const timeframe = winInfo && winInfo.tf ? winInfo.tf : window.chartHandler.timeframeHistory[0];
                        url = `./#/chart?symbol=${symbol}&timeframe=${timeframe}&detach=true&`;
                    }

                    url += "id="+i.keyInfoInstance+"";

                    if (winInfo && winInfo.pos) {
                        if (winInfo.main) {
                            window.moveTo(winInfo.pos.x, winInfo.pos.y)
                        } else {
                            const newChildWindow = window.open(url, "_blank", `width=${winInfo.pos.w},height=${winInfo.pos.h}, top=${winInfo.pos.y},left=${winInfo.pos.x},titlebar=0,toolbar=0`);
                            if (newChildWindow) {
                                if ((newChildWindow.moveTo) && (window.chartHandler.layoutHandler.screens && window.chartHandler.layoutHandler.screens.screens && (window.chartHandler.layoutHandler.screens.screens.length > 1)))
                                    newChildWindow.moveTo(winInfo.pos.x, winInfo.pos.y)
                                else
                                    newChildWindow.moveTo(0, 0)
                            }
                        }
                    }

                });
            }
        });
    }

    checkCurrentView() {
        Vue.set(this.userViews,"currentView", "");
        this.userViews.views.forEach((v) => {
            //console.log('handleUserViews 1 -> ', v.keyInfo, this.screens.systemViewKey, this.screens.systemViewKey == v.keyInfo);
            if (this.screens.systemViewKey === v.keyInfo) {
                Vue.set(this.userViews,"currentView", v.keyInfo);
            }
        });
    }

    saveScreen() {
        let sendCfg = {
            description: this.screens.osSystem+" ("+this.screens.screens.length+" Displays)",
            id: 0,
            name: this.screens.systemViewKey,
            cfg: [],
        };

        this.userViews.currentWindows.forEach((w) => {
           sendCfg.cfg.push({
               keyInfoInstance: w.id,
               typeInstance: w.source,
               settingsInstance: JSON.stringify({
                   main: w.main,
                   tf: w.tf || "",
                   pos: {
                       x: w.x,
                       y: w.y,
                       h: w.h,
                       w: w.w,
                   }
               }).replaceAll("\"","\\\"")
           });
        });

        this.owner.chartServerInterface.saveUserViews(sendCfg);
    }

    updateScreensSvg() {
        let svg = "\n";
        let h = {min: 100000000, max: -10000000};
        let w = {min: 100000000, max: -10000000};
        const offset = 50;

        this.screens.screens.forEach((s) => {
            if (w.min > s.availLeft) w.min = s.availLeft;
            if (w.max < s.availWidth + s.availLeft) w.max = s.availWidth + s.availLeft;
            if (h.min > s.availTop) h.min = s.availTop;
            if (h.max < s.availHeight + s.availTop) h.max = s.availHeight + s.availTop;
        });
        this.userViews.currentWindows.forEach((s) => {
            if (s.w && s.h) {
                if (w.min > s.x) w.min = s.x;
                if (w.max < s.w + s.x) w.max = s.w + s.x;
                if (h.min > s.y) h.min = s.y;
                if (h.max < s.h + s.y) h.max = s.h + s.y;
            }
        });

        this.screens.screens.forEach((s) => {
            svg += "<rect x=\"" + (s.availLeft-w.min+offset) + "\" y=\"" + (s.availTop-h.min+offset) + "\" width=\"" + s.availWidth + "\" height=\"" + s.availHeight + "\" style=\"fill:#113a60;stroke:#a80421;stroke-width:15;fill-opacity:0.95;stroke-opacity:1.0\" /> \n"
            console.log("updateScreenInfo",s);
        });
        this.userViews.currentWindows.forEach((s) => {
            if (s.w && s.h) {
                svg += "<rect x=\"" + (s.x-w.min+offset) + "\" y=\"" + (s.y-h.min+offset) + "\" width=\"" + s.w + "\" height=\"" + s.h + "\" style=\"fill:#c5a00c;stroke:#be4a00;stroke-width:15;fill-opacity:0.6;stroke-opacity:0.9\" /> \n"
            }
        });

        svg = "<svg style=\"background-color:#1f6da9\" viewBox=\"0 0 " +  (w.max - w.min + 2 * offset) + " " + (h.max - h.min + 2 * offset) + "\" width=\"600\" height=\"" + (600 * ((h.max-h.min) / (w.max - w.min))) + "\">" + svg + "</svg>";
        Vue.set(this.screens,"svgScreens",svg);

        //console.log("updateScreenInfo",w,h, svg, (h.max-h.min) / (w.max - w.min));
    }

    onScreenInfo(value) {
        let svg = "\n";

        this.screens.isExtended = value.isExtended;
        value.screens.forEach((s)=> {
            this.screens.screens.push(s)
            console.log(s);
        });

        this.screens.currentScreen = value.currentScreen;
        this.screens.osSystem = value.osSystem;
        const systemViewKey = JSON.parse(JSON.stringify(value.systemViewKey));

        if (systemViewKey) {
            systemViewKey.forEach((s) => {
                delete s.availTop;
                delete s.availLeft;
                delete s.availWidth;
                delete s.availHeight;
            });

            this.screens.systemViewKey = hexMD5(JSON.stringify(systemViewKey)+"#"+value.osSystem);
        } else {
            this.screens.systemViewKey = "UNKNOWN";
        }

        this.updateScreensSvg();
        console.log(JSON.stringify(this.screens));
    }

    checkHeartbeat(channel, source) {
        if (this.owner && this.owner.channel) {
            //console.log(source, this.id);
            let send = JSON.parse(JSON.stringify({action : "resHeartbeat", source: source, id: this.id}));
            this.owner.channel.postMessage(send);
        }
    }

    updateScreenInfo(data) {
        let found = false;
        //console.log('updateScreenInfo',data)
        this.userViews.currentWindows.forEach((s) => {
            if (s.id == data.id) {
                found = true;
                s.x = data.pos.x;
                s.y = data.pos.y;
                s.h = data.pos.h;
                s.w = data.pos.w;
                s.source = data.source;
                s.main = data.main;
                s.tf = data.tf || "";
            }
        });
        if (!found) {
            this.userViews.currentWindows.push({
                x : data.pos.x,
                y : data.pos.y,
                h : data.pos.h,
                w : data.pos.w,
                id: data.id,
                source: data.source,
                main: data.main,
                tf: data.tf || "",
            });
        }
        this.updateScreensSvg();
    }

    checkScreenInfo(channel, source) {
        console.log('checkScreenInfo', source);
        if (this.owner) {
            const pos =  {screenX: window.screenX, screenY: window.screenY, x: window.screenX, y: window.screenY, w: window.outerWidth, h: window.outerHeight}
            this.id = this.id || Utils.uuid();
            console.log(source, this.getScreenHash(pos.screenX, pos.screenY), window.screen, pos, this.id, pos);
            let send = JSON.parse(JSON.stringify({action : "updateScreenInfo", currentScreen: this.getScreenHash(pos.screenX, pos.screenY), source: source, main: source!=="ch" ? false:!this.owner.detach, tf: source!=="ch" ? "" : window.chartHandler.timeframeHistory[0], pos: pos, id: this.id,  responceId: Utils.uuid2()}));
            this.owner.channel.postMessage(send);
        }
    }

    getScreenHash(x,y) {
        let res = JSON.stringify(window.screen);
        if (this.screens && this.screens.screens) {
            this.screens.screens.forEach((s) => {
                if ((s.availTop <= y) && (y <= s.availTop + s.availHeight) &&
                   (s.availLeft <= x && x <= s.availLeft + s.availWidth)) {
                    res = JSON.stringify(s);
                }
            })
        }

        return hexMD5(res);
    }

    setActiveLayout(id) {
        const layout = this.layoutDefinition.filter((l) => { return l.id === id });
        if (layout && (layout.length>0)) {
            this.activeLayout = Object.assign({}, layout[0]);
            Vue.set(this,'activeLayoutId',this.activeLayout.id);
        }
    }

    getChartCount() {
        return this.activeLayout.definition.length;
    }

    getLayout(idx) {
        const index = this.switchLayoutConfig[idx];
        return Object.assign({}, this.activeLayout.definition[index]);
    }

    chartWidth(width, idx, sidebarSize) {
        //console.log('chartWidth', width);
        const index = this.switchLayoutConfig[idx];
        /*if (this.activeLayout.definition[index].w > 11)
            return this.activeLayout.definition[index].w * (width) / 12 - (sidebarSize);
        if (this.activeLayout.definition[index].x > 1)
            return this.activeLayout.definition[index].w * (width) / 12 - (sidebarSize);*/
        return this.activeLayout.definition[index].w * (width) / 12 - (sidebarSize/2);
    }

    chartHeight(height, idx) {
        const index = this.switchLayoutConfig[idx];
        return this.activeLayout.definition[index].h * (height) / 6;
    }

    switchLayout(id1, id2) {
        let idx1 = -1;
        let idx2 = -1;
        id1 = this.switchLayoutConfig[id1];
        id2 = 0;

        this.switchLayoutConfig.forEach((idx, i) => {
            if (id1 === idx)
                idx1 = i;
            if ((id2 === idx) || ((id2===undefined) && (idx === 0)))
                idx2 = i;
        });

        if ((idx1 >= 0) && (idx2 >= 0)) {
            const prev = this.switchLayoutConfig[idx1];
            this.switchLayoutConfig[idx1] = this.switchLayoutConfig[idx2];
            this.switchLayoutConfig[idx2] = prev;
            /*
            if (this.switchLayoutConfig[idx2] === id1) {
                this.switchLayoutConfig[idx1] = id1;
                this.switchLayoutConfig[idx2] = id2;
            } else {
                this.switchLayoutConfig[idx2] = id1;
                this.switchLayoutConfig[idx1] = id2;
            }*/
            this.owner.updateFullLayout();
        }
    }

    isFirst(id) {
        return this.switchLayoutConfig[parseInt(id)] == 0;
    }

    getFirstId() {
        let res = 0;
        this.switchLayoutConfig.forEach((id, i) => {if (id == 0) res = i; });
        return res;
    }
}