<template>
  <div class="multi-chart" id="multi-chart" @contextmenu="$easycm($event,$root,self)" :style="{'background-color':getBackgroundLayoutColor(),'fill':getFontLayoutColor(),'color':getFontLayoutColor()}">
    <div v-if="isShowModalStartup && !isApp && !loadQuerySymbol" class="animated_logo" :style="{'background-color':'#2c2c2c', 'overflow-x':'hidden', 'width': '100vw', 'height': '100vh', 'z-index': '10000000', 'position': 'absolute'}" >
      <div id="logo_start_div" class="logo_start_div">
        <img class="logo_start" src="/src/img/logo.svg" alt=""/>
        <p id="text_start" class="text_start" >Wir machen Trader erfolgreich.</p>
      </div>
    </div>
    <!--<toolbar v-if="toolbar()"

             v-on:custom-event="custom_event"
             v-bind="chart_props()"
             v-bind:config="chart_config()">
    </toolbar>-->

    <!--c-s-context-menu :display="showContextMenu" ref="menu">
      <ul>
        <li class="context-menu-item" v-for="(item, idx) in menuItems" v-bind:key="item.key" v-on:click="onMenuItemClick(item)"> {{item.caption}} </li>
      </ul>
    </c-s-context-menu-->
    <easy-cm :list="cmList().menu"
             @ecmcb="onMenuItemClick"
             :underline="false"
             :itemWidth="230"
             :isAdmin="chartHandler.isAdmin"
             :arrow="true"
              ref="easycm">
    </easy-cm>
    <!--TopModifyPanel ></TopModifyPanel-->
    <TopModifyPanel ref="ModifyPanel" v-show="indCfg!=null" @onLabelEvent="onLabelEvent" :indicator="indCfg" :chartHandler="chartHandler" @onAnimationInfo="onAnimationInfo" :animationLabels="publishLabelActions" :videoLength="publishVideoLength">
      <template slot="header"  >
        ::
      </template>
    </TopModifyPanel>
    <TopFavoritesPanel ref="FavoritesPanel" :chartHandler="chartHandler" v-show="favVisible">
      <template slot="header"  >
        ::
      </template>
    </TopFavoritesPanel>
    <DraggableContainer v-show="showModalAnalysis" @onLabelEvent="onLabelEvent" @onclose="showModalAnalysis=false" :labelActions="publishLabelActions" :indicator="null" :publishText="publishText" :caption="publishName">
      <template slot="main"  >
        <label style="display: block; margin-bottom: 4px">Description</label>
        <!--textarea v-model="publishText" style="height: 120px; width: 370px" placeholder="Description"></textarea-->
        <vue-editor v-model="publishText"></vue-editor>
      </template>
    </DraggableContainer>
    <!--div>
      <p v-for="s in getViewScreens()">{{ s.label }}</p>
    </div-->
    <KeyboardShortcuts
        :favTools="favTools"
        :systemShortcuts="getUserShortcuts()"
        :modelOpen="getIsShortcutModalOpen()"
        @on-shortcut-change="on_shortcut_change"
        @on-close="isShortcutModalOpen = false"
        @tool-selected="on_toolSelect"
        @on_save_shortcuts="on_save_shortcuts"
    />
    <Searchbar class="searchbar" v-if="!isApp" :useMultiScreen="chartHandler.rights.useMultiScreen" :chartHandler="chartHandler" :useLayout="chartHandler.rights.useLayout" :useTemplates="chartHandler.rights.useTemplates" :useSaveCharts="chartHandler.rights.useSaveCharts" :useIndicators="chartHandler.rights.useIndicators" :useSymbols="chartHandler.rights.useSymbols" @take-image="takeScreenShot" @indicator-selected="on_indicatorselected" @saveChart_selected="showModalDialog" @symbol-selected="on_symbolChanged" @splitmode="on_splitmode" @onFocusItem="on_FocusItem" :theme="themes"></Searchbar> <!--:indicatorTemplates="indicatorTemplates" :chartTemplates="chartTemplates"  -->
    <div class="mainbox" >
      <div v-if="userInformations()" class="userInformation">
        <div class="userInformationHead">
          <!--label style="display: block; margin-bottom: 4px">Updateinformationen</label-->
          <p style="text-align:left;" >
            {{userInformations().headline}}
          <span style="float:right;font-size:large">
                    <i v-if="((userInformationsLength()<2) || (selectedUserInfoIndex==0))" class="fa fa-arrow-circle-o-left" style="margin-left: 5px;color:rgba(60,76,79,0.84)"></i>
                    <i v-on:click="prevUserInfo()" v-if="!((userInformationsLength()<2) || (selectedUserInfoIndex==0))" class="fa fa-arrow-circle-o-left" style="margin-left: 5px"></i>
                    <i v-on:click="nextUserInfo()" v-if="!((userInformationsLength()<2) || (selectedUserInfoIndex==userInformationsLength()-1))" class="fa fa-arrow-circle-o-right" style="margin-left: 5px"></i>
                   <i v-if="((userInformationsLength()<2) || (selectedUserInfoIndex==userInformationsLength()-1))" class="fa fa-arrow-circle-o-right" style="margin-left: 5px;color:rgba(60,76,79,0.84)"></i>
          </span>
        </p>
        </div>
        <div v-for="(ui, idxInfo) in userInformations().text" class="userInformationText">
          <p>{{ui}}</p>
        </div>
          <div style="display:flex;justify-content: center">
            <div v-for="(btn,idx) in userInformations().buttons">
              <button v-on:click="confirmUserInfo({btn}, idx)" class="btn btn-primary" style="font-size:small;font-weight: bold;max-height:2em;padding:3px;margin-right:0.3em;margin-left:0.3em">{{btn.caption}}</button>
            </div>
        </div>
      </div>
      <div v-if="liveModeUser()" class="liveModeUser" :style="{'width':(cbox_width(0)-60)+'px'}">
          <span style="float:right;">Bearbeiter: {{ liveModeUser() }}</span>
      </div>
      <div class="innermainbox" v-if="!isApp" >
        <!--toolbar ref="toolbar"
                 v-on:custom-event="custom_event"
                 v-bind="chart_props"
                 v-bind:config="chart_config">
        </toolbar-->
        <LeftToolbar  ref="leftbar" :chartHandler="chartHandler" @tool-selected="on_toolSelect" @open-adminsite="on_OpenAdminSite" @open-multiscreen="showModalMultiScreen=true;" width="50px" />
      </div>
    <!--sidebar-menu class="sideb</div>ar" :menu="menu" :width=""275px'" :collapsed="true" >
<div>
    </sidebar-menu-->
      <div class="innermainboxmain" id="printMe" :style="{'flex': '1 0 1px'}">
        <div class="multi-chart-grid" >

          <grid-layout :layout.sync="chartslayouts"
                       :col-num="12"
                       :margin="[0, 0]"
                       :row-height="(height)/6"
                       :responsive="false"
                       :is-draggable="false"
                       :is-resizable="false"
                       :vertical-compact="false"
                       :use-css-transforms="true"
          >
        <!--<chartbox class="item2" v-for="(chartData, key, i) in charts" :key="key"
              :id="key" :tf="chartData.tf" :index="i" v-bind:data="chartData.data" v-on:timeframe-changed="timeframechanged"
              :width="cbox_width(i)" :height="cbox_height()" :symbol="chartData.symbol" :onchart="chartData.onchart"
              :night="night"
      >
      </chartbox>-->
            <grid-item v-for="(key, idx) in timeframes" v-bind:key="key"
                       :static="true"
                       :x="charts[''+idx].layout.x"
                       :y="charts[''+idx].layout.y"
                       :w="charts[''+idx].layout.w"
                       :h="charts[''+idx].layout.h"
                       :i="charts[''+idx].layout.i">
              <div v-if="getIsLoadingHistoryData() && true" class="animated_loading" style="overflow-x:hidden; background-color:rgba(27,31,44,0.04); position: absolute; width: 90%;height: 90%;z-index: 1" >
                <svg id="svground">
                  <circle r="30" cx="30" xy="30"></circle>
                </svg>
              </div>
              <chartbox :key="''+idx"
                        :id="''+idx" :index="idx" v-bind:data="charts[''+idx].data" v-on:remove-indicator="removedindicator" v-on:modify-indicator="modifyIndicator" v-on:timeframe-changed="timeframechanged"
                        :width="cbox_width(idx)" :height="cbox_height(idx)" :symbol="charts[''+idx].symbol" :onchart="charts[''+idx].onchart" v-on:on_grid_doubletap="on_grid_doubletap"
                        :theme="theme" :chartHandler="chartHandler" :fibData="charts[''+idx].settings" v-on:onSelectTool="onSelectTool"> </chartbox>
            </grid-item>
          </grid-layout>
          <div v-if="showModal">
            <transition name="modal">
              <div id="modal_BOX" class="modal-mask">
                <div class="modal-wrapper">
                  <div id="modal_inner" class="modal-dialog" role="document">
                    <div class="modal-content" :style="{'background-color':getBackgroundLayoutColor(),'color':getFontLayoutColor(),'border': getFontLayoutColor()+' 1px solid'}">
                      <div class="modal-header">
                        <h5 class="modal-title" v-if="showModalType == 'charts'">Save Chart</h5>
                        <h5 class="modal-title" v-if="showModalType == 'template'">Save Template</h5>
                        <h5 class="modal-title" v-if="showModalType == 'watchlist'">New Watchlist</h5>
                        <button v-shortkey="['esc']" @shortkey="showModal = false"  type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" @click="showModal = false">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <form ref="form" @submit="submit">
                          <label style="display: block; margin-bottom: 4px" for="Template Name" v-if="showModalType != 'watchlist'">Template Name</label>
                          <label style="display: block; margin-bottom: 4px" for="Template Name" v-if="showModalType == 'watchlist'">Watchlist Name</label>
                          <div class="akj-input" v-if="showModalType != 'watchlist'">
                            <input type="text" v-model="templateName" placeholder="Enter Chart Name" required />
                          </div>
                          <div class="akj-input" v-if="showModalType == 'watchlist'">
                            <input type="text" v-model="templateName" placeholder="Enter Watchlist Name" required />
                          </div>
                          <br v-if="showModalType == 'charts'" />
                          <!--checkbox v-if="showModalType == 'template'" /-->
                          <div class="akj-input-check" v-if="showModalType == 'template'"><input v-model="makeDefault" type="checkbox" />Set as Default</div>
                          <label style="display: block; margin-bottom: 4px" for="Tags" v-if="showModalType == 'charts'">Tags</label>
                          <div class="akj-input" v-if="showModalType == 'charts'">
                            <input type="text" v-model="templateTags" placeholder="Enter Tags, Separated by a comma" />
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="showModal = false">Cancel</button>
                        <button @click="submit" type="submit" class="btn btn-primary">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>

          <div v-if="showModalMultiScreen">
            <transition name="modal">
              <div id="modal_BOX" class="modal-mask">
                <div class="modal-wrapper">
                  <div id="modal_inner" class="modal-dialog" role="document" style="max-width:none;width:auto">
                    <div class="modal-content" :style="{'background-color':getBackgroundLayoutColor()}">
                      <div class="modal-header">
                        <h5 class="modal-title">Save Multiscreen View</h5>
                        <button v-shortkey="['esc']" @shortkey="showModalMultiScreen = false"  type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" @click="showModalMultiScreen = false">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="width: 100%">
                        <div v-html="svgToolTip.svgScreens"></div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="showModalMultiScreen = false">Cancel</button>
                        <button @click="saveMultiScreen" type="submit" class="btn btn-primary">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div v-if="showModalLoadMultiScreen && chartHandler.layoutHandler.userViews.currentView && isAdmin && !chartHandler.detach">
            <transition name="modal">
              <div id="modal_BOX" class="modal-mask">
                <div class="modal-wrapper">
                  <div id="modal_inner" class="modal-dialog" role="document" style="max-width:none;width:auto">
                    <div class="modal-content"  :style="{'background-color':getBackgroundLayoutColor(),'border': getFontLayoutColor()+' 1px solid',}">
                      <div class="modal-header">
                        <h6 class="modal-title">Load Multiscreen View</h6>
                        <button v-shortkey="['esc']" @shortkey="showModalLoadMultiScreen = false"  type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" @click="showModalLoadMultiScreen = false">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body" style="width: 100%">
                        <div>
                          <span class="modal-title">Es wurde eine Monitorkonfiguration für dieses System erkannt! </span>
                          <p></p>
                        </div>

                        <div></div>
                        <div v-html="svgToolTip.svgScreens"></div>
                        <div>
                          <p></p>
                          <span class="modal-title">Möchten Sie diese Konfiguration öffnen?</span>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="showModalLoadMultiScreen = false">Cancel</button>
                        <button @click="loadMultiScreen" type="submit" class="btn btn-primary">Load</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div v-if="showModalCreateAnalysis">
            <transition name="modal">
              <div id="modal_BOX" class="modal-mask">
                <div class="modal-wrapper" id="modal-analysis">
                  <div id="modal_inner_" class="modal-dialog_" role="document">
                    <div class="modal-content"  :style="{'background-color':getBackgroundLayoutColor()}">
                      <div class="modal-header">
                        <h5 v-if="((currentChartId>0) || (publishId >0))" class="modal-title" >Modify analysis</h5>
                        <h5 v-if="!(((currentChartId>0) || (publishId >0)))" class="modal-title" >Publish new analysis</h5>
                        <button v-shortkey="['esc']" @shortkey="showModalCreateAnalysis = false"  type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" @click="showModalCreateAnalysis = false">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <form ref="form" @submit="setOnlineFromDialog">
                          <label style="display: block; margin-bottom: 4px">Analysis Name</label>
                          <input style="width: 79em" type="text" v-model="publishName" placeholder="Enter Analysis Name" required />
                          <br>
                          <label style="display: block; margin-bottom: 4px;margin-top: 0.5em">kurze Beschreibung</label>
                          <input style="width: 79em" type="text" v-model="publishDescription" placeholder="Kurzbeschreibung" required />
                          <br>
                          <label style="display: block; margin-bottom: 4px;margin-top: 0.5em">interner Kommentar</label>
                          <input style="width: 79em" type="text" v-model="publishCommentInternal" placeholder="interner Kommentar" required />
                          <br>
                          <label style="display: block; margin-bottom: 4px">Analyse</label>
                          <!--textarea v-model="publishText" style="height: 120px; width: 370px" placeholder="Description"></textarea-->
                          <vue-editor v-model="publishText"></vue-editor>
                          <div class="preference">
                            <label for="check_users" style="display: block; margin-bottom: 4px">Visible: </label>
                            <input :disabled="!canPublish" type="checkbox" id="check_users" v-model="publishedAnalysis">
                          </div>
                          <label style="display: block; margin: .5rem;">Visible to following abos: </label>
                          <div style="border:1px solid #cb9a2c;margin:0.3em">
                            <div class="preference" v-for="(abo, idx) in publishAbos" :id=abo.id v-bind:key=abo.id>
                              <label style="display: block; margin-bottom: 4px">{{ abo.title}}</label>
                              <input type="checkbox" v-model="publishedAbos[idx]">
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="showModalCreateAnalysis = false">Cancel</button>
                        <button v-if="!(publishName && publishText)" class="btn btn-primary disabled" disabled="true">Publish</button>
                        <button v-if="publishName && publishText" @click="setOnlineFromDialog" type="submit" class="btn btn-primary">Publish</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>


          <!--div v-if="showModalAnalysis">
            <transition name="modal">
              <div id="modal_BOX" class="modal-mask">
                <div class="modal-wrapper" id="modal-analysis">
                  <div id="modal_inner_" class="modal-dialog_" role="document">
                    <div class="modal-content" >
                      <div class="modal-header">
                        <h5 class="modal-title" >Show analysis</h5>
                        <button v-shortkey="['esc']" @shortkey="showModalAnalysis = false"  type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" @click="showModalAnalysis = false">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                          <label style="display: block; margin-bottom: 4px">Description</label>
                          <vue-editor v-model="publishText"></vue-editor>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="showModalAnalysis = false">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div-->


          <div v-if="showUserRights">
            <transition name="modal">
              <div id="modal_BOX_RIGHTS" class="modal-mask">
                <div class="modal-wrapper">
                  <div id="modal_inner_rights" class="modal-dialog" role="document">
                    <div class="modal-content" style="border:1px solid #eeeeee;margin:0.3em;padding:1.5em">
                      <div class="modal-header">
                        <h5 class="modal-title" >Modify User Rights/Abos</h5>
                        <button v-shortkey="['esc']" @shortkey="showUserRights = false"  type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" @click="showUserRights = false">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <form ref="form">
                          <label style="display: block; margin-bottom: 4px">Select User:</label>
                          <!--v-select :options="[{label: 'Canada', code: 'ca'}]"></v-select-->
                          <v-select class="style-chooser" v-model="selectedUserId" :options="rows" label="label" :reduce="(option) => option.userId" @input="setSelectedAbos" />
                          <br>
                          <div style="margin:0.3em;display: flex">
                            <div style="border:1px solid rgba(255,255,255,0.7);margin:0.3em;width:50%;padding:1.5em">
                              <label style="display: block; margin: .5rem;text-decoration: underline;">Select following rights: </label>
                              <br>
                              <div class="preference" v-for="(r, idx) in rights" v-bind:key=r.rightId>
                                <label style="display: block; margin-bottom: 4px">{{ r.title}}</label>
                                <input type="checkbox" v-model="getUserRights[idx]" @click="setUserRight(r.rightId, idx)">
                              </div>
                            </div>
                            <div style="border:1px solid rgba(255,255,255,0.7);width:50%;margin:0.3em;padding:1.5em">
                              <label style="display: block; margin: .5rem;width:50%;text-decoration: underline;">Select following abos: </label>
                              <br>
                              <div class="preference" v-for="(abo, idx) in abos" v-bind:key=abo.rightId>
                                <label style="display: block; margin-bottom: 4px">{{ abo.title}}</label>
                                <input type="checkbox" v-model="getUserAbos[idx]" @click="setUserAbo(abo.rightId, idx)" >
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer">
                        <button class="btn btn-primary" @click="showUserRights = false">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <div v-if="updatedAnalysis.id>0">
          <transition name="modal">
            <div id="modal_BOX" class="modal-mask">
              <div class="modal-wrapper">
                <div id="modal_inner" class="modal-dialog" role="document">
                  <div class="modal-content"  :style="{'background-color':getBackgroundLayoutColor()}">
                    <div class="modal-header">
                      <h5 class="modal-title" >Analysis Update</h5>
                      <button v-shortkey="['esc']" @shortkey="resetInfoChartUpdate()"  type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" @click="resetInfoChartUpdate()">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                        <label>The author of this analysis updated the chart.</label>
                        <label>If you would like to display the latest status, then press Refresh.</label>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-secondary" @click="resetInfoChartUpdate()">Cancel</button>
                      <button @click="loadChart(updatedAnalysis.id)" type="submit" class="btn btn-primary">Refresh</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
          <div v-if="showModalAdmin && !isApp">
            <transition name="modal">
              <div id="modal_ADMINBOX" class="modal-mask">
                <div class="modal-wrapper">
                  <div id="modal_innerAdmin" class="modal-dialogAdmin" role="document">
                    <div class="modal-contentAdmin">
                      <div class="modal-header" style="color:rgba(0,0,0,0.72)">
                        <h5 class="modal-title">Admin</h5>
                      </div>
                      <div class="modal-body" style="font-size: 16px;color:rgb(0,0,0)">
                        <div class="w3-bar w3-black">
                          <button class="admin-bar-item w3-button" v-on:click="setAdminTab('Users')">User</button>
                          <button class="admin-bar-item w3-button" v-on:click="setAdminTab('Alerts')">Alerts</button>
                          <input v-if="showTabs=='Alerts' || showTabs=='update#Alerts'"type="text" name="alterFilter" value="" v-on:change="updateAlertSearch" v-on:input="updateAlertSearch">
                        </div >
                        <hr style="margin-bottom: 8px;margin-top: 2px">
                        <div v-if="showTabs=='Test1'" style="height:75vh">
                          <p>Test1 Seite</p>
                        </div>
                        <div v-if="showTabs=='Test2'" style="height:75vh">
                          <p>Test2 Seite</p>
                        </div>
                        <div v-if="showTabs=='update#Alerts'" style="height:75vh">
                          <div class="loader">
                            <div class="inner one"></div>
                            <div class="inner two"></div>
                            <div class="inner three"></div>
                          </div>
                        </div>
                        <div v-if="showTabs=='Alerts'" style="height:75vh">
                          <v-grid
                              ref="alertGrid"
                              row-headers="true"
                              theme="compact"
                              :source="alertRows"
                              :columns="columnsAlerts"
                          ></v-grid>
                        </div>
                        <div v-if="showTabs=='Users'" style="height:75vh">
                            <v-grid
                            ref="userGrid"
                            row-headers="true"
                            theme="default"
                            :source="rows"
                            :columns="columns"
                          ></v-grid>
                        </div>
                      </div>
                      <div class="modal-footer" style="background-color:#ffffff">
                        <button type="button" class="btn btn-secondary" @click="showModalAdmin = false">Cancel</button>
                        <button @click="submit" type="submit" class="btn btn-primary">Save</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </transition>
          </div>
        </div>
      </div>

      <div class="right_sidebar" v-if="showRightBar && !isApp" :style="{'height':'calc(100vh - 10px)','background-color':getBackgroundLayoutColor(),'fill':getFontLayoutColor(),'color':getFontLayoutColor()}">
      <span v-shortkey="['esc']" @shortkey="showSideBar('hide')" v-on:click="showSideBar('hide')" class="close-icon"></span>
      <div id="watchlist" v-if="getShowWatchList()" style="padding: 8px;">
        <h3 style="margin-top: 15px;margin-left: 7px;font-size: 20px;margin-bottom: 15px">Your Watchlist</h3>
        <div id="watch_list"></div>
        <!--ul class="dropdown-menu" >
          <li v-for="(option) in defThemes" setting="theme" :value="option.name">{{option.theme.name}}</li>
        </ul-->
        <div v-for="(wl) in watchLists" :id=wl.list_name v-bind:key=wl.name class="accordion-item">
          <div class="accordion-header watchlist_header" v-on:click="onShowWatchList(wl.marketName)">{{wl.marketName}}<img v-if="wl.userId>0" v-on:click="onDeleteWatchList(wl.marketName)" class="icon-delete" style="float: right; width: 18px; padding-top: 11px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAQMAAAD+JxcgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAATU1NkJ+rOQAAAAJ0Uk5TAP9bkSK1AAAALUlEQVR4nGNgAIN6ENHQACX4//9gYBBgYIESYC4LkA0lPEkmGFAI5v8PILYCAHygDJxlK0RUAAAAAElFTkSuQmCC"></div>
          <div class="accordion-body" >
            <table>
              <thead><tr><th v-if="!(wl.userId>0)" style="width:150px">Symbol</th><th v-if="wl.userId>0" style="width:130px">Symbol</th><th style="text-align: right; width:105px">Price</th><th style="text-align: right; width:45px">%</th><th v-if="wl.userId>0" style="text-align: left; width:20px"></th></tr></thead>
              <tbody :id=wl.marketName>
                <tr v-for="(wld) in wl.details" :key=wld.symbol :id=wld.symbol class="tr">
                  <td v-on:click="addToChart($event, wl.marketName, wld.symbol)" >{{wld.symbol}}</td>
                  <td v-on:click="addToChart($event, wl.marketName, wld.symbol)"  style="text-align: right; width:100px">{{wld.price}}</td>
                  <td v-on:click="addToChart($event, wl.marketName, wld.symbol)"  :style="{'text-align': 'right','color':getDirColor(wld.procent), 'width':'100px',}">{{wld.procent.toFixed(2)}}</td>
                  <td v-if="wl.userId>0" ><span class="close-icon" v-on:click="deleteMarketListDetail(wld.symbol,wl.marketListId)" style="display: inline-block; position: relative; top: initial; right: initial; height: 14px;padding-top: 2px;;margin-left:-5px"></span></td>
                </tr>
            </tbody></table></div>
        </div>
        <!--div id="list_Sven4" class="accordion-item">
          <div class="accordion-header watchlist_header">Sven4<img onclick="showConfirmModal('del-watchlist', 'Sven4', event)" class="icon-delete" style="float: right; width: 18px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAQMAAAD+JxcgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAATU1NkJ+rOQAAAAJ0Uk5TAP9bkSK1AAAALUlEQVR4nGNgAIN6ENHQACX4//9gYBBgYIESYC4LkA0lPEkmGFAI5v8PILYCAHygDJxlK0RUAAAAAElFTkSuQmCC"></div>
          <div class="accordion-body">
            <table>
              <thead><tr><th>Symbol</th><th>Price</th><th>Change(%)</th><th></th></tr></thead><tbody id="watchlist_Sven4">
            <tr class="tr" id="Sven4_ETHUSDT.BINANCE" onclick="addToChart(event, 'Sven4', 'ETHUSDT.BINANCE')">
              <td>ETHUSDT.BINANCE</td>
              <td>1.2986</td>
              <td>0.75%</td>
              <td><span class="close-icon" style="display: inline-block; position: relative; top: initial; right: initial; height: 14px;"></span></td>
            </tr></tbody></table></div>
        </div>
        <div id="list_Sven2" class="accordion-item">
          <div class="accordion-header watchlist_header">Sven3<img onclick="showConfirmModal('del-watchlist', 'Sven1', event)" class="icon-delete" style="float: right; width: 18px" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAQMAAAD+JxcgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAATU1NkJ+rOQAAAAJ0Uk5TAP9bkSK1AAAALUlEQVR4nGNgAIN6ENHQACX4//9gYBBgYIESYC4LkA0lPEkmGFAI5v8PILYCAHygDJxlK0RUAAAAAElFTkSuQmCC"></div>
          <div class="accordion-body">
            <table>
              <thead><tr><th>Symbol</th><th>Price</th><th>Change(%)</th><th></th></tr></thead><tbody id="watchlist_Sven2">
            <tr class="tr" id="Sven2_ETHUSDT.BINANCE" onclick="addToChart(event, 'Sven2', 'ETHUSDT.BINANCE')">
              <td>ETHUSDT.BINANCE</td>
              <td>1.2986</td>
              <td>0.75%</td>
              <td><span class="close-icon" style="display: inline-block; position: relative; top: initial; right: initial; height: 14px;"></span></td>
            </tr></tbody></table></div>
        </div-->


        <div class="btn_detach hide_on_detach" v-if="!isApp" onclick="detach_watchlist()" style="position: absolute;bottom: 20px;right: 20px;">
          <span class="icon"></span>
          <span class="tooltip">Detach Watchlist</span>
        </div>
      </div>
      <div id="indicator_settings" v-if="getShowIndCfg() && !isApp" style="padding: 8px 8px 100px;">
        <h3 style="margin-top: 15px;margin-left: 7px;font-size: 20px;margin-bottom: 15px">
          Indicators & Tools Settings
        </h3>
        <div onclick="toggleOffcharts(event)" class="btn" id="btn-offcharts" style="text-align: left;display: inline-block;margin-bottom: 12px;">
          Hide Offcharts
        </div>
        <div class="field-group">
          <label>Show Drawings</label>
          <div class="akj-input akj-input-check">
            <input type="checkbox" checked="showDrawings" v-on:click="akj_event('show-drawings',  $event.target.checked)" />
          </div>
        </div>
        <IndicatorConfig :indicator-config="getIndicatorConfig()"></IndicatorConfig>
      </div>
      <div id="chart_settings" v-if="getShowCfg() && !isApp" style="padding: 8px;">
        <h3 style="margin-top: 15px;margin-left: 7px;font-size: 20px;margin-bottom: 15px">Chart Settings</h3>
        <div id="chart_settings_list" style="padding: 0 12px;">
          <div class="field-group">
            <label>Auto Save</label>
            <div class="akj-input akj-input-check">
              <input type="checkbox" v-on:click="akj_event('set-chart-settings', ['isAuto-save', $event.target.checked])" id="chart-setting-autosave" v-model="chartHandler.chartSettings.isAutoSave"/>
            </div>
          </div>
          <div class="field-group">
            <label>Magnet Mode</label>
            <div class="akj-input akj-input-check">
              <input type="checkbox" v-on:click="akj_event('set-chart-settings', ['magnet-mode', $event.target.checked])" id="chart-setting-magnetmode" v-model="chartHandler.chartSettings.isMagnetMode"/>
            </div>
          </div>
          <!--div class="field-group">
            <label>Show Grid</label>
            <div class="akj-input akj-input-check">
              <input type="checkbox" v-on:click="akj_event('set-chart-settings', ['show-grid', $event.target.checked])" id="chart-setting-showgrid" v-model="chartHandler.chartSettings.isGrid"/>
            </div>
          </div-->
          <div class="field-group">
            <label>Auto Fibs</label>
            <div class="akj-input akj-input-check">
              <input type="checkbox" checked="true" v-on:click="akj_event('set-chart-settings', ['show-autofibs', $event.target.checked])" id="chart-setting-showautofibs" v-model="chartHandler.chartSettings.isAutoFibs"/>
            </div>
          </div>
          <div class="field-group">
            <label>Auto Horizontals</label>
            <div class="akj-input akj-input-check">
              <input type="checkbox" checked="true" v-on:click="akj_event('set-chart-settings', ['show-autohori', $event.target.checked])" id="chart-setting-showautohori" v-model="chartHandler.chartSettings.isAutoHori"/>
            </div>
          </div>
          <div class="field-group">
            <label>High/Lows</label>
            <div class="akj-input akj-input-check">
              <input type="checkbox" v-on:click="akj_event('set-chart-settings', ['show-highlows', $event.target.checked])" id="chart-setting-showhighlows" v-model="chartHandler.chartSettings.isHighLows"/>
            </div>
          </div>
          <div class="field-group">
            <label>Auto lines</label>
            <div class="akj-input akj-input-check">
              <input type="checkbox" v-on:click="akj_event('set-chart-settings', ['show-autolines', $event.target.checked])" id="chart-setting-showautolines" v-model="chartHandler.chartSettings.isAutoLines">
            </div>
          </div>
          <div class="field-group">
            <label>Auto Price Gaps</label>
            <div class="akj-input akj-input-check">
              <input type="checkbox" v-on:click="akj_event('set-chart-settings', ['show-autopricegaps', $event.target.checked])" id="chart-setting-autopricegaps" v-model="chartHandler.chartSettings.isAutoPriceGaps">
            </div>
          </div>
          <div class="field-group">
            <label>Default candles</label>
            <div class="akj-input">
              <input type="number" v-on:change="akj_event('set-chart-settings', ['default-len', $event.target.value])" id="chart-setting-defaultlen" min="2" v-model="chartHandler.chartSettings.defaultLen" />
            </div>
          </div>
          <!-- Default timeframe for watchlist >
          <div class="field-group">
            <label>Watchlist Timeframe</label>
            <div class="dropdown dropdown-line">
              <div class="dropdown-selector" id="watchlist_timeframe">
                <div class="selected-value" v-model="chartHandler.chartSettings.timeframe">
                  M15
                </div>
                <div class="control_wrapper" data-toggle="dropdown">
                  <div class="decreaseControl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8">
                      <path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path>
                    </svg>
                  </div>
                </div>
              </div>
                <ul class="dropdown-menu" >
                  <li v-for="(option,i) in defTimeFrames" setting="watchlist_timeframe" :value="option.value">{{option.value}}</li>
                </ul>
            </div>
          </div-->
          <!-- Gridmarker -->
          <div class="field-group">
            <label>Griddarstellung</label>
            <div class="dropdown dropdown-line">
              <div class="dropdown-selector" id="isGrid">
                <div class="selected-value">
                  {{getCurrentConfigValue('grid')}}
                </div>
                <div class="control_wrapper">
                  <div class="decreaseControl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8">
                      <path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <ul class="dropdown-menu" >
                <li v-for="(option) in defGrid" setting="isGrid" :value="option.name">{{option.name}}</li>
              </ul>
            </div>
          </div>
          <!-- LogScale -->
          <div class="field-group">
            <label>Anzeige Preisskala</label>
            <div class="dropdown dropdown-line">
              <div class="dropdown-selector" id="logScale">
                <div class="selected-value">
                  {{getCurrentConfigValue('logScale')}}
                </div>
                <div class="control_wrapper">
                  <div class="decreaseControl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8">
                      <path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <ul class="dropdown-menu" >
                <li v-for="(option) in defLogScale" setting="logScale" :value="option.name">{{option.name}}</li>
              </ul>
            </div>
          </div>
          <!-- Theme -->
          <div class="field-group">
            <label>Theme</label>
            <div class="dropdown dropdown-line">
              <div class="dropdown-selector" id="theme">
                <div class="selected-value">
                  {{getCurrentConfigValue('theme')}}
                </div>
                <div class="control_wrapper">
                  <div class="decreaseControl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8">
                      <path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <ul class="dropdown-menu" >
                <li v-for="(option) in defThemes" setting="theme" :value="option.name">{{option.theme.name}}</li>
              </ul>
            </div>
          </div>
          <!-- Timezone selector -->
          <div class="field-group">
            <label>Time Zone</label>
            <div class="dropdown dropdown-line">
              <div class="dropdown-selector">
                <div class="selected-value" id="chart-setting-settimezone">
                  Exchange
                </div>
                <div class="control_wrapper">
                  <div class="decreaseControl">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8">
                      <path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path>
                    </svg>
                  </div>
                </div>
              </div>
              <ul id="dropdown-menu-timezone" class="dropdown-menu">
                <li setting="timezone" value="X:XX">Exchange</li>
                <li setting="timezone" value="0:00">Greenwich Mean Time (GMT)</li>
                <li setting="timezone" value="0:00">Universal Coordinated Time (GMT)</li>
                <li setting="timezone" value="+1:00">European Central Time (GMT+1:00)</li>
                <li setting="timezone" value="+2:00">Eastern European Time (GMT+2:00)</li>
                <li setting="timezone" value="+2:00">(Arabic) Egypt Standard Time (GMT+2:00)</li>
                <li setting="timezone" value="+3:00">Eastern African Time (GMT+3:00)</li>
                <li setting="timezone" value="+3:30">Middle East Time (GMT+3:30)</li>
                <li setting="timezone" value="+4:00">Near East Time (GMT+4:00)</li>
                <li setting="timezone" value="+5:00">Pakistan Lahore Time (GMT+5:00)</li>
                <li setting="timezone" value="+5:30">India Standard Time (GMT+5:30)</li>
                <li setting="timezone" value="+6:00">Bangladesh Standard Time (GMT+6:00)</li>
                <li setting="timezone" value="+7:00">Vietnam Standard Time (GMT+7:00)</li>
                <li setting="timezone" value="+8:00">China Taiwan Time (GMT+8:00)</li>
                <li setting="timezone" value="+9:00">Japan Standard Time (GMT+9:00)</li>
                <li setting="timezone" value="+9:30">Australia Central Time (GMT+9:30)</li>
                <li setting="timezone" value="+10:00">Australia Eastern Time (GMT+10:00)</li>
                <li setting="timezone" value="+11:00">Solomon Standard Time (GMT+11:00)</li>
                <li setting="timezone" value="+12:00">New Zealand Standard Time (GMT+12:00)</li>
                <li setting="timezone" value="-11:00">Midway Islands Time (GMT-11:00)</li>
                <li setting="timezone" value="-10:00">Hawaii Standard Time (GMT-10:00)</li>
                <li setting="timezone" value="-9:00">Alaska Standard Time (GMT-9:00)</li>
                <li setting="timezone" value="-8:00">Pacific Standard Time (GMT-8:00)</li>
                <li setting="timezone" value="-7:00">Phoenix Standard Time (GMT-7:00)</li>
                <li setting="timezone" value="-7:00">Mountain Standard Time (GMT-7:00)</li>
                <li setting="timezone" value="-6:00">Central Standard Time (GMT-6:00)</li>
                <li setting="timezone" value="-5:00">Eastern Standard Time (GMT-5:00)</li>
                <li setting="timezone" value="-5:00">Indiana Eastern Standard Time (GMT-5:00)</li>
                <li setting="timezone" value="-4:00">Puerto Rico and US Virgin Islands Time (GMT-4:00)</li>
                <li setting="timezone" value="-3:30">Canada Newfoundland Time (GMT-3:30)</li>
                <li setting="timezone" value="-3:00">Argentina Standard Time (GMT-3:00)</li>
                <li setting="timezone" value="-3:00">Brazil Eastern Time (GMT-3:00)</li>
                <li setting="timezone" value="-1:00">Central African Time (GMT-1:00)</li>
              </ul>
            </div>
          </div>
          <div class="field-group">
            <label>Keyboard Shortcuts</label>
            <div class="akj-input akj-input-check">
              <button class="btn btn-ghost btn-primary" v-on:click="isShortcutModalOpen=true;">View / Edit</button>
            </div>
          </div>
        </div>
      </div>
    </div>

      <div class="inner_chartbar" v-if="!isApp && getRightOffset>100" :style="{'padding-left':'0.7em',width:(getRightOffset-25)+'px', 'height':height+'px'}">
        <div v-if="getMessageMode" style="margin: 0 auto;color: #ffffff; margin: 1.0em; font-size: large"> <strong> Messages </strong></div>
        <div v-if="getAlertMode" style="margin: 0 auto;color: #ffffff; margin: 1.0em; font-size: large"> <strong> Alerts </strong>
          <span style="float:right;font-size:large">

            <i v-on:click="filterAlerts=true" v-if="!filterAlerts" class="fa fa-filter" style="margin-left: 5px;color:rgba(60,76,79,0.84)"></i>
            <i v-on:click="filterAlerts=false" v-if="filterAlerts" class="fa fa-filter" style="margin-left: 5px"></i>
          </span>
        </div>
        <div v-if="getPulishVideos" style="margin: 0 auto;color: #ffffff; margin: 1.0em; font-size: large"> <strong> Videos </strong></div>
        <div v-if="getDebuggerMode" style="margin: 0 auto; margin: 1.0em; font-size: large"> <strong> Debuggerinfos </strong></div>
        <div v-if="getLiveMode" style="margin: 0 auto;color: #ffffff; margin: 1.0em; font-size: large">
          <!--i v-if="isLiveOnline" v-on:click="setOnline(false)" class="fa fa-share-alt" style="font-size:24px;color:#228f1a"></i-->
          <div style="display: grid;">
            <div>
              <strong> Live Analysis </strong>
              <span style="float:right;margin-right:0.6em">
              <i v-if="isAdmin && !((currentChartId>0) || (isLiveOnlineId >0))" class="fa fa-edit" style="font-size:24px;color:#434549"></i>
              <i v-if="isAdmin && ((currentChartId>0) || (isLiveOnlineId >0))" v-on:click="setOnline(true, false)" class="fa fa-edit" style="font-size:24px;color:#6a76be"></i>
              <i v-if="isAdmin" v-on:click="setOnline(true, true)" class="fa fa-share-alt" style="font-size:24px;color:#6a76be"></i>

              <i v-if="isAdmin && !useUserRights" class="fa fa-users" style="font-size:20px;color:#434549;margin-left:0.5em"></i>
              <i v-if="isAdmin && useUserRights" v-on:click="showUserRightsDialog()" class="fa fa-users" style="font-size:20px;color:#c4951e;margin-left:0.5em"></i>
            </span>

            </div>
            <v-select class="style-chooser" id="selectedRightList" v-model="selectedRightId" :options="publishAbos" label="label" :reduce="(option) => option.rightId" @input="setSelectedAnalysis"  style="margin-top:0.3em" />
          </div>
        </div>
        <!--p v-for="(key, idx) in messages" ></p-->
        <div :style="{padding: '.2em',bottom: '0',position: 'absolute'}">
          <div v-if="getMessageMode" id="messagesM" :style="{'margin-left': '0.7em',width: '255px', bottom: '180px',position: 'absolute',overflow:'scroll','overflow-x':'hidden',height:(height-230)+'px',}">
            <div v-for="item in instantmessages" :key="item.id" :style="{color: '#ffffff', margin: '0.4em', padding: '0.4em',border: '1px solid rgba(208,208,208,0.29)', 'margin-bottom': '0.2em', 'max-width': '258px'}">
              <p style="text-align:left;color: rgba(208,208,208,0.43);margin-bottom: 0.3em;font-size: small">
                {{getShortTimeFormat(item.time)}}
                <span style="float:right;color: rgba(208,208,208,0.43);">
                    <strong >{{ item.from }}</strong>
                  </span>
              </p>
              <em style="word-wrap: break-word;">{{ item.text }}</em>
            </div>
          </div>
          <div v-if="getAlertMode" id="messagesA" :style="{'margin-left': '0.7em',width: '255px', bottom: '25px',position: 'absolute',overflow:'scroll','overflow-x':'hidden',height:(height-75)+'px',}">
            <div v-for="item in liveAlerts()" :key="item.key" :style="{color: '#ffffff', margin: '0.4em', padding: '0.4em',border: '1px solid rgba(208,208,208,0.29)', 'margin-bottom': '0.2em', 'max-width': '258px'}">
              <p style="text-align:left;color: rgba(208,208,208,0.43);margin-bottom: 0.3em;font-size: small" >
                {{getShortTimeFormat(item.lastIn)}}
                <span style="float:right;color: rgba(208,208,208,0.43);">
                  <strong v-on:click="loadChart(-1, item.name, item.timeBase)">{{ item.name }} - {{ item.timeBase }}</strong>
                </span>
              </p>
              <em style="word-wrap: break-word;">{{ item.rule }}</em>
              <br>
              <em style="word-wrap: break-word;">{{ item.message }}</em>

              <div>
                <p style="text-align:left;color: rgba(208,208,208,0.43);margin-bottom: 0.3em;font-size: small" >
                  <i v-on:click="loadChart(-1, item.name, item.timeBase)" class="fa fa-line-chart" style="font-size:15px;color:#d0d0d0">  view chart</i>
                </p>
              </div>
            </div>
          </div>
          <div v-if="getPulishVideos" id="messagesV" :style="{'margin-left': '0.7em',width: '255px', bottom: '420px',position: 'absolute',overflow:'scroll','overflow-x':'hidden',height:(height-465)+'px',}">
            <div v-for="item in liveVideos" :key="item.key" :style="{'background-color': 'rgba(27,31,44,0.91)', color: '#ffffff', margin: '0.4em', padding: '0.4em',border: '1px solid rgba(208,208,208,0.29)', 'margin-bottom': '0.2em'}">
              <!--p style="text-align:left;color: rgba(208,208,208,0.43);margin-bottom: 0.3em;font-size: small" >
                {{item.url_parameter}}
                <br>{{ item.title }}
                <span style="float:right;color: rgba(208,208,208,0.43);">

                </span>
              </p-->
              <strong>{{ item.title }}</strong>
              <div style="color: #888888">
                {{ item.desc }}
              </div>
            </div>
          </div>

          <div v-if="getMarketViewMode" id="marketoverview" >
            <div >
              <v-select :class="{'style-chooser':true, 'dark': isDark(), 'padding-left':'0.1em'}" id="selectedMarketViewList" @input="setSelectedMO" :clearable="false" v-model="selectedmarketOverviewId" :options="getMarketOverviewList" label="marketName" :reduce="(option) => option.marketListId" :style="{width: '255px', 'margin-bottom': '0.2em',bottom: (height-50)+'px'}" />
            </div>
            <div :style="{'margin-left': '0.01em',width: '265px', bottom: '5px',position: 'absolute',overflow:'scroll','overflow-x':'hidden',height:(height-60)+'px',}">
              <market-overview-item :item="getMarketOverviewItem" :windowheight="((height-15)/5)" :draggable="draggable" :trendvisible="false" v-on:changeSymbol="changeSymbol" v-on:changeDirection="changeDirection"></market-overview-item>
            </div>
          </div>

          <div v-if="getAccountMode"  id="accountInfos" >
            <h6 style="text-align:left;margin-top:30px;" >Account</h6>
            <div style="margin-top:1em">
              <span style="width:4em;display:inline-block;text-align:left;" >Nutzer:</span>
              <span style="text-align:center;margin-top:30px;" >{{ userData.user }}</span>
            </div>
            <div style="margin-top:1em">
              <span style="width:4em;display:inline-block;text-align:left;" >E-Mail:</span>
              <span style="text-align:center;margin-top:30px;" >{{ userData.email }}</span>
            </div>
            <h6 style="text-align:left;margin-top:40px;" >verfügbare Packete</h6>
            <div :style="{'margin-left': '0.01em',width: '265px',overflow:'scroll','overflow-x':'hidden',height:(height-170)+'px',}">
              <div v-for="abo in userDataAbos" :key="abo.id" :style="{'border': getFontLayoutColor()+' 0.5px solid','padding':'0.7em 0.8em 0em 0.8em','margin':'0em 0.3em 0.3em 0em','background-color': getBackgroundLightLayoutColor()}">
                <div style="display:inline-flex;margin:0.3em 0.1em 0.4em 0.2em">
                  <span style="font-weight: bold" class="modal-title">{{abo.title}}</span>
                </div>
                <div style="display:inline-flex;margin:0.3em 0.1em 0.3em 0.2em">
                  <span  style="width:11em" class="modal-title">aktueller Preis:</span>
                  <span  class="modal-title">{{abo.booking_price || abo.current_price}} Euro</span>
                </div>
                <div v-if="abo.valid_until_date" style="display:inline-flex;margin:0.3em 0.1em 0.3em 0.2em">
                  <span  style="width:11em" class="modal-title">Abo gültig bis:</span>
                  <span  class="modal-title">{{formatDateStrDisplay(abo.valid_until_date)}}</span>
                </div>
                <div v-if="abo.current_price_valid_until && (abo.state==0)" style="display:inline-flex;margin:0.3em 0.1em 0.3em 0.2em">
                  <span  style="width:11em" class="modal-title">Angebot gültig bis:</span>
                  <span  style="float: right" class="modal-title">{{formatDateStrDisplay(abo.current_price_valid_until)}}</span>
                </div>
                <div v-if="abo.trail_until_date && !abo.valid_until_date" style="display:inline-flex;margin:0.3em 0.1em 0.3em 0.2em">
                  <span  style="width:11em" class="modal-title">Abo gültig bis:</span>
                  <span  class="modal-title">{{formatDateStrDisplay(abo.trail_until_date)}}</span>
                </div>
                <div style="display:inline-flex;margin: .6em 4em 0.7em 4em;">
                  <button v-if="abo.state==1 && !(abo.trail_until_date || abo.valid_until_date) && !abo.termination_date" style="padding: 5px 3px 1px 3px;border:#d2d2d2 1px solid;width:10em;font-size: small;font-weight:bold;background-color: rgb(140,20,30);color: #f2f2f2;text-align:center" @click="setAbostate('termination', abo)" type="submit" class="btn btn-primary">Kündigen</button>
                  <button v-if="abo.state==1 && (abo.trail_until_date || abo.valid_until_date) && !abo.termination_date" style="padding: 5px 3px 1px 3px;border:#d2d2d2 1px solid;width:10em;font-size: small;font-weight:bold;background-color: rgb(3,124,19);color: #f2f2f2;text-align:center" @click="setAbostate('extend', abo)" type="submit" class="btn btn-primary">Verlängern</button>
                  <button v-if="(abo.state==0) || (abo.termination_date)" style="padding: 5px 3px 1px 3px;border:#d2d2d2 1px solid;width:10em;font-size: small;font-weight:bold;background-color: rgb(3,124,19);color: #f2f2f2;text-align:center" @click="setAbostate( 'new',abo)" type="submit" class="btn btn-primary">Abonnieren</button>
                </div>
              </div>
            </div>
          </div>

          <div v-if="getDebuggerMode" id="messagesD" >
            <div >
              <v-select :class="{'style-chooser':true, 'dark': isDark(), 'padding-left':'0.1em'}" id="selectedDebugInfoList" v-model="selectedDebuggerId"  :clearable="false"  :options="[{id:'Trends'}, {id:'Screens'}]" label="id" :reduce="(option) => option.id" :style="{width: '255px', 'margin-bottom': '0.2em',bottom: (height-250)+'px',}" />
              <v-select v-if="selectedDebuggerId=='Trends'" :class="{'style-chooser':true, 'dark': isDark(), 'padding-left':'0.1em'}" id="selectedDebugInfoListTrendIdMaster"  :clearable="false" v-model="selectedTrendType" :options="[{id:'Alle'}, {id:'Haupttrends'}, {id:'Sub-Haupttrends'}, {id:'Nebentrends'}, {id:'Kandidat'}]" label="id" :reduce="(option) => option.id" :style="{width: '255px', 'margin-bottom': '0.2em',bottom: (height-250)+'px',}" />
              <v-select v-if="selectedDebuggerId=='Trends'" :class="{'style-chooser':true, 'dark': isDark(), 'padding-left':'0.1em'}" id="selectedDebugInfoListTrendIdActive"  :clearable="false" v-model="selectedTrendOnlyActive" :options="[{id:1,label:'nur aktive Trends'}, {id:2,label:'nur selektierter Trend'}, {id:0,label:'auch gelöschte Trends'}]"  @input="setSelectedTrendActive" label="label" :reduce="(option) => option.id" :style="{width: '255px', 'margin-bottom': '0.2em',bottom: (height-250)+'px',}" />
              <v-select v-if="selectedDebuggerId=='Trends'" :class="{'style-chooser':true, 'dark': isDark(), 'padding-left':'0.1em'}" id="selectedDebugInfoListTrendId123"  :clearable="false" v-model="selectedTrendOnly123" :options="[{id:true,label:'nur 123-Regeln'}, {id:false,label:'alle Regeln'}]" label="label" :reduce="(option) => option.id" :style="{width: '255px', 'margin-bottom': '0.2em',bottom: (height-250)+'px',}" />
              <v-select v-if="selectedDebuggerId=='Trends'" :class="{'style-chooser':true, 'dark': isDark(), 'padding-left':'0.1em'}" id="selectedDebugInfoListTrendId"  :clearable="false" v-model="selectedTrendId" :options="debuggerTrends" label="name" :reduce="(option) => option.id" @input="setSelectedTrend" :style="{width: '255px', 'margin-bottom': '0.2em',bottom: (height-250)+'px',}" />
              <button v-on:click="copyDebuggerInfo" class="btn btn-primary" :style="{width: '255px', bottom: (height-250)+'px', 'max-height':'2em', height:'2em','position':'relative','font-size':'0.9em', 'margin-top':'0.5em', 'padding-top':'0.15em'}">Copy</button>
            </div>
            <div :style="{'margin-left': '0.7em',width: '255px', bottom: '5px',position: 'absolute',overflow:'scroll','overflow-x':'hidden',height:(height-260)+'px',}">
              <div v-html="getDebuggerInfos" ></div>
            </div>
          </div>
            <!--  Live Analysis Abschnitt -->
            <div v-if="getLiveMode" id="messagesL" :style="{'margin-left': '0.7em', width: '255px', bottom: '25px',position: 'absolute',overflow:'scroll','overflow-x':'hidden',height:(height-105)+'px'}">
              <div v-for="item in liveAnalysis()" :key="item.id" :style="{ background: `${getColor(item)}` , 'color': 'rgba(240,240,240,0.8)', margin: '0.4em', padding: '0.4em',border: '1px solid rgba(208,208,208,0.29)', 'margin-bottom': '0.2em', 'max-width': '258px'}">
                <p style="text-align:left;color: rgba(240,240,240,0.8);margin-bottom: 0.3em;font-size: small" >
                  {{getShortTimeFormat(item.time)}}
                  <span style="float:right;color: rgba(240,240,240,0.8);">
                    <strong v-on:click="loadChart(item.id, item.symbol, item.tf)">{{ item.from }}</strong>
                        <i v-if="isAdmin" v-on:click="setOffline(item.id)" class="fa fa-times-circle" style="font-size:17px;margin-left: 5px"></i>
                  </span>
                </p>
                <p v-if="(item.inactive == 1)" style="text-align:left;color: rgba(204,164,27,0.94);margin-bottom: 0.3em;font-size: small" v-on:click="loadChart(item.id, item.symbol, item.tf)">
                  {{ item.symbol}} ({{ item.tf }})
                  <span style="float:right;">
                    <i v-if="isAdmin" class="fa fa-eye" style="color:rgba(240,240,240,0.8);margin-left: 5px"> {{ item.views }}</i>
                  </span>
                </p>
                <p v-if="(item.inactive != 1)"  style="text-align:left;color: rgba(240,240,240,0.8);margin-bottom: 0.3em;font-size: small" v-on:click="loadChart(item.id, item.symbol, item.tf)">
                {{ item.symbol}} ({{ item.tf }})
                <span style="float:right;">
                    <i v-if="isAdmin" class="fa fa-eye" style="color:rgba(240,240,240,0.8);margin-left: 5px"> {{ item.views }}</i>
                  </span>
              </p>
                <div :style="{'background-color': 'rgba(58,58,58,0.65)', padding: '0.2em','margin-bottom': '0.5em'}">
                    <p style="text-align:left;color: rgb(238,238,238);margin-bottom: 0.3em;font-size: small;font-weight: bold;" v-on:click="loadChart(item.id, item.symbol, item.tf)">
                    {{item.headline}}
                    </p>
                    <p style="text-align:left;color: rgb(238,238,238);margin-bottom: 0.1em;font-size: small" v-html="setShortDisplayText(item.description)" v-on:click="loadChart(item.id, item.symbol, item.tf)">
                    </p>
                </div>
                <div>
                  <p style="text-align:left;color: rgba(208,208,208,0.43);margin-bottom: 0.3em;font-size: small" >
                    <i v-if="isAdmin && (item.inactive != 1)" class="fa fa-users" style="font-size:17px;color:rgb(77,150,63)"></i>
                    <i v-if="isAdmin && (item.inactive == 1)" class="fa fa-users" style="font-size:17px;color:rgb(161,1,1)"></i>
                    <i v-on:click="loadChart(item.id, item.symbol, item.tf)" class="fa fa-line-chart" style="font-size:15px;color:#d0d0d0">  view analysis</i>
                    <span style="float:right;color: rgba(208,208,208,0.43);">
                      <i v-if="isAdmin && isLiveOnline && isLiveOnlineId==item.id" v-on:click="setLiveMode(false, item.id)"class="fa fa-wifi" style="font-size:17px;color:rgb(77,150,63)"></i>
                      <i v-if="isAdmin && !isLiveOnline" v-on:click="setLiveMode(true, item.id)"class="fa fa-wifi" style="font-size:17px;color:rgb(161,1,1)"></i>
                      <i v-if="isAdmin && isLiveOnline && isLiveOnlineId==item.id" v-on:click="setLiveModeReset(item.id)"class="fa fa-eraser" style="font-size:17px;color:rgb(161,1,1)"></i>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          <div v-if="getMessageMode">
            <div style="color: #ffffff; margin-left: 0.7em; margin-top: 1.0em"> <strong> Send Message </strong></div>
            <textarea v-on:keyup.enter="sendTextMessage" type="text" required style="bottom: 15px;margin: 0.7em; padding: 0.7em; margin-top:0px; margin-bottom:0.5em" name="IMArea" id="IMArea" cols="32" rows="3"></textarea>
            <button v-on:click="sendTextMessage" class="btn btn-primary" style="height:25px; width: 250px; bottom: 15px;padding: 0.01em;margin-left: 0.7em; margin-top:0px; margin-bottom:1.5em;background-color: rgba(27,31,44,0.91);">Send</button>
          </div>
          <div v-if="getPulishVideos">
          <div style="color: #ffffff; margin-left: 0.7em; margin-top: 1.0em"> <strong> Send Message </strong></div>
            <textarea type="text" required style="bottom: 15px;margin: 0.7em; padding: 0.7em; margin-top:0px; margin-bottom:0.5em" name="videoId" id="videoId" cols="32" rows="1"></textarea>
            <textarea type="text" required style="bottom: 15px;margin: 0.7em; padding: 0.7em; margin-top:0px; margin-bottom:0.5em" name="videoTitle" id="videoTitle" cols="32" rows="2"></textarea>
            <textarea type="text" required style="bottom: 15px;margin: 0.7em; padding: 0.7em; margin-top:0px; margin-bottom:0.5em" name="videoDesc" id="videoDesc" cols="32" rows="8"></textarea>
          <button v-on:click="sendPublishVideoMessage" class="btn btn-primary" style="height:25px; width: 250px; bottom: 15px;padding: 0.01em;margin-left: 0.7em; margin-top:0px; margin-bottom:1.5em;background-color: rgba(27,31,44,0.91);">publish Video</button>
        </div>

        </div>
      </div>
      <div class="inner_chartbar" v-if="!isApp && getRightOffset>20" :style="{'border-left': '0.5px solid black',width:34+'px', 'height':height+'px'}">
        <div v-on:click="setRightBarSizeMarketView" style="margin-top: 65px;cursor: pointer;margin-left: -54px;border: 1px solid rgba(255,110,0);transform: rotate(90deg);width: 130px;padding-left: 15px">Marketoverview</div>
        <div v-on:click="setRightBarSizeAccount" style="margin-top: 110px;cursor: pointer;margin-left: -54px;border: 1px solid rgba(255,110,0);transform: rotate(90deg);width: 130px;padding-left: 25px">My Account</div>
        <div v-if="isAdmin" v-on:click="setRightBarSizeLive" style="margin-top: 110px;cursor: pointer;margin-left: -54px;border: 1px solid rgba(255,110,0);transform: rotate(90deg);width: 130px;padding-left: 25px">Live Analysis</div>
        <div v-if="isAdmin" v-on:click="setRightBarSizeAlerts" style="margin-top: 140px;cursor: pointer;margin-left: -38px;border: 1px solid rgba(255,110,0);transform: rotate(90deg);width: 100px;padding-left: 33px">Alerts</div>
        <div v-if="isAdmin" v-on:click="setRightBarSizeVideos" style="margin-top: 80px;cursor: pointer;margin-left: -38px;border: 1px solid rgba(255,110,0);transform: rotate(90deg);width: 100px;padding-left: 28px">Videos</div>
        <div v-if="isAdmin" v-on:click="setRightBarSizeDebugger" style="margin-top: 80px;cursor: pointer;margin-left: -38px;border: 1px solid rgba(255,110,0);transform: rotate(90deg);width: 100px;padding-left: 13px">Debuginfos</div>
      </div>
    </div>
    <transition name="slide-fade" style="z-index: 1000;">
      <hint-overlay v-if="showHint" :hintData="charts['0'].hintData" :id="'0'" :chartHandler="chartHandler" :style="{left:'calc(100vw - 565px)',heigth: getHintHeight()+'px',top:'calc(100vh - 570px)', width:'440px', 'z-index': 1000}"/>
    </transition>
  </div>
</template>

<script>
import Chartbox from './Chartbox.vue'
import Rainbow from '../overlays/Rainbow.vue'
import {ChartHandler} from '../utils/ChartHandler.js'
import Searchbar from "./Searchbar.vue";
import { GridLayout, GridItem } from "vue-grid-layout"
import { SVGIcons } from "../img/svg_icons";
import LeftToolbar from './components/LeftToolbar.vue'
import Toolbar from '../lib/components/Toolbar.vue'
import Const from "../lib/stuff/constants";
import TopModifyPanel from './components/TopModifyPanel.vue';
import TopFavoritesPanel from './components/TopFavoritesPanel.vue';
import DraggableContainer from './components/DraggableContainer.vue';
import IndicatorConfig from './components/IndicatorConfig.vue';
import Vue from 'vue';
import KeyboardShortcuts from "./components/KeyboardShortcuts.vue";
import VGrid, { VGridVueTemplate } from "@revolist/vue-datagrid";
import HintOverlay from "./components/HintOverlay.vue";
import 'vue-select/dist/vue-select.css';
import { VueEditor } from "vue2-editor";
import Utils from "../lib/stuff/utils";
import ChartUtils from "../utils/ChartUtils";
import MarketOverviewItem from "./components/MarketOverviewItem.vue";
import {LayoutColors} from "../utils/LayoutHandler.js";

//import GridButton from "./components/GridButton.vue";

//const editor = VGridVueEditor(GridButton);

const myVue = Vue.component("my-component", {
  props: ["rowIndex", "model"],
  computed: {
    count() {
      return this.model.count || 0;
    },
  },
  /*methods: {
    iAmClicked(e) {
      Vue.set(this.model, "count", this.count + 1);
    },
  },*/
  template:
      '<div\n' +
      '          class="bubble green"\n' +
      '          style={{ backgroundColor: "green", color: "white" }}\n' +
      '      >\n\n' +
      '      </div>',
});
/*
function myJsxCellTemplate(h, props) {
  return (
      <div
          class="bubble green"
          style={{ backgroundColor: "green", color: "white" }}
      >
        {props.model[props.prop] || ""}
      </div>
  );
};*/
//import * as ClusterScript from "../../../ki-trading/chart_software_com/src/lib/helpers/script_cluster";
//import { SidebarMenu } from "../menue/index.js";

//import TopToolbar from "./components/TopToolbar.vue";

// TODO: investigate chart-disappearing problem

export default {
  name: 'app',
  description: 'All charts must work correctly and independently',
  props: ['night'],
  components: {
    Searchbar, Chartbox, Rainbow, IndicatorConfig, VGrid, TopModifyPanel, TopFavoritesPanel,
    GridLayout, HintOverlay, //CSContextMenu,
    VueEditor, DraggableContainer,MarketOverviewItem,
    GridItem, Toolbar, LeftToolbar, KeyboardShortcuts
  },
  methods: {
    /*showHint() {
      return this.chartHandler.settings.showHint;
    },*/

    isDark() {
      return this.chartHandler.layoutHandler.isDarkMode();
    },
    getIsLoadingHistoryData() {
      //console.log(this.chartHandler.loading.isLoadingHistoryData?"true":"false")
      return this.chartHandler.loading.isLoadingHistoryData;
    },
    submit() {
      this.onSavetemplate();
    },
    saveMultiScreen() {
      this.showModalMultiScreen = false;
      this.chartHandler.layoutHandler.saveScreen();
    },
    loadMultiScreen() {
      this.showModalLoadMultiScreen = false;
      this.chartHandler.loadMultiScreenView(this.chartHandler.layoutHandler.userViews.currentView);
    },
    liveModeUser() {
      return this.chartHandler.liveCharting.user;
    },
    onResize(event) {
      this.width = window.innerWidth
      this.height = window.innerHeight - (this.isApp ? 10 : 63)
    },
    getDirColor(change) {
      if (change <= 0.000001)
        return '#c4001f';
      return '#43a600';
    },
    onChangeTheme(event) {
      console.log(event);
    },
    getIndicatorConfig() {
      let res = [];
      res = res.concat(this.chartHandler.charts['' + this.chartHandler.activeChart].data.data.onchart);
      res = res.concat(this.chartHandler.charts['' + this.chartHandler.activeChart].data.data.offchart);
      for (let indCfgIdx in res) {
        let ft = this.chartHandler.getIndicatorConfigFieldTemplates(res[indCfgIdx].id||res[indCfgIdx].name) || {};
        res[indCfgIdx].fieldsTemplate = ft.fieldsTemplate;
        res[indCfgIdx].settings = Object.assign(res[indCfgIdx].settings, ft.settings);
        res[indCfgIdx].deleted = res[indCfgIdx].settings.deleted;
      }
      return res;
    },

    getIsShortcutModalOpen() {
      return this.isShortcutModalOpen;
    },

    on_shortcut_change(type, item, change, tool) {
      if (tool) {
        tool.currentSHORTCUT = change.target.value;
      }
      //console.log(type, item, tool);
    },

    on_save_shortcuts() {
      const tools = this.$refs.leftbar.tools;

      tools.forEach((t) => {
        this.chartHandler.settings.shortcuts.forEach((sc) => {
          if (t.name == sc.NAME) {
            Vue.set(t, 'shortcut', sc.SHORTCUT ? sc.SHORTCUT.split('+') : []);
          }
        });
      });

    },

    getOffsetSize() {
      return this.showRightBar ? 340 : 0;
    },

    getCurrentConfigValue(cfgKey) {
      let value = '';
      if (cfgKey == "theme") {
        value = chartHandler.chartSettings.theme;
        this.defThemes.forEach((t) => {
          if (t.name == value) {
            value = t.theme.name;
          }
        });
      } else if (cfgKey == "logScale") {
        value = chartHandler.chartSettings.logScale;
        this.defLogScale.forEach((t) => {
          if (t.value == value) {
            value = t.name;
          }
        });
      } else if (cfgKey == "grid") {
        value = chartHandler.chartSettings.isGrid;
        this.defGrid.forEach((t) => {
          if (t.value == value) {
            value = t.name;
          }
        });
      }
      return value;
    },

    cbox_width(i) {
      //interval_msconsole.log('chartWidth',this.getRightInnerBarVisible());
      const self = this;
      let w = this.chartHandler.layoutHandler.chartWidth(this.width - (this.isApp ? 0 : 45) - (this.isApp ? 0 : this.getRightOffset), i, this.showModalAdmin ? 0 : 0);
      //console.log(i,w);
      if (this.showRightBar) {
        //w -= 330;
        if (!this.eventDefined) {
          this.eventDefined = true;
          setTimeout(() => {
            if (document.getElementById("chart_settings_list")) {
              this.addListener(document.getElementById("chart_settings_list"), function (setting, value) {
                // change default watchlist timeframe
                //console.log(setting, value);
                if (setting == "watchlist_timeframe") self.akj_event("set-chart-settings", ["set-watchlist_timeframe", value]);

                // change theme
                if (setting == "theme") self.akj_event("set-chart-settings", ["set-theme", value]);

                // change logScale
                if (setting == "logScale") self.akj_event("set-chart-settings", ["set-logScale", value]);

                // change logScale
                if (setting == "isGrid") self.akj_event("set-chart-settings", ["set-grid", value]);

                // change time zone
                if (setting == "timezone") self.akj_event("set-chart-settings", ["set-timezone", value]);
              });
            }
          }, 100);
        }
      }
      return w; //;
    },

    showModalDialog(type) {
      //console.log(type);
      this.templateName = '';
      this.makeDefault = false;
      this.showModal = true;
      //this.showModalCharts = type == 'charts';
      this.showModalType = type;
    },

    removedindicator(event) {
      this.chartHandler.removeIndicator(event.chartId, event.type, event.overlay);
    },

    modifyIndicator(event) {
      this.chartHandler.setActiveTool(event.type+"."+(event.overlay).replace("_",""));
    },

    onSelectTool(ind) {
      if (ind && ind.toolTipInfo) {
        this.ToolTipInfoText = ind.toolTipInfo;
      } else  {
        this.ToolTipInfoText = "";
      }
      console.log('onSelectTool',ind)
      this.chartHandler.isDeleteMode = ind && (ind.type == "Delete");
      //this.indCfg = ind.settings;
    },

    on_grid_doubletap(event) {
      //console.log(event);
      this.chartHandler.layoutHandler.switchLayout(parseInt(event.chartId));
    },

    theme() {
      return this.chartHandler.chartSettings.theme;
    },

    getViewScreens() {
      return this.chartHandler.layoutHandler.screens.screens;
    },

    onDeleteWatchList(wlName) {
      this.chartHandler.deleteWatchList(wlName);
    },

    onShowWatchList(wlName) {
      this.chartHandler.onShowWatchList(wlName);
    },

    deleteMarketListDetail(symbol, wlName) {
      this.chartHandler.deleteMarketListDetail(symbol, wlName);
    },

    on_drawing_mode_on(tool, id, settings) {
      console.log("mousedownon_drawing_mode_on deleteTool_sc", tool, id, settings)
      this.chartHandler.isDrawingMode = true;
      this.chartHandler.drawingId = id;
      this.chartHandler.drawingUUID = settings && settings.$uuid ? settings.$uuid : id;
    },

    on_drawing_mode_off(tool, settings) {
      console.log("on_drawing_mode_off deleteTool_sc",this.chartHandler.isDrawingMode);

      let selectNew = this.chartHandler.isDrawingMode;

      this.ToolTipInfoText = "";
      this.chartHandler.isDrawingMode = false;

      // clear of there's any default settings
      window.defaultToolSettings = {};

      this.tool = "Cursor";

      //console.log("#tool",tool, settings)

      if (tool && selectNew) {
        this.on_toolSelect({name: tool, settings: settings})
      }
      else
        this.$refs["leftbar"].toolReset(true);

      //window.$store.tool = "Cursor";

      // reset left menus
//      this.$refs["leftbar"].toolReset();
    },

    copyDebuggerInfo() {
      navigator.clipboard.writeText(this.getDebuggerInfos);
    },

    formatDateStrDisplay(dt) {
      return ChartUtils.formatDateStrDisplay(dt);
    },

    onAnimationInfo(labelId, startSec) {
      var bAdded = false;
      this.lastOnAnimationInfo = Date.now();
      console.log('onAnimationInfo');
      var startSecTemp = 0;
      if (this.indCfg && this.indCfg.indicatorInstance && this.indCfg.indicatorInstance.settings) {
        if (!labelId && !this.indCfg.indicatorInstance.labelId) {
          this.indCfg.indicatorInstance.labelId = this.indCfg.indicatorInstance.settings.$uuid;
          labelId = this.indCfg.indicatorInstance.labelId;
        }
        var idx = -1;
        this.publishLabelActions.forEach((la, i) => {
          if ((la.action) && (la.action.labelId == labelId)) {
            startSecTemp = la.startSec;
            idx = i;
          }
        });

        console.log(labelId, idx);
        if ((idx >= 0) && (startSecTemp <= 0)) {
          this.publishLabelActions.splice(idx, 1);
        }
        if (idx < 0)
          startSecTemp = startSec;

        if (startSecTemp > 0) {
          if (idx < 0) {
            console.log('la add', this.indCfg.name, startSecTemp, idx);
            this.publishLabelActions.push({
              type: this.indCfg.indicatorInstance.name,
              startSec: startSecTemp,
              videoSize: 500,
              action: {labelId: labelId}
            });
            bAdded = true;
          } else {
            this.publishLabelActions[idx].startSec = startSecTemp;
          }
        }
      }
      this.sortPublishLabelActions();
      if (bAdded) {
        const prev = this.$refs.ModifyPanel.videoSize;
        this.$refs.ModifyPanel.videoSize = prev - 1;
        setTimeout((prevSize) => {
              this.$refs.ModifyPanel.videoSize = prevSize;
            },
            100, prev);
      }
    },
    sortPublishLabelActions() {
      this.publishLabelActions.sort((f1,f2) => {
        return f1.startSec - f2.startSec;
      })
    },
    onLabelEvent(eventInfo, currentSec){
      var action = eventInfo.action;

      //console.log(
      //    'onLabelEvent', action, eventInfo, currentSec
      //);
      eventInfo.type = eventInfo.type || action.name|| action.id;

      var chart = window.chartHandler.chartObjects['0'].$refs.tvjs.$refs.chart;
      if (chart && action) {
        switch (action.type) {
          case "switchTool": {
            if (this.lastOnAnimationInfo+250 < Date.now()) {
              chart.data.onchart.forEach((i) => {
                if (i.labelId) {
                  console.log(i.labelId, action.labelId);
                  if (i.labelId == action.labelId)
                    window.chartHandler.setActiveTool(i.settings.$uuid);
                }
              });
            }
            break;
          }
          case "addZoom": {
            //console.log("addZoom",chart.range);
            const zoom = {startSec:this.$refs.ModifyPanel.videoSize||1000000, videoSize: 500, action:{type:"zoom",labelId:`zoom.${Utils.uuid2()}`, from:chart.ti_map.i2t(chart.range[0]),until:chart.ti_map.i2t(chart.range[1])}};
            this.publishLabelActions.push(zoom);
            //this.sortPublishLabelActions();
            this.onAnimationInfo(zoom.action.labelId);
            const prev = this.$refs.ModifyPanel.videoSize;
            this.$refs.ModifyPanel.videoSize = prev-1;
            setTimeout((prevSize)=>{
              this.$refs.ModifyPanel.videoSize = prevSize;
            },
            100, prev);
            break;
          }
          case "loaded": {
            chart.data.onchart.forEach((i) => {
              if ((action.videoSize) && (i.startSec > action.videoSize)) {
                i.startSec = action.videoSize;
              }

              if (i.labelId) {
                this.publishLabelActions.forEach((la) => {
                  if (i.labelId == la.action.labelId)
                    window.chartHandler.setToolDisplay(i.id, true);
                });
              }
            });
            if (action.videoSize)
              this.$refs.ModifyPanel.videoSize = action.videoSize;
            break;
          }
          case "init": {
            chart.data.onchart.forEach((i) => {
              if ((action.videoSize) && (i.startSec > action.videoSize)) {
                i.startSec = action.videoSize;
              }

              if (i.labelId) {

                this.publishLabelActions.forEach((la) => {
                  if (i.labelId == la.action.labelId)
                    window.chartHandler.setToolDisplay(i.id, false);
                });
              }
            });
            if (action.videoSize)
              this.$refs.ModifyPanel.videoSize = action.videoSize;
            break;
          }
          case "addInd": {
              if (action.labelId) {
                this.publishLabelActions.forEach((la) => {
                  if (action.labelId == la.action.labelId)
                    window.chartHandler.setToolDisplay(action.id, false);
                });
              }
            break;
          }
          case "zoom": {
            //console.log("hierzoomen wir mal hin",Math.trunc(chart.ti_map.t2i(action.from)),Math.trunc(chart.ti_map.t2i(action.until)),action.from,action.until);
            chart.setRange(Math.trunc(chart.ti_map.t2i(action.from)),Math.trunc(chart.ti_map.t2i(action.until)));
            break;
          }
          default: {
            // TODO window.chartHandler.addInd(action,'0');if (ind.points) {
            //action.startSec = eventInfo.startSec;
            //console.log('default', action.labelId);


            chart.data.onchart.forEach((i) => {
              if (i.labelId == action.labelId)
                window.chartHandler.setToolDisplay(i.id,true);
            });
            break;
          }
        }
      }
    },

    onMenuItemClick(itemList) {
      let closeMenu = true;
      const menu = this.chartHandler.contextMenu.menu;
      console.log(menu,itemList);
      //this.closeContextMenu(null);
      if ((itemList[0] >= 0) && (itemList[0] <= 2) && window.chartHandler.activeTool) {
        //console.log(window.chartHandler.activeTool, this.publishLabelActions);
        if (itemList[0] == 1) {
          window.chartHandler.duplicateTool(window.chartHandler.activeTool);
        } else if (itemList[0] == 2) {
          //console.log('deleteTool_sc',this.chartHandler.isDrawingMode, this.chartHandler.drawingUUID )
          window.chartHandler.deleteCurrentTool();
        } else if (itemList[0] == 3) {
          //var act = {"startSec":18,"id":"zoom1","action": window.chartHandler.activeTool};
          //this.publishLabelActions.push(act);
        }
      } else {
        if (itemList[0] == 0 && (itemList.length > 1)) {
          let marketListId = menu[0].children[itemList[1]].marketListId;
          if (itemList[1] == 0) {
            this.showModalDialog('watchlist');
          } else {
            this.chartHandler.addMarketListDetail(null, marketListId, "");
          }
        } else if (itemList[0] == 1) {
          let marketListId = menu[1].children[itemList[1]].marketListId;
          this.chartHandler.deleteMarketListDetail(null, marketListId);
        } else if (itemList[0] == 7) {
          if (itemList.length > 1) {
            const isSet = !((this.chartHandler.chartSettings.bitMaskExtreams & Math.pow(2, itemList[1])) === Math.pow(2, itemList[1]));
            Vue.set(menu[itemList[0]].children[itemList[1]],'checked',isSet);
            if (isSet)
              this.chartHandler.chartSettings.bitMaskExtreams |= Math.pow(2, itemList[1]);
            else
              this.chartHandler.chartSettings.bitMaskExtreams &= ~Math.pow(2, itemList[1]);
          }
          closeMenu = false;
        } else if (itemList[0] >= 2) {
          const tools = [{
            name: 'LineTool',
            settings: {}
          }, {name: 'TrendChannel'}, {name: 'HorizontalLine'}, {name: 'VerticalLine'}, {name: 'Rectangle'}];
          this.on_toolSelect(tools[itemList[0] - 2])
        }
      }
      this.$refs.easycm.show = !closeMenu;
      //this.$refs.easycm.secondBorderCheck(itemList[0]);
      return true;
    },

    timeframechanged(tf) {
      this.isLiveOnlineId = -1;
      this.isLiveOnline = false;
      //this.chartHandler.reInitNew(tf.chartId);
      this.chartHandler.setLoadedChartId(-1);
      this.chartHandler.setTimeFrame(tf.chartId, tf.timeframeSelected);
    },

    cbox_height(i) {
      return this.chartHandler.layoutHandler.chartHeight(this.height, i)
    },

    resetInfoChartUpdate() {
      Vue.set(this.chartHandler.liveCharting.updatedAnalysis,'id',0);
    },

    nextUserInfo() {
      this.selectedUserInfoIndex++;
    },

    prevUserInfo() {
      this.selectedUserInfoIndex--;
    },

    changeDirection(col, marketListId) {
      this.chartHandler.marketListChangeDirection(col, marketListId);
    },
    confirmUserInfo(value, idxInfo) {
      console.log("confirmUserInfo",value,idxInfo);
      this.chartHandler.confirmUserInfo(this.selectedUserInfoIndex, idxInfo);
      if ((this.selectedUserInfoIndex > 0) || (this.chartHandler.userInformations.count<1)){
        this.selectedUserInfoIndex--;
      }
      //Vue.set(this.userInformationsLength= this.chartHandler.userInformations.data.length;
      this.selectedUserInfoIndex--;
      this.selectedUserInfoIndex++;
    },
    getValueAsDate(name, value) {
      if ((name == "startDate") || (name == "endDate") || (name == "when") || (name == "deleted")) {
        return ChartUtils.formatDateTimeStrDisplay(value);
      }
      if ((name == "rule")) {
        return this.chartHandler.getTranslation(value);
      }
      return value;
    },
    getScreenInfo() {
      const s = this.chartHandler.layoutHandler.screens;
      /*const res = [];
      s.forEach((scr) => {
        res.push({
          availHeight: scr.availHeight,
          availLeft: scr.availLeft,
          availTop: scr.availTop,
          availWidth: scr.availWidth,
          colorDepth: scr.colorDepth,
          devicePixelRatio: scr.devicePixelRatio,
          height: scr.height,
          isExtended: scr.isExtended,
          isInternal: scr.isInternal,
          isPrimary: scr.isPrimary,
          label: scr.label,
          left: scr.left,
          orientation: scr.orientation,
          pixelDepth: scr.pixelDepth,
          top: scr.top,
          width: scr.width
        });
      });*/
      return [s];
    },
    loadChart(id, orgSymbol, orgTf) {
      const symbol = orgSymbol || this.chartHandler.charts['' + this.chartHandler.activeChart].symbol;
      const tf = orgTf || this.chartHandler.charts['' + this.chartHandler.activeChart].tf;
      this.resetInfoChartUpdate();
      //this.chartHandler.reInitNew("0")
      this.chartHandler.setLoadedChartId(id)
      if ((symbol != this.chartHandler.displayData.symbol) && (tf != this.chartHandler.timeframe[0]))
        this.on_symbolChanged(symbol, tf, id > 0)
      else if (symbol != this.chartHandler.displayData.symbol)
          this.on_symbolChanged(symbol, null, id > 0)
      else if (tf != this.chartHandler.timeframe[0])
        this.chartHandler.setTimeFrame("0", tf, id > 0)
      this.chartHandler.getTemplateInfo('charts', {ID_CHART: id});
      if (id > 0) {
        this.chartHandler.requestLiveChart(id,symbol,tf, false);
        this.chartHandler.liveCharting.liveChartId = id;
      }
      this.isLiveOnlineId = id;
      this.isLiveOnline = false;

      this.setOnline(false, false);
      /*if (!this.isAdmin)
        this.isLiveOnlineId = id;
      else
        this.isLiveOnlineId = -1;*/
    },

    getShortTimeFormat(time) {
      let dt = new Date(time);
      if ((Math.trunc(Date.now()/86400000)*86400000) > dt.getTime()) {
        const day = dt.toLocaleDateString().substring(0, 1 + dt.toLocaleDateString().indexOf(".", 4));
        return day + " - " + dt.toLocaleTimeString();
      } else
        return dt.toLocaleTimeString();
    },

    setRightBarSize(changed) {
      if (!changed && (this.getRightOffset > 200))
        this.getRightOffset = 30;
      else {
        this.getRightOffset = 310;
      }
      this.isLiveModeView = this.isLiveModeAlerts = this.isLiveModeMessages = this.isLiveModeVideo = this.isDebuggerMode = this.isMarketViewMode = this.isAccountMode = false
    },

    setRightBarSizeInstant() {
        this.setRightBarSize(this.isLiveModeView || this.isLiveModeAlerts || this.isLiveModeVideo || this.isDebuggerMode || this.isMarketViewMode || this.isAccountMode);
        this.isLiveModeMessages = true;
        setTimeout(() => {
          if ($('#messagesM'))
            $('#messagesM').scrollTop(1000000);
        }, 100);
    },
    setRightBarSizeDebugger() {
      this.setRightBarSize(this.isLiveModeView || this.isLiveModeMessages || this.isLiveModeAlerts || this.isLiveModeVideo || this.isMarketViewMode || this.isAccountMode);
      this.isDebuggerMode = true;
    },

    setRightBarSizeMarketView() {
      this.setRightBarSize(this.isLiveModeView || this.isLiveModeMessages || this.isLiveModeAlerts || this.isLiveModeVideo || this.isDebuggerMode || this.isAccountMode);
      this.isMarketViewMode = true;
    },

    setRightBarSizeVideos() {
      this.setRightBarSize(this.isLiveModeView || this.isLiveModeMessages || this.isLiveModeAlerts || this.isDebuggerMode || this.isMarketViewMode || this.isAccountMode);
      this.isLiveModeVideo = true;
      /*setTimeout(() => {
        if ($('#messagesA'))
          $('#messagesA').scrollTop(1000000);
      }, 100);*/
    },

    setRightBarSizeAlerts() {
      this.setRightBarSize(this.isLiveModeView || this.isLiveModeMessages || this.isLiveModeVideo || this.isDebuggerMode || this.isMarketViewMode || this.isAccountMode);
      this.isLiveModeAlerts = true;
      /*setTimeout(() => {
        if ($('#messagesA'))
          $('#messagesA').scrollTop(1000000);
      }, 100);*/
    },

    setRightBarSizeLive() {
      this.setRightBarSize(this.isLiveModeAlerts || this.isLiveModeMessages || this.isLiveModeVideo || this.isDebuggerMode || this.isMarketViewMode || this.isAccountMode);
      this.isLiveModeView = true;
      /*setTimeout(() => {
        if ($('#messagesL'))
          $('#messagesL').scrollTop(1000000);
      }, 100);*/
    },

    setRightBarSizeAccount() {
      this.setRightBarSize(this.isLiveModeAlerts || this.isLiveModeMessages || this.isLiveModeVideo || this.isDebuggerMode || this.isMarketViewMode || this.isLiveModeView);
      this.isAccountMode = true;
      /*setTimeout(() => {
        if ($('#messagesL'))
          $('#messagesL').scrollTop(1000000);
      }, 100);*/
    },

    sendTextMessage(e) {
      //this.outputValueOnEnter =
      //console.log(e, e.target.value)
      //this.instantmessages.push({messageId: 10,text: e.target.value})
      this.chartHandler.requestSendInstantMessage(document.getElementById("IMArea").value);
      document.getElementById("IMArea").value = '';
    },

    sendPublishVideoMessage(e) {
      //this.outputValueOnEnter =
      //console.log(e, e.target.value)
      //this.instantmessages.push({messageId: 10,text: e.target.value})
      this.chartHandler.requestSendPublishVideoMessage(document.getElementById("videoId").value,
          document.getElementById("videoTitle").value,
          document.getElementById("videoDesc").value);
      document.getElementById("videoId").value = '';
      document.getElementById("videoTitle").value = '';
      document.getElementById("videoId").value = '';
    },

    on_OpenAdminSite() {
      console.log('on_OpenAdminSite')
      this.showModalAdmin = !this.showModalAdmin;
    },
    on_toolSelect(tool, settings) {
      //window.defaultToolSettings = {};

      // add default settings if there's any
      //if (settings != undefined)

      //this.tool = tool;
      const firstId = this.chartHandler.layoutHandler.getFirstId();
      console.log(firstId,'select-tool',tool, settings);
      if (tool && ('toolName' in tool) && ('settingName' in tool)) {
        window.defaultToolSettings = tool.configData;
        this.chartHandler.chartObjects[firstId].$refs.tvjs.$parent.select_tool({id: tool.toolName, settings:{extended:false}});
     } else {
        window.defaultToolSettings = null;
        this.chartHandler.chartObjects[firstId].$refs.tvjs.$parent.select_tool(tool);
      }
      //this.tool=tool;
    },

    takeScreenShot() {
      //const el = this.$refs.printMe;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      console.log(this.charts[0].layout);


      var base64image = window.ctxChart.toDataURL("image/png");

      console.log(base64image);
      document.getElementById('logo').value = base64image;
      // Open the image in a new window
      window.open(base64image , "_blank");

      html2canvas(document.getElementById("logo"), {
        // general
        logging: true,
        onrendered: function (canvas) {
          //console.log(canvas);
          document.getElementById('logo').value = canvas.toDataURL("image/png");
          //document.getElementById("myForm").submit();
          var base64image = canvas.toDataURL("image/png");

          // Open the image in a new window
          window.open(base64image , "_blank");
        }
      })
      /*window.html2canvas(document.body).then(function(canvas) {
        // Export the canvas to its data URI representation
        var base64image = canvas.toDataURL("image/png");

        // Open the image in a new window
        window.open(base64image , "_blank");
      });*/

    },
    setActiveChart(idx) {
      this.chartHandler.setActiveChart(idx);
    },

    on_indicatorselected(ind) {
      ind.fieldsTemplate = this.chartHandler.configHandler.getConfigFieldTemplates(ind.name) || ind.fieldsTemplate;
      this.chartHandler.addIndicator(ind);
    },
    on_symbolChanged(symbol, tf) {
      this.chartHandler.saveConfig('chart', false);
      this.chartHandler.settings.displayIndex = -1;
      return this.chartHandler.requestHistoryData(symbol, tf);
    },

    requestHistoryData(symbol, tf) {
      this.showModalAdmin = false;
      this.chartHandler.saveConfig('chart', false);
      this.chartHandler.settings.displayIndex = -1;
      return this.chartHandler.requestHistoryData(symbol, tf);
    },

    on_splitmode(count) {
      this.chartHandler.setLayout(count, true);
    },

    on_FocusItem(e) {
      console.log(e);
    },

    custom_event(event) {
      this.chartHandler.chartObjects[0].$props.tvjs.custom_event(event);
    },

    updateAlertSearch(e) {
      this.showTabs = "update#Alerts";
      this.chartHandler.updateAlertSearchData(e.target.value)
      return
    },

    setAdminTab(tabName) {
      if (tabName == "Alerts") {
        const indexFrom = 0, indexCount = 1000;
        this.chartHandler.requestAlerts(indexFrom, indexCount);
      }
      this.showTabs = "update#Alerts";
      if (tabName == "Users") {
        setTimeout(
            () => {
              this.showTabs = tabName
            }, 100);
      }
    },
    updateAlertsScreen() {
      if (this.showTabs)
        this.showTabs = "Alerts"
      /*if (this.$refs.alertGrid) {
        this.$refs.alertGrid.$el.setState({
          alertRows: this.chartHandler.adminData.alerts
        })
      }*/
    },
    onSavetemplate() {
      let sendType;

      if (this.templateName.trim()) {
        this.isLiveOnlineId = 0;

        if ((this.showModalType == 'charts') || (this.showModalType == 'template')) {
          let id = 0;
          const template = {
            NAME: this.templateName.trim(),
            SYMBOL: this.chartHandler.charts['' + this.chartHandler.activeChart].symbol,
            TIMEFRAME: this.chartHandler.charts['' + this.chartHandler.activeChart].tf,
            TAGS: this.templateTags
                .trim()
                .split(",")
                .toString(),
          };
          if (this.showModalType == 'charts') {
            sendType = 'chart';
            template.CHART_DATA = JSON.stringify(this.chartHandler.getChartConfig("0"));
          } else if (this.showModalType == 'template') {
            sendType = 'template';
            template.TEMPLATE_DATA = JSON.stringify(this.chartHandler.getTemplateConfig("0"));

            for (let tIdx in this.chartHandler.settings.templates) {
              if (this.chartHandler.settings.templates[tIdx].NAME == template.NAME)
                id = this.chartHandler.settings.templates[tIdx].ID_TEMPLATE;
            }
            //if (template.NAME
            console.log(template.TEMPLATE_DATA, id);
          }

          this.chartHandler.saveTemplate(
              sendType,
              id,
              template
          );
        } else if (this.showModalType == 'watchlist') {
          this.chartHandler.addMarketListDetail(null, -1, this.templateName.trim());
        }
      }

      this.templateName = '';
      this.showModal = false;

      //console.log(template);
    },

    toolbar() {
      return false;
    },

    showSideBar(status, name) {
      //console.log('test deleteTool_sc', status, name);
      let sidebarName = name == undefined ? "indicator_settings" : name;

      this.showCfg = (status == "show") && (sidebarName == "chart_settings");
      this.showIndCfg = (status == "show") && (sidebarName == "indicator_settings");
      this.showWatchList = (status == "show") && (sidebarName == "watchlist");

      if (!this.showCfg)
        this.eventDefined = false;
    },
    getShowWatchList() {
      return this.showWatchList;
    },

    getShowIndCfg() {
      return this.showIndCfg;
    },
    getShowCfg() {
      return this.showCfg;
    },
    getBackgroundLayoutColor() {
      return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.backgroundMain);
    },
    getBackgroundLightLayoutColor() {
      return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorMainLight);
    },
    getFontLayoutColor() {
      return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorMain);
    },
    getUserShortcuts() {
      return this.chartHandler.settings.shortcuts;
    },
    addToChart(event, list, instr) {
      //console.log('addToChart', event, list, instr);
      this.on_symbolChanged(instr);
    },

    cancelDrawingAndReset() {
      this.chartHandler.deleteCurrentTool();
      if (this.chartHandler.isDrawingMode) {
        this.on_drawing_mode_off(null, {});
      }
      this.$refs.leftbar.on_tool_select({type :"Cursor"},event);
      this.chartHandler.deleteCurrentTool();
      //e.stopPropagation();
    },
    openContextMenu(e) {
      console.log('openContextMenu 1 onselecttool_click',this.tool,this.$refs.leftbar.isCursorSelected());
      this.isCursorSelected = this.$refs.leftbar.isCursorSelected();
      const self = this;
      if (e.buttons == 2) {
        if (this.chartHandler.isDrawingMode) {
          this.cancelDrawingAndReset();
        } else if (!this.$refs.leftbar.isCursorSelected()) {
          this.on_drawing_mode_off(null, {});
          this.$refs.leftbar.on_tool_select({type :"Cursor"},event);
          this.$refs.leftbar.toolReset(true);

          setTimeout(() => {
            this.isCursorSelected = true;
          }, 100);

        } else if (this.$refs.leftbar.isCursorSelected()) {
          console.log('openContextMenu 2 onselecttool_click',this.tool,this.$refs.leftbar.isCursorSelected());
          setTimeout(() => {
            self.$refs.easycm.axis.x = e.clientX;
            self.$refs.easycm.axis.y = e.clientY;
            self.$refs.easycm.show = true;
          }, 100);
        }
      }

      //this.$refs.menu.open(e);
    },

    closeContextMenu(e) {
      //this.$refs.menu.close();
    },

    test(indexList) {
      switch (indexList[0]) {
        case 0:
          console.log('123');
          break
        case 1:
          console.log('423')
          break
      }
    },

    akj_event(event, args, e) {
      switch (event) {
        case "set-chart-settings":
          const settName = args[0];
          let settValue = args[1];
          let updatedSett = {...this.appSettings};
          let reset = false;

          if (settName == "isAuto-save") {
            this.chartHandler.chartSettings.isAutoSave = settValue == undefined ? true : settValue;
            updatedSett.isAutoSave = settValue;
          } else if (settName == "magnet-mode") {
            this.chartHandler.chartSettings.isMagnetMode = settValue == undefined ? true : settValue;
            updatedSett.isMagnetMode = settValue;
          } else if (settName == "indexBased") {
            this.chartHandler.chartSettings.isIndexBased = settValue == undefined ? true : settValue;
            updatedSett.isIndexBased = settValue;
          } else if (settName == "show-grid") {
            this.chartHandler.chartSettings.isGrid = settValue == undefined ? true : settValue;
            updatedSett.isGrid = settValue;
            this.showGrid = settValue;
          } else if (settName == "show-autofibs") {
            this.chartHandler.chartSettings.isAutoFibs = settValue == undefined ? true : settValue;
            //Object.keys(this.chartHandler.charts).forEach((chart) => {
            //  this.chartHandler.charts[chart].chart.merge("AUTOFIB.settings", { isAutoFibs: settValue });
            //});
          } else if (settName == "show-autohori") {
            this.chartHandler.chartSettings.isAutoHori = settValue == undefined ? true : settValue;
            //Object.keys(this.chartHandler.charts).forEach((chart) => {
            //  this.chartHandler.charts[chart].chart.merge("AUTOFIB.settings", { isAutoFibs: settValue });
            //});
          } else if (settName == "show-highlows") {
            this.chartHandler.chartSettings.isHighLows = settValue == undefined ? true : settValue;
          } else if (settName == "show-autolines") {
            this.chartHandler.chartSettings.isAutoLines = settValue;
          } else if (settName == "show-autopricegaps") {
            this.chartHandler.chartSettings.isAutoPriceGaps = settValue;
          } /*else if (settName == "set-watchlist_timeframe") {
            this.chartHandler.chartSettings.defaultWatchlistTimeframe = settValue;
            updatedSett.defaultWatchlistTimeframe = settValue;
          } */ else if (settName == "set-theme") {
            this.chartHandler.chartSettings.theme = settValue;
            this.theme = settValue;
            updatedSett.theme = settValue;
          } else if (settName == "set-logScale") {
            console.log(settName, settValue);

            this.defLogScale.forEach((t) => {
              if (t.name == settValue) {
                this.chartHandler.chartSettings.logScale = t.value;
                updatedSett.logScale = t.value;
                //reset = true;
              }
            });
          }else if (settName == "set-grid") {
            console.log(settName, settValue);

            this.defGrid.forEach((t) => {
              if (t.name == settValue) {
                this.chartHandler.chartSettings.isGrid = t.value;
                updatedSett.isGrid = t.value;
                //reset = true;
              }
            });
          } else if (settName == "set-timezone") {
            // set time zone
            this.chartHandler.chartSettings.timezone = settValue;
            updatedSett.timezone = settValue;
          } else if (settName == "default-len") {
            const min = document.getElementById("chart-setting-defaultlen").getAttribute("min");
            if (Number(settValue) < Number(min)) {
              document.getElementById("chart-setting-defaultlen").value = min;
              settValue = min;
            }
            reset = true;

            this.chartHandler.chartSettings.defaultLen = Number(settValue);
            this.chartHandler.chartObjects.forEach((chartObj) => {
              if (chartObj && (chartObj.$refs.tvjs.DAFAULT_LEN != Number(settValue)))
                chartObj.$refs.tvjs.DAFAULT_LEN = Number(settValue);
            });
            updatedSett.defaultLen = Number(settValue);
          }
          console.log('reset - changeIndicatorValue')
          this.chartHandler.chartObjects[this.chartHandler.activeChart].$refs.tvjs.resetChart(reset);
          // to avoid save to db for first time loading
          if (args[2] == undefined) {
            // save app settings
            this.chartHandler.saveSettings(JSON.stringify(this.chartHandler.chartSettings));
          }
          // update local state
          this.appSettings = {...updatedSett};

          break;

        case "show-drawings":
          //if (args) window.$store.activeChart.chart.show("onchart");
          //else window.$store.activeChart.chart.hide("onchart");
          this.showDrawings = !this.showDrawings;
          if (this.showDrawings)
            this.chartHandler.chartObjects[this.chartHandler.activeChart].$refs.tvjs.data.show('.');
          else
            this.chartHandler.chartObjects[this.chartHandler.activeChart].$refs.tvjs.data.hide('.');
          break;

        case "del-watchlist":
          if (e) {
            e.target.parentElement.parentElement.remove(); // remove watchlist el

            // send remove data to detached charts
            Object.keys(window.$store.detachCharts).forEach((key) => {
              window.$store.detachCharts[key].akj_event_from_source("del-watchlist", {
                Id: e.target.parentElement.parentElement.id,
              });
            });
          }

          API.dbWatchlist.deleteWatchlist(args);
          break;

        case "show-shortcuts":
          // hide if there's any modal
          if (document.querySelector(".modal")) document.querySelector(".modal").remove();

          this.isShortcutModalOpen = true;
          break;

        case "add-fav-tool":
          // hide if there's any modal
          if (document.querySelector(".modal")) document.querySelector(".modal").remove();

          let sett = {};
          const tool = window.$store.activeChart.chart.get(args)[0];
          const originalTool = this.tools.filter((t) => t.type.includes(tool.type))[0];
          tool.fieldsTemplate.forEach((field) => {
            sett[field[2]] = tool.settings[field[2]];
          });

          this.newFavToolData = {
            GROUP_NAME: originalTool.group.toLowerCase(),
            NAME: "",
            TYPE: originalTool.type,
            SHORTCUT: "",
            SETTINGS: JSON.stringify(sett),
          };
          this.isFavouriteModalOpen = true;

          // remove context menu
          if (document.querySelector(".context-menu")) document.querySelector(".context-menu").remove();
          break;

        case "chart-auto-save":
          // if not enable
          // no need update indicator data, exist

          console.log('autposave');
          if (!window.$store.chartSettings.isAutoSave) return;

          const chartID = window.$store.activeChart.id;

          if (window.$store.activeTemplates[chartID] != undefined && window.$store.activeTemplates[chartID].chartTemplateID) {
            // Id exist, update the current chart template
            API.dbChartTemplate.update(
                window.$store.activeTemplates[chartID].chartTemplateID,
                {
                  CHART_DATA: JSON.stringify(akjStuff.getTemplate(false)),
                },
                (result) => {
                }
            );
          } else {
            if (akjStuff.getTemplate(false) != undefined && akjStuff.getTemplate(false).indicators.length == 0) return;

            // create a chart template
            // with name 'defualt'
            const template = {
              NAME: "DEFAULT",
              CHART_DATA: JSON.stringify(akjStuff.getTemplate(false)),
              SYMBOL: window.$store.defaultSymbol,
              TIMEFRAME: window.$store.activeChart.timeframe,
            };

            API.dbChartTemplate.create(template, (result) => {
              // if (result.success) console.log("new created");
            });
          }

          break;

        case "detach_chart":
          // save current chart, auto save
          this.akj_event("chart-auto-save", "data");

          const width = screen.width - 300;
          const height = screen.height - 300;
          const left = 150;
          const top = 150;
          const t = new Date().getTime();

          const symbol = window.$store.activeChart.symbol;
          const timeframe = args != undefined && args.tf != undefined ? args.tf : window.$store.activeChart.timeframe;

          window.$store.detachCharts[t] = window.open(`./#/detach_chart?symbol=${symbol}&timeframe=${timeframe}`, "_blank", `width=${width},height=${height}, top=${top},left=${left}`);
          window.$store.detachCharts[t].onload = () => {
            window.$store.detachCharts[t].onunload = () => {
              delete window.$store.detachCharts[t];
            };
          };

          break;

          /*case "cluster-script":
          const command = args[0];

          if (command == "start") {
            // set default settings
            window.$store.cluster = true;

            // add cluster form to sidebar
            // only if it's not exist
            ClusterScript.generateSettings();
          } else if (command == "run") {
            // first save chart data
            this.akj_event("chart-auto-save", "data");

            if (args[1].includes("HorizontalLine")) {
              const price = window.$store.activeChart.chart.get(args[1])[0].settings.p1[1];
              ClusterScript.getResults({ type: "HL", price }, (result) => {
                // console.log(result);
              });
            } else {
              const p1 = window.$store.activeChart.chart.get(args[1])[0].settings.p1[1];
              const p2 = window.$store.activeChart.chart.get(args[1])[0].settings.p2[1];

              ClusterScript.getResults({ type: "Box", priceRange: [Math.min(p1, p2), Math.max(p1, p2)] }, (result) => {
                // console.log(result);
              });
            }
          } else if (command == "find") {
            this.akj_event("chart-auto-save", "data");
            ClusterScript.findClusters();
          }

          // remove context menu
          if (document.querySelector(".context-menu")) document.querySelector(".context-menu").remove();

          break;*/

        default:
          break;
      }
    },

    /** Add listers for new indicator settings **/
    addListener(root, callback) {
      if (!root) return;
      /** drop down code **/
      // hide all dropdown when click on ouside
      document.addEventListener("click", function (e) {
        var dropdown_list = document.querySelectorAll(".dropdown.active");
        for (var i = 0; i < dropdown_list.length; i++) {
          if (!dropdown_list[i].contains(e.target)) dropdown_list[i].classList.remove("active");
        }
      });

      // dropdown selector
      var dropdown_list = root.getElementsByClassName("dropdown");
      for (var i = 0; i < dropdown_list.length; i++) {
        dropdown_list[i].addEventListener("click", function (e) {
          this.classList.toggle("active");
        });
      }

      // dropdown list items
      var list_items = root.querySelectorAll(".dropdown li");
      for (var i = 0; i < list_items.length; i++) {
        list_items[i].addEventListener("click", function (e) {
          console.log(this.parentElement.previousElementSibling.firstElementChild, this.getAttribute("setting"), this.parentNode.previousElementSibling.id, this.getAttribute("value"));
          this.parentElement.previousElementSibling.querySelector(".selected-value").innerHTML = this.innerHTML;
          //this.previousElementSibling.querySelector(".selected-value").innerHTML = this.innerHTML;
          //let setting = this.getAttribute("setting");
          let value = isNaN(this.getAttribute("value")) ? this.getAttribute("value") : Number(this.getAttribute("value"));
          callback(this.getAttribute("setting"), value);
        });
      }

      // Number input
      var number_items = root.querySelectorAll(".akj-input-number");
      for (var i = 0; i < number_items.length; i++) {
        var input = number_items[i].firstElementChild.firstElementChild;
        let setting = input.getAttribute("setting");

        input.addEventListener("change", function (e) {
          var min = e.target.getAttribute("min") == null ? 1 : Number(e.target.getAttribute("min"));
          var max = Number(e.target.getAttribute("max"));
          let value = null;

          if (Number(e.target.value) < min) {
            value = min;
          } else if (e.target.getAttribute("max") != null && Number(e.target.value) > max) {
            value = max;
          } else {
            value = Number(e.target.value);
          }

          e.target.value = value;
          callback(setting, value);
        });
        number_items[i].querySelector(".increaseControl").addEventListener("click", function (e) {
          var input = this.parentElement.previousElementSibling.firstElementChild;

          let value = Number(input.value) + 1;

          if (input.getAttribute("max") != null && value > Number(input.getAttribute("max"))) {
            value = Number(input.getAttribute("max"));
          }

          input.value = value;

          callback(setting, value);
        });
        number_items[i].querySelector(".decreaseControl").addEventListener("click", function (e) {
          var input = this.parentElement.previousElementSibling.firstElementChild;
          var min = input.getAttribute("min") == null ? 1 : Number(input.getAttribute("min"));

          let value = Number(input.value) - 1;

          if (value < min) {
            value = min;
          }

          input.value = value;

          callback(setting, value);
        });
      }

      // Checkbox Input
      var check_items = root.querySelectorAll(".akj-input-check");
      for (var i = 0; i < check_items.length; i++) {
        var checkItem = check_items[i].firstElementChild;
        let setting = checkItem.getAttribute("setting");

        checkItem.addEventListener("click", function (e) {
          let value = e.target.checked;
          callback(setting, value);
        });
      }

      // Text Input
      var txt_items = root.querySelectorAll(".akj-input-text");
      for (var i = 0; i < txt_items.length; i++) {
        var txtItem = txt_items[i].firstElementChild;
        let setting = txtItem.getAttribute("setting");

        txtItem.addEventListener("keyup", function (e) {
          let value = e.target.value;
          callback(setting, value);
        });
      }
    },

    addThenRemoveClass(el, theClass, time) {
      el.classList.add(theClass);
      if (theClass != "animate-text") {
        setTimeout(() => {
          this.isShowModalStartup = false;
        }, time - 1000);
        setTimeout(() => {
          this.isShowModalStartup = false;
          el.classList.remove(theClass);
        }, time);
      }
    },

    changeIndicatorValue(setting, value) {
      this.chartHandler.changeIndicatorValue(setting, value);
    },
    hide() {
      //if (this.showCfg || this.showIndCfg || this.showWatchList)
      {
        this.showCfg = false;

        this.showIndCfg = false;
        this.showWatchList = false;
      }
    },
    cmList() {
       //console.log("getcmList",this.chartHandler.contextMenu)
      return this.chartHandler.contextMenu;
    },

    setShortDisplayText(description) {
      var shortText =  description ? description.replaceAll("&#13;&#10;","<br>") : "";
      var pos = shortText.indexOf("<br>");
      if (shortText.indexOf("<br>",pos+1)>0) {
        pos = shortText.indexOf("<br>",pos+1);
        shortText = shortText.substr(0,pos)+"<br>...";
      }
      return shortText;
    },

    setLiveModeReset(id) {
      this.chartHandler.sendLiveViewReset(id);
    },

    setLiveMode(value, id) {
      console.log("setLiveMode",value, id)
      Vue.set(this.chartHandler.liveCharting,'active', value)
      this.isLiveOnline = value
      if (value) {
        this.chartHandler.timerToSendLiveZoom(true);
        this.isLiveOnlineId = id
      }
      else
        this.isLiveOnlineId = -1
    },
    setOnline(value, newAnalysis) {
      //console.log("setOnline", value)
      if (this.publishId != this.isLiveOnlineId)
      {
        if (newAnalysis) {
          this.publishId = 0;
        } else {
          this.publishId = this.isLiveOnlineId;
        }
        var lv = this.chartHandler.getLiveViewFromId(this.publishId);
        this.publishName = "";
        this.publishText = "";
        this.publishedAbos.forEach((v,idx) => {
          this.publishedAbos[idx] = false;
        });
        this.publishedAnalysis = false;
        if (lv) {
          const self = this;

          this.publishedAbos.forEach((v,idx) => {
            this.publishedAbos[idx] = false;
          });
          console.log(lv);
          lv.abos.forEach((id) => {
            var pa = self.publishAbos;
            console.log(typeof pa);
            pa.forEach((abo, idx) => {
              if (abo.rightId == id) {
                this.publishedAbos[idx] = true
              }
            })
          })
          this.showModalAnalysis = true;
          this.publishName = lv.headline || "";
          this.publishText = atob(lv.resourceInfo || "");
          this.publishDescription = lv.description || "";
          this.publishCommentInternal = lv.commentInternal || "";
          this.publishLabelActions = lv.labelActions ? JSON.parse(atob(lv.labelActions)) : [];

          this.publishLabelActions.forEach((la) => {
            la.videoSize = 500;
          });

          this.publishVideoLength = 100;
          console.log('labelActions', this.publishLabelActions, "#######",lv.labelActions,'#######',this.publishText);
/*
          this.publishLabelActions = [{
            startSec: 4,
            id: 'zoom1',
            action: {
              type: 'zoom',
              from: 1664535522000,
              until: 1665380171000,
            }
          },
            {
              startSec: 7,
              id: 'zoom2',
              action: {
                type: 'zoom',
                from: 1663758185000,
                until: 1666160313000,
              }
            },
            {
              startSec: 13,
              id: 'rect1',
              action: {
                name: 'Rectangle',
                liveId: '123465798',
                type: 'Rectangle',
                points: {
                  p1: [
                    1665925200000,
                    19476.719392070623
                  ],
                  p2: [
                    1666238400000,
                    18618.25905804011
                  ]
                },
                settings: {
                  lineWidth: 1,
                  color: "rgba(18, 173, 196, 1)",
                  backColor: "rgba(69, 103, 138, 0.24)",
                  legend: false,
                  'z-index': 100,
                  $selected: false,
                  $state: 'finished',
                  $uuid: 'Rectangle-onchart-864037447397',
                  p1: [
                    1767,
                    19476.719392070623
                  ],
                  p2: [
                    1941,
                    18618.25905804011
                  ],
                  selected: false,
                  liveId: 'cc5077a8318c',
                  id: 'Rectangle-onchart-864037447397'
                },
                id: 'onchart.Rectangle0-1666345764185',
                data: [],
                side: 'onchart',
                showInCfg: true
              }
            }
          ];*/
          this.publishedAnalysis = (lv.inactive == 0);
          //this.publishText = this.publishText.replaceAll("&#13;&#10;",'\n');
        }
      }
      this.showModalCreateAnalysis = value;
    },
    showUserRightsDialog() {
      this.showUserRights = true;
    },
    setOnlineFromDialog() {
      this.showModalCreateAnalysis = false;

      var pT = btoa(this.publishText);
      var pD = this.publishDescription;
      var pCI = this.publishCommentInternal;
      var pLA = this.publishLabelActions||[];
      var pa = this.publishAbos;
      var abos = [];
      if (pD) {
        pD = pD.replaceAll('\n', "&#13;&#10;");
      }
      if (pCI) {
        pCI = pCI.replaceAll('\n', "&#13;&#10;");
      }
      if (this.publishedAbos && this.publishedAbos.length) {
        this.publishedAbos.forEach((v, idx) => {
          //console.log(pa);
          if (this.publishedAbos[idx]) {
            console.log('set', pa[idx], v);
            abos.push(pa[idx].rightId);
          }
        });
      }

      pLA.forEach((al) => {
        if (al.settings) {
          let idx = 0;
          while (al.settings['p' + (++idx)]) {
            al.settings.points['p' + idx] = [this.getTimeFromIndex("0", al.settings['p' + idx][0]), al.settings['p' + idx][1]];
          }
        }
      });

      console.log('setOnlineFromDialog',this.isLiveOnlineId,pT, abos);
      this.chartHandler.saveConfig('chart', true, this.publishId, this.publishName, pD, pCI, pT, btoa(JSON.stringify(pLA)), this.publishedAnalysis, abos);
      if (this.publishId > 0) {
        //console.log('publishedAnalysis',this.publishId, this.publishedAnalysis);
        this.chartHandler.setLiveChart(true, this.publishId, this.publishName, pD, pCI, pT, btoa(JSON.stringify(pLA)), this.publishedAnalysis, abos);
      }
    },
    setOffline(id) {
      if (id != 'alert') {
        //console.log("setOffline", id)
        //Vue.set(this.chartHandler.liveCharting,'active', value)
        this.chartHandler.setLiveChart(false, id);
      }
    },
    getRightInnerBarVisible() {
      return this.isLiveModeMessages || this.isLiveModeView || this.isLiveModeAlerts || this.isLiveModeVideo || this.isDebuggerMode || this.isMarketViewMode;
    },
    getHintHeight() {
      //if (this.charts)
      //  console.log('getHintHeight',this.charts['0'].hintData);
      return this.chartHandler.settings.showHint ? 250 : 0;
    },

    getColor(item) {
      return item && item.inactive == 0 ? "#1b1f2c" : "#1d323f";
    },

    canPublish() {
      return this.chartHandler.rights.canPublish;
    },

    displayContextMenu() {
      return this.isCursorSelected;
    },

    setUserAbo(id, idx) {
      this.chartHandler.setUserRight( this.userAbos[idx] ? 'd':'a', id, this.selectedUserId);
    },

    setUserRight(id, idx) {
      console.log(this.rights)
      this.chartHandler.setUserRight( this.userRights[idx] ? 'd':'a', id, this.selectedUserId);
    },

    setSelectedTrend(value) {
      this.chartHandler.setSelectedTrendConfig('showTrendId',value);
    },

    setSelectedTrendActive(value) {
      this.chartHandler.setSelectedTrendConfig('showTrendsOnlyActive',value);
    },

    setAbostate(type, item) {
      console.log(item,type);
      if (type !== 'termination') {
        window.open(item.shop_url, '_blank', 'noreferrer');
      } else {
        this.chartHandler.requestAboTermination(item);
      }
    },

    setSelectedMO(value) {
      this.chartHandler.setSelectedMarketOverviewId(value);
      console.log(value)
    },

    setSelectedAbos(value) {
      const self = this;

      //this.selectedUserId = value;

      var i = -1;
      while (++i<this.userRights.length) {
        Vue.set(this.userAbos,i,false);
        Vue.set(this.userRights,i,false);
      };

      const abosList = this.abos;
      const rightsList = this.rights;
      this.chartHandler.users.userRights.forEach((ur, i) => {
        if (ur.userId == value) {
          abosList.forEach((a, i)=>{
            if (a.rightId == ur.rightId) {
              Vue.set(self.userAbos,i,true);
            }
          });
          rightsList.forEach((a, i)=>{
            if (a.rightId == ur.rightId) {
              Vue.set(self.userRights,i,true);
            }
          });
        }
      });
    },
    useUserRights() {
      return this.$props.chartHandler.rights.useUserRights;
    },

    userInformationsLength() {
      const d = this.chartHandler.userInformations;
      //console.log(d);
      return d.count;
    },
    userInformations()
    {
      return this.chartHandler.userInformations.data[this.selectedUserInfoIndex];
    },
    changeSymbol(symbol) {
      console.log(symbol)
      this.on_symbolChanged(symbol,this.chartHandler.charts['' + this.chartHandler.activeChart].tf);
    },
    getSymbol() {
      return this.chartHandler.displayData.symbol;
    },
    liveAlerts() {
      const self = this;
      const symbolTemp = this.getSymbol();
      let res = this.chartHandler.adminData.filtered.filter((f) => { return (!self.filterAlerts) || (f.name == symbolTemp)});
      res.splice(50)
      return res; //this.chartHandler.adminData.filtered;
    },
    liveAnalysis() {
      var analysis = [];
      //console.log(this.chartHandler.liveCharting.liveAnalysis)
      if (this.selectedRightId <= 0) {
        analysis = this.chartHandler.liveCharting.liveAnalysis;
      } else {
        this.chartHandler.liveCharting.liveAnalysis.forEach((a) => {
          if (a && a.abos) {
            a.abos.forEach((r)=>{
              if (r == this.selectedRightId) {
                analysis.push(a);
              }
            })
          }
        });
      }
      return analysis;
    },
  },
  computed: {
    // Copy a subset of TradingVue props
    getIsLiveOnline() {
      return this.isLiveOnline
    },
    getUserAbos() {
      return this.userAbos;
    },
    getUserRights() {
      return this.userRights;
    },
    alertRows() {
      return this.chartHandler.adminData.filtered;
    },
    abos() {
      var a = [];
      this.chartHandler.users.rights.forEach((r) => {
        if ((r.groupKey == 'ABO') || (r.groupKey == 'ABO-PUBLISH'))
          a.push(r);
      });
      return a;
    },
    publishAbos() {
      const self = this;
      var a = [];

      this.chartHandler.users.rights.forEach((r) => {
        if (r.groupKey == 'ABO-PUBLISH') {
          if (this.chartHandler.isAdmin) {
            a.push(r);
          } else {
            self.chartHandler.users.userRights.forEach((ur) => {
              //console.log(ur);
              if (ur.rightId == r.rightId)
                a.push(r);
            });
          }
        }
      });
      return a;
    },
    rights() {
      var a = [];
      this.chartHandler.users.rights.forEach((r) => {
        if (r.groupKey == 'RIGHT')
          a.push(r);
      });
      return a;
    },
    getLiveChartId() {
      return this.chartHandler.liveCharting.liveChartId;
    },
    getLiveMode() {
      return this.isLiveModeView;
    },
    getLiveModeOnline() {
      return this.chartHandler.liveCharting.active && this.isAdmin;
    },
    getLiveModeOffline() {
      return !this.chartHandler.liveCharting.active && this.isAdmin;
    },
    getPulishVideos() {
      return this.isLiveModeVideo;
    },
    getMarketViewMode() {
      return this.isMarketViewMode;
    },
    getAccountMode() {
      return this.isAccountMode;
    },

    getMarketOverviewItem() {
      let list = this.chartHandler.displayData.marketListsLayout.filter(ml => ml.marketListId === this.selectedmarketOverviewId);
      //console.log('getMarketOverviewItem',list);
      return list.length == 1 ? list[0] : null;
    },
    getMarketOverviewList() {
      if ((this.selectedmarketOverviewId <= 0) && (this.chartHandler.displayData.marketListsLayout.length>0) && (this.chartHandler.chartSettings.currentMO)) {
        let id = this.chartHandler.displayData.marketListsLayout[0].marketListId;
        if (this.chartHandler.chartSettings.currentMO) {
            this.chartHandler.displayData.marketListsLayout.forEach((mo) => {
                if (this.chartHandler.chartSettings.currentMO == mo.marketName) {
                  id = mo.marketListId;
                }
            });
        }
        this.selectedmarketOverviewId = id;
      }
      return this.chartHandler.displayData.marketListsLayout
    },
    getDebuggerMode() {
      return this.isDebuggerMode;
    },

    debuggerTrends() {
      console.log('debuggerTrends');
      let trends = [{id:-1, name:"Alle Trends"}];
      this.chartHandler.charts['0'].settings.trends.forEach((t, idx) => {
        if ((this.selectedTrendType == "Alle") ||
            ((this.selectedTrendType == "Haupttrends") && (t.type == "MAIN")) ||
            ((this.selectedTrendType == "Sub-Haupttrends") && (t.type == "SUBMAIN")) ||
            ((this.selectedTrendType == "Kandidat") && (t.type == "CANDIDATE")) ||
            ((this.selectedTrendType == "Nebentrends") && (t.type == "SUB"))) {
          trends.push({id: idx, name: "" + (idx + 1) + ". Trend ("+t.type+")"});
        }
      });
      return trends;
    },
    getDebuggerInfos() {
      const self = this;
      let trendsInfo = (this.selectedDebuggerId !== "Trends" ? this.getScreenInfo() : this.chartHandler.charts['0'].settings.trends || []);
      const label= this.selectedDebuggerId+":";
      let lines = "<p>";
      trendsInfo.forEach((trdOrg, idx) => {
        if ((this.selectedDebuggerId !== "Trends") || (((this.selectedTrendId<0) || (this.selectedTrendId== idx)) && (!trdOrg.deleted || (this.selectedTrendOnlyActive==0)))) {
          lines += "<strong> " + (idx + 1) + ". " + label + " </strong><br>";
          const trdstr = JSON.stringify(trdOrg);
          const trd = JSON.parse(trdstr);
          //console.log('1',trd, Object.keys(trd), typeof trd, trdstr, trdOrg)
          Object.keys(trd).forEach((trdKey) => {
            if ((trd[trdKey]) && (typeof trd[trdKey] === "object")) {
              lines += "<div>" + trdKey + ": </div>";
              Object.keys(trd[trdKey]).forEach((trdKeySub) => {
                if ((trd[trdKey][trdKeySub]) && (typeof trd[trdKey][trdKeySub] === "object")) {
                  if (!this.selectedTrendOnly123 || (trd[trdKey][trdKeySub] && trd[trdKey][trdKeySub].rule && (trd[trdKey][trdKeySub].rule.indexOf("RULE123")>=0))) {
                    lines += "<div style=\"text-indent:15px;\">" + trdKeySub + ": </div>";
                    Object.keys(trd[trdKey][trdKeySub]).forEach((trdKeySubSub) => {
                      lines += "<div style=\"text-indent:30px;\">" + trdKeySubSub + ": " + self.getValueAsDate(trdKeySubSub, trd[trdKey][trdKeySub][trdKeySubSub]) + "</div>";
                      //lines += "| - -" + trdKeySubSub + ": " + trd[trdKey][trdKeySub][trdKeySubSub] + "<br>";
                    });
                  }
                } else {
                  lines += "<div style=\"text-indent:15px;\">" + trdKeySub + ": " + self.getValueAsDate(trdKeySub, trd[trdKey][trdKeySub]) + " </div>";
                  //lines += "| -" + trdKeySub + ": " + trd[trdKey][trdKeySub] + "<br>";
                }
              });
            } else {
              lines += "<div>" + trdKey + ": " + self.getValueAsDate(trdKey, trd[trdKey]) + " </div>";//+"<br>";
            }
          });
        }
      });
      return lines+"</p>";
    },
    getMessageMode() {
      return this.isLiveModeMessages;
    },
    getAlertMode() {
      return this.isLiveModeAlerts;
    },

    showRightBar() {
      return this.showCfg || this.showIndCfg || this.showWatchList;
    },
    chart_props() {
      return this.chartHandler.chartObjects[0].$props.tvjs.chart_props;
    },
    chart_config() {
      return Object.assign({},
          Const.ChartConfig,
          this.chartHandler.chartObjects[0].$props.tvjs.$props.chartConfig,
      );
    },
    styles() {
      let height = window.innerHeight-63;
      let width = parseInt(window.innerWidth)-330; //(this.showRightBar?330:0);

      return {
        width: width + 'px',
        height: height + 'px'
      };
    }
  },
  beforeCreate() {
    const loggedIn = JSON.parse(localStorage.getItem("user"));
    //console.log('beforeCreate', loggedIn)
    //console.log(this.$route);
    if (window.chartHandler) {
      this.chartHandler = window.chartHandler
    }
    else {
      this.chartHandler = window.chartHandler = new ChartHandler(loggedIn.token, loggedIn.isAdmin, this.$route.query);
    }

  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('click', function(e) {
      // Alternative
      if (e.buttons==2) {
        //console.log('2buttons');
        e.preventDefault();
      }
    }, false);
    this.onResize()
    const logo = document.querySelector(".animated_logo");
    this.addThenRemoveClass(logo, "animate-reveal", 6000);
    const logo_start = document.querySelector(".logo_start_div");
    this.addThenRemoveClass(logo_start, "animate-text", 500);
    const text_start = document.querySelector(".text_start");
    this.addThenRemoveClass(text_start, "animate-text", 500);

    //window.akj_event = this.akj_event;
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  data() {
    //console.log('data')
    return {
      columns: [
        { name: 'ID',prop: "userId", size : $("div").width()*0.03, },
        { name: 'Last Name',prop: "lastName",size : $("div").width()*0.15, },
        { name: 'First Name',prop: "firstName" ,size : $("div").width()*0.2,},
        { name: 'E-MAil-Address',prop: "email" ,size : $("div").width()*0.20, },
        { name: 'User Name',prop: "nickName" ,size : $("div").width()*0.15, },
        { name: 'Role',prop: "userRole" ,size : $("div").width()*0.05, cellTemplate: (createElement, props) => {
            return createElement('span', {
              style: {
                color: props.model[props.prop] == 'ADMIN' ? 'red' : 'green', "width" : "200px",
              },
            }, props.model[props.prop]);
          } },
        { name: 'State',prop: "state" , size : $("div").width()*0.05,cellTemplate: (createElement, props) => {
            return createElement('span', {
              style: {
                "border-radius": "20px","background": "#0a9800","color": "#ffffff","padding-top": "5px","padding-bottom": "5px","padding-right": "9px","padding-left": "9px"
              },
            }, props.model[props.prop]);
          }} ],
      columnsAlerts: [
        /*{ name: 'Zeitpunkt',prop: "lastIn" , size : $("div").width()*0.2,cellTemplate: (createElement, props) => {
            return createElement('span', {
              onClick: () => this.requestHistoryData(props.model["name"], props.model["timeBase"]),
              style: {
                "border-radius": "20px","background": "#0a9800","color": "#ffffff","padding-top": "5px","padding-bottom": "5px","padding-right": "9px","padding-left": "9px"
              },
            }, new props.model["lastIn"]);
          }},*/
        { name: 'Last Activation',prop: "lastInDate", size : 190, },
        { name: 'Last Deactivation',prop: "lastOutDate", size : 190, },
        { name: 'Symbol',prop: "name", size : 400, },
        { name: 'TF',prop: "timeBase",size : 55, },
        { name: 'Rule',prop: "rule" ,size : 250,},
        { name: 'Active',prop: "active" , size : 110,cellTemplate: (createElement, props) => {
            return createElement('span', {
              style: {
                "border-radius": "20px","background": "#0a9800","color": "#ffffff","padding-top": "5px","padding-bottom": "5px","padding-right": "4px","padding-left": "4px"
              },
            }, props.model["active"] ? "active" : "inactive");
          }},
        { name: 'Description',prop: "message", size : $("div").width()-1600, },
        {
          size: 200,
          // load Chart button
          cellTemplate: (h, props) => {
            return h(
                "span",
                {
                  onClick: () => this.requestHistoryData(props.model["name"], props.model["timeBase"]), style: {
                    "border-radius": "20px","background": "#FF6E00","color": "#ffffff","padding-top": "5px","padding-bottom": "5px","padding-right": "9px","padding-left": "9px"
                  }
                },
                "load Chart"
            );
          }
        }
        ],
      self: this,
      isCursorSelected: false,
      rows: this.chartHandler.users.users,
      userAbos:[false, false, false, false, false, false, false, false, false, false],
      userRights:[false, false, false, false, false, false, false, false, false, false],
      selectedUserId: null,
      selectedRightId: null,
      selectedTrendType: this.chartHandler.settings.showTrendType,
      selectedDebuggerId: "Trends",
      selectedTrendOnly123: true,
      selectedTrendOnlyActive:  this.chartHandler.settings.showTrendsOnlyActive,
      selectedTrendId: this.chartHandler.settings.showTrendId,
      selectedmarketOverviewId: 0,
      selectedUserInfoIndex: 0,
      ToolTipInfoText: "",
      //userInformationsLength: this.chartHandler.userInformations.count,
      filterAlerts: false,
      charts: this.chartHandler.charts,
      timeframes: this.chartHandler.timeframe,
      themes: this.chartHandler.timeframe,
      showContextMenu: false,
      showModal: false,
      showModalMultiScreen: false,
      showModalLoadMultiScreen: true,
      showModalCreateAnalysis: false,
      showModalAnalysis: false,
      showUserRights: false,
      currentChartId: this.chartHandler.charts["0"] && this.chartHandler.data ? this.chartHandler.data.chartId : -1,
      isShowModalStartup: true,
      showModalAdmin: false,
      showTabs: "Users",
      getRightOffse: 320,
      //showModalCharts: false,
      showCfg: false,
      showIndCfg: false,
      showDrawings: true,
      isShortcutModalOpen: false,
      showWatchList: false,
      showModalType: '',
      templateName: '',
      svgToolTip: this.chartHandler.layoutHandler.screens,
      makeDefault : false,
      templateTags: '',
      eventDefined: false,
      favTools: [],
      tools: [],
      publishId: 0,
      publishName: '',
      publishText: '',
      publishDescription: '',
      publishCommentInternal: '',
      publishLabelActions: [],
      publishVideoLength: 1,
      publishedAnalysis: false,
      publishedAbos: [false, false, false, false, false, false, false, false, false, false],
      userDataAbos: this.chartHandler.rights.abos,
      userData: this.chartHandler.userData,
      instantmessages : this.chartHandler.messanger.instanceMessages, //[{messageId :1, text : 'TEst1'},{messageId :2, text : 'Hallo Sven, das ist eine Testnachricht - hier steht irgenwas... VG Sven'},{messageId :3, text : 'Hallo'}],
      //liveAnalysis :this.chartHandler.liveCharting.liveAnalysis,
      indCfg: null,
      favVisible : true,
      lastOnAnimationInfo: 0,
      liveVideos : this.chartHandler.adminData.liveVideos,
      isliveAnalysisIsOnline : this.chartHandler.liveCharting.active,
      liveChartingId : this.chartHandler.liveCharting.liveChartId,
      updatedAnalysis : this.chartHandler.liveCharting.updatedAnalysis,
      isLiveOnline : false,
      isLiveOnlineId: 0,
      getRightOffset: 30,
      showHint: false,
      isLiveModeMessages : false,
      isLiveModeView : false,
      isLiveModeAlerts : false,
      isLiveModeVideo: false,
      isDebuggerMode: false,
      isMarketViewMode: true,
      isAccountMode: false,
      systemShortcuts: [],
      defTimeFrames: this.chartHandler.timeFrames,
      defThemes: this.chartHandler.getThemes(),
      defLogScale: [{id:1, name: "Absolut", value: false},{id:2, name: "Log", value: true}],
      defGrid: [{id:1, name: "keine Anzeige", value: 0},{id:2, name: "horizontal", value: 1},{id:3, name: "vertikal", value: 2},{id:4, name: "beide", value: 3}],
      watchLists: this.chartHandler.displayData.marketLists,
      menuItems: [],
      chartslayouts: [
            { x: 0, y: 0, w: 2, h: 2, i: "0" },
        { x: 2, y: 0, w: 2, h: 2, i: "1" },
        { x: 4, y: 0, w: 2, h: 2, i: "2" },
        { x: 6, y: 0, w: 2, h: 2, i: "3" },
        { x: 8, y: 0, w: 2, h: 2, i: "4" }],
      width: window.innerWidth,
      height: window.innerHeight,
      isAdmin: this.chartHandler.isAdmin,
      isApp: this.chartHandler.isApp,
      loadQuerySymbol: this.chartHandler.loadQuerySymbol,
      isLoadingHistoryData: this.chartHandler.loading.isLoadingHistoryData,
      draggable: true,
      resizable: true,
      colNum: 2,
      index: 0
    }
  }
}
</script>

<style>

#modal_BOX {
  position: fixed;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #3333337F;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

#modal_BOX_RIGHTS{
  position: fixed;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: #3333337F;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

#modal_inner_rights{
  position: fixed;
  width: 1000px;
  max-width: 1000px;
  height: 300px;
  border-radius: 8px;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  z-index: 2001;
  box-shadow: 0px 0px 12px 8px rgba(15, 15, 15, 0.2);
}

#modal_inner {
  position: fixed;
  width: 400px;
  height: 200px;
  border-radius: 8px;
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  z-index: 2001;
}

#modal_ADMINBOX {
  position: fixed;
  width: 100vw;
  height: 100vh;
  border-radius: 4px;
  background-color: rgb(12, 15, 24);
  left: 0vw;
  top: 0vh;
  z-index: 20000;
}

#modal_innerAdmin {
  position: fixed;
  max-width: 96vw;
  max-height: 96vw;
  width: 96vw;
  height: 96vh;
  border-radius: 8px;
  left: 2vw;
  top: 2vh;
  z-index: 20001;
  box-shadow: 0px 0px 12px 8px rgba(15, 15, 15, 0.2);
}

.modal-contentAdmin{
  background-color: #e5e5e5;
}

.modal-content{
  _box-shadow: 0px 0px 4px 4px rgba(194, 189, 189, 0.19);
  border-radius: 0px;
  _background-color: #0c0f18;
}

.searchbar {
  postion: absulute;
  z-index: 1001;
  width: 100vw;
}

.sidebarmenu {
  top: 70px;

}
.v-sidebar-menu {
  _background-color: #1b1e2c;
}

.item2 { grid-area: main;
  z-index: 1; }

.mainbox {
  display: flex;
  _margin-left: 25px;
  height: calc(100% - 60px);
}

.innermainbox {
  width: 45px;
  height: calc(100% - 0px);
}

.innermainboxmain {
  _flex: 1 0 calc(100% - 45px);
  border-right: 1px solid #43434a;
}

.multi-chart {
  _background-color: #1b1e2c;
  background-color: #f2f2f2;
  color: #0D3349;
}
.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
  z-index:1;
}

.multi-chart-grid {
  _background-color: #ffffff;
  z-index: 0;;
  height: calc(100% - 0px);
}
.vue-grid-layout {
  background: #ffffff;
  z-index: 1;
  width: calc(100% - 0px)

}
.chartbox {
  z-index: 1;
}
.multi-chart-gid .grid-layout {
  z-index: 1;
}

.vue-grid-item {
  /* transition: all 200ms ease; */
  transition: none;
}

.vue-grid-layout {
  /* transition: height 200ms ease; */
  transition: none;
}


.sidebar.v-sidebar-menu .vsm--mobile-item {
  z-index: 0 !important;
}
.sidebar.v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover {
  background-color: transparent;
}

.sidebar.v-sidebar-menu .vsm-arrow:after {
  content: "\f105";
  font-family: "FontAwesome";
}
.sidebar.v-sidebar-menu .collapse-btn:after {
  content: "\f07e";
  font-family: "FontAwesome";
}
.sidebar {
  top: 70px;
}

body {
  margin: 0;
  font-family: "Inter", "Avenir", "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande", "Lucida Sans", Arial, sans-serif;
  font-size: 14px;
  color: #e1e1e1;
  _background-color: #121826;
}

* {
  outline: none;
  box-sizing: border-box;
}

.sep {
  width: 100%;
  height: 1px;
  background-color: #414953;
  margin-bottom: 12px;
}

#wrapper {
  height: 100vh;
  overflow: hidden;
}

#chart_wrapper {
  position: relative;
  display: inline-block;
  width: calc(100% - 320px);
  height: 100vh;
  overflow: hidden;
}

.active_chart canvas {
  box-shadow: inset 0px 0px 2px 2px #1ab794;
  lbox-shadow: inset 0px 0px 2px 2px #f66f00;
}

#wrapper.no-sidebar #chart_wrapper {
  width: 100%;
}

.right_sidebar {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  float: right;
  width: 320px;
  height: 100%;
  box-sizing: border-box;
  _background-color: #1b1e2c;
  _background-color: #f2f2f2;
  _background-color: inherit;
  border-left: 1px solid #2f344b;
  _color: #b2b5be;
  overflow-y: scroll;
  user-select: none;
  -user-select: none;
  -webkit-user-select: none; /* Chrome/Safari */
  -webkit-user-select: none; /* Safari only */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  z-index: 100;
}

.close-icon {
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 22px;
  width: 24px;
  height: 24px;
  opacity: 0.3;
}
.close-icon:hover {
  opacity: 1;
}
.close-icon:before,
.close-icon:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 100%;
  width: 2px;
  background-color: #fff;
}
.close-icon:before {
  transform: rotate(45deg);
}
.close-icon:after {
  transform: rotate(-45deg);
}

#wrapper.no-sidebar #right_sidebar {
  display: none;
}
/* #wrapper.no-sidebar .tb-showSideBar {
	display: block !important;
} */

/*==== common components ====*/
.btn-wrapper {
  display: flex;
  padding: 12px;
}

.btn-wrapper .btn:not(:first-child) {
  margin-left: 12px;
}

.btn {
  cursor: pointer;
  min-width: 100px;
  padding: 4px;
  text-align: center;
  text-decoration: underline;
}

.admin-bar-item {
  margin-right: 1px;
  background-color: #FF6E00;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  cursor: pointer;
  text-decoration: none;
}

.btn-primary{
  background-color: rgba(255, 255, 255, 0.99);
  border: 1px solid #FF6E00;
  color: #FF6E00;
  cursor: pointer;
  text-decoration: none;
}

.btn-primary:hover  {
  border: 1px solid #FF6E00;
  background-color: #FF6E00;
  color: #ffffff;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.btn-primary:disabled  {
  border: 1px solid #484b5b;
  background-color: #414248;
  color: #ffffff;
  transition: all 250ms ease-in-out;
  cursor: default;
  text-decoration: none;
}

.btn-primary_ {
  color: #fff;
  background-color: #018df2;
  background-color: #00aa84;
  text-decoration: none;
  border-radius: 4px;
  transition: all ease 0.3s;
  border: 1px solid transparent;
}
.btn-ghost {
  color: #b2b5be;
  background-color: transparent;
  border: 1px solid #b2b5be;
}
.btn-full {
  width: 100%;
}
.btn_:hover {
  color: #0897ff;
}
.btn-primary_:hover {
  color: #fff;
  background-color: #35aaff;
  background-color: #ff8800;
}
.btn-ghost:hover {
  color: #fff;
  border: 1px solid #fff;
  background-color: transparent;
}

.btn_detach {
  display: flex;
  align-items: center;
}
.btn_detach span.icon {
  display: inline;
  cursor: pointer;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAAdZJREFUaAXtlz1OhEAUxxcCobJcW0mAEKI3sFJPYO8dTLyItafQC7hWFh7ALAlUNiba2REI+N5mN5klw9cyX5o3yWZhZph5v/9/PhcLSqQAKUAK/CUFLN3Bpml6ZFnW1Zg4PM97833/k1fX4WWqzHMc56Qsy8cxfRZFcQ31nnh1tYOAGz/w4wYHAS+bpjnnBd7O6wRZr9dNu/Kh70mSdA7hIAg+oF1Uei/leX4MTq32Mnte7J4ybUUMxCkE8Q6OvQ4F0+nI7sM+NXd1RP63IVzXvayq6mGoD6Mc4UGEYfg1BIHlxoDMgTAGZC6EESAiILSDiILQCjIFAlauWzgBnMHm+IxB89Lg8sv7aG7eFAjsa7tp9narfNWaCtEbPVOoFEQWBPIoA5EJoQxENoQSEBUQ0kHmQuBVYux1QtocybJsub1PbI7ieIodewBEhacmKSAIAUfvFwhGCQRCC98Q2xCwI1+AE99TFZ5aX6gjPIgoiqRDILQwEJ0QQkHqur6BBjdzAoeTKicQApMwR+I4vrdt+04HBIIInewIg43qSMIc0RE82yeBsGqY8EyOmOACGwM5wqphwjM5YoILbAyDO/vYGxrbqI7nfzO0dIhHfZICpAApcLgCv3yi7jUOX1vaAAAAAElFTkSuQmCC");
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 40px;
  height: 40px;
  background-color: #262a3f;
  border-radius: 50%;
  overflow: hidden;
}
/* .btn_detach:hover {
    filter: brightness(2);
}
*/
.btn_detach .tooltip {
  position: absolute;
  white-space: nowrap;
  transform: translate(-100%, 0);
  left: -10px;
  background: #3e404f;
  border-radius: 20px;
  padding: 4px 12px;
  font-size: 12px;
  color: #e0e1e3;
  display: none;
}
.btn_detach:hover .tooltip {
  display: block;
}

/*==== Indicator List ====*/
.accordion-item {
  margin-bottom: 2px;
}

.accordion-header {
  position: relative;
  font-weight: bold;
  cursor: pointer;
  _background-color: #1b2a3b;
  line-height: 40px;
  padding: 0px 19px;
}

.accordion-header.cluster {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-header.cluster input[type="checkbox"] {
  width: 20px;
  height: 22px;
}

.accordion-body {
  display: none;
  padding: 20px 12px;
}

.accordion-item.active .accordion-body {
  display: block;
}

.accordion-item.active .accordion-header:before {
  content: "";
  position: absolute;
  left: 0;
  width: 6px;
  height: 40px;
  _background-color: #0897ff;
}

.field-group {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  justify-content: space-between;
}

.field-group > span {
  flex-grow: 1;
}

.field-group > div {
  position: relative;
  width: 140px;
}
.field-group > label {
  position: relative;
  margin-top: 8px;
  width: 140px;
}

/*==== drawing settings ====*/
#drawingSettings {
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1000;
  box-shadow: 1px 3px 2px 0px rgba(0, 0, 0, 0.5);
}
#drawingSettings .field-group.color,
#drawingSettings .field-group.delete .akj-input {
  width: 40px;
}
#drawingSettings .field-group.color .dropdown-selector,
#drawingSettings .field-group.delete .akj-input {
  padding: 0px;
}
#drawingSettings .dropdown-selector,
#drawingSettings .akj-input {
  border-radius: 0;
  margin-right: -1px;
}
#drawingSettings .field-group.color .swatch,
#drawingSettings .field-group.delete .akj-input .icon {
  margin: 0 auto;
}
.icon-delete:hover {
  filter: brightness(1.45) sepia(1) hue-rotate(310deg) saturate(4.5) !important;
}
#drawingSettings .field-group {
  cursor: pointer;
  margin: 0;
}

/*==== Input Type checkbox ====*/
.akj-input input[type="checkbox"] {
  display: block;
  height: 100%;
  border: none;
  padding: 0;
  background-color: transparent;
  box-shadow: 0 0 0 0 transparent;
  font-size: 14px;
  transition: color 0.35s ease;
  box-sizing: border-box;
  -webkit-text-fill-color: currentColor;
}
.akj-input-check {
  border: none !important;
  padding: 0 !important;
}

/*==== Input Type Number ====*/

/* remove arrows/spinners from input type number */
/* Chrome, Safari, Edge, Opera */
.akj-input input::-webkit-outer-spin-button,
.akj-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
/* Firefox */
.akj-input input[type="number"],
.akj-input input[type="text"] {
  color: #b2b5be;
  font-size: 14px;
  -moz-appearance: textfield;
}

.akj-input {
  display: flex;
  height: 40px;
  border: 1px solid #2b323c;
  _background-color: #181e29;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 8px;
  align-items: center;
}

.akj-input input[type="number"],
.akj-input input[type="text"] {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  padding: 0;
  background-color: transparent;
  box-shadow: 0 0 0 0 transparent;
  font-size: 14px;
  transition: color 0.35s ease;
  box-sizing: border-box;
  -webkit-text-fill-color: currentColor;
}

.akj-input .control_wrapper div {
  cursor: pointer;
}

/*==== drop down ====*/
.dropdown-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 13px;
  position: absolute;
  r-width: 120px;
  left: 0;
  right: 0;
  z-index: 1000;
}

#dropdown-menu-timezone {
  width: 260px;
  right: 0;
  left: auto;
  height: 250px;
  overflow: scroll;
  font-size: 12px;
}

.dropdown-menu li {
  display: flex;
  line-height: 35px;
  height: 35px;
  padding: 0 8px;
  _background-color: #1b2a3b;
  _color: #ccc;
  align-items: center;
}

.dropdown-menu li:hover {
  color: #fff;
  background-color: #0897ff;
}

.dropdown-menu li:hover .line {
  background-color: #fff;
}

.dropdown.active .dropdown-menu {
  display: block;
}

.dropdown-selector {
  display: flex;
  padding: 0 8px;
  height: 40px;
  border: 1px solid #414953;
  _background-color: #121c29;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.selected-value {
  /* display: flex;
  align-items: center; */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.line {
  height: 1px;
  display: block;
  width: 100%;
  border-radius: 2px;
  background-color: #6d7278;
}

.dropdown-selector .line {
  width: 90%;
}

.line-2 {
  height: 2px;
}

.line-3 {
  height: 3px;
}

/*==== Color drop down ====*/
.akj-color .dropdown-selector {
  width: 40px;
}

.akj-color .dropdown-menu {
  width: 190px;
  flex-wrap: wrap;
  _background-color: #1b2a3b;
  padding: 5px;
  box-sizing: border-box;
  left: -60px;
  position: absolute;
  top: 44px;
}
#drawingSettings .akj-color .dropdown-menu {
  width: 190px;
  left: 0;
}

.akj-color.active .dropdown-menu {
  display: flex;
}
.akj-color .dropdown-menu li {
  height: 20px;
  padding: 1px;
  width: 20px;
  margin: 0px;
}

.akj-color .swatch {
  width: 20px;
  height: 20px;
  border-radius: 2px;
}

/*==== Modal style ====*/
.modal {
  position: fixed;
  min-width: 400px;
  border-radius: 8px;
  _background-color: #0c0f15;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
.modal_header {
  padding: 12px;
  border-bottom: 1px solid #414953;
}
.modal_header h3 {
  font-size: 16px;
  margin: 0;
}
.modal_content {
  padding: 12px;
  max-height: 60vh;
  overflow-y: scroll;
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 12px;
  border-top: 1px solid #414953;
}
.modal_footer .btn {
  margin-left: 12px;
}

/*==== Modal style ====*/
.context-menu {
  color: #fff;
  position: absolute;
  min-width: 170px;
  top: 44px;
  _background-color: #121826;
  box-shadow: 0px 1px 6px 1px #00000080;
  z-index: 1000;
  user-select: none;
}

.context-menu .menu-item {
  padding: 12px 12px;
}

.context-menu .menu-item:hover {
  _background-color: #1b222f;
}

/*==== Sidebar child items ====*/
#wrapper #right_sidebar.indicator_settings #indicator_settings {
  display: block !important;
}
#wrapper #right_sidebar.chart_settings #chart_settings {
  display: block !important;
}
#wrapper #right_sidebar.watchlist #watchlist {
  display: block !important;
}

.watchlist_header {
  width:304px;
}

#watchlist table {
  font-family: arial, sans-serif;
  font-size: 13px;
  border-collapse: collapse;
  width: 20px;
  table-layout: fixed;
  margin-left: -10px;
  width: 100%;
}

#watchlist th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding: 1px;
  padding-top: 8px;
  padding-bottom: 8px;
}

#watchlist td {
  cursor: pointer;
}

#watchlist tr:hover td {
  _color: #fff;
  _background-color: #0897ff;
}

#watchlist td {
  text-align: left;
  padding: 1px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/*==== Cluster script ====*/
.cluster_settings_wrapper {
  border-top: 1px solid #414953;
  margin-top: 12px;
}

.timeframe_label {
  position: relative;
  cursor: pointer;
  _background-color: #1b2a3b;
  line-height: 40px;
  padding: 0 12px;
  margin: 12px 0;
}

.timeframe_label:first-child {
  margin-top: 0px;
}

.cluster_result_item {
  cursor: pointer;
  padding: 0 0px 12px 12px;
}

.cluster_result_item:hover {
  color: #00aa84;
}

.cluster_result_item svg {
  margin-left: 12px;
}

.cluster_result_item:hover svg {
  stroke: #00aa84;
}


.animated_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  _background: #05091815;
}
#svground {
  position: relative;
  width: 70px;
  height: 70px;
  animation: rotate 3s linear infinite;
}

svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 10;
  stroke: #00a1ff;
  stroke-linecap: round;
  transform: translate(5px,35px);
  stroke-dasharray: 150;
  stroke-dashoffset: 250;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0%,100% {
    stroke-dashoffset: 440;
  }
  50% {
    stroke-dashoffset: 0;
  }
  50.1% {
    stroke-dashoffset: 880;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/*==== Mobile Responsive ====*/
@media only screen and (max-width: 1100px) {
  .trading-vue-toolbar {
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .trading-vue-toolbar::-webkit-scrollbar {
    display: none;
  }

  .trading-vue-toolbar .tb-group {
    position: relative !important;
    right: 0 !important;
    left: 24px;
    padding-right: 48px;
  }

  .trading-vue-toolbar .tb-group .tb-showSideBar {
    position: fixed;
    _background: #191f2b;
    right: 0;
    padding: 2px 4px 3px;
    margin-right: 0;
    border-radius: 0px !important;
    width: 46px !important;
  }
}

/* Other */
.hideOffcharts .splitpanes__pane:first-child {
  height: 100% !important;
}

.hideOffcharts .splitpanes__pane:not(:first-child) {
  display: none;
}

.hideOffcharts .splitpanes__splitter {
  display: none;
}


.easy-cm-ul li{
  background-color: rgb(40, 40, 40);
  color: #eff2ff;
  padding : 1px;
}
.easy-cm-ul li div:hover{
  background-color: #424242 !important;
  color: rgb(234, 234, 234) !important;
  padding : 1px;
}

.btn:focus {
  outline: none;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.99);
  border: 1px solid #FF6E00;
  color: #FF6E00;
}

table {
  width:10px;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(72, 69, 69, 0.85);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(241, 241, 241, 0.49);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(82, 80, 80, 0.93);
}

body_ani {
  background-image: radial-gradient(circle farthest-corner at center, #3C4B57 0%, #1C262B 100%);
}

.loader {
  position: absolute;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  width: 64px;
  height: 64px;
  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.inner.one {
  left: 0%;
  top: 0%;
  animation: rotate-one 1s linear infinite;
  border-bottom: 5px solid #e35522;
}

.inner.two {
  right: 0%;
  top: 0%;
  animation: rotate-two 1s linear infinite;
  border-right: 5px solid #1b1ba6;
}

.inner.three {
  right: 0%;
  bottom: 0%;
  animation: rotate-three 1s linear infinite;
  border-top: 5px solid #37373b;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}


revogr {
  width: 100% !important;
  overflow-x: hidden;
}


#logo_start_div {
  width: 600px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -300px;
  margin-top: -250px;
  border-radius: 8px;
  background-color: #2c2c2c;
  box-shadow: 0px 0px 12px 8px rgba(194, 189, 189, 0.37);
  opacity: 0%;
}


.logo_start {
  margin-top: -4em;
}

#text_start {
  width: 600px;
  height: 400px;
  position: absolute;
  margin-top: 2em;
  top: 50%;
  left: 50%;
  margin-left: -300px;
  opacity: 0%;
  text-align: center;
  font-size: xx-large;
  color: #FFFFFF
}

@media (max-width: 750px) {
  #logo_start {
    width: 300px;
    height: 200px;
    margin-left: -150px;
    margin-top: -125px;
  }
  #text_start {
    width: 300px;
    height: 200px;
    margin-left: -150px;
    margin-top: 50px;
    font-size: large;
  }
}

/* Classes that are added to each box */
.animate-text {
  opacity: 0.0;
  animation: textin 4s linear;
}
.animate-reveal {
  opacity: 0;
  animation: fadeinout 5000ms linear;
}

/* Keyframes for animation */
@keyframes fadeinout {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  95% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

/* Keyframes for animation */
@keyframes textin {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.0;
  }
}


/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .1s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.preference {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: .5rem;
}

.style-chooser {
  vs-font-size: 0.9rem;
  vs-line-height: 0.9;

}

.style-chooser .vs__search,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  _background: #dfe5fb;
  border: none;
  _color: #1b213f;
  background: #fff;
  color: #2F2F2F;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #2F2F2F;
}

.dark .vs__search,
.dark .vs__dropdown-toggle,
.dark .vs__dropdown-menu{
  background: #F2F2F2;
  border: none;
  color: #2F2F2F;
}

.dark .vs__clear,
.dark .vs__open-indicator{
  fill: #2F2F2F;
}

#selectedRightList {
  width : 240px;
  height: 2em;
  font-size: small;
}

.userInformation {
  width : 75vw;
  max-width : 60em;
  background-color: rgba(23, 56, 63, 1.0);
  position: fixed;
  min-width: 400px;
  border-radius: 8px;
  border: 2px solid #FF6E00;
  left: 50%;
  top: 70px;
  transform: translate(-50%, -0%);
  z-index: 10000;
  padding:1em;
  padding-top:0.7em;
}

.toolInformation {
  width : initial;
  max-width : 50em;
  min-width: 20em;
  background-color: rgba(23, 56, 63, 1.0);
  position: fixed;
  border-radius: 8px;
  border: 1px solid #FF6E00;
  padding:0.5em;
}

.liveModeUser {
  display: block;
  float: left;
  position: fixed;
  z-index: 100;
  color: #06b6ef;
  align-content: end;
  text-align: right;
  right_: 140px;
  font-size: medium;
  top: 110px;
}

.toolInformationText {
  _transform: translate(50%, 0%);
  display: flex;
  justify-content: center
}

.userInformationHead {
  font-size: large;
}

#modal-analysis {
  width: 80vw;
  background-color: #2d2d2d;
  border: 1px solid #cb9a2c;
  transform: translate(-50%, -50%);
  position: fixed;
  left: 50%;
  top: 50%;
  height: 80vh;
}
</style>