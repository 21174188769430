// Semi-automatic pin object. For stretching things.

import Utils from '../../stuff/utils.js'

export default class Pin {

    // (Comp reference, a name in overlay settings,
    // pin parameters)
    constructor(comp, name, params = {}) {

        this.RADIUS = comp.$props.config.PIN_RADIUS || 3.5
        this.RADIUS_SQ = Math.pow(this.RADIUS + 7, 2)

        if (Utils.is_mobile) {
            this.RADIUS += 2
            this.RADIUS_SQ *= 2.5
        }

        this.COLOR_BACK = comp.$props.colors.back
        this.COLOR_BR = comp.$props.colors.text

        this.comp = comp
        this.layout = comp.layout
        this.mouse = comp.mouse
        this.name = name
        this.state = params.state || 'settled'
        this.hidden = params.hidden || false

        this.mouse.on('mousemove', e => this.mousemove(e))
        this.mouse.on('mousedown', (e) => this.mousedown(e))
        this.mouse.on('checkfocus', (e) => this.checkfocus(e))
        this.mouse.on('mouseup', e => this.mouseup(e))

        if (comp.state === 'finished') {
            this.state = 'settled'
            this.update_from(comp.$props.settings[name])
        } else {
            this.update()
        }

        if (this.state !== 'settled') {
            this.comp.$emit('scroll-lock', true)
        }
    }

    re_init() {
        this.update_from(
            this.comp.$props.settings[this.name]
        )
    }

    draw(ctx) {
        if (this.hidden) return
        switch (this.state) {
            case 'tracking':
                break
            case 'dragging':
                if (!this.moved) this.draw_circle(ctx)
                break
            case 'settled':
                this.draw_circle(ctx)
                break
        }
    }

    draw_circle(ctx) {

        this.layout = this.comp.layout
        if (this.comp.selected) {
            var r = this.RADIUS, lw = 1.5
            ctx.fillStyle = this.COLOR_BACK
        } else {
            var r = this.RADIUS * 0.95, lw = 0.5
            ctx.fillStyle = '#efefef4f'
        }

        ctx.lineWidth = lw
        ctx.strokeStyle = this.COLOR_BR
        ctx.beginPath()
        ctx.arc(
            this.x = this.layout.t2screen(this.t),
            this.y = this.layout.$2screen(this.y$),
            r + 0.5, 0, Math.PI * 2, true)
        ctx.fill()
        ctx.stroke()
    }

    update() {

        this.y$ = this.comp.$props.cursor.y$
        this.y =  this.comp.$props.cursor.y
        this.t = this.comp.$props.cursor.t
        this.x =  this.comp.$props.cursor.x

        // Save pin as time in IB mode
        //if (this.layout.ti_map.ib) {
        //    this.t = this.layout.ti_map.i2t(this.t )
        //}

        //console.log(this.comp.$parent.$parent.$parent.$parent.$parent.chartHandler.chartSettings.isMagnetMode);
        if (this.comp.$parent.$parent.$parent.$parent.$parent.chartHandler.chartSettings.isMagnetMode) {
            let nc = this.comp.layout.c_magnet(this.t);
            if (this.magnetInfo == undefined) this.magnetInfo = {};
            if (nc) {
                let h = nc.h;
                let h_1 = Math.min(nc.o, nc.c);
                let l = nc.l;
                let l_1 = Math.max(nc.o, nc.c);
                let magnet_y$ = 0;
                let magnetOffset = 40;

                if (h - magnetOffset <= this.y && this.y < (h + h_1) / 2) {
                    magnet_y$ = nc.raw[2];
                    this.y$ = magnet_y$;
                    this.magnetInfo.text = `High ${magnet_y$.toFixed(2)}`;
                } else if ((h + h_1) / 2 <= this.y && this.y < (h_1 + l_1) / 2) {
                    magnet_y$ = Math.max(nc.raw[1], nc.raw[4]);
                    this.y$ = magnet_y$;
                    const openorclose = nc.raw[1] > nc.raw[4] ? "Open" : "Close";
                    this.magnetInfo.text = `${openorclose}  ${magnet_y$.toFixed(2)}`;
                } else if ((h_1 + l_1) / 2 <= this.y && this.y < (l_1 + l) / 2) {
                    magnet_y$ = Math.min(nc.raw[1], nc.raw[4]);
                    this.y$ = magnet_y$;
                    const openorclose = nc.raw[1] < nc.raw[4] ? "Open" : "Close";
                    this.magnetInfo.text = `${openorclose}  ${magnet_y$.toFixed(2)}`;
                } else if ((l_1 + l) / 2 <= this.y && this.y < l + magnetOffset) {
                    magnet_y$ = nc.raw[3];
                    this.y$ = magnet_y$;
                    this.magnetInfo.text = `Low  ${magnet_y$.toFixed(2)}`;
                } else {
                    this.magnetInfo.text = null;
                }

                this.magnetInfo.y = this.comp.layout.$2screen(magnet_y$);
            }
        }

        // Reset the settings attahed to the pin (position)
        this.comp.$emit('change-settings', {
             [this.name]: [this.t, this.y$]
        })
    }

    update_from(data, emit = false) {
        if (!data) return
        this.layout = this.comp.layout

        this.y$ = data[1]
        this.y = this.layout.$2screen(this.y$)
        this.t = data[0]
        this.x = this.layout.t2screen(this.t)

        if (this.on_update_from) {
            this.on_update_from(this.y$, this.t, this.state);
        }
        // TODO: Save pin as time in IB mode
        //if (this.layout.ti_map.ib) {
        //    this.t = this.layout.ti_map.i2t(this.t )
        //}

        if (emit) this.comp.$emit('change-settings', {
             [this.name]: [this.t, this.y$]
        })

        if (window.chartHandler && window.chartHandler.isSendLiveChart())
            window.chartHandler.sendLiveChartIndicator(this.comp);

    }

    rec_position() {
        this.t1 = this.t
        this.y$1 = this.y$
    }

    mousemove(event) {
        switch(this.state) {
            case 'tracking':
            case 'dragging':
                this.moved = true
                this.update()
                if (this.on_dragging) this.on_dragging(this.name);
                break
        }


    }

    checkfocus(event) {
        //console.log('checkfocus',this.isDrawingCheck());
        return !this.hidden && this.hover() && this.comp && this.comp.selected;
    }

    isDrawingCheck(withFirstDrawingPin = true) {
        return window.chartHandler.isDrawingMode || (withFirstDrawingPin && !this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$refs.leftbar.isCursorSelected(true));
    }

    mousedown(event, force = false) {
        //console.log('mousedown',this.isDrawingCheck(),this.comp.id, force);
        if (this.isDrawingCheck() && (this.comp.id != window.chartHandler.drawingId)) return;
        if (Utils.default_prevented(event) && !force && !this.isDrawingCheck()) return
        //console.log('mousedown 2',this.isDrawingCheck(),this.comp.id, force);
        switch (this.state) {
            case 'tracking':
                this.state = 'settled'
                if (this.on_settled) this.on_settled(this.name, "mousedown")
                this.comp.$emit('scroll-lock', false)
                break
            case 'settled':
                if (this.hidden) return
                if (this.hover()) {
                    this.state = 'dragging'
                    this.moved = false
                    this.comp.$emit('scroll-lock', true)
                    this.comp.$emit('object-selected')
                }
                break
        }
        if (this.hover() || this.isDrawingCheck(false)) {
            event.preventDefault()
        }
    }

    mouseup(event) {
        //console.log('mouseup mousedown',this.isDrawingCheck(),this.comp.id);
        if (this.isDrawingCheck() && (this.comp.id != window.chartHandler.drawingId)) return;
        switch (this.state) {
            case 'dragging':
                this.state = 'settled'
                console.log("mouseup pin");
                if (this.on_settled) this.on_settled(this.name,"mouseup")
                this.comp.$emit('scroll-lock', false)
                break
        }
    }

    on(name, handler) {
        switch (name) {
            case 'settled':
                this.on_settled = handler
                break
            case 'dragging':
                this.on_dragging = handler
                break
            case 'update_from':
                this.on_update_from = handler
                break
        }
    }

    hover() {
        let x = this.x
        let y = this.y
        return (
            (x - this.mouse.x) * (x - this.mouse.x) +
            (y - this.mouse.y) * (y - this.mouse.y)
        ) < this.RADIUS_SQ
    }

}
