<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";
import ChartUtils from "../utils/ChartUtils.js";

export default {
    name: "Rectangle",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "akjcodes", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                name: "Rectangle",
                group: "Drawing",
                icon: Icons["rectangle.png"],
                type: "Price",
                hint: "Price Range",
                data: [], // Default data
                settings: {
                    lineWidth: 1,
                    color: "#ffb74d",
                    backColor: "#ffb74d20",
                },
                fieldsTemplate: [
                    ["color", "Color", "color"],
                    ["lineWidth", "Line Thickness", "lineWidth"],
                    ["color", "Back Color", "backColor"],
                ],
            };
        },
        // Called after overlay mounted
        init() {
          this.updatePins();
        },
        draw(ctx) {
            if (!this.p1 || !this.p2) return;

            ctx.fillStyle = this.back_color;
            //ctx.fillStyle += ChartUtils.alphaToHex(this.back_opacity);

            if (this.show_pins)
              ctx.lineWidth = this.line_width+3;
            else
              ctx.lineWidth = this.line_width;
            ctx.strokeStyle = this.color;
            ctx.beginPath();

            // Background
            const layout = this.$props.layout;
            let x1 = layout.t2screen(this.p1[0]);
            //let x1 = this.p1[0];
            let y1 = layout.$2screen(this.p1[1]);
            let x2 = layout.t2screen(this.p2[0]);
            //let x2 = this.p2[0];
            let y2 = layout.$2screen(this.p2[1]);
            ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
            // ctx.strokeRect(x1, y1, x2 - x1, y2 - y1)

            // Top
            new Seg(this, ctx).draw(this.p1, [this.p2[0], this.p1[1]]);
            // // Top
            new Seg(this, ctx).draw([this.p2[0], this.p1[1]], this.p2);
            // // Top
            new Seg(this, ctx).draw(this.p2, [this.p1[0], this.p2[1]]);
            // // Top
            new Seg(this, ctx).draw([this.p1[0], this.p2[1]], this.p1);
            ctx.stroke();

            //console.log('add_ind_2',this.p1[0],this.p1[1],this.p2[0],this.p2[1])

            this.render_pins(ctx);
        },
        use_for() {
            return ["Rectangle"];
        },
        data_colors() {
            return [this.color];
        },
      updatePins() {
        const self = this;
        //console.log('add_ind_updatePins');if (this.$props.settings.p1) {
        this.pins.splice(0,this.pins.length);
        if (this.$props.settings.p1){
          this.pins.push(
              new Pin(this, "p1", {
                state: "finished",
                t: this.$props.settings.p1[0],
                y$: this.$props.settings.p1[1],
              })
          );
          this.pins.push(
              new Pin(this, "p2", {
                state: "finished",
                t: this.$props.settings.p2[0],
                y$: this.$props.settings.p2[1],
              })
          );
        } else {
          self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("Rectangle", this.id, this.$props.settings);
          // First pin is settled at the mouse position
          this.pins.push(new Pin(this, "p1"));
          // Second one is following mouse until it clicks
          this.pins.push(
              new Pin(this, "p2", {
                state: "tracking",
              })
          );
          this.pins[1].on("settled", () => {
            // Call when current tool drawing is finished
            // (Optionally) reset the mode back to 'Cursor'
            const orgState = this.state;

            this.set_state("finished");
            this.$emit("drawing-mode-off");

            if (orgState !== 'finished')
              self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("Rectangle");
            //global drawing mode
            //window.$store.drawingMode = false;
          });
        }
      },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        p1() {
            return this.$props.settings.p1;
        },
        p2() {
            return this.$props.settings.p2;
        },
        line_width() {
            return this.sett.lineWidth || 1;
        },
        color() {
            return this.sett.color || "#ffb74d";
        },
        back_color() {
            return this.sett.backColor || "#9b9ba3";
        },
        back_opacity() {
            return 100;
        },
    },
    data() {
        return {};
    },
};
</script>
