<template>
  <div id="hint-overlay" style="border: thin solid #ffffff;padding-top: 5px;padding-bottom: 8px">
    <hint-keys-table-auto-overlay :hintData="hintData" :id="id" :chartHandler="chartHandler" />
  </div>
</template>

<script>
import HintKeysTableAutoOverlay from "./HintKeysTableAutoOverlay.vue";

//const StompJs = require("@stomp/stompjs/esm5");

export default {
  name: "HintOverlay",
  props: ["hintData","chartHandler","id"],
  components: {HintKeysTableAutoOverlay
  },

  mounted() {
    // enable columns in the fib keys table
    // it takes few seconds to available data
    // So, that's why timers hack
    /*  let timer = setInterval(() => {
          if (window.$store.CHARTS == undefined || $store.activeChart.chart == undefined) return;
          this.CHART = window.$store.CHARTS[this.tvId];
          this.CHART.chart.get(`AUTOFIB`).forEach((fib) => {
              document.getElementById("fib-keys-table-auto").classList.add(fib.name.split("-")[1]);
          });

          // start websocket
          this.ws();

          clearInterval(timer);
      }, 100);
*/    },

  methods: {
  },

  beforeDestroy() {
    //if (this.client) this.client.deactivate();
  },

  data() {
    return {
    };
  },
};
</script>

<style>
#hint-overlay {
  #sfont-weight: 400;
  color: #e1e1e1;
  position: absolute;
  left: 10px;
  background-color: #262b3f;
  padding: 6px 0px 4px;
  font-size: 13px;
  z-index: 99;
  font-weight: 600
}
</style>
