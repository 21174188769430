<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";
//import Line from "../primitives/line.js";
import ChartUtils from "../utils/ChartUtils.js";

export default {
    name: "TrendChannel",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "C451", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                name: "TrendChannel",
                group: "trendchannel",
                icon: Icons["trendchannel.png"],
                type: "Segment",
                hint: "This hint will be shown on hover",
                data: [], // Default data
                settings: {
                    lineWidth: 1,
                    color: "#ffb74d",
                    backColor: "#ffb74d",
                    extendP1: true,
                    extendP2: true,
                }, // Default settings
                fieldsTemplate: [
                    ["color", "Color", "color"],
                    ["lineWidth", "Line Thickness", "lineWidth"],
                    ["color", "Back Color", "backColor"],
                ],
            };
        },
        // Called after overlay mounted
        init() {
          this.updatePins();
        },
        updatePins() {
            // loading from saved data
          const self = this;
          this.pins.splice(0,this.pins.length);
            if (this.$props.settings.p1) {
              this.$props.settings.$state = "finished";
                this.pins.push(new Pin(this, "p1"), new Pin(this, "p2"), new Pin(this, "p3"));

                this.pins[0].on("dragging", () => {
                    if (this.pins[2]) this.pins[2].t = Math.max(this.p1[0], this.p2[0]);
                  if (window.chartHandler && window.chartHandler.isSendLiveChart())
                    window.chartHandler.sendLiveChartIndicator(this.comp, true);
                });

                this.pins[1].on("dragging", () => {
                  if (this.pins[2]) this.pins[2].t = Math.max(this.p1[0], this.p2[0]);
                  if (window.chartHandler && window.chartHandler.isSendLiveChart())
                    window.chartHandler.sendLiveChartIndicator(this.comp, true);
                });

                this.pins[2].on("dragging", () => {
                  //this.pins[2].t = Math.max(this.p1[0], this.p2[0]);
                  //this.pins[2].y$ = this.Y;

                  this.p3[0] = this.pins[2].t;
                  this.p3[1] = this.pins[2].y$;
                  if (window.chartHandler && window.chartHandler.isSendLiveChart())
                    window.chartHandler.sendLiveChartIndicator(this.comp, true);
                });

                this.pins[2].on("settled", () => {
                  this.pins[2].t = Math.max(this.p1[0], this.p2[0]);
                  this.pins[2].y$ = this.Y;

                  this.p3[0] = this.pins[2].t;
                  this.p3[1] = this.pins[2].y$;
                  //console.log(this.state);
                  if (this.state !== 'finished') {
                    this.set_state("finished");
                    this.$emit("drawing-mode-off");
                    self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("TrendChannel");
                  }
                  if (window.chartHandler && window.chartHandler.isSendLiveChart())
                    window.chartHandler.sendLiveChartIndicator(this.comp, true);
                });

                this.pins[2].t = this.pins[1].t;
            } else {
              self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("TrendChannel", this.id, this.$props.settings);
                // First pin is settled at the mouse position
                this.pins.push(new Pin(this, "p1"));
                // Second one is following mouse until it clicks
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "tracking",
                    })
                );

                this.pins[1].on("settled", () => {
                    // Call when current tool drawing is finished
                    // (Optionally) reset the mode back to 'Cursor'
                    //this.set_state("finished");

                    // Second one is following mouse until it clicks
                    if (!this.pins[2]) {
                        this.pins.push(
                            new Pin(this, "p3", {
                                state: "tracking",
                            })
                        );

                        this.pins[2].on("settled", () => {
                            //   this.pins[2].t = this.p1[0] + (this.p2[0] - this.p1[0]) / 2;
                            this.pins[2].t = Math.max(this.p1[0], this.p2[0]);
                            this.pins[2].y$ = this.Y;

                            this.p3[0] = this.pins[2].t;
                            this.p3[1] = this.pins[2].y$;
                            //console.log(this.state);
                            if (this.state !== 'finished') {
                              this.set_state("finished");
                              this.$emit("drawing-mode-off");
                              self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("TrendChannel");
                            }
                            if (window.chartHandler && window.chartHandler.isSendLiveChart())
                              window.chartHandler.sendLiveChartIndicator(this.comp, true);
                        });
                    }
                });

                this.pins[0].on("dragging", () => {
                    if (this.pins[2]) this.pins[2].t = Math.max(this.p1[0], this.p2[0]);

                  if (window.chartHandler && window.chartHandler.isSendLiveChart())
                    window.chartHandler.sendLiveChartIndicator(this.comp, true);
                });

                this.pins[1].on("dragging", () => {
                    if (this.pins[2]) this.pins[2].t = Math.max(this.p1[0], this.p2[0]);

                  if (window.chartHandler && window.chartHandler.isSendLiveChart())
                    window.chartHandler.sendLiveChartIndicator(this.comp, true);
                });
            }
        },
        draw(ctx) {
            if (!this.p1 || !this.p2) return;

            if (this.show_pins)
              ctx.lineWidth = this.line_width+3;
            else
              ctx.lineWidth = this.line_width;
            ctx.strokeStyle = this.color;
            ctx.beginPath();

            new Seg(this, ctx).draw(this.p1, this.p2);

            ctx.stroke();

            if (this.pins[2]) {

                const x1 = this.p1[0]; //t
                const y1 = this.p1[1]; //$y
                const x2 = this.p2[0];
                const y2 = this.p2[1];

                let rx1 = this.layout.t2screen(x1);
                let ry1 = this.layout.$2screen(y1);
                let rx2 = this.layout.t2screen(x2);
                let ry2 = this.layout.$2screen(y2);

                const x3 = this.pins[2].t;
                const y3 = this.pins[2].y$;

                this.Y = y3 - ((x2 - x3) * (y1 - y2)) / (x2 - x1);

                this.newY1 = y1 + (this.Y - y2);

                let rY = this.layout.$2screen(this.Y);
                let rnewY1 = this.layout.$2screen(this.newY1);

                ctx.beginPath();

                new Seg(this, ctx).draw([x1, this.newY1], [x2, this.Y]);

                this.drawExtendion(ctx, rx1, rx2, ry1, ry2);
                this.drawExtendion(ctx, rx1, rx2, rnewY1, rY);

                ctx.stroke();

                ctx.fillStyle = this.back_color;
                ctx.fillStyle += ChartUtils.alphaToHex(this.back_opacity);

                if (this.show_pins)
                  ctx.lineWidth = this.line_width+3;
                else
                  ctx.lineWidth = this.line_width;
                ctx.strokeStyle = this.color;

                ctx.beginPath();

                ctx.moveTo(rx1, ry1);
                ctx.lineTo(rx2, ry2);
                ctx.lineTo(rx2, rY);
                ctx.lineTo(rx1, rnewY1);

                ctx.closePath();
                ctx.fill();
            }

            this.render_pins(ctx);
        },
        drawExtendion(ctx, x1, x2, y1, y2) {
          const layout = this.$props.layout;

          let w = layout.width
          let h = layout.height
          // TODO: transform k (angle) to screen ratio
          // (this requires a new a2screen function)
          let k = (y2 - y1) / (x2 - x1)
          let s = Math.sign(x2 - x1 || y2 - y1)
          let dx = w * s * 2
          let dy = w * k * s * 2
          if (dy === Infinity) { dx = 0, dy = h * s}

          if (this.extendP1) {
            ctx.moveTo(x2, y2)
            ctx.lineTo(x2 + dx, y2 + dy)
          }

          if (this.extendP2) {
            ctx.moveTo(x1, y1)
            ctx.lineTo(x1 - dx, y1 - dy)
          }
        },
        use_for() {
            return ["TrendChannel"];
        },
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        p1() {
            return this.$props.settings.p1;
        },
        p2() {
            return this.$props.settings.p2;
        },
        p3() {
            return this.$props.settings.p3;
        },
        extendP1() {
          return this.sett.extendP1;
        },
        extendP2() {
          return this.sett.extendP2;
        },
        line_width() {
            return this.sett.lineWidth || 1;
        },
        color() {
            return this.sett.color || "#ffb74d";
        },
        back_color() {
            return this.sett.backColor || "#9b9ba3";
        },
        back_opacity() {
            return this.sett.backOpacity || 10;
        },
    },
    data() {
        return {};
    },
};
</script>
