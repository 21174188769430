<template>
  <div v-if="modelOpen">
    <transition name="modal">
        <div id="modal_BOX" class="modal-mask">
          <div class="modal-wrapper">
            <div id="modal_inner" class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Keyboard Shortcuts</h5>
                  <button v-shortkey="['esc']" @shortkey="$emit('on-close')"  type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" @click="$emit('on-close')">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="section">
                    <h6>Indicators &amp; Drawing</h6>
                    <div class="list_item" v-for="(item, i, idx) in system_shortcuts" :key="i">
                      <span class="title">{{ item.NAME }}</span>
                      <span class="shortcut">
                        <select class="input_drop" @change="$emit('on-shortcut-change', 'sys', i, $event, item)" ref="elSysOpt">
                            <option selected :value="item.SHORTCUT != '' ? item.SHORTCUT : ''">
                                {{ item.SHORTCUT != "" ? item.SHORTCUT : "none" }}
                            </option>
                          <!-- <option selected :value="item.SHORTCUT">{{ item.SHORTCUT }}</option> -->
                          <!-- {{"option" == item.SHORTCUT ? (selected = "selected") : (selected = "")}} -->
                            <option v-for="option in available_shortcuts" :key="option" :value="option">
                                {{ option }}
                            </option>
                        </select>
                    </span>
                    </div>
                  </div>
                  <div class="section" v-if="favourite_shortcuts.length > 0">
                    <h4>Favourite Tools</h4>
                    <div class="list_item" v-for="(item, i) in favourite_shortcuts" :key="i">
                      <span class="title">{{ item.NAME }}</span>
                      <span class="shortcut">
                        <select class="input_drop" @change="$emit('on-shortcut-change', 'fav', i, $event)" ref="elFavOpt">
                            <option selected :value="item.SHORTCUT != '' ? item.SHORTCUT : ''">
                                {{ item.SHORTCUT != "" ? item.SHORTCUT : "none" }}
                            </option>
                          <!-- <option selected :value="item.SHORTCUT">{{ item.SHORTCUT }}</option> -->
                            <option v-for="option in available_shortcuts" :key="option">
                                {{ option }}
                            </option>
                        </select>
                    </span>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary btn-ghost" @click="$emit('on-close')">Cancel</button>
                  <button @click="submit" type="submit" class="btn btn-primary ">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
    </transition>
  </div>
</template>


<script>
//import hotkeys from "hotkeys-js";
//import API from "@/helpers/fb.js";

export default {
    name: "KeyboardShortcuts",
    props: ["favTools", "systemShortcuts", "modelOpen"],

    created() {
        //API.init();
      this.validShortcuts = []; //"none", "ctrl+1", "ctrl+2", "ctrl+3", "ctrl+4", "ctrl+5", "ctrl+6", "ctrl+7", "ctrl+8", "ctrl+9", "ctrl+e", "ctrl+y", "ctrl+u", "ctrl+i", "ctrl+[", "ctrl+]", "ctrl+g", "ctrl+h", "ctrl+j", "ctrl+k", "ctrl+l", "ctrl+;", "ctrl+b", "ctrl+m", "ctrl+/"];

      let base = 'ctrl+';
      for (let i = 1; i < 10; i++) {
        this.validShortcuts.push(base+(i));
      }
      for (let i = 97; i < 123; i++) {
        this.validShortcuts.push(base+String.fromCharCode(i));
      }
    },

    methods: {
      restrictChars(event) {
          console.log(event);
      },
        submit() {
            //API.dbShortcuts.updateShortcuts(JSON.stringify(this.system_shortcuts));
          //API.dbFavourite.updateShortcuts(JSON.stringify(this.favourite_shortcuts));
            this.add_event_listners();

            // close the modal
            this.$emit("on-close");
        },

        add_event_listners() {
            // remove all shortcuts
            //hotkeys.deleteScope("all");

            // add listners for system shortcuts
            this.system_shortcuts.forEach((tool) => {

              if (tool.currentSHORTCUT) {
                tool.SHORTCUT = tool.currentSHORTCUT;
                delete tool.currentSHORTCUT;
              }
              console.log(tool);

                // add shortcuts for favourite tools
                /*hotkeys(tool.SHORTCUT, (event, handler) => {
                    // prevent browser default action, as possible
                    event.preventDefault();

                    // pass tool and default settings
                    if (!window.$store.drawingMode) this.$emit("tool-selected", tool.TYPE);
                });*/
            });

            // add listners for favourite shortcuts
            this.favourite_shortcuts.forEach((tool) => {
                if (!tool.SHORTCUT || tool.SHORTCUT == "none") return;

                // add shortcuts for favourite tools
                /*hotkeys(tool.SHORTCUT, (event, handler) => {
                    // prevent browser default action, as possible
                    event.preventDefault();

                    // pass tool and default settings
                    if (!window.$store.drawingMode) this.$emit("tool-selected", tool.TYPE, JSON.parse(tool.SETTINGS));
                });*/
            });

            this.$emit("on_save_shortcuts");
        },
    },

    computed: {
        available_shortcuts() {
            const sysKeys = this.system_shortcuts.map((item) => item.SHORTCUT);
            const favKeys = this.favourite_shortcuts.map((item) => item.SHORTCUT);
            const allKeys = [...sysKeys, ...favKeys];
            const k = this.validShortcuts.filter((item) => allKeys.indexOf(item) == -1);

            return k;
        },

        system_shortcuts() {
            return this.systemShortcuts;
        },

        favourite_shortcuts() {
            return this.favTools;
        },
    },

    data() {
        return {
        //    validShortcuts: ["none", "ctrl+1", "ctrl+2", "ctrl+3", "ctrl+4", "ctrl+5", "ctrl+6", "ctrl+7", "ctrl+8", "ctrl+9", "ctrl+e", "ctrl+y", "ctrl+u", "ctrl+i", "ctrl+[", "ctrl+]", "ctrl+g", "ctrl+h", "ctrl+j", "ctrl+k", "ctrl+l", "ctrl+;", "ctrl+b", "ctrl+m", "ctrl+/"],
        };
    },

    watch: {
        systemShortcuts() {
            this.add_event_listners();
        },

        favourite_shortcuts() {
            this.add_event_listners();
        },
    },
};
</script>

<style>
.section h4 {
    font-weight: 400;
    background-color: #1e222d;
    padding: 10px 10px;
    border-radius: 4px;
}
.list_item {
    cursor: pointer;
    display: flex;
    color: #b2b5be;
    justify-content: space-between;
    margin-bottom: 16px;
    line-height: 30px;
    padding: 4px 10px;
    transition: background-colorfff ease 0.2s;
}

.list_item span {
    user-select: none;
}

.list_item .shortcut {
    position: relative;
    /* box-shadow: 0 2px 0 #434651;
    border: 1px solid #434651;
    border-radius: 6px;
    height: 30px;
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px; */
    display: flex;
    align-items: center;
}

.input_drop {
  cursor: pointer;
  color: #b2b5be;
  background: #0c0f15;

  box-shadow: 0 2px 0 #434651;
  border: 1px solid #434651;
  border-radius: 6px;
  height: 30px;
  padding-left: 12px;
  padding-right: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 8' width='16' height='8'%3E%3Cpath fill='%23fff' d='M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input_shortcut {
  cursor: pointer;
  color: #b2b5be;
  background: #0c0f15;

  box-shadow: 0 2px 0 #434651;
  border: 1px solid #434651;
  border-radius: 6px;
  height: 30px;
  padding-left: 12px;
  padding-right: 30px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 8' width='16' height='8'%3E%3Cpath fill='%23fff' d='M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
