<template>
    <div id="tbl-daxen-keys">
        <table style="width: 150px;  background-color: #1b1f2c; margin-right: 70px; margin-top: 10px">
            <tr>
                <th colspan="4" style="background-color: rgb(101,21,21);text-align:center">Daxen</th>
            </tr>
            <tr>
              <td class="Daxen-column" style="width:25%;text-align:center" >active:</td>
              <td class="Daxen-column" style="width:15%;text-align:right;" >{{activeRunsCount}}</td>
              <td class="Daxen-column" style="width:45%;text-align:center;">finished:</td>
              <td class="Daxen-column" style="width:15%;text-align:right;">{{finishedRunsCount}}</td>
            </tr>
        </table>
    </div>
</template>

<script>

export default {
    name: "DaxenMenu",
    props: ["daxen","chartHandler","activeRunsCount","finishedRunsCount"],

    mounted() {

    },
  computed: {
    activeRuns() {
      return this.$props.chartHandler.daxenHandler.info.activeRuns;
    }
  },

    methods: {
        zoomTo(fibType) {
          console.log(fibType);
          //this.chartHandler.chartObjects[this.id].$refs.tvjs.resetChart();

          //const firstId = this.$props.chartHandler.layoutHandler.getFirstId();


            let $max = null;
            let $min = null;
            let t1 = null;
            let t2 = null;

            if (fibType == "Fib2") {
                const fib1 = this.chartHandler.charts[''+this.id].settings.fibo.F1;
                const fib2 = this.chartHandler.charts[''+this.id].settings.fibo.F2;

                $max = Math.max(fib1.p1[1], fib1.p2[1], fib2.p1[1], fib2.p2[1]);
                $min = Math.min(fib1.p1[1], fib1.p2[1], fib2.p1[1], fib2.p2[1]);

                t1 = Math.min(fib1.p1[0], fib1.p2[0], fib2.p1[0], fib2.p2[0]);
            } else {
                let fib;
                if (fibType == "Fib1")
                  fib = this.chartHandler.charts[''+this.id].settings.fibo.F1;
                else
                  fib = this.chartHandler.charts[''+this.id].settings.fibo.FX;
                $max = Math.max(fib.p1[1], fib.p2[1]);
                $min = Math.min(fib.p1[1], fib.p2[1]);

                t1 = Math.min(fib.p1[0], fib.p2[0]);
            }

            //const offset = ($max - $min) / 30;
      //    this.chartHandler.chartObjects[this.id].$refs.tvjs.set_yRange($max + offset, $min - offset);

            // time offset
            const ohlcv = this.chartHandler.charts[''+this.id].data.get("chart")[0];
            t2 = ohlcv.data[ohlcv.data.length - 1][0];

            let diff = (t2 - t1)/20;
          this.chartHandler.chartObjects[this.id].$refs.tvjs.setRange(t1-5*diff, t2+diff, 5);
            // $store.activeChart.chart.tv.setRange(t1, t2, 5);*/
        },

        ws() {
          /*
            this.client = new StompJs.Client({ brokerURL: "wss://chart-software.com/ws" });
            this.client.activate();

            this.client.onConnect = () => {
                this.client.subscribe(`/topic/${this.CHART.symbol}/${this.CHART.timeframe}/fibo`, (data) => {
                    var fib = JSON.parse(data.body);
                    // this.fibKeys[fibData.type] = [Number(fibData.fibRatio).toFixed(4), Number(fibData.consolidationFactor).toFixed(4)];
                    document.getElementById(`${fib.type}Ratio`).textContent = Number(fib.fibRatio).toFixed(4);
                    document.getElementById(`${fib.type}Factor`).textContent = Number(fib.consolidationFactor).toFixed(4) * 100;
                });
            };*/
        },
    },

    beforeDestroy() {
        //if (this.client) this.client.deactivate();
    },

    data() {
        return {
            client: null,
            CHART: null,
            activeRuns: this.$props.chartHandler.daxenHandler.info.activeRuns,
            fibKeys: {
                FibX: [32, 21],
                Fib1: [3, 32.20],
                Fib2: [12.20, 23.0],
            },
          modelOpen: true,
        };
    },
};
</script>

<style scoped>
#tbl-fibkeys {
  font-weight: 400;
  color: #e1e1e1;
  position: absolute;
  left: 10px;
  background-color: #262b3f;
  padding: 6px 0px 4px;
  font-size: 12px;
  z-index: 99;
}

#tbl-fibkeys tr {
    height: 20px;
}

#tbl-fibkeys th {
    cursor: pointer;
}

#tbl-fibkeys th span {
    padding: 2px 4px;
    font-weight: 400;
}

#tbl-fibkeys th,
#tbl-fibkeys td {
    padding: 0 8px;
    text-align: left;
}

#tbl-fibkeys .fibDataX {
    color: #b601ef;
  text-align: center;
}

#tbl-fibkeys .fibData1 {
    color: rgba(27, 31, 44, 0.91);
  text-align: center;
}

#tbl-fibkeys .fibData2 {
    color: #00adff;
  text-align: center;
}

.Daxen-column,
.Fib1-column,
.Fib2-column {
    /*display: none;*/
  color: #d0d0d0;
  font-weight: 700;
}

#caption-column {
  width : 500px;

}

.FibX .FibX-column,
.Fib1 .Fib1-column,
.Fib2 .Fib2-column {
    display: table-cell;
}
</style>
