<script>
import Overlay from "../lib/mixins/overlay.js";
import Math2 from '../lib/stuff/math.js'
//import Rect from "../lib/components/primitives/rect.js";
//import Seg from "../lib/components/primitives/seg";

export default {
    name: "AutoTrend",
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: "formationstrader",
                version: "1.0",
                desc: "Auto Trend",
            };
        },
        tool() {
            return {
                // Descriptor for the tool
                group: "Indicators",
                type: "Indicator",
                chartType: "onchart",
                name: "Auto Trend",
                hint: "This hint will be shown on hover",
                settings: {
                    flags: [],
                    "z-index": 1,
                    isAutoLines: true,
                },
            };
        },

        canShow(rule) {
          return (rule) && (rule != 'NO_EXTREME_AT_END') && (rule.indexOf('RULE123')>=0);
        },

      getTranslation(rule) {
          return this.$parent.$parent.$parent.$parent.$parent.chartHandler.getTranslation(rule);
        },

        draw(ctx) {
          this.extHint = {idx:-1};
            if (!this.$parent.$parent.$parent.$parent.$parent.chartHandler.chartSettings.isAutoLines) return;
            const currentTrendId = this.$parent.$parent.$parent.$parent.$parent.chartHandler.settings.showTrendId;
            const setting = this.setts ;
            //console.log(setting.flags);
            let visibleLineIdx = currentTrendId;
            let isMain = false;
            if (setting.trends) {
              setting.trends.forEach((trend, idx) => {
                if ((currentTrendId < 0) || (currentTrendId == idx))
                  if (this.checkLine(trend)) {
                    if (!isMain) {
                      visibleLineIdx = idx;
                      isMain = trend.type == "MAIN";
                    }
                  }
              });
              let parent = (visibleLineIdx>=0) && setting.trends[visibleLineIdx] && (setting.trends[visibleLineIdx].type == "MAIN") && (this.$parent.$parent.$parent.$parent.$parent.chartHandler.settings.showTrendsOnlyActive != 2)? setting.trends[visibleLineIdx].startDate : 0;
              setting.trends.forEach((trend, idx) => {
                if (((this.$parent.$parent.$parent.$parent.$parent.chartHandler.settings.showTrendsOnlyActive == 0) ||
                    !trend.deleted) &&
                    ((visibleLineIdx < 0) || (visibleLineIdx== idx) || (parent == setting.trends[idx].parent)))
                  this.drawLine(ctx, trend, idx+1, (currentTrendId >= 0)); //, { copy: flag.copy, source: flag.source });
              });
            }
            this.drawHint(ctx, );
            //if (setting.flags[1] != undefined) this.drawLine(ctx, setting.flags[1].lines[0], { copy: setting.flags[1].copy, source: setting.flags[1].source }, true);
        },

        drawHint(ctx) {

          if (this.extHint.idx>=0) {
            const layout = this.$props.layout;
            let labelColor = 'rgb(255,255,255)';
            let fillColor = 'rgba(45,58,61,1.0)';
            if (window.chartHandler.chartSettings.theme.indexOf("dark") < 0) {
              labelColor = "rgb(10,10,10)";
              fillColor = "rgb(246,245,245)";
            }
            const line = this.setts.trends[this.extHint.lineIdx];
            const ext = line.extensions[this.extHint.idx];
            let extY = this.extHint.extY;
            let extX = this.extHint.extX;
            //console.log(ext, this.mouse.t, this.mouse.x, this.mouse.y);
            let date = new Date(ext.when);
            ctx.lineWidth = 1;

            let offsetX = 70;
            let offsetY = 50;
            if (ext.validBreakout) {
              offsetY = extY - layout.$2screen(ext.validBreakout) + 75;
            } else if (ext.consoPrice) {
              offsetY = extY - layout.$2screen(ext.consoPrice) + 75;
            }
            extX += offsetX;
            extY -= offsetY;
            const oldStyle = ctx.fillStyle;
            ctx.fillStyle = fillColor;
            this.roundRect(ctx, extX, extY, 350, 95, 8, true, true, date.toLocaleString(), labelColor);

            const textWidth = 100;
            ctx.fillStyle = labelColor;
            ctx.fillText(this.getTranslation(ext.rule), extX + 20, extY + 30, 1000);
            ctx.fillText(ext.validBreakout, extX + 110, extY + 50, 150);
            ctx.fillText(ext.consoPrice, extX + 110, extY + 66, 150);

            if (ext.ruleDate) {
              date = new Date(ext.ruleDate);
              ctx.fillText(date.toLocaleString(), extX + 110, extY + 82, 1000);
            }

            //ctx.fillText('rule:', extX+5, extY+30, 150);
            ctx.fillText('validBreakout:', extX + 20, extY + 50, 150);
            ctx.fillText('consoPrice:', extX + 20, extY + 66, 150);
            ctx.fillText('ruleDate:', extX + 20, extY + 82, 500);

            ctx.stroke();
            let oldstrokeStyle = ctx.strokeStyle;
            ctx.lineWidth = 2;
            if (ext.validBreakout) {
              ctx.strokeStyle = '#00bbff';
              ctx.beginPath();
              ctx.moveTo(extX - offsetX, layout.$2screen(ext.validBreakout));
              ctx.lineTo(extX, extY);
              ctx.stroke();
            }

            if (ext.consoPrice) {
              ctx.strokeStyle = '#ff9900';
              ctx.beginPath();
              ctx.moveTo(extX - offsetX, layout.$2screen(ext.consoPrice));
              ctx.lineTo(extX, extY);
              ctx.stroke();
            }

            ctx.lineWidth = 1;
            ctx.strokeStyle = oldstrokeStyle;
            ctx.fillStyle = oldStyle;
          }
        },

        checkLine(line) {
          const layout = this.$props.layout;
          const m = this.cursor;
          let x1 = layout.t2screen(line.startDate);
          let y1 = layout.$2screen(line.startPrice);
          let x2 = layout.t2screen(line.endDate);
          let y2 = layout.$2screen(line.endPrice);
          return Math2.point2seg([m.x, m.y], [x1,y1],[x2,y2])< 4;
        },

        drawLine(ctx, line, num, debuggerMode) {
          /*if (//!debuggerMode &&
              !((this.$parent.$parent.$parent.$parent.$parent.chartHandler.settings.selectedTrendType == "Alle") ||
                ((this.$parent.$parent.$parent.$parent.$parent.chartHandler.settings.selectedTrendType == "Haupttrends") && (t.type == "MAIN")) ||
                ((this.$parent.$parent.$parent.$parent.$parent.chartHandler.settings.selectedTrendType == "Kandidat") && (t.type == "CANDIDATE")) ||
                ((this.$parent.$parent.$parent.$parent.$parent.chartHandler.settings.selectedTrendType == "Nebentrends") && (t.type == "SUB")))) return;
*/
          const layout = this.$props.layout;
          let labelColor = 'rgb(255,255,255)';
          let fillColor = 'rgba(0,0,0,1.0)';
          if (window.chartHandler.chartSettings.theme.indexOf("dark") < 0) {
            labelColor = "rgb(10,10,10)";
            fillColor = "rgb(246,245,245)";
          }
            // define the styles

          if (line.deleted) {
            ctx.lineWidth = 0.5;
            //labelColor = 'rgba(255,255,255,0.16)';
            if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
              ctx.strokeStyle = line.up ? "rgba(62,218,0,0.57)":"rgba(255,0,61,0.6)";
            } else {
              ctx.strokeStyle = line.up ? "rgba(62,218,0,0.57)":"rgba(255,0,61,0.6)";
            }
            labelColor = 'rgb(255,255,255,.2)';
            fillColor = 'rgba(45,58,61,.2)';
            if (window.chartHandler.chartSettings.theme.indexOf("dark") < 0) {
              labelColor = "rgb(10,10,10,.2)";
              fillColor = "rgb(246,245,245,.2)";
            }
          } else if (line.type=="MAIN") {
            ctx.lineWidth = 4;
            if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
              ctx.strokeStyle = line.up ? "#3eda00":"#ff003d";
            } else {
              ctx.strokeStyle = line.up ? "#3eda00":"#ff003d";
            }
          } else if (line.type=="SUBMAIN") {
            ctx.lineWidth = 2.5;
            if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
              ctx.strokeStyle = line.up ? "#ccfdb8":"#fc9eb2";
            } else {
              ctx.strokeStyle = line.up ? "#ccfdb8":"#fc9eb2";
            }
          } else if (line.type=="CANDIDATE")  {
            ctx.lineWidth = 0.5;
            if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
              ctx.strokeStyle = line.up ? "rgba(86,141,185,0.56)":"rgba(155,45,168,0.48)";
            } else {
              ctx.strokeStyle = line.up ? "rgba(86,141,185,0.56)":"rgba(155,45,168,0.48)";
            }
            //labelColor = ctx.strokeStyle;
          }else {
            ctx.lineWidth = 1;
            if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
              ctx.strokeStyle = line.up ? "#70c94c":"#f8436f";
            } else {
              ctx.strokeStyle = line.up ? "#70c94c":"#f8436f";
            }
          }
          /*
                      if (lineObj.source == "RISING_FALLING") {
                        if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
                          ctx.strokeStyle = "#6f6f6f";
                          } else {
                              ctx.strokeStyle = "#6f6f6f";
                          }
                      }
          */
          //          if (lineObj.copy == true) {
                ctx.setLineDash([5, 5]);
          //} else {
          //    ctx.setLineDash([]);
          // }
          this.collisions = [];

            const dt = new Date();
          // TODO try to set in on chart directly
            const timeOffset = 0;//(390 + dt.getTimezoneOffset()) * 60 * 1000; // FIX for real-time candle creating issue

            const pointDate = line.startDate - timeOffset;
            const otherDate = line.endDate - timeOffset;
            const radius = 8;
            let offset = line.up ? radius*3 : -radius*3;

            let x1 = layout.t2screen(pointDate);
            let y1 = layout.$2screen(line.startPrice);
            let x2 = layout.t2screen(otherDate);
            let y2 = layout.$2screen(line.endPrice);

            //console.log('t2screen',x1,x2,y1,y2);
            //new Seg(this, ctx).draw([pointDate, line.startPrice], [otherDate, line.endPrice]);
            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);

            ctx.stroke();

          ctx.beginPath();
          ctx.setLineDash([]);

          if (line.type=="MAIN") {
            ctx.font = "13px Arial";
            offset+= line.up ? radius*3 : -radius*3;
          } else if (line.type=="SUBMAIN") {
            ctx.font = "11px Arial"
            offset+= line.up ? radius*3 : -radius*3;
          } else  {
            ctx.font = `9px Arial`;
          }
          ctx.lineWidth = 1;
          ctx.fillStyle = fillColor;
          ctx.strokeStyle = labelColor;

          this.roundRect(ctx, x1-radius, y1+offset,  2.5*radius, 2*radius, 3, false, true);

          ctx.stroke();
          ctx.fillStyle = labelColor;
          ctx.fillText(num , x1-ctx.measureText(num).width/2+2, y1+offset+11)
          //ctx.fillText(num,x1-radius+(num<=9?6:2.5), y1+offset+11);

          //this.render_pins(ctx);

          const m = this.cursor;

           //;
          let isInInfo = (m.x>=x1-radius && m.y>=y1+offset && m.x<=x1-radius+2.5*radius && m.y<=y1+offset+2*radius );
          let isIn = debuggerMode || isInInfo ||
              Math2.point2seg(
              [m.x, m.y], [layout.t2screen(line.startDate),y1],[layout.t2screen(line.endDate),y2])< 5;
            if ((isIn || debuggerMode) && line.extensions && (line.extensions.length>0)) {
              ctx.setLineDash([]);
              ctx.lineWidth = 2;
              ctx.strokeStyle = labelColor;

              let findFirstIn = false;
              ctx.beginPath();
              line.extensions.forEach((ext, idx) => {
                if (this.canShow(ext.rule) && (line.endDate-line.startDate > 0) && (ext.when - line.startDate > 0)){
                  let  extX = layout.t2screen(ext.when);
                  const extXStart = layout.t2screen(line.startDate);
                  const extXEnd = layout.t2screen(line.endDate);
                  const price = line.startPrice+((100 * (extX - extXStart) / (extXEnd - extXStart)) * (line.endPrice-line.startPrice))/100;
                  let extY = layout.$2screen(price); //y1; //layout.$2screen(line.startPrice + ((line.endDate - ext.when) * (line.endDate - line.startDate) / 100 * (line.startPrice - line.endDate)));
                  //console.log('trend', (100 * (extX - extXStart) / (extXEnd - extXStart)), price);
                  ctx.moveTo(extX, extY -5);
                  ctx.lineTo(extX, extY + 5);
                  isIn = Math2.point2seg(
                      [m.x, m.y], [extX-5, extY-5],[extX+5, extY+5])< 5;

                  if (!findFirstIn && isIn) {
                    this.extHint = {idx: idx, extX: extX,extY: extY,lineIdx:num-1};
                    findFirstIn = true;
                    //ctx.lineTo(extX, extY + 50);
                  } else if (!findFirstIn && isInInfo) {
                    ctx.stroke();
                    //console.log(ext, this.mouse.t, this.mouse.x, this.mouse.y);
                    ctx.lineWidth = 1;
                    this.roundRect(ctx,extX+35,extY-10, 130, 20, 8, true, true, ext.rule, fillColor);

                    ctx.moveTo(extX, extY);
                    ctx.lineTo(extX+35,extY);
                    //ctx.lineTo(extX, extY + 50);
                  }

                }
              });
              ctx.stroke();
            }

/*
            let w = layout.width;
            let h = layout.height;
            // TODO: transform k (angle) to screen ratio
            // (this requires a new a2screen function)
            let k = (y2 - y1) / (x2 - x1);
            let s = Math.sign(x2 - x1 || y2 - y1);
            let dx = w * s * 2;
            let dy = w * k * s * 2;
            if (dy === Infinity) {
                (dx = 0), (dy = h * s);
            }

            ctx.moveTo(x2, y2);
            ctx.lineTo(x2 + dx, y2 + dy);
            // ctx.moveTo(x1, y1);
            // ctx.lineTo(x1 - dx, y1 - dy);
*/
            //ctx.stroke();
        },
      roundRect(
          ctx,
          x,
          y,
          width,
          height,
          radius = 5,
          fill = false,
          stroke = true,
          text = "",
          textcolor = "#dedede"
      ) {
        const r  = radius;
        if (typeof radius === 'number') {
          radius = {tl: radius, tr: radius, br: radius, bl: radius};
        } else {
          radius = {...{tl: 0, tr: 0, br: 0, bl: 0}, ...radius};
        }
        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        if (fill) {
          ctx.fill();
        }
        if (text) {
          const textWidth = ctx.measureText(text+"").width;
          const oldStyle = ctx.fillStyle;
          ctx.fillStyle = textcolor;
          ctx.font = "11px Arial"

          ctx.fillText(text, x+((width)/2)-(textWidth/2), y+14, r+width);
          ctx.fillStyle = oldStyle;
        }
        if (stroke) {
          ctx.stroke();
        }
      },
      /*roundRect(
          ctx,
          x,
          y,
          width,
          height,
          radius = 5,
          fill = false,
          stroke = true
      ) {
        if (typeof radius === 'number') {
          radius = {tl: radius, tr: radius, br: radius, bl: radius};
        } else {
          radius = {...{tl: 0, tr: 0, br: 0, bl: 0}, ...radius};
        }
        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        if (fill) {
          ctx.fill();
        }
        if (stroke) {
          ctx.stroke();
        }
      },*/
        use_for() {
            return ["AutoTrend"];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },

    },
    data() {
        return {
          setts: this.$parent.$parent.$parent.$parent.$parent.chartHandler.charts[''+this.$parent.$parent.$parent.$parent.$props.id].settings,
            COLORS: ["#42b28a", "#5691ce", "#612ff9", "#d50b90", "#ff2316"],
        };
    },
};
</script>
