<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";

export default {
    name: "VerticalLine",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "C451", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                name: "VerticalLine",
                group: "Lines",
                label: "Vertical Line",
                icon: Icons["vertical.png"],
                type: "Segment",
                hint: "This hint will be shown on hover",
                data: [], // Default data
                settings: {
                    lineWidth: 1,
                    color: "#00ff00",
                    lineType: "Full Line",
                }, // Default settings
                fieldsTemplate: [
                    ["color", "Color", "color"],
                    ["lineWidth", "Line Thickness", "lineWidth"],
                ],
            };
        },
        // Called after overlay mounted
        init() {
          this.updatePins();
        },
        updatePins() {
          const self = this;
          this.pins.splice(0,this.pins.length);
          if (this.$props.settings.p1) {
            this.$props.settings.$state = "finished";
            this.pins.push(
                new Pin(this, "p1", {
                  yCenter: true,
                  state: "finished",
                  t: this.$props.settings.p1[0],
                  y$: this.$props.settings.p1[1],
                })
            );
          } else {
            self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("VerticalLine", this.id, this.$props.settings);
            // First pin is settled at the mouse position
            this.pins.push(new Pin(this, "p1", { yCenter: true }));

            //global drawing mode
            //window.$store.drawingMode = false;
            self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("VerticalLine");
          }
        },
        draw(ctx) {
            if (!this.p1) return;

            if (this.show_pins)
              ctx.lineWidth = this.line_width+3;
            else
              ctx.lineWidth = this.line_width;
            ctx.strokeStyle = this.color;
            ctx.beginPath();
            ctx.setLineDash([0]);

            let layout = this.$props.layout;
            new Seg(this, ctx).draw([this.p1[0], layout.screen2$(0)], [this.p1[0], layout.screen2$(layout.height)]);

            ctx.stroke();
            this.render_pins(ctx);
        },
        use_for() {
            return ["VerticalLine"];
        },
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        p1() {
            return this.$props.settings.p1;
        },
        p2() {
            return this.$props.settings.p2;
        },
        line_width() {
            return this.sett.lineWidth || 1;
        },
        color() {
            return this.sett.color || "#0ff00";
        },
    },
    data() {
        return {};
    },
};
</script>
