<script>
import Overlay from "../lib/mixins/overlay";
//import Hint from "../../js/Hint.js";

export default {
    name: "AutoOverlay",
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: "akjcodes",
                version: "1.0",
                desc: "Auto Overlay",
            };
        },
        tool() {
            return {
                // Descriptor for the tool
                group: "Indicators",
                type: "Indicator",
                chartType: "onchart",
                name: "Auto Overlay",
                hint: "This hint will be shown on hover",
                settings: {
                    extreams: [],
                    "z-index": 1,
                    isHighLows: true,
                },
            };
        },

        draw(ctx) {
          if (!window.chartHandler.chartSettings.isHighLows) return;
            this.drawExtreams(ctx);
        },

        drawExtreams(ctx) {
            const self = this;

            const bitMask = window.chartHandler.chartSettings.bitMaskExtreams;

            //if (!this.$props.settings.isHighLows) return;
            //return false;
            const layout = this.$props.layout;
            ctx.lineWidth = 0.75;
            const isCanDrawingHint = false; //window.chartHandler.checkHintTimer(this.mouse.x,this.mouse.y,this.id);
            let barsCountToPrev = null;
            let barsCountToNext = null;
            let beforePercentage = null;
            let nextPercentage = null;
            let xDraw = null;
            let yDraw = null;
            let isFocusOne = false;
            const fills = ["#ff0000A0","#00ff00A0","#ff000070","#00ff0070","#ff000020","#00ff0020"];
            const strokes = ["#ff0000DD","#00ff00DD","#ff000050","#00ff0050","#ff000050","#00ff0050","#ffffff","#ffffff"];
            let drawIndex1 = null;
            this.focusIndex = -1;
            this.prevIndexStrong = -1;
            this.prevIndexNormal = -1;
            this.nextIndexStrong = -1;
            this.nextIndexNormal = -1;
            const gridwidth = layout.width;

            //console.log(layout);

            if (isCanDrawingHint) {
              //console.log('extreams',this.extreams)
              this.extreams.forEach((extream, i) => {
                if ((self.nextIndexNormal < 0) || (self.nextIndexStrong < 0)) {
                  const x = layout.t2screen(extream.when);
                  const x2 = layout.t2screen(extream.closeWhen);
                  if (((x >= 0) && (x < gridwidth)) || ((x2 >= 0) && (x2 < gridwidth))) {
                    const y = layout.$2screen(extream.where);
                    const y2 = layout.$2screen(extream.closePrice);
                    if (!isFocusOne) {
                      let radius = 4;
                      if ((extream.strength == 'STRONG') || (extream.strength == 'LEVEL_3')) {
                        radius = 6;
                      } else if ((extream.strength == 'LEVEL_4')) {
                        radius = 8;
                      } else if (extream.strength == 'UNKNOWN') {
                        radius = 3;
                      }
                      //if (x < -radius || x > layout.width + radius) return;

                      const radius_focus = Math.max(radius,6);
                      isFocusOne = (this.mouse.x >= x - radius_focus && this.mouse.x <= x + radius_focus && this.mouse.y >= y - radius_focus && this.mouse.y <= y + radius_focus) ||
                          (this.mouse.x >= x2 - radius_focus && this.mouse.x <= x2 + radius_focus && this.mouse.y >= y2 - radius_focus && this.mouse.y <= y2 + radius_focus);

                      if (!isFocusOne) {
                        if ((extream.strength == 'STRONG') || (extream.strength == 'LEVEL_4') || (extream.strength == 'LEVEL_3')) {
                          self.prevIndexStrong = i;
                        } else {
                          self.prevIndexNormal = i;
                        }
                      } else {
                        self.focusIndex = i;
                      }
                    } else {
                      if ((extream.strength == 'STRONG') || (extream.strength == 'LEVEL_4') || (extream.strength == 'LEVEL_3')) {
                        self.nextIndexStrong = self.nextIndexStrong >= 0 ? self.nextIndexStrong : i;
                      } else {
                        self.nextIndexNormal = self.nextIndexNormal >= 0 ? self.nextIndexNormal : i;
                      }
                    }
                  }
                }
              });
            }
            this.extreams.forEach((extream, i) => {
              const currentTime = extream.when;
              const x = layout.t2screen(extream.when);
              const x2 = layout.t2screen(extream.closeWhen);

              // exit if  extream is outside the canvas
              if (((x >= 0) && (x < gridwidth)) || ((x2 >= 0) && (x2 < gridwidth))) {
                let radius = 4;
                if ((extream.strength == 'STRONG') || (extream.strength == 'LEVEL_3')) {
                  radius = 6;
                } else if ((extream.strength == 'LEVEL_4')) {
                  radius = 8;
                } else if (extream.strength == 'UNKNOWN') {
                  radius = 3;
                }

                if (x < -radius || x > layout.width + radius) return;

                if ((((bitMask &  1) === 1) && (extream.strength == 'LEVEL_0')) ||
                    (((bitMask &  2) === 2) && (extream.strength == 'LEVEL_1')) ||
                    (((bitMask &  4) === 4) && (extream.strength == 'LEVEL_2')) ||
                    (((bitMask &  8) === 8) && (extream.strength == 'LEVEL_3')) ||
                    (((bitMask &  16) === 16) && (extream.strength == 'LEVEL_4')) ||
                    (((bitMask & 32) === 32) && (extream.strength == 'UNKNOWN'))) {

                  const y = layout.$2screen(extream.where);
                  const y2 = layout.$2screen(extream.closePrice);

                  const isDrawSelected = (self.focusIndex == i) || (self.nextIndexStrong == i) || (self.nextIndexNormal == i) || (self.prevIndexStrong == i) || (self.prevIndexNormal == i);

                  if ((extream.what == "LOW") || (extream.what == "LEVEL_0") || (extream.what == "LEVEL_1")) {
                    drawIndex1 = 0;
                  } else {
                    drawIndex1 = 1;
                  }

                  if (isFocusOne && !isDrawSelected) {
                    drawIndex1 += 4;
                  } else if ((!isFocusOne) && (extream.strength != 'STRONG')) {
                    drawIndex1 += 2;
                  }

                  ctx.strokeStyle = strokes[drawIndex1];
                  ctx.fillStyle = fills[drawIndex1];

                  ctx.beginPath();
                  ctx.arc(x, y, radius - 1, 0, Math.PI * 2, true);
                  ctx.fill();
                  ctx.stroke();

                  // show info tooltip for 1st extream
                  if (isCanDrawingHint && (self.focusIndex == i)) {
                    xDraw = x;
                    yDraw = y;
                    [barsCountToPrev, barsCountToNext, beforePercentage, nextPercentage] = this.getInfoData(extream, currentTime, i);
                  }

                  // 2nd extream
                  //const x2 = layout.t2screen(extream.closeWhen);
                  //const y2 = layout.$2screen(extream.closePrice);

                  // exit if the 2nd extream is outside the canvas
                  if (x2 < -radius || x2 > layout.width + radius) return;
                  // layout.candles is undefines
                  if (!layout.candles[0]) return;
                  // exist if it's on same candle
                  if (x2 >= x - layout.candles[0].w / 2 && x2 <= x + layout.candles[0].w / 2) return;

                  //ctx.strokeStyle = strokes[drawIndex1];
                  //ctx.fillStyle = fills[drawIndex1];
                  ctx.beginPath();
                  ctx.arc(x2, y2, radius - 1, 0, Math.PI * 2, true);
                  ctx.fill();
                  ctx.stroke();

                  /*
                      // show 2nd info
                      if (isCanDrawingHint && isFocusSecond) {
                          [barsCountToPrev, barsCountToNext, beforePercentage, nextPercentage] = this.getInfoData(extream, currentTime, i);
                          xDraw = x2;
                          yDraw = y2;
                      }*/
                }
              }
            });
            if (barsCountToPrev){
              const aol = window.chartHandler.charts["0"].hintData.autoOverlay;
              aol.debug = "";
              aol.debugvalues = "";
              if (window.chartHandler.isAdmin) {
                //aol.debug = "<pre>";
                Object.keys(this.extreams[this.focusIndex]).forEach((value, index) => {
                  if ((value == "when") || (value == "closeWhen")) {
                    let d = new Date(this.extreams[this.focusIndex][value])
                    if (d instanceof Date) {
                      aol.debug += value+" = "+d.toString()+ "\n";
                    } else
                      aol.debug += value+" = "+this.extreams[this.focusIndex][value]+ "\n";
                  } else
                    aol.debug += value+" = "+this.extreams[this.focusIndex][value]+ "\n";
                  //aol.debugvalues += " * "+value+" = "+this.extreams[this.focusIndex][value]+ "\n";
                })
                //aol.debug += "</pre>";
              }
              aol.first = this.extreams[this.focusIndex].origin+ " ("+this.extreams[this.focusIndex].strength+")";
              aol.strength = Math.max(Math.min(parseFloat(this.extreams[this.focusIndex].strength),10),0) || (5);
              aol.second = this.extreams[this.focusIndex].where;
              if (this.prevIndexNormal>=0) {
                aol.normal.prev.isLow = this.extreams[this.prevIndexNormal].what == 'LOW';
                aol.normal.prev.abs = (this.extreams[this.prevIndexNormal].where - this.extreams[this.focusIndex].where).toFixed(2);
                aol.normal.prev.percent = ((this.extreams[this.prevIndexNormal].where / this.extreams[this.focusIndex].where - 1) * 100).toFixed(2);
              }
              aol.normal.prev.bars = this.getBars(this.prevIndexNormal,this.focusIndex);

              if (this.nextIndexNormal>=0) {
                aol.normal.next.isLow = this.extreams[this.nextIndexNormal].what == 'LOW';
                aol.normal.next.abs = (this.extreams[this.nextIndexNormal].where - this.extreams[this.focusIndex].where).toFixed(2);
                aol.normal.next.percent = ((this.extreams[this.nextIndexNormal].where / this.extreams[this.focusIndex].where - 1) * 100).toFixed(2);
              }
              aol.normal.next.bars = this.getBars(this.nextIndexNormal,this.focusIndex);

              if (this.prevIndexStrong>=0) {
                aol.strong.prev.isLow = this.extreams[this.prevIndexStrong].what == 'LOW';
                aol.strong.prev.abs = (this.extreams[this.prevIndexStrong].where - this.extreams[this.focusIndex].where).toFixed(2);
                aol.strong.prev.percent = ((this.extreams[this.prevIndexStrong].where / this.extreams[this.focusIndex].where - 1) * 100).toFixed(2);
              }
              aol.strong.prev.bars = this.getBars(this.prevIndexStrong,this.focusIndex);

              if (this.nextIndexStrong>=0) {
                aol.strong.next.isLow = this.extreams[this.nextIndexStrong].what == 'LOW';
                aol.strong.next.abs = (this.extreams[this.nextIndexStrong].where - this.extreams[this.focusIndex].where).toFixed(2);
                aol.strong.next.percent = ((this.extreams[this.nextIndexStrong].where / this.extreams[this.focusIndex].where - 1) * 100).toFixed(2);
              }
              aol.strong.next.bars = this.getBars(this.nextIndexStrong,this.focusIndex);

              window.chartHandler.setHintInfo(true);
              this.drawInfoTooltip(ctx, xDraw, yDraw, xDraw, yDraw, xDraw, yDraw, barsCountToPrev, barsCountToNext, beforePercentage, nextPercentage);
            } else
              window.chartHandler.setHintInfo(false);

        },

        getInfoData(extream, currentTime, i) {
            let barsCountToPrev,
                barsCountToNext,
                beforePercentage,
                nextPercentage = undefined;

            const currentIndex = window.chartHandler.chartObjects["0"].$refs.tvjs.$refs.chart.ti_map.t2i(currentTime);
            const currentPrice = extream.where;

            if (this.extreams[i - 1] != undefined) {
                const prevTime = this.extreams[i - 1].when;
                const prevIndex = window.chartHandler.chartObjects["0"].$refs.tvjs.$refs.chart.ti_map.t2i(prevTime);
                const prevPrice = this.extreams[i - 1].where;

                barsCountToPrev = currentIndex - prevIndex;
                beforePercentage = ((currentPrice / prevPrice - 1) * 100).toFixed(2);
            }

            if (this.extreams[i + 1] != undefined) {
                const nextTime = this.extreams[i + 1].when;
                const nextIndex = window.chartHandler.chartObjects["0"].$refs.tvjs.$refs.chart.ti_map.t2i(nextTime);
                const nextPrice = this.extreams[i + 1].where;

                barsCountToNext = nextIndex - currentIndex;
                nextPercentage = ((nextPrice / currentPrice - 1) * 100).toFixed(2);
            }

            return [barsCountToPrev, barsCountToNext, beforePercentage, nextPercentage];
        },

        use_for() {
            return ["AutoOverlay"];
        },

        mousemove(event) {
            this.mouse.x = event.layerX;
            this.mouse.y = event.layerY;
        },

        getBars(index1, index2) {
          let idx1 = -1;
          let idx2 = -1;
          if (this.extreams[index1] != undefined) {
            const nextTime = this.extreams[index1].when;
            idx1 = window.chartHandler.chartObjects["0"].$refs.tvjs.$refs.chart.ti_map.t2i(nextTime);
          }
          if (this.extreams[index2] != undefined) {
            const nextTime = this.extreams[index2].when;
            idx2 = window.chartHandler.chartObjects["0"].$refs.tvjs.$refs.chart.ti_map.t2i(nextTime);
          }
          if ((idx1>=0) && (idx2>=0))
            return Math.abs(idx1-idx2);
          else
            return "-";
        },

        drawInfoTooltip(ctx, x, y, px1, py1, px2, py2, barsCountToPrev, barsCountToNext, beforePercentage, nextPercentage) {
            let text = "extrema points";
            let hintHeight = 110;

            /*if (barsCountToPrev != undefined) text += `${barsCountToPrev} bars to previous point`;
            if (barsCountToNext != undefined) text += `\n${barsCountToNext} bars to next point`;

            if (beforePercentage != undefined) text += `\nBefore %: ${beforePercentage}%`;
            if (nextPercentage != undefined) text += `\nNext %: ${nextPercentage}%`;

            if (nextPercentage == undefined) {
                hintHeight = 40;
            }*/
/*
          {
            "when": 1637662500000,
              "what": "HIGH",
              "where": 4305,
              "closeWhen": 1637663400000,
              "closePrice": 4211.4,
              "origin": "RULE_5"
          }*/
          const digist = 3;
          const digistProz = 2;
          const width = 450;
          const height = hintHeight+40;
          const lineHeight = 20;
          const lineStart = 65;

          ctx.font = this.new_fontDisplay;
          const layout = this.$props.layout;
          let x1 = Math.min(this.mouse.x, layout.width-width);// layout.t2screen(this.p1[0]);
          let y1 = Math.min(this.mouse.y, layout.height-height); //layout.$2screen(this.p1[1]);
          //const row1 = x1 + width-280;
          const row1 = x1 + width-185;
          const row2 = x1 + width-10;
          const font = ctx.font;

          ctx.fillStyle = '#98acc4';
          ctx.fillRect(x1,y1+40,width,height-40)
          ctx.fillStyle = '#082e4b';
          ctx.fillRect(x1,y1,width,43);
          ctx.strokeStyle = '#082e4b60';
          ctx.moveTo(row2-150,y1 + lineStart + (lineHeight*0) -2);
          ctx.lineTo(row2,y1 + lineStart + (lineHeight*0) -2);
          ctx.moveTo(row1-150,y1 + lineStart + (lineHeight*0) -1);
          ctx.lineTo(row1,y1 + lineStart + (lineHeight*0) -1);
          ctx.stroke();
          ctx.strokeStyle = 'rgb(94,94,94)';
          ctx.textAlign = "center";
          ctx.beginPath();
          ctx.rect(x1,y1,width,height);
          ctx.stroke();
          ctx.fillStyle = '#d0d0d0';
          ctx.font='bold 14px '+font.slice(4);
          ctx.fillText(text+"123", x1 + width/2, y1+28);
          ctx.textAlign = "right";
          ctx.font='bold 11px '+font.slice(4);
          ctx.fillText(this.extreams[this.focusIndex].origin + "["+this.extreams[this.focusIndex].strength+"]", row2, y1+17);
          ctx.fillText(this.extreams[this.focusIndex].where, row2, y1+35);
          ctx.font='bold 12px '+font.slice(4);
          ctx.textAlign = "left";
          ctx.fillStyle = '#021632';
          ctx.fillText("Difference Bars: ", x1 + 10, y1 + lineStart + (lineHeight*1.5)+3);
          ctx.fillText("Difference: ", x1 + 10, y1 + lineStart + (lineHeight*2.5)+3);
          ctx.fillText("Difference %: ", x1 + 10, y1 + lineStart + (lineHeight*3.5)+3);
          ctx.fillStyle = 'rgba(27,31,44,0.91)';
          ctx.textAlign = "center";
          ctx.fillText("prev", row1-110, y1 + lineStart + (lineHeight*0.7) -3);
          ctx.fillText("normal", row1-75, y1 + lineStart + (lineHeight*-0.2) -3);
          ctx.fillText("next", row1-40, y1 + lineStart + (lineHeight*0.7) -3);
          ctx.fillText("prev", row2-110, y1 + lineStart + (lineHeight*0.7) -3);
          ctx.fillText("strong", row2-75, y1 + lineStart + (lineHeight*-0.2) -3);
          ctx.fillText("next", row2-40, y1 + lineStart + (lineHeight*0.7) -3);
          //ctx.textAlign = "right";
          ctx.font='bold 12px '+font.slice(4);

          if (this.prevIndexNormal>=0) {
            ctx.fillStyle = this.extreams[this.prevIndexNormal].what == 'LOW' ? '#8d0000' : '#0c5600';
            ctx.fillText((this.extreams[this.prevIndexNormal].where - this.extreams[this.focusIndex].where).toFixed(2), row1-110, y1 + lineStart + (lineHeight*2.5)+3);
            ctx.fillText(((this.extreams[this.prevIndexNormal].where / this.extreams[this.focusIndex].where - 1) * 100).toFixed(2)+" %", row1-110, y1 + lineStart + (lineHeight*3.5)+3);
          } else
            ctx.fillStyle = 'rgba(27,31,44,0.91)';
          ctx.fillText(this.getBars(this.prevIndexNormal,this.focusIndex), row1-110, y1 + lineStart + (lineHeight*1.5)+3);

          if (this.nextIndexNormal>=0) {
            ctx.fillStyle = this.extreams[this.nextIndexNormal].what == 'LOW' ? '#8d0000' : '#0c5600';
            ctx.fillText((this.extreams[this.nextIndexNormal].where - this.extreams[this.focusIndex].where).toFixed(2), row1-40, y1 + lineStart + (lineHeight*2.5)+3);
            ctx.fillText(((this.extreams[this.nextIndexNormal].where / this.extreams[this.focusIndex].where - 1) * 100).toFixed(2)+" %", row1 - 40, y1 + lineStart + (lineHeight * 3.5) + 3);
          } else
            ctx.fillStyle = 'rgba(27,31,44,0.91)';
          ctx.fillText(this.getBars(this.nextIndexNormal,this.focusIndex), row1-40, y1 + lineStart + (lineHeight*1.5)+3);

          if (this.prevIndexStrong>=0) {
            ctx.fillStyle = this.extreams[this.prevIndexStrong].what == 'LOW' ? '#8d0000' : '#0c5600';
            ctx.fillText((this.extreams[this.prevIndexStrong].where - this.extreams[this.focusIndex].where).toFixed(2), row2-110, y1 + lineStart + (lineHeight*2.5)+3);
            ctx.fillText(((this.extreams[this.prevIndexStrong].where / this.extreams[this.focusIndex].where - 1) * 100).toFixed(2)+" %", row2-110, y1 + lineStart + (lineHeight*3.5)+3);
          } else
            ctx.fillStyle = 'rgba(27,31,44,0.91)';
          ctx.fillText(this.getBars(this.prevIndexStrong,this.focusIndex), row2-110, y1 + lineStart + (lineHeight*1.5)+3);

          if (this.nextIndexStrong>=0) {
            ctx.fillStyle = this.extreams[this.nextIndexStrong].what == 'LOW' ? '#8d0000' : '#0c5600';
            ctx.fillText((this.extreams[this.nextIndexStrong].where - this.extreams[this.focusIndex].where).toFixed(2), row2-40, y1 + lineStart + (lineHeight*2.5)+3);
            ctx.fillText(((this.extreams[this.nextIndexStrong].where / this.extreams[this.focusIndex].where - 1) * 100).toFixed(2)+" %", row2 - 40, y1 + lineStart + (lineHeight * 3.5) + 3);
          } else
            ctx.fillStyle = 'rgba(27,31,44,0.91)';
          ctx.fillText(this.getBars(this.nextIndexStrong,this.focusIndex), row2-40, y1 + lineStart + (lineHeight*1.5)+3);

          ctx.font=font;
          /*this.hint = new Hint(this, {
              x: x,
              y: y,
              w: 150,
              h: hintHeight,
              text: text,
          });
          this.hint.draw(ctx);*/
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        extreams() {
            return this.setts.extreams || [];
        },
        new_font() {
            return "14px " + this.$props.font.split("px").pop();
        },
    },
    data() {
        return {
            setts: this.$parent.$parent.$parent.$parent.$parent.chartHandler.charts[''+this.$parent.$parent.$parent.$parent.$props.id].settings,
            COLORS: ["#42b28a", "#5691ce", "#612ff9", "#d50b90", "#ff2316"],
        };
    },
};
</script>
