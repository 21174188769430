<template>
    <!-- Timeframe Selector -->
  <div style="padding:2px;">
    <div class="vue-draggable-handle">

      <p v-if="trendvisible"  style="text-align:center;color: rgba(255,255,255,0.8);background-color: rgba(37,85,121,0.83);font-size: medium; margin-bottom: .2em" >

        <i class="fa fa-info-circle hover" style="float: left; color:rgba(255,255,255,0.8);margin-top:.2em;margin-left:.5em;cursor:default">
          <div class="tooltip" ><div v-html="item.svg"></div><div class="rotate"></div></div>
        </i>

      {{item.marketName}} <sup style="font-size: x-small">{{item.details.length}}</sup>

      <i title="eigene Sortierung bearbeiten" v-on:click="delMarketListLayout(item.marketListId)" class="fa fa-close" style="float: right; color:rgba(255,255,255,0.8);margin-top:.3em;margin-right:.3em;cursor:default"></i>
      <i v-if="draggable || (editTableId != item.marketListId)" title="eigene Sortierung bearbeiten" v-on:click="draggable=!draggable; editTableId = item.marketListId;" class="fa fa-edit" style="float: right; color:rgba(255,255,255,0.8);margin-top:.3em;margin-right:.5em;cursor:default"></i>
      <i v-if="(!draggable)  && (editTableId == item.marketListId)" title="eigene Sortierung rückgängig machen" v-on:click="resetMarketListDetailsSort(item.marketListId);" class="fa fa-undo" style="float: right; color:rgba(255,255,255,0.8);margin-top:.3em;margin-right:.3em;cursor:default"></i>
      <i v-if="draggable || (editTableId != item.marketListId)" title="eigene Sortierung speichern" class="fa fa-save" style="float: right; color:#797979;margin-right:0.5em;margin-top:.3em;cursor:default"></i>
      <i v-if="(!draggable)  && (editTableId == item.marketListId)" title="eigene Sortierung speichern" v-on:click="updMarketListDetails(item.marketListId);" class="fa fa-save" style="float: right; color:rgba(255,255,255,0.8);margin-right:0.5em;margin-top:.3em;cursor:default"></i>

      <i style="float: right; color:#ff3a3a;margin-top:.3em;font-size:small;margin-right:1.5em;cursor:default">{{ item.countDown }}</i>
      <i class="fa fa-arrow-down" style="float: right; color:#ff3a3a;margin-top:.3em;margin-right:0.2em;cursor:default"></i>
      <i style="float: right; color:#40b60d;margin-top:.3em;font-size:small;margin-right:1em;cursor:default">{{ item.countUp }}</i>
      <i class="fa fa-arrow-up" style="float: right; color:#40b60d;margin-top:.25em;margin-right:0.2em;cursor:default"></i>

      </p>
    </div>
    <div class="tableWrap" :style="{height: 'calc('+(windowheight*5)+'px - 55px )'}">
      <table :style="{width: 'calc(100% - 0.5em)', 'margin-left':'0.25em' }">
        <thead class="thead_mo":style="{'background-color': 'rgb(54,68,79)', color: 'rgba(255,255,255,0.8)', padding: '0.3em',height:'99%'}">
        <tr>
          <th class="th_mu" v-on:click="changeDirection('colIndex', item.marketListId)" style="text-align: center;width:5%">#</th>
          <th class="th_mu" v-on:click="changeDirection('symbol', item.marketListId)" style="width:35%">Symbol</th>
          <th v-if="trendvisible" class="th_mu"  style="text-align: center; width:1%;padding-right: 0.3em">Tendenz</th>
          <th class="th_mu" v-on:click="changeDirection('price', item.marketListId)" style="text-align: right; width:23%;padding-right: 0.3em">Price</th>
          <th class="th_mu" style="text-align: right; width:0%;padding-right: 0.05em"></th>
          <th class="th_mu" v-on:click="changeDirection('percent', item.marketListId)" style="text-align: right; width:13%;padding-right: 0.3em">%</th>
        </tr>
        </thead>
        <draggable v-if="item" v-model="item.details" tag="tbody" :id=item.marketName :style="{'overflow':'scroll', }"
                   @start="isDragging=true"
                   @end="handleDraggingEnd(item.marketListId)" >
          <tr v-for="(wld,idx) in item.details" v-on:click="changeSymbol(wld.symbol)" :key=wld.symbol :id=wld.symbol class="tr_mu" :style="{ padding: '0.5em',cursor: 'pointer'}">
            <td style="text-align: center;" >{{wld.colIndex}}</td>
            <td >{{wld.symbol}}</td>
            <td v-if="trendvisible" style="display: flex;align-content: center">
              <div class="circleInfo circleInfoDown"></div>
              <div class="circleInfo"></div>
              <div class="circleInfo"></div>
              <div class="circleInfo circleInfoDown"></div>
              <Transition name="slidefade">
                <div v-if="wld.new" class="circleInfo" :class="{circleInfoDown: true}"></div>
                <div v-if="!wld.new" class="circleInfo" ></div>
              </Transition>
            </td>
            <td :style="{'text-align': 'right'}">{{wld.price}}</td>

            <td class="percentLabel" v-if="wld.newUp" :class="{percentNewUp: wld.new> Date.now()}"></td>
            <td class="percentLabel" v-if="!wld.newUp" :class="{percentNew: wld.new> Date.now()}"></td>
            <td class="percentLabel" :class="{percentDown: wld.percent<-0.000001}" :style="{'text-align': 'right'}">{{wld.percent.toFixed(2)}}</td>
          </tr>
        </draggable>
      </table>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {GridItem, GridLayout} from "vue-grid-layout";

export default {
    name: "MarketOverviewItem",
    components: {draggable
    },
    props: ["item","windowheight","draggable","trendvisible"],
    mounted() {

    },
    computed: {
    },
    methods: {
      changeDirection(symbol) {
        this.$emit("changeDirection",  symbol );
      },
      changeSymbol(symbol) {
        this.$emit("changeSymbol",  symbol );
      },
    },
    data() {
        return {
          editTableId: -1,
        };
    },
    watch: {

    },
};
</script>

<style>
.tableWrap {
  border_: 1px solid #dc570e;
  align-content: center;
  overflow: auto;
  cursor: default;
}
thead tr th {
  position: sticky;
  border_: 1px solid #dc570e;
  top: 0;
}

table {
  border-collapse: collapse;
}

.th_mu {
  position: sticky;
  top: 0;
  padding_: 8px;
  padding-left_: 15px;
  border-left_: 1px solid rgba(200, 209, 224, 0.18);
  border-bottom_: 1px solid rgba(232, 232, 232, 0.99);
  z-index_: 3;
  background: rgb(49, 58, 65);
  text-align: left;
  box-shadow_: 0px 0px 0 2px #e8e8e8;
}

table {
  width: 100%;
  font-family_: sans-serif;
}
table td {
  padding: 0.15em;
}
tbody tr {
  border-bottom: 0.5px solid rgba(232, 232, 232, 0.24);
}
.thead_mo {
  color: rgba(201, 22, 22, 0.1);
}
tbody tr:hover {
  color: rgb(201, 85, 14);
  background: rgba(45, 58, 61, 0.25);
}

.layout_mu .vue-grid-layout {
  background_: rgba(238, 238, 238, 0.99);
}
.layout_mu .vue-grid-item:not(.vue-grid-placeholder) {
  background: rgba(37, 85, 121, 0.83);
  color: rgb(232, 232, 232);
  cursor: grab;
  border: 1px solid rgba(255, 255, 255, 0.38);
}

.layout_mu .vue-grid-item .resizing {
  opacity: 0.9;
}
.layout_mu .vue-grid-item .static {
  background: rgba(204, 204, 238, 0.07);
}
.layout_mu .vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}
.layout_mu .vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.layout_mu .vue-grid-item .minMax {
  font-size: 12px;
}
.layout_mu .vue-grid-item .add {
  cursor: pointer;
}
.layout_mu .vue-draggable-handle {
  cursor: grab;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 10px;
  background-color: #1223da;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -50px;
}

.tooltip::after {
  opacity: 1.0;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


#selAddMarketListId {
  width : 20em;
  margin-right: .5em;
  height: 1em;
  font-size: small;
}

.hover {
}

.tooltip {
  position:absolute;
  top: -100000px;
  background-color: black;
  color: white;
  border-radius: 5px;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}


.hover:hover .tooltip{
  z-index: 100000;
  transform: translate3d(-20px,-16px,0px);
  top: 20px;
  opacity: 1;
}

.hover:hover .sub-text{
  top: 20px;
  opacity: 1;
}

.st1 {
  fill: #0491B7;
}
.st2 {
  fill: #049137;
}
.st1:hover {
  fill: #ffffff;
  opacity: 0.9;
}
.st2:hover {
  fill: red;
}

stext_{
  display:none;
  fill:#fff;
  font-size:2em;
  font-family:sans-serif;
}

text{
  display:block;
  fill:#fff;
  font-size:2em;
  font-family:sans-serif;
}

.sub-text{
  display:none;
  fill:#fff;
  font-size:2em;
  font-family:sans-serif;
}

.rotate {

  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter_: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);


}

.tr_mu:nth-child(odd) {
  background: rgba(0,0,0,0.1)
}
.tr_mu:nth-child(even) {
  _background: rgba(27,31,44,0.91)
}

g:hover > text{
  display:block;
}

.percentLabel {
  color: #40b60d;
}

.percentDown {
  color: #b00b0b;
  background-color: #00000000;
  transition: background-color 1s;
}

.percentDown.fade {
  background-color: #b00b0b;
}

.circleInfo {
  background-color: #40b60d;
  width: 0.6em;
  height: 0.6em;
  margin: 0.2em;
  margin-top: 0.4em;
  border-radius: .3em;
}

.circleInfoDown {
  background-color: #b00b0b;
}


.percentNew_ {
  background-color: rgba(44, 105, 24, 1.0);
  transition: all .5s linear 0.2s;
}

.percentNewDown_ {
  background-color: rgba(201, 10, 54, 1.0);
  transition: all .5s linear 0.2s;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(0px);
  opacity: 0;
}

.percentNew {
  background-color: rgba(201, 10, 54, 0.0);
  animation: pricefading 2s infinite ease-in-out;
}

@keyframes pricefading {
  0%, 100% {
    background-color: rgba(201, 10, 54, 0.0); }
  50%,70% {
    background-color: rgba(201, 10, 54, 1.0);}
}

.percentNewUp {
  background-color: rgba(201, 10, 54, 0.0);
  animation: pricefadingUp 2s infinite ease-in-out;
}

@keyframes pricefadingUp {
  0%, 100% {
    background-color: rgba(201, 10, 54, 0.0); }
  50%,70% {
    background-color: rgb(80, 201, 10);}
}

</style>
