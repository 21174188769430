<script>
// Line drawing tool

import Overlay from "../../lib/mixins/overlay.js";
import Tool from "../../lib/mixins/tool.js";

export default {
  name: 'ftWilliamsR',
  mixins: [Overlay, Tool],
  methods: {
    meta_info() {
      return {
        author: 'Sven Schwind',
        version: '1.0.0',
        desc: 'Williams %R',
      }
    },
    use_for() {
      return ["ftWilliamsR"];
    },
    calc() {
      return {
        props: {
          length: {
            def: 14,
            text: 'Length'
          },
          upper: {
            def: -20,
            text: 'Upper'
          },
          lower: {
            def: -80,
            text: 'lower'
          },
        },
        conf: {
          renderer: 'Range'
        },
        update: " return wpr(length)[0]; \n                "
      };
    }
  }
};
</script>
