<template>
  <div>
    <div v-for="tool,i in getIndicatorOptions()" :key="tool.id" class="accordion-item">
      <div class="accordion-header watchlist_header" v-on:click="toggleShowConfig($event, i)" v-if="!tool.deleted">
        {{tool.name}} {{tool.showCfg}}
        <span v-on:click="delIndicator($event, i)"><img style="float: right; width: 20px; margin-top: 11px" class="icon icon-delete" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAQMAAAD+JxcgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAATU1NkJ+rOQAAAAJ0Uk5TAP9bkSK1AAAALUlEQVR4nGNgAIN6ENHQACX4//9gYBBgYIESYC4LkA0lPEkmGFAI5v8PILYCAHygDJxlK0RUAAAAAElFTkSuQmCC"></span>
        <span v-on:click="toggleVisible($event, i)"><img id="toggleVisible" style="float: right;width: 24px;margin-top: 10px;margin-right: 4px;" class="icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAR1QTFRFAAAAh4eHgYGBAAAAAAAAgYGBAAAAAwMDAAAAAAAAgYGBg4ODGBgYgYGBhISEAAAAPz8/AgIChoaGCQkJhYWFPj4+NjY2goKCgYGBAQEBJycngYGBgoKCEBAQCAgIhISEKioqZGRkCgoKBQUFERERd3d3gYGBg4ODgYGBGxsbNDQ0hISEgoKCgoKChYWFPDw8gYGBgYGBhoaGgoKCg4ODgoKCgYGBgoKCgoKCgoKCg4ODgoKChoaGgoKCgYGBhoaGg4ODYWFhBgYGdXV1gYGBg4ODgoKCgICAg4ODg4ODhISEAAAAg4ODOjo6gYGBGhoaeXl5goKCgYGBgoKChYWFgoKChISEgoKCY2NjgYGBg4ODgYGBgYGBg4ODgYGBo8n54AAAAF90Uk5TACn/AhH3BSgPEuhUJvFACigoLBM2KCeA6ykm+pMgIEkmKSEoICn9XCkmJ0u6nDop4sUypGuEzLZ6vmCYLZ/dLykpJynUYa8pcllCC1Ip2ycpisl1PadFsintbsPQZdi/bTW7AAAB4UlEQVR4nOWUZ1fCMBSGSSGWFiq0UDbIkr2XbBwMxS0b1P//M0xK9XSiftX7oel585zkvfcmMRj+SRhvzRRlthm/BU3Ry3TYzofTsajpIOjw2iNAjIiddehvHXSdA0mkXEEdG0fkE1DEKXmkSVqVIA6rBmsktUgAWLWHoGp30UNclbtLmwQgoyya91wPTbFy0mQXJ5zJQO6BgXRjfH0iSkX5stHIXr5r0bB/lu8syjR8rzsFbR2SpX+5J2eMP3csLtYsEY2K8BeTFuE2jaVCBw7bHOBuxq16AXmpbui3LtIfbRLUHMY2q4lcFo2WB4KA1SUAlWumNEKCzyxBKZxVHvYGaFguCBx1vM/x0IPzoqQoj5SdP4mns2cCGhBsrgj0uaeUBtzMyxQN8w4mYROTW8+r0oANp8W5mf6WQw5aCYJ2o7ymPaKMi2uVpmWM4TW6tdImgGo1bT4nK6DbbsCc0AZSdmLEFszzHrh6riVvRrNA3/9SE8QLWQu+Gjto9+gE9NBMwr9zi83gFeeFTe11zpm1CHE3HeyVCSknf3MIDcFTbfJKdbR1L4xX49L+/BoillV5uPJqkshD3JWSgpNMXP/lcrD8+hO84MnDr5YpFHv0Fe99VjJ0GBRs2H74aP6R+ACr+TFvZNAQ1wAAAABJRU5ErkJggg=="></span>
      </div>
      <div class="accordion-body"> <!--v-if="tool.showInCfg"-->
        <IndicatorConfigItem :indicator-config-items="tool.fieldsTemplate" :settings="tool.settings" :toolObject=tool :keyName="tool.id"></IndicatorConfigItem>
      </div>
    </div>
  </div>
</template>

<script>

import IndicatorConfigItem from "./IndicatorConfigItem.vue";
import Vue from 'vue';
export default {
  name: "IndicatorConfig",
  props: ["indicatorConfig"],
  components: {IndicatorConfigItem },

  methods: {
    // toggle tool/indicator visible status
    toggleVisible(event, i) {
      window.chartHandler.toggleVisible(this.$props.indicatorConfig[i].id,event);
    },
    toggleShowConfig(event, i) {
      if (this.$props.indicatorConfig[i])
        window.chartHandler.toggleShowConfig(this.$props.indicatorConfig[i].name);
    },
    delIndicator(event, i) {
      window.chartHandler.delIndicator(this.$props.indicatorConfig[i].id, event);
    },
    isVisible(i) {
      return window.chartHandler.getShowCfg(i);
    },
    getIndicatorOptions() {
      return this.$props.indicatorConfig;
    }
  },

  computed: {
  },

  data() {
    return {

    };
  },
};
</script>

<style scoped>
</style>