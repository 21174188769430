<template>
  <div id="hint-keys-table-auto-overlay" ><tbody v-if="isAdmin" >
  <tr >
    <td style="min-width:5px" ></td>
    <td rowspan="3" class="HintValues"  style="width:450px;height:120px;">
      <span
          v-for="(line,lineNumber) of hintData.autoOverlay.debug.split('\n')"
          v-bind:key="lineNumber" >
  {{ line }}<br/>
</span><!--span style="white-space: pre;"><pre>{{{  hintData.autoOverlay.debug}}}</pre></span-->
    </td>

  </tr>
  </tbody>
    <tbody >
    <tr >
      <td style="min-width:5px" ></td>
      <td rowspan="2" class="HintCaption"  style="min-width:280px;" > {{  hintData.autoOverlay.caption}}</td>
      <td v-if="isAdmin" class="HintValues" style="min-width:148px;min-height:30px">{{hintData.autoOverlay.first}}</td>
      <td v-if="!isAdmin" class="HintValues" style="border: thick solid #e1e1e1; padding :0.05em;background:linear-gradient(to left,#fc2727,yellow, #ffffff);min-width:148px;min-height:0.3em;max-height:0.3em;height:0.3em">
        <i class="fa fa-caret-up"  :style=" {'font-size':'1.2em','font-color': 'rgba(27,31,44,0.91)','transform':'translate('+getSize(12)+'px,10px)'}"></i>
        <i class="fa fa-caret-down"  :style="{'font-size':'1.2em','font-color': 'rgba(27,31,44,0.91)','transform':'translate('+getSize(0)+'px,-6px)'}"></i>
      </td>
    </tr>
    <tr>  <td></td>                              <td class="HintValues" >{{hintData.autoOverlay.second}}</td></tr>
    </tbody>

    <div style="padding-top: 10px">
      <table style="padding-top: 5px">
        <tr>
          <th style="min-width:126px" ></th>
          <th class="AOHead"><span style="color: #e1e1e1">normal</span></th>
          <th class="AOHead"><span style="color: #e1e1e1">strong</span></th>
        </tr>
      </table>
      <table>
        <tr>
          <th style="min-width:126px" ></th>
          <th class="AOHead" style="min-width:75px;border-top: 1px solid #b2b2b2;"><span style="color: #e1e1e1;">prev</span></th>
          <th class="AOHead" style="min-width:75px;border-top: 1px solid #b2b2b2;"><span style="color: #e1e1e1;">next</span></th>
          <th style="min-width:3px" ></th>
          <th class="AOHead" style="min-width:75px;border-top: 1px solid #b2b2b2;"><span style="color: #e1e1e1;">prev</span></th>
          <th class="AOHead" style="min-width:75px;border-top: 1px solid #b2b2b2;"><span style="color: #e1e1e1;">next</span></th>
        </tr>
        <tr>
          <td class="AOCaption"> Difference Bars:</td>
          <td class="AOValues" v-if="hintData.autoOverlay.normal.prev.bars" :style="{'color':  getColor(hintData.autoOverlay.normal.prev.isLow)}">{{ hintData.autoOverlay.normal.prev.bars }}</td>
          <td class="AOValues" v-if="hintData.autoOverlay.normal.next.bars" :style="{'color':  getColor(hintData.autoOverlay.normal.next.isLow)}">{{ hintData.autoOverlay.normal.next.bars }}</td>
          <th style="min-width:3px" ></th>
          <td class="AOValues" v-if="hintData.autoOverlay.strong.prev.bars" :style="{'color':  getColor(hintData.autoOverlay.strong.prev.isLow)}">{{ hintData.autoOverlay.strong.prev.bars }}</td>
          <td class="AOValues" v-if="hintData.autoOverlay.strong.next.bars" :style="{'color':  getColor(hintData.autoOverlay.strong.next.isLow)}">{{ hintData.autoOverlay.strong.next.bars }}</td>
        </tr>
        <tr>
          <td class="AOCaption">Difference:</td>
          <td class="AOValues" ><span v-if="hintData.autoOverlay.normal.prev.bars!='-'" :style="{'color':  getColor(hintData.autoOverlay.normal.prev.isLow)}">{{ hintData.autoOverlay.normal.prev.abs }}</span></td>
          <td class="AOValues" ><span v-if="hintData.autoOverlay.normal.next.bars!='-'" :style="{'color':  getColor(hintData.autoOverlay.normal.next.isLow)}">{{ hintData.autoOverlay.normal.next.abs }}</span></td>
          <th style="min-width:3px" ></th>
          <td class="AOValues" ><span v-if="hintData.autoOverlay.strong.prev.bars!='-'" :style="{'color':  getColor(hintData.autoOverlay.strong.prev.isLow)}">{{ hintData.autoOverlay.strong.prev.abs }}</span></td>
          <td class="AOValues" ><span v-if="hintData.autoOverlay.strong.next.bars!='-'" :style="{'color':  getColor(hintData.autoOverlay.strong.next.isLow)}">{{ hintData.autoOverlay.strong.next.abs }}</span></td>
        </tr>
        <tr>
          <td class="AOCaption">Difference %:</td>
          <td class="AOValues" ><span v-if="hintData.autoOverlay.normal.prev.bars!='-'" :style="{'color':  getColor(hintData.autoOverlay.normal.prev.isLow)}">{{ hintData.autoOverlay.normal.prev.percent }} %</span></td>
          <td class="AOValues" ><span v-if="hintData.autoOverlay.normal.next.bars!='-'" :style="{'color':  getColor(hintData.autoOverlay.normal.next.isLow)}">{{ hintData.autoOverlay.normal.next.percent }} %</span></td>
          <th style="min-width:3px" ></th>
          <td class="AOValues" ><span v-if="hintData.autoOverlay.strong.prev.bars!='-'" :style="{'color':  getColor(hintData.autoOverlay.strong.prev.isLow)}">{{ hintData.autoOverlay.strong.prev.percent }} %</span></td>
          <td class="AOValues" ><span v-if="hintData.autoOverlay.strong.next.bars!='-'" :style="{'color':  getColor(hintData.autoOverlay.strong.next.isLow)}">{{ hintData.autoOverlay.strong.next.percent }} %</span></td>

        </tr>
      </table>
      </div>
  </div>
</template>

<script>
//const StompJs = require("@stomp/stompjs/esm5");

export default {
  name: "HintKeysTableAutoOverlay",
  props: ["chartHandler"],

  mounted() {
    // enable columns in the fib keys table
    // it takes few seconds to available data
    // So, that's why timers hack
    /*  let timer = setInterval(() => {
          if (window.$store.CHARTS == undefined || $store.activeChart.chart == undefined) return;
          this.CHART = window.$store.CHARTS[this.tvId];
          this.CHART.chart.get(`AUTOFIB`).forEach((fib) => {
              document.getElementById("fib-keys-table-auto").classList.add(fib.name.split("-")[1]);
          });

          // start websocket
          this.ws();

          clearInterval(timer);
      }, 100);
*/    },

  methods: {
    zoomTo(fibType) {
      console.log(fibType);
      //this.chartHandler.chartObjects[this.id].$refs.tvjs.resetChart();

      //const firstId = this.$props.chartHandler.layoutHandler.getFirstId();


      let $max = null;
      let $min = null;
      let t1 = null;
      let t2 = null;

      if (fibType == "Fib2") {
        const fib1 = this.chartHandler.charts[''+this.id].settings.fibo.F1;
        const fib2 = this.chartHandler.charts[''+this.id].settings.fibo.F2;

        $max = Math.max(fib1.p1[1], fib1.p2[1], fib2.p1[1], fib2.p2[1]);
        $min = Math.min(fib1.p1[1], fib1.p2[1], fib2.p1[1], fib2.p2[1]);

        t1 = Math.min(fib1.p1[0], fib1.p2[0], fib2.p1[0], fib2.p2[0]);
      } else {
        let fib;
        if (fibType == "Fib1")
          fib = this.chartHandler.charts[''+this.id].settings.fibo.F1;
        else
          fib = this.chartHandler.charts[''+this.id].settings.fibo.FX;
        $max = Math.max(fib.p1[1], fib.p2[1]);
        $min = Math.min(fib.p1[1], fib.p2[1]);

        t1 = Math.min(fib.p1[0], fib.p2[0]);
      }

      //const offset = ($max - $min) / 30;
      //    this.chartHandler.chartObjects[this.id].$refs.tvjs.set_yRange($max + offset, $min - offset);

      // time offset
      const ohlcv = this.chartHandler.charts[''+this.id].data.get("chart")[0];
      t2 = ohlcv.data[ohlcv.data.length - 1][0];

      let diff = (t2 - t1)/20;
      this.chartHandler.chartObjects[this.id].$refs.tvjs.setRange(t1-5*diff, t2+diff, 5);
      // $store.activeChart.chart.tv.setRange(t1, t2, 5);*/
    },
    getSize(size) {
      return size - (127-(this.$props.hintData.autoOverlay.strength/10*(127)))
    },
    getColor(isLow) {
      return isLow ? '#ff1232' : '#23ff00';
    },
    ws() {
      /*
        this.client = new StompJs.Client({ brokerURL: "wss://chart-software.com/ws" });
        this.client.activate();

        this.client.onConnect = () => {
            this.client.subscribe(`/topic/${this.CHART.symbol}/${this.CHART.timeframe}/fibo`, (data) => {
                var fib = JSON.parse(data.body);
                // this.fibKeys[fibData.type] = [Number(fibData.fibRatio).toFixed(4), Number(fibData.consolidationFactor).toFixed(4)];
                document.getElementById(`${fib.type}Ratio`).textContent = Number(fib.fibRatio).toFixed(4);
                document.getElementById(`${fib.type}Factor`).textContent = Number(fib.consolidationFactor).toFixed(4) * 100;
            });
        };*/
    },
  },

  beforeDestroy() {
    //if (this.client) this.client.deactivate();
  },

  data() {
    return {
      isAdmin : this.chartHandler.isAdmin,
      hintData : this.chartHandler.charts["0"].hintData,
      id : "0",
      client: null,
      CHART: null,
      fibKeys: {
        FibX: [32, 21],
        Fib1: [3, 32.20],
        Fib2: [12.20, 23.0],
      },
    };
  },
};
</script>

<style>

.AOHead {
  color: #e1e1e1;
  min-width : 150px;
  text-align: center;
}

.AOValues {
  color: #e5e5e5;
  text-align: center;
}

.HintValues {
  color: rgba(27, 31, 44, 0.91);
  text-align: right;
  background-color: #e5e5e5;
  padding-right: 10px;
}

.HintCaption {
  color: rgba(27, 31, 44, 0.91);
  text-align: center;
  font-weight: 700;
  font-size: large;
  background-color: #e5e5e5;
}

.AOHead span {
  font-weight: 700;
}

.FibX .FibX-column,
.Fib1 .Fib1-column,
.Fib2 .Fib2-column {
  display: table-cell;
}

.AOCaption {
  padding: 0 14px;
  text-align: left;
}

.note pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: inherit;
}
</style>
