<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";
import ChartUtils from "../utils/ChartUtils.js";

export default {
  name: "GhostFeed",
  mixins: [Overlay, Tool],
  methods: {
    meta_info() {
      return { author: "akjcodes", version: "1.0.0" };
    },
    tool() {
      return {
        // Descriptor for the tool
        name: "GhostFeed",
        group: "GhostFeed",
        icon: "M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1H3a1 1 0 0 0-1 1V14a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V3.5a1 1 0 0 0-1-1h-1v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Zm6.979 3.856a.5.5 0 0 0-.968.04L7.92 10.49l-.94-3.135a.5.5 0 0 0-.895-.133L4.232 10H3.5a.5.5 0 0 0 0 1h1a.5.5 0 0 0 .416-.223l1.41-2.115 1.195 3.982a.5.5 0 0 0 .968-.04L9.58 7.51l.94 3.135A.5.5 0 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128L9.979 5.356Z", //"fa fa-pencil-square-o",
        svg: true,
        type: "Segment",
        hint: "Virtual Prices",
        data: [], // Default data
        settings: {
          color: "#eecc99",
          backColorUp: "#5ec423",
          backColorDown: "#a80000",
          lineColorUp: "#eaeaea",
          lineColorDown: "#dc570e",
          dochtUp: "#e8e8e8",
          dochtDown: "#3e487a"
        }/*,
        fieldsTemplate: [
          ["color", "Color", "color"],
          ["lineWidth", "Line Thickness", "lineWidth"],
          ["color", "Back Color", "backColor"],
          ["integer", "Background Opacity", "backOpacity"],
        ],*/
      };
    },
    // Called after overlay mounted
    init() {
      this.trackingPoints = false;
      this.candles = [];
      this.counter = (this.counter || 0);
      this.counter++;
      //this.points = this.$props.settings || {};

      this.updatePins();
    },
    updatePins() {
      const self = this;
      //console.log('add_ind_updatePins', this.pins.length,this.$props.settings);
      this.pins.splice(0,this.pins.length);
      if (this.$props.settings && this.$props.settings.p1 && this.$props.settings.p2) {
        //this.state = 'finished';
        this.set_state("finished");
        this.$props.settings.$state = "finished";
        //this.$props.settings = this.$props.settings || {};
        //this.$props.settings.p1 = [this.points[0][0],this.points[0][1]];
        //this.$props.settings.p2 = [this.points[this.$props.settings.points.length-1][0],this.points[this.$props.settings.points.length-1][1]];

        let i = 0;
        while (this.$props.settings['p'+(++i)]) {
          this.pins.push(
              new Pin(this, 'p'+i, {
                state: "finished",
                t: this.$props.settings['p'+i][0],
                y$: this.$props.settings['p'+i][1]
              })
          );
          this.pins[this.pins.length-1].on("settled", (p) => {
            self.updateCandles(-1+parseInt(p.substring(1)));
          });
          this.pins[this.pins.length-1].on("dragging", (p) => {
            self.updateCandles(-1+parseInt(p.substring(1)));
          });
        }
        //console.log(this.pins, this.$props.settings['p1'][0], this.$props.settings['p1'][1], this.$props.settings['p'+maxP][0], this.$props.settings['p'+maxP][1], this.pins[0].t,this.pins[0].y$);
        /*this.pins.push(
            new Pin(this, 'p'+maxP, {
              state: "finished",
                t: this.$props.settings['p'+maxP][0],
                y$: this.$props.settings['p'+maxP][1]
            })
        );*/
        //console.log('saveToStarage', this.counter, self.settings.$uuid, self.points, self);
        //window.chartHandler.setStorage(self.settings.$uuid, self.points);
        this.trackingPoints = false;
      } else {
                //console.log('saveToStarage2', this.counter, self.settings.$uuid, self.points, self);
        // First pin is settled at the mouse position
        //self.pins.push(new Pin(this, 'p1'));
        //self.$props.settings.p1 = [this.pins[0].t,this.pins[0].y$];
        //self.trackingPoints = true;
        // Second one is following mouse until it clicks
        self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("GhostFeed", this.id, this.$props.settings);
        this.pins.push(new Pin(this, "p1"));
        this.pins[this.pins.length-1].on("dragging", (p) => {
          self.updateCandles(-1+parseInt(p.substring(1)));
        });
        this.addPointWithTracking();
        /*this.pins.push(
            new Pin(this, 'p2', {
              state: "tracking",
            })
        );
        this.pins[1].on("settled", () => {
          // Call when current tool drawing is finished
          // (Optionally) reset the mode back to 'Cursor'
          //console.log("finished");
          self.set_state("finished");
          self.$emit("drawing-mode-off");
          self.points['p'+(self.counter++)] =[this.pins[1].t,this.pins[1].y$];

          if (self.trackingPoints) {
            self.trackingPoints = false;
            //window.chartHandler.setStorage(self.settings.$uuid, self.points);
            self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off('GhostFeed');
          }
        });*/
      }
      this.updateCandles(-1);
    },
    onDragging() {
      this.updateCandles(-1);
    },
    updateCandles(idxMoved) {
      const self = this;
      const saveCandles = self.candles;
      self.candles = [];
      //console.log(saveCandles, self.candles, idxMoved);
      this.pins.forEach((p, idx) => {
        if (idx>0) {
          let iFrom = Math.min(this.pins[idx - 1].t,this.pins[idx].t);
          let iFromNewHighs = iFrom;
          const iTo = Math.max(this.pins[idx - 1].t,this.pins[idx].t);
          const count = iTo - iFrom;
          const priceFrom = iFrom != this.pins[idx].t ? this.pins[idx].y$ : this.pins[idx - 1].y$;
          const priceTo = iFrom == this.pins[idx].t ? this.pins[idx].y$ : this.pins[idx - 1].y$;

          if (saveCandles[idx-1] && saveCandles[idx-1].length) {
            self.candles.push(saveCandles[idx - 1]);
            iFrom += saveCandles[idx-1].length;
          }
          else
            self.candles.push([]);

          if (iFrom > iTo) {
            self.candles[self.candles.length-1].splice(count);
          }

          const factor = ((priceFrom>priceTo) ? 0.3 : 0.7);
          const diff = Math.max(priceFrom*0.0002, Math.abs(priceTo-priceFrom)/(count)*3);
          const currentCount = self.candles[idx-1].length;
          let index = iFromNewHighs;
          while (++iFromNewHighs <= iTo) {
              const dirUp = Math.random() > factor ? 1 : -1;
              const newPrice = priceFrom + ((priceTo - priceFrom) / count * (iTo - iFromNewHighs));
              //console.log('priceTo',priceFrom,priceTo)
              if ((iTo - iFromNewHighs >= currentCount) || !self.candles[self.candles.length - 1]|| !self.candles[self.candles.length - 1][iTo-iFromNewHighs]) {
                const maxC = (diff * Math.random()) * dirUp;
                const minC = -((diff * Math.random()) * dirUp);
                const max = (diff * Math.random() * 1.35);
                const min = -(diff * Math.random() *1.35);
                self.candles[self.candles.length - 1].push([iFromNewHighs, newPrice, max, maxC, minC, min, -(((priceTo - priceFrom) / count * (iTo - iFromNewHighs)) * dirUp) / count]);
              } else {
                self.candles[self.candles.length - 1][iTo-iFromNewHighs][0] = iFromNewHighs;
                self.candles[self.candles.length - 1][iTo-iFromNewHighs][1] = newPrice;
              }
          }
        }
      });
    },
    addPointWithTracking() {
      const self = this;
      const layout = this.$props.layout;
      this.pins.push(
          new Pin(this, 'p'+(this.pins.length+1), {
            state: "tracking",
          })
      );
      this.pins[this.pins.length-1].on("dragging", (p) => {
        self.updateCandles(-1+parseInt(p.substring(1)));
      });
      this.pins[this.pins.length-1].on("settled", (name, source) => {
        if ((self.state !== "finished") && (source == "mousedown")) {
          if ((self.pins.length > 1))
            console.log(source,this.pins[this.pins.length - 1].t, this.pins[this.pins.length - 1].y$);
          //self.points['p' + (this.pins.length)] = [this.pins[this.pins.length - 1].t, this.pins[this.pins.length - 1].y$];
          if ((self.pins.length > 1) &&
              (Math.abs(layout.t2screen(self.pins[self.pins.length - 1].t) - layout.t2screen(self.pins[self.pins.length - 2].t))< 5) &&
              (Math.abs(layout.$2screen(self.pins[self.pins.length-1].y$)- layout.$2screen(self.pins[self.pins.length - 2].y$)) < 5)) {
            self.pins.splice(self.pins.length - 1, 1);
            delete self.sett['p' + (this.pins.length+1)];
            self.set_state("finished");
            self.$emit("drawing-mode-off");
            self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off('GhostFeed');
          } else {
            self.addPointWithTracking();
          }
        }
        self.updateCandles();
      });
    },
    draw(ctx) {
      if (this.deleted) return;
      const layout = this.$props.layout;
      //console.log(this);
      //if (this.points && (this.points.p1)) {
        ctx.lineWidth = this.line_width;
        ctx.strokeStyle = this.color;

        ctx.beginPath();

        //ctx.strokeStyle = '#ffff00';
        //if (this.p1Name)
        //  ctx.moveTo(layout.t2screen(this[this.p1Name][0]), layout.$2screen(this[this.p1Name][1]));
        //else
        //ctx.moveTo(layout.t2screen(this.$props.settings.p1[0]), layout.$2screen(this.$props.settings.p1[1]));

        const self = this;
        //ctx.moveTo(layout.t2screen(this.pins[0].t), layout.$2screen(this.pins[0].y$));
        this.pins.forEach((p, idx) => {
          if (idx >0) {
            new Seg(this, ctx).draw([p.t, p.y$], [self.pins[idx-1].t,self.pins[idx-1].y$]);
          }
        });

        if (this.selected || this.show_pins) {
          ctx.stroke();
        }
          /*
                  }

                  const idx = this.pins.length;
                  //ctx.beginPath()
                  ctx.moveTo(layout.t2screen(self.pins[idx-1].t), layout.$2screen(self.pins[idx-1].y$));
                  ctx.lineTo(layout.t2screen(self.pins[idx-1].t)-10, layout.$2screen(self.pins[idx-1].y$)+3);
                  ctx.lineTo(layout.t2screen(self.pins[idx-1].t)+10, layout.$2screen(self.pins[idx-1].y$)+3);
                //ctx.lineTo(layout.t2screen(p.t), layout.$2screen(p.y$));
                  ctx.stroke();
                  //new Seg(this, ctx).draw([this.points[lastP][0]-1, this.points[lastP][1]], [this.points[lastP][0]+1, this.points[lastP][1]]);
          */
      //console.log(this.candles, layout);
      const cw = Math.trunc(layout.px_step/4.2)+0.5;
      ctx.strokeStyle = this.settings.dochtUp;
      ctx.beginPath()
      this.candles.forEach((cndl) => {
        cndl.forEach((c) => {
          if (!(c[4]>c[3])) {
            ctx.moveTo(layout.t2screen(c[0]),
                layout.$2screen(c[1] + c[6] + c[2])+0.5);
            ctx.lineTo(layout.t2screen(c[0]),
                layout.$2screen(c[1] + c[6] + c[5])+0.5);
          }
        });
      });
      ctx.stroke();
      ctx.strokeStyle = this.settings.dochtDown;
      ctx.beginPath()
      this.candles.forEach((cndl) => {
        cndl.forEach((c) => {
          if ((c[4]>c[3])) {
            ctx.moveTo(layout.t2screen(c[0]),
                layout.$2screen(c[1] + c[6] + c[2]));
            ctx.lineTo(layout.t2screen(c[0]),
                layout.$2screen(c[1] + c[6] + c[5]));
          }
        });
      });
      ctx.stroke();
      ctx.fillStyle = this.settings.backColorUp;
      ctx.beginPath()
      this.candles.forEach((cndl) => {
        cndl.forEach((c) => {
          if (!(c[4]>c[3]))
            ctx.rect(layout.t2screen(c[0])-cw,
                layout.$2screen(c[1]+c[6]+c[3])+0.5,
                2*cw,
                layout.$2screen(c[1]+c[6]+c[4])-layout.$2screen(c[1]+c[6]+c[3])+0.5);
        });
      });
      ctx.fill();
      ctx.fillStyle = this.settings.backColorDown;
      ctx.beginPath()
      this.candles.forEach((cndl) => {
        cndl.forEach((c) => {
          if ((c[4]>c[3]))
            ctx.rect(layout.t2screen(c[0])-cw,
                layout.$2screen(c[1]+c[6]+c[3])+0.5,
                2*cw,
                layout.$2screen(c[1]+c[6]+c[4])-layout.$2screen(c[1]+c[6]+c[3])+0.5);
        });
      });
      ctx.fill();
      ctx.strokeStyle = this.settings.lineColorUp;
      ctx.beginPath()
      this.candles.forEach((cndl) => {
        cndl.forEach((c) => {
          if (!(c[4]>c[3]))
            ctx.rect(layout.t2screen(c[0])-cw,
                layout.$2screen(c[1]+c[6]+c[3])+0.5,
                2*cw,
                layout.$2screen(c[1]+c[6]+c[4])-layout.$2screen(c[1]+c[6]+c[3])+0.5);
        });
      });
      ctx.stroke();
      ctx.strokeStyle = this.settings.lineColorDown;
      ctx.beginPath()
      this.candles.forEach((cndl) => {
        cndl.forEach((c) => {
          if ((c[4]>c[3]))
            ctx.rect(layout.t2screen(c[0])-cw,
                layout.$2screen(c[1]+c[6]+c[3])+0.5,
                2*cw,
                layout.$2screen(c[1]+c[6]+c[4])-layout.$2screen(c[1]+c[6]+c[3])+0.5);
        });
      });
      ctx.stroke();
        //if (this.p2Name in this)
        //  ctx.lineTo(layout.t2screen(this[this.p2Name][0]), layout.$2screen(this[this.p2Name][1]));
      //}

      // Top
      // Bottom
      //new Seg(this, ctx).draw([this.p1[0], this.p1[1]], [this.p2[0], this.p1[1]]);

      if (this.pins.length>0)
        this.render_pins(ctx);
    },
    use_for() {
      return ["GhostFeed"];
    },
    data_colors() {
      return [this.color];
    },
  },
  // Define internal setting & constants here
  computed: {
    sett() {
      return this.$props.settings;
    },
    deleted() {
      return this.$props.settings.deleted;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    line_width() {
      return this.sett.lineWidth || 1;
    },
    color() {
      return this.sett.color || "#ffb74d";
    },
  },
  data() {
    return {
    };
  },
};
</script>
