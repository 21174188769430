<script>
import Overlay from "../lib/mixins/overlay.js";

export default {
    name: "AutoLines",
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: "akjcodes",
                version: "1.0",
                desc: "Auto Lines",
            };
        },
        tool() {
            return {
                // Descriptor for the tool
                group: "Indicators",
                type: "Indicator",
                chartType: "onchart",
                name: "Auto Lines",
                hint: "This hint will be shown on hover",
                settings: {
                    flags: [],
                    "z-index": 1,
                    isAutoLines: true,
                },
            };
        },

        draw(ctx) {
            if (!this.$parent.$parent.$parent.$parent.$parent.chartHandler.chartSettings.isAutoLines) return;
            const setting = this.setts ;
            //console.log(setting.flags);
            if (setting.flags) {
              setting.flags.forEach((flag) => {
                this.drawLine(ctx, flag.lines[0], { copy: flag.copy, source: flag.source });
              });
            }
            //if (setting.flags[1] != undefined) this.drawLine(ctx, setting.flags[1].lines[0], { copy: setting.flags[1].copy, source: setting.flags[1].source }, true);
        },

        drawLine(ctx, line, lineObj, isDash) {
            const layout = this.$props.layout;

            // define the styles
            ctx.lineWidth = 2;

            if (lineObj.source == "MAIN") {
              if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
                  ctx.strokeStyle = "#ffffff";
                } else {
                  ctx.strokeStyle = "#000";
                }
            }

            if (lineObj.source == "OPPOSITE") {
              if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
                ctx.strokeStyle = "#c2c2c2";
                } else {
                  ctx.strokeStyle = "#434343";
                }
            }

            if (lineObj.source == "RISING_FALLING") {
              if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
                ctx.strokeStyle = "#6f6f6f";
                } else {
                    ctx.strokeStyle = "#6f6f6f";
                }
            }

            if (lineObj.copy == true) {
                ctx.setLineDash([6, 4]);
            } else {
                ctx.setLineDash([]);
            }

            const dt = new Date();
          // TODO try to set in on chart directly
            const timeOffset = 0;//(390 + dt.getTimezoneOffset()) * 60 * 1000; // FIX for real-time candle creating issue

            const pointDate = line.pointDate - timeOffset;
            const otherDate = line.otherDate - timeOffset;

            let x1 = layout.t2screen(pointDate);
            let y1 = layout.$2screen(line.pointPrice);
            let x2 = layout.t2screen(otherDate);
            let y2 = layout.$2screen(line.otherPrice);

            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);

            let w = layout.width;
            let h = layout.height;
            // TODO: transform k (angle) to screen ratio
            // (this requires a new a2screen function)
            let k = (y2 - y1) / (x2 - x1);
            let s = Math.sign(x2 - x1 || y2 - y1);
            let dx = w * s * 2;
            let dy = w * k * s * 2;
            if (dy === Infinity) {
                (dx = 0), (dy = h * s);
            }

            ctx.moveTo(x2, y2);
            ctx.lineTo(x2 + dx, y2 + dy);
            // ctx.moveTo(x1, y1);
            // ctx.lineTo(x1 - dx, y1 - dy);

            ctx.stroke();
        },

        use_for() {
            return ["AutoLines"];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        flags() {
            return this.sett.flags || [];
        },
    },
    data() {
        return {
            setts: this.$parent.$parent.$parent.$parent.$parent.chartHandler.charts[''+this.$parent.$parent.$parent.$parent.$props.id].settings,
            COLORS: ["#42b28a", "#5691ce", "#612ff9", "#d50b90", "#ff2316"],
        };
    },
};
</script>
