<template>
  <div  v-if="isAdmin">
  </div>
  <div  v-else-if="isAdmin">
    <div class="login">
      <div class="container">

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import process from "process";
import {ChartHandler} from '../utils/ChartHandler.js'


export default {
    name: "Admin",
    data() {
      //console.log(this.$route.query)
      return {
        isAdmin: window.chartHandler.isAdmin
        };
    },
    mounted() {
      const lang = localStorage.getItem("language");
      //console.log(lang, localStorage);
      if (lang) {
        this.setLanguage(lang);
      }
    },
  beforeCreate() {
    window.language = {
    }
  },
  created() {
        // reset login status
        console.log(process.env);
    },
    computed: {
    // Copy a subset of TradingVue props

    },
    methods: {
      handleResetWithTokenSubmit() {

      },
    },
};
</script>

<style scoped>
.login_container {
    min-height: 100vh;
    padding: 0 0.2rem;
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    background-color: #f3f3f3;
}
.base-container {
  margin-top: -70px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    _overflow-y: scroll;
}
.base-container .header {
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
}
.base-container .content {
    display: flex;
    flex-direction: column;
}
.base-container .content .image {
    text-align: center;
    width: 21em;
}
.base-container .content .image img {
    width: 100%;
    height: 100%;
}
.base-container .content .form {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.base-container .content .form .form-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ywidth: fit-content;
}
.flex-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 700px;
  margin-bottom: 5px;
}
.flex-item-left {
  padding: 0px;
  flex: 50%;
}

.fieldset {
  float: left;
  width: 7em;
  margin-right: 5px;
  margin-top: 3px;
  text-align: left;
}

.flex-item-right {
  padding: 0px;
  flex: 50%;
  padding-left: 10px;
}

.flex-item-all {
  padding: 0px;
  flex: 100%;
}

.inputlong{
  width: 590px;
  float: left;
}
.inputshort {
  min-width: 230px;
  width: 230px;
  float: left;
}

.flex-item-all-left {
  padding: 0px;
  alignment: left;
  flex: 100%;
  justify-content: left;
}
.base-container .content .form .form-group-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
}
.base-container .content .form .form-group-col label {
  font-size: 20px;
}
.base-container .content .form .form-group-col input,
.base-container .content .form .form-group-col select {
  margin-top: 2px;
  min-width: 18em;
  height: 37px;
  padding: 0px 10px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  background-color: #f3f3f3;
  border: 0;
  border-radius: 4px;
  margin-bottom: 31px;
  transition: all 250ms ease-in-out;
}
.base-container .content .form .form-group input,
.base-container .content .form .form-group select{
  margin-top: 0px;
  min-width: 16em;
  height: 30px;
  padding: 0px 2px;
  font-size: 13px;
  font-family: "Open Sans", sans-serif;
  background-color: #f3f3f3;
  border: 0;
  border-radius: 4px;
  margin-bottom: 0px;
  transition: all 250ms ease-in-out;
  float: left;
  width: 200px;
}
.base-container .content .form .form-group-col input:focus,
.base-container .content .form .form-group input:focus {
    outline: none;
    box-shadow: 0px 0px 12px 0.8px #3474dbb2;
}
.base-container .footer {
    position: absolute;
    padding: 20px 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    background: #fff;
}
#login {
  margin-top: -50px;
  alignment: center;
  align-items: center;
  width: 400px;
}
#register {
  margin-top: -50px;
  height: 20em;
}
.login {
  width: 60em;
  height: 48em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  position: relative;
  z-index: 99;
}
.login .container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(27, 31, 44, 0.97);
    box-shadow: 0px 0px 12px 8px rgba(15, 15, 15, 0.2);
    border-radius: 4px;
    position: relative;
    z-index: 99;
    width: 100%;
    height: 100%;
    z-index: 99;
    padding: 17px 10px;
    overflow: hidden;
}
.login .right-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90%;
    background-color: rgba(27, 31, 44, 0.29);
    width: 100%;
    position: absolute;
    right: -34%;
    border-radius: 6px;
    z-index: 1;
    transition: all 400ms ease-in-out;
    cursor: pointer;
    box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.281);
}
.login .right-side.right {
    right: -40%;
    align-items: flex-end;
}
.login .right-side.right:hover {
    right: -45%;
}
.login .right-side.left {
    right: 40%;
    align-items: flex-start;
}
.login .right-side.left:hover {
    right: 45%;
}
.login .right-side .text {
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    margin-right: 3em;
    margin-left: 3em;
}

.btn:hover  {
  border: 2px solid #FF6E00;
    background-color: #FF6E00;
    color: #ffffff;
    border-radius: 3px;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    text-decoration: none;
  width: 288px;
  padding: 14px 28px;
  font-size: 20px;
}
.btn{
  background-color: #ffffff;
  border: 2px solid #FF6E00;
  color: #FF6E00;
  padding: 14px 28px;
  font-size: 20px;
  cursor: pointer;
  width: 288px;
  text-decoration: none;
}
.btn:focus {
    outline: none;
}
.link {
  margin-top: 1px;
  margin-bottom: 3px;
  transition: all 250ms ease-in-out;
  color: #FF6E00;
  font-size: 15px;
}
.form-group {
  margin-top: 1rem;
}
</style>
