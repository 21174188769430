
// Candle object for Candles overlay

export default class CandleExt {

    constructor(overlay, ctx, data) {
        this.ctx = ctx
        this.self = overlay
        this.style = data.raw[6] || this.self
        this.draw(data)
    }

    draw(data) {
        if (window.chartHandler.settings.showCandles) {
            const green = data.raw[4] >= data.raw[1]
            const body_color = green ?
                this.style.colorCandleUp :
                 this.style.colorCandleDw

            const wick_color = //green ?
                this.style.colorWickUp// : this.style.colorWickDw

            this.ctx.lineWidth = 1;
            let w = Math.max(data.w, 0.8)
            let hw = Math.max(Math.floor(w * 0.5), 0.6)
            let h = Math.abs(data.o - data.c)
            let max_h = data.c === data.o ? 1 : 2
            let x05 = Math.floor(data.x) - 0.5

            //console.log(wick_color,body_color)
            this.ctx.strokeStyle = wick_color
            this.ctx.beginPath()
            this.ctx.moveTo(x05, Math.floor(data.h))
            this.ctx.lineTo(x05, Math.floor(data.l))
            this.ctx.stroke()


            if (data.w >0.80) {

                this.ctx.fillStyle = body_color
                // TODO: Move common calculations to layout.js
                let s = green ? 1 : -1
                this.ctx.beginPath()
                this.ctx.fillRect(
                    Math.floor(data.x - hw - 1),
                    data.c,
                    Math.floor(hw * 2 + 1),
                    s * Math.max(h, max_h),
                )
                this.ctx.stroke()
                this.ctx.rect(
                    Math.floor(data.x - hw - 1)-0.5,
                    Math.floor(data.c)-0.5,
                    Math.floor(hw * 2 + 1)+1,
                    Math.floor(s * Math.max(h, max_h))+1,
                )
                this.ctx.stroke()


            } else {


                this.ctx.strokeStyle = body_color

                this.ctx.beginPath()
                this.ctx.moveTo(
                    x05,
                    Math.floor(Math.min(data.c-.5, data.o-.5)),
                )
                this.ctx.lineTo(
                    x05,
                    Math.floor(Math.max(data.c+.5, data.o+.5)) +
                    (data.h === data.l ? 1 : 0)
                )

                this.ctx.stroke()
                /*                  this.ctx.strokeStyle = body_color
                                  this.ctx.moveTo(
                                      x05,
                                      Math.floor(Math.min(data.o, data.c)),
                                  )
                                  this.ctx.lineTo(
                                      x05,
                                      Math.floor(Math.max(data.o, data.c)) +
                                      (data.o === data.c ? 1 : 0)
                                  )

                                  this.ctx.stroke()*/
            }
        }
    }
}
