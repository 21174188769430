<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";
import Line from "../lib/components/primitives/line.js";
import Math2 from "../lib/stuff/math";

export default {
    name: "LineToolOwn",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "C451", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                name: "LineTool",
                group: "Lines",
                label: "Line",
                icon: Icons["segment.png"],
                type: "Segment",
                hint: "This hint will be shown on hover",
                data: [], // Default data
                settings: {
                    lineWidth: 1,
                    color: "#ff0000",
                    extendP1: true,
                    extendP2: true,
                }, // Default settings
                fieldsTemplate: [
                    ["color", "Color", "color"],
                    ["lineWidth", "Line Thickness", "lineWidth"],
                ],
              // Modifications
              mods_: {
                Extended: {
                  // Rewrites the default setting fields
                  settings: {
                    lineWidth: 1,
                    color: "#ff0000",
                    extendP1: true,
                    extendP2: true,
                  },
                  icon: Icons["extended.png"],
                },
              },
            };
        },
        // Called after overlay mounted
        init() {
          this.updatePins();
        },
        updatePins() {
          // loading from saved data
          const self = this;
          this.pins.splice(0,this.pins.length);
            if (this.$props.settings.p1) {
                this.pins.push(
                    new Pin(this, "p1", {
                        state: "finished",
                        t: this.$props.settings.p1[0],
                        y$: this.$props.settings.p1[1],
                    })
                );
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "finished",
                        t: this.$props.settings.p2[0],
                        y$: this.$props.settings.p2[1],
                    })
                );
            } else {
              const self = this;
                // First pin is settled at the mouse position
                self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("LineTool", this.id, this.$props.settings);

                this.pins.push(new Pin(this, "p1"));
                // Second one is following mouse until it clicks
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "tracking",
                    })
                );
                this.pins[1].on("settled", () => {
                    // Call when current tool drawing is finished
                    // (Optionally) reset the mode back to 'Cursor'
                  const orgState = this.state;

                  this.set_state("finished");
                  this.$emit("drawing-mode-off");

                  if (orgState !== 'finished') {
                    self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.charts['0'].data.tv.$set(this.data, 'drawingMode', false);
                    self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("LineTool", this.$props.settings);
                  }

                    //global drawing mode
                    //window.$store.drawingMode = false;
                  //self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.charts['0'].data.tv.$set(this.data, 'drawingMode', false);
                });
            }
        },
        draw(ctx) {
            if (!this.p1 || !this.p2) return;

            const layout = this.$props.layout

            if (this.show_pins)
              ctx.lineWidth = this.line_width+3;
            else
              ctx.lineWidth = this.line_width;
            ctx.strokeStyle = this.color;
            ctx.beginPath();


            let x1 = layout.t2screen(this.p1[0])
            let y1 = layout.$2screen(this.p1[1])
            let x2 = layout.t2screen(this.p2[0])
            let y2 = layout.$2screen(this.p2[1])

            new Seg(this, ctx).draw(this.p1, this.p2);

            ctx.moveTo(x1, y1)
            ctx.lineTo(x2, y2)

            let w = layout.width
            let h = layout.height
            // TODO: transform k (angle) to screen ratio
            // (this requires a new a2screen function)
            let k = (y2 - y1) / (x2 - x1)
            let s = Math.sign(x2 - x1 || y2 - y1)
            let dx = w * s * 2
            let dy = w * k * s * 2

            let col1x = x1;
            let col1y = y1;
            let col2x = x2;
            let col2y = y2;
            if (dy === Infinity) { dx = 0, dy = h * s}

            if (this.extendP1) {
                ctx.moveTo(x2, y2)
                ctx.lineTo(x2 + dx, y2 + dy)
                col2x = x2 + dx;
                col2y = y2 + dy;
            }

            if (this.extendP2) {
              ctx.moveTo(x1, y1)
              ctx.lineTo(x1 - dx, y1 - dy)
              col1x = x1 - dx;
              col1y = y1 - dy;

              this.collisions.push(
                this.make([x2, y2], [x1, y1], !this.extendP1)
              )
            } else if (this.extendP1) {
              this.collisions.push(
                  this.make([x1, y1], [x2, y2], true)
              )
            }

            //console.log(col1x, col1y, col2x, col2y, x1, y1, x2, y2)


          ctx.stroke();
          this.render_pins(ctx);
        },
        make(p1, p2, ray) {
          let f = ray ?
              Math2.point2ray.bind(Math2) :
              Math2.point2line.bind(Math2);
          return (x, y) => {
            return f(
                [x, y], p1, p2
            ) < 3
          }
        },
        use_for() {
            return ["LineTool"];
        },
        data_colors() {
            return [this.color];
        },

    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        p1() {
            return this.$props.settings.p1;
        },
        p2() {
            return this.$props.settings.p2;
        },
        extendP1() {
          return this.sett.extendP1;
        },
        extendP2() {
          return this.sett.extendP2;
        },
        line_width() {
            return this.sett.lineWidth || 1;
        },
        color() {
            return this.sett.color || "#ff0000";
        },
        color2() {
            return this.$props.settings.p2;
        },
    },
    data() {
        return {
          highestCandleUpDown : [0,0,0,0]
        };
    },
};
</script>
