<template>
    <div class="left_toolbar"  :style="{'background-color':getBackgroundLayoutColor(),'fill':getFontLayoutColor(),'color':getFontLayoutColor()}">
        <div class="akj-tbitem cursor selected-item" id="ele_cursor" v-shortkey="['esc']" @shortkey="on_tool_select({ type: 'Cursor' }, $event)" @click="on_tool_select({ type: 'Cursor' }, $event)">
            <div :style="icon_style('ele_cursor','data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAgMAAAC5h23wAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAxQTFRFAAAATU1NTU1NTU1NwlMHHwAAAAR0Uk5TAOvhxbpPrUkAAAAkSURBVHicY2BgYHBggAByabxg1WoGBq2pRCk9AKUbcND43AEAufYHlSuusE4AAAAASUVORK5CYII=',0.2)"></div>
        </div>
        <div  v-for="(tools, groupName, idx) in toolGroups" :key="groupName">
            <div v-if="tools.length == 1" :class="{'akj-tbitem': true}" v-shortkey="tools[0].shortcut" @shortkey="on_tool_select(tools[0], $event)"  @click="on_tool_select(tools[0], $event)">
              <i :class="{'tools[0].icon' : true, 'div_brightness' :true}"  v-if="!tools[0].svg && tools[0].icon.indexOf('fa fa-')>=0" ></i>
              <svg v-if="tools[0].svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
                <path fill-rule="evenodd" :d="tools[0].icon"/>
              </svg>
              <div v-if="!tools[0].svg && (tools[0].icon.indexOf('fa fa-')<0)" :style="icon_style(tools[0],tools[0].icon,0.2)"></div>
            </div>
          <div v-else-if="groupName=='Lines'" class="akj-tbitem Lines" @mousemove="showLinesMenu" @mouseover="showLinesMenu" @mouseleave="disableLinesMenu">
            <ToolList ref="linesMenu" :chart-handler="chartHandler" @mousemove="showLinesMenu" :hasDeleteSub= "true" :widthMenu="250" :items="toolsByGroup(groupName)" :nameKey="'label'" :icon="groupIcon_style(tools[0],groupName)" :svg="groupIcon_svg(groupName)"  @tool-selected="on_tool_select" />
          </div>
          <div v-else-if="groupName=='patterns'" class="akj-tbitem Patterns" @mousemove="showPatternsMenu" @mouseleave="disablePatternsMenu">
            <ToolList ref="patternsMenu" :chart-handler="chartHandler" class="menu-item-list" :hasDeleteSub= "true"  :widthMenu="300" @mousemove="showPatternsMenu" :items="toolsByGroup(groupName)" :nameKey="'label'" :icon="groupIcon_style(tools[0],groupName)" :svg="groupIcon_svg(groupName)"  @tool-selected="on_tool_select" />
          </div>
        </div>
      <div class="akj-tbitem Delete" v-shortkey="{up: ['backspace'], press: ['del']}" @shortkey="deleteTool($event)"  @click="on_tool_select({type:'Delete'}, $event)" @tool-selected="on_tool_select" ><!--  @click="deleteTool($event)"-->
        <div :style="icon_style('delete',icon_dataURI('trash.png'),0.2)"></div>
      </div>
        <!-- style="position: absolute; bottom: 8px" -->
        <div style="display: flex; flex-direction: column; position: absolute; bottom: 8px">
          <div class="akj-tbitem" v-if="chartHandler.isAdmin && !chartHandler.detach && isViewFound()" @click="loadView()">
            <div :style="icon_style('viewsave',icon_dataURI('load.png'),.2, isViewFound() ? '#cde314':null)"></div>
          </div>
          <div class="akj-tbitem hover" v-if="chartHandler.isAdmin && !chartHandler.detach" v-shortkey="{up: ['meta','s'], down : ['ctrl', 's']}" @shortkey="saveView()" @click="saveView()">
            <div :style="icon_style('view',icon_dataURI('save.png'),.2, isViewFound() ? '#cde314':null)"></div>
            <div class="tooltip" ><div v-html="svgToolTip.svgScreens"></div></div>
          </div>
          <div class="akj-tbitem" v-if="chartHandler.isAdmin"  @shortkey="hideCandles()" @click="hideCandles()">
            <div :style="icon_style('hideCandles',icon_dataURI('visible.png'),0.2)"></div>
          </div>
          <div class="akj-tbitem" v-shortkey="{up: ['meta','c'], down : ['ctrl', 'c']}" @shortkey="resetChart()" @click="resetChart()">
            <div :style="icon_style('resetChart',icon_dataURI('backtocandle.png'))"></div>
          </div>
          <div class="akj-tbitem" v-if="!chartHandler.detach && isAdmin" v-shortkey="{down : ['F1']}" @shortkey="on_OpenAdminSite()" @click="on_OpenAdminSite()">
            <div :style="icon_style('adminPage',icon_dataURI('settings.png'))"></div>
          </div>
            <router-link to="/login" class="btn-link">
                <div class="akj-tbitem" v-if="!chartHandler.detach" @click="goToLogin()">
                    <div :style="icon_style('logout',icon_dataURI('logout.png'))"></div>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import ToolList from "./ToolList.vue";
import Icons from "../../img/icons.json";
import {LayoutColors} from "../../utils/LayoutHandler";
//import akjStuff from "@/helpers/akjStuff.js";
//import API from "@/helpers/fb.js";

export default {
    name: "LeftToolbar",
    props: ['chartHandler'],
    components: { ToolList },

    created() {
        //API.init();
    },

    mounted() {
      const self = this;
        let timer = setInterval(() => {

          if (this.chartHandler) {
            const firstId = this.chartHandler.layoutHandler.getFirstId();
            //console.log(firstId, this.chartHandler.charts[firstId], this.chartHandler.charts[''+firstId]);

            this.tools = this.chartHandler.charts[firstId].data.data.tools;
            //if ((typeof(window.$store) == 'undefined') || window.$store.CHARTS == undefined || window.$store.CHARTS["chart_0"] == undefined || window.$store.CHARTS["chart_0"].chart == undefined) return;
            //this.tools = window.$store.CHARTS["chart_0"].chart.data.tools;
            if (this.tools) {
              clearInterval(timer);

              this.tools.forEach((t, idx) => {
                t.shortcut = ['ctrl', '' + idx];
              });

              //console.log('tools',this.tools);

              this.tools.splice(4,1);

              const result = this.tools.filter((tool) => tool.group);

              const groupresult = result.reduce(function (r, a) {
                r[a.group] = r[a.group] || [];
                r[a.group].push(a);
                return r;
              }, Object.create(null));

              delete groupresult["Indicators"];
              delete groupresult["AUTO FIB"];
              self.groups = groupresult;
            }
          }
        }, 100);
    },

    methods: {
        getBackgroundLayoutColor() {
          return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.backgroundMain);
        },
        getFontLayoutColor() {
          return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorMain);
        },
        isDark() {
          return this.chartHandler.layoutHandler.isDarkMode();
        },
        goToLogin() {
          this.$props.chartHandler.layoutHandler.closeMultiscreen();
          this.$props.chartHandler.saveConfig('chart', false);
          this.$props.chartHandler.setToken(null);
        },
        deleteTool(event) {
          const ae = document.activeElement;
          if (!(ae && ae.classList.contains('nonDeleteShortcut'))) {
            window.chartHandler.deleteCurrentTool();
            if (this.chartHandler.isDrawingMode) {
              this.$parent.on_drawing_mode_off(null, {});
            }
            this.on_tool_select({type :"Cursor"},event);
            //window.chartHandler.deleteTool(window.chartHandler.activeTool);
          } else {
            return true;
          }
          return false;
        },
        saveView() {
          window.chartHandler.saveMultiScreenView();
          this.$emit("open-multiscreen");
        },
        loadView() {
          window.chartHandler.loadMultiScreenView(this.userViews.currentView);
        },
        hideCandles() {
          window.chartHandler.switchCandleVisible();
        },
      showLinesMenu() {
        this.$refs.linesMenu[0].show();
      },
      showPatternsMenu() {
        this.$refs.patternsMenu[0].show();
      },
      disableLinesMenu() {
        this.$refs.linesMenu[0].hidetimer();
      },
      disablePatternsMenu() {
        this.$refs.patternsMenu[0].hidetimer();
      },
      resetChart() {
          const firstId = this.$props.chartHandler.layoutHandler.getFirstId();
          this.chartHandler.chartObjects[this.$props.chartHandler.activeChart].$refs.tvjs.resetChart();

          /* // update fibkeys table
            // need some delay, till reset the chart
            setTimeout(() => {
                window.$store.activeChart.chart.get(`AUTOFIB`).forEach((fib) => {
                    const fibName = fib.name.split("-")[1];
                    document.getElementById(`${fibName}Ratio`).textContent = Number(fib.data.fibRatio).toFixed(4);
                    document.getElementById(`${fibName}Factor`).textContent = Number(fib.data.consolidationFactor).toFixed(4);
                });
            }, 100);*/
        },

        toolReset(selectedCursor) {
          console.log("toolReset toolselect",selectedCursor)
          let iSave = 0;
          while ((iSave < 10) && (this.$el.querySelector(".selected-item"))) {
            this.$el.querySelector(".selected-item").classList.remove("selected-item");
            iSave++;
          }
          if (selectedCursor) {
            this.on_tool_select({type :"Cursor"},null);
            this.$el.querySelector(".cursor").classList.add("selected-item");
          }
        },

        getShortcut(sc) {
          const shortcuts = this.$props.chartHandler.settings.shortcuts;

          //console.log('getShortcut');
          for (let scIdx in shortcuts) {
            //console.log(shortcuts[scIdx], sc);
            if ((shortcuts[scIdx].NAME==sc.name) && (shortcuts[scIdx].SHORTCUT) && (shortcuts[scIdx].SHORTCUT != "") && (shortcuts[scIdx].SHORTCUT != "none")) {
              const shortcut = shortcuts[scIdx].SHORTCUT.split('+');
              //console.log(shortcut);
              return shortcut;
            }
          }
          return [];
        },

        isCursorSelected(orDeleteButton) {
          let addCond = false;
          const selItem = this.$el.querySelector(".selected-item");
          const selItem2 = this.$el.querySelector(".cursor");
          if (orDeleteButton) {
            addCond = selItem == this.$el.querySelector(".Delete");
          }
          return (selItem == selItem2) || addCond;
        },

        drawMouseIcon(ctx, x, y) {
          let sub = "";
          const selItem = this.$el.querySelector(".selected-item");
          if (selItem && selItem.innerHTML) {
            ctx.lineWidth = 1;
            ctx.strokeStyle = "gray";
            //ctx.moveTo(x,y);
            //ctx.lineTo(x+20,y+20);
            if (!this.isDark()) {
              ctx.filter = 'brightness(.1)';
            }
            var image = new Image();
            let pos = selItem.innerHTML.indexOf("data:image");
            if (pos > 0) {
              sub = selItem.innerHTML.substring(pos);
              pos = sub.indexOf("&quot;");
              sub = sub.substring(0, pos);
              image.src = sub;
              ctx.drawImage(image, x, y, 24, 24);
            } else if (selItem.innerHTML.indexOf("<svg")>0) {
              pos = selItem.innerHTML.indexOf(" d=");
              if (pos > 0) {
                sub = selItem.innerHTML.substring(pos+4);
                pos = sub.indexOf("\"");
                sub = sub.substring(0, pos);
                let path = new Path2D(sub); //"M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5… 0 0 11 11h1.5a.5.5 0 0 0 0-1h-1.128L9.979 5.356Z"); //sub
                ctx.translate(x+0.5,y+0.5);
                ctx.stroke(path);
                ctx.translate(-x-0.5,-y-0.5);
              }
            }
            ctx.filter = 'none';
          }
          //console.log('mousedown',sub);
        },

        on_tool_select(tool, event) {
          const selItem = this.$el.querySelector(".selected-item")
          const selItem2 = this.$el.querySelector(".cursor")
          const selItemLines = this.$el.querySelector(".Lines")
          const selItemPatterns = this.$el.querySelector(".Patterns")
          const selItemSubLines = this.$el.querySelector(".selected-subitem")
          if (selItemSubLines) {
            selItemSubLines.classList.remove("selected-subitem");
          }
          if (selItem) {
            this.toolReset(false);
            //selItem.classList.remove("selected-item");
          }
          this.chartHandler.setActiveTool(null,true);
          console.log("onselecttool_click",tool, event, selItem,selItem2,selItem ? selItem.class : "not",selItem ? selItem.className : "not",selItem ? selItem.path : "not")
          /*if (selItem && !((selItem==selItem2) && (tool && tool.type =="Cursor"))) {
            selItem.classList.remove("selected-item");
          }*/
          if (tool && tool.type =="Cursor") {
            if (this.chartHandler.isDrawingMode) {
              this.chartHandler.deleteTool(this.chartHandler.drawingUUID);
              this.$parent.on_drawing_mode_off(null, {});
            } else if (selItem === selItem2) {
              this.chartHandler.setActiveTool(null);
            }
            selItem2.classList.add("selected-item");
          } else if (tool && tool.group =="Lines") {
            const selItemLinesSub = this.$el.querySelector(".Lines_"+tool.name)
            if (selItemLinesSub)
              selItemLinesSub.classList.add("selected-item");
            selItemLines.classList.add("selected-subitem");
          } else if (tool && tool.group =="patterns") {
            const selItemLinesSub = this.$el.querySelector(".Lines_"+tool.name)
            if (selItemLinesSub)
              selItemLinesSub.classList.add("selected-item");
            selItemPatterns.classList.add("selected-subitem");
          } else if (event) {
            if (event.target.classList.contains("akj-tbitem"))
              event.target.classList.add("selected-item");
            else if (event.target.parentElement.classList.contains("akj-tbitem"))
            event.target.parentElement.classList.add("selected-item");
          else
              event.target.parentElement.parentElement.classList.add("selected-item");
          }

          if (tool && tool.type =="Cursor") {
            this.chartHandler.isDrawingModeStart = true;
          }

          this.$emit("tool-selected", tool);
        },

        on_OpenAdminSite() {
          this.$emit("open-adminsite");

        },

        toolsByGroup(group) {
            const res = this.groups[group].filter(function(tool) {
                return tool.icon;
            });
            //console.log('toolsByGroup',res);
            res.forEach((t) => {
              t.templates = [];
              t.isFav = this.chartHandler.favoritesHandler.isFavorite(t.name);
              this.chartHandler.settings.tools.forEach((setting, idx) => {
                if (setting.toolName === t.name) {
                  if (setting.settingName !== 'default') {
                    setting.isFav = this.chartHandler.favoritesHandler.isFavorite(t.name,setting.settingName);
                    t.templates.push(setting);
                  }
                }
            });
          });

          return res;
        },

        icon_dataURI(name) {
          //console.log(name);
            return Icons[name];
        },

        groupIcon_svg(groupName) {
          //console.log(groupName);
          return (groupName == 'Lines') || (groupName == 'patterns')
        },
        groupIcon_style(elementClass, groupName) {
          //console.log(groupName);
          if (groupName == 'Lines')
            return "M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z";
          if (groupName == 'patterns')
            return "M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z";
          return this.icon_style(elementClass, Icons[groupName]);
        },

        hide: function() {
          this.toolReset(true);
          this.isActive = false;
        },
      isViewFound: function () {
        //console.log(this.userViews.currentView);
        return this.userViews.currentView !== "";
      },icon_style: function (elementClass, icon, brightness, color) {
        /*const selItem = null; //this.$el && this.$el. ? this.$el.querySelector(".selected-item") : null;
        if (selItem && selItem.id == elementClass) {
          console.log('icon_style found',icon,elementClass,selItem);
        }
        console.log('icon_style',icon,elementClass);'*/
        let res = {
          "background-image": `url(${icon})`,
          width: "25px",
          height: "25px",
          "background-color": color,
          "background-repeat": "no-repeat",
          "background-size": "contain",
        };
        if (brightness && !this.isDark()) {
          res.filter = `brightness(${(brightness)}) !important`;
        }
        return res;
      },
    },

    data() {
        return {
            tools: [],
            groups: {},
            userViews: this.chartHandler.layoutHandler.userViews,
            svgToolTip: this.chartHandler.layoutHandler.screens,
            isAdmin: this.chartHandler.isAdmin,
        };
    },

    computed: {
        toolGroups: function() {
            return this.groups;
        },




        validTools: function() {
            if (this.tools == undefined) return;
            return this.tools.filter(function(tool) {
                return tool.icon;
            });
        },
    },
};
</script>

<style scoped>
.left_toolbar {
  display: flex;
  margin-top: 3px;
  flex-direction: column;
  _color: #1d1d1d;
  __background: #f2f2f2;
  _background: #079d4d;
  width: 40px;
}
.akj-tbitem {
  display: block;
  _color: #1d1d1d;
  _fill: rgba(80, 80, 80);
  padding: 2px;
  width: 40px;
  margin-left: 23px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 7px;
}

.fa {
  width: 25px;
  height: 25px;
  font-size: x-large;
  background-repeat: no-repeat;
  background-size: contain;
  filter: brightness(.2)

}

.akj-tbitem.selected-item div,
akj-tbitem.toggle_on,
.akj-tbitem.selected-item i {
  _filter: none;
  z-index: 1000;
  _background-color: lightslategray;
}

._div_brightness div {
  filter: brightness(.2);
  z-index: 1000;
}

.akj-tbitem.selected-item,
.akj-tbitem.selected-subitem{
  _fill: rgb(107, 224, 47);
  _color: rgb(107, 224, 47);
  _filter: brightness(1.45) sepia(0.4) hue-rotate(30deg) saturate(100) !important;
  background-color: #6AAB50D7;
  z-index: 1000;
}

#LineTool {
  filter: none;
}

.akj-tbitem:hover {
  background-color: #6AAB50D7;
}


.hover {
}

.tooltip {
  position:absolute;
  top: -100000px;
  background-color: black;

  color: #1d1d1d;_color: white;
  border-radius: 5px;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity .5s ease-in-out;
  -moz-transition: opacity .5s ease-in-out;
  -ms-transition: opacity .5s ease-in-out;
  -o-transition: opacity .5s ease-in-out;
  transition: opacity .5s ease-in-out;
}


.hover:hover .tooltip{
  z-index: 100000;
  transform: translate3d(100px,-200px,0px);
  top: 0px;
  opacity: 1;
}

.hover:hover .sub-text{
  top: 20px;
  opacity: 1;
}

.Lines {
  font-size: x-small;
}


/* .trading-vue-toolbar {
  position: relative !important;
  height: 40px;
  z-index: 100;
  padding-top: 3px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #121826;
  border-bottom: 1px solid #646873;
}

.trading-vue-tbitem > div {
  margin: 0 auto;
}


.trading-vue-tbitem {
  width: 25px;
  height: 29px;
  margin-right: 2px;
  border-radius: 3px;
} */
</style>
