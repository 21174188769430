import Vue from 'vue';
import {ChartServerInterface} from "./ChartServerInterface.js";
import DataCube from "../lib/helpers/datacube.js";
import {LayoutHandler} from "./LayoutHandler";
import {ConfigHandler} from "./ConfigHandler";
import {DaxenHandler} from "./DaxenHandler";
import Utils  from  '../lib/stuff/utils.js';
import {FavoritesHandler} from "./FavoritesHandler";
//import { URL } from 'url';
//import {Uuml} from "../../node_modules_save/acorn-jsx/xhtml";

//const DEFAULT_TIMEFRAME = ['M15', 'D1', 'H4', 'H1'];

export class ChartHandler {
    constructor(token,isAdmin,query) {
        const self = this;
        this.loading = {isLoadingHistoryData : false};
        this.layoutHandler = new LayoutHandler(this, query);
        this.configHandler = new ConfigHandler(this);
        this.daxenHandler  = new DaxenHandler(this);
        this.favoritesHandler = new FavoritesHandler(this);

        this.drawingUUID = "";
        this.isDrawingMode = false;
        this.isDeleteMode = false;


        this.channel = new BroadcastChannel('cs-com-data');
        let screenInfo;
        if (this.channel) {
            this.channel.addEventListener ("message", (event) => {

                if (event.data) {
                    //console.log('1',event.data);
                    if (event.data.action == "mu")
                        self.updateMouseMove(event.data);
                    else if ((event.data.action == "changeSymbol") && ((this.detach) || (event.data.source === "ml"))) {
                        console.log('2',event.data);
                        self.requestHistoryData(event.data.symbol, false);
                    } else if ((event.data.action == "changeSymbol") && ((this.detach) || (event.data.source === "ml"))) {
                        console.log('2',event.data);
                        self.requestHistoryData(event.data.symbol, false);
                    } else if (event.data.action == "screenInfo") {
                        //console.log('addEventListener - message',  event.data, event.data.token)
                        setTimeout(this.layoutHandler.checkScreenInfo.bind(this.layoutHandler),100, self.channel, 'ch');
                    } else if (event.data.action == "updateScreenInfo") {
                        setTimeout(this.layoutHandler.updateScreenInfo.bind(this.layoutHandler),100, event.data);
                    } else if (event.data.action == "heartbeat") {
                        setTimeout(this.layoutHandler.checkHeartbeat.bind(this.layoutHandler),100, self.channel, 'ch');
                    } else if ((event.data.action == "close") && (this.detach)) {
                        window.close();
                    }
                }
            })
        }

        this.messanger = {instanceMessages : []};
        this.remoteInfo = {showRemoteCursor : false, dt: 0, price: -1};
        this.adminData = {alerts: [], filtered: [], search : "", liveVideos : []}

        this.showHint = false;
        this.isAdmin = isAdmin;

        let loggedIn = JSON.parse(localStorage.getItem("user"));
        this.redirectMode = loggedIn && loggedIn.mode ? loggedIn.mode : "";
        this.user = loggedIn && loggedIn.user ? loggedIn.user : "";
        this.userData = {user:this.user, email:loggedIn && loggedIn.email ? loggedIn.email : ""};
        this.isApp = (this.redirectMode != "") && (this.redirectMode != "detach")

        console.log('token',token, loggedIn);

        this.rights = {info : null, useIndicators : this.isAdmin==true, useSymbols : this.isAdmin==true, useLayout : this.isAdmin==true, useTemplates : this.isAdmin==true, useSaveCharts : this.isAdmin==true, canPublish : this.isAdmin==true, useMultiScreen: this.isAdmin==true, useUserRights: false, abos: loggedIn ? loggedIn.abos : []};

        this.rights.abos.forEach((a) => {
           a.state = a.rel_id_user > 0 ? 1 : 0;
           console.log(a);
        });

        this.userInformations = {count: 0, data: []};

        /*
        if (window && window.location && window.location.href && ((window.location.href.indexOf('localhost')>0) || (window.location.href.indexOf('ki-trading.de')>0)))

            this.chartServerInterface = new ChartServerInterface(this, 'http://localhost:7701', 'https://chart-software.com/api', 'wss://chart-software.com/wsapi', 'wss://as01.chart-software.com/md','as01', true, 1000);
        else */ if (window && window.location && window.location.href && ((window.location.href.indexOf('uat.chart-software')>0) ))
            this.chartServerInterface = new ChartServerInterface(this, 'https://uat.chart-software.com', 'https://uat.chart-software.com/api', 'wss://uat.chart-software.com/wsapi', 'wss://as01.chart-software.com/md', 'uat', false, 1);
        else if (window && window.location && window.location.href && ((window.location.href.indexOf('uat-slim.chart-software')>0) ))
            this.chartServerInterface = new ChartServerInterface(this, 'https://uat-slim.chart-software.com', 'https://uat-slim.chart-software.com/api', 'wss://uat-slim.chart-software.com/wsapi', 'wss://as01.chart-software.com/md', 'uat-slim', false, 1);
        else
            this.chartServerInterface = new ChartServerInterface(this, 'https://chart-software.com', 'https://chart-software.com/api','wss://chart-software.com/wsapi','wss://as01.chart-software.com/md' , 'as01', true, 1);

        this.timeframeHistory = [(query && query.timeframe) ? query.timeframe : 'M15', 'D1', 'H4', 'H1'];
        this.timeframe = [];
        //console.log(window.location.href);
        this.loadQuerySymbol = (query && query.symbol);
        this.detach = (query && query.detach) ? query.detach : false;
        this.displayData = {prec: 2, symbol : (this.isAdmin || this.detach) ? ((query && query.symbol) ? query.symbol : '') : '', marketLists : [], marketListsLayout: []};
        if (this.displayData.symbol.indexOf('/')>=0) {
            this.timeframeHistory[0] = this.displayData.symbol.substr(this.displayData.symbol.indexOf('/')+1);
            Vue.set(this.displayData,'symbol',this.displayData.symbol.substr(0, this.displayData.symbol.indexOf('/')));
        }
        //this.displayData.symbol = (query && query.symbol) ? query.symbol : 'ETHUSDT.BINANCE';
        //console.log(this.displayData.symbol);
        this.charts =  {};
        this.chartObjects = [null,null,null,null,null,null,null,null,null,null,null,null];
        this.activeChart = 0;
        this.activeTool = null;
        this.metaData = {};
        //this.watchLists = {data:[]};
        this.contextMenu = {menu:[]};
        this.showCfgList = [];
        this.liveCharting = {user: "", active : false, lastDeleteToolId : "", liveChartId : 0, sendDataPackets: 0, sendDataPacketsSaved: 0, loadedChartId: -1, liveAnalysis: [], updatedAnalysis : {loadedId : -1, id:0}};
        this.loadedChart = {chartId : 0, lastUpdate : "", firstLastViewRequestId: "-"};
        this.canUpdateMarketList = false;
        this.settings = {showTrendType : "Alle",showTrendId : -1, showTrendsOnlyActive: 1,viewDefaultlen: null, showCandles: true, 'displayIndex':-1, 'displayList': [], 'marketList': [], 'favourites': [], "templates" : [], "shortcuts" : [], "showHint": false, tools : []};
        this.chartSettings = {isAutoSave : true, bitMaskExtreams: 63, isPinModifyPanel : false, pinModifyPanelPos: [100,100], isMagnetMode: true, isGrid: true, isAutoHori: true, isAutoFibs : false, isHighLows: true, isAutoLines: true, isAutoPriceGaps: true,isIndexBased: true,defaultLen: 100, theme : "dark", logScale: true, currentMO: "DAX"};
        this.storages = new Map();
        this.users = {users: [], rights: [], userRights: []},
        this.hintInfos = {};
        this.lastWheel = 0;
        this.timeFrames = [
            {value: 'M1'},
            {value: 'M15'},
            {value: 'M30'},
            {value: 'H1'},
            {value: 'H4'},
            {value: 'D1'},
            {value: 'W1'},
            {value: 'MN'}
        ];
        this.themes = {
            white: {
                name : "White Theme",
                colorCandleDw: "#ff1515",
                colorWickDw: "#ff1515",
                colorVolDw: "rgba(185,30,30,0.34)",
                colorCandleUp: "#fafafa",
                colorWickUp: "#000",
                colorCandleUpText: "#111",
                colorVolUp: "rgba(34,143,26,0.3)",
                colorBack: '#ffffff',
                colorGrid: 'rgba(27,31,44,0.29)',
                colorText: 'rgba(27,31,44,0.91)',
                colorTitle: 'rgba(27,31,44,0.8)',
            },
            dark : {
                name : "Dark Theme",
                colorCandleDw: "#d20000",
                colorWickDw: "rgba(255,255,255,0.7)",
                colorVolDw: "rgba(182,13,13,0.32)",
                colorCandleUp: "rgb(222,222,222)",
                colorWickUp: "#ececec",
                colorCandleUpText: "#fdfdfd",
                colorVolUp: "rgba(8,131,0,0.16)",
                colorBack: '#191f2b',
                colorGrid: 'rgba(210,210,210,0.27)',
                colorText: '#eee',
                colorTitle: '#eee',
                showCandles: false,
            }/*,
            orange : {
                name : "Orange Theme",
                colorCandleDw: "#F07831",
                colorWickDw: "#F07831",
                colorVolDw: "#F0783188",
                colorCandleUp: "#1b1f2c",
                colorWickUp: "#1b1f2c",
                colorCandleUpText: "#1b1f2c88",
                colorVolUp: "#1b1f2c44",
                colorBack: '#eee',
                colorGrid: 'rgba(27,31,44,0.3)',
                colorText: '#1b1f2c',
                colorTitle: '#1b1f2c',
                showCandles: false,
            },
            darkorange : {
                name : "Dark Orange Theme",
                colorCandleDw: "#eee",
                colorWickDw: "#eee",
                colorVolDw: "#e0e0e088",
                colorCandleUp: "#F07831",
                colorWickUp: "#F07831",
                colorCandleUpText: "#333",
                colorVolUp: "#F0783188",
                colorBack: '#1b1f2c',
                colorGrid: 'rgba(210,210,210,0.27)',
                colorText: '#eee',
                colorTitle: '#eee',
                showCandles: false,
            }*/
        };
        /*

        default:
            COLORS.colorBack = '#fff';
            COLORS.colorGrid = '#eee';
            COLORS.colorText = '#333';
            COLORS.colorTitle = '#333';

            break;
        }
        console.log('colors',this.$props.theme, COLORS);
        return COLORS;
    }};*/
        this.currentTheme = {colorCandleUp:'#123'};

        this.idx = 0;

        this.fullChartData = new Map();

        this.indicators = {};

        this.setToken(token);
        this.setLayout(this.layoutHandler.activeLayout, true);

        this.requestIndicators();
        if (!this.isApp) {
            this.requestMarketList();
            this.requestUserFavorites();
            this.requestSettings();
            this.requestTemplates();
            this.requestWatchList();
            this.requestShortcuts();
        }
        this.updateTheme();
        this.updateContextMenue();

        if (this.isAdmin && !this.isApp) {
            this.requestUsers();
            this.requestAlerts(0, 100);
        }

        const dt = new Date();
        // const timeOffset = (390 + dt.getTimezoneOffset()) * 60 * 1000; // FIX for real-time candle creating issue
        // const timeOffset = 60 * 1000 * 60;
        const timeHours = 0; //dt.getTimezoneOffset();
        this.timeOffset = timeHours * 60 * 1000;


        //if (query && query.fullscreen) {

        //console.log('fullScreenGlobal 0');

            //if (window.confirm("Do you really want to leave?")) {
                //window.open("exit.html", "Thanks for Visiting!");
            //    setTimeout(this.fullScreen.bind(this), 5000);
            //}

        //}
    }

    setStorage(key, data) {
        console.log('setStorage', key, data.length);
        this.storages.set(key, data);
    }

    getStorage(key) {
        console.log('getStorage', key);
        return this.storages.get(key);
    }

    setChart(chart, id) {
        this.chartObjects[id] = chart;
        //console.log(chart, id);
    }

    reInitNew(chartId) {
        console.log('new instrument setTimeFrame');
        //  this.charts[chartId].data.initNew(true);

        //if (this.chartObjects[chartId] && this.chartObjects[chartId].$refs && this.chartObjects[chartId].$refs.tvjs)
        //    this.chartObjects[chartId].$refs.tvjs.resetChart(true);
        //else
        this.charts[chartId].data.initNew(false);
        this.chartObjects[chartId].$refs.tvjs.resetChart(false);

        //  this.chartObjects['' + chartId].$refs.tvjs.resetChart(false);
        //  Vue.set(this.loading,'isLoadingHistoryData',false);
    }

    setLoadedChartId(chartId) {
        //console.log('setLoadedChartId',chartId)
        this.liveCharting.loadedChartId = chartId;
        this.liveCharting.updatedAnalysis.loadedId = chartId;
    }

    setTimeFrame(chartId, timeframe){
        //console.log('new instrument setTimeFrame');
        //this.charts[chartId].data.initNew(true);

        if (this.timeframe[parseInt(chartId)]) {
            this.timeframeHistory[parseInt(chartId)] = timeframe;
            this.timeframe[parseInt(chartId)] = timeframe;
            //this.timeframe.forEach()
            //console.log('setTimeframe', timeframe,this.timeframe[parseInt(chartId)] );
            Vue.set(this.charts[chartId],'tf', timeframe);
            //console.log('setTimeframe', this.charts[chartId]);
            if (!(this.charts[chartId] &&
                    this.charts[chartId].data &&
                    this.charts[chartId].data.tv &&
                    this.charts[chartId].data.tv.$refs.chart)) {
                console.log('reset refs chart!!!');
                this.resetChartObjects();
                //Vue.set(this.charts[chartId].data.tv.$refs,'chart',this.chartObjects[''+chartId].$refs.tvjs.$refs.chart);
            }
            this.clearIndicators(chartId, false);

            //this.charts[chartId].data.data.drawingMode = false;
            //Vue.set(this.charts[chartId],'fibData', {FibX : {}, Fib1 : {}, Fib2 : {}});
            if (this.displayData.symbol)
                this.chartServerInterface.requestHistory(this.displayData.symbol, timeframe, null, null, 100);
        }
    }

    setToken(token) {
        //console.log("setToken",token)
        this.chartServerInterface.setToken(token);

        if (this.channel && !this.detach)
            this.channel.postMessage({action : "loginInfo", token : token})
    }

    setLayout(item, bRequest) {
        let tempArray = [];
        const self = this;
        const prevCount = this.timeframe.length;

        this.layoutHandler.setActiveLayout(item.id);
        const count = this.layoutHandler.getChartCount();

        tempArray = this.timeframe;
        //console.log('setLayout',count, prevCount)

        if (prevCount > count) {
            while (count < tempArray.length) {
                //console.log('setLayout',this.timeframe[this.timeframe.length-1],this.charts);
                Vue.delete(this.charts, tempArray.length-1);
                tempArray.pop();
            }
        }
        else if (prevCount < count) {
            tempArray = this.timeframeHistory.slice(0,count);
            tempArray.forEach((tf, idx) => {
                if (idx >= prevCount) {
                    Vue.set(self.charts, idx, self.getChartDefinition(tf, idx));
                    //console.log('new def',idx,tf,this.charts[idx]);
                }
            })
        }

        tempArray.forEach((c, idx) => {
            Vue.set(self.timeframe, idx, c);
        });
        this.updateFullLayout();
        if (bRequest && (prevCount < count))
            this.requestHistoryData(this.displayData.symbol);

    }

    getThemes() {
        const self = this;
        let res = [];
        Object.keys(this.themes).forEach((t) => {
            res.push({name:t, theme:self.themes[t]});
        });
        return res;
    }

    updateFullLayout() {
        const self = this;
        this.timeframe.forEach((c, idx) => {
            self.updateLayout(idx);
        });
    }

    updateMarketListSelected(index) {
        const id = parseInt(index);
        if (!isNaN(id) && (id>=-1) && (id<this.settings.displayList.length) && (id!==this.settings.displayIndex)) {
            this.settings.displayList.forEach((p) => {
                p.selected = (p.id == id);
                //if (p.selected)
                //    console.log(id, p.name)
            });
            Vue.set(this.settings,'displayIndex',id);
        }
    }

    updateMarketList(searchTextOrg) {
        if (searchTextOrg === -1) {
            this.canUpdateMarketList = true;
            searchTextOrg = "";
        }
        const sizeVisible = 150;
        const searchText = searchTextOrg.toUpperCase();
        let counter = 0;
        this.settings.displayList.splice(0,this.settings.displayList.length);

        if (this.canUpdateMarketList) {

            const len = this.settings.favourites.length - 1
            for (let i in this.settings.favourites) {
                const f = this.settings.favourites[len - i];
                //console.log(f.name, searchText);
                if ((counter < sizeVisible) && ((f.name.indexOf(searchText) == 0) || (f.name.indexOf("^" + searchText) == 0) || (('caption' in f) && (f.caption.toUpperCase().indexOf(searchText) >= 0)))) {
                    f.selected = this.displayIndex == counter;
                    f.id = counter++;
                    this.settings.displayList.push(f);
                } /*else if (counter >= 15) {
                   console.log(0, counter);
                   break;
               }*/
            }
            //console.log('displayList', "+" + searchText + "+")
            if (searchText != "") {
                for (let i in this.settings.marketList) {
                    const f = this.settings.marketList[i];
                    if ((counter < sizeVisible) && ((f.name.indexOf(searchText) == 0) || (f.name.indexOf("^" + searchText) == 0) || (('caption' in f) && (f.caption.toUpperCase().indexOf(searchText)) >= 0))) {
                        f.selected = this.displayIndex == counter;
                        f.id = counter++;
                        this.settings.displayList.push(f);
                    } /*else if (counter >= 15) {
                       console.log(1, counter);
                       break;
                   }*/
                }
            }
        }
        //console.log(this.settings.displayList);
    }

    updateLayout(idx) {
        const key = ''+idx;
        const layout = this.layoutHandler.getLayout(idx)
        //console.log(idx, key, layout, this.charts[key].layout,this.timeframe.length);
        if (this.charts[key] && !this.charts[key].layout)
            Vue.set(this.charts[key],'layout', layout);
        else {
            Vue.set(this.charts[key].layout,'x', layout.x);
            Vue.set(this.charts[key].layout,'y', layout.y);
            Vue.set(this.charts[key].layout,'h', layout.h);
            Vue.set(this.charts[key].layout,'w', layout.w);
        }
    }

    deleteCurrentTool() {
        if (this.isDrawingMode)
            this.deleteTool(this.drawingUUID);
        else
            this.deleteTool(this.activeTool);
    }

    deleteTool(tool, noSendLiveChart) {
        console.log('deleteTool',tool, noSendLiveChart)
        const self = this;
        if ((this.chartObjects['0']) && (this.chartObjects['0'].$refs) && (this.chartObjects['0'].$refs.tvjs)) {
            const i = this.chartObjects['0'].$refs.tvjs.$refs.chart;
            i.$children.forEach((c)=>{
                //console.log('add_ind_c',c.id);
                c.$children.forEach((sc)=>{
                    //console.log('deleteTool_sc',sc, sc.id,tool);
                    sc.$children.forEach((ssc)=>{
                        //console.log('deleteTool_sc',ssc, sc, sc.id,tool);
                        if (tool && ssc.id && ssc.settings && ssc.settings.$uuid && tool.length && ssc.id.length) {
                            if (ssc.settings.$uuid == tool) {
                                if (self.isSendLiveChart() && (!noSendLiveChart) && ssc.settings) {
                                    this.timerToSendLiveData();
                                    this.timerToSendLiveZoom();
                                    self.sendLiveChart(ssc.id,ssc.name,ssc.settings.liveId ,ssc.settings, true, 'deleteTool');
                                }
                                if (ssc.id.indexOf('DrawingLine_s')>=0) {
                                    Vue.set(ssc.settings,'deleted',true);
                                }
                                else {
                                    if (noSendLiveChart) {
                                        this.delIndicator(("onchart."+ssc.id).replace("_", ""));
                                        ssc.$selected = true;
                                    } else
                                        ssc.remove_tool(noSendLiveChart);
                                }
                            }
                        }
                    });
                });
            });
            this.setActiveTool(null, true);
        }
        /*
        this.chartObjects.forEach((c) => {
            if (c) {
                let deleted = null;
                c.dc.data.onchart.forEach((t) => {
                    //console.log('deleteToolcheck',tool,t.settings,t.id);
                    if (t.settings && (t.settings.$uuid == tool)) {
                        console.log('deleteTool',tool,t.settings.$uuid ,t.id);
                        //deleted = c.data.del(t.id);
                        t.$emit('remove-tool');
                    }
                })
                //if (deleted)
                    console.log('deleteTool',deleted);
            }
        })*/
        //this.saveConfig('chart', false);
    }

    isSendLiveChart() {
        return  this.isAdmin &&
                this.liveCharting.active &&
                this.chartObjects["0"] &&
                this.chartObjects["0"].$parent &&
                this.chartObjects["0"].$parent.$parent &&
                this.chartObjects["0"].$parent.$parent.$parent &&
                this.chartObjects["0"].$parent.$parent.$parent.isLiveOnlineId>0
                this.chartObjects["0"].$parent.$parent.$parent.isLiveOnline;
    }

    sendLiveChartIndicator(ind, updatePoints, updateSettings) {
        if (this.isSendLiveChart() &&
            ind &&
            ind.id &&
            ind.settings) {
            ind.settings.liveId = ind.settings.liveId || Utils.uuid2();

            const indCfg = ind; //this.chartObjects["0"].$parent.$parent.$parent.indCfg.indicatorInstance;

            const indId = 'onchart.'+ind.id.replace("_","");
            const indType = ind.id.substring(0,ind.id.indexOf("_"));


            let settings = ind.settings;

            if (indId.indexOf("DrawingLines")>=0) {
                let idx = 0;
                while (ind.points['p' + (++idx)]) {
                    settings['p' + idx] = ind.points['p' + idx];
                    //console.log("add_ind_0",'p' + idx, ind.points,ind.settings['p' + idx] );
                }
            }
/*
            if (updateSettings) {
                //console.log(ind.points);
                let idx = 0;
                while (ind.points['p' + (++idx)]) {
                    if (ind.settings)
                        ind.settings['p' + idx] = [this.getIndexFromTime("0", ind.points['p' + idx][0]), ind.points['p' + idx][1]];
                    //console.log("add_ind_0",'p' + idx, ind.points,ind.settings['p' + idx] );
                }
            }
            if (updatePoints) {
                ind.points = {};
                let idx = 0;
                while (ind.settings['p' + (++idx)]) {
                    ind.points['p' + idx] = [this.getTimeFromIndex("0", ind.settings['p' + idx][0]), ind.settings['p' + idx][1]];
                }
            }
*/
            if (this.liveCharting.dataToSend && this.liveCharting.dataToSend.length && (this.liveCharting.dataToSend[0] == indId) && (Date.now() - this.liveCharting.dataToSend[4] < 150)) {
                this.liveCharting.dataToSend[0] = indId;
                this.liveCharting.dataToSend[1] = indType;
                this.liveCharting.dataToSend[2] = indCfg.settings.liveId;
                this.liveCharting.dataToSend[3] = settings;
                this.liveCharting.dataToSend[4] = 'sendLiveChartIndicator';
                this.liveCharting.sendDataPacketsSaved++;
            } else {
                this.timerToSendLiveData();
                this.liveCharting.dataToSend = [indId,indType,indCfg.settings.liveId, settings, Date.now(),'sendLiveChartIndicator'];
            }
        }
    }

    setLiveChartZoom(from, to) {
        if (this.isSendLiveChart()) {
            if (!this.liveCharting.dataToZoom || (this.liveCharting.dataToZoom.length && (Date.now() - this.liveCharting.dataToZoom[0] > 100))) {
                this.timerToSendLiveZoom(false);
                this.liveCharting.dataToZoom = [Date.now(), 'new'];
            }
        }
    }

    setLiveChartMouse(price, dt) {
        if (this.isSendLiveChart()) {
            if (this.liveCharting.dataToMouse && this.liveCharting.dataToMouse.length && (Date.now() - this.liveCharting.dataToMouse[2] < 100)) {
                this.liveCharting.dataToMouse[0] = price;
                this.liveCharting.dataToMouse[1] = dt;
            } else {
                this.timerLiveChartMouse();
                this.liveCharting.dataToMouse = [price, dt, Date.now()];
            }
        }
    }

    timerToSendLiveData() {
        this.timerToSendLiveZoom();
        if (this.liveCharting.dataToSend && this.liveCharting.dataToSend.length && this.liveCharting.dataToSend[0]!=="send") {
            //console.log('sendLiveChart #live 2',this.liveCharting.dataToSend[0],this.liveCharting.dataToSend[1],this.liveCharting.dataToSend[2]);
            this.sendLiveChart(this.liveCharting.dataToSend[0],this.liveCharting.dataToSend[1],this.liveCharting.dataToSend[2],this.liveCharting.dataToSend[3], false,this.liveCharting.dataToSend[4]);
            this.liveCharting.dataToSend[0] = "send";
            //console.log('timerToSendLiveData', ++this.liveCharting.sendDataPackets, this.liveCharting.sendDataPacketsSaved);
        }
    }

    timerToSendLiveZoom(force) {
        if (force || (this.liveCharting.dataToZoom && this.liveCharting.dataToZoom[1] !== "send")) {
            this.sendLiveChartZoom(this.chartObjects["0"].$parent.$parent.$parent.isLiveOnlineId, this.getVisibleBars(0), this.getRightSpace(0), this.user);
            if (this.liveCharting.dataToZoom && this.liveCharting.dataToZoom.length)
                this.liveCharting.dataToZoom[1] = "send";
        }
    }

    timerLiveChartMouse() {
        if (this.liveCharting.dataToMouse && this.liveCharting.dataToMouse.length && this.liveCharting.dataToMouse[0]!=="send") {
            this.sendLiveChartMouse(this.chartObjects["0"].$parent.$parent.$parent.isLiveOnlineId, this.liveCharting.dataToMouse[0],this.liveCharting.dataToMouse[1]);
            this.liveCharting.dataToMouse[0] = "send";
        }
    }

    sendLiveViewReset() {
        this.sendLiveViewReset(this.chartObjects["0"].$parent.$parent.$parent.isLiveOnlineId);
    }

    getToolPos(tool) {
        let res = [null,null];
        const self = this;
        console.log('getToolPos_0',this.chartObjects['0']);
        if ((this.chartObjects['0']) && (this.chartObjects['0'].$refs) && (this.chartObjects['0'].$refs.tvjs)) {
            const i = this.chartObjects['0'].$refs.tvjs.$refs.chart;
            i.$children.forEach((c) => {
                //console.log('getToolPos_1',c.id);
                c.$children.forEach((sc) => {
                    //console.log('deleteTool_sc',sc.id,tool);
                    sc.$children.forEach((ssc) => {
                        if (('onchart.'+ssc.id).replace("_","") == tool) {
                            //console.log('getToolPos_2', ('onchart.' + ssc.id).replace("_", ""), tool, ssc);
                            if (ssc.getPosModifyPanel) {
                                let pos = ssc.getPosModifyPanel();
                                res[0] = pos.x;
                                res[1] = pos.y;
                            } else if (ssc.layout) {
                                let up = true;
                                let x = ssc.layout.t2screen(ssc.p1[0]);
                                let y = ssc.layout.$2screen(ssc.p1[1]);
                                if (ssc.p2) {
                                    up = y > ssc.layout.$2screen(ssc.p2[1]);
                                    x = (x + ssc.layout.t2screen(ssc.p2[0])) / 2;
                                    y = (y + ssc.layout.$2screen(ssc.p2[1])) / 2;
                                }
                                res[0] = x+75;
                                res[1] = y+(40*(up?1:-2));
                            }
                        }
                    });
                });
            });
        }
        return res;
    }

    duplicateTool(tool) {
        if (tool) {
            this.chartObjects.forEach((c) => {
                if (c) {
                    const t = c.data.get(tool);
                    if (t) {
                        console.log(JSON.stringify(t));
                        let newTool = JSON.parse(JSON.stringify(t));
                        let side = tool.indexOf('offchart')>=0 ? 'offchart' : 'onchart';
                        //delete newTool[0].name;
                        delete newTool[0].id;
                        newTool[0].settings.$selected = false;
                        newTool[0].settings.liveId = Utils.uuid2();
                        newTool[0].settings.$uuid = `${side}.${newTool[0].type}-${Utils.uuid2()}`;
                        t.$selected = false;
                        const toolId = c.data.add(side, newTool[0]);
                        this.setActiveTool(toolId);
                    }
                }
            })
        }
    }

    setActiveTool(tool, resetDeleteNotAllowed) {
        //console.log('setActiveTool',tool, this.isDeleteMode);
        let indCfg = null;
        let side = tool && tool.indexOf("offchart") == 0 ? "offchart" : "onchart";
        this.activeTool = tool;
        this.charts["0"].data.data[side].forEach((i) => {
            if(i.settings) {
                i.settings.$selected = i.settings && tool && ((i.settings.$uuid == tool) || (i.id == tool));
                if (i.settings.$selected) {
                    indCfg = {settings: this.configHandler.getConfigFieldTemplates(i.name), indicatorInstance : i};
                }
            }
            if ((this.chartObjects["0"].$parent.$parent.$parent.isLiveOnlineId>0) && this.isAdmin && this.liveCharting.active && i.settings && (i.settings.$selected)) {
                i.settings.liveId = i.settings.liveId || Utils.uuid2();
                //console.log('sendLiveChart #live 1',i.id,i.name,i.settings.liveId,tool);
                let settings = i.settings;

                if (i.name == 'DrawingLines') {
                    const ch = this.chartObjects['0'].$refs.tvjs.$refs.chart;
                    ch.$children.forEach((c) => {
                        //console.log('add_ind_c',c.id);
                        c.$children.forEach((sc) => {
                            //console.log('add_ind_sc',sc.id);
                            sc.$children.forEach((ssc) => {
                                //console.log('add_ind_ssc',ssc.id,i.name);
                                if (i.id && ssc.id && i.id.length && ssc.id.length) {
                                    const indName = ssc.id.replace("_", "") + "§";
                                    if ((i.id+"§").indexOf(indName) >= 0) {
                                        //console.log('add_ind_ssc', ssc.id, i.id,indName);
                                        if (ssc.points && Object.keys(ssc.points).length) {
                                            Object.keys(ssc.points).forEach((key) => {
                                                settings[key] = ssc.points[key];
                                            });
                                        }
                                    }
                                }
                            });
                        });
                    });
                }
                this.sendLiveChart(i.id,i.name,i.settings.liveId ,settings,false, 'setActiveTool');
            }
        });
        this.chartObjects["0"].$parent.$parent.$parent.indCfg = indCfg;
        if (this.isDeleteMode) {
            console.log('isDeleteMode',tool);
            if (tool != null) {
                this.deleteCurrentTool();
            } else if (!resetDeleteNotAllowed) {
                this.isDeleteMode = false;
                this.chartObjects["0"].$parent.$parent.$parent.cancelDrawingAndReset();
                this.chartObjects["0"].$parent.$parent.$parent.$refs.leftbar.toolReset(true);
            }
        }
    }

    setActiveChart(idx) {
        //console.log('setActiveChart',idx)
        this.activeChart = ''+idx;

        let h = 0;
        const ele = document.getElementById('0-grid-0-canvas');
        if (this.chartObjects[this.activeChart]) {
            if (ele)
                h = this.chartObjects[this.activeChart].height - parseInt(ele.style.height.replace('px', '')) + 10;
            else
                h = this.chartObjects[this.activeChart].height + 10;
        }
        if (this.charts[this.activeChart])
            this.charts[this.activeChart].fibHeight = h;
    }

    addIndicator(indicator) {
        const self = this;
        let ind = JSON.parse(JSON.stringify(indicator));
        ind.type = ind.type || ind.name;
        if (!ind.type || (ind.type.indexOf("undefined")>=0)) {
            return
        } else {
            ind.data = ind.data || [];
            console.log(ind, 'group' in ind, ind.settings);
            if (!ind.settings)
                ind.settings = {};
            //console.log(ind);
            if (!ind.side) {
                if (ind.id && (ind.id.indexOf('offchart') >= 0)) {
                    ind.side = 'offchart';
                } else {
                    if (ind.id && (ind.id.indexOf('onchart')) >= 0) {
                        ind.side = 'onchart';
                    } else if ((ind.type == 'ATR') ||
                        (ind.type == 'RSI') ||
                        (ind.type == 'Momentum')) {
                        ind.side = 'offchart';
                    } else
                        ind.side = 'onchart';

                }
            }
            ind.settings.$uuid = `${ind.type}-${ind.side}-${Utils.uuid2()}`;
            ind.settings.id = ind.settings.$uuid;
            ind.showInCfg = ind.showInCfg || true;
            ind.settings.liveId = ind.settings.liveId;
            /*if (ind.name == 'DrawingLines') {
                //ind.settings.points = ind.data || [];
                //window.chartHandler.setStorage(ind.settings.$uuid, ind.settings.points);
                ind.data = [];
            }*/


            if (ind.points) {
                //console.log(ind.points);
                let idx = 0;
                while (ind.points['p' + (++idx)]) {
                    if (ind.settings)
                        ind.settings['p' + idx] = [this.getIndexFromTime("0", ind.points['p' + idx][0]), ind.points['p' + idx][1]];
                    //console.log("add_ind_0",'p' + idx, ind.points,ind.settings['p' + idx] );
                }
                //if (ind.points.p1) ind.settings.p1 = [this.getIndexFromTime("0",ind.points.p1[0]),ind.points.p1[1]];
                //if (ind.points.p2) ind.settings.p2 = [this.getIndexFromTime("0",ind.points.p2[0]),ind.points.p2[1]];
                //if (ind.points.p3) ind.settings.p3 = [this.getIndexFromTime("0",ind.points.p3[0]),ind.points.p3[1]];
                //if (ind.points.p1) {
                //    console.log("add_ind_0",ind.name,ind.points.p1[0],ind.points.p2[0], ind.settings.p1[0], ind.settings.p2[0]);
                //}
            } else if (ind.settings) {
                ind.points = {};
                let idx = 0;
                while (ind.settings['p' + (++idx)]) {
                    ind.points['p' + idx] = [this.getTimeFromIndex("0", ind.settings['p' + idx][0]), ind.settings['p' + idx][1]];
                }
                //if (ind.settings.p1) ind.points.p1 = [this.getTimeFromIndex("0",ind.settings.p1[0]),ind.settings.p1[1]];
                //if (ind.settings.p2) ind.points.p2 = [this.getTimeFromIndex("0",ind.settings.p2[0]),ind.settings.p2[1]];
                //if (ind.settings.p3) ind.points.p3 = [this.getTimeFromIndex("0",ind.settings.p3[0]),ind.settings.p3[1]];

            }
            console.log("addind_1",ind.name);
            if ('group' in ind)
                delete ind.group;
            if (ind.name == 'BollingerBands') return;
            //ind.name = ind.name+`#-${Utils.uuid2()}`;
            //console.log('add ind',ind);
            ind.showInCfg = true;
            /*if (indicator.parameter) {
                indicator.parameter.forEach((p) => {
                    if (name == ind.name)
                        name += ' [';

                    name += '25';
                });
                if (name !== ind.name) {
                    name += ']';
                    ind.name = name;
                }
            }*/
            /*"{"indicators":[{"name":"EMA","liveId":"29870d0cb3e3","type":"EMA","points":{}," +
            ""settings":{"$uuid":"EMA-3ce0892fcbb8","length":12,"$props":["length"],"$selected":null,"liveId":"29870d0cb3e3"},"id":"onchart.EMA0","data":[]},{"name":"EMA","liveId":"b94283464761","type":"EMA","points":{}," +
            "" +
            ""settings":{"length":"25","$uuid":"EMA-onchart-0e7de60959be","id":"EMA-onchart-0e7de60959be","liveId":"b94283464761","legend":true,"$selected":null,"$props":["length"],"color":"#00e5ff"},"id":"onchart.EMA1","data":[]}]" +
            "" +
            "" +
            ","visibleBars":103,"rightSpace":5}"*/
            if (self.charts[self.activeChart] && self.charts[self.activeChart].data) {
                self.addInd(ind, self.activeChart);
                //ind.id = self.charts[self.activeChart].data.add(ind.side, ind);
                //    self.updateFiboPos(self.activeChart);
                //setTimeout(self.simulateClick.bind(this), 50, self.activeChart);  // do something cool
                //})
            }
        }
    }

    addInd(ind, ac) {
        console.log('addind-0 removeIndicator',ind);
        const self = this;
        ind.id = self.charts[ac].data.add(ind.side, ind);
        //console.log('addind-1',ind.id,self.charts[ac]);


        self.charts[ac].data.merge(ind.id + ".settings", {
            legend: this.isPriceLineIndicator(ind.name),
        });

        let cfg = {};
        Object.keys(ind.settings).forEach((sk) => {
            cfg[sk] = ind.settings[sk];
        });
        /*if (ind.fieldsTemplate) {
            Object.keys(ind.fieldsTemplate).forEach((sk) => {
                cfg[sk] = cfg[sk] || ind.fieldsTemplate[sk];
            });
        }*/
        //cfg.lineColor = '#258457';
        //cfg.color = cfg.color || '#258457';
        console.log('addInd settings', cfg);
        self.charts[ac].data.merge(ind.id + ".settings", cfg);


        self.updateFiboPos(ac);
        self.simulateClick(ac);  // do something cool
        //console.log(this.chartObjects[0].$parent.$parent.$parent);
        setTimeout(
            this.chartObjects[0].$parent.$parent.$parent.onLabelEvent.bind(this),
            10,
            {action: {type:"addInd", id: ind.id, labelId: ind.labelId}});
        //setTimeout(this.simulateClick.bind(this), 100, ac);
    }

    updateFiboPos(chartId, reset=false){
        const self = this;
        if (this.chartObjects[''+chartId] &&
            this.chartObjects[''+chartId].$refs &&
            this.chartObjects[''+chartId].$refs.tvjs &&
            this.chartObjects[''+chartId].$refs.tvjs.$refs &&
            this.chartObjects[''+chartId].$refs.tvjs.$refs.chart) {
            let range = this.chartObjects['' + chartId].$refs.tvjs.$refs.chart.range;
            setTimeout(
                this.chartObjects[chartId].updateFibPos.bind(this),
                10);
            setTimeout(this.resetAndRerangeChart.bind(this), 50, chartId, reset, range);
        }
    }

    resetAndRerangeChart(chartId, reset, range) {
        //console.log('reset - resetAndRerangeChart');
        this.setActiveChart(chartId);
        this.setActiveTool(null);
        this.chartObjects[chartId].updateFibHeight();
        //this.chartObjects[chartId].$refs.tvjs.resetChart(reset);
        //if (reset)
        //    this.chartObjects[chartId].$refs.tvjs.$refs.chart.range_changed(range);
    }


    removeIndicator(chartId, type, indicatorKey) {
        //this.delIndicator(type+"."+(indicatorKey).replace("_",""));
        console.log('removeIndicator',chartId, type, indicatorKey);
        this.charts[chartId].data.del(type+"."+(indicatorKey).replace("_",""));
        this.updateFiboPos(chartId, false);
        this.simulateClick(chartId);
        //setTimeout(this.simulateClick.bind(this), 100, chartId);

    }

    simulateClick(chartId) {
        $('#'+chartId+'-grid-0-canvas')[0].click()
    }
    getChartDefinition(tf, idx) {
        let onchart =   idx > 0 ? [] : [{
            "name": "AutoLines",
            "type": "AutoLines",
            "indId": "3",
            "group": "Indicators",
            "data": [],
            "settings": { "flags": [], "extremes" : [], "z-index": 1, "legend":false, "isAutoLines": true},
            //"chartHandler": this,
        },{
            "name": "AutoOverlay",
            "type": "AutoOverlay",
            "indId": "4",
            "group": "Indicators",
            "data": [],
            "settings": { "flags": [], "extremes" : [], "z-index": 1, "legend":false, "isAutoLines": true},
            //"chartHandler": this,
        }];
        onchart.push(JSON.parse(`{"name":"AUTOFIB-1","type":"AUTOFIB","settings":{ "fibotype":"F1","levels":[0,23.6,38.2,50,61.8,78.6,100,138.2,161.8,200,261.8,-138.2,-161.8,-200,-261.8],"showPrice":true,"showPercentage":true,"showBackground":false,"backColor":"#787b86","backOpacity":10,"color":"#00FF00","lineWidth":1,"showLevel0":true,"showLevel1":false,"showLevel2":true,"showLevel3":true,"showLevel4":true,"showLevel5":true,"showLevel6":true,"showLevel7":false,"showLevel8":false,"showLevel9":false,"showLevel10":false,"showLevel11":false,"showLevel12":false,"showLevel13":false,"showLevel14":false, "isAutoFibs": true, "legend":false,"z-index":100,"$selected":true,"$state":"finished","$uuid":"onchart.AUTOFIB0-1610102349692","p1":[],"p2":[]},"data":[],"grid":{"id":0},"id":"onchart.AUTOFIB0"}`));
        onchart.push(JSON.parse(`{"name":"AUTOFIB-2","type":"AUTOFIB","settings":{ "fibotype":"F2","levels":[0,23.6,38.2,50,61.8,78.6,100,138.2,161.8,200,261.8,-138.2,-161.8,-200,-261.8],"showPrice":true,"showPercentage":true,"showBackground":false,"backColor":"#787b86","backOpacity":10,"color":"#00FF00","lineWidth":1,"showLevel0":true,"showLevel1":false,"showLevel2":true,"showLevel3":true,"showLevel4":true,"showLevel5":true,"showLevel6":true,"showLevel7":false,"showLevel8":false,"showLevel9":false,"showLevel10":false,"showLevel11":false,"showLevel12":false,"showLevel13":false,"showLevel14":false, "isAutoFibs": true, "legend":false,"z-index":100,"$selected":true,"$state":"finished","$uuid":"onchart.AUTOFIB0-1610102349692","p1":[],"p2":[]},"data":[],"grid":{"id":0},"id":"onchart.AUTOFIB2"}`));
        onchart.push(JSON.parse(`{"name":"AUTOFIB-X1","type":"AUTOFIB","settings":{ "fiboId": 0, "fibotype":"FX","levels":[0,23.6,38.2,50,61.8,78.6,100,138.2,161.8,200,261.8,-138.2,-161.8,-200,-261.8],"showPrice":true,"showPercentage":true,"showBackground":false,"backColor":"#787b86","backOpacity":10,"color":"#00FF00","lineWidth":1,"showLevel0":true,"showLevel1":false,"showLevel2":true,"showLevel3":true,"showLevel4":true,"showLevel5":true,"showLevel6":true,"showLevel7":false,"showLevel8":false,"showLevel9":false,"showLevel10":false,"showLevel11":false,"showLevel12":false,"showLevel13":false,"showLevel14":false, "isAutoFibs": true, "legend":false,"z-index":100,"$selected":true,"$state":"finished","$uuid":"onchart.AUTOFIB0-1610102349692","p1":[],"p2":[]},"data":[],"grid":{"id":0},"id":"onchart.AUTOFIBX"}`));
        onchart.push(JSON.parse(`{"name":"AUTOFIB-X2","type":"AUTOFIB","settings":{ "fiboId": 1, "fibotype":"FX","levels":[0,23.6,38.2,50,61.8,78.6,100,138.2,161.8,200,261.8,-138.2,-161.8,-200,-261.8],"showPrice":true,"showPercentage":true,"showBackground":false,"backColor":"#787b86","backOpacity":10,"color":"#00FF00","lineWidth":1,"showLevel0":true,"showLevel1":false,"showLevel2":true,"showLevel3":true,"showLevel4":true,"showLevel5":true,"showLevel6":true,"showLevel7":false,"showLevel8":false,"showLevel9":false,"showLevel10":false,"showLevel11":false,"showLevel12":false,"showLevel13":false,"showLevel14":false, "isAutoFibs": true, "legend":false,"z-index":100,"$selected":true,"$state":"finished","$uuid":"onchart.AUTOFIB0-1610102349692","p1":[],"p2":[]},"data":[],"grid":{"id":0},"id":"onchart.AUTOFIBX"}`));
        onchart.push(JSON.parse(`{"name":"PriceGap","type":"PriceGap","data":[],"grid":{"id":0},"id":"onchart.PriceGap","settings": {"legend":false}}`));
        onchart.push(JSON.parse(`{"name":"AUTOHORI","type":"AUTOHORI","data":[],"grid":{"id":0},"id":"onchart.AutoHori","settings": {"legend":false}}`));
        onchart.push(JSON.parse(`{"name": "AutoTrend", "type": "AutoTrend", "indId": "3", "group": "Indicators","data": [],"settings": { "flags": [], "extremes" : [], "z-index": 1, "legend":false, "isAutoLines": true}}`));

        //{"type":"Fib2","startDate":1626877800000,"startPrice":1917.56,"endDate":1626879600000,"endPrice":1946,"deletedAt":"2021-07-21T15:45:00.000+00:00","width":2,"consolidation":2,"retracementPrice":1935.84,"deleted":true,"endAtr":13.38037847723369,"referenceDate":null,"referenceAtr":0,"serial":128,"alertLevels":[],"direction":"UP","retracementLevel":0.3572433192686379,"fibRatio":1,"consolidationFactor":0.642756680731362},
        /*fibAutoData.data = [[1626877800000]];
        fibAutoData.settings.data = [[1626877800000]];
            fibAutoData.settings.p1 = [1626877800000,1917.56];
        fibAutoData.settings.p2 = [1626877800000,1946];
        */
        //onchart.push(fibAutoData);

        //console.log('getDefinition',this.charts[idx])

        return {data: new DataCube( { chart: { type: "Candles", data: [], tf:tf} , offchart: [], onchart: onchart, drawingMode:false}, {auto_scroll: false}), canUpdate : false, chartId : 0, fibHeight: 20, fibData: {FibX : {}, Fib1 : {}, Fib2 : {}}, hintData : { autoOverlay : { first: '', second: '', caption : 'extrema points', strong : { prev: {bars:"-", percent: 0.0, abs: 0.0, isLow: false}, next: {bars:"-", percent: 0.0, abs: 0.0, isLow: false}}, normal : { prev: {bars:"-", percent: 0.0, abs: 0.0, isLow: false}, next: {bars:"-", percent: 0.0, abs: 0.0, isLow: false}}}}, tf: tf, symbol: ''+this.displayData.symbol, indexBased: true,
            layout:this.layoutHandler.getLayout(idx), settings: {flags:[], extreams : [], priceGaps : [], horizontals: [], fibo: { F1 : {p1: null, p2: null}, F2 : {p1: null, p2: null}, FX : [{p1: null, p2: null}, {p1: null, p2: null}]}, chartsTemplates : [], clusters: [], trends: []}};

        /*
                    const isAutoFibs = window.$store.chartSettings.isAutoFibs;
                    const fibAutoData = `{"name":"AUTOFIB-${fib.type}","type":"AUTOFIB","settings":{"levels":[0,23.6,38.2,50,61.8,78.6,100,138.2,161.8,200,261.8,-138.2,-161.8,-200,-261.8],"showPrice":true,"showPercentage":true,"showBackground":false,"backColor":"#787b86","backOpacity":10,"color":"${color}","lineWidth":1,"showLevel0":true,"showLevel1":false,"showLevel2":true,"showLevel3":true,"showLevel4":true,"showLevel5":true,"showLevel6":true,"showLevel7":false,"showLevel8":false,"showLevel9":false,"showLevel10":false,"showLevel11":false,"showLevel12":false,"showLevel13":false,"showLevel14":false, "isAutoFibs": ${isAutoFibs}, "legend":false,"z-index":100,"$selected":true,"$state":"finished","$uuid":"onchart.AUTOFIB0-1610102349692","p1":[${p1}],"p2":[${p2}]},"data":[],"fieldsTemplate":[["bool","Show Price","showPrice"],["bool","Show Percentage","showPercentage"],["sep","sep","sep"],["bool","Show Background","showBackground",false],["color","Back Color","backColor"],["integer","Background Opacity","backOpacity"],["sep","sep","sep"],["color","Line Color","color"],["lineWidth","Line Thickness","lineWidth"],["sep","sep","sep"],["bool","Show Level 0%","showLevel0"],["bool","Show Level 23.6%","showLevel1"],["bool","Show Level 38.2%","showLevel2"],["bool","Show Level 50%","showLevel3"],["bool","Show Level 61.8%","showLevel4"],["bool","Show Level 78.6%","showLevel5"],["bool","Show Level 100%","showLevel6"],["bool","Show Level 138.2%","showLevel7"],["bool","Show Level 161.8%","showLevel8"],["bool","Show Level 200%","showLevel9"],["bool","Show Level 261.8%","showLevel10"],["bool","Show Level -138.2%","showLevel11"],["bool","Show Level -161.8%","showLevel12"],["bool","Show Level -200%","showLevel13"],["bool","Show Level -261.8%","showLevel14"]],"grid":{"id":0},"id":"onchart.AUTOFIB0"}`;
                    const fibAutoTool = JSON.parse(fibAutoData);

                    fibAutoTool.settings.$uuid = `onchart.AUTOFIB${i}-${new Date().getTime()}`;
                    fibAutoTool.settings.id = `onchart.${fib.type}`;
                    fibAutoTool.data = {
                        fibRatio: fib.fibRatio,
                        consolidationFactor: fib.consolidationFactor,
                    };
                    onchart.push(fibAutoTool);*/
    }

    requestIndicators() {
        this.chartServerInterface.requestIndicators();
    }

    handleIndicatorsResponce(dataOrg) {
        const self = this;
        const data = JSON.parse(dataOrg);
        //console.log(data);
        console.log('handleIndicatorsResponce',data);

        data.Indicators.push({data:[], group: 'Indicators', name: "TEMA", parameter:[], side: 'onchart', type: "ftTEMA"});
        data.Indicators.push({data:[], group: 'Indicators', name: "TSI", parameter:[], side: 'offchart', type: "TSI"});
        data.Indicators.push({data:[], group: 'Indicators', name: "SWMA", parameter:[], side: 'onchart', type: "SWMA"});
        data.Indicators.push({data:[], group: 'Indicators', name: "WMA", parameter:[], side: 'onchart', type: "WMA"});
        data.Indicators.push({data:[], group: 'Indicators', name: "Williams %R", parameter:[], side: 'offchart', type: "ftWilliamsR"});
        data.Indicators.push({data:[], group: 'Indicators', name: "Stochastic", parameter:[], side: 'offchart', type: "ftStochastic"});
        data.Indicators.push({data:[], group: 'Indicators', name: "Ichimoku", parameter:[], side: 'onchart', type: "Ichimoku"});
        data.Indicators.push({data:[], group: 'Indicators', name: "MACD", parameter:{histWidth: 4,
            macdWidth: 1,
            signalWidth: 1,
            defColor: "#42b28a",
            macdColor: "#3782f2",
            signalColor: "#f48709",
            histColors: ["#35a776", "#79e0b3", "#e54150", "#ea969e"]}, side: 'offchart', type: "ftMACD"});

        data.Indicators.forEach((ind) => {
            ind.group = "Indicators";
            ind.side  = ind.side || "onchart";
            ind.data  = [];
            Vue.set(self.indicators, ind.name, ind);
        })
    }

    updateHistory(symbol) {
        //return;

        var idx = -1;
        this.settings.favourites.forEach((fav, index) => {
            if (fav.name == symbol) {
                idx = index;
            }
        });

        if (idx >= 0) {
            this.settings.favourites.splice(idx,1);
        }

        const symbolFound = this.settings.favourites.filter((value) => { return value.name == symbol});
        //console.log('updateHistory', symbolFound, idx);

        if (!symbolFound.length) {
            if (this.settings.favourites.length >= 10)
                this.settings.favourites.splice(0,1)

            //console.log('updateHistory handleMarket', symbol);

            var found = false;
            this.settings.marketList.forEach((mle) => {
                if ((mle.name == symbol) || (mle.key == symbol)) {
                    found = true;
                    this.settings.favourites.push(mle);
                }
            });
            if (!found) {
                this.settings.favourites.push({name: symbol})
            }
        }
    }

    requestHistoryData(symbolValue, resetTF) {
        if (!symbolValue) return
        const self = this;
        if (symbolValue=="ALB.FMP")
            console.log("test irgendwas ist komisch ...")

        let symbol = symbolValue;
        if ((symbolValue.charCodeAt(symbolValue.length-1) >= 97) && (symbolValue.charCodeAt(symbolValue.length-1) <= 122)) {
            for (let mli in this.settings.marketList) {
                const mlItem = this.settings.marketList[mli];
                if (mlItem.name == symbolValue) {
                    if ('instrId' in mlItem) {
                        symbol = mlItem.instrId;
                    }
                }
            }
        }

        const requests = [];
        const oldSymbol = this.displayData.symbol;
        console.log('requestHistoryData',symbol, this.liveCharting.liveChartId)
        Vue.set(this.displayData,'symbol',symbol);


        //if ((oldSymbol != symbol) || resetTF ){
            Object.keys(self.charts).forEach((c) => {
                //self.charts[c].data.initNew(true);
                //self.clearIndicators(c, false);
                if (oldSymbol != symbol)
                    self.charts[c].data.canUpdate = false;

                //self.charts[c]=self.getChartDefinition(self.charts[c].tf, c)
                //self.charts[c].data.canUpdate = false;
            });
            this.clearHistory();
            this.updateHistory(symbol);
       // }

        //this.displayData.symbol = symbol;
        //console.log('new instrument - handlePriceUpdate', this.displayData.symbol, symbol);
        this.updateContextMenue();

        this.timeframe.forEach((tf, idx) => {
            //console.log('requestHistoryData forEach',idx,tf,self.charts[''+idx].data);
            Vue.set(self.charts[''+idx],'symbol',symbol);
            //self.charts[''+idx].data.initNew(true);
            if (!requests.find(element => element == tf)) {
                if ((!resetTF) || (idx > 0)) {
            //        self.charts[''+idx].data.initNew(true);
                    self.chartServerInterface.requestHistory(symbol, tf);
                    self.chartServerInterface.aboUpdates(symbol, tf);
                    requests.push(tf);
                }
            }
        });
        if (this.channel && !this.detach)
            this.channel.postMessage({action : "changeSymbol", symbol : this.displayData.symbol})
        if (resetTF)
            this.setTimeFrame("0",resetTF);
    }

    clearHistory() {
        this.fullChartData.clear();
    }

    requestMarketList() {
        this.chartServerInterface.requestMarketList();
    }

    requestSettings() {
        this.chartServerInterface.requestSettings();
    }

    requestUsers() {
        this.chartServerInterface.requestUsers();
    }

    requestTemplates() {
        this.chartServerInterface.requestTemplates();
    }

    requestWatchList() {
        this.chartServerInterface.requestWatchList();
        this.updateContextMenue();
    }

    addMarketListDetail(symbol, marketListId, marketListName) {
        console.log(symbol, marketListId, marketListName)
        this.chartServerInterface.addMarketListDetail( marketListId, symbol || this.displayData.symbol, marketListName);
        //this.updateContextMenue();
    }

    deleteMarketListDetail(symbol, marketListId) {
        //this.resetWatchList(wlName,symbol || this.displayData.symbol);
        this.chartServerInterface.deleteMarketListDetail(marketListId, symbol || this.displayData.symbol);
        //this.updateContextMenue();
    }

    deleteWatchList(wlName) {
        this.resetWatchList(wlName, null);
        this.chartServerInterface.deleteWatchList(wlName);
        this.updateContextMenue();
    }

    onShowWatchList(wlName) {
        this.displayData.marketLists.forEach((wl, wlidx) => {
            wl.visible = wl.marketName === wlName && !wl.visible;
        });

    }

    resetWatchList(wlName, symbol) {
        const self = this;
        /*this.watchLists.data.forEach((wl, idxWL) => {
            //console.log(wl);
           if (wl.name == wlName) {
               if (symbol) {
                   wl.details.forEach((instr, i) => {
                       if (instr.name == symbol) {
                           //console.log(instr.name);
                           wl.elements.splice(i, 1);
                       }
                   });
               } else {
                   //wl.elements.clear();
                   self.watchLists.data.splice(idxWL,1);
               }
           }
        });*/
    }

    getTemplateInfo(type, template) {
        //console.log('requestTemplateInfo', type, template);
        this.chartServerInterface.requestTemplateInfo(type, template);
    }

    getKey(timeframe) {
        let key = "0";
        this.timeframe.forEach((tf, index) => {
            if (timeframe == tf)
                key = index;
        });
        return key;
    }

    handleSettingsResponce(settings){
        const self = this;
        //console.log('settings',settings);
        const defaultLen = self.chartSettings['defaultLen'];
        if (settings && settings.result && settings.result.length && settings.result[0].SETTINGS) {
            const data = JSON.parse(settings.result[0].SETTINGS);
            Object.keys(data).forEach((s) => {
                //Vue.set(self.chartSettings, s, !data[s]);
                Vue.set(self.chartSettings, s, data[s]);
                //self.chartSettings[s] = data[s];
                //console.log(s, data[s]);
            });
        }

        if (defaultLen != self.chartSettings['defaultLen']) {
            this.chartObjects.forEach((chartObj) => {
                if (chartObj && chartObj.$refs && chartObj.$refs.tvjs)
                    chartObj.$refs.tvjs.DAFAULT_LEN = Number(self.chartSettings['defaultLen']);
            });
        }

        this.updateTheme();
    }

    handleGetToolSettings(settings) {
        if (settings && settings.data && settings.data.length) {
            const self = this;
            while (this.settings.tools.pop()) ;
            settings.data.forEach((setting) => {
                self.settings.tools.push(setting);
            });
        }
        this.favoritesHandler.refreshFavData();
    }

    handleSetToolSetting(setting) {
        if (setting) {
            let bFound = false;
            this.settings.tools.forEach((s) => {
                if (s.idToolSettings === setting.idToolSettings) {
                    s.configData = setting.configData;
                    bFound = true;
                }
            });
            if (!bFound) {
                this.settings.tools.push({idToolSettings: setting.idToolSettings, configData: setting.configData, userId: setting.userId, settingName: setting.settingName, toolName: setting.toolName});
            }
        }
    }

    handleDelToolSetting(setting) {
        if (setting) {
            let iFound = -1;
            this.settings.tools.forEach((s, idx) => {
                if (s.idToolSettings === setting.idToolSettings) {
                    iFound = idx;
                }
            });
            if (iFound>=0) {
                this.settings.tools.splice(iFound,1);
            }
        }
    }

    saveToolSetting(toolName, name, cfg) {
        this.chartServerInterface.saveToolSetting(toolName, name, cfg);
    }

    deleteToolSetting(idToolSettings) {
        let del = false;
        this.settings.tools.forEach((s) => {
            if ((s.idToolSettings === idToolSettings) && (s.userId > 0)) {
                s.deleted = true;
                del = true;
            }
        });
        if (del)
            this.chartServerInterface.deleteToolSetting(idToolSettings);
    }

    getToolDefaultSettings(name) {
        return this.getToolSettings(name,"default");
    }

    getToolSettings(name, settingName) {
        let res = null;
        this.settings.tools.forEach((s) => {
            if ((s.toolName === name) && (s.settingName == settingName)) {
                if ((!res) || (s.userId > 0))
                    res = s.configData;
            }
        });
        return res;
    }

    getLength() {
        //console.log(this.settings.viewDefaultlen);
        return this.settings.viewDefaultlen ? this.settings.viewDefaultlen : this.chartSettings.defaultLen;
    }

    updateTheme() {
        const self = this;
        let tempTheme = this.themes[this.chartSettings.theme];
        //console.log('currentTheme',tempTheme)
        if (!tempTheme)
            tempTheme = this.themes['dark'];
        //console.log('currentTheme',tempTheme)
        if (tempTheme) {
            Vue.set(self.currentTheme,'colorBack', '#000');
            Object.keys(tempTheme).forEach((c) => {
                //console.log(c);
                Vue.set(self.currentTheme,c, tempTheme[c]);
            });
        }
        //Vue.set(this.chartSettings,'timezone','+1.00');
        //Vue.set(this.chartSettings,'timezone','+2.00');
        /*this.chartObjects.forEach((c) => {
            if (c) {
                console.log(c.height++);
                console.log(c.height--);
                //c.$refs.tvjs.;
           }
        });*/
    }

    handleWatchListResponce(watchList) {
        const self = this;
        //console.log(watchList);
        /*while (this.watchLists.data.pop());

        if (watchList && watchList.length) {
            watchList.forEach((wl) => {
                let newWL = {name: wl.NAME, wl_name: "watchlist_"+wl.NAME, list_name: "list_"+wl.NAME, visible : false, elements : []};

                self.watchLists.data.push(newWL);
            })
        }
        this.chartServerInterface.requestWatchListDetails();*/
        this.updateContextMenue();
    }

    handleWatchListDetailsResponce(watchListDetails) {
        const self = this;
        //console.log(watchListDetails);

        if (watchListDetails && watchListDetails.length) {
            watchListDetails.forEach((wld) => {
                let wl = self.watchLists.data.filter((w) => {
                    return w.name.toLowerCase() == wld.NAME.toLowerCase()
                });
                if (wl && wl.length && wl[0].elements) {
                    wl[0].elements.push({ name: wld.SYMBOL, instr_name: wl[0].name+"_"+wld.SYMBOL, percent: 2.43, price: 1.231212, func: "chartHandler.deleteWatchListDetail('"+wld.SYMBOL+"', '"+wl[0].name+"')"});
                }
            });
        }
        this.updateContextMenue();
    }

    getIndexFromTime(chartId, index) {
        //console.log("add_ind_it1", this.charts[chartId].data, this.charts[chartId] &&  this.charts[chartId].data ? this.charts[chartId].data.tv:"-");
        if (this.chartObjects[chartId] &&
            this.chartObjects[chartId].$refs.tvjs &&
            this.chartObjects[chartId].$refs.tvjs.$refs &&
            this.chartObjects[chartId].$refs.tvjs.$refs.chart &&
            this.chartObjects[chartId].$refs.tvjs.$refs.chart.ti_map) {
            //console.log("add_ind_it2", this.chartObjects[chartId].$refs.tvjs.$refs.chart.ti_map.t2i(index));
            //this.charts[chartId].data.tv.$refs.chart.ti_map.t2i(index);
            return this.chartObjects[chartId].$refs.tvjs.$refs.chart.ti_map.t2i(index);
        }
        return index;
    }
    getTimeFromIndex(chartId, index) {
        //console.log("add_ind_ti0",this.charts[chartId].data,this.charts[chartId] &&  this.charts[chartId].data ? this.charts[chartId].data.tv:"-");
        if (this.chartObjects[chartId] &&
            this.chartObjects[chartId].$refs.tvjs &&
            this.chartObjects[chartId].$refs.tvjs.$refs &&
            this.chartObjects[chartId].$refs.tvjs.$refs.chart &&
            this.chartObjects[chartId].$refs.tvjs.$refs.chart.ti_map) {
            //console.log("add_ind_ti1", index, this.chartObjects[chartId].$refs.tvjs.$refs.chart.ti_map.i2t(index));
            //return this.charts[chartId].data.tv.$refs.chart.ti_map.i2t(index);
            return this.chartObjects[chartId].$refs.tvjs.$refs.chart.ti_map.i2t(index);
        }
        return index;
    }

    getVisibleBars(index) {
        if ((this.chartObjects[""+index]) &&
            (this.chartObjects[""+index].$refs) &&
            (this.chartObjects[""+index].$refs.tvjs) &&
            (this.chartObjects[""+index].$refs.tvjs.$refs) &&
            (this.chartObjects[""+index].$refs.tvjs.$refs.chart) &&
            (this.chartObjects[""+index].$refs.tvjs.$refs.chart.sub)) {
            const range = this.chartObjects["" + index].$refs.tvjs.$refs.chart.range;
            return Math.trunc(range[1]-range[0]) ; //this.chartObjects["" + index].$refs.tvjs.$refs.chart.sub.length + range[0] - Math.trunc(range[0])-1;
        }
        return 0;
    }

    getRightSpace(index) {
        if ((this.chartObjects[""+index]) &&
            (this.chartObjects[""+index].$refs) &&
            (this.chartObjects[""+index].$refs.tvjs) &&
            (this.chartObjects[""+index].$refs.tvjs.$refs) &&
            (this.chartObjects[""+index].$refs.tvjs.$refs.chart) &&
            (this.chartObjects[""+index].$refs.tvjs.$refs.chart.sub)) {
            const range =  this.chartObjects["" + index].$refs.tvjs.$refs.chart.range;
            //console.log(range[1]-range[0]-this.chartObjects[""+index].$refs.tvjs.$refs.chart.sub.length,range[1],range[0],range[1]-this.chartObjects[""+index].$refs.tvjs.$refs.chart.data.chart.data.length);
            return Math.trunc(range[1]-this.chartObjects[""+index].$refs.tvjs.$refs.chart.data.chart.data.length); //range[1]-range[0]-this.chartObjects[""+index].$refs.tvjs.$refs.chart.sub.length+range[0]-Math.trunc(range[0])
        }
        return 0;
    }

    getConfigToSave(type) {
        const self = this;
        if (type == 'chart') {
            const data = {indicators: [],visibleBars: this.getVisibleBars(0), rightSpace: this.getRightSpace(0)};
            //console.log(this.chartObjects["0"].$refs.tvjs.$refs.chart);
            //console.log();
            this.charts["0"].data.data.onchart.forEach((i) => {
                if (!self.isSpecialIndicator(i.name)) {
                    i.settings.liveId = i.settings.liveId || Utils.uuid2();
                    const points = {};

                    if (i.name == 'DrawingLines') {
                        const ch = self.chartObjects['0'].$refs.tvjs.$refs.chart;
                        ch.$children.forEach((c) => {
                            //console.log('add_ind_c',c.id);
                            c.$children.forEach((sc) => {
                                //console.log('add_ind_sc',sc.id);
                                sc.$children.forEach((ssc) => {
                                    //console.log('add_ind_ssc',ssc.id,i.name);
                                    if (i.id && ssc.id && i.id.length && ssc.id.length) {
                                        const indName = ssc.id.replace("_", "") + "§";
                                        if ((i.id+"§").indexOf(indName) >= 0) {
                                            //console.log('add_ind_ssc', ssc.id, i.id,indName);
                                            const isdeleted = i.settings.deleted;
                                            i.settings = ssc.points;
                                            if (isdeleted) {
                                                i.settings.deleted = isdeleted;
                                            }
                                        }
                                    }
                                });
                            });
                        });
                    }

                    var idx= 0;
                    while (i.settings['p'+(++idx)]) {
                        points['p'+idx] = [self.getTimeFromIndex("0", i.settings['p' + idx][0]), i.settings['p' + idx][1]];
                    }
                    //console.log(i.settings, points)
                    //console.log('2',i.settings, points)
                    //console.log('add_ind_3',i.settings.p1,i.settings.p2, points.p1,points.p2)
                    const cfg = {name: i.name, liveId : i.settings.liveId, type: i.type, points : points, settings: i.settings, id: i.id,labelId: i.labelId,data : i.type == 'DrawingLines' ?
                            //window.chartHandler.getStorage(i.settings.$uuid) :
                            []:[]};
                    cfg.data = cfg.data || [];
                    //console.log(i, i.settings, points);
                    if (!cfg.settings.deleted)
                        data.indicators.push(cfg);
                }
            });
            /*this.charts["0"].data.data.tools.forEach((i) => {
                data.tools.push({name: i.name, type: i.type, settings: i.settings, id: i.id,data : []});
            });*/
            this.charts["0"].data.data.offchart.forEach((i) => {
                //console.log(i);
                if (!self.isSpecialIndicator(i.name))
                    data.indicators.push({name: i.name, type: i.type, settings: i.settings, ieventfsdd: i.id,data : [], grid: {height: 0.2}});
            });
            //console.log(i.id, data.visibleBars);
            return data;
        }
    }

    saveConfigWithIDAndName(sendType, name, id, tags) {
        const template = {
            NAME: name.trim(),
            SYMBOL: this.charts['' + this.activeChart].symbol,
            TIMEFRAME: this.charts['' + this.activeChart].tf,
            CHART_DATA: JSON.stringify(this.getChartConfig("0")),
            TAGS: tags
                .trim()
                .split(",")
                .toString(),
        };
        this.saveTemplate(
            sendType,
            id,
            template
        );
    }

    saveConfig(type, force, id, headline, text, commentInternal, resourceInfo,  labelActions, publishedAnalysis, abos) {
        if (force || ((type == 'chart') && this.chartSettings.isAutoSave)) {
            const data = {CHART_DATA: JSON.stringify(this.getConfigToSave(type))};
            if (type == 'chart') {
                if (headline) {
                    this.charts['' + this.activeChart].data.chartId = id;
                    data.NAME = "PUBLISHED";
                    data.SYMBOL = this.charts['' + this.activeChart].symbol;
                    data.TIMEFRAME = this.charts['' + this.activeChart].tf;
                } else {
                    this.liveCharting.liveAnalysis.forEach((value, idx) => {
                        if ((force) &&
                            (value.id > 0) &&
                            (this.charts['' + this.activeChart].data.chartId === 0) &&
                            (this.charts['' + this.activeChart].symbol === value.symbol) &&
                            (this.charts['' + this.activeChart].tf === value.tf)) {
                            this.charts['' + this.activeChart].data.chartId = value.id;
                        }
                        if ((!force) &&
                            (value.id > 0) &&
                            (this.charts['' + this.activeChart].data.chartId > 0) &&
                            (this.charts['' + this.activeChart].symbol === value.symbol) &&
                            (this.charts['' + this.activeChart].tf === value.tf)) {
                            this.charts['' + this.activeChart].data.chartId = 0;
                        }
                    });

                    if (!force) {
                        this.charts['' + this.activeChart].settings.chartsTemplates.forEach((t) => {
                            //console.log('save_point',t.ID_CHART,this.charts['' + this.activeChart].data.chartId);
                            if ((t.NAME === "DEFAULT") &&
                                (this.charts['' + this.activeChart].data.chartId <= 0) &&
                                (this.charts['' + this.activeChart].symbol === t.SYMBOL) &&
                                (this.charts['' + this.activeChart].tf === t.TIMEFRAME)) {
                                this.charts['' + this.activeChart].data.chartId = t.ID_CHART;
                            }
                        });
                    }


                    if (this.charts["0"].data.chartId == 0) {
                        if (id > 0) {
                            this.charts["0"].data.chartId == id;
                        } else {
                            data.NAME = "DEFAULT";
                            data.SYMBOL = this.charts['' + this.activeChart].symbol;
                            data.TIMEFRAME = this.charts['' + this.activeChart].tf;
                        }
                    }
                }

                if (!data.SYMBOL || !data.TIMEFRAME) {
                    data.NAME = "DEFAULT";
                    data.SYMBOL = this.charts['' + this.activeChart].symbol;
                    data.TIMEFRAME = this.charts['' + this.activeChart].tf;
                }
                //console.log('save '+type,this.charts['' + this.activeChart].data.chartId);
                this.saveTemplate('chart', this.charts["0"].data.chartId, data, headline, text, commentInternal, resourceInfo,  labelActions, publishedAnalysis, abos);
                if (force)
                    this.chartServerInterface.requestChartsTemplates(this.charts['' + this.activeChart].symbol, this.charts['' + this.activeChart].tf);
            }
        }
    }

    sortCharts(c1,c2) {
        return (c2.EDITED_DATE || c2.CREATED_DATE) - (c1.EDITED_DATE || c1.CREATED_DATE);
    }

    handleTemplatesResponce(templates, type, timeframe){
        //this.settings.templates.clear();
        console.log('handleTemplatesResponce removeIndicator',templates, type);
        if (type == 'charts') {
            let chartArray = [];
            let tfIdx = -1;
            while (this.charts[this.activeChart].settings.chartsTemplates.pop()) ;
            //this.settings.set('chartsTemplates', []);
            if (templates) {
                templates.forEach((t) => {
                    if ((t.TIMEFRAME == timeframe) || (t.name !== "DEFAULT")) {
                        chartArray.push(t);
                    }
                });
            }

            if (this.liveCharting.loadedChartId<0)
                this.charts[this.activeChart].data.chartId = 0;

            chartArray.sort(this.sortCharts);
            chartArray.forEach((t, idx) => {
                if ((tfIdx<0) && (t.TIMEFRAME == timeframe)) {
                    tfIdx = idx;
                }
            });
            if (tfIdx<0) {
                tfIdx = 0;
            }
            if (chartArray && chartArray[tfIdx] && (this.liveCharting.loadedChartId<0))
                this.getTemplateInfo(type, chartArray[tfIdx]);
            else {
                Vue.set(window.chartHandler.loading, 'isLoadingHistoryData', false);
                window.chartHandler.setChartVisible(true);
            }
                //this.handleTemplateResponce(type, {result: [chartArray[0]]});

            //console.log(chartArray);
            chartArray.forEach((c) => {
                this.charts[this.activeChart].settings.chartsTemplates.push(c);
            });
        }
        else {
            //this.settings.set('templates', []);
            //this.settings.templates = [];
            while (this.settings.templates.pop()) ;
            if (templates) {
                templates.forEach((t) => {
                    this.settings.templates.push(t);
                });
            }
        }
    }

    isSpecialIndicator(indName) {
        return indName && ((indName.indexOf('AUTOFIB')>=0) ||
            (indName.indexOf('AutoOverlay')>=0) ||
            (indName.indexOf('AutoTrend')>=0) ||
            (indName.indexOf('AutoLines')>=0) ||
            (indName.indexOf('PriceGap')>=0)||
            (indName.indexOf('AUTOHORI')>=0));
    }

    isPriceLineIndicator(indName) {
        return indName && ((indName.indexOf('TSI')>=0) ||
            (indName.indexOf('WMA')>=0) ||
            (indName.indexOf('Williams')>=0) ||
            (indName.indexOf('TEMA')>=0) ||
            (indName.indexOf('Stochastic')>=0) ||
            (indName.indexOf('MACD')>=0) ||
            (indName.indexOf('EMA')>=0) ||
            (indName.indexOf('SMA')>=0) ||
            (indName.indexOf('ATR')>=0) ||
            (indName.indexOf('Ichimoku')>=0) ||
            (indName.indexOf('RSI')>=0) ||
            (indName.indexOf('BB')>=0) ||
            (indName.indexOf('Bollinger Band')>=0) ||
            (indName.indexOf('Momentum')>=0) ||
            (indName.indexOf('MOM')>=0));
    }

    clearIndicators(chartId, clearAll) {

        console.log('removeIndicator',chartId, clearAll);
        if (this.charts[chartId] && this.charts[chartId].data && this.charts[chartId].data.data) {
            let i = this.charts[chartId].data.data.offchart.length;
            while (--i >= 0) {
                if (this.charts[chartId].data.data.offchart[i] && !this.isSpecialIndicator(this.charts[chartId].data.data.offchart[i].id) && (clearAll == this.isPriceLineIndicator(this.charts[chartId].data.data.offchart[i].id)))
                    this.charts[chartId].data.del(this.charts[chartId].data.data.offchart[i].id.substr(9));
            }
            i = this.charts[chartId].data.data.onchart.length;
            while (--i >= 0) {
                //console.log(i, this.charts[chartId].data.data.onchart[i]);
                if (this.charts[chartId].data.data.onchart[i] && !this.isSpecialIndicator(this.charts[chartId].data.data.onchart[i].id) && (clearAll == this.isPriceLineIndicator(this.charts[chartId].data.data.onchart[i].id)))
                    this.charts[chartId].data.del(this.charts[chartId].data.data.onchart[i].id.substr(8));
            }
        }
    }

    handleTemplateResponce(type, template) {
        const self = this;
        console.log("handleTemplateResponce res" ,type, template);
        let t;
        let data = template.result && template.result.length && template.result[0] ? template.result[0] : {};
        let onchart = [];
        //
        if (type == 'charts') {
            t = JSON.parse(template.result && template.result.length && template.result[0].CHART_DATA ? template.result[0].CHART_DATA : '{}');
            console.log('resLastView handleTemplateResponce',type, template, this.loadedChart.firstLastViewRequestId,t && t.indicators && t.indicators.length ? t.indicators[0] : null);
            if (template.result &&
                template.result.length &&
                this.isAdmin) {
                this.charts['' + this.activeChart].data.chartId = template.result[0].ID_CHART;
                //console.log(t, template.result);
            } else {
                this.charts['' + this.activeChart].data.chartId = 0;
            }
            //console.log(t, t.ID_CHART);
            //this.reInitNew(''+this.activeChart);
            this.clearIndicators(''+this.activeChart, false);
        }
        else {
            this.clearIndicators(''+this.activeChart, true);
            t = JSON.parse(template.result && template.result.length && template.result[0].TEMPLATE_DATA ? template.result[0].TEMPLATE_DATA : '{}');


        /*if (t.tools) {
            while (self.charts[self.activeChart].data.data.tools.pop());
            t.tools.forEach((tool) => {
                self.charts[self.activeChart].data.data.tools.push(tool);
            });
        }*/

        }
        this.reInitNew(''+this.activeChart);
            if (t.indicators) {
                let reset = false;
                        t.indicators.forEach((indicator) => {
                            let ind = JSON.parse(JSON.stringify(indicator));
                            //ind.settings.$uuid = ind.settings.$uuid;
                            ind.id = ind.settings.$uuid;
                            ind.settings.liveId = ind.settings.liveId || Utils.uuid2();
                            //console.log(ind);
                            if (!this.isSpecialIndicator(ind.id) && ((this.loadedChart.firstLastViewRequestId == data.SYMBOL) || ((type != 'charts') == this.isPriceLineIndicator(ind.id)))) {
                                if (('settings' in ind) && ('$selected' in ind.settings)) {
                                    //console.log('isSelected', ind.settings.$selected);
                                    ind.settings.$selected = false;
                                }
                                if (this.loadedChart.firstLastViewRequestId == data.SYMBOL)
                                    reset = true;
                                //console.log('resLastView add_ind_1',ind, data);
                                /*Vue.nextTick(function () {
                                    self.addIndicator(ind);
                                    // do something cool
                                })*/
                                self.addIndicator(ind);
                            }
                    /*if (ind.id.indexOf('onchart') >= 0) {
                        if (ind.type == "BollingerBands") {
                            ind.type = "BB";
                        }
                        console.log(ind);
                        this.addIndicator(ind);
                        //this.charts['0'].data.add('onchart',ind);
                    }
                    if (ind.id.indexOf('offchart') >= 0) {
                        //this.charts['0'].data.add('offchart',ind);
                    }*/
                });
                if (reset) {
                    this.loadedChart.firstLastViewRequestId = "";
                }
            }

        if (t.visibleBars) {
            const index = 0;
            console.log('handleTemplateResponce outer',t.visibleBars,t.rightSpace,self.charts['' + index].data,self.chartObjects[""+index].$refs.tvjs);
            if ((self.charts['' + index].data) &&
                //(self.charts['' + index].data.canUpdate) &&
                (self.charts['' + index].data.data) &&
                (self.charts['' + index].data.data.chart) &&
                (self.charts['' + index].data.data.chart.data) &&
                (self.charts['' + index].data.data.chart.data.length) &&
                (self.chartObjects[""+index]) &&
                (self.chartObjects[""+index].$refs) &&
                (self.chartObjects[""+index].$refs.tvjs)) {
                self.chartObjects[""+index].$refs.tvjs.$nextTick(() => {
                    //self.charts['' + index].data.tv.setRange(1800,3500);
                    console.log('handleTemplateResponce',self.charts['' + index].data.data.chart.data.length, t.visibleBars, t.rightSpace);
                    const rightSpace = t.rightSpace || 5;
                    self.chartObjects[""+index].$refs.tvjs.setRange(self.charts["0"].data.data.chart.data.length + rightSpace - t.visibleBars, self.charts["0"].data.data.chart.data.length + rightSpace);
                });
            }
        }
        //this.charts['0'].data,data.onchart. = onchart);
        //this.charts[''+0].data.set('data.onchart', []);
        //this.charts['0'].data.set('data.onchart',this.charts['0'].data.data.onchart);
        //this.charts['0'].data.set('data.offchart',this.charts['0'].data.data.offchart);

        if (template && template.success) {
            if (this.liveCharting.loadedChartId)
                this.requestLiveChart(this.liveCharting.loadedChartId,"","",false);

            Vue.set(this.loading,'isLoadingHistoryData',false);
            this.updateIndicatorPoints("0")
            window.chartHandler.setChartVisible(true);
            //console.log("visible",template)
        }
        this.updateFiboPos(''+this.activeChart, false);
    }

    handleMarketListResponce(list) {
        const self = this;
        //console.log('handleMarketListResponce',list);
        if (list) {
            list.forEach((item) => {
                if ((typeof item == "object") && (("name" in item) || ("print_name" in item))) {
                    if ((item.print_name.indexOf(':CHID')<0) && (item.print_name.indexOf(':BATD')<0) && (item.print_name.indexOf(':BATF')<0) && (item.print_name.indexOf(':BATP')<0) && (item.print_name.indexOf(':CHIX')<0) && (item.print_name.indexOf(':BATE')<0) && (item.print_name.indexOf(':CEUD')<0) && (item.print_name.indexOf(':LISZ')<0) && (item.print_name.indexOf(':LISX')<0) && (item.print_name.indexOf(':BEUP')<0) && (item.print_name.indexOf(':CEUO')<0))
                        self.settings.marketList.push({name: item.name || item.print_name, isin: item.isin, caption: item.caption});
                } else {
                    self.settings.marketList.push({name: item, caption : item + " (old)"});
                }
            });
        }
        this.settings.marketList.sort((mle1, mle2) => {
           if (mle1.caption == mle2.caption)
               return mle1.name.localeCompare(mle2.name);
           return mle1.caption.localeCompare(mle2.caption);
        });


        this.settings.favourites.forEach((fav) => {
            if (!fav.caption) {
                this.settings.marketList.forEach((mle) => {
                    if (fav.name == mle.name) {
                        fav.caption = mle.caption;
                    }
                });
            }
        });
    }

    handlePriceUpdate(symbol, timeframe, tickData) {
        //console.log('handlePriceUpdate',symbol, timeframe, tickData);
        const now = Date.now();
        this.displayData.marketLists.forEach((ml) => {
            ml.details.forEach((mld) => {
                if ((mld.symbol == symbol) && (Math.abs(mld.price - tickData.close)>0.0001)) {
                    //console.log('handlePriceUpdate',mld.price,tickData)
                    mld.new = now;
                    mld.newUp = mld.price <= tickData.close;
                    mld.price = tickData.close;
                    mld.percent = tickData.prev && (tickData.prev>0.000001)? (tickData.close / tickData.prev * 100) - 100 : 0;
                } else {
                    //mld.new = 0;
                }
            });
        });

        if (this.displayData.symbol != symbol)
            return;

        const self = this;
        this.timeframe.forEach((tf, index) => {
//            if (timeframe == tf) {

                if ((self.charts['' + index].data) &&
                    //(self.charts['' + index].data.canUpdate) &&
                    (self.charts['' + index].data.tv) &&
                    (self.charts['' + index].data.tv.$refs) &&
                    (self.charts['' + index].data.tv.$refs.chart)) {
                    if (self.charts['' + index].data.canUpdate) {
                        let price = self.getLastCandleX(index);
                        //console.log('hloc',price, index,tickData.timestamp , price[0],self.charts['' + index].data.canUpdate);
                        if (tickData.timestamp >= price[0]) {
                            if (price && (price.length > 4) && Utils.isSameTimeFrame(tf, tickData.timestamp, price[0])) {
                                tickData.open = price[1];
                                if (tickData.high < price[2]) {
                                    tickData.high = price[2];
                                }
                                if (tickData.low > price[3]) {
                                    tickData.low = price[3];
                                }
                                if (tickData.low > price[3]) {
                                    tickData.low = price[3];
                                }
                            }
                            self.charts['' + index].data.update({
                                t: tickData.timestamp,
                                open: parseFloat(tickData.open), // Trade price,
                                high: parseFloat(tickData.high), // Trade price,
                                low: parseFloat(tickData.low), // Trade price,
                                price: parseFloat(tickData.close), // Trade price
                                volume: parseFloat(tickData.volume || 0), // Trade amount
                            });
                        }
                    }
                } else {
                    console.error('chartObject is null',symbol,timeframe, self.charts['' + index] && self.charts['' + index].data ? self.charts['' + index].data.tv : null, self.charts['' + index] && self.charts['' + index].data ? self.charts['' + index].data.canUpdate : null);
                    self.resetChartObjects()
                    /*if ((self.charts['' + index].data.tv) &&
                        (self.charts['' + index].data.tv.$refs) &&
                        !(self.charts['' + index].data.tv.$refs.chart))
                    {
                        console.log('reset refs chart!!!');

                    }*/
                }
            //}
        });
    }

    resetChartObjects() {

        const self = this;
        console.log("resetChartObjects")
        for (let index=0;index<10;index++) {
            if (self.charts['' + index] && self.charts['' + index].data && self.chartObjects['' + index] && self.chartObjects['' + index].$refs) {
                let bReset = false;
                if (!self.charts['' + index].data.tv.$refs.chart) {
                    bReset = true
                    Vue.set(self.charts['' + index].data.tv.$refs, 'chart', self.chartObjects['' + index].$refs.tvjs.$refs.chart);
                }
                if (!self.charts['' + index].data.tv.$refs.widgets) {
                    bReset = true

                    Vue.set(self.charts['' + index].data.tv.$refs, 'widgets', self.chartObjects['' + index].$refs.tvjs.$refs.widgets);
                }
                if (bReset) {
                    //console.log("reset 1");
                    self.charts['' + index].data.initNew(true);
                }
            } else if (self.charts['' + index]) {
                self.charts['' + index].data.initNew(true);
            }
        }
    }

    handleHoriUpdate(symbol, timeframe, fibo) {
        if (this.displayData.symbol != symbol) return;
        console.log(symbol,timeframe,fibo);
    }

    handleFiboUpdate(symbol, timeframe, fibo) {
        if (this.displayData.symbol != symbol) return;

        const self = this;
        //const fibDataArray = {FibX : {}, Fib1 : {}, Fib2 : {}};
        this.timeframe.forEach((tf, index) => {
            if (timeframe == tf) {
                const f = fibo;
                    if (f.type == 'FibX') {
                        Vue.set(self.charts[''+index].settings.fibo.FX,'p2', [f.startDate-self.timeOffset,f.startPrice]);
                        Vue.set(self.charts[''+index].settings.fibo.FX,'p1', [f.endDate-self.timeOffset,f.endPrice]);
                        Vue.set(self.charts[''+index].settings.fibo.FX,'conso',   Number.parseFloat(f.consolidationFactor*100).toFixed(2));
                        Vue.set(self.charts[''+index].settings.fibo.FX,'factor',   Number.parseFloat(f.fibRatio).toFixed(2));
                    }
                    if (f.type == 'Fib1') {
                        Vue.set(self.charts[''+index].settings.fibo.F1,'p2', [f.startDate-self.timeOffset,f.startPrice]);
                        Vue.set(self.charts[''+index].settings.fibo.F1,'p1', [f.endDate-self.timeOffset,f.endPrice]);
                        Vue.set(self.charts[''+index].settings.fibo.F1,'conso',   Number.parseFloat(f.consolidationFactor*100).toFixed(2));
                        Vue.set(self.charts[''+index].settings.fibo.F1,'factor',   Number.parseFloat(f.fibRatio).toFixed(2));
                    }
                    if (f.type == 'Fib2') {
                        Vue.set(self.charts[''+index].settings.fibo.F2,'p2', [f.startDate-self.timeOffset,f.startPrice]);
                        Vue.set(self.charts[''+index].settings.fibo.F2,'p1', [f.endDate-self.timeOffset,f.endPrice]);
                        Vue.set(self.charts[''+index].settings.fibo.F2,'conso',   Number.parseFloat(f.consolidationFactor*100).toFixed(2));
                        Vue.set(self.charts[''+index].settings.fibo.F2,'factor',   Number.parseFloat(f.fibRatio).toFixed(2));
                    }

                //Vue.set(self.charts[''+index],'fibData', fibDataArray);
            }
        });
    }

    getLastCandleX(index) {
        //console.log('hloc',this.charts[''+index],this.charts[''+index].data);
        if (this.charts[''+index] &&
            this.charts[''+index].data &&
            this.charts[''+index].data.data &&
            this.charts[''+index].data.data.chart &&
            this.charts[''+index].data.data.chart.data &&
            this.charts[''+index].data.data.chart.data.length>0) {

            return this.charts[''+index].data.data.chart.data[this.charts[''+index].data.data.chart.data.length-1];
        }
        return null;
    }

    handleHistoryResponce(symbol, timeframe, hloc, fibData, extremes, flags, horizontals, priceGaps, clusters, trends, requestInfo) {
        if (symbol != this.displayData.symbol) return;

        const self = this;
        //const fibDataArray = {FibX : {}, Fib1 : {}, Fib2 : {}};

        //console.log('hloc handleTemplateResponce trends',hloc,trends);

        const autoFibo = {
            F1 : {p1: null, p2: null, conso: null, factor: null},
            F2 : {p1: null, p2: null, conso: null, factor: null},
            FX : [{p1: null, p2: null, conso: null, factor: null}, {p1: null, p2: null, conso: null, factor: null}]};

        let iFX = 0;
        if (fibData && !requestInfo.start) {

            fibData.forEach((f) => {
                if (f.type == 'FibX') {
                    autoFibo.FX[iFX].p2 = [f.startDate,f.startPrice];
                    autoFibo.FX[iFX].p1 = [f.endDate,f.endPrice];
                    autoFibo.FX[iFX].conso = Number.parseFloat(f.consolidationFactor*100).toFixed(2);
                    autoFibo.FX[iFX].factor = Number.parseFloat(f.fibRatio).toFixed(2);
                    iFX=1;
                    //console.log("fibData.forEach",f)
                }
                if (f.type == 'Fib1') {
                    autoFibo.F1.p2 = [f.startDate,f.startPrice];
                    autoFibo.F1.p1 = [f.endDate,f.endPrice];
                    autoFibo.F1.conso = Number.parseFloat(f.consolidationFactor*100).toFixed(2);
                    autoFibo.F1.factor = Number.parseFloat(f.fibRatio).toFixed(2);
                }
                if (f.type == 'Fib2') {
                    autoFibo.F2.p2 = [f.startDate,f.startPrice];
                    autoFibo.F2.p1 = [f.endDate,f.endPrice];
                    autoFibo.F2.conso = Number.parseFloat(f.consolidationFactor*100).toFixed(2);
                    autoFibo.F2.factor = Number.parseFloat(f.fibRatio).toFixed(2);
                }

            });
        }


        this.timeframe.forEach((tf, index) => {
            if (timeframe == tf) {
                const hlocData = self.updateChartData(hloc, tf, requestInfo);

                const lastX = self.getLastCandleX(index);
                //console.log(self.charts[''+index].data.data.chart.data.length, lastX);
                self.charts['' + index].data.canUpdate = false;// Merge as time series
                // Merge as time series
                if (self.charts['' + index] &&
                    self.charts['' + index].data &&
                    self.charts['' + index].data.data &&
                    self.charts['' + index].data.data.onchart) {
                    //console.log("update_points", 1);
                    self.charts['' + index].data.data.onchart.forEach((ind) => {
                        if (ind.settings && ind.settings.p1) {
                            ind.points = ind.points || {};
                            let idx = 0;
                            while (ind.settings['p'+(++idx)]) {
                                if (!ind.points['p' + idx]) {
                                    ind.points['p' + idx] = [this.getTimeFromIndex("0", ind.settings['p' + idx][0]), ind.settings['p' + idx][1]];
                                }
                            }
                        }
                    });
                }
                //self.charts.dc.get('chart.data') // -> [[10001, 1, 1, 1, 1 ], [10002, 1, 1, 1, 1 ]]
                //dc.merge('chart.data', [
                //    [10002, 2, 2, 2, 2 ], [10003, 3, 3√√, 3, 3 ]
                //])
                //self.charts[''+index].data.set('chart.data', hlocData);
                //self.chartObjects[''+index].dc.merge('chart.data', hlocData)
                //console.log(self.charts[''+index].data.data.chart.data.length, lastX);
                self.charts['' + index].data.canUpdate = false;
                try {
                //console.log("handleHistoryResponce",self.charts[''+index].data,index);
                    self.charts[''+index].data.set('chart.data', hlocData);
                } catch(e){
                    self.charts['' + index].data.canUpdate = true;
                }

                //self.charts['' + index].data.canUpdate = true;

                if (!requestInfo.start) {
/*                    Vue.set(self.charts['' + index], 'fibData', fibDataArray);*/
                    Vue.set(self.charts['' + index].settings, 'flags', []);
                    if (this.isAdmin) {
                        flags.forEach((f) => {
                            self.charts['' + index].settings.flags.push(f);
                        });
                    }
                    Vue.set(self.charts['' + index].settings, 'priceGaps', []);
                    if (this.isAdmin || (tf.indexOf('m')<0)) {
                        priceGaps.forEach((f) => {
                            self.charts['' + index].settings.priceGaps.push(f);
                        });
                    }
                    console.log('trends',trends);
                    Vue.set(self.charts['' + index].settings, 'trends', []);
                    trends.forEach((f) => {
                        //if (f.type == 'SUBMAIN')
                        //    console.log("submain",f);
                        if (f.type != 'CANDIDATE')
                            self.charts['' + index].settings.trends.push(f);
                    });
                    Vue.set(self.charts['' + index].settings, 'clusters', []);
                    clusters.forEach((f) => {
                        self.charts['' + index].settings.clusters.push(f);
                    });
                    Vue.set(self.charts['' + index].settings, 'extreams', []);
                    if (this.isAdmin) {
                        extremes.forEach((f) => {
                            self.charts['' + index].settings.extreams.push(f);
                        });
                    }
                    //console.log('check hori',tf.indexOf('M'),tf)
                    Vue.set(self.charts['' + index].settings, 'horizontals', []);
                    if (this.isAdmin || (tf.indexOf('M')<0)|| (tf=='MN')) {
                        horizontals.forEach((f) => {
                            self.charts['' + index].settings.horizontals.push(f);
                        });
                    }
                    Vue.set(self.charts['' + index].settings.fibo.F1, 'p1', autoFibo.F1.p1);
                    Vue.set(self.charts['' + index].settings.fibo.F1, 'p2', autoFibo.F1.p2);
                    Vue.set(self.charts['' + index].settings.fibo.F1, 'conso', autoFibo.F1.conso);
                    Vue.set(self.charts['' + index].settings.fibo.F1, 'factor', autoFibo.F1.factor);
                    Vue.set(self.charts['' + index].settings.fibo.F2, 'p1', autoFibo.F2.p1);
                    Vue.set(self.charts['' + index].settings.fibo.F2, 'p2', autoFibo.F2.p2);
                    Vue.set(self.charts['' + index].settings.fibo.F2, 'conso', autoFibo.F2.conso);
                    Vue.set(self.charts['' + index].settings.fibo.F2, 'factor', autoFibo.F2.factor);
                    Vue.set(self.charts['' + index].settings.fibo.FX[0], 'p1', autoFibo.FX[0].p1);
                    Vue.set(self.charts['' + index].settings.fibo.FX[0], 'p2', autoFibo.FX[0].p2);
                    Vue.set(self.charts['' + index].settings.fibo.FX[0], 'conso', autoFibo.FX[0].conso);
                    Vue.set(self.charts['' + index].settings.fibo.FX[0], 'factor', autoFibo.FX[0].factor);
                    Vue.set(self.charts['' + index].settings.fibo.FX[1], 'p1', autoFibo.FX[1].p1);
                    Vue.set(self.charts['' + index].settings.fibo.FX[1], 'p2', autoFibo.FX[1].p2);
                    Vue.set(self.charts['' + index].settings.fibo.FX[1], 'conso', autoFibo.FX[1].conso);
                    Vue.set(self.charts['' + index].settings.fibo.FX[1], 'factor', autoFibo.FX[1].factor);

                    this.updateFiboPos('' + index, true);


                    if (self.charts[''+index] &&
                        self.charts[''+index].data.agg &&
                        self.charts[''+index].data.agg.symbols['ohlcv']) {
                        self.charts['' + index].data.agg.symbols['ohlcv'].upd = [];
                        self.charts['' + index].data.agg.symbols['ohlcv'].t = 0;
                    }

                    this.chartServerInterface.requestChartsTemplates(this.displayData.symbol, timeframe);
                    this.chartServerInterface.aboUpdates(this.displayData.symbol, timeframe);
                    //self.charts['' + index].data.tv.$refs.chart.default_range();

                    this.resetChartObjects();
                    self.charts['' + index].data.canUpdate = false;

                    setTimeout((index, hlocData) => {
                        self.charts['' + index].data.canUpdate = true;
                        if (hlocData && (hlocData.length > 5)) {
                        /*    self.handlePriceUpdate(symbol, timeframe, {
                                candle: {
                                    //"t": hlocData[0],
                                    "timestamp": hlocData[0],
                                    "open": hlocData[1],
                                    "close": hlocData[4],
                                    "high": hlocData[2],
                                    "low": hlocData[3],
                                    "volume": hlocData[5],
                                    "range": 0
                                }});

                            if ((self.chartObjects['' + index]) && (self.chartObjects['' + index].$refs) && (self.chartObjects['' + index].$refs.tvjs))
                                self.chartObjects['' + index].$refs.tvjs.resetChart(false);
*/
                            self.updateIndicatorPoints(index);
                            //console.log('321-1-6',hlocData);
                        }

                       /* if (lastX) {
                            const len = self.charts[''+index].data.data.chart.data.length;
                            //console.log(this.charts[''+index].data.data);
                            if (len) {
                                this.charts[''+index].data.ww.dc.tv.goto(len);
                                const lastXOrg = self.getLastCandleX(index);
                                this.charts[''+index].data.ww.dc.tv.goto(len+10);
                                const lastX3 = self.getLastCandleX(index);

                                const fswitch = len+((1/(lastXOrg-lastX3))*(lastXOrg-lastX)*10);//len-((lastXOrg-lastX3)/(10)*(lastXOrg-lastX));
                                self.charts[''+index].data.ww.dc.tv.goto(fswitch);
                                self.charts[''+index].data.agg.data_changed = false
                                //console.log(self.charts[''+index].data);
                            }
                        }*/


                    }, 50, index, hlocData[hlocData.length-1]);
                }
                self.updateIndicatorPoints(index);
                if (requestInfo.start) {
                    if (requestInfo.start < hlocData[0][0]) {
                        //console.log('add_ind_load','load more on responce', tf, requestInfo.start, hlocData[0][0]);
                        setTimeout(this.loadMoreHistoryData.bind(this), 200, tf, requestInfo.start);
                    }
                }
                /*
                console.log(self.charts[''+index].data);
                */
            }
        });
    }

    updateIndicatorPoints(index) {
        const self = this;
        if (self.charts['' + index] &&
            self.charts['' + index].data &&
            self.charts['' + index].data.data &&
            self.charts['' + index].data.data.onchart) {
            //console.log("update_points",1);
            self.charts['' + index].data.data.onchart.forEach((ind) => {
                Vue.nextTick(() => {
                    //console.log("update_points", 2);
                    if (ind.settings && !ind.points) {
                        //console.log("update_points", 3);
                        ind.points = {};
                        let idx = 0;
                        while (ind.settings['p'+(++idx)]) {
                            ind.points['p' + idx] = [this.getTimeFromIndex("0", ind.settings['p' + idx][0]), ind.settings['p' + idx][1]];
                        }
                    }
                    //console.log("update_points", 4, ind.points);
                    let idx = 0;
                    while (ind.points['p'+(++idx)]) {
                        if (!ind.settings['p'+idx]) {
                            ind.settings['p'+idx] = [self.getIndexFromTime('' + index, ind.points['p'+idx][0]), ind.points['p'+idx][1]];
                        } else {
                            ind.settings['p'+idx][0] = self.getIndexFromTime('' + index, ind.points['p'+idx][0]);
                        }
                        //console.log("add_ind_1",'p' + idx, ind.points,ind.settings['p' + idx],ind.settings['p'+idx][0]);
                    }
                    //console.log("add_ind_reset handleTemplateResponce",ind.settings);
                    //if (ind.points.p1) ind.settings.p1[0] = self.getIndexFromTime('' + index, ind.points.p1[0]);
                    //if (ind.points.p2) ind.settings.p2[0] = self.getIndexFromTime('' + index, ind.points.p2[0]);
                    //if (ind.points.p3) ind.settings.p3[0] = self.getIndexFromTime('' + index, ind.points.p3[0]);
                    if ((self.chartObjects['' + index]) && (self.chartObjects['' + index].$refs) && (self.chartObjects['' + index].$refs.tvjs)) {
                        const i = self.chartObjects['' + index].$refs.tvjs.$refs.chart;
                        i.$children.forEach((c)=>{
                            //console.log('add_ind_c',c.id);
                            c.$children.forEach((sc)=>{
                                //console.log('add_ind_sc',sc.id);
                                sc.$children.forEach((ssc)=>{
                                    if (ind.id && ssc.id && ind.id.length && ssc.id.length) {
                                        const indName = "."+ssc.id.replace("_","")+"§";
                                        if ((ind.id+"§").indexOf(indName)>0) {
                                            //console.log('add_ind_ssc', ind.id+"§", indName,(ind.id+"§").indexOf(indName));
                                            if (ssc.updatePins) {
                                                ssc.updatePins();
                                            }
                                        }
                                    }
                                });
                            });
                        });
                    }
                },self);
                //}
            });
            //if (ind.points.p1) ind.settings.p1 = [this.getIndexFromTime("0",ind.points.p1[0]),ind.points.p1[1]];
            //if (ind.points.p2) ind.settings.p2 = [this.getIndexFromTime("0",ind.points.p2[0]),ind.points.p2[1]];
            //if (ind.points.p3) ind.settings.p3 = [this.getIndexFromTime("0",ind.points.p3[0]),ind.points.p3[1]];
        }
    }

    updateChartData(hloc, tf, requestInfo) {
        let tfData = requestInfo.start ? this.fullChartData.get(tf) : {data: [], lastRequestTime: Date.now(), lastRequestTimeTo: Date.now()};
        //console.log(hloc.length);
        //console.log('321-4-1', hloc[hloc.length-4]);
        //console.log('321-3-1', hloc[hloc.length-3]);
        //console.log('321-2-1', hloc[hloc.length-2]);
        //console.log('321-1-1', hloc[hloc.length-1]);
        if (hloc.length > 1) {
            let idxFirst = 0;
            let idxLast = hloc.length-1;
            if (tfData.data.length) {
                for (let i in hloc) {
                    if (hloc[i] < tfData.data[0][0]) {
                        idxLast = i;
                    }
                }
            }
            if (tfData.data.length>0)
                tfData.data = hloc.slice(idxFirst,idxLast-1).concat(tfData.data)
            else
                tfData.data = hloc;
        }
        this.fullChartData.set(tf, tfData);

        //console.log('321-4-2', tfData.data[tfData.data.length-4]);
        //console.log('321-3-2', tfData.data[tfData.data.length-3]);
        //console.log('321-2-2', tfData.data[tfData.data.length-2]);
        //console.log('321-1-2', tfData.data[tfData.data.length-1]);
        return tfData.data;
    }

    loadMoreHistoryData(tf, leftTimePos) {
        let diff = 1000*24*60*60;
        if (tf.indexOf('H')>=0)
            diff *= 80;
        if (tf.indexOf('D')>=0)
            diff *= 104;
        if (tf.indexOf('W')>=0)
            diff *= 600;
        let tfData = this.fullChartData.get(tf);
        if (tfData && tfData.data && tfData.data[0]) {
            let timeToRequest = leftTimePos ? Math.min(tfData.data[0][0] - diff, leftTimePos) : tfData.data[0][0] - diff;
            if (tfData.data[0] && (tfData.lastRequestTime > timeToRequest) && (tfData.lastRequestTimeTo > tfData.data[0][0])) {
                //console.log('start', tfData.data[0][0]-1000*24*60*60*10);
                tfData.lastRequestTime = timeToRequest;
                tfData.lastRequestTimeTo = tfData.data[0][0];
                this.chartServerInterface.requestHistory(this.displayData.symbol, tf, timeToRequest + this.timeOffset, tfData.data[0][0] + this.timeOffset);
            }
        }
    }

    saveTemplate(type, id, data, headline, text, commentInternal, resourceInfo,  labelActions, publishedAnalysis, abos) {
        //console.log(type, data);
        if (type == 'template') {
            if ('TIMEFRAME' in data)
                delete data.TIMEFRAME;
            if ('TAGS' in data)
                delete data.TAGS;
        }
        this.chartServerInterface.saveTemplate(type, id, data, this.charts['' + this.activeChart].symbol, this.charts['' + this.activeChart].tf, headline, text, commentInternal, resourceInfo,  labelActions, publishedAnalysis, abos);
    }

    deleteTemplate(type, id) {
        this.chartServerInterface.deleteTemplate(type, id);

    }

    saveSettings(data) {
        console.log('saveSettings',data)
        this.chartServerInterface.saveSettings(data);
        this.updateTheme();
    }

    getChartConfig(id) {

        //console.log('getChartConfig',this.charts[id]);
        const data = {visibleBars: this.getVisibleBars(0), rightSpace: this.getRightSpace(0),indicators: []};
        this.charts["0"].data.data.onchart.forEach((i) => {
            if (!this.isSpecialIndicator(i.name))
                data.indicators.push({name: i.name, type: i.type, settings: i.settings, id: i.id,data : []});
        });
        this.charts["0"].data.data.offchart.forEach((i) => {
            //console.log(i);
            if (!this.isSpecialIndicator(i.name))
                data.indicators.push({name: i.name, type: i.type, settings: i.settings, id: i.id,data : [], grid: {height: 0.2}});
        });
        return data;
    }

    isTemplateIndicator(ind) {
        return !((ind =='HorizontalLine') || (ind =='VerticalLine') || (ind =='MarkerInfo') || (ind =='TXT') || (ind =='FIB') || (ind =='RangeTool') ||
            (ind =='TrendChannel') || (ind =='Rectangle') || (ind =='LineTool') || (ind =='GhostFeed') || (ind =='CRV') || (ind =='DrawingLines'));
    }

    getTemplateConfig(id) {
        //console.log('getTemplateConfig',this.charts[id],i.id);
        const data = {indicators: [], visibleBars:  this.getVisibleBars(0), rightSpace: this.getRightSpace(0)};
        this.charts["0"].data.data.onchart.forEach((i) => {
            //console.log(i);
            if ((!this.isSpecialIndicator(i.name)) && (this.isTemplateIndicator(i.name)))
                data.indicators.push({name: i.name, type: i.type, settings: i.settings, id: i.id,data : []});
        });
        this.charts["0"].data.data.offchart.forEach((i) => {
            //console.log(i);
            if (!this.isSpecialIndicator(i.name))
                data.indicators.push({name: i.name, type: i.type, settings: i.settings, id: i.id,data : [], grid: {height: 0.2}});
        });
        return data;
    }

    updateContextMenue() {

        let res = null;

        if (this.activeTool)
            res = [{
                text: 'Make to Favourite',
                icon: 'iconfont bi-plus-circle',
                data: 'favourite'
            }, {
                text: 'Duplicate',
                icon: 'iconfont icon-xiayishou',
                data: 'duplicate'
            }, {
                text: 'Delete',
                icon: 'iconfont icon-xiayishou',
                data: 'delete'
            }, {
                text: 'zur Animation hinzufügen',
                icon: 'iconfont icon-xiayishou',
                data: 'addAnimation'
            }]
        else {
            res = [{
                text: 'add to watchlist',
                icon: 'fa fa-plus-circle',
                id: 6,
                children: [{text: '< new watchlist >'}]
            }, {
                text: 'remove from watchlist',
                icon: 'fa fa-minus-circle',
                children: []
            }, {
                text: 'LineTool',
                icon: 'iconfont icon-xiayishou',
                data: 'LineTool:Price'
            }, {
                text: 'Trend',
                icon: 'iconfont icon-xiayishou',
                data: 'LineTool:Extended'
            }, {
                text: 'HorizontalLine',
                icon: 'iconfont icon-xiayishou',
                data: 'HorizontalLine:Segment'
            }, {
                text: 'VerticalLine',
                icon: 'iconfont icon-xiayishou',
                data: 'VerticalLine:Segment'
            }, {
                text: 'Rectangle',
                icon: 'iconfont icon-xiayishou',
                data: 'Rectangle:Segment'
            },{
                text: 'Extreams ein-/ausblenden',
                id: 100,
                isAdmin: true,
                children: [
                    {text: 'Level 0', checked: ((this.chartSettings.bitMaskExtreams &  1) === 1)},
                    {text: 'Level 1', checked: ((this.chartSettings.bitMaskExtreams &  2) === 2)},
                    {text: 'Level 2', checked: ((this.chartSettings.bitMaskExtreams &  4) === 4)},
                    {text: 'Level 3', checked: ((this.chartSettings.bitMaskExtreams &  8) === 8)},
                    {text: 'Level 4', checked: ((this.chartSettings.bitMaskExtreams &  16) === 16)},
                    {text: 'unknown', checked: ((this.chartSettings.bitMaskExtreams &  32) === 32)}]
            },];
        let addList = [];
        let delList = [];
        this.displayData.marketLists.forEach((wl) => {
                if ((wl.userId > 0) || (this.isAdmin)) {
                    let bFound = false;
                    wl.details.forEach((wld) => {
                        if (wld.symbol == this.displayData.symbol)
                            bFound = true;
                    })

                    let caption = wl.marketName;
                    if (wl.userId == 0)
                        caption += " - (global)";
                    if (wl.userId < 0)
                        caption += " - (intern)";
                    if (bFound)
                        res[1].children.push({text: caption, marketListId: wl.marketListId});
                    else
                        res[0].children.push({text: caption, marketListId: wl.marketListId});
                }
            });
        }

        setTimeout( () => {
            while(this.contextMenu.menu.pop()) {};

            for (let i in res) {
                this.contextMenu.menu.push(res[i]);
            }
        }, 10);

        //console.log(this.contextMenu)
        //Vue.set(this.watchLists.contextMenu[0],'children',addList);
        //Vue.set(this.watchLists.contextMenu[1],'children',delList);

    }

    chartCount() {
        return this.timeframe.length
    }

    changeIndicatorValue(setting, value, force, noSendLiveChart) {
        //console.log(setting, value, force, noSendLiveChart);
        const self = this;
        const chart = this.charts[this.activeChart];
        const indList = setting.indexOf('onchart')>=0 ? chart.data.data.onchart : chart.data.data.offchart;
        const indName = setting.substr(0,setting.indexOf('#'));
        const settingName = setting.substr(setting.indexOf('#')+1);

        indList.forEach((ind) => {
            if (indName == ind.id) {
                //console.log('<<<<', setting, ind);
                //if (ind.settings) {
                //    Vue.set(ind.settings,settingName,value);
                //}
                if (ind.parameters) {
                    Vue.set(ind.parameters,settingName,value);
                }
                if (!ind.props)
                    ind.props = {};
                //Vue.set(ind,'data',[]);
                //
                //this.chartObjects['0'].$refs.tvjs.$refs.chart.data.dc.merge(`${settingName}.settings`,value);
                this.charts[this.activeChart].data.ww.dc.merge(`${indName}.settings`,{[settingName]:value});
                //if (force)
                //    this.charts[this.activeChart].data.ww.dc.initNew(true);
                //ind.set_state('test');

                //console.log('sendLiveChart #live 0',self.isSendLiveChart(),!noSendLiveChart,ind.id,ind.name,ind.settings.liveId ,ind.settings)
                if (self.isSendLiveChart() && (!noSendLiveChart)) {
                    ind.settings.liveId = ind.settings.liveId || Utils.uuid2();
                    let settings = ind.settings;

                    if (indName.indexOf("DrawingLines")>=0) {
                        let idx = 0;
                        while (ind.points['p' + (++idx)]) {
                            settings['p' + idx] = ind.points['p' + idx];
                            //console.log("add_ind_0",'p' + idx, ind.points,ind.settings['p' + idx] );
                        }
                    }
                    self.sendLiveChart(ind.id,ind.name,ind.settings.liveId , settings);
                }
            }
        });
        //this.chartObjects['0'].$refs.tvjs.$refs.chart.update_layout(true);
        //chart.data.data_changed();
        //let range = this.chartObjects[this.activeChart].$refs.tvjs.$refs.chart.range;
        //console.log('reset - changeIndicatorValue');
        if (force) {
            this.chartObjects[this.activeChart].$refs.tvjs.resetChart(false);
          //  this.chartObjects[this.activeChart].$refs.tvjs.$refs.chart.range_changed(range);
        }
    }

    setSelectedTrendConfig(config, value) {
        Vue.set(this.settings,config,value);
        this.chartObjects[this.activeChart].$refs.tvjs.resetChart(false);
    }

    getShowCfg(name) {
        console.log(name, this.showCfgList.indexOf(name) >= 0);
        return this.showCfgList.indexOf(name) >= 0;
    }

    toggleShowConfig(name) {
        console.log(name);
        if (this.getShowCfg(name)) {
            this.showCfgList.splice(this.showCfgList.indexOf(name),1);
        } else {
            this.showCfgList.push(name);
        }
    }

    setToolDisplay(id, display) {
        let DC = window.chartHandler.charts[this.activeChart].data;
        let d = DC.get(id)[0];

        if (d) {
            if ((!display) == ((d.settings.display == undefined || d.settings.display == true))) {
                DC.merge(id + ".settings", {
                    display: display,
                });
            }
        }
    }

    toggleVisible(id, event) {
        console.log(id);
        let DC = window.chartHandler.charts[this.activeChart].data;

        var display_off =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAU1QTFRFAAAAh4eHh4eHAAAAAAAAAAAAAwMDAAAAAAAAhoaGGBgYgYGBAAAAPz8/AgICg4ODCQkJhISEh4eHh4eHPj4+NjY2gYGBg4ODgYGBgYGBgoKCAQEBJycngoKChYWFEBAQg4ODCAgIKioqZGRkCgoKBQUFERERd3d3gYGBGxsbNDQ0hISEgYGBPDw8gYGBgYGBh4eHh4eHhYWFh4eHgoKChYWFgYGBgYGBg4ODhoaGg4ODYWFhgoKCBgYGdXV1goKCg4ODgYGBgICAgYGBAAAAg4ODhYWFhISEh4eHgoKChYWFOjo6goKCGhoah4eHh4eHh4eHgoKCh4eHeXl5hoaGgoKChISEgYGBgYGBgoKCY2NjgYGBgoKCh4eHgoKCgYGBhoaGg4ODhoaGhYWFh4eHgYGBhoaGhoaGhoaGg4ODgoKChISEgoKChYWFh4eHfKktUwAAAG90Uk5TACn/AhEFKA8SLCbxCigoVBNKUTYoJ/lh3PyAKSaTNiBtICYpISggKSkmJ0LEKef3lGxA8rn//+pcMSkpnCcptHPJKe0LUjnx5LzKKaMnX73hl64pLnhkzNSgKeLv17LQ+liIzaLe7PfTw5tFpz3K1fXR/gAAAgBJREFUeJzllNdXwjAUxknB0lIoCKVsGTIFQRAZ7r333nuv///R3LZ4mlDQZ/0ekp7b37n5bnITk+mfyDxv5Tir3fwjaElO5BIOKZFLJS1dQVfI0Y809TtEV+elo95RpFPWG+1go4fdQ5QybI8haaNBkM2ANbM09bnrwaPY7iFKrz7EMBdu7CHdVruXIt0M1hb+GKA3LTRKkp5lTA6Dg6xIkhaHhvQ1IlW/UCouQdJNJTRIpk1qO7+wUpcfpl537oBc7VNip3Gi/AmVPBAC1UrL6HXtSGVT+k2Yz0Focad07OMRf3P5BEbd63PFQx7HN+w61JoAm+uBlV48O/0jkLSMmtPCmQ8HwlYdykFV4/LJPp7e3hVyFdapHNehLk6PSjhSkBvwu/cFyJGIYvOyhoc1jjYQFGbygD4CWjoAMla/og3YoSw+KPhjPNoFcim4iFD+pFYA8zZ9WeYU5OBjZ3ORWyCfG03E+47kKpCIJTpGO4KP8XMgtw990xG/PBNTgmPEEXwf7P42oOdFIRAoBCtqTKL6Rcwq4Xsgh5xYC/mmSs6yJKk1YbnVeTq1NaEpmlHbmVn2EORkW2trF2ZzmHGTSUMGl1a9hp4ySRpdQ8yKGURpMmRIYg9pb1YPzg6kO79cLlE6bYFjEtv91bLEUxvhwbWwjY13BxUb9l8+mn9EX8x3Nki8ff5wAAAAAElFTkSuQmCC";
        var display_on =
            "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAR1QTFRFAAAAh4eHgYGBAAAAAAAAgYGBAAAAAwMDAAAAAAAAgYGBg4ODGBgYgYGBhISEAAAAPz8/AgIChoaGCQkJhYWFPj4+NjY2goKCgYGBAQEBJycngYGBgoKCEBAQCAgIhISEKioqZGRkCgoKBQUFERERd3d3gYGBg4ODgYGBGxsbNDQ0hISEgoKCgoKChYWFPDw8gYGBgYGBhoaGgoKCg4ODgoKCgYGBgoKCgoKCgoKCg4ODgoKChoaGgoKCgYGBhoaGg4ODYWFhBgYGdXV1gYGBg4ODgoKCgICAg4ODg4ODhISEAAAAg4ODOjo6gYGBGhoaeXl5goKCgYGBgoKChYWFgoKChISEgoKCY2NjgYGBg4ODgYGBgYGBg4ODgYGBo8n54AAAAF90Uk5TACn/AhH3BSgPEuhUJvFACigoLBM2KCeA6ykm+pMgIEkmKSEoICn9XCkmJ0u6nDop4sUypGuEzLZ6vmCYLZ/dLykpJynUYa8pcllCC1Ip2ycpisl1PadFsintbsPQZdi/bTW7AAAB4UlEQVR4nOWUZ1fCMBSGSSGWFiq0UDbIkr2XbBwMxS0b1P//M0xK9XSiftX7oel585zkvfcmMRj+SRhvzRRlthm/BU3Ry3TYzofTsajpIOjw2iNAjIiddehvHXSdA0mkXEEdG0fkE1DEKXmkSVqVIA6rBmsktUgAWLWHoGp30UNclbtLmwQgoyya91wPTbFy0mQXJ5zJQO6BgXRjfH0iSkX5stHIXr5r0bB/lu8syjR8rzsFbR2SpX+5J2eMP3csLtYsEY2K8BeTFuE2jaVCBw7bHOBuxq16AXmpbui3LtIfbRLUHMY2q4lcFo2WB4KA1SUAlWumNEKCzyxBKZxVHvYGaFguCBx1vM/x0IPzoqQoj5SdP4mns2cCGhBsrgj0uaeUBtzMyxQN8w4mYROTW8+r0oANp8W5mf6WQw5aCYJ2o7ymPaKMi2uVpmWM4TW6tdImgGo1bT4nK6DbbsCc0AZSdmLEFszzHrh6riVvRrNA3/9SE8QLWQu+Gjto9+gE9NBMwr9zi83gFeeFTe11zpm1CHE3HeyVCSknf3MIDcFTbfJKdbR1L4xX49L+/BoillV5uPJqkshD3JWSgpNMXP/lcrD8+hO84MnDr5YpFHv0Fe99VjJ0GBRs2H74aP6R+ACr+TFvZNAQ1wAAAABJRU5ErkJggg==";

        let d = DC.get(id)[0];

        if (d) {
            if (d.settings.display == undefined || d.settings.display == true) {
                DC.merge(id + ".settings", {
                    display: false,
                });

                if (event)
                    event.target.src = display_off;
            } else {
                DC.merge(id + ".settings", {
                    display: true,
                });


                if (event)
                    event.target.src = display_on;
            }
        }
    }

    delIndicator(id) {
        console.log('removeIndicator',id);
        let DC = window.chartHandler.charts[this.activeChart].data;
        DC.del(id);
        // document.querySelector('[indicatorid = "' + id + '"]').remove();

        // hide the context menu
        if (document.querySelector(".context-menu")) document.querySelector(".context-menu").remove();

        this.updateFiboPos('0', false);
        this.simulateClick(this.activeChart);
    }

    getIndicatorConfigFieldTemplates(indName) {
        return this.configHandler.getConfigFieldTemplates(indName);
    }

    requestShortcuts() {
        this.chartServerInterface.requestShortcuts();
    }

    requestUserFavorites() {
        this.chartServerInterface.requestUserFavorites();
    }

    requestUserViews() {
        this.chartServerInterface.requestUserViews();
    }

    setUserRight(action, rightid, userId) {
        if (userId > 0) {
            this.chartServerInterface.setUserRight(action, rightid, userId);
        }
        this.updateRights();
    }

    handleGetUserRights(data) {
        const self = this;
        while (this.users.userRights.pop()) ;
        data.userRights.forEach((t) => {
            //console.log('handleGetUserRights',t);
            self.users.userRights.push(t)
        });
        this.updateRights();
    }

    handleSetUserRights(data) {
        const self = this;
        //console.log('handleSetUserRights 1', data);
        if (data.cmd === 'd') {
            self.users.userRights.forEach((t, i) => {
                if ((t.userId === data.userId) && (t.rightId === data.rightId)) {
                    //console.log('handleSetUserRights', 'd', t.rightId, t.userId);
                    self.users.userRights.splice(i, 1);
                }
            });
        }
        if (data.cmd === 'a') {
            //console.log('handleSetUserRights', 'a', data.rightId, data.userId);
            self.users.userRights.push({rightId: data.rightId, userId: data.userId})
        }
        this.updateRights();
    }

    handleGetRights(data) {
        const self = this;
        while (this.users.rights.pop()) ;
        data.rights.forEach((t) => {
            t.label = t.title;
            //console.log('handleGetRights',t);
            self.users.rights.push(t)
        });
        this.updateRights();
    }

    handleGetUserInfos(data) {
        //console.log(data);
        if (data && data.userInfos && data.userInfos.length) {
            const self = this;
            data.userInfos.forEach((ui) => {
                self.userInformations.data.push(ui);
            });

            Vue.set(this.userInformations,'count',this.userInformations.data.length);
        }
    }

    handleGetUsers(data) {
        const self = this;
        while (this.users.users.pop()) ;
        data.users.forEach((t) => {
            t.userRole = t.userRole || 'USER';
            t.label = t.lastName+", "+t.firstName+" ("+t.email+")";
            self.users.users.push(t)
        });
    }

    handleShortcutsResponce(shortcuts) {
        const self = this;
        while (this.settings.shortcuts.pop()) ;
        shortcuts.forEach((t) => {
            const sc = JSON.parse(t.SHORTCUTS);
            Object.keys(sc).forEach((s) => {
                //console.log(sc[s]);
                self.settings.shortcuts.push(sc[s]);
            });
        });
        //"ID" : 7,
        //"SHORTCUTS": "[{\"NAME\":\"Cursor\",\"TYPE\":\"Cursor\",\"SHORTCUT\":\"ctrl+3\"},{\"NAME\":\"LineTool Price\",\"TYPE\":\"LineTool:Price\",\"SHORTCUT\":\"ctrl+4\"},{\"NAME\":\"LineTool Extended\",\"TYPE\":\"LineTool:Extended\",\"SHORTCUT\":\"ctrl+5\"},{\"NAME\":\"TrendChannel\",\"TYPE\":\"TrendChannel:Segment\",\"SHORTCUT\":\"ctrl+6\"},{\"NAME\":\"Rectangle\",\"TYPE\":\"Rectangle:Segment\",\"SHORTCUT\":\"ctrl+8\"}]",
        //"CREATED_BY": "22"
    }

    updateRights() {
        const self = this;
        if ((this.users.rights.length) && (this.users.userRights.length)) {
            this.users.rights.forEach((r) => {
                if (r.groupKey == "RIGHT") {
                    self.users.userRights.forEach((ur) => {
                        if (ur.rightId == r.rightId) {
                            if (r.rightKey === 'SYMBOLS') {
                                Vue.set(self.rights,'useSymbols',true);
                            }
                            if (r.rightKey === 'INDICATORS') {
                                Vue.set(self.rights,'useIndicators',true);
                            }
                            if (r.rightKey === 'PUBLISH') {
                                Vue.set(self.rights,'canPublish',true);
                            }
                            if (r.rightKey === 'LAYOUT') {
                                Vue.set(self.rights,'useLayout',true);
                            }
                            if (r.rightKey === 'TEMPLATES') {
                                Vue.set(self.rights,'useTemplates',true);
                            }
                            if (r.rightKey === 'CHARTS') {
                                Vue.set(self.rights,'useSaveCharts',true);
                            }
                            if (r.rightKey === 'MULTISCREEN') {
                                Vue.set(self.rights,'useMultiScreen',true);
                            }
                            if (r.rightKey === 'RIGHTS') {
                                Vue.set(self.rights,'useUserRights',true);
                            }
                        }
                    });
                }
            });
        }
    }
/*
    setAbos(abos) {
        const self = this;
        this.abos.info = abos;
        //console.log('setAbos', abos)
        if (abos && abos.length) {
            abos.forEach((a) => {
                self.abos.aboData.set(a.name, a);
            });
        }
    }
*/
    handleUserApiLogin(loginResult) {
        /*if (loginResult) {
            this.setRights(loginResult.rights);
            this.setAbos(loginResult.abos);
        }*/
        if (!this.isApp) {
            this.chartServerInterface.requestLastChartView();
            this.chartServerInterface.requestUserFavorites();
            this.chartServerInterface.requestUserInfos();
            this.chartServerInterface.requestAndAboDaxenInfos();
            this.chartServerInterface.requestRights();
            this.chartServerInterface.requestUserRights();
            this.chartServerInterface.requestMarketLists();
            this.chartServerInterface.requestToolSettings();
            this.chartServerInterface.requestUserViews();
            setTimeout(() => {
                this.chartServerInterface.requestLiveChartList();
            },500);
            if (this.isAdmin) {
                this.chartServerInterface.requestUsers();
                this.chartServerInterface.requestVideos();
                this.requestAlerts(0, 1000);
            }
        }
    }

    handleSetMarketList(data) {
        //console.log('handleSetMarketList', data)
        if (data) {
            const self = this;
            let ml = {marketListId:data.marketListId,marketName:data.description,createDate:data.createDate,userId:data.userId,details: []};
            self.displayData.marketLists.push(ml);
        }

    }

    handleGetMarketLists(data) {
        console.log('handleGetMarketLists', data)
        while (this.displayData.marketLists.pop()) ;
        if (data && data.data && (data.data.length>=0)) {
            const self = this;
            let res = [];
            data.data.forEach((ml) => {
                //res.push(ml);
                ml.details = [];
                ml.sortKey = 'id';
                ml.sortDir = 1;
                ml.svg = "";
                ml.getOptionKey = (ml) => { return ml.marketListId; }
                self.displayData.marketLists.push(ml);
                self.chartServerInterface.requestMarketListDetails(ml.marketListId);
            });
            // Vue.set(this.displayData,'marketLists',res);
            /*data.data.forEach((ml) => {
                self.displayData.marketLists.push(ml);
            });*/
        }
        this.updateMarketListsLayout();
    }

    handleGetMarketListDetails(data) {
        const self = this;
        //console.log('handleGetMarketListDetails',data);
        if (data && (data.marketListId >= 0)) {
            this.displayData.marketLists.forEach((ml)=>{
                if (data.marketListId === ml.marketListId) {
                    while (ml.details.pop()) ;
                    data.data.forEach((mld) => {
                        mld.price = 0.0;
                        mld.percent = 0.0;
                        mld.new = false;
                        mld.newUp = true;
                        self.chartServerInterface.aboUpdates(mld.symbol, "D1")
                        ml.details.push(mld);
                        mld.colIndex = mld.colIndex || ml.details.length;
                        mld.colIndexOrg = mld.colIndex;
                        mld.owner = ml;
                    });
                    this.marketListChangeDirection('colIndex', data.marketListId);
                }
            });
        }
        this.updateContextMenue();
        this.updateMarketListsLayout();
    }

    setSelectedMarketOverviewId(value) {
        this.displayData.marketLists.forEach((ml)=>{
            if (value === ml.marketListId) {
                this.chartSettings.currentMO = ml.marketName;
            }
        });
        this.saveSettings(JSON.stringify(this.chartSettings));
    }

    handleSetMarketListDetail(data) {
        const self = this;
        if (data && (data.marketListId >= 0)) {
            this.displayData.marketLists.forEach((ml)=>{
                if (data.marketListId === ml.marketListId) {
                    let mld = {price : 0.0, percent : 0.0, symbol: data.symbol, new: false, newUp: true}
                    self.chartServerInterface.aboUpdates(mld.symbol, "D1")
                    ml.details.push(mld);
                    mld.colIndex = mld.colIndex || ml.details.length;
                    mld.colIndexOrg = mld.colIndex;
                    mld.owner = ml;
                }
            });
        }
        this.updateContextMenue();
        this.updateMarketListsLayout();
    }

    handleDelMarketListDetail(data) {
        if (data && (data.marketListId >= 0)) {
            this.displayData.marketLists.forEach((ml)=>{
                if (data.marketListId === ml.marketListId) {
                    let foundIdx = -1;
                    ml.details.forEach((mld, idx)=>{
                        if (mld.symbol === data.symbol) {
                            foundIdx = idx;
                        }
                    });
                    if (foundIdx>=0)
                        ml.details.splice(foundIdx,1)
                }
            });
        }
        this.updateContextMenue();
        this.updateMarketListsLayout();
    }

    updateMarketListsLayout() {
        //console.log('updateMarketListsLayout', this.displayData.marketLists.length, this.displayData.layoutConfig)
        if ((this.displayData.marketLists.length>0) && (this.displayData.layoutConfig !== null)) {
            while (this.displayData.marketListsLayout.pop()) ;
            this.displayData.marketLists.forEach((ml) => {
                ml.id = ml.marketListId;
                //console.log('updateMarketListsLayout item', ml)
                this.displayData.marketListsLayout.push(ml);
                this.updateMarketListSVG(ml);
            });
        }
    }

    marketListChangeDirection(col, marketListId) {
        //console.log('changeDirection',col, marketListId)
        if (marketListId >= 0) {
            this.displayData.marketLists.forEach((ml)=>{
                if (ml.marketListId === marketListId) {
                    if (ml.sortKey === col)
                        ml.sortDir *= -1;
                    else
                        ml.sortKey = col;
                    ml.details.sort(this.sortMarketList);
                }
            });
        }
    }

    updateMarketListSVG(ml) {
        let percentSum = 0;
        let elements = [];
        let svg = ""
        let countUp = 0;
        let countDown = 0;
        ml.details.forEach((mld) => {
            percentSum += Math.abs(mld.percent);
            elements.push(mld);
            if (mld.percent>=-0.00001)
                countUp++;
            else
                countDown++;
        })

        elements.sort((f1, f2) => { return Math.abs(f2.percent) - Math.abs(f1.percent);})

        const xOffset = 6;
        const yOffset = 40;
        const xGlobal = 988;
        const yGlobal = 794;
        let xSize = 0;
        let ySize = 0;

        let iLastPrint = -1;
        let fPrintPercent = 0;

        elements.forEach((elem) => {
            if (Math.abs(elem.percent)>0.0001) {
                let s = (xGlobal * yGlobal) / 100 * ((100 * Math.abs(elem.percent)) / percentSum);
                const dir = xSize > ySize;
                let x, y;
                if (!dir) {
                    y = (yGlobal-ySize);
                    x = s/(y)
                } else {
                    x = (xGlobal-xSize);
                    y = s / (x);
                }
                const p1 = Math.trunc(xSize)+1.5+xOffset;
                const p2 = Math.trunc(ySize)+1.5+yOffset;
                const p3 = Math.trunc(xSize+x)-1.5+xOffset;
                const p4 = Math.trunc(ySize+y)-1.5+yOffset;

                //return '#a20c31';
                //return '#167540';
                //let col = elem.percent>=0 ? '#469b53':  '#e84f4f';
                let col = elem.percent>=0 ? '#167540':'#a20c31';
                let rotate = !dir && ((p4-p2) > (p3-p1)) ? " rotate" : ""
                let svg1 = "<g><polygon class=\"st1\" style=\"fill:"+col+";\"  points=\""+p1+","+p2+" "+p3+","+p2+" "+p3+","+p4+" "+p1+","+p4+"\"></polygon>\n";

                if (rotate.length >5)
                    svg1 += "<text class=\"sub-text "+rotate+"\" x=\""+((-p4+20))+"\" y=\""+(p3-15)+"\"  > "+ elem.symbol +"</text>\n";
                else
                    svg1 += "<text class=\"sub-text "+rotate+"\" x=\""+(p1+15)+"\" y=\""+(p4-15)+"\"  > "+ elem.symbol +"</text>\n";

                svg1 += "<text class=\"sub-text\" style=\"fill:rgb(45,58,61);\"  text-anchor=\"end\" x=\"490\" y=\"32\"  > "+ elem.symbol +"</text>\n";
                svg1 += "<text class=\"sub-text\" style=\"fill:"+col+";\" x=\"510\" y=\"32\"  > "+elem.price.toFixed(4)+" ("+ elem.percent.toFixed(2) +" %)</text>\n";
                svg1 += "</g>";

                svg = svg1 + svg;

                //if (ml.marketListId == 1) {
                //    console.log('ele', xGlobal * yGlobal, s, (100 * Math.abs(elem.percent)) / percentSum); //ml.marketListId, percentSum, (xGlobal * yGlobal) / 100, (100 * Math.abs(elem.percent)) / percentSum, elem.percent, s, xGlobal * yGlobal, xSize, ySize, x, y);
                //}
                if (!dir) {
                    xSize = xSize + x;
                } else  {
                    ySize = ySize + y;
                }
            }
        })

        //console.log(svg)

        Vue.set(ml, 'countUp', countUp);
        Vue.set(ml, 'countDown', countDown);

        Vue.set(ml,"svg","<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" "+
            "xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"" +
            "500px\" height=\"420px\" viewBox=\"0 0 1000 840\" style=\"enable-background:new 0 0 1000 840;\" xml:space=\"preserve\">\n" +"\n" +
            "<polygon style=\"fill:rgb(222,222,222);\"  points=\"0,0 1000,0 1000,850 0,850\"></polygon>\n"+
            svg + "</svg>");
        /*
        "  <polygon style=\"fill:#b7044f;\"  points=\"0,0 300,0 300,300 0,300 \">\n" +
        "  </polygon>\n" +
        "    <polygon style=\"fill:#0491B7;\" points=\"0,0 600,0 300,300 0,300 \">\n" +
        "  </polygon>\n" +
        "    <text x=\"10\" y=\"40\"> SAP </text>\n" +
        "    <text x=\"40\" y=\"70\" class=\"sub-text\" style=\"font-size:0.5em;font-size:0.5em;fill:#0491B7;\">Some Content</text>\n" +
        "    </g>\n" +
        "\n" +
        "  <polygon style=\"fill:#b7044f;\"  points=\"0,353.813 649,353.813 891.5,663.313 0,663.313 \">\n" +
        "    <title>Two</title>\n" +
        "  </polygon>\n" +
        "\n" +
        "  <polygon style=\"fill:#b76846;\"  points=\"392.5,347.313 514.75,179.813 645.25,347.313 \">\n" +
        "    <title>Three</title>\n" +
        "  </polygon>\n" +
        "\n" +
        "  <polyline class=\"st2\" points=\"518.875,173.388 644.667,0.501 1139.833,0 1139.75,662.063 897.25,662.063\">\n" +
        "    <title>Four</title>\n" +
        "  </polyline>\n" +'*/
        //"</svg>");
    }

    handleUserApiDaxen(daxenMessage) {
        //console.log(daxenMessage);
        if (daxenMessage.type == 'info') {
            this.daxenHandler.handleInfoUpdate(daxenMessage)
        } else if (daxenMessage.type == 'activeRun') {
            this.daxenHandler.handleRunUpdate(daxenMessage.data)
        }
    }

    handleInstanceMessage(message) {
        if (message && message.msg) {
            //console.log('handleInstanceMessage', message.msg);
            const msg = JSON.parse(message.msg);
            this.messanger.instanceMessages.push({id: msg.message_id, from: msg.from.first_name, text: msg.text, time: msg.time ||  (msg.date*1000)})
        }
        setTimeout(() => {
            if ($('#messagesM'))
                $('#messagesM').scrollTop(1000000);
        }, 100);
        //
    }

    handleVideoUpdate(videos) {
        const self = this;
        //console.log("handleVideoUpdate",videos)
        if (videos) {
            videos.forEach((v) => {
                let vsearch = false;
                self.adminData.liveVideos.forEach((sv) => {
                    if (sv.id == v.id) {
                        vsearch = true
                    }
                })
                if (!vsearch) {
                    self.adminData.liveVideos.push(v);
                }
            })
        }
    }

    handleAlertUpdate(alertsMsg) {
        const self = this;
        if (alertsMsg && alertsMsg.msg) {
            //console.log('alerts', alertsMsg.msg);
            const alerts = JSON.parse(alertsMsg.msg);
            if (alerts.data) {
                alerts.data.forEach((alert) => {
                    var indexFound = -1
                    const lastInDate = new Date(alert.lastIn);
                    alert.key = alert.name+'#'+alert.timeBase+'#'+alert.rule;
                    alert.lastInDate = lastInDate.toString();
                    alert.lastInDate = alert.lastInDate.substring(0,alert.lastInDate.indexOf("GMT"));
                    if (alert.lastOut) {
                        const lastOutDate = new Date(alert.lastOut);
                        alert.lastOutDate = lastOutDate.toString();
                        alert.lastOutDate = alert.lastOutDate.substring(0,alert.lastOutDate.indexOf("GMT"));
                    } else {
                        alert.lastOutDate = ""
                    }
                    self.adminData.alerts.forEach((currentAlert, index) => {
                        if ((currentAlert.name == alert.name) && (currentAlert.timeBase == alert.timeBase) && (currentAlert.rule == alert.rule)) {
                            Vue.set(self.adminData.alerts, index, alert);
                            indexFound = index;
                        }
                    });
                    if (indexFound < 0)
                        self.adminData.alerts.push(alert);
                });
            }

            Vue.set(self.adminData, 'alerts', self.adminData.alerts);
            this.updateAlertSearchData();
        }
    }

    updateAlertSearchData(searchText) {
        const self = this;

        if (searchText !== undefined)
            self.adminData.search = searchText;

        while (self.adminData.filtered.pop()) {
        }

        const search = self.adminData.search.toUpperCase();
        self.adminData.alerts.forEach((alert) => {
            if (alert.name.indexOf(search) >= 0)
                self.adminData.filtered.unshift(alert)
            else if (alert.rule.toUpperCase().indexOf(search) >= 0)
                self.adminData.filtered.unshift(alert)
        })

        self.adminData.filtered.sort((a1,a2)=> {
            return a2.lastIn - a1.lastIn
        });

        //self.adminData.filtered.splice(50)

        Vue.set(self.adminData, 'filtered', self.adminData.filtered);

        setTimeout(() => {
                    if(this.chartObjects["0"] &&
                        this.chartObjects["0"].$parent &&
                        this.chartObjects["0"].$parent.$parent &&
                        this.chartObjects["0"].$parent.$parent.$parent)
                this.chartObjects["0"].$parent.$parent.$parent.updateAlertsScreen.bind(this);
            }
            , 1);
    }
    resetHintTimer(id) {
        this.lastWheel = Date.now();
    }

    checkHintTimer(x,y,id) {
        if (this.isAdmin) {
            let hintInfo = this.hintInfos[id];
            if (!hintInfo) {
                hintInfo = {x: x, y: y, timeLast: Date.now()};
                this.hintInfos[id] = hintInfo;
            }

            if ((Math.abs(hintInfo.x - x) < 4) && (Math.abs(hintInfo.y - y) < 4)) {
                if ((this.lastWheel + 200 < Date.now()) &&
                    (hintInfo.timeLast + 200 < Date.now())) {
                    /*     if (!this.settings.showHint) {
                           console.log(id,'test true');
                           Vue.set(this.settings, 'showHint', true);
                    }*/
                    return true;
                }
            } else
                hintInfo.timeLast = Date.now();

            hintInfo.x = x;
            hintInfo.y = y;
        }
            /*if (this.settings.showHint) {
                console.log(id,'test false');
                Vue.set(this.settings, 'showHint', false);
            }*/
        return false;
    }

    setHintInfo(active) {
        if (this.settings.showHint != active) {
            Vue.set(this.settings, 'showHint', active);
            //console.log('showHint',this.settings.showHint,active);
            setTimeout(
                this.chartObjects["0"].$parent.$parent.$parent.showHint = active, 1);
            //this.resetAndRerangeChart("0");
        }
    }

    requestSendInstantMessage(text) {
        this.chartServerInterface.requestSendInstantMessage(text);
    }

    requestSendPublishVideoMessage(videoId, videoTitle, videoDesc) {
        this.chartServerInterface.requestSendPublishVideoMessage(videoId, videoTitle, videoDesc);
    }

    sendLiveChart(id, name, liveId, cfg, deleted, source) {
        console.log('sendLiveChart #live',id, name, liveId, deleted)
        this.chartServerInterface.sendLiveChart('live', this.charts[''+this.activeChart].data.chartId, cfg, this.charts["0"].symbol, this.charts["0"].tf, id, name, liveId, deleted, source);
    }

    sendLiveChartZoom(liveId, visibleBars, rightSpace, user) {
        //console.log('#live zoom',liveId, visibleBars, rightSpace, user)
        this.chartServerInterface.sendLiveChartZoom('live', liveId, visibleBars, rightSpace, user);
    }

    sendLiveChartMouse(liveId, price, dt) {
        //console.log('#live mouse',liveId,  price, dt)
        this.chartServerInterface.sendLiveChartMouse('live', liveId, price, dt);

    }

    sendLiveViewReset(liveId) {
        this.chartServerInterface.sendLiveViewReset('live', liveId);
        this.getTemplateInfo('charts', {ID_CHART: liveId});
        //this.requestLiveChart(liveId,"","",false);
    }

    requestLiveChart(id,symbol,timeframe, save) {
        //console.log('#live request',id,symbol,timeframe)
        this.chartServerInterface.requestLiveChart(id,symbol,timeframe, save);
    }

    requestLiveChartList() {
        this.chartServerInterface.requestLiveChartList();
    }

    setMouseMove(price, dt) {
        //console.log(this.channel)
        if (this.channel)
            this.channel.postMessage({action : "mu", symbol : this.displayData.symbol, price: price, dt: dt})
            //this.chartServerInterface.setMouseMove(symbol, last, dt);
        if (this.isSendLiveChart()) {
            this.setLiveChartMouse(price, dt);
        }
    }

    updateMouseMove(data) {
        //console.log(data)
        this.remoteInfo.showRemoteCursor = data.price >= -0.1;
        this.remoteInfo.dt = data.dt;
        this.remoteInfo.price = data.price;
    }

    resetMouseMove() {
        this.remoteInfo.showRemoteCursor = false;
        if (this.channel)
            this.channel.postMessage({action : "mu", symbol : this.displayData.symbol, price: -1, dt: 0})
    }

    setLiveChart(state, id, headline, description, commentInternal, resourceInfo, labelActions, publishedAnalysis, abos) {
        //console.log('setLiveChart', state, this.displayData.symbol);
        this.chartServerInterface.setLiveChart(id || this.charts["0"].data.chartId, state, this.charts["0"].symbol, this.charts["0"].tf, headline, description, commentInternal, resourceInfo, labelActions, publishedAnalysis, abos);
    }

    requestVideos() {
        this.chartServerInterface.requestVideos();
    }

    requestAlerts(indexFrom, indexCount) {
        this.chartServerInterface.requestAlerts(indexFrom, indexCount, "", "");
    }

    handleLastViewResponce(cfg) {
        //return;
        console.log('handleLastViewResponce',cfg);
        if ((!this.loadQuerySymbol) && cfg.data && (cfg.data.length > 0)) {
            const data = cfg.data[0];
            //const data = JSON.parse(data1);
            const id = data && 'id' in data ? data.id : -1;
            const lastUpdate = data ? data.updTime : "";
            //console.log("resLastView", data, id, lastUpdate, JSON.stringify(this.loadedChart));
            console.log("resLastView", id, data, data.symbol, this.loadedChart.firstLastViewRequestId, lastUpdate, this.loadedChart.chartId, this.loadedChart.lastUpdate < lastUpdate);
            if (this.loadedChart.firstLastViewRequestId=="-") {
                this.loadedChart.firstLastViewRequestId = data.symbol;
            }
            if ((id >= 0) && ((this.loadedChart.chartId !== id) || (this.loadedChart.lastUpdate < lastUpdate))) {
                this.requestHistoryData(data.symbol, data.tf);
                this.loadedChart.chartId = id;
                this.loadedChart.lastUpdate = lastUpdate;
            }

            const rev = cfg.data.reverse();
            rev.forEach((view) => {
                this.updateHistory(view.symbol);
            })
        }
    }

    onUpdateLiveChart(cfg) {
        const self = this;
        const data = cfg;
        var bFound = false;
        var iSendInd = null;
        //const data = JSON.parse(data1);
        const id = data.id ? data.id : -1;
        //console.log('onUpdateLiveChart',id, data.mode, data);
        if (data.mode == 'full') {
            //this.onUpdateLiveState(data.id, data.state, data.user, data.time, data.text, data.symbol, data.tf, data.views, data.headline, data.description, data.commentInternal, data.resourceInfo, data.labelActions, data.inactive, typeof data.abos == "string" ? JSON.parse(data.abos) : data.abos);
        } else if (data.mode == 'state') {
            this.onUpdateLiveState(data.id, data.state, data.user, data.time, data.text, data.symbol, data.tf, data.views, data.headline, data.description, data.commentInternal, data.resourceInfo, data.labelActions, data.inactive, typeof data.abos == "string" ? JSON.parse(data.abos) : data.abos);
        } else if ((data.mode == 'part') && (id == this.liveCharting.liveChartId)) {
            if (this.isSendLiveChart()) {
                Vue.nextTick(() => {
                    this.chartObjects["0"].$parent.$parent.$parent.setLiveMode(false, 0);
                });
            }
            if (data.isMouse) {
                if (('price' in data) && ('dt' in data) && this.chartObjects['0']) {
                    this.updateMouseMove(data);
                }
            } else if (data.isReset) {
                this.getTemplateInfo('charts', {ID_CHART: id});
                //this.requestLiveChart(id,"","",false);
            } else if (data.isZoom) {
                if (('visibleBars' in data) && ('rightSpace' in data) && this.chartObjects['0']) {
                    this.liveCharting.user = data.liveUser || this.liveCharting.user;
                    var chart = this.chartObjects['0'].$refs.tvjs;
                    if (chart) {
                        const rightSpace = data.rightSpace || 5;
                        const x1 = data.visibleBars ? (self.charts["0"].data.data.chart.data.length + rightSpace - data.visibleBars ) : chart.ti_map.t2i(data.from);
                        const x2 = data.visibleBars ? self.charts["0"].data.data.chart.data.length + rightSpace : chart.ti_map.t2i(data.to);

                        self.chartObjects["0"].$refs.tvjs.$nextTick(() => {
                            this.chartObjects["0"].$refs.tvjs.setRange(x1, x2);
                        });
                        //});
                    }
                }
            } else if (this.charts["0"].data && this.charts["0"].data.data && this.charts["0"].data.data.onchart) {
                this.charts["0"].data.data.onchart.forEach((i) => {
                    if ((i.settings) && (i.settings.liveId) && (i.settings.liveId == data.data.liveId)) {
                        if (data.deleteTool) {
                            i.settings.$selected = true;
                            this.liveCharting.lastDeleteToolId = data.data.liveId;
                            this.deleteTool(i.settings.$uuid, true);
                        } else {
                            Object.keys(data.data).forEach((key) => {
                                this.changeIndicatorValue(i.id + '#' + key, data.data[key], this.isPriceLineIndicator(i.id), true);
                            });
                            //console.log(i.pins);
                            i.settings.$selected = false;
                            i.settings.points = i.settings.points || {};
                            var idx = 0;
                            while (i.settings['p' + (++idx)]) {
                                i.points['p' + idx] = [self.getTimeFromIndex("0", i.settings['p' + idx][0]), i.settings['p' + idx][1]];
                                i.settings.points['p' + idx] = [self.getTimeFromIndex("0", i.settings['p' + idx][0]), i.settings['p' + idx][1]];
                            }
                            if (i.updatePins) {
                                i.updatePins();
                            }
                        }
                        bFound = true;
                    }
                });
                if (bFound) {
                    this.updateIndicatorPoints(0);
                }
                if (!this.liveCharting.active && !bFound && !this.isSpecialIndicator(data.indName) && (data.data) && (this.liveCharting.lastDeleteToolId !== data.data.liveId)) {
                    this.addIndicator({name:data.indName, id: data.indId, settings: data.data});
                }
            }
        }
    }

    getLiveViewFromId(id) {
        var i = -1;
        this.liveCharting.liveAnalysis.forEach((value, idx) => {
            if (value.id == id) {
                i = idx;
            }
        });
        if (i>=0) {
            return this.liveCharting.liveAnalysis[i];
        }
        return null;
    }

    onUpdateLiveState(id, state, user, time, text, symbol, tf, views, headline, description, commentInternal, resourceInfo, labelActions, inactive, abos) {
        let key = -1;

        //console.log('onUpdateLiveState',time, abos);

        if (!this.displayData.symbol) {
            this.settings.displayIndex = -1;
            this.requestHistoryData(symbol, tf);
            this.requestLiveChart(id,symbol,tf,false);
        }

        this.liveCharting.liveAnalysis.forEach((value, idx) => {
            if (value.id == id)
                key = idx;
        });
        if ((!state) || ((!this.isAdmin) && (inactive > 0))) {
            if (key >= 0)
                this.liveCharting.liveAnalysis.splice(key,1);
        } else {
            if ((key >= 0) && (this.liveCharting.liveAnalysis[key].time <= time)) {
                const sort = this.liveCharting.liveAnalysis[key].time !== time;

                this.liveCharting.liveAnalysis[key].id = id;
                this.liveCharting.liveAnalysis[key].from = user;
                this.liveCharting.liveAnalysis[key].time = time;
                this.liveCharting.liveAnalysis[key].text = text || symbol+" ("+tf+")";
                this.liveCharting.liveAnalysis[key].symbol = symbol;
                this.liveCharting.liveAnalysis[key].tf = tf;
                this.liveCharting.liveAnalysis[key].views = views;
                this.liveCharting.liveAnalysis[key].inactive = inactive;
                this.liveCharting.liveAnalysis[key].headline = headline;
                this.liveCharting.liveAnalysis[key].description = description;
                this.liveCharting.liveAnalysis[key].commentInternal = commentInternal;
                this.liveCharting.liveAnalysis[key].resourceInfo = resourceInfo;
                this.liveCharting.liveAnalysis[key].labelActions = labelActions;
                this.liveCharting.liveAnalysis[key].abos = abos;

                //console.log(sort, user, this.user, this.liveCharting.updatedAnalysis);
                if (sort) {
                    if ((user !== this.user) && (this.liveCharting.updatedAnalysis.loadedId === id)) {
                        Vue.set(this.liveCharting.updatedAnalysis,'id', id);
                    }

                    this.liveCharting.liveAnalysis.sort(function(t1,t2) {
                        if (t1.time < t2.time)
                            return 1;
                        if ( t1.time > t2.time)
                            return -1;
                        return 0;} );
                }
            } else if (key < 0) {
                this.liveCharting.liveAnalysis.unshift({id: id, from : user, time: time, text : text || symbol+" ("+tf+")", symbol : symbol, tf : tf, views: views, headline: headline, description: description, resourceInfo: resourceInfo, labelActions: labelActions, commentInternal: commentInternal, inactive: inactive, abos: abos});
            }
        }
    }

    switchCandleVisible() {
        this.settings.showCandles = !this.settings.showCandles
    }

    saveMultiScreenView() {
        // TODO - Multiscreen
        console.log('saveMultiScreenView', this.channel && !this.detach )
        if (this.channel && !this.detach)
            this.channel.postMessage({action : "screenInfo"})
    }

    loadMultiScreenView(view) {
        this.layoutHandler.loadMultiScreenView(view);
    }

    setChartVisible(visible) {
        this.settings.showChart = visible
    }

    confirmUserInfo(id, valueIdx){
        var act = [];
        const self = this;

        this.userInformations.data.forEach((ui,idx) => {
            if (id != idx) {
                act.push(ui);
            } else {
                console.log('setUserInfo', valueIdx, id, valueIdx, ui)
                this.chartServerInterface.confirmUserInfo(ui.id, ui.buttons[valueIdx].confirmValue)
            }
        })
        while (this.userInformations.data.pop()) ;

        act.forEach((ui,idx) => {
            self.userInformations.data.push(ui);
        });
        Vue.set(self.userInformations,'count',self.userInformations.data.length, id);
    }

    pinModifyPanel(isPined,x,y) {
        this.chartSettings.isPinModifyPanel = isPined;
        this.chartSettings.pinModifyPanelPos = [x,y];
        this.saveSettings(JSON.stringify(this.chartSettings));
    }

    fullScreen() {
        if (document.fullscreenElement) {
            /* Close fullscreen */
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE/Edge */
                document.msExitFullscreen();
            }
        } else {
            var elem = document.getElementById("multi-chart");
            console.log(elem);
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) {
                /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) {
                /* Chrome, Safari & Opera */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) {
                /* IE/Edge */
                elem.msRequestFullscreen();
            } else {
                return;
            }
        }
    }

    requestAboTermination(abo) {

    }

    getTranslation(rule) {
        if (rule =='RULE123_2B')
            return 'Bestätigung eines Kandidaten nach 123-Regel Punkt 2b';
        if (rule =='RULE123_2C')
            return 'Bestätigung eines Kandidaten nach 123-Regel Punkt 2c';
        if (rule =='RULE123_3A')
            return 'Verlängerung 123-Regel Pkt 3 (Kofi-Faktor und Pullback Var.1)';
        if (rule =='RULE123_3B')
            return 'Verlängerung 123-Regel Pkt 3b (Kofi-Faktor und Pullback Var.2)';
        if (rule =='RULE123_3C')
            return 'Verlängerung 123-Regel Pkt 3c (Extrem-Level / Fib-Ratio)';
        if (rule =='RULE123_3')
            return 'Verlängerung nach ursprünglichen Fib-Regeln'

        return rule;
    }
}