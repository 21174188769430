<template>
    <div class="login_container">
        <div class="login">
            <div class="container">
                <form v-if="isLoginForm" @submit.prevent="handleLoginSubmit">
                  <!--vue-apple-login></vue-apple-login-->
                  <div class="image" style="position:absolute;right:1.5em;top:1em" >
                    <img id="uk" src="/src/img/uk.svg" style="height: 19px;border: 1px solid #fff" alt="Login" v-on:click="setLanguage('en', event)"/>
                    <img id="german" src="/src/img/germany.svg" style="height: 19px;margin-left: 10px;border: 1px solid #fff" alt="Login" v-on:click="setLanguage('de', event)"/>
                  </div>

                  <div class="flex-container" style="margin-top: 0px">
                    <div class="flex-item-all">
                      <div class="image">
                        <img id="login" src="/src/img/logo.svg" alt="Login"/>
                      </div>
                    </div>
                  </div>
                  <div class="base-container" style="height: 300px; overflow-y: hidden;">
                        <div class="header" >{{language.login}}</div>
                        <div class="content">
                            <div class="form">
                                <div class="form-group-col" style="margin-top: 5px"><label for="username">{{language.email}}</label><input type="text" name="username" v-model="inputLogin.username" :placeholder="language.email" /></div>
                                <div class="form-group-col" style="margin-top: -10px;margin-bottom: -18px;">
                                  <label for="password">{{language.password}}</label>
                                  <input type="password" name="password" v-model="inputLogin.password" :placeholder="language.password" autocomplete="on" />
                                </div>

                              <!--div class="form-group" style="width: 100%" -->
                                <!--router-link class="link" to="/register">{{language.register}}</router-link-->
                                <router-link class="link" to="/reset">{{language.reset_link}}</router-link>
                                <!--/div-->

                              <p v-if="isSendResetMail" style="margin-top:0px;color: #1bc901;font-size: medium"> Die E-Mail für die Passwortänderung wurde erfolgreich versandt!</p>
                              <p v-if="isPasswortChanged" style="margin-top:0px;color: #1bc901;font-size: medium"> Ihr Passwort wurde erfolgreich geändert!</p>
                              <p v-if="isLoginError" style="margin-top:0px;color: red"> Ihre Anmeldung ist fehlgeschlagen, bitte prüfen Sie Ihr Eingaben und versuchen es danach erneut!</p>

                            </div>
                        </div>
                        <div class="footer">
                            <button ref="btn_login" class="btn">{{language.login}}</button>
                            <label style="position: absolute;font-size:12px;bottom: -10px;right: 7px;color: rgb(234,234,234)">Version: {{version.version}}-{{version.git}}</label>
                        </div>
                    </div>
                </form>
                <form v-if="isRegisterForm" @submit.prevent="handleRegSubmit">
                  <div class="image" style="position:absolute;right:1.5em;top:1em"  >
                    <img id="uk" src="/src/img/uk.svg" style="height: 19px;border: 1px solid #fff" alt="Login" v-on:click="setLanguage('en', event)"/>
                    <img id="german" src="/src/img/germany.svg" style="height: 19px;margin-left: 10px;border: 1px solid #fff" alt="Login" v-on:click="setLanguage('de', event)"/>
                  </div>

                  <div class="flex-container" style="margin-top: 0px">
                    <div class="flex-item-all">
                      <div class="image">
                        <img id="login" src="/src/img/logo.svg" alt="Login"/>
                      </div>
                    </div>
                  </div>
                <!--form @submit.prevent="handleRegSubmit" style="overflow-y: scroll; height: 100%; padding-bottom: 40px"-->
                  <div class="base-container"  style="height: 400px">
                    <div class="header">{{language.register}}</div>
                    <div class="content">
                      <div class="form">
                          <div class="flex-container" style="margin-top:10px">
                            <div class="flex-item-left">
                              <label class="fieldset" for="TITLE">{{language.title}}</label>
                              <select class="inputshort" style="min-width:190px" v-model="inputRegister.TITLE">
                                <option disabled value="">{{language.selectMsMr}}</option>
                                <option value="Mr">Mr</option>
                                <option value="Mrs">Mrs</option>
                              </select>
                            </div>

                          </div>
                        <div class="flex-container" >
                        <div class="flex-item-left"><label class="fieldset" for="FIRST_NAME">{{ language.firstName}}</label><input class="inputshort" type="text" name="FIRST_NAME" v-model="inputRegister.FIRST_NAME" :placeholder="language.firstName" required /></div>
                        <div class="flex-item-right"><label class="fieldset" for="LAST_NAME">{{ language.lastName}}</label><input class="inputshort" type="text" name="LAST_NAME" v-model="inputRegister.LAST_NAME" :placeholder="language.lastName" /></div>
                      </div>
                        <div class="flex-container">
                          <div class="flex-item-all-left"><label class="fieldset"  for="ADDRESS_LINE_1">{{ language.address}}</label><input class="inputlong" type="text" name="ADDRESS_LINE_1" v-model="inputRegister.ADDRESS_LINE_1" :placeholder="language.address"/></div>
                        </div>
                        <div class="flex-container">
                          <div class="flex-item-left"><label class="fieldset" for="POSTAL_CODE">{{ language.postalCode }}</label><input class="inputshort" type="text" name="POSTAL_CODE" v-model="inputRegister.POSTAL_CODE" :placeholder="language.postalCode" /></div>
                          <div class="flex-item-right"><label class="fieldset" for="CITY">{{ language.city }}</label><input class="inputshort" type="text" name="CITY" v-model="inputRegister.CITY" :placeholder="language.city" /></div>
                        </div>
                        <div class="flex-container">
                          <div class="flex-item-all-left"><label class="fieldset" for="CONTACT_DETAIL_1 ">{{ language.contact }}</label><input class="inputshort" type="text" name="CONTACT_DETAIL_1 " v-model="inputRegister.CONTACT_DETAIL_1" :placeholder="language.contact" /></div>
                        </div>
                        <div class="flex-container" style="margin-top:5px">
                          <div class="flex-item-left"><label class="fieldset" for="email">{{ language.email }}</label><input class="inputshort" type="email" name="email" v-model="inputRegister.EMAIL" :placeholder="language.email" required /></div>
                          <div class="flex-item-right"><label class="fieldset" for="password">{{ language.password }}</label><input class="inputshort" type="password" name="password" v-model="inputRegister.PASSWORD" :placeholder="language.password" required /></div>
                        </div>
                        <div class="flex-container">
                          <div class="flex-item-all"><label class="fieldset" for="NICK_NAME">{{ language.nickName}}</label><input class="inputshort" type="text" name="NICK_NAME" v-model="inputRegister.NICK_NAME" :placeholder="language.nickName" /></div>
                        </div>

                        <router-link class="link" style="margin-top: 10px" to="/login">{{language.login}}</router-link>
                      </div>
                    </div>
                    <div class="footer">
                      <button ref="btn_reg" class="btn">{{language.register}}</button>
                    </div>
                  </div>
                </form>
              <form v-if="isResetForm" @submit.prevent="handleResetSubmit">
                <div class="image" style="position:absolute;right:1.5em;top:1em" >
                  <img id="uk" src="/src/img/uk.svg" style="height: 19px;border: 1px solid #fff" alt="Login" v-on:click="setLanguage('en', event)"/>
                  <img id="german" src="/src/img/germany.svg" style="height: 19px;margin-left: 10px;border: 1px solid #fff" alt="Login" v-on:click="setLanguage('de', event)"/>
                </div>

                <div class="flex-container" style="margin-top: 70px">
                  <div class="flex-item-all">
                    <div class="image">
                      <img id="login" src="/src/img/logo.svg" alt="Login"/>
                    </div>
                  </div>
                </div>
                <div class="base-container"  style="height: 400px">
                  <div class="header">{{language.reseting_link}}</div>
                  <div class="content">
                    <div class="form" style="margin-top: 30px">
                      <div class="form-group-col"><label for="username">{{ language.email }}</label><input type="text" name="username" v-model="inputReset.EMAIL" :placeholder="language.email" required/></div>
                      <p>{{ language.reset_text1 }}</p>
                      <p style="margin-bottom: 5px">{{ language.reset_text2 }}</p>

                      <!--div class="form-group" style="width: 100%" -->
                      <router-link class="link" style="margin-top: 10px"  to="/login">{{language.login}}</router-link>
                      <!--/div-->
                    </div>
                  </div>
                  <div class="footer">
                    <button ref="btn_reset" class="btn">{{language.reseting_link}}</button>
                  </div>
                </div>
              </form>
              <form v-if="isResetWithTokenForm" @submit.prevent="handleResetWithTokenSubmit">
                <div class="image" style="position:absolute;right:1.5em;top:1em" >
                  <img id="uk" src="/src/img/uk.svg" style="height: 19px;border: 1px solid #fff" alt="Login" v-on:click="setLanguage('en', event)"/>
                  <img id="german" src="/src/img/germany.svg" style="height: 19px;margin-left: 10px;border: 1px solid #fff" alt="Login" v-on:click="setLanguage('de', event)"/>
                </div>

                <div class="flex-container" style="margin-top: -10px">
                  <div class="flex-item-all">
                    <div class="image">
                      <img id="login" src="/src/img/logo.svg" alt="Login"/>
                    </div>
                  </div>
                </div>

                <div class="base-container"  style="margin-top: -100px;height: 340px; overflow-y: hidden;">
                  <!--div class="header">{{language.reset}}</div-->
                  <div class="content">
                    <div class="form" style="margin-top: 20px">
                      <div class="form-group-col" style="margin-bottom: -10px" ><label for="password">{{ language.newPassword }}</label><input type="password" name="password" v-model="inputReset.PASSWORD" :placeholder="language.newPassword" required/></div>
                      <div class="form-group-col"  style="margin-bottom: -10px" ><label for="password2">{{ language.newPasswordConfirm }}</label><input type="password" name="password2" v-model="inputReset.PASSWORD2" :placeholder="language.newPasswordConfirm" required/></div>
                      <p style="margin-bottom: 0px" >{{ language.resetToken_text1 }}</p>
                      <p style="margin-bottom: 15px" >{{ language.resetToken_text2 }}</p>
                      <router-link class="link" to="/login" >{{language.login}}</router-link>

                      <!--div class="form-group" style="width: 100%" -->

                      <!--/div-->
                    </div>
                  </div>
                  <div class="footer">
                    <button ref="btn_reset" class="btn" :disabled="!isPasswordSetAndCorrect">{{language.reset}}</button>
                  </div>
                </div>
              </form>
            </div>
            <!--div class="right-side" :class="[isLoginForm ? 'right' : 'left']" @click="isLoginForm = !isLoginForm">
                <div class="inner-container">
                    <div class="text">{{ isLoginForm ? "Register" : "Login" }}</div>
                </div>
            </div-->
        </div>
    </div>
</template>

<script>
import axios from "axios";
import process from "process";
import {ChartHandler} from "../utils/ChartHandler";
import version from "../version.json";


export default {
    name: "Login",
    data() {
      //console.log(this.$route.query)
      return {
            inputLogin: {
                username: "",
                password: "",
                password2: "",
            },

            version,

            inputRegister: {
                TITLE: "",
                FIRST_NAME: "",
                LAST_NAME: "",
                ADDRESS_LINE_1: "",
                CITY: "",
                POSTAL_CODE: "",
                CONTACT_DETAIL_1: "",
                EMAIL: "",
                PASSWORD: "",
            },

            inputReset: {
              EMAIL: "",
              PASSWORD: "",
              PASSWORD2: "",
              TOKEN: "",
            },

            language: window.language,

            isRegisterForm: false, //window.currentUrlPath.indexOf("register")>0,
            isSendResetMail: window.currentUrlPath.indexOf("login")>0 && this.$route.query && (this.$route.query.info == "resemail"),
            isPasswortChanged: window.currentUrlPath.indexOf("login")>0 && this.$route.query && (this.$route.query.info == "pwdchanged"),
            isResetForm: window.currentUrlPath.indexOf("reset")>0 && this.$route.query && !this.$route.query.token,
            isResetWithTokenForm: window.currentUrlPath.indexOf("reset")>0 && this.$route.query && this.$route.query.token,
            isLoginError: false,

            resetToken: this.$route.query ? this.$route.query.token : "",

            apiUrl: "",
        };
    },
    mounted() {
      const lang = localStorage.getItem("language");
      //console.log(lang, localStorage);
      if (lang) {
        this.setLanguage(lang);
      } else {
        this.setLanguage('de');
      }
    },
  beforeCreate() {
    window.language = {
        login : "Login",
        title : "Title",
        firstName : "First Name",
        lastName : "Last Name",
        address : "Address",
        nickName : "User Name",
        password : "Password",
        newPassword : "New Password",
        newPasswordConfirm : "Confirm New Password",
        postalCode : "Postal Code",
        city : "City",
        contact : "Contact No",
        email : "Email Address",
        reset_link : "Forgot Password?",
        register : "Register",
        reset : "Reset password",
        reseting_link : "Request link",
        selectMsMr: "Please select one",
        reset_text1 : "Bitte geben Sie ihre hinterlegte E-Mail-Adresse an <br>und wir senden",
        reset_text2 : "send you a link to reset your password.",
        resetToken_text1 : "Bitte geben Sie Ihr neues Passwort (mind. 6 Zeichen) ein und bestätigen Sie dieses.",
        resetToken_text2 : "Im Anschluß drücken Sie 'Passwort zurücksetzen' um die Änderung durchzuführen.",
    }
  },
  created() {
        // reset login status
        localStorage.removeItem("user");

        //console.log(process.env);

        if (process.env.VUE_APP_APIHOST) {
            this.apiUrl = process.env.VUE_APP_APIHOST;
        /*} else if (process.env.NODE_ENV === "development") {
            this.apiUrl = "http://ki-trading.de:7072";//
        */} else {
            this.apiUrl = "https://chart-software.com/api";
        }

        //console.log(this.apiUrl);
    },
    computed: {
    // Copy a subset of TradingVue props
      isPasswordSetAndCorrect() {
        return (this.inputReset.PASSWORD) && (this.inputReset.PASSWORD.length >= 6) && (this.inputReset.PASSWORD == this.inputReset.PASSWORD2)
      },
      isLoginForm() {
        return !(this.isRegisterForm || this.isResetForm || this.isResetWithTokenForm);
      },
    },
    methods: {
      setLanguage: function(lang, event) {
        //localStorage.setItem("language", lang);
        if (lang == 'de') {
          this.language.login = "Anmeldung";
          this.language.password = "Passwort";
          this.language.newPassword = "Neues Passwort";
          this.language.newPasswordConfirm = "Neues Passwort bestätigen";
          this.language.nickName = "Nutzername";
          this.language.firstName = "Vorname";
          this.language.lastName = "Nachname";
          this.language.address = "Adresse";
          this.language.title = "Titel";
          this.language.email = "E-Mail-Adresse";
          this.language.postalCode = "PLZ";
          this.language.city = "Ort";
          this.language.contact = "Telefonnr.";
          this.language.reset_link = "Passwort vergessen?";
          this.language.register = "Registrierung";
          this.language.reset = "Passwort zurücksetzen";
          this.language.selectMsMr = "Bitte wählen";
          this.language.reset_text1 = "Bitte geben Sie ihre hinterlegte E-Mail-Adresse an und wir senden Ihnen umgehen einen Link, um Ihr Passwort zurück zu setzen.";
          this.language.reset_text2 = "";
          this.language.resetToken_text1 = "Bitte geben Sie Ihr neues Passwort (mind. 6 Zeichen) ein und bestätigen Sie dieses.";
          this.language.resetToken_text2 = "Im Anschluß drücken Sie 'Passwort zurücksetzen' um die Änderung durchzuführen.";
          this.language.reseting_link = "Änderungslink anfragen";
        } else {
          this.language.login = "Login";
          this.language.password = "Password";
          this.language.newPassword = "New Password";
          this.language.newPasswordConfirm = "Confirm New Password";
          this.language.selectMsMr = "Please select one";
          this.language.nickName = "User Name";
          this.language.firstName = "First Name";
          this.language.lastName = "Last Name";
          this.language.title = "Title";
          this.language.email = "Email Address";
          this.language.address = "Address";
          this.language.postalCode = "Postal Code";
          this.language.city = "City";
          this.language.contact = "Contact No";
          this.language.reset_link = "Forgot Password?";
          this.language.register = "Register";
          this.language.reset = "Reset Password";
          this.language.reset_text1 = "Please enter your email address and we will";
          this.language.reset_text2 = "send you a link to reset your password.";
          this.language.resetToken_text1 = "Please enter your new password (at least 6 characters) and confirm it.";
          this.language.resetToken_text2 = "Then press 'Reset Password' to make the change.";
          this.language.reseting_link = "Request Change Link";
        }
      },
        handleLoginSubmit() {
            this.isLoginError = false;
            this.$refs.btn_login.textContent = "Loading...";
            const { username, password } = this.inputLogin;

            if (username != "" && password != "") {
                const data = {
                    USERNAME: username,
                    PASSWORD: password,
                };

                //console.log('login')
                axios
                    .post(`${this.apiUrl}/login/user`, data)
                    .then((res) => {
                        //console.log('login','result',res)
                        if (res.data.token) {
                            const user = {
                                FIRST_NAME: res.data.result[0].FIRST_NAME,
                                user: res.data.result[0].NICK_NAME,
                                email: res.data.result[0].EMAIL,
                                isAdmin: res.data.isAdmin,
                                token: res.data.token,
                                rights: res.data.result[0].rights,
                                abos: res.data.result[0].abos,
                                lastAction: (new Date()).getTime()
                            };
                            if ((res.data.result[0].resetPasswordToken) &&
                               (res.data.result[0].USER_STATE == "RESET_PASSWORD")) {
                                this.$router.push("/reset?token="+res.data.result[0].resetPasswordToken).catch(() => {});
                            } else {
                              localStorage.setItem("user", JSON.stringify(user));
                              window.chartHandler = new ChartHandler(user.token, user.isAdmin, this.$route.query);
                              this.$router.push("/chart").catch(() => {});
                            }
                        }
                    })
                    .catch((error) => {
                        console.error("Email or password is wrong", error);
                        this.isLoginError = true;
                        this.$refs.btn_login.textContent = "Login";
                    });
            } else {
                console.log("A username and password must be present");
                this.$refs.btn_login.textContent = "Login";
            }
        },
      handleResetSubmit() {
        //this.$refs.btn_reg.textContent = "Loading...";
        const { EMAIL } = this.inputReset;

        if (EMAIL != "" ) {
          axios
              .post(`${this.apiUrl}/reset`, this.inputReset)
              .then((res) => {
                console.log(res);
                if (res && (res.status == 201)&& (res.statusText == "Created")) {
                  this.$router.push("/login?info=resemail").catch(() => {
                  })
                }
              })
              .catch((error) => {
                // console.error(error);
                this.$refs.btn_reg.textContent = "Register";
              });
        } else {
          console.log("A first name, username and password must be present");
          this.$refs.btn_reg.textContent = "Register";
        }
      },
      handleResetWithTokenSubmit() {
        //this.$refs.btn_reg.textContent = "Loading...";
        const { EMAIL, PASSWORD } = this.inputReset;

        this.inputReset.TOKEN = this.resetToken;

        console.log(this.inputReset)

        if (PASSWORD != "" ) {
          axios
              .put(`${this.apiUrl}/reset`, this.inputReset)
              .then((res) => {
                if (res) {
                  console.log(res);
                  if (res && (res.statusText == "OK")) {
                      this.$router.push("/login?info=pwdchanged").catch(() => {
                      });
                  }
                }
              })
              .catch((error) => {
                // console.error(error);
                this.$refs.btn_reg.textContent = "Register";
              });
        } else {
          console.log("A first name, username and password must be present");
          this.$refs.btn_reg.textContent = "Register";
        }
      },
        handleRegSubmit() {
            this.$refs.btn_reg.textContent = "Loading...";
            const { FIRST_NAME, LAST_NAME, ADDRESS_LINE_1, CITY, POSTAL_CODE, CONTACT_DETAIL_1, EMAIL, PASSWORD } = this.inputRegister;

            if (FIRST_NAME != "" && EMAIL != "" && PASSWORD != "") {
                axios
                    .post(`${this.apiUrl}/login/create/user`, this.inputRegister)
                    .then((res) => {
                        if (res.data.token) {
                            const user = {
                                FIRST_NAME: res.data.result.FIRST_NAME,
                                token: res.data.token,
                            };
                            localStorage.setItem("user", JSON.stringify(user));
                            this.$router.push("/chart");
                        }
                    })
                    .catch((error) => {
                        // console.error(error);
                        this.$refs.btn_reg.textContent = "Register";
                    });
            } else {
                console.log("A first name, username and password must be present");
                this.$refs.btn_reg.textContent = "Register";
            }
        },
    },
};
</script>

<style scoped>
.login_container {
    min-height: 100vh;
    padding: 0 0.2rem;
    display: -moz-box;
    display: flex;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    flex-direction: column;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    background-color: #ffffff;
    box-shadow: 0px 0px 12px 8px rgba(80, 79, 79, 0.37);
}
.base-container {
  margin-top: -70px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    _overflow-y: scroll;
}
.base-container .header {
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
}
.base-container .content {
    display: flex;
    flex-direction: column;
    max-width: 340px;
    text-align: center;
}
.base-container .content .image {
    text-align: center;
    width: 21em;
}
.base-container .content .image img {
    width: 100%;
    height: 100%;
}
.base-container .content .form {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.base-container .content .form .form-group {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    ywidth: fit-content;
}
.flex-container {
  display: flex;
  flex-direction: row;
  text-align: center;
  max-width: 700px;
  min-width: 320px;
  width: 80%;
  margin-bottom: 5px;
}
.flex-item-left {
  padding: 0px;
  flex: 50%;
}

.fieldset {
  float: left;
  width: 7em;
  margin-right: 5px;
  margin-top: 3px;
  text-align: left;
}

.flex-item-right {
  padding: 0px;
  flex: 50%;
  padding-left: 10px;
}

.flex-item-all {
  padding: 0px;
  flex: 100%;
}

.inputlong{
  width: 590px;
  float: left;
}
.inputshort {
  min-width: 230px;
  width: 230px;
  float: left;
}

.flex-item-all-left {
  padding: 0px;
  alignment: left;
  flex: 100%;
  justify-content: left;
}
.base-container .content .form .form-group-col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
}
.base-container .content .form .form-group-col label {
  font-size: medium;
}
.base-container .content .form .form-group-col input,
.base-container .content .form .form-group-col select {
  margin-top: 2px;
  min-width: 18em;
  height: 37px;
  padding: 0px 10px;
  #font-size: 16px;
  font-size: medium;
  font-family: "Open Sans", sans-serif;
  background-color: #ffffff;
  border: 0;
  border-radius: 4px;
  margin-bottom: 31px;
  transition: all 250ms ease-in-out;
}
.base-container .content .form .form-group input,
.base-container .content .form .form-group select{
  margin-top: 0px;
  min-width: 16em;
  height: 30px;
  padding: 0px 2px;
  #font-size: 13px;
  font-size: medium;
  font-family: "Open Sans", sans-serif;
  background-color: #ffffff;
  border: 0;
  border-radius: 4px;
  margin-bottom: 0px;
  transition: all 250ms ease-in-out;
  float: left;
  width: 200px;
}
.base-container .content .form .form-group-col input:focus,
.base-container .content .form .form-group input:focus {
    outline: none;
    box-shadow: 0px 0px 12px 0.8px #3474dbb2;
}
.base-container .footer {
    position: absolute;
    padding: 20px 0;
    bottom: 0;
    width: 100%;
    text-align: center;
  _background: #fff;
  background: #555555;
  _background-color: #2c2c2c;
  _box-shadow: 0px 0px 10px 8px rgba(108, 107, 107, 0.35);
}
#login {
  margin-top: -100px;
  alignment: center;
  align-items: center;
  width: 400px;
}
@media (max-width: 700px) {
  .container {
    max-width: 490px;
    text-align: center;
  }
  .content{
    max-width: 480px;
    text-align: center;
  }
}
@media (max-width: 580px) {
  .container {
    max-width: 390px;
    text-align: center;
  }
  .content{
    max-width: 380px;
    text-align: center;
  }
}
@media (max-width: 490px) {
  .container {
    max-width: 320px;
    text-align: center;
  }
  .content{
    max-width: 310px;
    text-align: center;
  }
  .header {
    font-size:small;
  }
  #login {
    width: 300px;
  }
}
#register {
  margin-top: -50px;
  height: 20em;
}
.login {
  min-width: 320px;
  max-width: 700px;
  #max-height: 40em;
  #height: 80%;
  _width: 80%;
  height: 40em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  position: relative;
  z-index: 99;
}
.login .container {
    display: flex;
    justify-content: center;
    align-items: center;
    _background-color: rgba(27, 31, 44, 0.97);
    background-color: #2c2c2c;
    box-shadow: 0px 0px 10px 8px rgba(77, 77, 77, 0.7);
    _box-shadow: 0px 0px 12px 8px rgba(15, 15, 15, 0.2);
    border-radius: 1.0em;
    position: relative;
    z-index: 99;
    width: 650px;
    height: 100%;
    z-index: 99;
    padding: 1px 1px;
    overflow: hidden;
}
.login .right-side {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90%;
    background-color: rgba(27, 31, 44, 0.29);
    width: 100%;
    position: absolute;
    right: -34%;
    border-radius: 6px;
    z-index: 1;
    transition: all 400ms ease-in-out;
    cursor: pointer;
    box-shadow: 0px 0px 12px 2px rgba(15, 15, 15, 0.281);
}
.login .right-side.right {
    right: -40%;
    align-items: flex-end;
}
.login .right-side.right:hover {
    right: -45%;
}
.login .right-side.left {
    right: 40%;
    align-items: flex-start;
}
.login .right-side.left:hover {
    right: 45%;
}
.login .right-side .text {
    font-size: 21px;
    font-weight: 500;
    color: #fff;
    margin-right: 3em;
    margin-left: 3em;
}

.btn:hover  {
  border: 1px solid #FF6E00;
    background-color: #FF6E00;
    color: #ffffff;
    border-radius: 3px;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    text-decoration: none;
  width: 230px;
  padding: 4px 10px;
  #font-size: 16px;
  font-size: medium;
}
.btn{
  background-color: #ffffff;
  border: 1px solid #FF6E00;
  color: #FF6E00;
  padding: 4px 3px;
  #font-size: 16px;
  font-size: medium;
  cursor: pointer;
  width: 230px;
  text-decoration: none;
}
.btn:focus {
    outline: none;
}
.link {
  margin-top: 1px;
  margin-bottom: 3px;
  transition: all 250ms ease-in-out;
  color: #FF6E00;
  #font-size: 15px;
  font-size: small;
}
.form-group {
  margin-top: 1rem;
}
.btn.disabled, .btn:disabled {
  pointer-events: none;
  opacity: .8;
  border: 1px solid rgba(180, 180, 180, 0.6);
  color: rgba(143, 143, 143, 0.72);
}
</style>
