import Vue from "vue";
import Router from "vue-router";
import Admin from "../views/Admin.vue";
import Login from "../views/Login.vue";
import Health from "../views/Health.vue";
import Chart from "../views/Multichart.vue";
import MarketOverview from "../views/MarketOverview.vue";
import VueSuggestion from 'vue-suggestion'
import Suggestions from 'v-suggestions'
import VueShortkey from 'vue-shortkey'
import vSelect from 'vue-select'
import EasyCm from '../views/components/EasyCm.vue';
import {ColorPicker, ColorPanel} from 'one-colorpicker';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCoffee, faChartLine } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner, faAlignLeft, faAddressCard  } from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner, faAlignLeft, faAddressCard)

library.add(faCoffee, faChartLine)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)

Vue.use(ColorPanel)
Vue.use(ColorPicker)

//import 'v-suggestions/dist/v-suggestions.css' // you can import the stylesheets also (optional)
Vue.component('suggestions', Suggestions)
/*
import Forgot from "@/views/Forgot.vue";
import Reset from "@/views/Reset.vue";
import Chart from "@/views/Chart.vue";
import Watchlist from "@/views/Watchlist.vue";
import DetachChart from "@/views/DetachChart.vue";
*/
Vue.use(VueShortkey, { prevent: ['input','search-input', 'vue-suggestion', 'textarea', '.ql-editor'] });
Vue.use(VueSuggestion);
Vue.use(Router);

Vue.component('easy-cm', EasyCm)
Vue.prototype.$easycm = function (e,root,owner,tag) {

    e.stopPropagation()
    e.preventDefault()
    console.log('openContextMenu 3 easy-cm', root, owner,owner.displayContextMenu());
    if (owner.displayContextMenu()) {
        root.$emit('easyAxis', {
            tag: tag,
            x: e.clientX,
            y: e.clientY
        })
    }
}

export const router = new Router({
    routes: [
        /*{
            path: "/",
            name: "Home",
            component: Home,
        },*/
        {
            path: "/",
            name: "Login1",
            component: Login,
        },
        {
            path: "/login",
            name: "Login2",
            component: Login,
        },
        {
            path: "/marketoverview",
            name: "MarketOverview",
            component: MarketOverview,
        },
        {
            path: "/register",
            name: "Login3",
            component: Login,
        },
        {
            path: "/reset",
            name: "Login4",
            component: Login,
        },
        {
            path: "/health",
            name: "health",
            component: Health,
        },
        {
            path: "/chart",
            name: "Chart",
            component: Chart,
        },
        {
            path: "/admin",
            name: "Admin",
            component: Admin,
        }
        /*,
        {
            path: "/watchlist",
            name: "Watchlist",
            component: Watchlist,
        },
        {
            path: "/detach_chart",
            name: "DetachChart",
            component: DetachChart,
        },
        {
            path: "/forgot",
            name: "Forgot",
            component: Forgot,
        },
        {
            path: "/reset",
            name: "Reset",
            component: Reset,
        }*/,
    ],
});

router.beforeEach((to, from, next) => {

    //console.log('router', to, from);

    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["/login", "/register", "/forgot", "/reset", "/health", "/marketoverview"];
    const authRequired = !publicPages.includes(to.path);
    let loggedIn = JSON.parse(localStorage.getItem("user"));

    window.currentUrlPath = to.path;

    if (authRequired) {
        let now = new Date();
        if (loggedIn && ("/directchart" != to.path)) {
            //console.log(loggedIn, loggedIn.lastAction, loggedIn.token, now.getTime());
        }
        else if ("/directchart" == to.path) {
            if (to.query && to.query.token && to.query.name && to.query.auth && to.query.mode ) {
                loggedIn = {
                    FIRST_NAME: to.query.name,
                    isAdmin: to.query.auth == 'a1',
                    mode: to.query.mode,
                    token: to.query.token,
                    lastAction: (new Date()).getTime()
                };
                localStorage.setItem("user", JSON.stringify(loggedIn));
            }
            return next({
                path: "/chart",
                query: {
                    token: to.query.token,
                    symbol: to.query.symbol,
                }
            });
        }

        if ((!loggedIn) || (!loggedIn.lastAction) || (!loggedIn.token) || ((loggedIn.lastAction + 1000*60*180) < now.getTime())) {
            //console.log('load loginpage')
            return next("/login");
        }
        else {
            //console.log('update last Action')
            loggedIn.lastAction = now.getTime();
        }
    }

    next();
});
