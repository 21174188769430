<template>
  <div id="app">
    <!-- $route.fullPath to re render all the component when URL params change manually -->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  name: "app",
};
</script>
