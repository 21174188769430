<script>
import Overlay from "../lib/mixins/overlay.js";
import ChartUtils from "../utils/ChartUtils.js";

export default {
    name: "PriceGap",
    mixins: [Overlay],
    methods: {
        meta_info() {
            return {
                author: "akjcodes",
                version: "1.0",
                desc: "Exponential Moving Average",
            };
        },
        tool() {
            return {
                // Descriptor for the tool
                group: "Indicators",
                type: "Indicator",
                chartType: "onchart",
                name: "PriceGap",
                hint: "This hint will be shown on hover",
                settings: {
                    minPriceGap: 0,
                    numberOfCandles: 300,
                    gapUpColor: "#00ff00",
                    gapUpOpacity: 10,
                    gapDownColor: "#ff0000",
                    gapDownOpacity: 10,
                    rerender: 0,
                },
                fieldsTemplate: [
                    ["integer", "Minimum Gap", "minPriceGap", 0],
                    ["integer", "Number of Candles", "numberOfCandles", 1],
                    ["color", "Gap Up Color", "gapUpColor"],
                    ["integer", "Gap Up Opacity", "gapUpOpacity", 0, 100],
                    ["color", "Gap Down Color", "gapDownColor"],
                    ["integer", "Gap Down Opacity", "gapDownOpacity", 0, 100],
                ],
            };
        },
        init() {
            this.ohlcv = window.chartHandler.charts[this.$parent.tv_id].data.get_one("chart.data");
        },
        draw(ctx) {
          if (!this.$parent.$parent.$parent.$parent.$parent.chartHandler.chartSettings.isAutoPriceGaps) return;

          const layout = this.$props.layout;

          //console.log(this.priceGaps);
          let height, y1;
          const arrayData = this.$parent.$parent.$parent.$parent.data.data.chart.data;
            for (var p of this.priceGaps) {
                /*if (p.type == "gap_up") {
                  ctx.fillStyle = this.gap_up_color;
                  ctx.fillStyle += ChartUtils.alphaToHex(this.gap_up_opacity);
                } else {*/
              let x1 = layout.t2screen(p.afterDT);
              let width = ( (arrayData && arrayData.length > 3) && p.until == null) ? layout.t2screen(arrayData[arrayData.length - 1][0]) - x1 : (p.untilDT == p.afterDT ?  layout.px_step  : layout.t2screen(p.untilDT) - x1);
              /*if (p.filledHigh) {
                //ctx.beginPath();
                y1 = layout.$2screen(p.filledHigh);
                height = layout.$2screen(p.filledLow) - y1;
                ctx.fillStyle = "rgba(234,84,183,0.43)";//this.gap_up_color;
                //ctx.fillStyle += ChartUtils.alphaToHex(this.gap_up_opacity);
                ctx.fillRect(x1, y1, width, height);
              }*/
                  //ctx.fillStyle = this.gap_down_color;
              ctx.fillStyle = "rgba(152,152,152,0.06)";//this.gap_up_color;
                  //ctx.fillStyle += ChartUtils.alphaToHex(this.gap_down_opacity);
                //}
                //ctx.beginPath();
              y1 = layout.$2screen(Math.min(p.high,p.filledLow));
              height = layout.$2screen(Math.max(p.low,p.filledHigh)) - y1;
              ctx.fillRect(x1, y1, width, height);
              //console.log('priceGaps', p.afterDT, x1, y1, width, height);
            }
        },
        use_for() {
            return ["PriceGap"];
        },
        // Colors for the legend, should have the
        // same dimention as a data point (excl. timestamp)
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        rerender() {
            return this.sett.rerender || 0;
        },
        numberOfCandles() {
            return this.sett.numberOfCandles || 300;
        },
        min_price_gap() {
            return this.sett.minPriceGap || 1;
        },
        gap_up_color() {
            return this.sett.gapUpColor ? this.sett.gapUpColor : "#00ff00";
        },
        gap_up_opacity() {
            return this.sett.gapUpOpacity || 10;
        },
        gap_down_color() {
            return this.sett.gapDownColor ? this.sett.gapDownColor : "#ff0000";
        },
        gap_down_opacity() {
            return this.sett.gapDownOpacity || 10;
        },

      sett() {
        return this.$props.settings;
      },
      priceGaps() {
          //console.log('priceGaps',this.setts);
        return this.setts.priceGaps || [];
      },
      new_font() {
        return "14px " + this.$props.font.split("px").pop();
      },
    },
    data() {
        return {
          setts: this.$parent.$parent.$parent.$parent.$parent.chartHandler.charts[''+this.$parent.$parent.$parent.$parent.$props.id].settings
        };
    },

  //watch: {
        //rerender(n, o) {
        //    this.ohlcv = window.chartHandler.charts[''+this.$parent.tv_id].data.get_one("chart.data");
      //},
  //},
};
</script>
