<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Seg from "../lib/components/primitives/seg.js";

export default {
    name: "AUTOHORI",
    mixins: [Overlay, Tool],
    methods: {
        p1(i) {
          if (this.setts.horizontals && this.setts.horizontals[i]) {
            return this.setts.horizontals[i];
          }
          return null;
        },
        meta_info() {
            return { author: "C451", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                group: "Indicators",
                type: "Indicator",
                chartType: "onchart",name: "AUTO HORI",
                hint: "Price Range",
                data: [], // Default data
                settings: {
                    color: "#b2b5be",
                    isAutoHorizontals: true,
                }, // Default settings
                fieldsTemplate: [
                    ["bool", "Show Price", "showPrice"]
                ],
            };
        },
        // Called after overlay mounted
        init() {
          /*
            if (this.p1) {
                this.pins.push(
                    new Pin(this, "p1", {
                        state: "finished",
                        t: this.p1[0],
                        y$: this.p1[1],
                    })
                );
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "finished",
                        t: this.p2[0],
                        y$: this.p2[1],
                    })
                );
            } else {
                // First pin is settled at the mouse position
                this.pins.push(new Pin(this, "p1"));
                // Second one is following mouse until it clicks
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "tracking",
                    })
                );
                this.pins[1].on("settled", () => {
                    // Call when current tool drawing is finished
                    // (Optionally) reset the mode back to 'Cursor'
                    this.set_state("finished");
                    this.$emit("drawing-mode-off");

                    //global drawing mode
                    //window.$store.drawingMode = false;
                });
            }*/
        },
        draw(ctx) {
            if (!this.$parent.$parent.$parent.$parent.$parent.chartHandler.chartSettings.isAutoHori) return;

            let i = -1;
            while (this.p1(++i)) {
              const p1 = this.p1(i);
              const layout = this.$props.layout;
              this.x1 = layout.t2screen(p1.startDate);
              this.x2 = layout.t2screen(p1.breakoutAt);
              const y = layout.$2screen(p1.price);

              //console.log(p1);

              ctx.lineWidth = 1;
              ctx.setLineDash([]);

              ctx.beginPath();
              const theme = this.$parent.$parent.$parent.$parent.$parent.chartHandler.chartSettings.theme || "dark";
                // line styles
              /*if (theme.indexOf("dark") >=0) {
                    // for dark background
                    // default
                    ctx.strokeStyle = p1.type != "RESISTANCE" ? "#519da4" : "#e37f7f";
                    ctx.setLineDash([]);

                } else {*/
                    // for white background
                    // default
                  //ctx.strokeStyle = "#202020";//"#202020";
                  ctx.strokeStyle = p1.type != "RESISTANCE" ? "#0c7d85" : "#e14d4d";
                  //ctx.setLineDash([]);

                //}

              ctx.setLineDash([2,2]);
              const arrayData = this.$parent.$parent.$parent.$parent.data.data.chart.data;
              if (arrayData && arrayData.length > 3) {
                //new Seg(this, ctx, false)
                const x3 = layout.t2screen(arrayData[arrayData.length - 1][0]);
                if (!p1.breakoutAt) {
                  this.x2 = x3;
                } else {
                  ctx.moveTo(x3, y);

                  ctx.lineTo(this.x2, y);
                  //console.log(x3, this.x2)
                  //.draw([p1.breakoutAt, nY], [arrayData[arrayData.length - 1][0], nY]);
                  ctx.stroke();
                  this.draw_level(ctx, p1.price, layout.t2screen(p1.startDate), x3, y, false, true);

                  ctx.beginPath();
                }
              }


              ctx.setLineDash([]);
              ctx.lineWidth = 2.5; //this.line_width;
              ctx.moveTo(this.x1, y);
              ctx.lineTo(this.x2, y);
              ctx.stroke();

            }
        },

        draw_level(ctx, p, x1, x2, y, showPrice, showPercentage) {
            let price = "",
                percentage = "";
            ctx.font = this.new_font;
            ctx.fillStyle = '#e2e2e2'; //this.value_color;
            ctx.textAlign = "center";

            if (showPrice) {
              price = `${this.layout.screen2$(y).toFixed(window.chartHandler.displayData.prec)}`;
              if (showPercentage) {
                price = '('+price+')';
              }
            }
            if (showPercentage) percentage = `${p.toFixed(window.chartHandler.displayData.prec)}`;

            let text = `${percentage} ${price} `;
            let w = ctx.measureText(text).width;
            ctx.fillText(text, x2 - w * 0.5, y - 4);
        },

        use_for() {
            return ["AUTOHORI"];
        },
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        levels() {
            return this.$props.settings.levels || [0, 23.6, 38.2, 50, 61.8, 78.6, 100, 138.2, 161.8, 200, 261.8, -138.2, -161.8, -200, -261.8];
        },
        line_width() {
            return this.sett.lineWidth || 1;
        },
        color() {
            return this.sett.color || this.$props.colors.colorCross;
        },
        new_font() {
            return "1spx " + this.$props.font.split("px").pop();
        },
    },
    data() {
        return {
          setts: this.$parent.$parent.$parent.$parent.$parent.chartHandler.charts[''+this.$parent.$parent.$parent.$parent.$props.id].settings,
        }
    },
};
</script>
