import Vue from 'vue'
import App from './App.vue'
import { router } from "./router";
import {ChartHandler} from "./utils/ChartHandler";
//import VueAppleLogin from 'vue-apple-login';


// MOB_DEBUG=true npm run test - Enables mobile debugging
// (sending console output to the webpack terminal)
if (MOB_DEBUG) {
    console.log = debug
    console.error = debug
    console.warn = debug
}
/** Accordion init **/
document.addEventListener("click", function(e) {
    //console.log('click', 'test')

    //e.target.parentElement.classList.toggle("active");

    if (window.chartHandler && !window.chartHandler.isDrawingMode) {
        if (e.target.id && (e.target.id.indexOf('-grid-') >= 0) && (e.target.id.indexOf('-canvas') >= 0)) {
            //console.log(e.target.id);

            window.chartHandler.setActiveChart(e.target.id.substr(0, 1));

            if ((window.chartHandler.chartObjects[window.chartHandler.activeChart] &&
                window.chartHandler.chartObjects[window.chartHandler.activeChart].data &&
                window.chartHandler.chartObjects[window.chartHandler.activeChart].data.data)) {
                window.chartHandler.setActiveTool(window.chartHandler.chartObjects[window.chartHandler.activeChart].data.data.selected, true);
            }
            window.chartHandler.updateContextMenue();
        }

        if (!e.target.classList.contains("accordion-header")) return;
        //console.log('click', 'test2')

        // if clicked on the active one
        if (e.target.parentElement.classList.contains("active")) {
            e.target.parentElement.classList.toggle("active");
            return;
        }

        // hide current accordion
        if (document.querySelector(".accordion-item.active")) document.querySelector(".accordion-item.active").classList.remove("active");

        e.target.parentElement.classList.toggle("active");
    }
});


/** From dc event **/
function loadIndicatorSettings(tool, showSidebar) {
    // exclude some tools
    console.log('tool',tool)
    if (tool.type == "AutoOverlay" || tool.type == "ClusterResults") return;

    // hide current accordion
    if (document.querySelector("#indicator_list .accordion-item.active")) document.querySelector("#indicator_list .accordion-item.active").classList.remove("active");

    var id = tool.indicatorID.split(".")[1];

    if (tool.type == "AUTOFIB") {
        id = tool.settings.id.split(".")[1];
    }

    var accordionHTML = `<div class="accordion-item active" indicatorId="${tool.indicatorID}">
                            <div class="accordion-header">${id}
                                <span onclick="delIndicator('${tool.indicatorID}')"><img style="float: right; width: 20px; margin-top: 11px" class="icon icon-delete" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZAQMAAAD+JxcgAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAAZQTFRFAAAATU1NkJ+rOQAAAAJ0Uk5TAP9bkSK1AAAALUlEQVR4nGNgAIN6ENHQACX4//9gYBBgYIESYC4LkA0lPEkmGFAI5v8PILYCAHygDJxlK0RUAAAAAElFTkSuQmCC"></span>
                                <span onclick="toggleVisible('${tool.indicatorID}', event)"><img id="toggleVisible" style="float: right;width: 24px;margin-top: 10px;margin-right: 4px;" class="icon" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAR1QTFRFAAAAh4eHgYGBAAAAAAAAgYGBAAAAAwMDAAAAAAAAgYGBg4ODGBgYgYGBhISEAAAAPz8/AgIChoaGCQkJhYWFPj4+NjY2goKCgYGBAQEBJycngYGBgoKCEBAQCAgIhISEKioqZGRkCgoKBQUFERERd3d3gYGBg4ODgYGBGxsbNDQ0hISEgoKCgoKChYWFPDw8gYGBgYGBhoaGgoKCg4ODgoKCgYGBgoKCgoKCgoKCg4ODgoKChoaGgoKCgYGBhoaGg4ODYWFhBgYGdXV1gYGBg4ODgoKCgICAg4ODg4ODhISEAAAAg4ODOjo6gYGBGhoaeXl5goKCgYGBgoKChYWFgoKChISEgoKCY2NjgYGBg4ODgYGBgYGBg4ODgYGBo8n54AAAAF90Uk5TACn/AhH3BSgPEuhUJvFACigoLBM2KCeA6ykm+pMgIEkmKSEoICn9XCkmJ0u6nDop4sUypGuEzLZ6vmCYLZ/dLykpJynUYa8pcllCC1Ip2ycpisl1PadFsintbsPQZdi/bTW7AAAB4UlEQVR4nOWUZ1fCMBSGSSGWFiq0UDbIkr2XbBwMxS0b1P//M0xK9XSiftX7oel585zkvfcmMRj+SRhvzRRlthm/BU3Ry3TYzofTsajpIOjw2iNAjIiddehvHXSdA0mkXEEdG0fkE1DEKXmkSVqVIA6rBmsktUgAWLWHoGp30UNclbtLmwQgoyya91wPTbFy0mQXJ5zJQO6BgXRjfH0iSkX5stHIXr5r0bB/lu8syjR8rzsFbR2SpX+5J2eMP3csLtYsEY2K8BeTFuE2jaVCBw7bHOBuxq16AXmpbui3LtIfbRLUHMY2q4lcFo2WB4KA1SUAlWumNEKCzyxBKZxVHvYGaFguCBx1vM/x0IPzoqQoj5SdP4mns2cCGhBsrgj0uaeUBtzMyxQN8w4mYROTW8+r0oANp8W5mf6WQw5aCYJ2o7ymPaKMi2uVpmWM4TW6tdImgGo1bT4nK6DbbsCc0AZSdmLEFszzHrh6riVvRrNA3/9SE8QLWQu+Gjto9+gE9NBMwr9zi83gFeeFTe11zpm1CHE3HeyVCSknf3MIDcFTbfJKdbR1L4xX49L+/BoillV5uPJqkshD3JWSgpNMXP/lcrD8+hO84MnDr5YpFHv0Fe99VjJ0GBRs2H74aP6R+ACr+TFvZNAQ1wAAAABJRU5ErkJggg=="></span>
                            </div>
                            <div class="accordion-body"></div>
                        </div>`;

    document.getElementById("indicator_list").insertAdjacentHTML("beforeend", accordionHTML);

    // generate setting form fields
    const fields = tool.fieldsTemplate || [];

    let html = "";
    for (let i = 0; i < fields.length; i++) {
        // fields[i].push(tool.settings[fields[i][2]])
        html += generateSetingsForm(fields[i], tool.settings[fields[i][2]]);
    }

    // generated settings form to newly created according
    document
        .getElementById("indicator_list")
        .lastElementChild.querySelector(".accordion-body")
        .insertAdjacentHTML("afterbegin", html);

    // init event listeners to newly created form
    addListener(document.getElementById("indicator_list").lastElementChild, updateIndicatorSettings);

    // show the sidebar if it's not active
    if (showSidebar || showSidebar == undefined) showSideBar("show");
}

/** reload all the setings  **/
function reloadIndicatorSettings() {
    let DC = window.$store.activeChart.chart;

    document.getElementById("indicator_list").innerHTML = ""; // delete all

    let tools = DC.get(".");

    if (tools == undefined || tools.length == 0) return;

    for (let i = 0; i < tools.length; i++) {
        let tool = tools[i];
        let type = tool.type;
        let list = DC.data.tools;

        // previously there were no field templates from dc get
        /* let proto = list.find(x => {
            return x.name === type;
        }); */

        tool.fieldsTemplate = tool.fieldsTemplate;
        tool.indicatorID = tool.id;
        loadIndicatorSettings(tool, false);
    }

    const $chart = document.getElementById(window.$store.activeChart.id);
    if ($chart.classList.contains("hideOffcharts")) {
        document.getElementById("btn-offcharts").textContent = "Show Offcharts";
    } else {
        document.getElementById("btn-offcharts").textContent = "Hide Offcharts";
    }
}

/** Generate Setting form **/
function generateSetingsForm(field, value) {
    console.log(field, value)
    const fieldType = field[0];
    const fieldName = field[1];
    const fieldID = field[2];
    const fieldValue = value;

    switch (fieldType) {
        case "lineWidth":
            return `
            <div class="field-group">
                <label>${fieldName}</label>
                <div class="dropdown dropdown-line">
                    <div class="dropdown-selector">
                        <div class="selected-value">
                            <span class='line line-${fieldValue}'></span>
                        </div>
                        <div class="control_wrapper">
                            <div class="decreaseControl"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8"><path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path></svg></div>
                        </div>
                    </div>
                    <ul class="dropdown-menu">
                        <li setting="${fieldID}" value="1"><span class='line line-1'></span></li>
                        <li setting="${fieldID}" value="2"><span class='line line-2'></span></li>
                        <li setting="${fieldID}" value="3"><span class='line line-3'></span></li>
                    </ul>
                </div>
            </div>`;
            break;

        case "integer":
            const minTag = field[3] != undefined ? `min=${field[3]}` : "min=1";
            const maxTag = field[4] != undefined ? `max=${field[4]}` : "";

            return `
                <div class="field-group">
                    <label>${fieldName}</label>
                    <div class="akj-input akj-input-number">
                        <div class="selected-value">
                            <input type="number" value="${fieldValue}" setting="${fieldID}" ${minTag} ${maxTag}>
                        </div>
                        <div class="control_wrapper">
                            <div style="transform: rotate(180deg); margin-bottom: -6px;" class="increaseControl"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8"><path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path></svg></div>
                            <div class="decreaseControl"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8"><path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path></svg></div>
                        </div>
                    </div>
                </div>`;
            break;

        case "color":
            return `
            <div class="field-group">
                <label>${fieldName}</label>
                <div class="dropdown akj-color">
                    <div class="dropdown-selector">
                        <div class="selected-value"><div class="swatch" style="background-color: ${fieldValue};"></div></div>
                    </div>
                    <ul class="dropdown-menu">
                        <li setting="${fieldID}" value="#ffffff"><div class="swatch" style="background-color: rgb(255, 255, 255);"></div></li>
                        <li setting="${fieldID}" value="#b2b5be"><div class="swatch" style="background-color: rgb(178, 181, 190);"></div></li>
                        <li setting="${fieldID}" value="#787b86"><div class="swatch" style="background-color: rgb(120, 123, 134);"></div></li>
                        <li setting="${fieldID}" value="#000000"><div class="swatch" style="background-color: rgb(0, 0, 0);"></div></li>
                        <li setting="${fieldID}" value="#ce93d8"><div class="swatch" style="background-color: rgb(206, 147, 216);"></div></li>
                        <li setting="${fieldID}" value="#f48fb1"><div class="swatch" style="background-color: rgb(244, 143, 177);"></div></li>
                        <li setting="${fieldID}" value="#e57373"><div class="swatch" style="background-color: rgb(229, 115, 115);"></div></li>
                        <li setting="${fieldID}" value="#ffb74d"><div class="swatch" style="background-color: rgb(255, 183, 77);"></div></li>
                        <li setting="${fieldID}" value="#fff176"><div class="swatch" style="background-color: rgb(255, 241, 118);"></div></li>
                        <li setting="${fieldID}" value="#81c784"><div class="swatch" style="background-color: rgb(129, 199, 132);"></div></li>
                        <li setting="${fieldID}" value="#4db6ac"><div class="swatch" style="background-color: rgb(77, 182, 172);"></div></li>
                        <li setting="${fieldID}" value="#4dd0e1"><div class="swatch" style="background-color: rgb(77, 208, 225);"></div></li>
                        <li setting="${fieldID}" value="#64b5f6"><div class="swatch" style="background-color: rgb(100, 181, 246);"></div></li>
                        <li setting="${fieldID}" value="#9575cd"><div class="swatch" style="background-color: rgb(149, 117, 205);"></div></li>
                        <li setting="${fieldID}" value="#ba68c8"><div class="swatch" style="background-color: rgb(186, 104, 200);"></div></li>
                        <li setting="${fieldID}" value="#f06292"><div class="swatch" style="background-color: rgb(240, 98, 146);"></div></li>
                        <li setting="${fieldID}" value="#ef5350"><div class="swatch" style="background-color: rgb(239, 83, 80);"></div></li>
                        <li setting="${fieldID}" value="#ffa726"><div class="swatch" style="background-color: rgb(255, 167, 38);"></div></li>
                        <li setting="${fieldID}" value="#ffee58"><div class="swatch" style="background-color: rgb(255, 238, 88);"></div></li>
                        <li setting="${fieldID}" value="#66bb6a"><div class="swatch" style="background-color: rgb(102, 187, 106);"></div></li>
                        <li setting="${fieldID}" value="#26a69a"><div class="swatch" style="background-color: rgb(38, 166, 154);"></div></li>
                        <li setting="${fieldID}" value="#26c6da"><div class="swatch" style="background-color: rgb(38, 198, 218);"></div></li>
                        <li setting="${fieldID}" value="#42a5f5"><div class="swatch" style="background-color: rgb(66, 165, 245);"></div></li>
                        <li setting="${fieldID}" value="#7e57c2"><div class="swatch" style="background-color: rgb(126, 87, 194);"></div></li>
                        <li setting="${fieldID}" value="#ab47bc"><div class="swatch" style="background-color: rgb(171, 71, 188);"></div></li>
                        <li setting="${fieldID}" value="#ec407a"><div class="swatch" style="background-color: rgb(236, 64, 122);"></div></li>
                        <li setting="${fieldID}" value="#d32f2f"><div class="swatch" style="background-color: rgb(211, 47, 47);"></div></li>
                        <li setting="${fieldID}" value="#f57c00"><div class="swatch" style="background-color: rgb(245, 124, 0);"></div></li>
                        <li setting="${fieldID}" value="#fbc02d"><div class="swatch" style="background-color: rgb(251, 192, 45);"></div></li>
                        <li setting="${fieldID}" value="#388e3c"><div class="swatch" style="background-color: rgb(56, 142, 60);"></div></li>
                        <li setting="${fieldID}" value="#00796b"><div class="swatch" style="background-color: rgb(0, 121, 107);"></div></li>
                        <li setting="${fieldID}" value="#0097a7"><div class="swatch" style="background-color: rgb(0, 151, 167);"></div></li>
                        <li setting="${fieldID}" value="#1976d2"><div class="swatch" style="background-color: rgb(25, 118, 210);"></div></li>
                        <li setting="${fieldID}" value="#512da8"><div class="swatch" style="background-color: rgb(81, 45, 168);"></div></li>
                        <li setting="${fieldID}" value="#7b1fa2"><div class="swatch" style="background-color: rgb(123, 31, 162);"></div></li>
                        <li setting="${fieldID}" value="#c2185b"><div class="swatch" style="background-color: rgb(194, 24, 91);"></div></li>
                        <li setting="${fieldID}" value="#b71c1c"><div class="swatch" style="background-color: rgb(183, 28, 28);"></div></li>
                        <li setting="${fieldID}" value="#121826"><div class="swatch" style="background-color: rgb(230, 81, 0);"></div></li>
                        <li setting="${fieldID}" value="#f57f17"><div class="swatch" style="background-color: rgb(245, 127, 23);"></div></li>
                        <li setting="${fieldID}" value="#1b5e20"><div class="swatch" style="background-color: rgb(27, 94, 32);"></div></li>
                        <li setting="${fieldID}" value="#004d40"><div class="swatch" style="background-color: rgb(0, 77, 64);"></div></li>
                        <li setting="${fieldID}" value="#006064"><div class="swatch" style="background-color: rgb(0, 96, 100);"></div></li>
                        <li setting="${fieldID}" value="#0d47a1"><div class="swatch" style="background-color: rgb(13, 71, 161);"></div></li>
                        <li setting="${fieldID}" value="#311b92"><div class="swatch" style="background-color: rgb(49, 27, 146);"></div></li>
                        <li setting="${fieldID}" value="#4a148c"><div class="swatch" style="background-color: rgb(74, 20, 140);"></div></li>
                        <li setting="${fieldID}" value="#880e4f"><div class="swatch" style="background-color: rgb(136, 14, 79);"></div></li>
                    </ul>
                </div>
            </div>`;
            break;

        case "text":
            return `
            <div class="field-group">
                <label>${fieldName}</label>
                <div class="akj-input akj-input-text">
                    <input type="text" setting="${fieldID}" value="${fieldValue}">
                </div>
            </div>`;
            break;

        case "bool":
            let defaultValue = "checked";
            if (field[3] != undefined) {
                defaultValue = field[3] == true ? "checked" : "";
            }

            return `
            <div class="field-group">
                <label>${fieldName}</label>
                <div class="akj-input akj-input-check">
                    <input type="checkbox" setting="${fieldID}" ${defaultValue}>
                </div>
            </div>`;
            break;

        case "options":
            return `
            <div class="field-group">
                <label>${fieldName}</label>
                <div class="dropdown dropdown-line">
                    <div class="dropdown-selector">
                        <div class="selected-value">
                            ${fieldValue}
                        </div>
                        <div class="control_wrapper">
                            <div class="decreaseControl"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8"><path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path></svg></div>
                        </div>
                    </div>
                    <ul class="dropdown-menu">
                        <li setting="${fieldID}" value="Full Line">Full Line</li>
                        <li setting="${fieldID}" value="Extended to Left">Extended to Left</li>
                        <li setting="${fieldID}" value="Extended to Right">Extended to Right</li>
                    </ul>
                </div>
            </div>`;
            break;

        case "sep":
            return `<div class="sep"></div>`;

        default:
            break;
    }
}

/** Change indicator setting **/
function updateIndicatorSettings(setting, value) {
    var indicatorId = document.querySelector(".accordion-item.active").getAttribute("indicatorId");

    var updatedSetting = {};
    updatedSetting[setting] = value;

    let DC = window.$store.activeChart.chart;
    DC.merge(indicatorId + ".settings", updatedSetting);
}

function delIndicator(id) {
    let DC = window.$store.activeChart.chart;
    DC.del(id);
    // document.querySelector('[indicatorid = "' + id + '"]').remove();

    // hide the context menu
    if (document.querySelector(".context-menu")) document.querySelector(".context-menu").remove();
}

function duplicate(id, gridId) {
    let DC = window.$store.activeChart.chart;

    var tool = DC.get(id)[0];
    var settings = { ...tool.settings };
    delete settings.$uuid;
    // delete settings.$selected

    settings.$uuid = `onchart.${tool.type}-${new Date().getTime()}`;
    settings.$selected = false;

    var cloneTool = {
        type: tool.type,
        name: tool.type,
        data: [],
        settings: settings,
        fieldsTemplate: tool.fieldsTemplate,
        grid: {
            id: gridId,
        },
    };
    var id = DC.add("onchart", cloneTool);

    // load setings if available
    if (cloneTool.fieldsTemplate !== undefined) {
        cloneTool.indicatorID = id;
        loadIndicatorSettings(cloneTool, false);
    }

    // hide the context menu
    if (document.querySelector(".context-menu")) document.querySelector(".context-menu").remove();
}

// toggle tool/indicator visible status
function toggleVisible(id, event) {
    let DC = window.chartHandler.charts["0"].data;

    var display_off =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAU1QTFRFAAAAh4eHh4eHAAAAAAAAAAAAAwMDAAAAAAAAhoaGGBgYgYGBAAAAPz8/AgICg4ODCQkJhISEh4eHh4eHPj4+NjY2gYGBg4ODgYGBgYGBgoKCAQEBJycngoKChYWFEBAQg4ODCAgIKioqZGRkCgoKBQUFERERd3d3gYGBGxsbNDQ0hISEgYGBPDw8gYGBgYGBh4eHh4eHhYWFh4eHgoKChYWFgYGBgYGBg4ODhoaGg4ODYWFhgoKCBgYGdXV1goKCg4ODgYGBgICAgYGBAAAAg4ODhYWFhISEh4eHgoKChYWFOjo6goKCGhoah4eHh4eHh4eHgoKCh4eHeXl5hoaGgoKChISEgYGBgYGBgoKCY2NjgYGBgoKCh4eHgoKCgYGBhoaGg4ODhoaGhYWFh4eHgYGBhoaGhoaGhoaGg4ODgoKChISEgoKChYWFh4eHfKktUwAAAG90Uk5TACn/AhEFKA8SLCbxCigoVBNKUTYoJ/lh3PyAKSaTNiBtICYpISggKSkmJ0LEKef3lGxA8rn//+pcMSkpnCcptHPJKe0LUjnx5LzKKaMnX73hl64pLnhkzNSgKeLv17LQ+liIzaLe7PfTw5tFpz3K1fXR/gAAAgBJREFUeJzllNdXwjAUxknB0lIoCKVsGTIFQRAZ7r333nuv///R3LZ4mlDQZ/0ekp7b37n5bnITk+mfyDxv5Tir3fwjaElO5BIOKZFLJS1dQVfI0Y809TtEV+elo95RpFPWG+1go4fdQ5QybI8haaNBkM2ANbM09bnrwaPY7iFKrz7EMBdu7CHdVruXIt0M1hb+GKA3LTRKkp5lTA6Dg6xIkhaHhvQ1IlW/UCouQdJNJTRIpk1qO7+wUpcfpl537oBc7VNip3Gi/AmVPBAC1UrL6HXtSGVT+k2Yz0Focad07OMRf3P5BEbd63PFQx7HN+w61JoAm+uBlV48O/0jkLSMmtPCmQ8HwlYdykFV4/LJPp7e3hVyFdapHNehLk6PSjhSkBvwu/cFyJGIYvOyhoc1jjYQFGbygD4CWjoAMla/og3YoSw+KPhjPNoFcim4iFD+pFYA8zZ9WeYU5OBjZ3ORWyCfG03E+47kKpCIJTpGO4KP8XMgtw990xG/PBNTgmPEEXwf7P42oOdFIRAoBCtqTKL6Rcwq4Xsgh5xYC/mmSs6yJKk1YbnVeTq1NaEpmlHbmVn2EORkW2trF2ZzmHGTSUMGl1a9hp4ySRpdQ8yKGURpMmRIYg9pb1YPzg6kO79cLlE6bYFjEtv91bLEUxvhwbWwjY13BxUb9l8+mn9EX8x3Nki8ff5wAAAAAElFTkSuQmCC";
    var display_on =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAqCAMAAADyHTlpAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAR1QTFRFAAAAh4eHgYGBAAAAAAAAgYGBAAAAAwMDAAAAAAAAgYGBg4ODGBgYgYGBhISEAAAAPz8/AgIChoaGCQkJhYWFPj4+NjY2goKCgYGBAQEBJycngYGBgoKCEBAQCAgIhISEKioqZGRkCgoKBQUFERERd3d3gYGBg4ODgYGBGxsbNDQ0hISEgoKCgoKChYWFPDw8gYGBgYGBhoaGgoKCg4ODgoKCgYGBgoKCgoKCgoKCg4ODgoKChoaGgoKCgYGBhoaGg4ODYWFhBgYGdXV1gYGBg4ODgoKCgICAg4ODg4ODhISEAAAAg4ODOjo6gYGBGhoaeXl5goKCgYGBgoKChYWFgoKChISEgoKCY2NjgYGBg4ODgYGBgYGBg4ODgYGBo8n54AAAAF90Uk5TACn/AhH3BSgPEuhUJvFACigoLBM2KCeA6ykm+pMgIEkmKSEoICn9XCkmJ0u6nDop4sUypGuEzLZ6vmCYLZ/dLykpJynUYa8pcllCC1Ip2ycpisl1PadFsintbsPQZdi/bTW7AAAB4UlEQVR4nOWUZ1fCMBSGSSGWFiq0UDbIkr2XbBwMxS0b1P//M0xK9XSiftX7oel585zkvfcmMRj+SRhvzRRlthm/BU3Ry3TYzofTsajpIOjw2iNAjIiddehvHXSdA0mkXEEdG0fkE1DEKXmkSVqVIA6rBmsktUgAWLWHoGp30UNclbtLmwQgoyya91wPTbFy0mQXJ5zJQO6BgXRjfH0iSkX5stHIXr5r0bB/lu8syjR8rzsFbR2SpX+5J2eMP3csLtYsEY2K8BeTFuE2jaVCBw7bHOBuxq16AXmpbui3LtIfbRLUHMY2q4lcFo2WB4KA1SUAlWumNEKCzyxBKZxVHvYGaFguCBx1vM/x0IPzoqQoj5SdP4mns2cCGhBsrgj0uaeUBtzMyxQN8w4mYROTW8+r0oANp8W5mf6WQw5aCYJ2o7ymPaKMi2uVpmWM4TW6tdImgGo1bT4nK6DbbsCc0AZSdmLEFszzHrh6riVvRrNA3/9SE8QLWQu+Gjto9+gE9NBMwr9zi83gFeeFTe11zpm1CHE3HeyVCSknf3MIDcFTbfJKdbR1L4xX49L+/BoillV5uPJqkshD3JWSgpNMXP/lcrD8+hO84MnDr5YpFHv0Fe99VjJ0GBRs2H74aP6R+ACr+TFvZNAQ1wAAAABJRU5ErkJggg==";

    let d = DC.get(id)[0];

    if (d) {
        if (d.settings.display == undefined || d.settings.display == true) {
            DC.merge(id + ".settings", {
                display: false,
            });

            event.target.src = display_off;
        } else {
            DC.merge(id + ".settings", {
                display: true,
            });

            event.target.src = display_on;
        }
    }
}

// watchlist
function addToChart(event, listname, symbol) {
    if (event.target.classList.contains("close-icon")) {
        window.removeWatchlistItem(listname, symbol, event.currentTarget);
    } else {
        window.loadFromWatch(window.$store.activeChart.id, symbol);
    }
}

// Detach watchlist
function detach_watchlist() {
    if (window.detachWatchlistWindow) {
        window.detachWatchlistWindow.location.reload();
        window.detachWatchlistWindow.focus();
    } else {
        var left = screen.width / 2 - 400 / 2;
        var top = screen.height / 2 - 600 / 2;
        window.detachWatchlistWindow = window.open("./#/watchlist", "detachWatchlistWindow", "width=400,height=600, top=" + top + ",left=" + left);
        window.detachWatchlistWindow.onunload = function() {
            window.detachWatchlistWindow = undefined;
        };
    }
}

// chart settings
// init event listeners


// hide off charts
function toggleOffcharts(event) {
    const activeChart = window.$store.activeChart;
    const DC = activeChart.chart;
    const $chart = document.getElementById(activeChart.id);

    if (DC.data.offchart.length <= 0) return;

    if ($chart.classList.contains("hideOffcharts")) {
        $chart.classList.remove("hideOffcharts");
        event.target.textContent = "Hide Offcharts";
    } else {
        $chart.classList.add("hideOffcharts");
        event.target.textContent = "Show Offcharts";
    }

    window.$store.CHARTS[activeChart.id].hideOffcharts = !window.$store.CHARTS[activeChart.id].hideOffcharts;

    // small hack to refresh the chart
    const prevHeight = DC.data.offchart[0].grid.height;
    DC.set(`${DC.data.offchart[0].id}.grid`, { height: 0.2 });
    DC.set(`${DC.data.offchart[0].id}.grid`, { height: prevHeight });
}

// form.reportValidity(); polyfill
// To support IE and Safari
if (!HTMLFormElement.prototype.reportValidity) {
    HTMLFormElement.prototype.reportValidity = function() {
        if (this.checkValidity()) return true;
        var btn = document.createElement("button");
        this.appendChild(btn);
        btn.click();
        this.removeChild(btn);
        return false;
    };
}

global.chartHandler = null;

    Vue.directive("clickaway", {
    bind: function(el, binding, vnode) {
        let event = function(event) {
            if (vnode.context[binding.expression] && !(el == event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", event);
    },
    unbind: function(el) {
        document.body.removeEventListener("click", event);
    },
});

    /*
Vue.use(VueAppleLogin, {
    clientId: 'com.chart-software.signin',
    scope: 'name email',
    redirectURI: 'https://chart-software.com',
    state: Date.now().toString(),
    usePopup: true,
});
*/
//Vue.prototype.$getScreenDetails = window.getScreenDetails;

new Vue({
  el: '#app',
  render: h => h(App),
  router
})

function debug(...argv) {
    fetch('/debug?argv=' + JSON.stringify(argv))
}
