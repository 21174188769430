<template>
  <div ref="draggableContainer" id="draggable-container" :style="{'visibility': isVisible() ? 'visible' : 'hidden'}">
    <div id="panel-container" >
      <div id="draggable-header" @mousedown="dragMouseDown">
        <i class="fa fa-bars" style="margin-top:0.1em"></i>
        {{caption}}
        <span class="sizeicon" v-on:click="close();"  style="margin-top: 0.01em">
           <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="18" height="18" fill="currentColor" viewBox="0 0 121.31 122.876" enable-background="new 0 0 121.31 122.876" xml:space="preserve"><g><path fill-rule="evenodd" clip-rule="evenodd" d="M90.914,5.296c6.927-7.034,18.188-7.065,25.154-0.068 c6.961,6.995,6.991,18.369,0.068,25.397L85.743,61.452l30.425,30.855c6.866,6.978,6.773,18.28-0.208,25.247 c-6.983,6.964-18.21,6.946-25.074-0.031L60.669,86.881L30.395,117.58c-6.927,7.034-18.188,7.065-25.154,0.068 c-6.961-6.995-6.992-18.369-0.068-25.397l30.393-30.827L5.142,30.568c-6.867-6.978-6.773-18.28,0.208-25.247 c6.983-6.963,18.21-6.946,25.074,0.031l30.217,30.643L90.914,5.296L90.914,5.296z"/></g></svg>
        </span>
        <span class="sizeicon" v-on:click="currentSizeIndex=3;"  style="margin-top: 0.01em">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-window-fullscreen" viewBox="0 0 16 16">
            <path d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"/>
            <path d="M.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H.5ZM1 5V2h14v3H1Zm0 1h14v8H1V6Z"/>
          </svg>
        </span>
        <span class="sizeicon" v-on:click="currentSizeIndex=2" style="margin-top: 0.05em">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-window-fullscreen" viewBox="0 0 16 16">
            <path d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"/>
            <path d="M.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H.5ZM1 5V2h14v3H1Zm0 1h14v8H1V6Z"/>
          </svg>
        </span>
        <span class="sizeicon" v-on:click="currentSizeIndex=1" style="margin-top: 0.13em">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-window-fullscreen" viewBox="0 0 16 16">
            <path d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"/>
            <path d="M.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H.5ZM1 5V2h14v3H1Zm0 1h14v8H1V6Z"/>
          </svg>
        </span>
        <span class="sizeicon" v-on:click="currentSizeIndex=0"  style="margin-top: 0.18em">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-window-fullscreen" viewBox="0 0 16 16">
            <path d="M3 3.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1.5 0a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Zm1 .5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1Z"/>
            <path d="M.5 1a.5.5 0 0 0-.5.5v13a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5v-13a.5.5 0 0 0-.5-.5H.5ZM1 5V2h14v3H1Zm0 1h14v8H1V6Z"/>
          </svg>
        </span>

      </div>
      <!--select v-model="currentSizeIndex" id="currentSizeIndex" name="currentSizeIndex">
        <option value="0">klein</option>
        <option value="1">mittel</option>
        <option value="2">groß</option>
      </select-->
      <div style="margin-top: 0.5em;">
        <!--slot name="main"></slot-->
        <!--textarea v-model="publishText" style="height: 120px; width: 370px" placeholder="Description"></textarea-->
        <!--vue-editor v-model="publishText" :editor-toolbar="customToolbar"></vue-editor-->
        <div v-html="getPublishText()" ></div>
        <!--div>{{getPublishText()}}</div-->
        <div v-show="isShowingVideo">
          <div id="player"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

var tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


export default {
  name: 'DraggableContainer',
  components: { VueEditor },
  props: ["publishText", "caption", "labelActions"],
  data: function () {
    return {
      player: null,
      isShowing: true,
      isShowingVideo: true,
      currentVideoId: "",
      intervalId: -1,
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      currentSizeIndex: 0,
      currentSize: [237,350,450,787.5],
      currentLabelIndex : 0,
      chartHandler: window.chartHandler,
    }
  },
  methods: {

    dragMouseDown: function (event) {
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    close() {
      this.initYoutube(null, 0);
      this.$emit('onclose', null)
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement() {
      this.chartHandler.pinModifyPanel(
          this.chartHandler.chartSettings.isPinModifyPanel,
          this.$refs.draggableContainer.offsetLeft,
          this.$refs.draggableContainer.offsetTop);
      document.onmouseup = null
      document.onmousemove = null
    },
    isVisible() {
      this.isShowing = this.publishText && this.publishText.length;
      return this.publishText && this.publishText.length;
    },
    initYoutube(videoId, hash) {
      const _ = this;
      //console.log("initYoutube videoId", videoId, hash);


      this.isShowingVideo = true;
      if (videoId && (hash != this.currentVideoId)) {
        if (this.player) {
          this.player.stopVideo();
          this.player.clearVideo();
          this.player.loadVideoById(videoId, 0, "large");
          this.player.playVideo();
        } else {
          this.player = new YT.Player("player", {
            width: (this.currentSize[0]*16/9),
            height: this.currentSize[0],
            videoId: videoId,
            modestbranding: 1,
            origin: "https://chart-software.com",
            iv_load_policy: 3,
            events: {
              onReady: _.onPlayerReady,
              onStateChange: _.onPlayerStateChange
            }
          });
        }
        this.currentLabelIndex = 0;
        this.currentVideoId = hash;
        this.$emit('onLabelEvent', {action: {type: "init"}});
      } else if (this.player && !videoId) {
        this.player.stopVideo();
        this.player.clearVideo();
        this.isShowingVideo = false;
      }
    },
    onPlayerReady(evt) {
      console.log("Player ready", evt.target.getDuration());
      evt.target.playVideo();
    },
    onPlayerStateChange(evt) {
      if ( evt.target) {
        console.log("onPlayerStateChange", evt, evt.target.getCurrentTime());
        if (evt.data == 1) {
          this.$emit('onLabelEvent', {action: {type: "init", videoSize: evt.target.getDuration()}});
          this.currentLabelIndex = 0;
        }

        else if (evt.data == 2)
          this.$emit('onLabelEvent', {action: {type: "loaded", videoSize: evt.target.getDuration()}});
      }
    },
    getPublishText() {
      var hash = (this.publishText||"").hashCode();
      var res = this.publishText.replace("<iframe", "<!--iframe width=\""+(this.currentSize[this.currentSizeIndex]*16/9)+"\" height=\""+this.currentSize[this.currentSizeIndex]+"\" " );

      res = res.replace("</iframe>", "</iframe-->" );

      if (res && (res.lastIndexOf("<p><br></p>")==res.length-11)) {
        res = res.substring(0,res.length-11);
      }
      if (res && (res.lastIndexOf("?showinfo")>0)) {
        res = res.replace("?showinfo","?enablejsapi=1&showinfo");
      }
      while (res && (res.indexOf("<p>")>=0)) {
        res = res.replace("<p>","<p style='margin-bottom: 0;'>");
      }


      if (res) {
        let videoId = res.substring(res.indexOf("/embed/")+7);
        videoId = videoId.substring(0,videoId.indexOf("?"));
        console.log('videoId',res.substring(res.indexOf("/embed/")+7,),'######',videoId)
        if (videoId)
          this.initYoutube(videoId, hash);
        else
          this.initYoutube(null, hash);
      } else {
        this.initYoutube(null, hash);
      }

      this.checkLabels();

      return res;
    },
    checkLabels() {
      let currentSec = -1;
      if ((this.player != null) && (this.player.getCurrentTime)) {
        currentSec = this.player.getCurrentTime();
      }
      //console.log("Player state changed", currentSec, this.labelActions);

      if ((currentSec > 0) && (this.labelActions) && (this.labelActions.length) && (this.currentLabelIndex<this.labelActions.length)) {
        if (this.labelActions[this.currentLabelIndex].startSec<currentSec) {
          this.$emit('onLabelEvent', this.labelActions[this.currentLabelIndex], currentSec);
          this.currentLabelIndex++;
        }
      }
      if (this.intervalId<0)
        this.intervalId = setInterval(this.checkLabels.bind(this),1000)

    }
  },
  mounted() {
    const self = this;
    window.onYouTubeIframeAPIReady = () => {
      //console.log("onYouTubeIframeAPIReady");
      if (self.intervalId<0)
        self.intervalId = setInterval(self.checkLabels.bind(self),1000)
    };
  },
  watch: {
    "isShowing"(newValue) {

      if (newValue) {
        //console.log("isVisible", this.$parent.height,this.$refs.draggableContainer.style,this.$refs.draggableContainer.offsetHeight);

        this.$refs.draggableContainer.style.top = (this.$parent.height - this.$refs.draggableContainer.offsetHeight + 20) + 'px'; //(this.$props.indicator.indicatorInstance.settings.p1) + 'px'
        this.$refs.draggableContainer.style.left = '60px'; //(this.$refs.draggableContainer.offsetLeft + 10) + 'px'
      }
    },
    "currentSizeIndex"(sizeIndex) {
      var h = this.$refs.draggableContainer.offsetHeight;
      var t = this.$refs.draggableContainer.offsetTop;
      if (this.player) {
        this.player.setSize((this.currentSize[sizeIndex] * 16 / 9), this.currentSize[sizeIndex]);
        this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop + (h - this.$refs.draggableContainer.offsetHeight)) + 'px'; //(this.$props.indicator.indicatorInstance.settings.p1) + 'px'
      }
    }
  },
  computed: {}
}
</script>

<style scoped>

.sizeicon {
  margin-left: 0.6em;
  cursor: pointer;
  float: right;
  height: 20px;
  bottom: 2px;
  min-height: 25px;
  min-height: 25px;
}

.fa {
  font-size: large;
  padding-top: 0.1em;
}
.fcolorpicker-curbox {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

#draggable-container {
  display: block;
  position: absolute;
  z-index: 90;
  top: 20em;
  left: 30em;
}
#panel-container {
  display: flex;
  flex-direction: column;
  padding: 0.5em;
  color: #d9d9d9;
  background-color: rgb(37, 41, 42);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  box-shadow: 0px 0px 4px #4b4b4b;
}
#draggable-header {
  display: block;
  cursor:grab;
  z-index: 91;
  padding-left: 0.2em;
  padding-right: 0.2em;
  padding-bottom: 0.2em;
  padding-top: 0.1em;
  font-size: medium;
  min-width: 10em;
}

panel-button {
  z-index: 91;
  padding-right: 1em;
  padding-bottom: 0.1em;
}

#color-button-group {
  display: flex;
}

color-button {
  z-index: 91;
}

.button:hover {
  background-color: #3b3b3b;
  border: 1px solid #b9b9b9;
}

.button:focus {
  border: 0px solid #b9b9b9;
  outline:0;
}

button:focus {
  border: 1px solid #b9b9b900;
  outline:1px;
}

.vl {
  border-left: 1px solid rgba(185, 185, 185, 0.49);
  margin: 0.25em;
  margin-top: 0.2em;
  height: 2.0em;
}

.button {
  background-color: #00000000;
  border: 0.1px solid rgba(222, 222, 222, 0.29);
  color: #dedede;
  border-radius: 2px;
  box-shadow_: rgba(117, 119, 119, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember",sans-serif;
  font-size: 0.8em;
  line-height: 2em;
  padding: 2px 2px 2px 2px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  border-left_: 1px solid #b9b9b9;
  margin-top: 0.35em;
  width: 2.4em;
  height: 2.4em;
}

.button1 {
  border-radius: 4px;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.selbutton {
  margin: 0.05em;
}

.colorbutton {
  background-color: #0dca62;

}
.colorbutton:hover {
  background-color: #0dca62;
  color: #dedede;
}

#linewidthpanel {
  width:10em;
  margin-left: 1em;
}

.lineitem {
  padding-left: 0.5em;
  cursor: pointer;
  width:100%;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.lineitem:hover {
  background-color: #1F4661;
}

.lineitemunlink {
  padding-left: 0.5em;
  cursor: default;
  width:100%;
  margin: 0.2em;
  color: rgba(222,222,222,0.41);
}

saveaspanel {
  margin-right: 0.2em;

}

.modifypanel {
  margin-top: 0.5em;
  background-color: rgb(37, 41, 42);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  color: #dedede;
  padding: 0.5em;
}

.row {
  display: flex;
  width:18em;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 50%;/* Should be removed. Only for demonstration */
}
#fontSize {
  width:100%;
}
#colormap {
  display: flex;
  flex-wrap: wrap;
  width: 19em;
}

div.video-annotations {display:none;}

</style>

