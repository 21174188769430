
import Grid from "../lib/components/js/grid";
import Botbar from "../lib/components/js/botbar";
import Sidebar from "../lib/components/js/sidebar";
import Utils from '../lib/stuff/utils.js'

import Hamster from "hamsterjs";
import * as Hammer from "hammerjs";
import math from "../lib/stuff/math";
import Crosshair from "../lib/components/js/crosshair";

Grid.prototype.listeners = function() {

    this.lastClickTime = Date.now();

    window.ctxChart = this.canvas;

    this.offgridVisible = true;
    this.hm = Hamster(this.canvas)
    this.hm.wheel((event, delta) => this.mousezoom(-delta * 50, event))

    let mc = this.mc = new Hammer.Manager(this.canvas)
    let T = Utils.is_mobile ? 10 : 0
    mc.add(new Hammer.Pan({ threshold: T}))
    mc.add(new Hammer.Tap())
    mc.add(new Hammer.Pinch({ threshold: 0}))
    mc.get('pinch').set({ enable: true })
    if (Utils.is_mobile) mc.add(new Hammer.Press())

    mc.on('panstart', event => {
        if (this.cursor.scroll_lock) return
        if (this.cursor.mode === 'aim') {
            return this.emit_cursor_coord(event)
        }
        let tfrm = this.$p.y_transform
        this.drug = {
            x: event.center.x + this.offset_x,
            y: event.center.y + this.offset_y,
            r: this.range.slice(),
            t: this.range[1] - this.range[0],
            o: tfrm ?
                (tfrm.offset || 0) : 0,
            y_r: tfrm && tfrm.range ?
                tfrm.range.slice() : undefined,
            B: this.layout.B,
            t0: Utils.now()
        }
        this.comp.$emit('cursor-changed', {
            grid_id: this.id,
            x: event.center.x + this.offset_x,
            y: event.center.y + this.offset_y
        })
        this.comp.$emit('cursor-locked', true)
    })

    mc.on('panmove', event => {
        if (Utils.is_mobile) {
            this.calc_offset()
            this.propagate('mousemove', this.touch2mouse(event))
        }
        if (this.drug) {
            this.mousedrag(
                this.drug.x + event.deltaX,
                this.drug.y + event.deltaY,
            )
            this.comp.$emit('cursor-changed', {
                grid_id: this.id,
                x: event.center.x + this.offset_x,
                y: event.center.y + this.offset_y
            })
        } else if (this.cursor.mode === 'aim') {
            this.emit_cursor_coord(event)
        }
    })

    mc.on('panend', event => {
        if (Utils.is_mobile && this.drug) {
            this.pan_fade(event)
        }
        this.drug = null
        this.comp.$emit('cursor-locked', false)
    })

    mc.on('tap', event => {
        if (Date.now() - this.lastClickTime < 250)
            this.comp.$emit('custom-event', {
                event: 'grid-doubletap', args : [{grid_id: this.id}]
            });
        this.lastClickTime = Date.now();
        if (!Utils.is_mobile) return

        this.sim_mousedown(event)
        if (this.fade) this.fade.stop()
        this.comp.$emit('cursor-changed', {})
        this.comp.$emit('cursor-changed', {
//            grid_id: this.id,
//            x: undefined,//event.center.x + this.offset_x,
//            y: undefined,//event.center.y + this.offset_y,
            mode: 'explore'
        })
        this.update()
    })


    mc.on('pinchstart', () =>  {
        this.drug = null
        this.pinch = {
            t: this.range[1] - this.range[0],
            r: this.range.slice()
        }
    })

    mc.on('pinchend', () =>  {
        this.pinch = null
    })

    mc.on('pinch', event => {
        if (this.pinch) this.pinchzoom(event.scale)
    })

    mc.on('press', event => {
        if (!Utils.is_mobile) return
        if (event.buttons == 2) {
            this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent.openContextMenu(event);
        }
        if (this.fade) this.fade.stop()
        this.calc_offset()
        this.emit_cursor_coord(event, { mode: 'aim' })
        setTimeout(() => this.update())
        this.sim_mousedown(event)
    })

    let add = addEventListener
    add("gesturestart", this.gesturestart)
    add("gesturechange", this.gesturechange)
    add("gestureend", this.gestureend)
    add("dblclick", this.dblclick.bind(this))

};

Grid.prototype.dblclick = function (event) {
    const cursor = this.comp.$props.cursor

    let x = this.$p.cursor.x
    let y = this.$p.cursor.y
    if (x && y) {
        console.log('dblclick',event,event.x, x, y);
        this.offgridVisible = !this.offgridVisible;
        this.comp.$emit('set-offgrid-visible', this.offgridVisible)
    }
}

Grid.prototype.getLastBarX = function (range) {
    let last = this.comp.$props.meta.last
    if (range)
        return this.t2screenFast(range, last[0])
    else
        return this.layout.t2screen(last[0])
};

Grid.prototype.getLastTime = function () {
    let last = this.comp.$props.meta.last
    return last[0]
};

Grid.prototype.mousedown = function(event) {
    //console.log(event.button,this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent);

    /*if (event.buttons == 2) {
        this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent.openContextMenu(event);
    }
*/
    //event.preventDefault();
    if (event.button == 2) {
        this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent.openContextMenu(event);
        if (event.defaultPrevented) return;
        this.propagate("mousedown", event);
    }

    if (event.button == 0) {
        this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent.closeContextMenu(event);
        this.propagate("mousedown", event);
        //this.comp.$emit("cursor-locked", true);

        if (event.defaultPrevented) return;
        this.comp.$emit("custom-event", {
            event: "grid-mousedown",
            args: [this.id,event],
        });

    }
}

Grid.prototype.t2screenFast = function (range, t) {
    const spacex = this.$p.width - this.layout.sb
    const dt = range[1] - range[0];
    const r = spacex / dt;
    const t1 = this.layout.ti_map.smth2i(t);
    return Math.floor((t1 - range[0]) * r) - 0.5;
};

Grid.prototype.screen2tFast = function (range, x) {
    const spacex = this.$p.width - this.layout.sb
    const dt = range[1] - range[0];
    const r = spacex / dt;
    return range[0] + x / r;
};

Grid.prototype.dpr = function () {
    return window.devicePixelRatio || 1
};

// Actually draws the grid (for real)
Grid.prototype.grid = function () {
    const ch = this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent.chartHandler;
    if (ch && ch.chartSettings && ch.chartSettings.isGrid) {
        //console.log('saveSettings',window.chartHandler.chartSettings.isGrid,(window.chartHandler.chartSettings.isGrid != 1),(window.chartHandler.chartSettings.isGrid != 2));
        this.ctx.strokeStyle = "#88888825"; //this.$p.colors.grid
        this.ctx.beginPath()

        if (ch.chartSettings.isGrid != 1) {
            const ymax = this.layout.height
            for (var [x, p] of this.layout.xs) {

                this.ctx.moveTo(x - 0.5, 0)
                this.ctx.lineTo(x - 0.5, ymax)

            }
        }

        if (ch.chartSettings.isGrid != 2) {
            for (var [y, y$] of this.layout.ys) {
                this.ctx.moveTo(0, y - 0.5)
                this.ctx.lineTo(this.layout.width, y - 0.5)
            }
        }

        this.ctx.stroke()
    }

    if (this.$p.grid_id) this.upper_border()

}

Grid.prototype.mousemove = function (event) {
    if (Utils.is_mobile) return
    this.mouseX = event.layerX;
    this.comp.$emit('cursor-changed', {
        grid_id: this.id,
        x: event.layerX,
        y: event.layerY + this.layout.offset
    })
    this.calc_offset()
    this.propagate('mousemove', event)

    //console.log(this.$p.cursor.t,this.layout.ti_map.i2t(this.$p.cursor.t))
    window.chartHandler.setMouseMove(this.$p.cursor.y$,this.layout.ti_map.i2t(this.$p.cursor.t))
}

Crosshair.prototype.draw = function(ctx) {
    // Update reference to the grid
    this.layout = this.$p.layout

    const cursor = this.comp.$props.cursor
    if (!this.visible && cursor.mode === 'explore' && !window.chartHandler.remoteInfo.showRemoteCursor) return


    this.x = this.$p.cursor.x
    this.y = this.$p.cursor.y

    if ((window.chartHandler.remoteInfo.showRemoteCursor) && ((this.x === undefined) || (this.y === undefined))) {
        this.x = this.comp.ch.layout.t2screen(window.chartHandler.remoteInfo.dt);
        this.y = this.comp.ch.layout.$2screen(window.chartHandler.remoteInfo.price);
    }

    //console.log(this.comp.ch.layout);

    ctx.save()
    ctx.strokeStyle = this.$p.colors.cross
    ctx.beginPath()
    ctx.setLineDash([5])

    // H
    if ((this.$p.cursor.grid_id === this.layout.id) || window.chartHandler.remoteInfo.showRemoteCursor) {
        ctx.moveTo(0, this.y)
        ctx.lineTo(this.layout.width - 0.5, this.y)
    }

    // V
    ctx.moveTo(this.x, 0)
    ctx.lineTo(this.x, this.layout.height)
    ctx.stroke()
    ctx.restore()
}

Crosshair.prototype.hide = function() {
    window.chartHandler.resetMouseMove()
    this.visible = false
    this.x = undefined
    this.y = undefined
}

Grid.prototype.update = function() {
    // Update reference to the grid
    // TODO: check what happens if data changes interval
    this.layout = this.$p.layout.grids[this.id]
    this.interval = this.$p.interval

    if (!this.layout) return

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
    if (window.chartHandler.settings.showChart) {
        if (this.$p.shaders.length) this.apply_shaders()

        let mouseSelected = false;

        this.grid()

        let overlays = []
        overlays.push(...this.overlays)

        // z-index sorting
        overlays.sort((l1, l2) => l1.z - l2.z)

        overlays.forEach(l => {
            if (!l.display) return
            this.ctx.save()
            let r = l.renderer
            r.canDrawPin = !mouseSelected;
            if (r.pre_draw) r.pre_draw(this.ctx)
            r.draw(this.ctx)
            //console.log(l, mouseSelected,r.show_pins);
            mouseSelected = mouseSelected || r.show_pins;
            if (r.post_draw) r.post_draw(this.ctx)
            this.ctx.restore()
        })
    }

    /**/
    if (this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$refs.leftbar && !this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$refs.leftbar.isCursorSelected() && this.cursor.y) {
        this.comp.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$refs.leftbar.drawMouseIcon(this.ctx,this.mouseX+15,this.cursor.y+10);
    } else if (this.crosshair) {
        this.crosshair.renderer.draw(this.ctx)
        this.ctx.stroke();
    }
}

Grid.prototype.mousezoom = function (delta, event) {
    //const now = new Date()
    const dt = Date.now()

    // TODO: for mobile
    if (this.wmode !== 'pass') {
        if (this.wmode === 'click' && !this.$p.meta.activated) {
            return
        }
        event.originalEvent.preventDefault()
        event.preventDefault()
    }

    window.chartHandler.resetHintTimer(this.id);

    event.deltaX = event.deltaX || Utils.get_deltaX(event)
    event.deltaY = event.deltaY || Utils.get_deltaY(event)

    if (Math.abs(event.deltaX) > 0) {
        this.trackpad = true
        if (Math.abs(event.deltaX) >= Math.abs(event.deltaY)) {
            delta *= 0.1
            // TODO test mit Tablet und co (MAC geht mit Functionsaufruf nicht)
            //this.trackpad_scroll(event)
        }
    }

    if (this.trackpad) delta *= 0.032

    delta = Utils.smart_wheel(delta)

    // TODO: mouse zooming is a little jerky,
    // needs to follow f(mouse_wheel_speed) and
    // if speed is low, scroll shoud be slower
    if (delta < 0 && this.data.length <= this.MIN_ZOOM) return
    if (delta > 0 && this.data.length > this.MAX_ZOOM) return
    let k = this.interval / 1000
    let diff = delta * k * this.data.length
    let t1 = this.comp.config.ZOOM_MODE === 't1'
    let t2 = this.comp.config.ZOOM_MODE === 't2'
    let t3 = this.comp.config.ZOOM_MODE === 't3'

    if (t3 && this.range && this.range.length) {
        //let xLastPrev = this.getLastBarX()
        let xLastPrev = this.getLastBarX(this.range)
        if (this.layout.width > xLastPrev) {
            //let LastTimePrev = this.getLastTime()
            this.range[0] -= diff
            //this.change_range(true)
            //this.change_range(true)
            let xNewPrev2 = this.screen2tFast(this.range, xLastPrev)

            let xLast = this.getLastBarX(this.range)//this.getLastBarX()
            //let xNew = this.comp.layout.grids[0].screen2t(xLast)
            let xNew = this.screen2tFast(this.range, xLast)

            //console.log(xLast, xNewPrev2, xNew)

            this.range[0] += (xNew - xNewPrev2)
            this.range[1] += (xNew - xNewPrev2)
        } else {
            t1 = true
        }
    }
    if ((event.originalEvent.ctrlKey || t1 || t2) && (!event.originalEvent.ctrlShift)) {
        let offset = event.originalEvent.offsetX * this.dpr()
        let diff1 = offset / (this.canvas.width-1) * diff
        let diff2 = diff - diff1
        this.range[0] -= diff1
        if ((t2 && !event.originalEvent.ctrlKey) || (!t2 && event.originalEvent.ctrlKey))
            this.range[1] -= diff1
        else
            this.range[1] += diff2
    } else if (!t3) {
        this.range[0] -= diff
    }
    //
        //        if (t1 || t2) {
        //        let offset = event.originalEvent.offsetY
        //        let diff1 = offset / (this.canvas.height-1) * 2
        //        let diff2 = 2 - diff1
        //        let z = diff / (this.range[1] - this.range[0])
        //        //rezoom_range(z, diff_x, diff_y)
    //        this.comp.$emit('rezoom-range', {
    //                grid_id: this.id, z, diff1, diff2
        //            })
    //    }

    this.change_range()

    //const time = new Date()
    this.performanceInfo = -1; //time.getTime() - now.getTime()
    //console.log(Date.now()-dt)

};

Botbar.prototype.listeners = function () {
    this.hm = Hamster(this.canvas)
    this.hm.wheel((event, delta) => this.mousezoom(-delta * 50, event))

    let mc = this.mc = new Hammer.Manager(this.canvas)
    let T = Utils.is_mobile ? 10 : 0
    mc.add(new Hammer.Pan({ threshold: T}))
    mc.add(new Hammer.Tap())
    mc.add(new Hammer.Pinch({ threshold: 0}))
    mc.get('pinch').set({ enable: true })
    if (Utils.is_mobile) mc.add(new Hammer.Press())

    mc.on('panstart', event => {
        if (this.cursor.scroll_lock) return
        if (this.cursor.mode === 'aim') {
            return this.emit_cursor_coord(event)
        }
        let tfrm = this.$p.y_transform
        this.drug = {
            x: event.center.x + this.offset_x,
            y: event.center.y + this.offset_y,
            r: this.range.slice(),
            t: this.range[1] - this.range[0],
            o: tfrm ?
                (tfrm.offset || 0) : 0,
            y_r: tfrm && tfrm.range ?
                tfrm.range.slice() : undefined,
            B: this.layout.B,
            t0: Utils.now()
        }
        this.comp.$emit('cursor-changed', {
            grid_id: this.id,
            x: event.center.x + this.offset_x,
            y: event.center.y + this.offset_y
        })
        this.comp.$emit('cursor-locked', true)
    })

    mc.on('panmove', event => {
        if (Utils.is_mobile) {
            this.calc_offset()
            this.propagate('mousemove', this.touch2mouse(event))
        }
        if (this.drug) {
            this.mousedrag(
                this.drug.x + event.deltaX,
                this.drug.y + event.deltaY,
            )
            this.comp.$emit('cursor-changed', {
                grid_id: this.id,
                x: event.center.x + this.offset_x,
                y: event.center.y + this.offset_y
            })
        } else if (this.cursor.mode === 'aim') {
            this.emit_cursor_coord(event)
        }
    })

    mc.on('panend', event => {
        if (Utils.is_mobile && this.drug) {
            this.pan_fade(event)
        }
        this.drug = null
        this.comp.$emit('cursor-locked', false)
    })

    mc.on('tap', event => {
        if (!Utils.is_mobile) return
        this.sim_mousedown(event)
        if (this.fade) this.fade.stop()
        this.comp.$emit('cursor-changed', {})
        this.comp.$emit('cursor-changed', {
        //    grid_id: this.id,
         //   x: undefined,//event.center.x + this.offset_x,//
        //    y: undefined,//event.center.y + this.offset_y,
            mode: 'explore'
        })
        this.update()
    })

    mc.on('pinchstart', () =>  {
        this.drug = null
        this.pinch = {
            t: this.range[1] - this.range[0],
            r: this.range.slice()
        }
    })

    mc.on('pinchend', () =>  {
        this.pinch = null
    })

    mc.on('pinch', event => {
        if (this.pinch) this.pinchzoom(event.scale)
    })

    mc.on('press', event => {
        if (!Utils.is_mobile) return
        if (this.fade) this.fade.stop()
        this.calc_offset()
        this.emit_cursor_coord(event, { mode: 'aim' })
        setTimeout(() => this.update())
        this.sim_mousedown(event)
    })

    let add = addEventListener
    add("gesturestart", this.gesturestart)
    add("gesturechange", this.gesturechange)
    add("gestureend", this.gestureend)

}

Botbar.prototype.mousemove = function(event) {
    //this.grid_0.mousemove(event)
}

Botbar.prototype.mouseout = function(event) {
    //this.grid_0.mouseout(event)
}

Botbar.prototype.mouseup = function(event) {
    //this.grid_0.mouseup(event)
}

Botbar.prototype.mousedown = function(event) {
    //this.grid_0.mousedown(event)
}

Botbar.prototype.touch2mouse = function(t) {
    return this.calc_offset(),
        {
            original: t.srcEvent,
            layerX: t.center.x + this.offset_x,
            layerY: t.center.y + this.offset_y,
            preventDefault: function() {
                this.original.preventDefault()
            }
        }
}
Botbar.prototype.upper_border = function() {
    this.ctx.strokeStyle = this.$p.colors.scale,
        this.ctx.beginPath(),
        this.ctx.moveTo(0, .5),
        this.ctx.lineTo(this.layout.width, .5),
        this.ctx.stroke()
}
Botbar.prototype.mousedrag = function(t) {
    let e = this.drug.t * (this.drug.x - t) / this.layout.botbar.width
        , s = this.layout.botbar.width / 4
        , i = this.layout.botbar.width - this.layout.botbar.width / 4;
    this.drug.x < s ? this.range[0] = this.drug.r[0] + e : this.drug.x > i ? this.range[1] = this.drug.r[1] + e : this.range[0] = this.drug.r[0] + e,
        this.change_range()
}
Botbar.prototype.calc_offset = function() {
    let t = this.canvas.getBoundingClientRect();
    this.offset_x = -t.x,
        this.offset_y = -t.y
}
Botbar.prototype.change_range = function() {
    if (!this.range.length || this.data.length < 2)
        return;
    let t = this.data.length - 1
        , e = this.data
        , s = this.range;
    s[0] = Utils.clamp(s[0], -1 / 0, e[t][0] - 5.5 * this.interval),
        s[1] = Utils.clamp(s[1], e[0][0] + 5.5 * this.interval, 1 / 0),
        //this.comp.$parent.$emit("range-changed", s)
    this.comp.$parent.$parent.setRange(s[0],s[1])
}
Botbar.prototype.trackpad_scroll = function(t) {
    let e = this.range[1] - this.range[0];
    this.range[0] += t.deltaX * e * .011,
        this.range[1] += t.deltaX * e * .011,
        this.change_range()
}
Botbar.prototype.destroy = function() {
    this.mc && this.mc.destroy()
}

Botbar.prototype.dpr = function () {
    return window.devicePixelRatio || 1
};

Sidebar.prototype.dpr = function () {
    return window.devicePixelRatio || 1
};


Sidebar.prototype.calc_range = function(diff1 = 1, diff2 = 1) {

    let z = this.zoom / this.drug.z
    let zk = (1 / z - 1) / 2

    let range = this.y_range.slice()
    let delta = range[0] - range[1]

    if (!this.layout.grid.logScale) {
        if ((this.drug) && ((this.drug.y*this.dpr()) < (this.canvas.height/2))) {
            range[0] = range[0] + delta * zk * diff1
            range[1] = range[1] // - delta * zk * diff2
        }else {
            range[0] = range[0] //+ delta * zk * diff1
            range[1] = range[1]  + delta * zk * diff2
        }
    } else {

        let px_mid = this.layout.height / 2
        let new_hi = px_mid - px_mid * (1/z)
        let new_lo = px_mid + px_mid * (1/z)

        // Use old mapping to get a new range
        let f = y => math.exp((y - this.drug.B) / this.drug.A)

        let copy = range.slice()
        range[0] = f(new_hi)
        range[1] = f(new_lo)

    }

    return range
}


// A gray bar behind the current price
Sidebar.prototype.update = function () {

    // Update reference to the grid
    this.layout = this.$p.layout.grids[this.id]

    var points = this.layout.ys
    var x, y, w, h, side = this.side
    var sb = this.layout.sb

    //this.ctx.fillStyle = this.$p.colors.back
    this.ctx.font = this.$p.font

    switch(side) {
        case 'left':
            x = 0
            y = 0
            w = Math.floor(sb)
            h = this.layout.height

            //this.ctx.fillRect(x, y, w, h)
            this.ctx.clearRect(x, y, w, h)

            this.ctx.strokeStyle = this.$p.colors.scale

            this.ctx.beginPath()
            this.ctx.moveTo(x + 0.5, 0)
            this.ctx.lineTo(x + 0.5, h)
            this.ctx.stroke()

            break
        case 'right':
            x = 0
            y = 0
            w = Math.floor(sb)
            h = this.layout.height
            //this.ctx.fillRect(x, y, w, h)
            this.ctx.clearRect(x, y, w, h)

            this.ctx.strokeStyle = this.$p.colors.scale

            this.ctx.beginPath()
            this.ctx.moveTo(x + 0.5, 0)
            this.ctx.lineTo(x + 0.5, h)
            this.ctx.stroke()
            break
    }
    this.drawClusters(this.$p.layout.grids[this.id])

    this.ctx.fillStyle = this.$p.colors.text
    this.ctx.beginPath()

    for (var p of points) {

        if (p[0] > this.layout.height) continue

        var x1 = side === 'left' ? w - 0.5 : x - 0.5
        var x2 = side === 'left' ? x1 - 4.5 : x1 + 4.5

        this.ctx.moveTo(x1, p[0] - 0.5)
        this.ctx.lineTo(x2, p[0] - 0.5)

        var offst = side === 'left' ? - 10 : 10
        this.ctx.textAlign = side === 'left' ? 'end' : 'start'
        let d = this.layout.prec
        this.ctx.fillText(p[1].toFixed(d), x1 + offst, p[0] + 4)
    }

    this.ctx.stroke()


    if (this.$p.grid_id) this.upper_border()

    this.apply_shaders()


    let layout = this.$p.layout.grids[this.id]
    let props = {
        layout: layout,
        cursor: this.$p.cursor
    }
    for (var s of this.$p.shaders) {
        this.ctx.save()
        s.draw(this.ctx, props)
        this.ctx.restore()
    }

    if (this.$p.cursor.y && this.$p.cursor.y$) this.panel()

}

Sidebar.prototype.drawClusters = function () {
    if ((window.chartHandler) &&
        (window.chartHandler.chartObjects) &&
        (window.chartHandler.chartObjects[this.layout.id]) &&
        (window.chartHandler.chartObjects[this.layout.id].fibData) &&
        (window.chartHandler.chartObjects[this.layout.id].fibData.clusters)) {
        var PANHEIGHT = 100

        const cluster = window.chartHandler.chartObjects[this.layout.id].fibData.clusters
        const ctx = this.ctx
        const self = this
        ctx.fillStyle = "rgb(192,153,0)";

        cluster.forEach((c) => {
    //        let lbl = this.$p.cursor.y$.toFixed(this.layout.prec)

            let panwidth = self.layout.sb + 1

            let x = - 0.5
            let y = self.layout.$2screen(c.lowerPrice)
            let a = 7
            PANHEIGHT = self.layout.$2screen(c.upperPrice) - self.layout.$2screen(c.lowerPrice)

            ctx.fillRect(x - 0.5, y, panwidth, PANHEIGHT)
            //this.ctx.fillStyle = this.$p.colors.textHL
            //      this.ctx.textAlign = 'left'
        })    //this.ctx.fillText(lbl, a, y + 15)
    }
}