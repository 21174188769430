import Vue from 'vue';
import {ChartServerInterface} from "./ChartServerInterface.js";
import {LayoutHandler} from "./LayoutHandler";

export class MarketOverviewHandler {
    constructor(token, isAdmin, query) {
        const self = this;
        this.layoutHandler = new LayoutHandler(this, query);
        this.displayData = {token: [], symbol: "", marketListsLayout: [], marketLists:[], marketListToAdd: [], layout: [], layoutConfig :null, svgToolTip : ""};

        this.chartServerInterface = new ChartServerInterface(this, 'https://chart-software.com', 'https://chart-software.com/api', 'wss://chart-software.com/wsapi', 'wss://as01.chart-software.com/md', 'as01', false);
        this.channel = new BroadcastChannel('cs-com-data');

        this.checkLogin(token);

        if (this.channel) {
            this.channel.addEventListener("message", (event) => {
                if (event.data) {
                    if (event.data.action == "changeSymbol") {
                        //console.log('addEventListener - message',  event.data, event.data.symbol)
                        Vue.set(self.displayData, 'symbol', event.data.symbol);
                    } else if (event.data.action == "loginInfo") {
                        //console.log('addEventListener - message',  event.data, event.data.token)
                        setTimeout(this.checkLogin.bind(this),100,(event.data.token));
                    } else if (event.data.action == "screenInfo") {
                        //console.log('addEventListener - message',  event.data, event.data.token)
                        setTimeout(this.layoutHandler.checkScreenInfo.bind(this.layoutHandler),100, self.channel, 'ml');
                    } else if (event.data.action == "close") {
                        window.close();
                    }
                }
            })
        }
        Vue.set(self.displayData, 'symbol', "");

        /*if (query && query.fullscreen) {
            setTimeout(this.fullScreen.bind(this), 5000);
        }*/
    }

    checkLogin(token) {
        console.log(token);
        while (this.displayData.token.pop()) ;

        if (token)
            this.displayData.token.push(token);

        this.chartServerInterface.setToken(token);
        if (token)
            this.chartServerInterface.requestWatchList();
    }

    setChangeSymbol(symbol) {
        if (this.channel)
            this.channel.postMessage({action : "changeSymbol", symbol : symbol, source: "ml"})
    }

    handleWatchListResponce(watchList) {
        //console.log(watchList);
    }

    handleUserApiLogin(loginResult) {
        console.log('handleUserApiLogin',loginResult);
        this.chartServerInterface.getMarketListLayouts();
        this.chartServerInterface.requestMarketLists();
        this.chartServerInterface.requestUserViews();
        //this.chartServerInterface.aboUpdates("BTCUSDT.BINANCE", "H1")
    }

    handleMDApiLogin(loginResult) {
        console.log('handleMDApiLogin',loginResult);

    }

    handlePriceUpdate(symbol, timeframe, tickData){
        console.log('handlePriceUpdate',symbol, timeframe, tickData);
        const now = Date.now();
        this.displayData.marketLists.forEach((ml) => {
            ml.details.forEach((mld) => {
                if ((mld.symbol == symbol) && (Math.abs(mld.price - tickData.close)>0.0001)) {
                    console.log('handlePriceUpdate',mld.price,tickData)
                    mld.new = now;
                    mld.newUp = mld.price <= tickData.close;
                    mld.price = tickData.close;
                    mld.percent = tickData.prev && (tickData.prev>0.000001)? (tickData.close / tickData.prev * 100) - 100 : 0;
                } else {
                    //mld.new = 0;
                }
            });
        });
    }


    handleGetMarketLists(data) {
        //console.log('handleGetMarketLists', data)
        while (this.displayData.marketLists.pop()) ;
        if (data && data.data && (data.data.length>=0)) {
            const self = this;
            let res = [];
            data.data.forEach((ml) => {
                //res.push(ml);
                ml.details = [];
                ml.sortKey = 'id';
                ml.sortDir = 1;
                ml.svg = "";
                ml.getOptionKey = (ml) => { return ml.marketListId; }
                self.displayData.marketLists.push(ml);
                self.chartServerInterface.requestMarketListDetails(ml.marketListId);
            });
           // Vue.set(this.displayData,'marketLists',res);
            /*data.data.forEach((ml) => {
                self.displayData.marketLists.push(ml);
            });*/
        }
        this.updateMarketListsLayout();
    }

    handleGetMarketListDetails(data) {
        const self = this;
        //console.log('handleGetMarketListDetails',data);
        if (data && (data.marketListId >= 0)) {
            this.displayData.marketLists.forEach((ml)=>{
                if (data.marketListId === ml.marketListId) {
                    while (ml.details.pop()) ;
                    data.data.forEach((mld) => {
                        mld.price = 0.0;
                        mld.percent = 0.0;
                        mld.new = false;
                        mld.newUp = true;
                        self.chartServerInterface.aboUpdates(mld.symbol, "D1")
                        ml.details.push(mld);
                        mld.colIndex = mld.colIndex || ml.details.length;
                        mld.colIndexOrg = mld.colIndex;
                        mld.owner = ml;
                    });
                    this.changeDirection('colIndex', data.marketListId);
                }
            });
        }
        this.updateMarketListsLayout();
    }

    handleSetMarketListDetail(data) {
        //console.log('handleSetMarketListDetail',data);
        const self = this;
        if (data && (data.marketListId >= 0)) {
            this.displayData.marketLists.forEach((ml)=>{
                if (data.marketListId === ml.marketListId) {
                    let mld = {price : 0.0, percent : 0.0, symbol: data.symbol, new: false, newUp: true}
                    self.chartServerInterface.aboUpdates(mld.symbol, "D1")
                    ml.details.push(mld);
                    mld.colIndex = mld.colIndex || ml.details.length;
                    mld.colIndexOrg = mld.colIndex;
                    mld.owner = ml;
                }
            });
        }
        this.updateMarketListsLayout();
    }

    handleUpdMarketListDetail(data) {
        //console.log('handleUpdMarketListDetail',data);
        if (data && (data.marketListId >= 0)) {
            this.chartServerInterface.aboUpdates(data.symbol, "D1")
            this.displayData.marketLists.forEach((ml)=>{
                if (data.marketListId === ml.marketListId) {
                    ml.details.forEach((mld, idx)=>{
                        if (mld.symbol === data.symbol) {
                            mld.colIndex = data.colIndex
                            mld.colIndexOrg = mld.colIndex;
                            ml.details.sort(this.sortMarketList);
                        }
                    });
                }
            });
        }
        this.updateMarketListsLayout();
    }

    handleDelMarketListDetail(data) {
        if (data && (data.marketListId >= 0)) {
            this.displayData.marketLists.forEach((ml)=>{
                if (data.marketListId === ml.marketListId) {
                    let foundIdx = -1;
                    ml.details.forEach((mld, idx)=>{
                        if (mld.symbol === data.symbol) {
                            foundIdx = idx;
                        }
                    });
                    if (foundIdx>=0)
                        ml.details.splice(foundIdx,1)
                }
            });
        }
        this.updateMarketListsLayout();
    }

    handleGetMarketListLayouts(data) {
        //console.log('handleGetMarketListLayouts', data)
        if (data && data.data && data.data.length) {
            this.displayData.layoutConfig = data.data[0].configData;
        } else {
            this.displayData.layoutConfig = [];
        }
        this.updateMarketListsLayout();
    }

    handleSetMarketListLayout(data) {
        //console.log('handleSetMarketListLayout', data)
        if (data && data.configData) {
            this.displayData.layoutConfig = data.configData;
        } else {
            this.displayData.layoutConfig = [];
        }
        this.updateMarketListsLayout();
    }

    updateColIndex(marketListId) {
        //console.log('updateColIndex', marketListId)
        if (marketListId >= 0) {
            this.displayData.marketLists.forEach((ml)=>{
                if (ml.marketListId === marketListId) {
                    ml.details.forEach((mld, idx)=>{
                        mld.colIndex = idx+1
                    });
                }
            });
        }
        this.updateMarketListsLayout();
    }

    changeDirection(col, marketListId) {
        //console.log('changeDirection',col, marketListId)
        if (marketListId >= 0) {
            this.displayData.marketLists.forEach((ml)=>{
                if (ml.marketListId === marketListId) {
                    if (ml.sortKey === col)
                        ml.sortDir *= -1;
                    else
                        ml.sortKey = col;
                    ml.details.sort(this.sortMarketList);
                }
            });
        }
    }

    sortMarketList(mld1, mld2) {
        let res = 0;

        if (mld1[mld1.owner.sortKey] > mld2[mld1.owner.sortKey]) {
            res = 1;
        } else if (mld1[mld1.owner.sortKey] < mld2[mld1.owner.sortKey]) {
            res = -1;
        }

        return res * mld1.owner.sortDir;
    }

    updMarketListDetails(marketListId) {
        if (marketListId >= 0) {
            this.displayData.marketLists.forEach((ml)=>{
                if (ml.marketListId === marketListId) {
                    ml.details.forEach((mld) => {
                        if (mld.colIndexOrg !== mld.colIndex) {
                            this.chartServerInterface.updMarketListDetail(marketListId, mld.symbol, mld.colIndex);
                        }
                    });
                }
            });
        }
    }

    resetMarketListDetailsSort(marketListId) {
        if (marketListId >= 0) {
            this.displayData.marketLists.forEach((ml)=>{
                if (ml.marketListId === marketListId) {
                    ml.details.forEach((mld) => {
                        mld.colIndex = mld.colIndexOrg;
                    });
                    ml.details.sort(this.sortMarketList);
                }
            });
        }
    }

    addMarketListLayout(marketListId, x, y, w, h) {
        if (marketListId >= 0) {
            let found = false;
            this.displayData.layoutConfig.forEach((cl)=>{
                if (cl.i == marketListId) {
                    found = true;
                }
            });
            if (!found) {
                let cl = {
                    x: x,
                    y: y,
                    w: w,
                    h: h,
                    i: ""+marketListId}
                this.displayData.layoutConfig.push(cl)
                this.updateMarketListsLayout();
            }
        }
    }

    delMarketListLayout(marketListId) {
        if (marketListId >= 0) {
            let foundIdx = -1;
            this.displayData.layoutConfig.forEach((cl, idx)=>{
                if (cl.i == marketListId) {
                    foundIdx = idx;
                }
            });
            if (foundIdx >= 0) {
                this.displayData.layoutConfig.splice(foundIdx,1)
                this.updateMarketListsLayout();
            }
        }
    }

    setMarketListLayout(layoutName, data) {
        this.chartServerInterface.setMarketListLayout(layoutName, data);
    }

    updateMarketListsLayout() {
        //console.log('updateMarketListsLayout', this.displayData.marketLists.length, this.displayData.layoutConfig)
        if ((this.displayData.marketLists.length>0) && (this.displayData.layoutConfig !== null)) {
            while (this.displayData.marketListsLayout.pop()) ;
            while (this.displayData.marketListToAdd.pop()) ;
            while (this.displayData.layout.pop()) ;
            this.displayData.marketLists.forEach((ml) => {
                let found = false;
                this.displayData.layoutConfig.forEach((lc) => {
                    if (ml.marketListId == lc.i) {
                        found = true;
                        this.displayData.marketListsLayout.push(ml);
                        this.displayData.layout.push(lc);
                    }
                });
                if (!found) {
                    this.displayData.marketListToAdd.push(ml);
                }
                this.updateMarketListSVG(ml);
            });
        }
    }

    updateMarketListSVG(ml) {
        let percentSum = 0;
        let elements = [];
        let svg = ""
        let countUp = 0;
        let countDown = 0;
        ml.details.forEach((mld) => {
            percentSum += Math.abs(mld.percent);
            elements.push(mld);
            if (mld.percent>=-0.00001)
                countUp++;
            else
                countDown++;
        })

        elements.sort((f1, f2) => { return Math.abs(f2.percent) - Math.abs(f1.percent);})

        const xOffset = 6;
        const yOffset = 40;
        const xGlobal = 988;
        const yGlobal = 794;
        let xSize = 0;
        let ySize = 0;

        let iLastPrint = -1;
        let fPrintPercent = 0;

        elements.forEach((elem) => {
            if (Math.abs(elem.percent)>0.0001) {
                let s = (xGlobal * yGlobal) / 100 * ((100 * Math.abs(elem.percent)) / percentSum);
                const dir = xSize > ySize;
                let x, y;
                if (!dir) {
                    y = (yGlobal-ySize);
                    x = s/(y)
                } else {
                    x = (xGlobal-xSize);
                    y = s / (x);
                }
                const p1 = Math.trunc(xSize)+1.5+xOffset;
                const p2 = Math.trunc(ySize)+1.5+yOffset;
                const p3 = Math.trunc(xSize+x)-1.5+xOffset;
                const p4 = Math.trunc(ySize+y)-1.5+yOffset;

                //return '#a20c31';
                //return '#167540';
                //let col = elem.percent>=0 ? '#469b53':  '#e84f4f';
                let col = elem.percent>=0 ? '#167540':'#a20c31';
                let rotate = !dir && ((p4-p2) > (p3-p1)) ? " rotate" : ""
                let svg1 = "<g><polygon class=\"st1\" style=\"fill:"+col+";\"  points=\""+p1+","+p2+" "+p3+","+p2+" "+p3+","+p4+" "+p1+","+p4+"\"></polygon>\n";

                if (rotate.length >5)
                    svg1 += "<text class=\"sub-text "+rotate+"\" x=\""+((-p4+20))+"\" y=\""+(p3-15)+"\"  > "+ elem.symbol +"</text>\n";
                else
                    svg1 += "<text class=\"sub-text "+rotate+"\" x=\""+(p1+15)+"\" y=\""+(p4-15)+"\"  > "+ elem.symbol +"</text>\n";

                svg1 += "<text class=\"sub-text\" style=\"fill:rgb(45,58,61);\"  text-anchor=\"end\" x=\"490\" y=\"32\"  > "+ elem.symbol +"</text>\n";
                svg1 += "<text class=\"sub-text\" style=\"fill:"+col+";\" x=\"510\" y=\"32\"  > "+elem.price.toFixed(4)+" ("+ elem.percent.toFixed(2) +" %)</text>\n";
                svg1 += "</g>";

                svg = svg1 + svg;

                //if (ml.marketListId == 1) {
                //    console.log('ele', xGlobal * yGlobal, s, (100 * Math.abs(elem.percent)) / percentSum); //ml.marketListId, percentSum, (xGlobal * yGlobal) / 100, (100 * Math.abs(elem.percent)) / percentSum, elem.percent, s, xGlobal * yGlobal, xSize, ySize, x, y);
                //}
                if (!dir) {
                    xSize = xSize + x;
                } else  {
                    ySize = ySize + y;
                }
            }
        })

        //console.log(svg)

        Vue.set(ml, 'countUp', countUp);
        Vue.set(ml, 'countDown', countDown);

        Vue.set(ml,"svg","<svg version=\"1.1\" id=\"Layer_1\" xmlns=\"http://www.w3.org/2000/svg\" "+
            "xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"" +
            "500px\" height=\"420px\" viewBox=\"0 0 1000 840\" style=\"enable-background:new 0 0 1000 840;\" xml:space=\"preserve\">\n" +"\n" +
            "<polygon style=\"fill:rgb(222,222,222);\"  points=\"0,0 1000,0 1000,850 0,850\"></polygon>\n"+
                svg + "</svg>");
            /*
            "  <polygon style=\"fill:#b7044f;\"  points=\"0,0 300,0 300,300 0,300 \">\n" +
            "  </polygon>\n" +
            "    <polygon style=\"fill:#0491B7;\" points=\"0,0 600,0 300,300 0,300 \">\n" +
            "  </polygon>\n" +
            "    <text x=\"10\" y=\"40\"> SAP </text>\n" +
            "    <text x=\"40\" y=\"70\" class=\"sub-text\" style=\"font-size:0.5em;font-size:0.5em;fill:#0491B7;\">Some Content</text>\n" +
            "    </g>\n" +
            "\n" +
            "  <polygon style=\"fill:#b7044f;\"  points=\"0,353.813 649,353.813 891.5,663.313 0,663.313 \">\n" +
            "    <title>Two</title>\n" +
            "  </polygon>\n" +
            "\n" +
            "  <polygon style=\"fill:#b76846;\"  points=\"392.5,347.313 514.75,179.813 645.25,347.313 \">\n" +
            "    <title>Three</title>\n" +
            "  </polygon>\n" +
            "\n" +
            "  <polyline class=\"st2\" points=\"518.875,173.388 644.667,0.501 1139.833,0 1139.75,662.063 897.25,662.063\">\n" +
            "    <title>Four</title>\n" +
            "  </polyline>\n" +'*/
            //"</svg>");
    }

    fullScreen() {
        if (document.fullscreenElement) {
            /* Close fullscreen */
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                /* IE/Edge */
                document.msExitFullscreen();
            }
        } else {
            var elem = document.getElementById("viewMain");
            console.log(elem);
            if (elem) {
                if (elem.requestFullscreen) {
                    elem.requestFullscreen();
                } else if (elem.mozRequestFullScreen) {
                    /* Firefox */
                    elem.mozRequestFullScreen();
                } else if (elem.webkitRequestFullscreen) {
                    /* Chrome, Safari & Opera */
                    elem.webkitRequestFullscreen();
                } else if (elem.msRequestFullscreen) {
                    /* IE/Edge */
                    elem.msRequestFullscreen();
                } else {
                    return;
                }
            }
        }
    }
}