<template>
    <div>
        <Dropdown :chart-handler="chartHandler" @item-selected="$emit('item-selected', $event)" @item-delete="on_item_delete" @item-save="on_item_save" @item-fav="on_item_fav" :items="valid_templates" :title="'Charts'" :hasDelete="true" :hasSave="true" :nameKey="'NAME'" :filterInfoKey="tf" :infoKey="'TIMEFRAME'">
            <div class="btn-wrapper">
                <div @click="save" class="btn btn-primary hide_on_detach">Save</div>
                <div @click="open_modal" class="btn btn-ghost btn-primary hide_on_detach">Save As</div>
            </div>
        </Dropdown>
        <div class="modal" v-if="modelOpen">
            <div class="modal_header">
                <h3>Save Chart</h3>
                <span @click="modelOpen = false" class="close-icon" style="width: 18px; height: 18px;"></span>
            </div>
            <div class="modal_content">
                <form ref="form" @submit="submit">
                    <label style="display: block; margin-bottom: 4px" for="Template Name">Template Name</label>
                    <div class="akj-input">
                        <input type="text" v-model="templateName" placeholder="Enter Chart Name" required />
                    </div>
                    <br />
                    <label style="display: block; margin-bottom: 4px" for="Tags">Tags</label>
                    <div class="akj-input">
                        <input type="text" v-model="templateTags" placeholder="Enter Tags, Separated by a comma" />
                    </div>
                </form>
            </div>
            <div class="modal_footer btn-wrapper">
                <div @click="modelOpen = false" class="btn btn-primary btn-ghost">Cancel</div>
                <div  class="btn btn-primary">Save</div>
            </div>
        </div>
    </div>
</template>

<script>
import Dropdown from "./Dropdown.vue";
//import akjStuff from "@/helpers/akjStuff.js";
//import API from "@/helpers/fb.js";

export default {
    name: "ChartTemplates",
    props: ["templates", "tf", "chartHandler"],
    components: { Dropdown },

    created() {
        //API.init();
      //console.log(this.templates);
    },

    methods: {
        submit(e) {
            e.preventDefault();

            if (this.templateName.trim() == "") return;

            const template = {
                NAME: this.templateName.trim(),
                //CHART_DATA: JSON.stringify(akjStuff.getTemplate(false)),
                SYMBOL: window.$store.activeChart.symbol,
                TIMEFRAME: window.$store.activeChart.timeframe,
                TAGS: this.templateTags
                    .trim()
                    .split(",")
                    .toString(),
            };

            //const existingId = this.templates.filter((a) => a.NAME.toLowerCase() == this.templateName.trim().toLowerCase());
            /*if (existingId.length == 0) {
                API.dbChartTemplate.create(template, (result) => {
                    if (result.success) this.templates.unshift({ ID_CHART: result.createdId, ...template });
                });
            } else {
                API.dbChartTemplate.update(
                    existingId[0].ID_CHART,
                    {
                        CHART_DATA: template.CHART_DATA,
                        TAGS: template.TAGS,
                    },
                    (result) => {
                        // console.log(result);
                    }
                );
            }*/

            // Empty input field
            this.templateName = "";
            this.templateTags = "";

            // close the modal
            this.modelOpen = false;
        },

        save() {
            console.log(this.$parent.chartHandler.charts["0"]);

          this.$parent.chartHandler.saveConfig('chart', true);

          //this.$parent.chartHandler.saveTemplate('chart', this.$parent.chartHandler.charts["0"].data.chartId, {
          //  CHART_DATA: JSON.stringify(data)
          //});
            return;
            const chartID = window.$store.activeChart.id;
            if (window.$store.activeTemplates[chartID] == undefined || window.$store.activeTemplates[chartID].chartTemplateID == undefined) {
                // then save as
                this.open_modal();
                return;
            }

            const templateId = window.$store.activeTemplates[chartID].chartTemplateID;

            /*if (templateId.length != 0) {
                API.dbChartTemplate.update(
                    templateId,
                    {
                        CHART_DATA: JSON.stringify(akjStuff.getTemplate(false)),
                    },
                    (result) => {
                        // console.log(result);
                    }
                );
            }*/
        },

        on_item_delete(item) {
          console.log('del template',item);
          this.$parent.chartHandler.deleteTemplate('chart',item.ID_CHART);
            //API.dbChartTemplate.delete(item.ID_CHART);
        },

        open_modal() {
            // hide if there's any modalsaveChart_selected
            //if (document.querySelector(".modal")) document.querySelector(".modal").remove();

            console.log('showmodal');
            this.$emit("saveChart_selected", 'charts');
        },

        on_item_save(item) {
          this.$parent.chartHandler.saveConfigWithIDAndName('chart', item.NAME,item.ID_CHART,item.TAGS);
        },

        on_item_fav(item) {
          this.$parent.chartHandler.favoritesHandler.addToFavorites(item.NAME);
        }
    },

    computed: {
        valid_templates() {
            if (!this.templates)
              return [];
            //console.log('handleTemplateResponce valid_templates',this.templates);
            let res = this.templates.filter((item) => item.NAME != "DEFAULT");
            res.sort((r1,r2)=> {
              if ((r1.TIMEFRAME == this.tf) && (r2.TIMEFRAME == this.tf)) {
                return r1.NAME.localeCompare(r2.NAME);
              } else if (r1.TIMEFRAME == this.tf) {
                return -1;
              } else if (r2.TIMEFRAME == this.tf) {
                return 1;
              } else {
                return r1.NAME.localeCompare(r2.NAME);
              }
            });
            //console.log('handleTemplateResponce valid_templates',res);
            return res;
        },
    },

    data() {
        return {
            templateName: "",
            templateTags: "",
            modelOpen: false,
        };
    },
};
</script>


<style>

/*==== Modal style ====*/
modal1 {  top:5%;
          right:50%;
          outline: none;
          overflow:hidden;

}
.modal {
   position: fixed;
   min-width: 400px;
   border-radius: 8px;
   background-color: #0c0f15;
   left: 50%;
   top: 50%;
   transform: translate(-50%, -50%);
   z-index: 1000;
 }
.modal_header {
  padding: 12px;
  border-bottom: 1px solid #414953;
}
.modal_header h3 {
  font-size: 14px;
  margin: 0;
}
.modal_content {
  padding: 12px;
  max-height: 60vh;
  overflow-y: scroll;
}
.modal_footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 12px;
  border-top: 1px solid #414953;
}
.modal_footer .btn {
  margin-left: 12px;
}
</style>
