<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
//import Seg from "../primitives/seg.js";

export default {
    name: "ZoomTool",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "C451", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                name: "ZoomTool",
                group: "ZoomTool",
                icon: Icons["zoom.png"],
                type: "Price",
                hint: "Price Range",
                data: [], // Default data
                settings: {}, // Default settings
            };
        },

        // Called after overlay mounted
        init() {
            // First pin is settled at the mouse position
            this.pins.push(new Pin(this, "p1"));
            // Second one is following mouse until it clicks
            this.pins.push(
                new Pin(this, "p2", {
                    state: "dragging",
                })
            );

            this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("ZoomTool", this.id, this.$props.settings);


            this.pins[1].on("settled", () => {
                // Call when current tool drawing is finished
                // (Optionally) reset the mode back to 'Cursor'
                this.set_state("finished");
                this.$emit("drawing-mode-off");

                // Do the zoom thing
                let DC = this.$parent.$parent.$parent.$parent.$parent.$refs.tvjs;
                console.log('zoom',DC);
                DC.setRange(Math.min(this.p1[0], this.p2[0]), Math.max(this.p1[0], this.p2[0]));
                this.remove_tool();
                this.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.cancelDrawingAndReset();
            });
        },
        draw(ctx) {
            if (!this.p1 || !this.p2) return;

            let dir = Math.sign(this.p2[1] - this.p1[1]);

            ctx.lineWidth = this.line_width;
            ctx.strokeStyle = this.color;
            ctx.beginPath();

            // Background
            ctx.fillStyle = "rgba(101,162,206,0.3)";
            const layout = this.$props.layout;
            let x1 = layout.t2screen(this.p1[0]);
            let y1 = 0; //layout.$2screen(this.p1[1]);
            let x2 = layout.t2screen(this.p2[0]);
            let y2 = 100000; //layout.$2screen(this.p2[1]);
            ctx.fillRect(x1, y1, x2 - x1, y2 - y1);
        },
        use_for() {
            return ["ZoomTool"];
        },
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        p1() {
            return this.$props.settings.p1;
        },
        p2() {
            return this.$props.settings.p2;
        },
        new_font() {
            return "12px " + this.$props.font.split("px").pop();
        },
    },
    data() {
        return {};
    },
};
</script>
