<template>
  <div ref="draggableContainer" id="draggable-container" :style="{'visibility': getFavorites().length ? 'visible' : 'hidden','background-color':getBackgroundLayoutColor(),'fill':getFontLayoutColor(),'color':getFontLayoutColor()}">
    <div id="draggable-header" @mousedown="dragMouseDown" >
      <div id="panel-container" >
        <div>
          <i class="fa fa-bars" style="margin-top:0.35em;padding-right:0.35em"></i>
          <!--slot name="header"></slot-->
        </div>
        <div v-for="param in getFavorites()" :key="param.key" id="color-button-group">
          <div id="panel-button" class="no-drag" @mousedown="dragMouseDownNo" >
            <div :style="{'backgroundColor':param.iconColor || '#621199','height':'0.25em','margin-top':'0.1em','margin-left':'0.1em','width':'2em'}" ></div>
            <button class="button button1" :style="{'filter':(isDark()? 'brightness(1.4)':'brightness(.1)')}" @click="loadFavorite(param.name, param.subName, param.configData)">
              <!--i v-if="!param.iconData" class="fa fa-bookmark" style="font-size: 1.4em"></i-->
              <img  v-if="param.iconData" :src="param.iconData" :style="{'width': '1.85em'}"  ></img>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SaveDialog from '../components/SaveDialog.vue';
import {LayoutColors} from "../../utils/LayoutHandler";

export default {
  name: 'TopFavoritesPanel',
  components: { SaveDialog},
  props: ["chartHandler"],
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
      },
      favorites: this.chartHandler.favoritesHandler.favorites.favs,
    }
  },
  methods: {
    getFavorites() {
      return this.chartHandler.favoritesHandler.favorites.favs;
    },
    loadFavorite(name, subName, configData) {
      if (this.chartHandler.isPriceLineIndicator(name)) {
        this.$parent.on_indicatorselected({name : name,settings:configData});
      } else {
        this.$parent.$refs.leftbar.on_tool_select({
          toolName: name,
          settingName: subName,
          group: name.indexOf('Line') >= 0 ? 'Lines' : 'patterns',
          configData: configData
        }, null);
      }
    },
    isDark() {
      return this.chartHandler.layoutHandler.isDarkMode();
    },
    getBackgroundLayoutColor() {
      return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.backgroundMain);
    },
    getFontLayoutColor() {
      return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorMain);
    },
    dragMouseDownNo: function (event) {
      event.stopPropagation();
      console.log('dragMouseDownNo',event);
    },
    dragMouseDown: function (event) {
      console.log('dragMouseDown',event);
      event.preventDefault()
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      // set the element's new position:
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
    },

  },

  watch: {
    "isVisible"(newValue) {
/*
      console.log("isVisible",this.$props.indicator, newValue);

      if (newValue && !window.chartHandler.chartSettings.isPinModifyPanel && this.$props.indicator.indicatorInstance && this.$props.indicator.indicatorInstance.settings && this.$props.indicator.indicatorInstance.settings.p1) {
        const toolPos = window.chartHandler.getToolPos(this.$props.indicator.indicatorInstance.id);
        if ((toolPos.length>1) && (toolPos[0]) && (toolPos[1])) {
          this.setVisible(toolPos[0]+40,toolPos[1]+60);
        }
      } else {
        this.setVisible(this.chartHandler.chartSettings.pinModifyPanelPos[0],this.chartHandler.chartSettings.pinModifyPanelPos[1]);
      }*/
      //console.log("isVisible",this.$props.indicator.indicatorInstance,window.chartHandler.chartSettings.isPinModifyPanel, newValue);
    }
  },
  computed: {}
}
</script>

<style scoped>

.fa {
  font-size: large;
  padding-top: 0.1em;
}
.fcolorpicker-curbox {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

#draggable-container {
  display: block;
  position: absolute;
  z-index: 90;
  top: 20em;
  left: 30em;
}
#panel-container {
  display: flex;
  padding-left: 0.5em;
  padding-right: 0.2em;
  padding-top: 0.1em;
  padding-bottom: 0.2em;
  _color: #d9d9d9;
  _background-color: rgb(37, 41, 42);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  top:25%;
  left:45%;
  box-shadow: 0px 0px 4px #4b4b4b;
}
#draggable-header {
  cursor:grab;
  z-index: 91;
  padding-left: 0.2em;
  padding-right: 0.2em;
  padding-bottom: 0.2em;
  padding-top: 0.1em;
}

.no-drag {
  cursor:default;
  z-index: 92;
  font-size: small;
}

panel-button {
  z-index: 91;
  padding-right: 1em;
  padding-bottom: 0.1em;
}

#color-button-group {
  display: flex;
}

color-button {
  z-index: 91;
}

.button:hover {
  background-color: #88888885;
  border: 1px solid #44444466;
}

.button:focus {
  border: 0px solid #b9b9b9;
  outline:0;
}

button:focus {
  border: 1px solid #b9b9b900;
  outline:1px;
}

.vl {
  border-left: 1px solid rgba(185, 185, 185, 0.49);
  margin: 0.25em;
  margin-top: 0.2em;
  height: 2.0em;
}

.button {
  background-color: #00000000;
  border: 0.1px solid rgba(222, 222, 222, 0.29);
  color: #dedede;
  border-radius: 2px;
  box-shadow_: rgba(117, 119, 119, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember",sans-serif;
  font-size: 0.8em;
  line-height: 2em;
  padding: 2px 2px 2px 2px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  border-left_: 1px solid #b9b9b9;
  margin-top: 0.35em;
  width: 2.4em;
  height: 2.4em;
}

.itembutton {
  background-color: #00000000;
  border: 0.1px solid rgba(222, 222, 222, 0.0);
  color: #dedede;
  border-radius: 2px;
  box-shadow_: rgba(117, 119, 119, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember",sans-serif;
  font-size: 0.8em;
  line-height: 2em;
  padding: 1px 1px 1px 1px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  border-left_: 1px solid #b9b9b9;
  margin-top_: 0.35em;
  width: 2.4em;
  height: 2.4em;
}

.panel-parameter {
  margin-top: 0.3em;
  margin-left: 0.2em;
  margin-right: 0.2em;
  width: 3em;
  text-align: center;
}

.button1 {
  border-radius: 4px;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.selbutton {
  margin: 0.05em;
}

.colorbutton {
  background-color: #0dca62;

}
.colorbutton:hover {
  background-color: #0dca62;
  color: #dedede;
}

#linewidthpanel {
  width:10em;
  margin-left: 1em;
}

#checkedpanel {
  width:21em;
  margin-left: 1em;
}

.lineitem {
  padding-left: 0.5em;
  cursor: pointer;
  width:100%;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.lineitemlong {
  width:20em;
}

.lineitem:hover {
  background-color: #1F4661;
}

.lineitemunlink {
  padding-left: 0.5em;
  cursor: default;
  width:100%;
  margin: 0.2em;
  color: rgba(222,222,222,0.41);
}

saveaspanel {
  margin-right: 0.2em;

}

.modifypanel {
  margin-top: 0.5em;
  _background-color: rgb(37, 41, 42);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  _color: #dedede;
  padding: 0.5em;
}

.row {
  display: flex;
  width:18em;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 50%;/* Should be removed. Only for demonstration */
}
#fontSize {
  width:100%;
}
#colormap {
  display: flex;
  flex-wrap: wrap;
  width: 19em;
}

</style>

