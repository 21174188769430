<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";

export default {
    name: "TXT",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "akjcodes", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                group: "text",
                icon: Icons["text.png"],
                type: "Segment",
                name: "TXT",
                hint: "This hint will be shown on hover",
                data: [], // Default data
                settings: {
                    color: "#8091a0",
                    fontSize: 18,
                    text: "Text",
                }, // Default settings
                fieldsTemplate: [
                    ["color", "Text Color", "color"],
                    ["integer", "Font Size", "fontSize"],
                    ["text", "Text", "text"],
                ],
            };
        },
        // Called after overlay mounted
        init() {
          this.updatePins();
        },
        updatePins() {
          // loading from saved data
          const self = this;
          this.pins.splice(0,this.pins.length);
            if (this.$props.settings.p1) {
              this.$props.settings.$state = "finished";
                this.pins.push(
                    new Pin(this, "p1", {
                        state: "finished",
                        t: this.$props.settings.p1[0],
                        y$: this.$props.settings.p1[1],
                    })
                );
            } else {
              self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("TXT", this.id, this.$props.settings);
                // First pin is settled at the mouse position
                this.pins.push(new Pin(this, "p1"));

                //global drawing mode
                //window.$store.drawingMode = false;
              self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("TXT");
            }
        },
        draw(ctx) {
            if (!this.p1) return;

            const layout = this.$props.layout;
            let x1 = layout.t2screen(this.p1[0]) + 4;
            let y1 = layout.$2screen(this.p1[1]);

            ctx.font = `${this.$props.settings["fontSize"]}px ${this.new_font}`;
            let text = this.$props.settings["text"];
            /*var measure = ctx.measureText(text);
            let w = ctx.measureText(text).width;
            // var h = measure.width / text.length;
            // ctx.textAlign = 'center'
            ctx.fillText(text, x1, y1);


             */
            ctx.fillStyle = this.color;
            let size = this.printLine(ctx,text,x1,y1);
            this.collisions.push(this.make([x1, y1+size.h], size.w, size.h));

            this.render_pins(ctx);
        },
        printLine(ctx,txt,x,y) {
          var w = 1;
          var h = 0;
          var lines = txt.split('\n');

          for (var i = 0; i<lines.length; i++) {
            var measure = ctx.measureText(lines[i]||"M");
            var lineheight = measure.fontBoundingBoxAscent;
            h += lineheight+1;
            if (measure.width > w) {
              w = measure.width;
            }
            ctx.fillText(lines[i]||"", x, y + h);
          }
          return {w:w, h:h+8};
        },
        // Collision function. x, y - mouse coord.
        make(p1, w, h) {
            return (x, y) => {
                return x > p1[0] && x < p1[0] + w && y > p1[1] - h && y < p1[1];
            };
        },
        use_for() {
            return ["TXT"];
        },
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        p1() {
            return this.$props.settings.p1;
        },
        color() {
            return this.sett.color || "#8091a0";
        },
        new_font() {
            return this.$props.font.split("px").pop();
        },
    },
    data() {
        return {};
    },
};
</script>
