<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";;
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";
//import akjStuff from "@/helpers/akjStuff.js";

export default {
    name: "AUTOFIB",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "C451", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                group: "AUTO FIB",
                icon: Icons["fib.png"],
                type: "Price",
                name: "AUTO FIB",
                hint: "Price Range",
                data: [], // Default data
                settings: {
                    levels: [0, 23.6, 38.2, 50, 61.8, 78.6, 100, 138.2, 161.8, 200, 261.8, -138.2, -161.8, -200, -261.8],
                    showPrice: true,
                    showPercentage: true,
                    showBackground: false,
                    backColor: "#787b86",
                    backOpacity: 10,
                    color: "#b2b5be",
                    lineWidth: 1,
                    showLevel0: true,
                    showLevel1: false,
                    showLevel2: true,
                    showLevel3: true,
                    showLevel4: true,
                    showLevel5: true,
                    showLevel6: true,
                    showLevel7: false,
                    showLevel8: true,
                    showLevel9: true,
                    showLevel10: false,
                    showLevel11: false,
                    showLevel12: true,
                    showLevel13: false,
                    showLevel14: false,
                    showLevel15: false,
                    isAutoFibs: true,
                }, // Default settings
                fieldsTemplate: [
                    ["bool", "Show Price", "showPrice"],
                    ["bool", "Show Percentage", "showPercentage"],
                    ["sep", "sep", "sep"],

                    /*["bool", "Show Background", "showBackground", false],
                    ["color", "Back Color", "backColor"],
                    ["integer", "Background Opacity", "backOpacity"],
                    ["sep", "sep", "sep"],
                    ["color", "Line Color", "color"],
                    ["lineWidth", "Line Thickness", "lineWidth"],
                    ["sep", "sep", "sep"],*/
                    ["bool", "Show Level 0%", "showLevel0"],
                    ["bool", "Show Level 23.6%", "showLevel1"],
                    ["bool", "Show Level 38.2%", "showLevel2"],
                    ["bool", "Show Level 50%", "showLevel3"],
                    ["bool", "Show Level 61.8%", "showLevel4"],
                    ["bool", "Show Level 78.6%", "showLevel5"],
                    ["bool", "Show Level 100%", "showLevel6"],
                    ["bool", "Show Level 138.2%", "showLevel7"],
                    ["bool", "Show Level 161.8%", "showLevel8"],
                    ["bool", "Show Level 200%", "showLevel9"],
                    ["bool", "Show Level 261.8%", "showLevel10"],
                    ["bool", "Show Level -138.2%", "showLevel11"],
                    ["bool", "Show Level -161.8%", "showLevel12"],
                    ["bool", "Show Level -200%", "showLevel13"],
                    ["bool", "Show Level -261.8%", "showLevel14"],
                ],
            };
        },
        // Called after overlay mounted
        init() {
            if (this.p1) {
                this.pins.push(
                    new Pin(this, "p1", {
                        state: "finished",
                        t: this.p1[0],
                        y$: this.p1[1],
                    })
                );
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "finished",
                        t: this.p2[0],
                        y$: this.p2[1],
                    })
                );
            } else {
                // First pin is settled at the mouse position
                this.pins.push(new Pin(this, "p1"));
                // Second one is following mouse until it clicks
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "tracking",
                    })
                );
                this.pins[1].on("settled", () => {
                    // Call when current tool drawing is finished
                    // (Optionally) reset the mode back to 'Cursor'
                    this.set_state("finished");
                    this.$emit("drawing-mode-off");

                    //global drawing mode
                    //window.$store.drawingMode = false;
                });
            }
        },
        draw(ctx) {
            if (!this.$parent.$parent.$parent.$parent.$parent.chartHandler.chartSettings.isAutoFibs) return;

            if (!this.p1 || !this.p2) return;

            const layout = this.$props.layout;
            this.x2 = layout.t2screen(this.p2[0]);
            this.x1 = Math.min(this.x2+100,layout.t2screen(this.p1[0]));

            ctx.lineWidth = this.line_width;
            //  ctx.strokeStyle = this.color;

            for (let i = 0; i < this.levels.length; i++) {
                // show / hide this level
                if (!this.$props.settings[`showLevel${i}`]) continue;

                // line styles
              if (window.chartHandler.chartSettings.theme.indexOf("dark") >= 0) {
                // for dark background
                    // default
                    ctx.strokeStyle = "rgba(180,180,180,0.53)";
                    ctx.setLineDash([]);
                    if (this.levels[i] == 38.2) {
                        ctx.strokeStyle = "rgba(180,180,180,0.65)";
                        ctx.setLineDash([6, 4]);
                    } else if (this.levels[i] == 50) {
                        ctx.strokeStyle = "rgba(88,88,88,0.87)";
                        ctx.setLineDash([6, 8]);
                    } else if (this.levels[i] == 61.8) {
                        ctx.strokeStyle = "rgba(255,255,255,0.34)";
                        ctx.setLineDash([]);
                    } else if (this.levels[i] == 78.6) {
                        ctx.strokeStyle = "rgba(143,143,143,0.72)";
                        ctx.setLineDash([6, 2]);
                    }
                } else {
                    // for white background
                    // default
                    ctx.strokeStyle = "rgba(161,161,161,0.68)";
                    ctx.setLineDash([]);
                    if (this.levels[i] == 38.2) {
                        ctx.strokeStyle = "rgba(157,157,157,0.71)";
                        ctx.setLineDash([6, 4]);
                    } else if (this.levels[i] == 50) {
                        ctx.strokeStyle = "rgba(88,88,88,0.85)";
                        ctx.setLineDash([6, 8]);
                    } else if (this.levels[i] == 61.8) {
                        ctx.strokeStyle = "rgba(58,58,58,0.7)";
                        ctx.setLineDash([]);
                    } else if (this.levels[i] == 78.6) {
                        ctx.strokeStyle = "rgba(143,143,143,0.79)";
                        ctx.setLineDash([6, 2]);
                    }
                }

                ctx.beginPath();
                let nY = this.p1[1] + ((this.p2[1] - this.p1[1]) * this.levels[i]) / 100;

                //new Seg(this, ctx, false).draw([Math.min(this.p1[0], this.p2[0]), nY], [Math.max(this.p1[0], this.p2[0]), nY]);
                ctx.moveTo(this.x1, layout.$2screen(nY));
                ctx.lineTo(this.x2, layout.$2screen(nY));

                let showPercentage = false;
                let showPrice = false;

                if (this.levels[i] == 0 || this.levels[i] == 100) {
                    showPercentage = this.$props.settings['showPercentage'];
                    showPrice = this.$props.settings['showPrice'];
                }
                this.draw_level(ctx, this.levels[i], this.x1, this.x2, layout.$2screen(nY), showPrice, showPercentage);
                ctx.stroke();

            }
        },

        draw_level(ctx, p, x1, x2, y, showPrice, showPercentage) {
            let price = "",
                percentage = "";
            ctx.font = this.new_font;
            ctx.fillStyle = this.value_color;
            ctx.fillStyle = "rgba(180,180,180,0.95)";
            ctx.textAlign = "center";

            if (showPrice) {
              price = `${this.layout.screen2$(y).toFixed(window.chartHandler.displayData.prec)}`;
              if (showPercentage) {
                price = '('+price+')';
              }
            }
            if (showPercentage) percentage = `${p.toFixed(window.chartHandler.displayData.prec)}%`;

            let text = `${percentage} ${price} `;
            let w = ctx.measureText(text).width;
            ctx.fillText(text, x2 - w * 0.5, y - 4);
        },

        use_for() {
            return ["AUTOFIB"];
        },
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        levels() {
            return this.$props.settings.levels || [0, 23.6, 38.2, 50, 61.8, 78.6, 100, 138.2, 161.8, 200, 261.8, -138.2, -161.8, -200, -261.8];
        },
        p1() {
          if (this.setts.fibo[this.$props.settings.fibotype]) {
            //console.log("fibData.forEach",this.$props.settings,this.setts.fibo[this.$props.settings.fibotype])
            if (('fiboId' in this.$props.settings) && (this.$props.settings.fiboId in this.setts.fibo[this.$props.settings.fibotype])) {

              return this.setts.fibo[this.$props.settings.fibotype][this.$props.settings.fiboId].p1;
            }
            return this.setts.fibo[this.$props.settings.fibotype].p1;
            //console.log(this.$parent.$parent.$parent.$parent.$parent.chartHandler.charts["0"].settings.fibo[this.$props.settings.fibotype].p1);
          }
          //console.log(this.$props.settings.fibotype);
          return null;
        },
        p2() {
          if (this.setts.fibo[this.$props.settings.fibotype]) {
            if (('fiboId' in this.$props.settings) && (this.$props.settings.fiboId in this.setts.fibo[this.$props.settings.fibotype])) {
              return this.setts.fibo[this.$props.settings.fibotype][this.$props.settings.fiboId].p2;
            }
            return this.setts.fibo[this.$props.settings.fibotype].p2;
            //console.log(this.$parent.$parent.$parent.$parent.$parent.chartHandler.charts["0"].settings.fibo[this.$props.settings.fibotype].p1);
          }
          //console.log(this.$props.settings.fibotype);
          return null;
        },
        line_width() {
            return this.sett.lineWidth || 2;
        },
        color() {
            return this.sett.color || this.$props.colors.colorCross;
        },
        show_background() {
            return this.sett.showBackground == undefined ? true : this.sett.showBackground;
        },
        back_color() {
            return this.sett.backColor ? this.sett.backColor : "rgba(155,155,163,0.68)";
        },
        back_opacity() {
            return this.sett.backOpacity || 20;
        },
        value_color() {
            return 'rgba(180,180,180,0.49)' ; //this.sett.valueColor || this.$props.colors.colorText;
        },
        new_font() {
            return "1spx " + this.$props.font.split("px").pop();
        },
    },
    data() {
        return {
          setts: this.$parent.$parent.$parent.$parent.$parent.chartHandler.charts[''+this.$parent.$parent.$parent.$parent.$props.id].settings,
        }
    },
};
</script>
