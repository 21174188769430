<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";

export default {
    name: "RangeTool",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "C451", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                name: "RangeTool",
                group: "Measurements",
                icon: Icons["price_range.png"],
                type: "Price",
                hint: "Price Range",
                data: [], // Default data
                settings: {
                    color: "#788998",
                    lineWidth: 1,
                    backColor: "rgba(95,98,101,0.28)",
                    valueColor: "rgb(233,237,239)",
                    valueBack: "rgba(139,177,225,0.28)",
                }, // Default settings
            };
        },
        // Called after overlay mounted
        init() {
          this.averages = {
            allMaxUp: {abs:0, prc:0},
            allMaxDown: {abs:0, prc:0},
            all : {
              noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
            },
            p10: {
              noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
            },
            p20: {
              noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
            },
            p10Big: {
              noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
            },
            p20Big: {
              noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
            },
            p10Low: {
              noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
            },
            p20Low: {
              noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
              down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
            }
          };
          this.updatePins();
        },
      updatePins() {
        const self = this;
        //console.log('add_ind_updatePins');if (this.$props.settings.p1) {
        this.pins.splice(0,this.pins.length);
          if (this.$props.settings.p1) {
                this.pins.push(
                    new Pin(this, "p1", {
                        state: "finished",
                        t: this.$props.settings.p1[0],
                        y$: this.$props.settings.p1[1],
                    })
                );
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "finished",
                        t: this.$props.settings.p2[0],
                        y$: this.$props.settings.p2[1],
                    })
                );
                this.pins[0].on("settled", () => {
                  this.calcAdditionals();
                });
                this.pins[1].on("settled", () => {
                  this.calcAdditionals();
                });
                this.calcAdditionals();
            } else {
            self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("RangeTool", this.id, this.$props.settings);
                // First pin is settled at the mouse position
                this.pins.push(new Pin(this, "p1"));
                // Second one is following mouse until it clicks
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "tracking",
                    })
                );
                this.pins[0].on("settled", () => {
                  this.calcAdditionals();
                });
                this.pins[1].on("settled", () => {
                    // Call when current tool drawing is finished
                    // (Optionally) reset the mode back to 'Cursor'
                    this.set_state("finished");
                    this.calcAdditionals();
                    this.$emit("drawing-mode-off");
                    self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("RangeTool");
                });
            }
        },
        draw(ctx) {
            if (!this.p1 || !this.p2) return;

            let dir = Math.sign(this.p2[1] - this.p1[1]);

            if (this.show_pins)
              ctx.lineWidth = this.line_width+3;
            else
              ctx.lineWidth = this.line_width;
            ctx.strokeStyle = this.color;
            ctx.beginPath();

            // Background
            ctx.fillStyle = this.back_color;
            const layout = this.$props.layout;
            let x1 = layout.t2screen(this.p1[0]);
            let y1 = layout.$2screen(this.p1[1]);
            let x2 = layout.t2screen(this.p2[0]);
            let y2 = layout.$2screen(this.p2[1]);
            ctx.fillRect(x1, y1, x2 - x1, y2 - y1);

            // Top
            new Seg(this, ctx).draw([this.p1[0], this.p2[1]], [this.p2[0], this.p2[1]]);
            // Bottom
            new Seg(this, ctx).draw([this.p1[0], this.p1[1]], [this.p2[0], this.p1[1]]);

            // Arrow
            let xm = layout.t2screen((this.p1[0] + this.p2[0]) * 0.5);
            ctx.moveTo(xm - 4, y2 + 5 * dir);
            ctx.lineTo(xm, y2);
            ctx.lineTo(xm + 4, y2 + 5 * dir);

            ctx.stroke();

            // Vertical
            ctx.beginPath();
            ctx.setLineDash([5, 5]);
            new Seg(this, ctx).draw([(this.p1[0] + this.p2[0]) * 0.5, this.p2[1]], [(this.p1[0] + this.p2[0]) * 0.5, this.p1[1]]);
            ctx.stroke();
            ctx.setLineDash([]);

            this.draw_value(ctx, dir, xm, y2);

            this.render_pins(ctx);

            if (this.p1 && this.p2 && (this.show_pins) && window.chartHandler.checkHintTimer(this.mouse.x,this.mouse.y,this.id)) {
                const digist = 3;
                const digistProz = 2;
                const width = 600;
                const height = 265;
                const lineHeight = 20;
                const lineStart = 65;

                ctx.font = this.new_fontDisplay;
                const layout = this.$props.layout;
                let x1 = Math.min(this.mouse.x, layout.width-width);// layout.t2screen(this.p1[0]);
                let y1 = Math.min(this.mouse.y, layout.height-height); //layout.$2screen(this.p1[1]);
                const row1 = x1 + width-280;
                const row2 = x1 + width-145;
                const row3 = x1 + width-10;
                const font = ctx.font;

                ctx.fillStyle = '#98acc4';
                ctx.fillRect(x1,y1+40,width,height-40)
                ctx.fillStyle = '#082e4b';
                ctx.fillRect(x1,y1,width,43);
                ctx.strokeStyle = '#082e4b60';
                ctx.moveTo(row1-125,y1 + lineStart + (lineHeight*0) +4);
                ctx.lineTo(row3,y1 + lineStart + (lineHeight*0) +4);
                ctx.stroke();
                ctx.strokeStyle = 'rgb(94,94,94)';
                ctx.textAlign = "center";
                ctx.beginPath();
                ctx.rect(x1,y1,width,height);
                ctx.stroke();
                ctx.fillStyle = '#d0d0d0';
                ctx.font='bold 14px '+font.slice(4);
                ctx.fillText("statistical evaluation", x1 + width/2, y1+28);
                ctx.textAlign = "right";
                ctx.font='bold '+font;
                ctx.fillText(this.chgBars, x1 + width-10, y1+17);
                ctx.fillText(this.chgText, x1 + width-10, y1+35);
                ctx.textAlign = "left";
                ctx.fillStyle = '#021632';
                ctx.fillText("Greatest candle: ", x1 + 10, y1 + lineStart + (lineHeight*1));
                ctx.fillText("Range ø (all candles): ", x1 + 10, y1 + lineStart + (lineHeight*2.5));
                ctx.fillText("Range ø (candles < best 10 %): ", x1 + 10, y1 + lineStart + (lineHeight*3.5));
                ctx.fillText("Range ø (candles < best 20 %): ", x1 + 10, y1 + lineStart + (lineHeight*4.5));
                ctx.fillText("Range ø (candles >= best 10 %): ", x1 + 10, y1 + lineStart + (lineHeight*6));
                ctx.fillText("Range ø (candles >= best 20 %): ", x1 + 10, y1 + lineStart + (lineHeight*7));
                ctx.fillText("Range ø (candles < worst 10 %): ", x1 + 10, y1 + lineStart + (lineHeight*8.5));
                ctx.fillText("Range ø (candles < worst 20 %): ", x1 + 10, y1 + lineStart + (lineHeight*9.5));
                ctx.fillStyle = 'rgba(27,31,44,0.91)';
                ctx.textAlign = "center";
                ctx.fillText("all", row1-40, y1 + lineStart + (lineHeight*0) -3);
                ctx.textAlign = "right";
                ctx.font=font;
                ctx.fillText(this.averages.all.noDir.abs.toFixed(digist)+' ('+this.averages.all.noDir.prc.toFixed(digistProz)+' %)', row1, y1 + lineStart + (lineHeight*2.5));
                ctx.fillText(this.averages.p10.noDir.abs.toFixed(digist)+' ('+this.averages.p10.noDir.prc.toFixed(digistProz)+' %)', row1, y1 + lineStart + (lineHeight*3.5));
                ctx.fillText(this.averages.p20.noDir.abs.toFixed(digist)+' ('+this.averages.p20.noDir.prc.toFixed(digistProz)+' %)', row1, y1 + lineStart + (lineHeight*4.5));
                ctx.fillText(this.averages.p10Big.noDir.abs.toFixed(digist)+' ('+this.averages.p10Big.noDir.prc.toFixed(digistProz)+' %)', row1, y1 + lineStart + (lineHeight*6));
                ctx.fillText(this.averages.p20Big.noDir.abs.toFixed(digist)+' ('+this.averages.p20Big.noDir.prc.toFixed(digistProz)+' %)', row1, y1 + lineStart + (lineHeight*7));
                ctx.fillText(this.averages.p10Low.noDir.abs.toFixed(digist)+' ('+this.averages.p10Low.noDir.prc.toFixed(digistProz)+' %)', row1, y1 + lineStart + (lineHeight*8.5));
                ctx.fillText(this.averages.p20Low.noDir.abs.toFixed(digist)+' ('+this.averages.p20Low.noDir.prc.toFixed(digistProz)+' %)', row1, y1 + lineStart + (lineHeight*9.5));
                ctx.fillStyle = '#8d0000';
                ctx.textAlign = "center";
                ctx.font='12px FontAwesome';
                ctx.fillText('\uf063', row2-40, y1 + lineStart + (lineHeight*0) - 3);
                ctx.textAlign = "right";
                ctx.font=font;
                ctx.fillText(this.averages.allMaxDown.abs.toFixed(digist)+' ('+this.averages.allMaxDown.prc.toFixed(digistProz)+' %)', row2, y1 + lineStart + (lineHeight*1));
                ctx.fillText(this.averages.all.down.abs.toFixed(digist)+' ('+this.averages.all.down.prc.toFixed(digistProz)+' %)', row2, y1 + lineStart + (lineHeight*2.5));
                ctx.fillText(this.averages.p10.down.abs.toFixed(digist)+' ('+this.averages.p10.down.prc.toFixed(digistProz)+' %)', row2, y1 + lineStart + (lineHeight*3.5));
                ctx.fillText(this.averages.p20.down.abs.toFixed(digist)+' ('+this.averages.p20.down.prc.toFixed(digistProz)+' %)', row2, y1 + lineStart + (lineHeight*4.5));
                ctx.fillText(this.averages.p10Big.down.abs.toFixed(digist)+' ('+this.averages.p10Big.down.prc.toFixed(digistProz)+' %)', row2, y1 + lineStart + (lineHeight*6));
                ctx.fillText(this.averages.p20Big.down.abs.toFixed(digist)+' ('+this.averages.p20Big.down.prc.toFixed(digistProz)+' %)', row2, y1 + lineStart + (lineHeight*7));
                ctx.fillText(this.averages.p10Low.down.abs.toFixed(digist)+' ('+this.averages.p10Low.down.prc.toFixed(digistProz)+' %)', row2, y1 + lineStart + (lineHeight*8.5));
                ctx.fillText(this.averages.p20Low.down.abs.toFixed(digist)+' ('+this.averages.p20Low.down.prc.toFixed(digistProz)+' %)', row2, y1 + lineStart + (lineHeight*9.5));
                ctx.textAlign = "center";
                ctx.fillStyle = '#0c5600';
                ctx.font='12px FontAwesome';
                ctx.fillText('\uf062', row3-40, y1 + lineStart + (lineHeight*0) - 3);
                ctx.font=font;
                ctx.textAlign = "right";
                ctx.fillText(this.averages.allMaxUp.abs.toFixed(digist)+' ('+this.averages.allMaxUp.prc.toFixed(digistProz)+' %)', row3, y1 + lineStart + (lineHeight*1));
                ctx.fillText(this.averages.all.up.abs.toFixed(digist)+' ('+this.averages.all.up.prc.toFixed(digistProz)+' %)', row3, y1 + lineStart + (lineHeight*2.5));
                ctx.fillText(this.averages.p10.up.abs.toFixed(digist)+' ('+this.averages.p10.up.prc.toFixed(digistProz)+' %)', row3, y1 + lineStart + (lineHeight*3.5));
                ctx.fillText(this.averages.p20.up.abs.toFixed(digist)+' ('+this.averages.p20.up.prc.toFixed(digistProz)+' %)', row3, y1 + lineStart + (lineHeight*4.5));
                ctx.fillText(this.averages.p10Big.up.abs.toFixed(digist)+' ('+this.averages.p10Big.up.prc.toFixed(digistProz)+' %)', row3, y1 + lineStart + (lineHeight*6));
                ctx.fillText(this.averages.p20Big.up.abs.toFixed(digist)+' ('+this.averages.p20Big.up.prc.toFixed(digistProz)+' %)', row3, y1 + lineStart + (lineHeight*7));
                ctx.fillText(this.averages.p10Low.up.abs.toFixed(digist)+' ('+this.averages.p10Low.up.prc.toFixed(digistProz)+' %)', row3, y1 + lineStart + (lineHeight*8.5));
                ctx.fillText(this.averages.p20Low.up.abs.toFixed(digist)+' ('+this.averages.p20Low.up.prc.toFixed(digistProz)+' %)', row3, y1 + lineStart + (lineHeight*9.5));
                /*
                              Aufwärts-Kerze mit der größten Range in toto und in %
                              Abwärts-Kerze mit der größten Range in toto und in %

                              Durchschnittliche Range der Kerzen für die gesamte Range in toto und in %

                              Durchschnittliche Range der Kerzen in toto und in % ohne Einziehung der Kerzen mit der größten und der niedrigsten 10%-Range
                              Durchschnittliche Range der Kerzen in toto und in % ohne Einziehung der Kerzen mit der größten und der niedrigsten 20%-Range

                              Durchschnittliche Range der aufwärtsgerichteten Kerzen in toto und in % ohne Einziehung der aufwärtsgerichteten Kerzen mit der größten 10%-Range
                              Durchschnittliche Range der aufwärtsgerichteten Kerzen in toto und in % ohne Einziehung der aufwärtsgerichteten Kerzen mit der größten 20%-Range

                              Durchschnittliche Range der abwärtsgerichteten Kerzen in toto und in % ohne Einziehung der abwärtsgerichteten Kerzen mit der größten 10%-Range
                              Durchschnittliche Range der abwärtsgerichteten Kerzen in toto und in % ohne Einziehung der abwärtsgerichteten Kerzen mit der größten 20%-Range


                              Durchschnittliche Range der größten Kerzen in der obersten 10% Percentile in toto und in % (Richtung unabhängig)
                              Durchschnittliche Range der größten, aufwärtsgerichteten Kerzen in der obersten 10% Percentile in toto und in %
                              Durchschnittliche Range der größten, abwärtsgerichteten Kerzen in der obersten 10% Percentile in toto und in %

                              Durchschnittliche Range der größten Kerzen in der obersten 20% Percentile in toto und in % (Richtung unabhängig)
                              Durchschnittliche Range der größten, aufwärtsgerichteten Kerzen in der obersten 20% Percentile in toto und in %
                              Durchschnittliche Range der größten, abwärtsgerichteten Kerzen in der obersten 20% Percentile in toto und in %

                              Durchschnittliche Range der kleinsten Kerzen in der untersten 10% Percentile in toto und in % (Richtung unabhängig)
                              Durchschnittliche Range der kleinsten, aufwärtsgerichteten Kerzen in der untersten 10% Percentile in toto und in %
                              Durchschnittliche Range der kleinsten, abwärtsgerichteten Kerzen in der untersten 10% Percentile in toto und in %

                              Durchschnittliche Range der kleinsten Kerzen in der untersten 20% Percentile in toto und in % (Richtung unabhängig)
                              Durchschnittliche Range der kleinsten, aufwärtsgerichteten Kerzen in der untersten 20% Percentile in toto und in %
                              Durchschnittliche Range der kleinsten, abwärtsgerichteten Kerzen in der untersten 20% Percentile in toto und in %*/
            }
        },
        draw_value(ctx, dir, xm, y) {
            ctx.font = this.new_font;
            let d$ = (this.p2[1] - this.p1[1]).toFixed(this.prec);
            let p = (100 * (this.p2[1] / this.p1[1] - 1)).toFixed(this.prec);
            let b = this.p2[0] - this.p1[0];
            this.chgText = `${d$}  (${p}%)`;
            this.chgBars = `${b} bars`;
            const text = this.chgText + ', ' + this.chgBars;
            let w = Math.max(ctx.measureText(text).width + 20, 100);
            ctx.fillStyle = this.value_back;
            ctx.fillRect(xm - w * 0.5, y - 30 * dir, w, 20 * dir);
            //ctx.fillStyle = this.value_color;
            ctx.fillStyle = this.value_color;
            ctx.textAlign = "center";
            ctx.fillText(text, xm, y + (dir > 0 ? -15 : 25));
        },
        use_for() {
            return ["RangeTool"];
        },
        data_colors() {
            return [this.color];
        },calcAdditionals() {
        const first = this.p1[0] < this.p2[0] ? this.p1[0] : this.p2[0];
        let last  = this.p1[0] > this.p2[0] ? this.p1[0] : this.p2[0];

        const candles = window.chartHandler.chartObjects["0"].data.data.chart.data;

        ///
        this.highestCandleUpDown = [0,0,0,0];
        this.averages = {
          allMaxUp: {abs:0, prc:0},
          allMaxDown: {abs:0, prc:0},
          all : {
            noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
          },
          p10: {
            noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
          },
          p20: {
            noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
          },
          p10Big: {
            noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
          },
          p20Big: {
            noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
          },
          p10Low: {
            noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
          },
          p20Low: {
            noDir : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            up    : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0},
            down  : {cnt:0, sumAbs: 0, sumPrc : 0, abs: 0, prc : 0}
          }
        };

        const selCandles = candles.slice(first, last);

        selCandles.sort((c1,c2) => {
          return Math.abs(c1[4]-c1[1]) - Math.abs(c2[4]-c2[1]);
        });

        last = selCandles.length-1;
        let i = -1;
        while (++i<=last) {
          const candleSize = selCandles[i][4] - selCandles[i][1];
          const candlePrc  = Math.abs(candleSize) * 100 / selCandles[i][1];
          if (candleSize > 0) {
            if (Math.abs(candlePrc) > this.averages.allMaxUp.prc) {
              this.averages.allMaxUp.abs = Math.abs(candleSize);
              this.averages.allMaxUp.prc = candlePrc;
            }
          } else {
            if (Math.abs(candlePrc) > this.averages.allMaxDown.prc) {
              this.averages.allMaxDown.abs = Math.abs(candleSize);
              this.averages.allMaxDown.prc = candlePrc;
            }
          }
        }

        const highestValue = this.averages.allMaxUp.prc > this.averages.allMaxDown.prc ? this.averages.allMaxUp.prc : this.averages.allMaxDown.prc;
        i = -1;
        while (++i<=last) {
          const candleSize = selCandles[i][4] - selCandles[i][1];
          const candlePrc  = candleSize * 100 / selCandles[i][1];

          //console.log(candleSize, candlePrc, highestValue, highestValue * 0.9);
          const is20 = i > last * 0.8;
          const is10 = i > last * 0.9;
          const is20Low = i < last * 0.2;
          const is10Low = i < last * 0.1;

          this.averages.all.noDir.cnt++;
          this.averages.all.noDir.sumAbs += Math.abs(candleSize);
          this.averages.all.noDir.sumPrc += Math.abs(candlePrc);

          if (candleSize > 0) {
            this.averages.all.up.cnt++;
            this.averages.all.up.sumAbs += Math.abs(candleSize);
            this.averages.all.up.sumPrc += Math.abs(candlePrc);
          } else {
            this.averages.all.down.cnt++;
            this.averages.all.down.sumAbs += Math.abs(candleSize);
            this.averages.all.down.sumPrc += Math.abs(candlePrc);
          }

          if (!is10) {
            this.averages.p10.noDir.cnt++;
            this.averages.p10.noDir.sumAbs += Math.abs(candleSize);
            this.averages.p10.noDir.sumPrc += Math.abs(candlePrc);

            if (candleSize > 0) {
              this.averages.p10.up.cnt++;
              this.averages.p10.up.sumAbs += Math.abs(candleSize);
              this.averages.p10.up.sumPrc += Math.abs(candlePrc);
            } else {
              this.averages.p10.down.cnt++;
              this.averages.p10.down.sumAbs += Math.abs(candleSize);
              this.averages.p10.down.sumPrc += Math.abs(candlePrc);
            }
          } else {
            this.averages.p10Big.noDir.cnt++;
            this.averages.p10Big.noDir.sumAbs += Math.abs(candleSize);
            this.averages.p10Big.noDir.sumPrc += Math.abs(candlePrc);

            if (candleSize > 0) {
              this.averages.p10Big.up.cnt++;
              this.averages.p10Big.up.sumAbs += Math.abs(candleSize);
              this.averages.p10Big.up.sumPrc += Math.abs(candlePrc);
            } else {
              this.averages.p10Big.down.cnt++;
              this.averages.p10Big.down.sumAbs += Math.abs(candleSize);
              this.averages.p10Big.down.sumPrc += Math.abs(candlePrc);
            }
          }
          if (!is20) {
            this.averages.p20.noDir.cnt++;
            this.averages.p20.noDir.sumAbs += Math.abs(candleSize);
            this.averages.p20.noDir.sumPrc += Math.abs(candlePrc);

            if (candleSize > 0) {
              this.averages.p20.up.cnt++;
              this.averages.p20.up.sumAbs += Math.abs(candleSize);
              this.averages.p20.up.sumPrc += Math.abs(candlePrc);
            } else {
              this.averages.p20.down.cnt++;
              this.averages.p20.down.sumAbs += Math.abs(candleSize);
              this.averages.p20.down.sumPrc += Math.abs(candlePrc);
            }
          } else {
            this.averages.p20Big.noDir.cnt++;
            this.averages.p20Big.noDir.sumAbs += Math.abs(candleSize);
            this.averages.p20Big.noDir.sumPrc += Math.abs(candlePrc);

            if (candleSize > 0) {
              this.averages.p20Big.up.cnt++;
              this.averages.p20Big.up.sumAbs += Math.abs(candleSize);
              this.averages.p20Big.up.sumPrc += Math.abs(candlePrc);
            } else {
              this.averages.p20Big.down.cnt++;
              this.averages.p20Big.down.sumAbs += Math.abs(candleSize);
              this.averages.p20Big.down.sumPrc += Math.abs(candlePrc);
            }
          }

          if (is20Low) {

            this.averages.p20Low.noDir.cnt++;
            this.averages.p20Low.noDir.sumAbs += Math.abs(candleSize);
            this.averages.p20Low.noDir.sumPrc += Math.abs(candlePrc);

            if (candleSize > 0) {
              this.averages.p20Low.up.cnt++;
              this.averages.p20Low.up.sumAbs += Math.abs(candleSize);
              this.averages.p20Low.up.sumPrc += Math.abs(candlePrc);
            } else {
              this.averages.p20Low.down.cnt++;
              this.averages.p20Low.down.sumAbs += Math.abs(candleSize);
              this.averages.p20Low.down.sumPrc += Math.abs(candlePrc);
            }

            if (is10Low) {
              this.averages.p10Low.noDir.cnt++;
              this.averages.p10Low.noDir.sumAbs += Math.abs(candleSize);
              this.averages.p10Low.noDir.sumPrc += Math.abs(candlePrc);

              if (candleSize > 0) {
                this.averages.p10Low.up.cnt++;
                this.averages.p10Low.up.sumAbs += Math.abs(candleSize);
                this.averages.p10Low.up.sumPrc += Math.abs(candlePrc);
              } else {
                this.averages.p10Low.down.cnt++;
                this.averages.p10Low.down.sumAbs += Math.abs(candleSize);
                this.averages.p10Low.down.sumPrc += Math.abs(candlePrc);
              }
            }
          }
        }

        if (this.averages.all.noDir.cnt > 0) {
          this.averages.all.noDir.abs = this.averages.all.noDir.sumAbs / this.averages.all.noDir.cnt;
          this.averages.all.noDir.prc = this.averages.all.noDir.sumPrc / this.averages.all.noDir.cnt;
        }
        if (this.averages.all.up.cnt > 0) {
          this.averages.all.up.abs = this.averages.all.up.sumAbs / this.averages.all.up.cnt;
          this.averages.all.up.prc = this.averages.all.up.sumPrc / this.averages.all.up.cnt;
        }
        if (this.averages.all.down.cnt > 0) {
          this.averages.all.down.abs = this.averages.all.down.sumAbs / this.averages.all.down.cnt;
          this.averages.all.down.prc = this.averages.all.down.sumPrc / this.averages.all.down.cnt;
        }

        if (this.averages.p10.noDir.cnt > 0) {
          this.averages.p10.noDir.abs = this.averages.p10.noDir.sumAbs / this.averages.p10.noDir.cnt;
          this.averages.p10.noDir.prc = this.averages.p10.noDir.sumPrc / this.averages.p10.noDir.cnt;
        }
        if (this.averages.p10.up.cnt > 0) {
          this.averages.p10.up.abs = this.averages.p10.up.sumAbs / this.averages.p10.up.cnt;
          this.averages.p10.up.prc = this.averages.p10.up.sumPrc / this.averages.p10.up.cnt;
        }
        if (this.averages.p10.down.cnt > 0) {
          this.averages.p10.down.abs = this.averages.p10.down.sumAbs / this.averages.p10.down.cnt;
          this.averages.p10.down.prc = this.averages.p10.down.sumPrc / this.averages.p10.down.cnt;
        }


        if (this.averages.p20.noDir.cnt > 0) {
          this.averages.p20.noDir.abs = this.averages.p20.noDir.sumAbs / this.averages.p20.noDir.cnt;
          this.averages.p20.noDir.prc = this.averages.p20.noDir.sumPrc / this.averages.p20.noDir.cnt;
        }
        if (this.averages.p20.up.cnt > 0) {
          this.averages.p20.up.abs = this.averages.p20.up.sumAbs / this.averages.p20.up.cnt;
          this.averages.p20.up.prc = this.averages.p20.up.sumPrc / this.averages.p20.up.cnt;
        }
        if (this.averages.p20.down.cnt > 0) {
          this.averages.p20.down.abs = this.averages.p20.down.sumAbs / this.averages.p20.down.cnt;
          this.averages.p20.down.prc = this.averages.p20.down.sumPrc / this.averages.p20.down.cnt;
        }

        if (this.averages.p10Big.noDir.cnt > 0) {
          this.averages.p10Big.noDir.abs = this.averages.p10Big.noDir.sumAbs / this.averages.p10Big.noDir.cnt;
          this.averages.p10Big.noDir.prc = this.averages.p10Big.noDir.sumPrc / this.averages.p10Big.noDir.cnt;
        }
        if (this.averages.p10Big.up.cnt > 0) {
          this.averages.p10Big.up.abs = this.averages.p10Big.up.sumAbs / this.averages.p10Big.up.cnt;
          this.averages.p10Big.up.prc = this.averages.p10Big.up.sumPrc / this.averages.p10Big.up.cnt;
        }
        if (this.averages.p10Big.down.cnt > 0) {
          this.averages.p10Big.down.abs = this.averages.p10Big.down.sumAbs / this.averages.p10Big.down.cnt;
          this.averages.p10Big.down.prc = this.averages.p10Big.down.sumPrc / this.averages.p10Big.down.cnt;
        }

        if (this.averages.p20Big.noDir.cnt > 0) {
          this.averages.p20Big.noDir.abs = this.averages.p20Big.noDir.sumAbs / this.averages.p20Big.noDir.cnt;
          this.averages.p20Big.noDir.prc = this.averages.p20Big.noDir.sumPrc / this.averages.p20Big.noDir.cnt;
        }
        if (this.averages.p20Big.up.cnt > 0) {
          this.averages.p20Big.up.abs = this.averages.p20Big.up.sumAbs / this.averages.p20Big.up.cnt;
          this.averages.p20Big.up.prc = this.averages.p20Big.up.sumPrc / this.averages.p20Big.up.cnt;
        }
        if (this.averages.p20Big.down.cnt > 0) {
          this.averages.p20Big.down.abs = this.averages.p20Big.down.sumAbs / this.averages.p20Big.down.cnt;
          this.averages.p20Big.down.prc = this.averages.p20Big.down.sumPrc / this.averages.p20Big.down.cnt;
        }

        if (this.averages.p10Low.noDir.cnt > 0) {
          this.averages.p10Low.noDir.abs = this.averages.p10Low.noDir.sumAbs / this.averages.p10Low.noDir.cnt;
          this.averages.p10Low.noDir.prc = this.averages.p10Low.noDir.sumPrc / this.averages.p10Low.noDir.cnt;
        }
        if (this.averages.p10Low.up.cnt > 0) {
          this.averages.p10Low.up.abs = this.averages.p10Low.up.sumAbs / this.averages.p10Low.up.cnt;
          this.averages.p10Low.up.prc = this.averages.p10Low.up.sumPrc / this.averages.p10Low.up.cnt;
        }
        if (this.averages.p10Low.down.cnt > 0) {
          this.averages.p10Low.down.abs = this.averages.p10Low.down.sumAbs / this.averages.p10Low.down.cnt;
          this.averages.p10Low.down.prc = this.averages.p10Low.down.sumPrc / this.averages.p10Low.down.cnt;
        }

        if (this.averages.p20Low.noDir.cnt > 0) {
          this.averages.p20Low.noDir.abs = this.averages.p20Low.noDir.sumAbs / this.averages.p20Low.noDir.cnt;
          this.averages.p20Low.noDir.prc = this.averages.p20Low.noDir.sumPrc / this.averages.p20Low.noDir.cnt;
        }
        if (this.averages.p20Low.up.cnt > 0) {
          this.averages.p20Low.up.abs = this.averages.p20Low.up.sumAbs / this.averages.p20Low.up.cnt;
          this.averages.p20Low.up.prc = this.averages.p20Low.up.sumPrc / this.averages.p20Low.up.cnt;
        }
        if (this.averages.p20Low.down.cnt > 0) {
          this.averages.p20Low.down.abs = this.averages.p20Low.down.sumAbs / this.averages.p20Low.down.cnt;
          this.averages.p20Low.down.prc = this.averages.p20Low.down.sumPrc / this.averages.p20Low.down.cnt;
        }

        //console.log('calcAdditionals - first',first);
        //console.log('calcAdditionals - last',last);
      }
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        p1() {
            return this.$props.settings.p1;
        },
        p2() {
            return this.$props.settings.p2;
        },
        line_width() {
            return this.sett.lineWidth || 0.9;
        },
        color() {
            return this.sett.color || "#788998";
        },
        back_color() {
            return this.sett.backColor || "#9b9ba3";
        },
        value_back() {
          return this.sett.valueBack || "#9b9ba316";
        },
        value_color() {
            return this.sett.valueColor || "#fff8f8";
        },
        prec() {
            return this.sett.precision || 2;
        },
      new_font() {
        return "12px " + this.$props.font.split("px").pop();
      },
      new_fontDisplay() {
        return "11px " + this.$props.font.split("px").pop();
      },
    },
    data() {
        return {};
    },
};
</script>
