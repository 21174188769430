<template>
    <!-- Timeframe Selector -->
    <div class="tf-selector">
        <span class="timeframe" v-for="(tf, i) in this.timeframes" :key="i" v-on:click="on_click(tf, i)" v-bind:style="selected === i ? { color: '#00aa84', 'font-weight': '500' } : {}">
            {{ tf }}
        </span>
    </div>
</template>

<script>
export default {
    name: "TfSelector",
    props: ["tfReset", "defaultTf"],
    mounted() {
        // const tfk = ["1m", "15m", "30m", "1h", "4h", "12h", "1d", "1w", "1M"];
        //console.log('12',this.defaultTf);
        this.selected = this.timeframeskey.indexOf(this.defaultTf);
        // this.$emit('selected', {
        //     name: this.timeframes[this.selected],
        //     i: this.selected
        // })
    },
    computed: {
        timeframes() {
            return ["1m", "5m", "15m", "30m", "H", "4H", "D", "W", "M"];
            // return ["1m", "15m", "30m", "H", "4H", "12H", "D", "W", "M"];
        },
        timeframeskey() {
            return ["M1", "M5", "M15", "M30", "H1", "H4", "D1", "W1", "MN"];
            // return ["1m", "15m", "30m", "1h", "4h", "12h", "1d", "1w", "1M"];
        },
    },
    methods: {
        on_click(tf, i) {
            //console.log(i, this.timeframeskey[i]);
            this.selected = i;
            this.$emit("selected", {
                timeframeSelected: this.timeframeskey[i], // pass right interval to binance
            });
        },
    },
    data() {
        return {
            selected: 2,
        };
    },
    watch: {
        tfReset() {
            // this.selected = 2;
        },
        defaultTf() {
            this.selected = this.timeframeskey.indexOf(this.defaultTf);
        },
    },
};
</script>

<style>
.tf-selector {
    position: absolute;
    top: 8px;
    right: 100px;
    font: 16px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    _background: #1b202d;
    _color: #ccc;
    padding: 8px;
    border-radius: 8px;
    border: 0.5px solid #454c5e;
}
.timeframe {
    margin-right: 5px;
    user-select: none;
    cursor: pointer;
    font-weight: 200;
    max-width: 10px;
}
.timeframe:hover {
    _color: #fff;
}
</style>
