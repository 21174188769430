<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";
import ChartUtils from "../utils/ChartUtils.js";

export default {
  name: "DrawingLines",
  mixins: [Overlay, Tool],
  methods: {
    meta_info() {
      return { author: "akjcodes", version: "1.0.0" };
    },
    tool() {
      return {
        // Descriptor for the tool
        name: "DrawingLines",
        group: "DrawingLines",
        icon: Icons["drawing.png"],
        brightness: .01,
        type: "Segment",
        hint: "Drawing Lines",
        data: [], // Default data
        settings: {
          lineWidth: 1,
          color: "#ffb74d",
          backColor: "#ffb74d",
          backOpacity: 10
        }/*,
        fieldsTemplate: [
          ["color", "Color", "color"],
          ["lineWidth", "Line Thickness", "lineWidth"],
          ["color", "Back Color", "backColor"],
          ["integer", "Background Opacity", "backOpacity"],
        ],*/
      };
    },
    // Called after overlay mounted
    init() {
      this.trackingPoints = false;
      this.counter = (this.counter || 0);
      this.counter++;
      this.points = this.$props.settings.points || {};

      this.updatePins();
    },
    updatePins() {
      const self = this;
      const layout = this.$props.layout;
      console.log('add_ind_updatePins',this.$props.settings, this.points );
      this.pins.splice(0,this.pins.length);
      this.points = {};
      //this.points.splice(0,this.points.length);
      if (this.$props.settings && this.$props.settings.p1 && this.$props.settings.p2) {
        this.counter = 1;
        //this.state = 'finished';
        //this.set_state("finished");
        this.$props.settings.$state = "finished";
        //this.$props.settings = this.$props.settings || {};
        //this.$props.settings.p1 = [this.points[0][0],this.points[0][1]];
        //this.$props.settings.p2 = [this.points[this.$props.settings.points.length-1][0],this.points[this.$props.settings.points.length-1][1]];
        this.pins.push(
            new Pin(this, 'p1', {
              state: "finished",
              t: this.$props.settings.p1[0],
              y$: this.$props.settings.p1[1],
            })
        );
        let i = 0;
        let maxP = 2;
        while (this.$props.settings['p'+(++i)]) {
          self.points['p'+(self.counter++)] = [[this.$props.settings['p'+(i)][0]],[this.$props.settings['p'+(i)][1]]]
          maxP = i;
        }
        //console.log(this.pins, this.$props.settings['p1'][0], this.$props.settings['p1'][1], this.$props.settings['p'+maxP][0], this.$props.settings['p'+maxP][1], this.pins[0].t,this.pins[0].y$);
        this.pins.push(
            new Pin(this, 'p'+maxP, {
              state: "finished",
                t: this.$props.settings['p'+maxP][0],
                y$: this.$props.settings['p'+maxP][1]
            })
        );
        //console.log('saveToStarage', this.counter, self.settings.$uuid, self.points, self);
        //window.chartHandler.setStorage(self.settings.$uuid, self.points);
        this.trackingPoints = false;
        this.set_state("finished");
        console.log('add_ind_updatePins 2',this.$props.settings, this.points );
      } else {
        self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("DrawingLines", this.id, this.$props.settings);
        this.mouse.on('mousemove', e => {
          if (self.trackingPoints) {
              self.points['p'+(self.counter++)] = [layout.screen2t(e.clientX-45),
              layout.screen2$(e.clientY-60)
              ];
                //console.log('add_ind_draw2',self.points);
            //[layout.screen2t(e.clientX-45), layout.screen2$(e.clientY-60)]);
            //self.data.push([layout.screen2t(e.clientX-45), layout.screen2$(e.clientY-60)]);
          }
        });
        //console.log('saveToStarage2', this.counter, self.settings.$uuid, self.points, self);
        // First pin is settled at the mouse position
        self.pins.push(new Pin(this, 'p1'));
        self.$props.settings.p1 = [this.pins[0].t,this.pins[0].y$];
        self.trackingPoints = true;
        // Second one is following mouse until it clicks
        this.pins.push(
            new Pin(this, 'p2', {
              state: "tracking",
            })
        );
        this.pins[1].on("settled", () => {
          // Call when current tool drawing is finished
          // (Optionally) reset the mode back to 'Cursor'
          //console.log("finished");
          if (self.trackingPoints) {
            self.set_state("finished");
            self.$emit("drawing-mode-off");
            self.points['p'+(self.counter++)] =[this.pins[1].t,this.pins[1].y$];

            self.trackingPoints = false;
            //window.chartHandler.setStorage(self.settings.$uuid, self.points);
            self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off('DrawingLines');

            if (window.chartHandler && window.chartHandler.isSendLiveChart())
              window.chartHandler.sendLiveChartIndicator(this.comp);
          } else {
            self.updatePoints(1);
          }
        });

        console.log('add_ind_updatePins 3',this.$props.settings, this.points );
      }

      this.pins[1].on("dragging", () => {
        if (!self.trackingPoints) {
          self.updatePoints(1);
        }
      });
      this.pins[0].on("update_from", () => {
        self.updatePoints(0);
      });
      this.pins[0].on("settled", () => {
        self.updatePoints(0);
      });
      this.pins[0].on("dragging", () => {
        self.updatePoints(0);
      });
    },
    updatePoints(sourcePin) {
      if (this.points['p1']) {
        let diffX = this.points['p1'][0] - this.pins[sourcePin].t;
        let diffY = this.points['p1'][1] - this.pins[sourcePin].y$;
        if (sourcePin > 0) {
          let i = 0;
          while (this.points['p' + (++i)]) {
            diffX = this.points['p' + i][0] - this.pins[sourcePin].t;
            diffY = this.points['p' + i][1] - this.pins[sourcePin].y$;
          }
          this.pins[0].t -= diffX;
          this.pins[0].y$ -= diffY;
        } else {
          this.pins[1].t -= diffX;
          this.pins[1].y$ -= diffY;
        }

        let i = 0;
        while (this.points['p' + (++i)]) {
          this.points['p' + (i)][0] -= diffX;
          this.points['p' + (i)][1] -= diffY;
        }
      }
    },
    draw(ctx) {
      if (this.deleted) return;
      const layout = this.$props.layout;
      //console.log(this);
      if (this.points && (this.points.p1)) {
        if (this.show_pins)
          ctx.lineWidth = this.line_width+3;
        else
          ctx.lineWidth = this.line_width;
        ctx.strokeStyle = this.color;

        ctx.beginPath();

        //ctx.strokeStyle = '#ffff00';
        //if (this.p1Name)
        //  ctx.moveTo(layout.t2screen(this[this.p1Name][0]), layout.$2screen(this[this.p1Name][1]));
        //else
        //ctx.moveTo(layout.t2screen(this.$props.settings.p1[0]), layout.$2screen(this.$props.settings.p1[1]));

        let lastP = "p1";
        let lastPos = null;
        let first = true;
        Object.keys(this.points).forEach((key,i)=> {
            if ((key[0] == 'p') && (this.points[key][1])) {
              //console.log(key,this.$props.settings);
              if (first) {
                //ctx.moveTo(layout.t2screen(this.points[key][0]), layout.$2screen(this.points[key][1]));

                first = false;
              }
              else {

                new Seg(this, ctx).draw([this.points[lastPos][0], this.points[lastPos][1]], [this.points[key][0], this.points[key][1]]);
                //ctx.lineTo(layout.t2screen(this.points[key][0]), layout.$2screen(this.points[key][1]));
              }
              if (lastP<key)
                lastP = key;
              lastPos = key;
            }
        });
        ctx.closePath();
        ctx.stroke();
        ctx.strokeStyle = "#f00";
        //console.log(this.points.p1[0]-1, this.points.p1[1], this.points.p1[0]+2, this.points.p1[1])
        //new Seg(this, ctx).draw([this.points.p1[0]-1, this.points.p1[1]], [this.points.p1[0]+2, this.points.p1[1]]);
        //new Seg(this, ctx).draw([this.points[lastP][0]-1, this.points[lastP][1]], [this.points[lastP][0]+1, this.points[lastP][1]]);
        //new Seg(this, ctx).draw([this.points.p1[0]-1, this.points.p1[1]], [this.points[lastP][0]+1, this.points[lastP][1]]);

        //ctx.stroke();
        //if (this.p2Name in this)
        //  ctx.lineTo(layout.t2screen(this[this.p2Name][0]), layout.$2screen(this[this.p2Name][1]));
      }

      // Top
      // Bottom
      //new Seg(this, ctx).draw([this.p1[0], this.p1[1]], [this.p2[0], this.p1[1]]);

      if (this.pins.length>0)
        this.render_pins(ctx);
    },
    use_for() {
      return ["DrawingLines"];
    },
    data_colors() {
      return [this.color];
    },
  },
  // Define internal setting & constants here
  computed: {
    sett() {
      return this.$props.settings;
    },
    deleted() {
      return this.$props.settings.deleted;
    },
    p1() {
      return this.$props.settings.p1;
    },
    p2() {
      return this.$props.settings.p2;
    },
    line_width() {
      return this.sett.lineWidth || 1;
    },
    color() {
      return this.sett.color || "#ffb74d";
    },
  },
  data() {
    return {
    };
  },
};
</script>
