<script>
// Line drawing tool

import Overlay from "../../lib/mixins/overlay.js";
import Tool from "../../lib/mixins/tool.js";
import {drawPattern, updatePins} from "./drawPatterns.js"

export default {
    name: "elliot_12345",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "Sven Schwind", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                name: "elliot_12345",
                label: "Elliot 12345",
                group: "patterns",
                icon: "M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z",
                svg: true,
                type: "Segment",
                hint: "This hint will be shown on hover",
                toolTipInfo: "bitte Einstieg festlegen",
                data: [], // Default data
                settings: {
                    lineWidth: 1,
                    lineColor: "#0074b2",
                    backColor: "rgba(71,122,81,0.14)",
                }, // Default settings
                fieldsTemplate: [
                  ["color", "Color", "lineColor"],
                  ["color", "Back Color", "backColor"],
                  ["lineWidth", "Line Thickness", "lineWidth"],
                ],
            };
        },
        // Called after overlay mounted
        init() {
          this.pointCount = 6;
          this.pointCaptions = ["0","1","2","3","4","5"];
          this.drawBackgroundInfo = [];
          updatePins(this, this.$props.settings);
        },
        draw(ctx) {
            drawPattern(this, this.$props.layout, ctx);
        },
        use_for() {
            return ["elliot_12345"];
        },
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        p1() {
            return this.$props.settings.p1;
        },
        p2() {
            return this.$props.settings.p2;
        },
        p3() {
          return this.$props.settings.p3;
        },
        p4() {
          return this.$props.settings.p4;
        },
        p5() {
          return this.$props.settings.p5;
        },
        lineWidth() {
            return this.sett.lineWidth || 1;
        },
        lineColor() {
          return this.sett.lineColor || "#0074b2";
        },
        backColor() {
          return this.sett.backColor || "rgba(71,122,81,0.14)";
        },
    },
    data() {
        return {};
    },
};
</script>
