<script>
// Line drawing tool

import Overlay from "../lib/mixins/overlay.js";
import Tool from "../lib/mixins/tool.js";
import Icons from "../lib/stuff/icons.json";
import Pin from "../lib/components/primitives/pin.js";
import Seg from "../lib/components/primitives/seg.js";
//import Line from "../primitives/line.js";
import ChartUtils from "../utils/ChartUtils.js";

export default {
    name: "CRV",
    mixins: [Overlay, Tool],
    methods: {
        meta_info() {
            return { author: "C451", version: "1.0.0" };
        },
        tool() {
            return {
                // Descriptor for the tool
                name: "CRV",
                group: "crv",
                icon: "M2 12.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm0-3a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11a.5.5 0 0 1-.5-.5zm4-3a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z",
                svg: true,
                type: "Segment",
                hint: "This hint will be shown on hover",
                toolTipInfo: "bitte Einstieg festlegen",
                data: [], // Default data
                settings: {
                    lineWidth: 1,
                    colorLong: "#28b200",
                    backColorLong: "rgba(71,122,81,0.14)",
                    colorStop: "rgba(189,0,0,0.83)",
                    backColorStop: "rgba(134,7,7,0.09)",
                }, // Default settings
                fieldsTemplate: [
                  ["color", "Color", "colorLong"],
                  ["color", "Back Color", "backColorLong"],
                  ["color", "Color", "colorStop"],
                  ["color", "Back Color", "backColorStop"],
                  ["lineWidth", "Line Thickness", "lineWidth"],
                ],
            };
        },
        // Called after overlay mounted
        init() {
          this.updatePins();
        },
        updatePins() {
            // loading from saved data
          const self = this;
          this.pins.splice(0,this.pins.length);
            if (this.$props.settings.p1) {
              this.$props.settings.$state = "finished";
                this.pins.push(new Pin(this, "p1"), new Pin(this, "p2"), new Pin(this, "p3"));


                this.pins[0].on("dragging", () => {
                  if (this.pins[2]) this.pins[2].t = Math.min(this.p1[0], this.p2[0]);
                });

                this.pins[1].on("dragging", () => {
                  if (this.pins[2]) this.pins[2].t = Math.min(this.p1[0], this.p2[0]);
                });

                this.pins[2].on("dragging", () => {
                  if (this.pins[2]) this.pins[2].t = Math.min(this.p1[0], this.p2[0]);
                });

            } else {
              self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_on("CRV", this.id, this.$props.settings);
                // First pin is settled at the mouse position
                this.pins.push(new Pin(this, "p1"));
                if (this.state !== "finished") {
                  self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.ToolTipInfoText = "Zielzone festlegen";
                }
                // Second one is following mouse until it clicks
                this.pins.push(
                    new Pin(this, "p2", {
                        state: "tracking",
                    })
                );

                this.pins[1].on("settled", () => {
                    if (this.state !== "finished") {
                      self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.ToolTipInfoText = "Stop definieren";
                    }
                    // Call when current tool drawing is finished
                    // (Optionally) reset the mode back to 'Cursor'

                    // Second one is following mouse until it clicks
                    if (!this.pins[2]) {
                        this.pins.push(
                            new Pin(this, "p3", {
                                state: "tracking",
                            })
                        );

                        this.pins[2].on("settled", () => {
                          if (this.state !== "finished") {
                            this.set_state("finished");
                            //   this.pins[2].t = this.p1[0] + (this.p2[0] - this.p1[0]) / 2;
                            //this.pins[2].t = Math.max(this.p1[0], this.p2[0]);
                            //this.pins[2].y$ = this.Y;
                            this.$emit("drawing-mode-off");

                            //global drawing mode
                            //window.$store.drawingMode = false;
                            self.$parent.$parent.$parent.$parent.$parent.$parent.$parent.$parent.on_drawing_mode_off("CRV");
                          }
                        });


                      this.pins[2].on("dragging", () => {
                        if (this.pins[2]) this.pins[2].t = Math.min(this.p1[0], this.p2[0]);
                      });
                    }
                });

                this.pins[0].on("dragging", () => {
                   if (this.pins[2]) this.pins[2].t = Math.min(this.p1[0], this.p2[0]);
                });

                this.pins[1].on("dragging", () => {
                  if (this.pins[2]) this.pins[2].t = Math.min(this.p1[0], this.p2[0]);
                });
            }
        },
        roundRect(
            ctx,
            x,
            y,
            width,
            height,
            radius = 5,
            fill = false,
            stroke = true,
            text = "",
            textcolor = "#dedede"
        ) {
          if (typeof radius === 'number') {
            radius = {tl: radius, tr: radius, br: radius, bl: radius};
          } else {
            radius = {...{tl: 0, tr: 0, br: 0, bl: 0}, ...radius};
          }
          ctx.beginPath();
          ctx.moveTo(x + radius.tl, y);
          ctx.lineTo(x + width - radius.tr, y);
          ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
          ctx.lineTo(x + width, y + height - radius.br);
          ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
          ctx.lineTo(x + radius.bl, y + height);
          ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
          ctx.lineTo(x, y + radius.tl);
          ctx.quadraticCurveTo(x, y, x + radius.tl, y);
          ctx.closePath();
          if (fill) {
            ctx.fill();
          }
          if (text) {
            const textWidth = ctx.measureText(text+"").width;
            const oldStyle = ctx.fillStyle;
            ctx.fillStyle = textcolor;
            ctx.fillText(text, x+(width/2)-(textWidth/2), y+14, width);
            ctx.fillStyle = oldStyle;
          }
          if (stroke) {
            ctx.stroke();
          }
        },
        draw(ctx) {
            if (!this.p1 || !this.p2) return;

            if (this.show_pins)
              ctx.lineWidth = this.line_width+3;
            else
              ctx.lineWidth = this.line_width;
            ctx.strokeStyle = this.colorLong;
            ctx.fillStyle = this.backColorLong;

            const x1 = this.p1[0]; //t
            const y1 = this.p1[1]; //$y
            const x2 = this.p2[0];
            const y2 = this.p2[1];

            let rx1 = this.layout.t2screen(x1);
            let ry1 = this.layout.$2screen(y1);
            let rx2 = this.layout.t2screen(x2);
            let ry2 = this.layout.$2screen(y2);

            ctx.beginPath();

            ctx.moveTo(rx1, ry1);
            ctx.lineTo(rx1, ry2);
            ctx.lineTo(rx2, ry2);
            ctx.lineTo(rx2, ry1);

            ctx.closePath();
            ctx.fill();

            ctx.beginPath();
            new Seg(this, ctx).draw([x1, y1], [x2, y1]);
            new Seg(this, ctx).draw([x1, y2], [x2, y2]);
            ctx.stroke();


            if (this.pins[2]) {
                const y3 = this.pins[2].y$ || -1;

                let ry3 = this.layout.$2screen(y3);

              ctx.fillStyle = this.backColorStop;
                ctx.beginPath();

                ctx.moveTo(rx1, ry1);
                ctx.lineTo(rx1, ry3);
                ctx.lineTo(rx2, ry3);
                ctx.lineTo(rx2, ry1);

                ctx.closePath();
                ctx.fill();

                ctx.strokeStyle = this.colorStop;
                ctx.beginPath();
                new Seg(this, ctx).draw([x1, y3], [x2, y3]);

                ctx.stroke();

                if (this.selected || this.show_pins) {
                  ctx.fillStyle = "#006404";
                  ctx.strokeStyle = "#dedede";
                  const h =  20;
                  const signFactor = (ry3 > ry2) ? 1 : -1;
                  let maxy = ry3-10+(h*signFactor);
                  let middley = ry1-10;
                  let miny = ry2-10-(h*signFactor);
                  let textCRV = "Einstieg: "+y1.toFixed(2)+" - CRV: "+((y2-y1)/(y1-y3)).toFixed(2);
                  let textStop = "Stop: "+y3.toFixed(2)+"  \u25BC "+(y3-y1).toFixed(2)+" | "+(((y3-y1)/y1)*100).toFixed(2)+"%";
                  let textZiel = "Ziel: "+y2.toFixed(2)+"  \u25B2 "+(y2-y1).toFixed(2)+" | "+(((y2-y1)/y1)*100).toFixed(2)+"%";
                  ctx.font = ctx.font.replace(/\d+px/, "12px");
                  let textWidth = Math.min(Math.max(Math.max(ctx.measureText(textStop).width, ctx.measureText(textZiel).width)+10, 135),250);
                  //console.log(textWidth,ctx.measureText(textCRV).width);
                  ctx.lineWidth = 1;
                  this.roundRect(ctx, Math.trunc(rx1+(rx2 - rx1)/2-(textWidth/2))+.5, Math.trunc(miny)+0.5, textWidth, h, 5, true, true, textZiel);
                  ctx.fillStyle = "#bb0633";
                  this.roundRect(ctx, Math.trunc(rx1+(rx2 - rx1)/2-(textWidth/2))+.5, Math.trunc(maxy)+.5, textWidth, h, 5, true, true, textStop);
                  ctx.fillStyle = "#be8000";
                  this.roundRect(ctx, Math.trunc(rx1+(rx2 - rx1)/2-(textWidth/2))+.5, Math.trunc(middley)+.5, textWidth, h, 5, true, true, textCRV);


                }
            }

            this.render_pins(ctx);
        },
        use_for() {
            return ["CRV"];
        },
        data_colors() {
            return [this.color];
        },
    },
    // Define internal setting & constants here
    computed: {
        sett() {
            return this.$props.settings;
        },
        p1() {
            return this.$props.settings.p1;
        },
        p2() {
            return this.$props.settings.p2;
        },
        p3() {
            return this.$props.settings.p3;
        },
        extendP1() {
          return this.sett.extendP1;
        },
        extendP2() {
          return this.sett.extendP2;
        },
        line_width() {
            return this.sett.lineWidth || 1;
        },
      colorLong() {
        return this.sett.colorLong || "#ffb74d";
      },
      backColorLong() {
        return this.sett.backColorLong || "#9b9ba3";
      },
      colorStop() {
        return this.sett.colorStop || "#ffb74d";
      },
      backColorStop() {
        return this.sett.backColorStop || "#9b9ba3";
      },
        back_opacity() {
            return this.sett.backOpacity || 10;
        },
    },
    data() {
        return {};
    },
};
</script>
