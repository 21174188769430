<template>
  <div>
    <div v-for="item in getIndicatorOptionsItem" :key="item.name" class="field-group" :id="item.fieldID">
        <div class="sep" v-if="item.fieldType=='sep'"></div>
        <label v-if="item.fieldType!='sep'">{{item.caption}}</label>
        <div class="dropdown dropdown-line" v-if="item.fieldType=='lineWidth'">
          <div class="dropdown-selector">
            <div class="selected-value">
              <span :class="item.htmlValue"></span>
            </div>
            <div class="control_wrapper">
              <div class="decreaseControl"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8"><path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path></svg></div>
            </div>
          </div>
          <ul class="dropdown-menu">
            <li :setting="item.fieldID" value="1"><span class='line line-1'></span></li>
            <li :setting="item.fieldID" value="2"><span class='line line-2'></span></li>
            <li :setting="item.fieldID" value="3"><span class='line line-3'></span></li>
          </ul>
        </div>

        <div class="akj-input akj-input-number" v-if="item.fieldType=='integer'">
          <div class="selected-value">
            <input type="number" :value="item.value" :setting="item.fieldID" :min=item.minTag :max=item.maxTag>
          </div>
          <div class="control_wrapper">
            <div style="transform: rotate(180deg); margin-bottom: -6px;" class="increaseControl"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8"><path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path></svg></div>
            <div class="decreaseControl"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8"><path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path></svg></div>
          </div>
        </div>

        <div class="akj-input akj-input-text" v-if="item.fieldType=='text'">
          <input type="text" :setting="item.fieldID" :value="item.value">
        </div>

        <div class="akj-input akj-input-check" v-if="item.fieldType=='bool'">
          <input type="checkbox" :setting="item.fieldID" :checked="item.value">
        </div>

        <div class="dropdown dropdown-line" v-if="item.fieldType=='options'">
          <div class="dropdown-selector">
            <div class="selected-value">
              {{item.value}}
            </div>
            <div class="control_wrapper">
              <div class="decreaseControl"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="16" height="8"><path fill="#fff" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path></svg></div>
            </div>
          </div>
          <ul class="dropdown-menu">
            <li :setting="item.fieldID" value="Full Line">Full Line</li>
            <li :setting="item.fieldID" value="Extended to Left">Extended to Left</li>
            <li :setting="item.fieldID" value="Extended to Right">Extended to Right</li>
          </ul>
        </div>
        <div class="dropdown akj-color" v-if="item.fieldType=='color'">

          <!--verte display="widget" v-model="item.value" picker="square" model="rgb"><svg viewBox="0 0 24 24">
            <path d="M0 20h24v4H0z"/>
            <path style="fill: #000" d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"/>
            </svg>
          </verte-->
          <div class="fcolorpicker-curbox" :style="{ backgroundColor: item.value }" v-on:click="showModal=item;modalColor=item.value;">

          </div>
        </div>

        <div class="dropdown akj-color" v-if="item.fieldType=='coloralpha'">
          <div class="dropdown-selector">
            <div class="selected-value"><div class="swatch" :style="{ backgroundColor: item.value }"></div></div>
          </div>
          <ul class="dropdown-menu">
            <li :setting="item.fieldID" value="#ffffff"><div class="swatch" style="background-color: rgb(255, 255, 255);"></div></li>
            <li :setting="item.fieldID" value="#b2b5be"><div class="swatch" style="background-color: rgb(178, 181, 190);"></div></li>
            <li :setting="item.fieldID" value="#787b86"><div class="swatch" style="background-color: rgb(120, 123, 134);"></div></li>
            <li :setting="item.fieldID" value="#000000"><div class="swatch" style="background-color: rgb(0, 0, 0);"></div></li>
            <li :setting="item.fieldID" value="#ce93d8"><div class="swatch" style="background-color: rgb(206, 147, 216);"></div></li>
            <li :setting="item.fieldID" value="#f48fb1"><div class="swatch" style="background-color: rgb(244, 143, 177);"></div></li>
            <li :setting="item.fieldID" value="#e57373"><div class="swatch" style="background-color: rgb(229, 115, 115);"></div></li>
            <li :setting="item.fieldID" value="#ffb74d"><div class="swatch" style="background-color: rgb(255, 183, 77);"></div></li>
            <li :setting="item.fieldID" value="#fff176"><div class="swatch" style="background-color: rgb(255, 241, 118);"></div></li>
            <li :setting="item.fieldID" value="#81c784"><div class="swatch" style="background-color: rgb(129, 199, 132);"></div></li>
            <li :setting="item.fieldID" value="#4db6ac"><div class="swatch" style="background-color: rgb(77, 182, 172);"></div></li>
            <li :setting="item.fieldID" value="#4dd0e1"><div class="swatch" style="background-color: rgb(77, 208, 225);"></div></li>
            <li :setting="item.fieldID" value="#64b5f6"><div class="swatch" style="background-color: rgb(100, 181, 246);"></div></li>
            <li :setting="item.fieldID" value="#9575cd"><div class="swatch" style="background-color: rgb(149, 117, 205);"></div></li>
            <li :setting="item.fieldID" value="#ba68c8"><div class="swatch" style="background-color: rgb(186, 104, 200);"></div></li>
            <li :setting="item.fieldID" value="#f06292"><div class="swatch" style="background-color: rgb(240, 98, 146);"></div></li>
            <li :setting="item.fieldID" value="#ef5350"><div class="swatch" style="background-color: rgb(239, 83, 80);"></div></li>
            <li :setting="item.fieldID" value="#ffa726"><div class="swatch" style="background-color: rgb(255, 167, 38);"></div></li>
            <li :setting="item.fieldID" value="#ffee58"><div class="swatch" style="background-color: rgb(255, 238, 88);"></div></li>
            <li :setting="item.fieldID" value="#66bb6a"><div class="swatch" style="background-color: rgb(102, 187, 106);"></div></li>
            <li :setting="item.fieldID" value="#26a69a"><div class="swatch" style="background-color: rgb(38, 166, 154);"></div></li>
            <li :setting="item.fieldID" value="#26c6da"><div class="swatch" style="background-color: rgb(38, 198, 218);"></div></li>
            <li :setting="item.fieldID" value="#42a5f5"><div class="swatch" style="background-color: rgb(66, 165, 245);"></div></li>
            <li :setting="item.fieldID" value="#7e57c2"><div class="swatch" style="background-color: rgb(126, 87, 194);"></div></li>
            <li :setting="item.fieldID" value="#ab47bc"><div class="swatch" style="background-color: rgb(171, 71, 188);"></div></li>
            <li :setting="item.fieldID" value="#ec407a"><div class="swatch" style="background-color: rgb(236, 64, 122);"></div></li>
            <li :setting="item.fieldID" value="#d32f2f"><div class="swatch" style="background-color: rgb(211, 47, 47);"></div></li>
            <li :setting="item.fieldID" value="#f57c00"><div class="swatch" style="background-color: rgb(245, 124, 0);"></div></li>
            <li :setting="item.fieldID" value="#fbc02d"><div class="swatch" style="background-color: rgb(251, 192, 45);"></div></li>
            <li :setting="item.fieldID" value="#388e3c"><div class="swatch" style="background-color: rgb(56, 142, 60);"></div></li>
            <li :setting="item.fieldID" value="#00796b"><div class="swatch" style="background-color: rgb(0, 121, 107);"></div></li>
            <li :setting="item.fieldID" value="#0097a7"><div class="swatch" style="background-color: rgb(0, 151, 167);"></div></li>
            <li :setting="item.fieldID" value="#1976d2"><div class="swatch" style="background-color: rgb(25, 118, 210);"></div></li>
            <li :setting="item.fieldID" value="#512da8"><div class="swatch" style="background-color: rgb(81, 45, 168);"></div></li>
            <li :setting="item.fieldID" value="#7b1fa2"><div class="swatch" style="background-color: rgb(123, 31, 162);"></div></li>
            <li :setting="item.fieldID" value="#c2185b"><div class="swatch" style="background-color: rgb(194, 24, 91);"></div></li>
            <li :setting="item.fieldID" value="#b71c1c"><div class="swatch" style="background-color: rgb(183, 28, 28);"></div></li>
            <li :setting="item.fieldID" value="#121826"><div class="swatch" style="background-color: rgb(230, 81, 0);"></div></li>
            <li :setting="item.fieldID" value="#f57f17"><div class="swatch" style="background-color: rgb(245, 127, 23);"></div></li>
            <li :setting="item.fieldID" value="#1b5e20"><div class="swatch" style="background-color: rgb(27, 94, 32);"></div></li>
            <li :setting="item.fieldID" value="#004d40"><div class="swatch" style="background-color: rgb(0, 77, 64);"></div></li>
            <li :setting="item.fieldID" value="#006064"><div class="swatch" style="background-color: rgb(0, 96, 100);"></div></li>
            <li :setting="item.fieldID" value="#0d47a1"><div class="swatch" style="background-color: rgb(13, 71, 161);"></div></li>
            <li :setting="item.fieldID" value="#311b92"><div class="swatch" style="background-color: rgb(49, 27, 146);"></div></li>
            <li :setting="item.fieldID" value="#4a148c"><div class="swatch" style="background-color: rgb(74, 20, 140);"></div></li>
            <li :setting="item.fieldID" value="#880e4f"><div class="swatch" style="background-color: rgb(136, 14, 79);"></div></li>
          </ul>
      </div>
    </div>
    <div v-if="showModal">
      <transition name="modal">
        <div id="modal_BOX" class="modal-mask">
          <div class="modal-wrapper">
            <div id="modal_inner" class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Color</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" @click="showModal = null">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <form ref="form" @submit="submit">
                    <verte display="widget" v-model="modalColor" picker="square" model="rgb" >
                    </verte>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="showModal=null;">Cancel</button>
                  <button @click="submit" type="submit" class="btn btn-primary">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
//import colorPicker from '@caohenghu/vue-colorpicker'
//import colorpicker from 'v-color';
import Verte from 'verte';
import 'verte/dist/verte.css';

export default {
  name: "IndicatorConfigItem",
  props: ["indicatorConfigItems", "settings","keyName","toolObject"],
  components: { Verte },

  methods: {
    submit() {
      console.log(this.modalColor, this.showModal);
      this.showModal.value = this.modalColor;
      this.$parent.$parent.changeIndicatorValue(this.showModal.fieldID, this.modalColor);
      this.showModal = null;
    },
    akj_event(event, args, e) {
      console.log(event, args, e);
    },
    onColorChange(color) {
      console.log('Color has changed to: ', color);
    }
  },

  computed: {
    getIndicatorOptionsItem() {
      let res = [];
      if (this.$props.indicatorConfigItems) {
        this.$props.indicatorConfigItems.forEach((data, idx) => {
          res.push({fieldType: data[0], fieldName: data[1], caption: data[1], fieldID: this.$props.keyName+'#'+data[2], value: this.$props.settings[data[2]]|| data[3], htmlValue: "line line-"+this.$props.settings[data[2]]})
        });
      }
      return res;
    }
  },

  mounted() {
    const self = this;
    console.log(this.$props.indicatorConfigItems);
    for (let item in this.$props.indicatorConfigItems) {
      //console.log(item, this.$props.keyName+'#'+this.$props.indicatorConfigItems[item][2], this.$props.settings[this.$props.indicatorConfigItems[item][2]]);
      this.$parent.$parent.addListener(document.getElementById(this.$props.keyName+'#'+this.$props.indicatorConfigItems[item][2]),
          function (setting, value) {
            self.$parent.$parent.changeIndicatorValue(setting, value);
          });
    }

    /*if (document.getElementById("chart_settings_list")) {
              this.addListener(document.getElementById("chart_settings_list"), function (setting, value) {
                // change default watchlist timeframe
                console.log(setting, value);
                if (setting == "watchlist_timeframe") self.akj_event("set-chart-settings", ["set-watchlist_timeframe", value]);

                // change theme
                if (setting == "theme") self.akj_event("set-chart-settings", ["set-theme", value]);

                // change time zone
                if (setting == "timezone") self.akj_event("set-chart-settings", ["set-timezone", value]);
              });
            }*/
  },

  data() {
    return {
      colorpicker: null,
      showModal: false,
      modalColor:"#ff3311",
    };
  },
};
</script>

<style scoped>
.fcolorpicker-curbox {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
</style>