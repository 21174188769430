<template>
    <div id="tbl-fibkeys" :style="{'background-color':getBackgroundLayoutColor(),'fill':getFontLayoutColor(),'color':getFontLayoutColor()}">
        <table>
            <tr>
                <th style="min-width:90px" ></th>
                <th @click="zoomTo('FibX1')" class="FibX-column" v-if="fibData.fibo.FX[0].factor"><span style="background-color: #b601ef;">FibX</span></th>
                <th @click="zoomTo('FibX2')" class="FibX-column" v-if="fibData.fibo.FX[1].factor"><span style="background-color: #b601ef;">FibX</span></th>
                <th @click="zoomTo('Fib1')" class="Fib1-column" v-if="fibData.fibo.F1.factor"><span style="background-color: #0dca62;">Fib1</span></th>
                <th @click="zoomTo('Fib2')" class="Fib2-column" v-if="fibData.fibo.F2.factor"><span style="background-color: #00adff;">Fib2</span></th>
            </tr>
            <tr>
                <td>Fib Ratio</td>
                <td id="FibXRatio1" class="fibDataX FibX-column" v-if="fibData.fibo.FX[0].factor">{{ fibData.fibo.FX[0].factor }}</td>
                <td id="FibXRatio2" class="fibDataX FibX-column" v-if="fibData.fibo.FX[1].factor">{{ fibData.fibo.FX[1].factor }}</td>
                <td id="Fib1Ratio" class="fibData1 Fib1-column" v-if="fibData.fibo.F1.factor">{{ fibData.fibo.F1.factor }}</td>
                <td id="Fib2Ratio" class="fibData2 Fib2-column" v-if="fibData.fibo.F2.factor">{{ fibData.fibo.F2.factor }}</td>
            </tr>
            <tr>
                <td>Conso Factor</td>
                <td id="FibXFactor1"  class="fibDataX FibX-column" v-if="fibData.fibo.FX[0].factor">{{ fibData.fibo.FX[0].conso }}</td>
                <td id="FibXFactor2" class="fibDataX FibX-column" v-if="fibData.fibo.FX[1].factor">{{ fibData.fibo.FX[1].conso }}</td>
                <td id="Fib1Factor" class="fibData1 Fib1-column" v-if="fibData.fibo.F1.factor">{{ fibData.fibo.F1.conso }}</td>
                <td id="Fib2Factor" class="fibData2 Fib2-column" v-if="fibData.fibo.F2.factor">{{ fibData.fibo.F2.conso }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
//const StompJs = require("@stomp/stompjs/esm5");

import {LayoutColors} from "../../utils/LayoutHandler";

export default {
    name: "FibKeysTable",
    props: ["fibData","chartHandler","id"],

    mounted() {
        // enable columns in the fib keys table
        // it takes few seconds to available data
        // So, that's why timers hack
      /*  let timer = setInterval(() => {
            if (window.$store.CHARTS == undefined || $store.activeChart.chart == undefined) return;
            this.CHART = window.$store.CHARTS[this.tvId];
            this.CHART.chart.get(`AUTOFIB`).forEach((fib) => {
                document.getElementById("tbl-fibkeys").classList.add(fib.name.split("-")[1]);
            });

            // start websocket
            this.ws();

            clearInterval(timer);
        }, 100);
*/    },

    methods: {
        zoomTo(fibType) {
          //console.log(fibType, this.chartHandler.charts[''+this.id].settings.fibo);
          //this.chartHandler.chartObjects[this.id].$refs.tvjs.resetChart();

          //const firstId = this.$props.chartHandler.layoutHandler.getFirstId();


            let $max = null;
            let $min = null;
            let t1 = null;
            let t2 = null;

            if (fibType == "Fib2") {
                const fib1 = this.chartHandler.charts[''+this.id].settings.fibo.F1;
                const fib2 = this.chartHandler.charts[''+this.id].settings.fibo.F2;

                $max = Math.max(fib1.p1[1], fib1.p2[1], fib2.p1[1], fib2.p2[1]);
                $min = Math.min(fib1.p1[1], fib1.p2[1], fib2.p1[1], fib2.p2[1]);

                t1 = Math.min(fib1.p1[0], fib1.p2[0], fib2.p1[0], fib2.p2[0]);
            } else {
                //console.log(this.chartHandler.charts['' + this.id].settings.fibo);
                let fib;
                if (fibType == "Fib1")
                  fib = this.chartHandler.charts[''+this.id].settings.fibo.F1;
                else if (fibType == "FibX1") {
                  fib = this.chartHandler.charts['' + this.id].settings.fibo.FX[0];
                } else if (fibType == "FibX2") {
                fib = this.chartHandler.charts['' + this.id].settings.fibo.FX[1];
              }
                $max = Math.max(fib.p1[1], fib.p2[1]);
                $min = Math.min(fib.p1[1], fib.p2[1]);

                t1 = Math.min(fib.p1[0], fib.p2[0]);
            }

            //const offset = ($max - $min) / 30;
      //    this.chartHandler.chartObjects[this.id].$refs.tvjs.set_yRange($max + offset, $min - offset);
            // time offset
            const chart = this.chartHandler.charts[''+this.id].data.get("chart");
            if (chart) {
              const ohlcv = chart[0];
              if (ohlcv.data[ohlcv.data.length - 1]) {
                t2 = ohlcv.data[ohlcv.data.length - 1][0];

                let diff = (t2 - t1) / 20;
                this.chartHandler.chartObjects[this.id].$refs.tvjs.setRange(t1 - 5 * diff, t2 + diff, 5);
              }
            }
            // $store.activeChart.chart.tv.setRange(t1, t2, 5);*/
        },

        getBackgroundLayoutColor() {
          return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.backgroundMain);
        },
        getFontLayoutColor() {
          return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorMain);
        },
        ws() {
          /*
            this.client = new StompJs.Client({ brokerURL: "wss://chart-software.com/ws" });
            this.client.activate();

            this.client.onConnect = () => {
                this.client.subscribe(`/topic/${this.CHART.symbol}/${this.CHART.timeframe}/fibo`, (data) => {
                    var fib = JSON.parse(data.body);
                    // this.fibKeys[fibData.type] = [Number(fibData.fibRatio).toFixed(4), Number(fibData.consolidationFactor).toFixed(4)];
                    document.getElementById(`${fib.type}Ratio`).textContent = Number(fib.fibRatio).toFixed(4);
                    document.getElementById(`${fib.type}Factor`).textContent = Number(fib.consolidationFactor).toFixed(4) * 100;
                });
            };*/
        },
    },

    beforeDestroy() {
        //if (this.client) this.client.deactivate();
    },

    data() {
        return {
            client: null,
            CHART: null,
            fibKeys: {
                FibX: [32, 21],
                Fib1: [3, 32.20],
                Fib2: [12.20, 23.0],
            },
        };
    },
};
</script>

<style>
#tbl-fibkeys {
  font-weight: 400;
  _color: #e1e1e1;
  position: absolute;
  left: 10px;
  _background-color: #262b3f;
  padding: 6px 0px 4px;
  font-size: 12px;
  z-index: 99;
}

#tbl-fibkeys tr {
    height: 20px;
}

#tbl-fibkeys th {
    cursor: pointer;
}

#tbl-fibkeys th span {
    padding: 2px 4px;
    font-weight: 400;
}

#tbl-fibkeys th,
#tbl-fibkeys td {
    padding: 0 8px;
    text-align: left;
}

#tbl-fibkeys .fibDataX {
    color: #b601ef;
  text-align: center;
}

#tbl-fibkeys .fibData1 {
    color: #0dca62;
  text-align: center;
}

#tbl-fibkeys .fibData2 {
    color: #00adff;
  text-align: center;
}

.FibX-column,
.Fib1-column,
.Fib2-column {
    /*display: none;*/
  width : 25px;
  color: #262b3f;
  font-weight: 900;
}

#caption-column {
  width : 500px;

}

.FibX .FibX-column,
.Fib1 .Fib1-column,
.Fib2 .Fib2-column {
    display: table-cell;
}
</style>
