<template>
    <div class="tb-dropdown" >
        <div class="group-icon" @mouseover="show" @mousedown="show" v-clickaway="hide" @mouseleave="hidetimer">
            <span v-if="(icon != undefined) && !svg" :style="icon"></span>
            <svg  v-if="(icon != undefined) && svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
              <path :d="icon"/>
            </svg>
            <svg viewBox="0 0 1030 638" width="8" id="expand_icon">
                <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z"></path>
            </svg>
        </div>
        <div class="tb-dropdown-list" v-show="isActive" v-clickaway="hide" @mouseover="show" @mouseleave="hidetimer" :style="{'width':(widthMenu)+'px','background-color':getBackgroundLayoutColor(),'fill':getFontLayoutColor(),'color':getFontLayoutColor()}">
            <div @click="on_tool_select(item, $event, item.O_ID)" :class="'menu-item Lines_'+item.name" v-for="(item, i) in itemsGroups" :key="i" :id="item.name"  @mouseover="setActiveItem(i)">
                <span v-if="item['icon']" class="tool_icon" :style="icon_style(item['icon'])"></span>
                <div
                    style="
            display: flex;
            width: 100%;
            position: relative;
            justify-content: space-between;
            align-items: center;
          "
                >
                    <span style="overflow: hidden; text-overflow: ellipsis; margin-right: 38px; max-width: 170px;">{{ item[nameKey] }}</span>
                    <span v-if="item.SHORTCUT && item.SHORTCUT != 'none'" class="shortcut">{{ item["SHORTCUT"].toUpperCase() }}</span>
                  <i v-show="!item.isFav" class="fa fa-star-o" :style="{'position':'absolute','right':(hasDelete?2:1)+'em','font-size': 'large'}" @click="setFavorite($event,item)"></i>
                  <i v-show="item.isFav" class="fa fa-star" :style="{'position':'absolute','right':(hasDelete?2:1)+'em','font-size': 'large'}" @click="setFavorite($event,item)"></i>
                  <i v-show="hasDelete" class="fa fa-times-circle" style="position:absolute;right:.8em;font-size: large"></i>
                  <i v-show="(item.templates.length > 0)" class="fa fa-angle-right" style="position:absolute;right:.2em"></i>

                </div>
                <div class="tb-dropdown-list-sub" v-show="item.templates && (item.templates.length > 0) && activeItemId==i" v-clickaway="hide" @mouseover="show" @mouseleave="hidetimer" :style="{'top':(i*35)+'px', 'left':(widthMenu+1)+'px','background-color':getBackgroundLayoutColor(),'fill':getFontLayoutColor(),'color':getFontLayoutColor()}">
                  <div @click="on_tool_select(itemsub, $event, itemsub.O_ID)" :class="'menu-item Lines_'+itemsub.name" v-for="(itemsub, isub) in item.templates" :key="isub" :id="itemsub.settingName">
                    <span v-if="itemsub['icon']" class="tool_icon" :style="icon_style(itemsub['icon'])"></span>
                    <div style="display: flex;width: 100%;position: relative;justify-content: space-between;align-items: center;padding-left: 0.5em">
                      <span style="overflow: hidden; text-overflow: ellipsis; margin-right: 3em; max-width: 210px;">{{ itemsub['settingName'] }}</span>
                      <span v-if="itemsub.SHORTCUT && itemsub.SHORTCUT != 'none'" class="shortcut">{{ itemsub["SHORTCUT"].toUpperCase() }}</span>
                      <i v-show="hasDeleteSub" class="fa fa-times-circle" style="position:absolute;right:.0em;font-size: large" @click="deleteSubItem($event,itemsub)"></i>
                      <i v-show="!itemsub.isFav" class="fa fa-star-o" :style="{'position':'absolute','right':(hasDeleteSub?1.2:0.2)+'em','font-size': 'large'}" @click="setFavorite($event,itemsub)"></i>
                      <i v-show="itemsub.isFav" class="fa fa-star" :style="{'position':'absolute','right':(hasDeleteSub?1.2:0.2)+'em','font-size': 'large'}" @click="setFavorite($event,itemsub)"></i>

                    </div>

                  </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
//import {getCurrentInstance} from 'vue';

import {LayoutColors} from "../../utils/LayoutHandler";

export default {
    name: "ToolList",
    props: ["items", "title", "nameKey", "icon", "svg", "hasDeleteSub","hasDelete","widthMenu","chartHandler"],

    data() {
        return {
            isActive: false,
            timer: null,
            activeItemId: -1,
            renderComponent: true,
        };
    },

    methods: {
        getBackgroundLayoutColor() {
          return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.backgroundMain);
        },
        getFontLayoutColor() {
          return this.chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorMain);
        },
        on_tool_select(item, event, O_ID) {
          const selItem = this.$el.querySelector(".selected-item")
          const selItemLines = this.$el.querySelector(".Lines")
          const selItemSubLines = this.$el.querySelector(".selected-subitem")
          if (selItemSubLines) {
            selItemSubLines.classList.remove("selected-subitem");
          }
          if (selItem) {
            selItem.classList.remove("selected-item");
          }
          /*if (item && item.group =="Lines") {
            const selItemLinesSub = this.$el.querySelector(".Lines_"+item.name)
            if (selItemLinesSub)
              selItemLinesSub.classList.add("selected-item");
            if (selItemLines)
              selItemLines.classList.add("selected-subitem");
          }*/

          this.$emit("tool-selected", item);
          event.stopPropagation();
        },
        setActiveItem(itemId) {
          this.activeItemId = itemId;
        },
        deleteSubItem(e, item) {
          this.$props.chartHandler.deleteToolSetting(item.idToolSettings);
          //console.log('deleteSubItem',item.idToolSettings);
          this.$forceUpdate();
          e.stopPropagation();
        },
        setFavorite(e, item) {
            item.isFav=!item.isFav;
            //this.forceRerender();
          this.$forceUpdate();
          window.chartHandler.favoritesHandler.toogleToFavorites(item.toolName||item.name,item.settingName||"");
          console.log('setfav',item);
          e.stopPropagation();
        },
        async forceRerender() {
          // Remove MyComponent from the DOM
          this.renderComponent = false;

          // Wait for the change to get flushed to the DOM
          await this.$nextTick();

          // Add the component back in
          this.renderComponent = true;
        },
        show() {
          //this.activeItemId = -1;
          //console.log('getTool timer show', this.timer);
            if (this.timer) {
              //console.log('timer clearTimeout', this.timer);
              window.clearTimeout(this.timer);
              this.timer = null;
            }
            this.isActive = true;
        },
        hide: function() {
          this.activeItemId = -1;
          if (this.timer) {
            //console.log('timer hidetimer cancel hide', this.timer);
            window.clearTimeout(this.timer);
          }
            this.isActive = false;
        },
        hidetimer: function() {
          const self = this;
          if (this.timer) {
            console.log('timer hidetimer cancel', this.timer);
            window.clearTimeout(this.timer);
          }
          this.timer = window.setTimeout(() => {
                console.log('timer hidetimer', self.timer);
                self.activeItemId = -1;
                self.isActive = false;
          },
              100);

        },
        icon_style(icon) {
            return {
                "background-image": `url(${icon || this.icon})`,
                display: "block",
                width: "25px",
                height: "25px",
                _filter: "brightness(1)",
              _filter: `brightness(${(chartHandler ? chartHandler.layoutHandler.getLayoutColor(LayoutColors.fontColorBrightnessMain) : 0.0)}) hue-rotate(90deg) saturate(4.5) !important`,
                "background-repeat": "no-repeat",
                "background-size": "contain",
            };
        },
    },
    computed: {
      itemsGroups: function () {
        return this.items;
      },
    }
};
</script>

<style scoped>
.tb-dropdown {
    position: relative;
    _color: #fff;
    _color: #2d2d2d;
  _background-color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    user-select: none;
}
.selected-subitem .tb-dropdown {
  _fill: rgb(107, 224, 47);
}
.group-icon {
    display: flex;
}

#expand_icon {
  position: absolute;
  bottom: -5px;
  right: -5px;
  transform: rotate(-45deg);
}

.tb-dropdown-list {
    font-size: 13px;
    position: absolute;
    _background-color: #12151b;
    box-shadow: 0px 1px 6px 1px #00000080;
    z-index: 1000;
    top: -5px;
    left: 36px;
    margin: 0 auto;
    white-space: nowrap;
}

.tb-dropdown-list-sub {
  font-size: 13px;
  position: absolute;
  _background-color: #12151b;
  box-shadow: 0px 1px 6px 1px #00000080;
  z-index: 1001;
  top: 0px;
  left: 230px;
  margin: 0 auto;
  width: auto;
  white-space: nowrap;
}

.tb-dropdown-list .menu-item {
  padding: 5px 5px;
  display: flex;
  align-items: center;
}


.tb-dropdown-list-sub .menu-item {
  padding: 5px 5px;
  display: flex;
  align-items: center;
}
.menu-item .icon-delete {
    cursor: pointer;
    display: none;
    position: absolute;
    right: 0;
}
.shortcut {
    font-size: 12px;
    color: #4e4e4e;
    float: right;
}
.menu-item:hover .icon-delete {
    display: inline;
}
.icon-delete:hover {
    filter: brightness(1.45) sepia(1) hue-rotate(310deg) saturate(4.5) !important;
}
.menu-item:hover .shortcut {
    visibility: hidden;
}

.menu-item {
  _background-color: #f2f2f2;
}

.menu-item:hover,
.menu-item.active {
  _background-color: #1b222f;
  _background-color: #d5d5d5;
}

.menu-item.selected-item {
  _color: #12fc01;
}
.menu-item .tool_icon {
  font-size: 0.1em;
    margin-right: 12px;
}
.Lines {
  font-size: x-small;
}
</style>
