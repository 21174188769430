<template>
  <div ref="draggableTimeLine" id="maincontainer" >
    <div id="panel-container" v-on:click="onClickDraggable" :style="{'margin-left':getCurrentSize()+'px','background-color':getBGColor()}">
      <div id="draggable-header" @mousedown="dragMouseDown" v-on:click="onClickDraggable" :style="{'cursor': selectedLabelId ? 'grab' : 'pointer'}">
        <svg xmlns="http://www.w3.org/2000/svg" style="padding-top: 0.25em;float: left" width="20" height="20" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"/>
        </svg>
        <span class="sizeicon" style="float: left;margin-left: 0.5em;font-size: 1.05em;padding-top: 0.17em;">
           {{caption}}
        </span>
        <span class="sizeicon"  style="font-size: 1.05em;padding-top: 0.17em;">
           {{currentSecondFormated}}
        </span>
      </div>
    </div>
    <div><i class="fa fa-plus" v-on:click="incSecond" aria-hidden="true" :style="{'color':currentSecond<videoSize?'#d9d9d9':'rgba(122,122,122,0.5)'}"></i></div>
    <div><i class="fa fa-minus" v-on:click="decSecond" aria-hidden="true" :style="{'color':getCurrentSize()>1?'#d9d9d9':'rgba(122,122,122,0.5)'}"></i></div>
    <div><i class="fa fa-close" v-on:click="clearSecond" aria-hidden="true" :style="{'color':getCurrentSize()>0?'#d9d9d9':'rgba(122,122,122,0.5)'}"></i></div>
  </div>
</template>

<script>

export default {
  name: 'DraggableTimeLine',
  components: {  },
  props: ["videoLength","currentVideoPos","currentSecond","labelId","selectedLabelId","caption","videoSize"],
  data: function () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0,
        moveStartX: 0,
      },
      currentSecondFormated:"00:00",
      currentSize: 0,
      calcSize: 0,
      sizeOffset: 250,
      chartHandler: window.chartHandler,
    }
  },
  methods: {
    onClickDraggable() {
      console.log(new Date(),'onClickDraggable', this.labelId);
      if (!this.selectedLabelId) {
        this.$emit('switchTool', this.labelId);
      }
    },
    dragMouseDown: function (event) {
      console.log(new Date(),'dragMouseDown', this.labelId);
      if (this.selectedLabelId) {
        event.preventDefault()
        // get the mouse cursor position at startup:
        this.positions.clientX = event.clientX
        this.positions.moveStartX = event.clientX
        this.positions.clientY = event.clientY
        document.onmousemove = this.elementDrag
        document.onmouseup = this.closeDragElement
        this.calcSize = this.currentSize;
      }
    },
    elementDrag: function (event) {
      event.preventDefault()
      //console.log(this.positions.moveStartX,event.clientX);
      if (this.selectedLabelId && this.$refs.draggableTimeLine && (this.positions.clientX !== event.clientX))  {
        this.positions.movementX = this.positions.clientX - event.clientX
        this.positions.movementY = this.positions.clientY - event.clientY
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY
        // set the element's new position:

        var calcSizeTemp = Math.max(0, this.calcSize - this.positions.movementX);
        if (this.$refs.draggableTimeLine.offsetWidth - this.sizeOffset < calcSizeTemp) {
          calcSizeTemp = this.$refs.draggableTimeLine.offsetWidth - this.sizeOffset;
        }
        this.calcSize = calcSizeTemp;
      }
      //this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      //this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement(event) {
      console.log(new Date(),'closeDragElement', this.labelId);
      //this.chartHandler.pinModifyPanel(
      //    this.chartHandler.chartSettings.isPinModifyPanel,
      //    this.$refs.draggableContainer.offsetLeft,
      //    this.$refs.draggableContainer.offsetTop);
      if (this.selectedLabelId) {
        document.onmouseup = null
        document.onmousemove = null
        if (Math.abs(this.positions.moveStartX-event.clientX)>0)
          this.$emit('onAnimationInfo', this.labelId);
      }
    },
    currentDisplayPos() {
      //if (this.$refs.draggableTimeLine)
      //  console.log(this.$refs.draggableTimeLine.width);
      return this.currentVideoPos/(Math.max(1,this.videoSize||1))*250;
    },
    getBGColor(){
      return this.currentSecond <= 0 ? "rgb(140,126,0)" : (this.caption == 'zoom' ? "rgb(20,91,66)" : (this.selectedLabelId ? "rgb(20,67,91)" : "rgb(41, 88, 100)"));
    },
    incSecond() {
      if (this.getSafeCurrentSecound()>0) {
        this.$emit('onchangeStartSec', this.labelId, Math.min(this.videoSize||1, this.getSafeCurrentSecound() + 1));
        this.$emit('onAnimationInfo', this.labelId);
      } else {
        this.$emit('onchangeStartSec', this.labelId, 1000000);
        this.$emit('onAnimationInfo', this.labelId);
      }
    },
    decSecond() {
      if (this.getSafeCurrentSecound()>1) {
        this.$emit('onchangeStartSec', this.labelId, Math.max(1, this.getSafeCurrentSecound() - 1));
        this.$emit('onAnimationInfo', this.labelId);
      }
    },
    clearSecond() {
      if (this.getSafeCurrentSecound()>0) {
        this.$emit('onchangeStartSec', this.labelId, 0);
        this.$emit('onAnimationInfo', this.labelId);
      }
    },
    getCurrentSize() {
      this.updateCurrentSize();
      return this.currentSize;
    },
    updateCurrentSize() {
      if (this.$refs.draggableTimeLine) {
        const sec = Math.trunc(this.getSafeCurrentSecound() % 60)+"";
        this.currentSecondFormated = Math.trunc(this.getSafeCurrentSecound()/60)+":"+(sec.length==1 ? "0"+sec : sec);

        const size = this.$refs.draggableTimeLine.offsetWidth || (parseFloat(getComputedStyle(this.$refs.draggableTimeLine).fontSize)*50);
        this.currentSize = Math.round(this.getSafeCurrentSecound() / (this.videoSize || 1) * (size - this.sizeOffset)) + (this.getSafeCurrentSecound() <= 0 ? 0 : 1);
        console.log(this.currentSize, this.getSafeCurrentSecound(), this.videoSize, this.$refs.draggableTimeLine.offsetWidth, size ,this.sizeOffset);

      }
    },
    getSafeCurrentSecound() {
      return Math.min(this.currentSecond,this.videoSize);
    }
  },
  render() {
    this.updateCurrentSize();
    /*if (this.$refs.draggableTimeLine) {
      console.log(this.$refs.draggableTimeLine.offsetWidth);
      console.log(this.currentSize, this.positions.movementX);
      console.log(this.currentVideoPos, this.currentVideoPos / (Math.max(1, this.videoLength)) * (this.$refs.draggableTimeLine.offsetWidth - this.sizeOffset));
    }*/
    //this.currentSize = 100;// this.currentVideoPos/(Math.max(1,this.videoLength))*this.$refs.draggableTimeLine.offsetWidth;
  },
  watch: {
    "calcSize"(newValue) {
      //currentSize
      var currentSecondTemp = Math.max(1,Math.trunc(newValue / (this.$refs.draggableTimeLine.offsetWidth - this.sizeOffset) * this.videoSize||1));

      this.$emit('onchangeStartSec', this.labelId, currentSecondTemp);
    },
    "currentSecond"(newValue) {
      //currentSize
      console.log("videoLength 2",newValue)
      const sec = Math.trunc(newValue % 60)+"";
      this.currentSecondFormated = Math.trunc(newValue/60)+":"+(sec.length==1 ? "0"+sec : sec);
      this.updateCurrentSize();
    },
    "videoSize"(newValue) {
      console.log("videoSize 1",newValue);
      this.updateCurrentSize();
      //this.currentSize = Math.round(this.currentSecond / this.videoSize * (this.$refs.draggableTimeLine.offsetWidth - this.sizeOffset));
    },

  },
  computed: {}
}
</script>

<style scoped>


svg {
  padding-top: 0.01em;

}

.sizeicon {
  margin-right: 0.5em;
  padding-top: 0.1em;
  float: right;
}

.fa {
  margin: 0.2em;
  margin-left: 0.2em;
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  padding: 0.4em;
  padding-bottom: 0.2em;
  float: center;
}

#panel-container {
  display: flex;
  flex-direction: column;
  color: #d9d9d9;
  background-color: rgb(41, 88, 100);
  border: 1px solid #b4b4b4;
  border-radius: 0em;
  width:47em;
}

#maincontainer {
  display: flex;
  flex-direction: row;
  padding: 0.05em;
  color: #d9d9d9;
  background-color: rgb(114, 141, 148);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  margin-top: 0.3em;
  width: 50em;
}

#draggable-header {
  display: block;
  z-index: 91;
  padding-left: 0.05em;
  padding-right: 0.05em;
  padding-bottom: 0.05em;
  padding-top: 0.15em;
  font-size: small;
}

panel-button {
  z-index: 91;
  padding-right: 1em;
  padding-bottom: 0.1em;
}

#color-button-group {
  display: flex;
}

color-button {
  z-index: 91;
}

.button:hover {
  background-color: #3b3b3b;
  border: 1px solid #b9b9b9;
}

.button:focus {
  border: 0px solid #b9b9b9;
  outline:0;
}

button:focus {
  border: 1px solid #b9b9b900;
  outline:1px;
}

.vl {
  border-left: 1px solid rgba(185, 185, 185, 0.49);
  margin: 0.25em;
  margin-top: 0.2em;
  height: 2.0em;
}

.button {
  background-color: #00000000;
  border: 0.1px solid rgba(222, 222, 222, 0.29);
  color: #dedede;
  border-radius: 2px;
  box-shadow_: rgba(117, 119, 119, 0.5) 0 2px 5px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Amazon Ember",sans-serif;
  font-size: 0.8em;
  line-height: 2em;
  padding: 2px 2px 2px 2px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  border-left_: 1px solid #b9b9b9;
  margin-top: 0.35em;
  width: 2.4em;
  height: 2.4em;
}

.button1 {
  border-radius: 4px;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

.selbutton {
  margin: 0.05em;
}

.colorbutton {
  background-color: #0dca62;

}
.colorbutton:hover {
  background-color: #0dca62;
  color: #dedede;
}

#linewidthpanel {
  width:10em;
  margin-left: 1em;
}

.lineitem {
  padding-left: 0.5em;
  cursor: pointer;
  width:100%;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}

.lineitem:hover {
  background-color: #1F4661;
}

.lineitemunlink {
  padding-left: 0.5em;
  cursor: default;
  width:100%;
  margin: 0.2em;
  color: rgba(222,222,222,0.41);
}

saveaspanel {
  margin-right: 0.2em;

}

.modifypanel {
  margin-top: 0.5em;
  background-color: rgb(37, 41, 42);
  border: 1px solid #b4b4b4;
  border-radius: 0.3em;
  color: #dedede;
  padding: 0.5em;
}

.row {
  display: flex;
  width:18em;
}

/* Create two equal columns that sits next to each other */
.column {
  flex: 50%;/* Should be removed. Only for demonstration */
}
#fontSize {
  width:100%;
}
#colormap {
  display: flex;
  flex-wrap: wrap;
  width: 19em;
}

div.video-annotations {display:none;}

</style>

