String.prototype.hashCode = function() {
    var hash = 0,
        i, chr;
    if (this.length === 0) return hash;
    for (i = 0; i < this.length; i++) {
        chr = this.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}


export default {

    alphaToHex(alpha) {
        if (alpha < 0 || isNaN(alpha)) return "00";
        if (alpha > 100) return "ff";

        let y = Math.round((255 / 100) * alpha);
        let ret = y < 16 ? "0" + y.toString(16) : y.toString(16);

        return ret;
    },

    fixLength(num, len) {
        return num.toString().padStart(len, '0')
    },

    //2021-07-26T23:44:00.000%2B02:00
    formatDateStr(dt) {
        const d = new Date(dt);
        return d.getUTCFullYear()+'-'+this.fixLength(d.getUTCMonth()+1,2)+'-'+this.fixLength(d.getUTCDate(),2)+'T'+this.fixLength(d.getUTCHours(),2)+':'+this.fixLength(d.getUTCMinutes(),2)+':'+this.fixLength(d.getUTCSeconds(),2)+'.'+this.fixLength(d.getUTCMilliseconds(),3)+'%2B00:00';
    },

    //2021-07-26 23:44:00
    formatDateTimeStrDisplay(dt) {
        const d = new Date(dt);
        return d.getUTCFullYear()+'-'+this.fixLength(d.getUTCMonth()+1,2)+'-'+this.fixLength(d.getUTCDate(),2)+' '+this.fixLength(d.getUTCHours(),2)+':'+this.fixLength(d.getUTCMinutes(),2)+':'+this.fixLength(d.getUTCSeconds(),2);
    },

    //2021-07-26 23:44:00
    formatDateStrDisplay(dt) {
        try {
            const d = new Date(dt);
            return this.fixLength(d.getUTCDate(), 2) + '.' + this.fixLength(d.getUTCMonth() + 1, 2) + '.' + d.getUTCFullYear();
        } catch(err) {
            return dt;
        }
    },

    calcAngle(a, b) {
        let c_2 = a*a + b*b;
        let c = Math.sqrt(c_2);

        let cos = ((-0.5*(a*a)) + (0.5*(b*b)) + (0.5*c_2)) / (c*b);

        let x = Math.cos(Math.acos(cos)) * 10;
        let y = Math.sin(Math.acos(cos)) * 10;
        //console.log(cos,Math.acos(cos)* 180/Math.PI,Math.cos(0.89445438282));
        return {alpha:Math.acos(cos)* 180/Math.PI, x:x,y:y};
    },
    calcAnglePos(x1,y1,x2,y2,size) {
        let a = x2-x1;
        let b = y2-y1;
        let c_2 = a*a + b*b;
        let c = Math.sqrt(c_2);

        let cos = ((-0.5*(a*a)) + (0.5*(b*b)) + (0.5*c_2)) / (c*b);

        let x = Math.cos(Math.acos(cos)) * size;
        let y = Math.sin(Math.acos(cos)) * size;
        //console.log(cos,Math.acos(cos)* 180/Math.PI,Math.cos(0.89445438282));
        if (x1>x2)
            return {x:x1-x,y:y1-y};
        else
            return {x:x1+x,y:y1-y};
    },
    getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Mac OS X', 'macOS'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        } else {
            os = platform;
        }

        return os;
    }
}
