import axios from "axios";
import * as StompJs from '@stomp/stompjs';
import ChartUtils from './ChartUtils.js';
import Vue from "vue";

class UserDataInterface {
    constructor(owner, cfg) {
        this.owner = owner;
        this.apiUrl = cfg.apiUrl;
        this.token  = '';

        this.callbackSettings = cfg.callbackSettings;
        this.callbackIndicators = cfg.callbackIndicators;
        this.callbackTemplates = cfg.callbackTemplates;
        this.callbackTemplate = cfg.callbackTemplate;
        this.callbackShortcuts = cfg.callbackShortcuts;
        this.callbackUsers = cfg.callbackUsers;
    }

    setToken(token) {
        this.token  = token;
    }

    request(url, cfg, callback, type, subInfo) {
        const callbackFunc = callback.bind(this.owner);
        //url = url.replace('https://chart-software.com/indicators','http://ki-trading.de:8081/indicators');
        //url = url.replace('http://localhost:7072/indicators','http://ki-trading.de:8081/indicators');
        //console.log(url);
        axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
        axios
            .get(url, cfg.withHeader ? {
                headers: {
                    token: `${this.token}`,
                }} : {}) //?limit=${limit}
            .then((res) => {
                //console.log('result',type);
                callbackFunc(res.data, type, subInfo);
            }).catch((error) => {
            console.error(url,error);
        });
    }

    put(url, data, cfg) {
        //const callbackFunc = callback.bind(this.owner);
        //console.log(url);
        axios.defaults.headers.put['Access-Control-Allow-Origin'] = '*';
        axios
            .put(url, data, cfg.withHeader ? {
                headers: {
                    token: `${this.token}`,
                }} : {}) //?limit=${limit}
            .then((res) => {
                //console.log('result save',res);
                //callbackFunc(res.data, type);
            }).catch((error) => {
            console.error(error);
        });
    }

    post(url, data, cfg) {
        //const callbackFunc = callback.bind(this.owner);
        console.log(url);
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios
            .post(url, data, cfg.withHeader ? {
                headers: {
                    token: `${this.token}`,
                }} : {}) //?limit=${limit}
            .then((res) => {
                //console.log('result-save',1,res, cfg, data);
                if ((res.status == 201) && (res.statusText == 'Created')) {
                    if (cfg && cfg.headline && cfg.text && url.indexOf('/chart')>=0) {
                        console.log('result-save',3,res, cfg.headline, cfg.text, data);
                        this.owner.setLiveChart(res.data.createdId, true, cfg.symbol, cfg.tf, cfg.headline, cfg.text, cfg.commentInternal, cfg.resourceInfo, cfg.labelActions, cfg.publishedAnalysis, cfg.abos);
                    }
                }

                if ((res.status == 200) && (res.statusText == 'OK')) {
                    //console.log('result-save',2,res, cfg.headline, cfg.text, data);
                    if (url.indexOf('/template')>=0) {
                        this.requestTemplates();
                    }
                }
                //callbackFunc(res.data, type);
            }).catch((error) => {
            console.error(error);
        });
    }

    delete(url, cfg) {
        //const callbackFunc = callback.bind(this.owner);
        //console.log(url);
        axios.defaults.headers.delete['Access-Control-Allow-Origin'] = '*';
        axios
            .delete(url, cfg.withHeader ? {
                headers: {
                    token: `${this.token}`,
                }} : {}) //?limit=${limit}
            .then((res) => {
                //console.log('result save',res);
                //callbackFunc(res.data, type);
            }).catch((error) => {
            console.error(error);
        });
    }

    requestSettings() {
        this.request(
            `${this.apiUrl}/setting`,
            {withHeader: true},
            this.callbackSettings,
            'settings');
    }

    requestTemplateInfo(type, template) {
        console.log('requestTemplateInfo removeIndicator',type, template.ID_CHART, template.ID_TEMPLATE);
        if (type=='charts')
            this.request(
                `${this.apiUrl}/chart/?chartId=${template.ID_CHART}`,
                {withHeader: true},
                this.callbackTemplate,
                type);
        else
            this.request(
                `${this.apiUrl}/template/?templateId=${template.ID_TEMPLATE}`,
                {withHeader: true},
                this.callbackTemplate,
                type);
    }

    saveTemplate(type, id, data, symbol, tf, headline, text, commentInternal, resourceInfo, labelActions, publishedAnalysis, abos) {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        let url = id > 0 ? `${this.apiUrl}/${type}/${id}` : `${this.apiUrl}/${type}`;
        //console.log('saveTemplate',id,data);
        if (id > 0) {
            this.put(
                url,
                data,
                {withHeader: true});
        } else {

            this.post(
                url,
                data,
                {withHeader: true, symbol: symbol, tf: tf, headline: headline, text: text, publishedAnalysis: publishedAnalysis, commentInternal: commentInternal, resourceInfo: resourceInfo, labelActions: labelActions, abos: abos});
        }
    }

    deleteTemplate(type, id) {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        let url = `${this.apiUrl}/${type}/${id}`;
        if (id > 0) {
            this.delete(
                url,
                {withHeader: true});
        }
    }

    saveSettings(data) {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        let url = `${this.apiUrl}/setting/`;
        //console.log('saveSettings',data);
        this.put(
            url,
            { SETTINGS: data },
            {withHeader: true});
    }

    requestTemplates() {
        this.request(
            `${this.apiUrl}/template/name`,
            {withHeader: true},
            this.callbackTemplates,
            'templates');
    }

    requestShortcuts() {
        this.request(
            `${this.apiUrl}/shortcut`,
            {withHeader: true},
            this.callbackShortcuts,
            'shortcut');
    }

    requestChartsTemplates(symbol, timeframe) {
        this.request(
            `${this.apiUrl}/chart?symbol=${symbol}`, //&timeFrame=${timeframe}`,
            {withHeader: true},
            this.callbackTemplates,
            'charts',
            timeframe);
    }

    requestIndicators() {
        this.request(
            `${this.apiUrl}/indicator`,
            {withHeader: true},
            this.callbackIndicators,
            'indicator');
    }
}

class WatchListInterface {
    constructor(owner, cfg) {
        this.owner = owner;
        this.apiUrl = cfg.apiUrl;
        this.callbackLoadWatchList = cfg.callbackLoadWatchList;
        this.callbackWatchListDetailsResponce = cfg.callbackWatchListDetailsResponce;
    }

    setToken(token) {
        this.token  = token;
    }

    request(url, cfg, callback, type) {
        const callbackFunc = callback.bind(this.owner);
        //url = url.replace('https://chart-software.com/api/indicators','http://ki-trading.de:8081/indicators');
        //console.log(url, callback);
        axios
            .get(url, cfg.withHeader ? {
                headers: {
                    token: `${this.token}`,
                }} : {}) //?limit=${limit}
            .then((res) => {
                //console.log('result wl',res, type);
                callbackFunc(res.data, type);
            }).catch((error) => {
            console.error(error);
        });
    }

    post(url, data, cfg, callback, type) {
        const self = this;
        const callbackFunc = callback.bind(this.owner);
        axios
            .post(url, data,cfg.withHeader ? {
                headers: {
                    token: `${this.token}`,
                }} : {}) //?limit=${limit}
            .then((res) => {
                //console.log('result wl',res, type);
                self.requestWatchListDetails(data.SYMBOL, data.NAME);
                callbackFunc(res.data, type);
            }).catch((error) => {
            console.error(error);
        });
    }

    loadWatchList() {
        this.request(
            `${this.apiUrl}/watchlist/name`,
            {withHeader: true},
            this.callbackLoadWatchList,
            'watchlist');
    }
    requestWatchListDetails(symbol,name) {
        this.request(
            `${this.apiUrl}/watchlist/?symbol=${symbol}&name=${name}`,
            {withHeader: true},
            this.callbackWatchListDetailsResponce,
            'watchlist');
    }

    addWatchListDetail(symbol,name) {
        this.post(
            `${this.apiUrl}/watchlist`,
            {SYMBOL: symbol, NAME: name},
            {withHeader: true},
            this.callbackWatchListDetailsResponce,
            'watchlist');
    }

    deleteWatchList(name) {
        axios.delete(`${this.apiUrl}/watchlist/list/${name}`, {
                    headers: {
                        token: `${this.token}`,
                    },
                })
                .then((res) => {
                    // console.log(res);
                })
                .catch((error) => {
                    console.error(error);
                });
    }

    deleteWatchListDetail(symbol, name) {
        const self = this;
        axios
            .delete(`${this.apiUrl}/watchlist/${name}/${symbol}`, {
                headers: {
                    token: `${this.token}`,
                },
            })
            .then((res) => {
                self.requestWatchListDetails();
            })
            .catch((error) => {
                self.requestWatchListDetails();
                console.error(error);
            });
    }
}

class MarketDataInterface {
    constructor(owner, cfg, timeCalc) {
        this.timeCalc = timeCalc || 1;
        this.apiUrl = cfg.apiUrl;
        this.token  = null;
        this.mockMode = (cfg.mockMode ? 'hiku/' : '');
        this.callbackHistory = cfg.callbackHistory.bind(owner);
        this.callbackMarketList = cfg.callbackMarketList.bind(owner);
    }

    setToken(token) {
        this.token  = token;
    }

    requestHistory(symbol, timeframe, start, end, limit = 640) {
        const self = this;
        axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
        let url = `${this.apiUrl}/markets/${symbol}/${timeframe}/TOHLCV`;
        if (symbol.indexOf(':')>=1)
            url = `${this.apiUrl}/${this.mockMode}markets/${symbol}/${timeframe}/TOHLCV/`;

        if ((start) && (end)) {
            url += '?start='+ChartUtils.formatDateStr(start);
            url += '&end='+ChartUtils.formatDateStr(end);
            //url += '&startDT='+Math.trunc(start);
            url += '&endDT='+Math.trunc(end);
        }
        console.log(url);

        axios
            //.get(url) //?limit=${limit}
            .get(url, {
                headers: {
                    token: `${this.token}`,
                }} ) //?limit=${limit}
            .then((res) => {
                self.handleHistDataResponce(symbol, timeframe, start, end, limit, res)
            })
            .catch((error) => {
                console.error(error);
                //self.handleHistDataResponce(symbol, timeframe, start, end, limit, )
            });
    }

    handleHistDataResponce(symbol, timeframe, start, end, limit, res) {
        const self = this;
        const dt = new Date();
        // const timeOffset = (390 + dt.getTimezoneOffset()) * 60 * 1000; // FIX for real-time candle creating issue
        // const timeOffset = 60 * 1000 * 60;
        // TODO try to set in on chart directly
        const timeHours = 0; //dt.getTimezoneOffset();
        const timeOffset = timeHours * 60 * 1000;
        //console.log(res);

        if ((res.data !== null) && (typeof res.data === 'object') && (res.data.candles)) {
           console.log('hloc',res.data);
            // candle data

            const timeCalcFactor = res.data.timeCalcFactor || this.timeCalc;
            const priceBasis = res.data.priceBase || 0;
            const timeBaseValue = res.data.timeBaseValue || 0;
            const splits = res.data.splits ? res.data.splits : []; //(res.data.name == "LCI:EDGX" ? [{time:1675728000000, factor: 4}] : []);
            console.log('hloc',timeOffset, timeCalcFactor,timeBaseValue,priceBasis,splits);
            const ohlcvData = res.data.candles.map((x) => {
                let res = [(x[0] - timeOffset + timeBaseValue) * timeCalcFactor, x[1] + priceBasis, x[2] + priceBasis, x[3] + priceBasis, x[4] + priceBasis, x[5]];
                splits.forEach((s)=> {
                   if (s.time > res[0]) {
                       res[1] *= s.factor;
                       res[2] *= s.factor;
                       res[3] *= s.factor;
                       res[4] *= s.factor;
                       res[5] *= s.factor;
                   }
                });
                return res;
            });
            //console.log('321-4', res.data.gaps);// fib  data
            let gaps = [];

            if (res.data.gaps) {
                res.data.gaps.forEach((gap) => {
                    const d1 = new Date(gap.after);
                    gap.afterDT = d1.getTime() - timeOffset;
                    gap.untilDT = gap.until;
                    if (gap.until && (gap.after != gap.until)) {
                        const d2 = new Date(gap.until);
                        gap.untilDT = d2.getTime() - timeOffset;
                    } else
                        gap.until = null;
                    //if ((gap.filledHigh == gap.high) && (gap.filledLow == gap.low)) {
                    //    gap.filledHigh = null;
                    //}
                    //fib.endDate = fib.endDate - timeOffset;
                    if ((gap.filledHigh == null) || (gap.filledLow == null) || (Math.abs(gap.filledHigh - gap.filledLow) > 0.0000001))
                        gaps.push(gap);
                });
            }

            //console.log('321-4', ohlcvData[ohlcvData.length-4]);
            //console.log('321-3', ohlcvData[ohlcvData.length-3]);
            //console.log('321-2', ohlcvData[ohlcvData.length-2]);
            //console.log('321-1', ohlcvData[ohlcvData.length-1]);
            const flags = res.data.flags == null ? [] : res.data.flags;

            // fib  data
            let fibData = [];
            if (res.data.results) {
                res.data.results.forEach((fib) => {
                    if (fib.deleted) return;
                    fib.startDate = fib.startDate - timeOffset;
                    fib.endDate = fib.endDate - timeOffset;
                    fibData.push(fib);
                });
            }

            //console.log("read fibData",fibData)

            // extreme data
            let extremes = [];
            if (res.data.extremes) {
                res.data.extremes.forEach((extreme, i) => {
                    //console.log(extreme, extreme.strength == 'NORMAL' ? 'isstrength' : 'nostrength');
                    extreme.when = extreme.when - timeOffset;
                    extreme.closeWhen = extreme.closeWhen - timeOffset;
                    extremes.push(extreme);
                });
            }

            // extreme data
            let horizontals = [];
            if (res.data.horizontals) {
                res.data.horizontals.forEach((horizontal, i) => {
                    horizontal.startDate = horizontal.startDate - timeOffset;
                    horizontals.push(horizontal);
                });
            }

            // clusters data
            let clusters = [];
            if (res.data.clusters) {
                res.data.clusters.forEach((cluster, i) => {
                    cluster.found = cluster.found - timeOffset;
                    clusters.push(cluster);
                });
            }


            // trends data
            let trends = [];
            if (res.data.trends) {
                res.data.trends.forEach((trend, i) => {
                    trend.endDate = trend.endDate - timeOffset;
                    trend.startDate = trend.startDate - timeOffset;
                    if (trend.parent) {
                        trend.parent = trend.parent - timeOffset;
                    }
                    if (trend.extensions) {
                        trend.extensions.forEach((e) => {
                            e.when = e.when - timeOffset;
                        });
                    }
                    trends.push(trend);
                });
            }
            self.callbackHistory(symbol, timeframe, ohlcvData, fibData, extremes, flags, horizontals, gaps, clusters, trends, {start: start, end: end});
        }
    }

    requestMarketList(oldData) {
        const self = this;
        //const url = `${this.apiUrl}/markets/list`;
        let url = `${this.apiUrl}/${this.mockMode}markets/list`;
        if (oldData)
            url = `${this.apiUrl}/markets/list`;
        axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
        console.log('requestMarketList',url);
        // axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
        axios
            .get(url)

            /*,{
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'POST, GET, PUT, OPTIONS, DELETE',
                        'Access-Control-Allow-Headers': 'Access-Control-Allow-Methods, Access-Control-Allow-Origin, Origin, Accept, Content-Type',
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                })*/
            .then((res) => {
                self.callbackMarketList(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    }
}

export class ApiWebSocketInterface {
    constructor(owner, url) {
        this.owner = owner;
        this.url = url;
        this.connected = false;
        this.client = null;
        this.token  = null;
        this.timer  = null;
        this.timeout = false;
        this.connect();
    }

    setToken(token) {
        this.token  = token;
        if (this.connected)
            this.onOpen();
    }

    connect() {
        console.log('ws connect');
        this.timeout = false;
        this.client = new WebSocket(this.url);

        this.client.onopen = this.onOpen.bind(this);
        this.client.onerror = this.onError.bind(this);
        this.client.onmessage = this.onMessage.bind(this);
        this.client.onclose = this.onClose.bind(this);
    }

    reconnect(waitTime) {
        console.log('ws reconnect',waitTime);
        try {
            if (!this.timeout) {
                this.timeout = true;
                this.client.close();
                this.client = null;
                setTimeout(this.connect.bind(this), waitTime || 30000);
            }
        } catch (e) {
            if (!this.timeout) {
                this.timeout = true;
                this.client = null;
                this.timer = setTimeout(this.connect.bind(this), waitTime || 30000);
            }
        }
    }

    onMessage(data) {
        //console.log(data.data)
        const msg = JSON.parse(data.data);
        if (msg && msg.action) {
            if (msg.action == 'login') {
                this.onLoginResponce(msg.data);
            } else if (msg.action == 'daxen') {
                this.owner.owner.handleUserApiDaxen(msg.data);
            } else if (msg.action == 'updLiveChart') {
                //console.log("updLiveChart",msg);
                if (this.owner.owner.onUpdateLiveChart)
                    this.owner.owner.onUpdateLiveChart(msg.data);
            } else if (msg.action == 'resLastView') {
                this.owner.owner.handleLastViewResponce(msg.data);
            } else if (msg.action == 'updInstantMessage') {
                this.owner.owner.handleInstanceMessage(msg.data);
            } else if (msg.action == 'updAlert') {
                this.owner.owner.handleAlertUpdate(msg.data);
            } else if (msg.action == 'update_video') {
                this.owner.owner.handleVideoUpdate(msg.data);
            } else if (msg.action == 'updPrice') {
                if (msg.data.candle && msg.data.candle.timestamp && msg.data.candle.timestamp<16745930000)
                    msg.data.candle.timestamp *= 1000;
                if (window.chartHandler)
                    window.chartHandler.handlePriceUpdate(msg.data.name,msg.data.timeBase,msg.data.candle);
                else if (this.owner.owner.handlePriceUpdate)
                    this.owner.owner.handlePriceUpdate(msg.data.name,msg.data.timeBase,msg.data.candle);
            } else if (msg.action == 'user_update') {
                if (msg.data.action == 'getUsers') {
                    this.owner.owner.handleGetUsers(msg.data);
                } else if (msg.data.action == 'getRights') {
                    this.owner.owner.handleGetRights(msg.data);
                } else if (msg.data.action == 'getUserRights') {
                    this.owner.owner.handleGetUserRights(msg.data);
                } else if (msg.data.action == 'setUserRights') {
                    this.owner.owner.handleSetUserRights(msg.data);
                } else if (msg.data.action == 'getUserInfos') {
                    this.owner.owner.handleGetUserInfos(msg.data);
                } else if (msg.data.action == 'getMarketLists') {
                    this.owner.owner.handleGetMarketLists(msg.data);
                } else if (msg.data.action == 'setMarketList') {
                    this.owner.owner.handleSetMarketList(msg.data);
                } else if (msg.data.action == 'getMarketListDetails') {
                    this.owner.owner.handleGetMarketListDetails(msg.data);
                } else if (msg.data.action == 'setMarketListDetail') {
                    if (this.owner.owner.handleSetMarketListDetail)
                        this.owner.owner.handleSetMarketListDetail(msg.data);
                } else if (msg.data.action == 'updMarketListDetail') {
                    if (this.owner.owner.handleUpdMarketListDetail)
                        this.owner.owner.handleUpdMarketListDetail(msg.data);
                } else if (msg.data.action == 'delMarketListDetail') {
                    if (this.owner.owner.handleDelMarketListDetail)
                        this.owner.owner.handleDelMarketListDetail(msg.data);
                } else if (msg.data.action == 'getMarketListLayouts') {
                    if (this.owner.owner.handleGetMarketListLayouts)
                        this.owner.owner.handleGetMarketListLayouts(msg.data);
                } else if (msg.data.action == 'setMarketListLayout') {
                    if (this.owner.owner.handleSetMarketListLayout)
                        this.owner.owner.handleSetMarketListLayout(msg.data);
                } else if (msg.data.action == 'getToolSettings') {
                    if (this.owner.owner.handleGetToolSettings)
                        this.owner.owner.handleGetToolSettings(msg.data);
                } else if (msg.data.action == 'setToolSetting') {
                    if (this.owner.owner.handleSetToolSetting)
                        this.owner.owner.handleSetToolSetting(msg.data);
                } else if (msg.data.action == 'delToolSetting') {
                    if (this.owner.owner.handleDelToolSetting)
                        this.owner.owner.handleDelToolSetting(msg.data);
                } else if (msg.data.action == 'getUserViews') {
                    if (this.owner.owner.layoutHandler.handleUserViews)
                        this.owner.owner.layoutHandler.handleUserViews(msg.data);
                } else if (msg.data.action == 'getUserFavorites') {
                    if (this.owner.owner.favoritesHandler)
                        this.owner.owner.favoritesHandler.handleFavoriteUpdate(msg.data);
                }
            } else if (msg.action == 'resHeartbeat') {
            } else {
                console.log('wsmessage',msg);
            }
        }
    }

    onLoginResponce(msg) {
        console.log('login result ws: '+JSON.stringify(msg));
        if (msg.result == 'ok') {
            const self = this;
            this.owner.owner.handleUserApiLogin(msg);
            if (this.timer)
                clearInterval(this.timer);
            this.timer = setInterval(() => {
                self.sendMessage('heartbeat', {"app": "www"});
            }, 5000);
        }
    }

    onClose(err) {
        this.connected = false;
        console.error('ws close',err);
        this.reconnect(10000);
    }

    onError(err) {
        console.error('ws wsmessage-error',err);
        //this.connected = false;
        //this.reconnect(10000);
    }

    sendMessage(type, data) {
        if (this.client && this.connected) {
            /*if (data) {
                console.log('sendMessage', {action: type, data: data});
            }*/
            this.client.send(JSON.stringify({action: type, data: data}));
        }
    }

    onOpen() {
        console.log('ws open',this.token);
        this.connected = true;
        if (this.token) {
            this.sendMessage('login', {token: this.token});
        }
    }
}

export class ChartWebsocketInterface {
    // chart-software.com
    constructor(url) {
        this.isActive = false;
        this.subscribtions = new Map();
        this.client = new StompJs.Client({ brokerURL: "wss://"+url+"/#ws" , reconnectDelay: 2500,
                heartbeatIncoming: 10000,
                heartbeatOutgoing: 10000,
                connectHeaders: {
                    'Access-Control-Allow-Origin': '*'
                }
            },
            this.onConnect.bind(this),
            this.onDisConnect.bind(this));
        this.client.onConnect = this.onConnect.bind(this);
        this.client.onclose = this.onDisConnect.bind(this);
        this.client.onStompError = this.onError.bind(this);
        this.client.activate();

        console.log("wss", url)

    }

    aboUpdates(symbol, timeframe, partCB, fiboCB, gapsCB, horizontalCB, clusterCB) {
        this.subscribe('part', symbol, timeframe, partCB);
        this.subscribe('fibo', symbol, timeframe, fiboCB);
        this.subscribe('gaps', symbol, timeframe, gapsCB);
        this.subscribe('horizontal', symbol, timeframe, horizontalCB);
        this.subscribe('cluster', symbol, timeframe, clusterCB);
        this.subscribe('clusters', symbol, timeframe, clusterCB);
    }

    deAboUpdates(symbol, timeframe) {
        this.unSubscribe('part', symbol, timeframe);
        this.unSubscribe('fibo', symbol, timeframe);
        this.unSubscribe('gaps', symbol, timeframe);
        this.unSubscribe('horizontal', symbol, timeframe);
    }

    subscribe(type, symbol, timeframe, cb)
    {
        const subscriptionInfo = {
            type: type,
            symbol: symbol,
            timeframe: timeframe,
            cb: cb
        };

        if (this.isActive && this.client && this.client.connected) {
            //console.log('send subsriction to ws', subscriptionInfo, `/topic/${symbol}/${timeframe}/` + type);
            subscriptionInfo.subscription = this.client.subscribe(`/topic/${symbol}/${timeframe}/` + type, (data) => {
                cb(symbol, timeframe, data);
            });
        } //else
        //    console.log('try send subsriction to ws', subscriptionInfo);

        this.subscribtions.set(type + '#' + symbol + '#' + timeframe, subscriptionInfo);
    }

    unSubscribe(type, symbol, timeframe) {
        const subscription = this.subscribtions.get(type+'#'+symbol+'#'+timeframe);

        if (subscription && subscription.subscription) {
            subscription.subscription.unsubscribe();
            this.subscribtions.delete(type+'#'+symbol + '#' + timeframe);
        }
    }

    onDisConnect() {
        this.isActive = false;
        if (this.client)
            this.client.forceDisconnect();
        console.log('disconnect ws');
    }

    onError(frame) {
        console.log('Broker reported error: ' + frame.headers['message']);
        console.log('Additional details: ' + frame.body);
        this.isActive = false;
        if (this.client)
            this.client.forceDisconnect();
        console.log('onError ws');
    }

    onConnect() {
        const self = this;
        this.isActive = true;
        //console.log('on connect');
        /*this.subscribtions.forEach((sub) => {
            if (sub.subscription)
                sub.subscription.unsubscribe();
        });*/
        this.subscribtions.forEach((sub) => {
            //console.log('subscripbtion', sub);
            self.subscribe(sub.type, sub.symbol, sub.timeframe, sub.cb);
        });
    }

    off() {
        if (this.client)
            this.client.deactivate();
    }
}

export class ChartServerInterface {
    constructor(owner, apiUrlMarket, apiUrlUser, apiWebsocket, apiMDWebsocket, system, mockMode, timeCalc) {
        this.owner = owner;
        this.userDataInterface = new UserDataInterface(this,{
            apiUrl : apiUrlUser,
            callbackSettings : this.handleSettingsResponce,
            callbackIndicators : this.handleIndicatorsResponce,
            callbackTemplates : this.handleTemplatesResponce,
            callbackTemplate : this.handleTemplateResponce,
            callbackShortcuts : this.handleShortcutsResponce,
            mockMode : mockMode
        })
        this.marketInterface = new MarketDataInterface(this, {
            apiUrl : apiUrlMarket,
            callbackMarketList : this.handleMarketListResponce,
            callbackHistory : this.handleHistoryResponce,
            mockMode : mockMode
        }, timeCalc);
        this.watchListInterface = new WatchListInterface(this, {
            apiUrl : apiUrlUser,
            callbackLoadWatchList : this.handleWatchListResponce,
            callbackWatchListDetailsResponce : this.handleWatchListDetailsResponce,
            mockMode : mockMode
        })
        this.chartWebsocketInterface = new ChartWebsocketInterface(system+'.chart-software.com');
        this.apiWebSocketInterface = new ApiWebSocketInterface(this, apiWebsocket);
        this.marketDataSocketInterface = new ApiWebSocketInterface(this, apiMDWebsocket);
    }

    setToken(token) {
        this.marketInterface.setToken(token);
        this.userDataInterface.setToken(token);
        this.watchListInterface.setToken(token);
        this.apiWebSocketInterface.setToken(token);
        this.marketDataSocketInterface.setToken(token);
    }

    requestIndicators() {
        this.userDataInterface.requestIndicators();
    }

    handleIndicatorsResponce(indicators) {
        this.owner.handleIndicatorsResponce(indicators.result);
    }

    handleWatchListResponce(watchList) {
        this.owner.handleWatchListResponce(watchList.result);
    }

    handleWatchListDetailsResponce(watchListDetails) {
        this.owner.handleWatchListDetailsResponce(watchListDetails.result);
    }

    handleTemplatesResponce(templates, type, timeframe) {
        //setTimeout(this.handleTemplatesResponceAsync.bind(this),10,templates.result, type);
        this.owner.handleTemplatesResponce(templates.result, type, timeframe);
    }
    handleTemplatesResponceAsync(templates, type) {
        this.owner.handleTemplatesResponce(templates, type);
        //this.owner.handleTemplatesResponce(templates.result, type);
    }

    requestSettings() {
        this.userDataInterface.requestSettings();
    }

    handleSettingsResponce(settings) {
        this.owner.handleSettingsResponce(settings);
    }

    requestMarketList() {
        this.marketInterface.requestMarketList(true);
        this.marketInterface.requestMarketList(false);
    }

    handleMarketListResponce(list) {
        this.owner.handleMarketListResponce(list);

    }

    requestHistory(symbol, timeframe, start, end, limit) {
        console.log("requestHistory", symbol, timeframe, start, end)
        if (window.chartHandler && !start && !end) {
            Vue.set(window.chartHandler.loading, 'isLoadingHistoryData', true);
            window.chartHandler.setChartVisible(false);
        }
        this.marketInterface.requestHistory(symbol, timeframe, start, end, limit);
        if (!start && !end) {
            setTimeout(() => {
                this.apiWebSocketInterface.sendMessage('chart_cfg', {
                    mode: 'saveLastView',
                    symbol: symbol,
                    tf: timeframe,
                    visibleBars: this.owner.getVisibleBars(0),
                    visibleBarsToRight: this.owner.getRightSpace(0),
                });
            }, 1000);
        }
    }

    requestTemplates() {
        this.userDataInterface.requestTemplates();
    }

    requestMarketLists() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getMarketLists'});
    }

    requestMarketListDetails(marketListId) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getMarketListDetails', id: marketListId});
    }

    addMarketListDetail(marketListId, symbol, marketListName, colIndex) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'setMarketListDetail', id: marketListId, symbol: symbol, colIndex: colIndex || 0, description: marketListName});
    }

    updMarketListDetail(marketListId, symbol, colIndex) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'updMarketListDetail', id: marketListId, symbol: symbol, colIndex: colIndex || 0});
    }

    deleteMarketListDetail(marketListId, symbol) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'delMarketListDetail', id: marketListId, symbol: symbol});
    }

    getMarketListLayouts() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getMarketListLayouts'});
    }

    setMarketListLayout(layoutName, layoutData) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'setMarketListLayout', name: layoutName, configData: layoutData});
    }

    requestUsers() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getUsers'});
    }

    requestUserInfos() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getUserInfos'});
    }

    confirmUserInfo(id, text) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'setUserInfos', id: id, text: text});
    }

    requestToolSettings() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getToolSettings'});
    }

    saveToolSetting(toolName, name, cfg) {
        console.log(toolName, name);
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'setToolSetting', name: name, description: toolName, configData: JSON.stringify(cfg)});
    }

    deleteToolSetting(idToolSettings) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'delToolSetting', id: idToolSettings});
    }

    requestUserViews() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getUserViews'});
    }

    saveUserViews(cfg) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'setUserView',
            name: cfg.name,
            id: cfg.id,
            description: cfg.description, cfg: cfg});
    }

    requestRights() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getRights'});
    }

    requestUserRights() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getUserRights'});
    }

    setUserRight(action, rightId, userId) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'setUserRights', action: action, rightId: rightId, userId: userId});
    }

    requestChartsTemplates(symbol, timeframe, limit) {
        this.userDataInterface.requestChartsTemplates(symbol, timeframe, limit);
    }

    requestTemplateInfo(type, template) {
        //this.owner.handleTemplateResponce(type, {result:{}});
        console.log('requestTemplateInfo',type, template);
        this.userDataInterface.requestTemplateInfo(type, template);
    }

    addWatchListDetail(symbol, wlName) {
        this.watchListInterface.addWatchListDetail(symbol, wlName);
    }

    deleteWatchListDetail(symbol, wlName) {
        this.watchListInterface.deleteWatchListDetail(symbol, wlName);
    }

    deleteWatchList(wlName) {
        this.watchListInterface.deleteWatchList(wlName);
    }

    requestWatchList() {
        this.watchListInterface.loadWatchList();
    }

    requestWatchListDetails() {
        this.watchListInterface.requestWatchListDetails('','');
    }

    saveTemplate(type, id, data, symbol, timeframe, headline, text, commentInternal, resourceInfo,  labelActions, publishedAnalysis, abos) {
        let t = data && data.CHART_DATA ? JSON.parse( data.CHART_DATA) : {};
        console.log('saveTemplate handleTemplateResponce',type, t, data);
        //this.userDataInterface.saveTemplate(type, id, data, symbol, timeframe, headline, text, commentInternal, resourceInfo,  labelActions, publishedAnalysis, abos);
        if (type !== 'template') {
            this.apiWebSocketInterface.sendMessage('chart_cfg', {
                mode: 'full',
                //id: id,
                data: data.CHART_DATA,
                symbol: symbol,
                tf: timeframe,
                name: data.NAME || "DEFAULT",
                headline: headline || "",
                text: text || "",
                commentInternal: commentInternal || "",
                resourceInfo: resourceInfo || "",
                labelActions: labelActions || "",
                publishedAnalysis: publishedAnalysis || false,
                abos: abos ? abos.toString() : "[]",
                visibleBars: this.owner.getVisibleBars(0),
                visibleBarsToRight: this.owner.getRightSpace(0)
            });
            /*this.apiWebSocketInterface.sendMessage('chart_cfg', {
                mode: 'saveLastView',
                id: id,
                symbol: symbol,
                tf: timeframe,
                visibleBars: this.owner.getVisibleBars(0),
                visibleBarsToRight: this.owner.getRightSpace(0)
            });*/
        }
    }

    deleteTemplate(type, id) {
        this.userDataInterface.deleteTemplate(type, id);
    }
    saveSettings(data) {
        this.userDataInterface.saveSettings(data);
    }

    handleHistoryResponce(symbol, timeframe, ohlc, fibData, extremes, flags, horizontals, priceGaps, clusters, trends, requestInfo) {
        //console.log('extremes',extremes)
        //setTimeout(this.handleHistoryResponceAsync.bind(this), 100, symbol, timeframe, ohlc, fibData, extremes, flags, horizontals, priceGaps, clusters, requestInfo);
        this.owner.handleHistoryResponce(symbol, timeframe, ohlc, fibData, extremes, flags, horizontals, priceGaps, clusters, trends, requestInfo);
    }

    handleHistoryResponceAsync(symbol, timeframe, ohlc, fibData, extremes, flags, horizontals, priceGaps, clusters, requestInfo) {
        this.owner.handleHistoryResponce(symbol, timeframe, ohlc, fibData, extremes, flags, horizontals, priceGaps, clusters, requestInfo);
    }

    handlePriceUpdate(symbol, timeframe, price) {
        return;


        const tickData = JSON.parse(price.body);

        //console.log('321-0',Date.now(),'handlePriceUpdate',tickData);
        //if (window.chartHandler)
        //    window.chartHandler.handlePriceUpdate(symbol, timeframe, tickData);
        if (this.owner.handlePriceUpdate)
            this.owner.handlePriceUpdate(symbol, timeframe, tickData.candle);
    }

    handleClusterUpdate(symbol, timeframe, clusterData) {
        const tickData = JSON.parse(clusterData.body);

        console.log(Date.now(),'handleClusterUpdate',symbol, timeframe, tickData);
        //this.owner.handleHoriUpdate(symbol, timeframe, tickData);
    }

    handleHoriUpdate(symbol, timeframe, horiData) {
        const tickData = JSON.parse(horiData.body);

        //console.log(Date.now(),'handleHoriUpdate',symbol, timeframe, tickData);
        this.owner.handleHoriUpdate(symbol, timeframe, tickData);
    }

    handleFiboUpdate(symbol, timeframe, trade) {
        const tickData = JSON.parse(trade.body);

        //console.log(Date.now(),'handleFiboUpdate',symbol, timeframe, tickData);
        if (this.owner.handleFiboUpdate)
            this.owner.handleFiboUpdate(symbol, timeframe, tickData);
    }

    handleGapsUpdate(symbol, timeframe, gaps) {
        const tickData = JSON.parse(gaps.body);

        //console.log(Date.now(),'handleGapsUpdate',symbol, timeframe, tickData);
        if (this.owner.handleFiboUpdate)
            this.owner.handleFiboUpdate(symbol, timeframe, tickData);
    }

    handleTemplateResponce(d, type) {
        this.owner.handleTemplateResponce(type, d);
    }

    requestShortcuts() {
        this.userDataInterface.requestShortcuts();
    }

    handleShortcutsResponce(shortcuts) {
        //console.log('handleShortcutsResponce',shortcuts);
        this.owner.handleShortcutsResponce(shortcuts.result);
    }

    aboUpdates(symbol, timeframe) {
        //console.log("aboPrices", symbol);
        this.marketDataSocketInterface.sendMessage('priceAbo', {symbol: symbol});
        this.chartWebsocketInterface.aboUpdates(symbol, timeframe, this.handlePriceUpdate.bind(this), this.handleFiboUpdate.bind(this), this.handleGapsUpdate.bind(this), this.handleHoriUpdate.bind(this), this.handleClusterUpdate.bind(this));
    }


    // UserApi Parts
    requestAndAboDaxenInfos() {
        this.apiWebSocketInterface.sendMessage('daxen', {type: 'abo'});
    }

    requestUserFavorites() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'getUserFavorites'});
    }

    saveUserFavorites(data) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'setUserFavorites', dataList: data});
    }

    sendLiveChart(type, id, data, symbol, timeframe, indId, indName, liveId, deleted, source) {
        //console.log('sendLiveChart',id, indId)
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'part', id: id, data: data, indId: indId, indName: indName, text: symbol+" ("+timeframe+")", symbol: symbol, tf: timeframe, liveId: liveId, deleteTool:deleted, source: source});
    }

    sendLiveChartZoom(type, liveId, visibleBars, rightSpace, user) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'part', isZoom: true, id: liveId, visibleBars: visibleBars, rightSpace: rightSpace, user: user, liveUser: user, liveId: 'zoom', deleteTool: false});
    }

    sendLiveChartMouse(type, liveId, price, dt) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'part', isMouse: true, id: liveId, price: price, dt: dt, liveId: 'mouse', deleteTool: false});
    }

    sendLiveViewReset(type, liveId) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'part', isReset: true, id: liveId});
    }

    setLiveChart(id, state, symbol, timeframe, headline, description, commentInternal, resourceInfo, labelActions, publishedAnalysis, abos) {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'state', state: state, id: id, text: symbol+" ("+timeframe+")", symbol: symbol, tf: timeframe, headline: headline, description: description, commentInternal: commentInternal, resourceInfo: resourceInfo, labelActions: labelActions, inactive: publishedAnalysis ? 0 : 1, abos: JSON.stringify(abos)});
    }

    requestVideos() {
        this.apiWebSocketInterface.sendMessage('video_info', {mode: 'req'});
    }

    requestAlerts(indexFrom, indexCount, symbol, rule) {
        this.apiWebSocketInterface.sendMessage('chart_alert', {mode: 'req', symbol: symbol, rule: rule, indexFrom: indexFrom, alertCount: indexCount });
    }

    requestLiveChart(id, symbol, timeframe, save) {
        if (save) {
            this.apiWebSocketInterface.sendMessage('chart_cfg', {
                mode: 'saveLastView',
                id: id,
                symbol: symbol,
                tf: timeframe,
                visibleBars: this.owner.getVisibleBars(0),
                visibleBarsToRight: this.owner.getRightSpace(0),
            });
        }
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'req', id: id});
        //console.log('requestLiveChart',id)
    }

    requestLastChartView() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'reqLastView'});
    }

    requestLiveChartList() {
        this.apiWebSocketInterface.sendMessage('chart_cfg', {mode: 'reqList'});
    }

    // UserApi Parts
    requestSendInstantMessage(text) {
        this.apiWebSocketInterface.sendMessage('sendInstantMessage', {text: text});
    }

    requestSendPublishVideoMessage(videoId, videoTitle, videoDesc) {
        this.apiWebSocketInterface.sendMessage('video_info', {mode: 'save', videoId: videoId, videoTitle: videoTitle, videoDesc: videoDesc, Abos: "FREE"});
    }

    /*setMouseMove(symbol, last, dt) {
        this.apiWebSocketInterface.sendMessage('mu', {symbol: symbol, last: last, dt: dt});
    }*/
}