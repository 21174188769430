<script>
// Line drawing tool

import Overlay from "../../lib/mixins/overlay.js";
import Tool from "../../lib/mixins/tool.js";

export default {
  name: 'ftTEMA',
  mixins: [Overlay, Tool],
  methods: {
    meta_info() {
      return {
        author: 'Sven Schwind',
        version: '1.0.0',
        desc: 'TEMA - Triple EMA',
      }
    },
    use_for() {
      return ["ftTEMA"];
    },
    calc() {
      return {
        props: {
          length: {
            def: 14,
            text: 'Length'
          },
        },
        conf: {
          renderer: 'Spline'
        },
        update: " const ea1 = ema(close, length); \n const ea2 = ema(ea1, length); \n const ea3 = ema(ea2, length); \n  return [3*ea1[0] - 3*ea2[0] + ea3[0],ea1[0],ea2[0],ea3[0]]; \n     "
      };
    }
  }
};
</script>
